import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";
import iconChamp from "../../../../assets/icons/Common/second.svg";
import imgThird from "../../../../assets/images/Donors/third.svg";
import iconCheckWhite from "../../../../assets/icons/Common/check-white.svg";

function First() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <div className="content">
              <div className="fs-content">
                <img src={iconChamp} alt="" />
                <h1>Incentivos</h1>
                <p id="text-subcontent">
                  Apoiando a Ciência você ainda conquista vantagens e benefícios
                  exclusivos!
                </p>
              </div>
              <div>
                <ImgWrapper>
                  <Img src={imgThird} alt="" />
                </ImgWrapper>
              </div>
            </div>
          </Column>
          <Column>
            <ul>
              <li>
                <div className="box-list">
                  <img src={iconCheckWhite} alt="" />
                  <p id="list-title" style={{ marginLeft: 10 }}>
                    Transforme suas doações em Moedas Whuffie!
                  </p>
                </div>
                <p id="list-text">
                  Com essas moedas você pode adquirir produtos, serviços e
                  experiências de verdade, disponíveis em nosso “Mercado
                  Whuffie”.
                </p>
              </li>

              <li>
                <div className="box-list">
                  <img src={iconCheckWhite} alt="" />
                  <p id="list-title" style={{ marginLeft: 10 }}>
                    Participe dos Desafios
                  </p>
                </div>
                <p id="list-text">
                  Ao se cadastrar na plataforma W4S, você pode participar dos
                  Desafios lançados pelas empresas participantes, ganhar prêmios
                  e encontrar oportunidades profissionais!
                </p>
              </li>

              <li>
                <div className="box-list">
                  <img src={iconCheckWhite} alt="" />
                  <p id="list-title" style={{ marginLeft: 10 }}>
                    Faça parte da maior rede de cientistas e apoiadores da
                    Ciência que está surgindo!
                  </p>
                </div>
                <p id="list-text">
                  Venha fazer parte da comunidade W4S, nós queremos mudar o
                  mundo através do progresso científico.
                </p>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default First;
