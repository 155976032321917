import styled, { css } from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  margin-bottom: 39px;
  margin-left: 14px;
  width: 270px;
  height: 429px;
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 13px 0 rgba(49, 22, 159, 0.09);
  background-color: #fafaff;
  border-radius: 10px;
  #product-content-1 {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  #product-content-2 {
    width: 100%;
    height: 230px;
    background-color: #fafaff;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      #product-text-1-content-2 {
        margin-top: 20px;
        margin-left: 26px;
        width: 209px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
      #category {
        margin-left: 26px;
        margin-top: 7px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #product-text-2-content-2 {
        margin-top: -40px;
        margin-left: -70px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        img {
          height: 16px;
          width: 16px;
          margin-right: 6px;
        }
      }
      section {
        margin-top: 20px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    #product-price {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      #product-text-3-content-2 {
        margin-left: 26px;
        margin-top: 20px;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #product-text-4-content-2 {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #product-text-5-content-2 {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #b0b3ce;
      }
    }
  }
  #product-content-3 {
    width: 100%;
    height: 59px;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .content {
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 18px;

      #car-icon {
        margin: 38px 9.4px 40px 26px;
      }
      #link-product {
        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;

        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
  section {
    margin-top: 10px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (max-width: 472px) {

    ${(props) =>
      props.special &&
      css`
        width: 100%;
      `}

    ${(props) =>
      !props.special &&
      css`
        width: 90vw;
      `}
  }
`;
