import React, { useState, useEffect } from "react";
import { Bounce, Roll, Slide, Fade } from "react-reveal";

import { Form, Input } from "@rocketseat/unform";
import Recaptcha from "react-recaptcha";

import { useDispatch, useSelector } from "react-redux";
import { signUpRequest, signInRequest } from "../../store/modules/auth/actions";

//import { Scrollbars } from "react-custom-scrollbars";

import * as Yup from "yup";

import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import AvatarInput from "../../components/AvatarInput";

import { useHistory } from "react-router-dom";
import { store } from "../../store";

import {
  Wrapper,
  Content,
  Header,
  RegisterContent,
  Button,
  ButtonInterests,
  LoginContent,
  BoxScientist,
} from "./styles";

import { Link } from "react-router-dom";

import { FaCheck } from "react-icons/fa";

import iconRegister from "../../assets/icons/Register/icon-register.svg";
import iconScientist from "../../assets/icons/Scientists/scientist.svg";
import imgHeader from "../../assets/images/Register/img-header.svg";
import iconPencil from "../../assets/icons/Register/pencil.svg";
import iconInterests from "../../assets/icons/Register/interests.svg";
import iconClose from "../../assets/icons/Register/close.svg";
import iconTrash from "../../assets/icons/Common/trash.svg";
import { toast } from "react-toastify";
import api from "../../services/api";
import Scrollbars from "react-custom-scrollbars";
import { Helmet } from "react-helmet";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("O e-mail é obrigatório"),
  password: Yup.string().required("A senha é obrigatória"),
});

const phoneRegex =
  /^1\d\d(\d\d)?$|^0800 ?\d{3} ?\d{4}$|^(\(0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d\) ?|0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d[ .-]?)?(9|9[ .-])?[2-9]\d{3}[ .-]?\d{4}$/gm;

const schemaReg = Yup.object().shape({
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("O e-mail é obrigatório"),
  name: Yup.string().required("O nome é obrigatório"),
  phone: Yup.string()
    .required("O Telefone é obrigatório")
    .matches(phoneRegex, "Formato de telefone inválido"),
  password: Yup.string().required("A senha é obrigatória"),
  confirmPassword: Yup.string().when("password", (password, field) =>
    password
      ? field
          .required("Confirmação de senha Obrigatória")
          .oneOf([Yup.ref("password")], "Senhas não batem")
      : field
  ),
  avatar_id: Yup.number(),
});

const schemaScientist = Yup.object().shape({
  id: Yup.string()
    .required("Digite o nome do Cientista")
    .typeError("Digite o nome do Cientista"),
});

function Register() {
  const { token, perfil } = store.getState().auth;

  const [buttonPeople, setButtonPeople] = useState(() => true);
  const [buttonCompany, setButtonCompany] = useState(() => false);
  const [buttonProject, setButtonProject] = useState(() => false);

  const [buttonRegister, setButtonRegister] = useState(() => true);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handlePeopleClick() {
    if (buttonPeople === false) {
      setButtonPeople(!buttonPeople);

      if (buttonCompany === true) {
        setButtonCompany(!buttonCompany);
      }
      if (buttonProject === true) {
        setButtonProject(!buttonProject);
      }
    }
  }

  function handleCompanyClick() {
    if (buttonCompany === false) {
      setButtonCompany(!buttonCompany);

      if (buttonPeople === true) {
        setButtonPeople(!buttonPeople);
      }
      if (buttonProject === true) {
        setButtonProject(!buttonProject);
      }
    }
  }

  function handleProjectClick() {
    if (buttonProject === false) {
      setButtonProject(!buttonProject);

      if (buttonPeople === true) {
        setButtonPeople(!buttonPeople);
      }
      if (buttonCompany === true) {
        setButtonCompany(!buttonCompany);
      }
    }
  }

  let history = useHistory();

  // const [preferences, setPreferences] = useState([]);

  const [agribusiness, setAgribusiness] = useState(false);
  const togglingAgribusiness = () => setAgribusiness(!agribusiness);

  const [sustainability, setSustainability] = useState(false);
  const togglingSustainability = () => setSustainability(!sustainability);

  const [industry, setIndustry] = useState(false);
  const togglingIndustry = () => setIndustry(!industry);

  const [trade, setTrade] = useState(false);
  const togglingTrade = () => setTrade(!trade);

  const [urban, setUrban] = useState(false);
  const togglingUrban = () => setUrban(!urban);

  const [arts, setArts] = useState(false);
  const togglingArts = () => setArts(!arts);

  const [science, setScience] = useState(false);
  const togglingScience = () => setScience(!science);

  const [economy, setEconomy] = useState(false);
  const togglingEconomy = () => setEconomy(!economy);

  const [education, setEducation] = useState(false);
  const togglingEducation = () => setEducation(!education);

  const [cheers, setCheers] = useState(false);
  const togglingCheers = () => setCheers(!cheers);

  const [philosophy, setPhilosophy] = useState(false);
  const togglingPhilosophy = () => setPhilosophy(!philosophy);

  const [entertainment, setEntertainment] = useState(false);
  const togglingEntertainment = () => setEntertainment(!entertainment);

  const [childish, setChildish] = useState(false);
  const togglingChildish = () => setChildish(!childish);

  const [internet, setInternet] = useState(false);
  const togglingInternet = () => setInternet(!internet);

  const [business, setBusiness] = useState(false);
  const togglingBusiness = () => setBusiness(!business);

  const [politics, setPolitics] = useState(false);
  const togglingPolitics = () => setPolitics(!politics);

  const [products, setProducts] = useState(false);
  const togglingProducts = () => setProducts(!products);

  const [social, setSocial] = useState(false);
  const togglingSocial = () => setSocial(!social);

  const [media, setMedia] = useState(false);
  const togglingMedia = () => setMedia(!media);

  const [scientistUser, setScientistUser] = useState([]);
  const [nameScientist, setNameScientist] = useState("");
  const [finalScientist, setFinalScientist] = useState([]);

  async function handleAddScientist() {
    const res = await api.get(`/scientist/${nameScientist}`);

    if (res.data.error) {
      toast.error("Cientista não encontrado!");
    } else {
      setScientistUser(res.data);
    }
  }

  function trash(index) {
    var arr = scientistUser;
    arr = arr.slice(index, 1);

    setScientistUser(arr);
  }

  const [accept, setAccept] = useState(false);
  const [loadCaptcha, setLoadCaptcha] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);

  const toggleAccept = () => setAccept(!accept);

  // specifying verify callback function
  var verifyCallback = function () {
    setRecaptcha(true);
  };

  function handleSubmit(data) {
    if (!accept) {
      toast.error("Aceite o termo de uso para validar o cadastro!");
    } else if (loadCaptcha && !recaptcha) {
      toast.error("Marque o captcha!");
    } else {
      if (buttonPeople) {
        data.profile = "Apoiador/Visitante";
      } else if (buttonCompany) {
        data.profile = "Apoiador Empresarial/Desafiante";
      } else if (buttonProject) {
        data.profile = "Cientista/Pesquisador";
      }

      var aux = [];

      agribusiness && aux.push("Agronegócio");
      sustainability && aux.push("Meio ambiente e Sustentabilidade");
      industry && aux.push("Indústria");
      trade && aux.push("Comércio e prestação de serviços");
      urban && aux.push("Desenvolvimento Urbano");
      arts && aux.push("Artes e Cultura");
      science && aux.push("Ciência e Tecnologia");
      economy && aux.push("Economia");
      education && aux.push("Educação");
      cheers && aux.push("Saúde");
      philosophy && aux.push("Filosofia e Sociologia");
      entertainment && aux.push("Entretenimento");
      childish && aux.push("Infantil");
      internet && aux.push("Internet");
      business && aux.push("Negócios e Oportunidades");
      politics && aux.push("Política");
      products && aux.push("Produtos e Serviços");
      social && aux.push("Responsabilidade Social");
      media && aux.push("Mídia e Comunicação");

      finalScientist && aux.push(finalScientist.id);

      setScientistUser([]);
      setNameScientist("");
      setFinalScientist([]);

      var text = "";

      aux.map((item) => {
        if (isNaN(item)) {
          text = text + "category:" + item + ";";
        } else {
          text = text + "id:" + item + ";";
        }
        return text;
      });

      data.preferences = text;

      try {
        dispatch(signUpRequest(data));
      } catch (error) {
        toast.error("Erro ao cadastrar perfil!");
      } finally {
        setTimeout(function () {
          handleRegisterClick();
        }, 2100);
      }
    }
  }

  function handleRegisterClick() {
    setButtonRegister(!buttonRegister);
  }

  function handleSubmitLogin({ email, password, perfil }) {
    if (buttonPeople) {
      perfil = "Apoiador/Visitante";
    } else if (buttonCompany) {
      perfil = "Apoiador Empresarial/Desafiante";
    } else if (buttonProject) {
      perfil = "Cientista/Pesquisador";
    }
    dispatch(signInRequest(email, password, perfil));
  }

  useEffect(() => {
    if (token && perfil !== "admin") {
      setTimeout(function () {
        history.push("/projects");
      }, 2000);
    }
  }, [token, history, perfil]);

  return (
    <>
      <Helmet>
        <title>Cadastro</title>
      </Helmet>
      <Wrapper>
        <Bounce top duration={2000} distance="20px">
          <Content active={!buttonRegister}>
            <div style={{ background: "#227af1", paddingBottom: 35 }}>
              <SubHeader />
            </div>
            <Header>
              <section id="second-content-header">
                <img id="icon-header" src={iconRegister} alt="" />

                {buttonRegister ? (
                  <Fade>
                    <div className="titles">
                      <p id="title">CADASTRO</p>
                      <p id="sub-title">
                        Escolha abaixo como deseja se cadastrar gratuitamente em
                        nossa plataforma de apoiadores da Ciência.
                      </p>
                    </div>
                  </Fade>
                ) : (
                  <Fade>
                    <div className="titles">
                      <p id="title">LOGIN</p>
                      <p id="sub-title">
                        Escolha abaixo como deseja fazer Login em nossa
                        plataforma de apoiadores da Ciência.
                      </p>
                    </div>
                  </Fade>
                )}

                <div className="buttons">
                  <Button active={buttonPeople} onClick={handlePeopleClick}>
                    Apoiador / Visitante
                  </Button>
                  <Button active={buttonCompany} onClick={handleCompanyClick}>
                    Apoiador Empresarial / Desafiante
                  </Button>
                  <Button active={buttonProject} onClick={handleProjectClick}>
                    Cientista / Pesquisador
                  </Button>
                </div>

                <img id="img-header" src={imgHeader} alt="" />
              </section>
            </Header>
            <RegisterContent active={!buttonRegister}>
              {buttonRegister ? (
                <Roll>
                  <Form
                    className="form"
                    onSubmit={handleSubmit}
                    schema={schemaReg}
                  >
                    <AvatarInput name="avatar_id" />
                    <button
                      type="submit"
                      className="login-btn"
                      onClick={handleRegisterClick}
                    >
                      Já tenho um{" "}
                      {buttonPeople
                        ? "Perfil de Apoiador"
                        : buttonCompany
                        ? "Perfil Empresarial"
                        : "Perfil de Cientista"}
                    </button>
                    <div className="down-avatar">
                      <div className="pencil">
                        <img src={iconPencil} alt="" />
                      </div>
                      <p>Inserir Foto de Perfil</p>
                    </div>
                    <p
                      id="obs"
                      style={{ color: "#7f7fb9", fontSize: "0.9rem" }}
                    >
                      OBS: utilize uma imagem com pelo menos 112x112 pixels.
                    </p>
                    <div className="inputs">
                      <div className="input-content">
                        <Input
                          name="name"
                          type="text"
                          placeholder={
                            buttonCompany ? "Nome Fantasia: *" : "Nome: *"
                          }
                        />
                      </div>
                      <div className="input-content">
                        <Input
                          name="email"
                          type="text"
                          placeholder="E-mail: *"
                        />
                      </div>

                      <div className="input-content">
                        <Input
                          name="phone"
                          type="text"
                          placeholder="Telefone: * (ddd) 1234-5678 "
                        />
                      </div>

                      <div className="input-content">
                        <Input
                          name="password"
                          type="password"
                          placeholder="Senha: *"
                        />
                      </div>

                      <div className="input-content">
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirmar senha: *"
                        />
                      </div>
                    </div>
                    <p
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10%",
                        color: "#7f7fb9",
                        fontSize: "0.85rem",
                      }}
                    >
                      *Campo obrigatório.
                    </p>
                    <section></section>
                    <div className="interests">
                      <div className="title">
                        <img src={iconInterests} alt="" />
                        <div>
                          <p>Seus interesses científicos</p>
                        </div>
                      </div>
                      <div className="sub-title">
                        <p>
                          Marque quais são seus interesses científicos favoritos
                          para melhorarmos sua experiência na plataforma W4S!
                        </p>
                      </div>
                      <div className="buttons-interests">
                        <ButtonInterests
                          type="button"
                          id="fourth"
                          active={agribusiness}
                          onClick={togglingAgribusiness}
                        >
                          Agronegócio
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          type="button"
                          id="third"
                          active={sustainability}
                          onClick={togglingSustainability}
                        >
                          Meio ambiente e Sustentabilidade
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          id="fourth"
                          type="button"
                          active={industry}
                          onClick={togglingIndustry}
                        >
                          Indústria
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={urban}
                          onClick={togglingUrban}
                          type="button"
                          id="first"
                        >
                          Desenvolvimento Urbano
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={economy}
                          onClick={togglingEconomy}
                          type="button"
                          id="fourth"
                        >
                          Economia
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          type="button"
                          id="third"
                          active={trade}
                          onClick={togglingTrade}
                        >
                          Comércio e prestação de serviços
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          type="button"
                          id="second"
                          active={arts}
                          onClick={togglingArts}
                        >
                          Artes e Cultura
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={science}
                          onClick={togglingScience}
                          type="button"
                          id="first"
                        >
                          Ciência e Tecnologia
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={education}
                          onClick={togglingEducation}
                          type="button"
                          id="fourth"
                        >
                          Educação
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={cheers}
                          onClick={togglingCheers}
                          type="button"
                          id="fourth"
                        >
                          Saúde
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={philosophy}
                          onClick={togglingPhilosophy}
                          type="button"
                          id="first"
                        >
                          Filosofia e Sociologia
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={entertainment}
                          onClick={togglingEntertainment}
                          type="button"
                          id="second"
                        >
                          Entretenimento
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={business}
                          onClick={togglingBusiness}
                          type="button"
                          id="first"
                        >
                          Negócios e Oportunidades
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={childish}
                          onClick={togglingChildish}
                          type="button"
                          id="fourth"
                        >
                          Infantil
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={products}
                          onClick={togglingProducts}
                          type="button"
                          id="first"
                        >
                          Produtos e Serviços
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={internet}
                          onClick={togglingInternet}
                          type="button"
                          id="fourth"
                        >
                          Internet
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={social}
                          onClick={togglingSocial}
                          type="button"
                          id="first"
                        >
                          Responsabilidade Social
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={politics}
                          onClick={togglingPolitics}
                          type="button"
                          id="fourth"
                        >
                          Política
                          <img src={iconClose} alt="" />
                        </ButtonInterests>

                        <ButtonInterests
                          active={media}
                          onClick={togglingMedia}
                          type="button"
                          id="first"
                        >
                          Mídia e Comunicação
                          <img src={iconClose} alt="" />
                        </ButtonInterests>
                      </div>
                      <section></section>

                      <div className="box-add-scientist">
                        <div className="inputs">
                          <div className="title">
                            <img src={iconScientist} alt="" />
                            <p>Seguir Cientista</p>
                          </div>
                          <p id="sub-title">
                            Clique no Cientista que deseja acompanhar
                          </p>
                          <div className="new-member">
                            <Form
                              schema={schemaScientist}
                              onSubmit={handleAddScientist}
                            >
                              <Input
                                name="name"
                                type="text"
                                onChange={(event) =>
                                  setNameScientist(event.target.value)
                                }
                                placeholder="Nome do Cientista que deseja acompanhar"
                              />
                              <button
                                type="button"
                                onClick={() => handleAddScientist()}
                              >
                                Adicionar
                              </button>
                            </Form>
                          </div>
                        </div>

                        {scientistUser.length !== 0 && (
                          <ul className="content-scrool">
                            <Scrollbars>
                              {scientistUser.map((preview) => (
                                <BoxScientist
                                  select={
                                    preview === finalScientist ? true : false
                                  }
                                  key={preview.id}
                                  onClick={() => setFinalScientist(preview)}
                                >
                                  <div className="fs-box">
                                    <div className="first-div">
                                      {preview.avatar && (
                                        <img
                                          id="profile"
                                          src={
                                            preview.avatar && preview.avatar.url
                                          }
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <img
                                      id="icon-trash"
                                      src={iconTrash}
                                      onClick={() =>
                                        trash(scientistUser.indexOf(preview))
                                      }
                                      alt=""
                                    />
                                  </div>

                                  <p id="name-user">
                                    Nome:
                                    {preview != null &&
                                      (preview.name =
                                        preview != null &&
                                        preview.name.split(" ")[0])}
                                  </p>
                                  {preview != null && preview.profession && (
                                    <p id="name-user">
                                      Profissão:
                                      {preview != null &&
                                        preview.profession.split(" ")[0]}
                                    </p>
                                  )}
                                </BoxScientist>
                              ))}
                            </Scrollbars>
                          </ul>
                        )}
                      </div>
                    </div>
                    <section></section>
                    <label className="label">
                      <input
                        className="label__checkbox"
                        type="checkbox"
                        onClick={toggleAccept}
                      />

                      <span className="label__text">
                        <span className="label__check">
                          {accept && (
                            <FaCheck
                              className="fa fa-check icon"
                              color={"#7f7fb9"}
                            />
                          )}
                        </span>
                        <p id="text-checkbox">
                          Li e aceito os{" "}
                          <Link to="/terms-user">Termos de Uso</Link> e{" "}
                          <Link to="/terms-politic" style={{ marginRight: 5 }}>
                            Política de Privacidade e Uso de Dados
                          </Link>
                          da W4S
                        </p>
                      </span>
                    </label>
                    <Recaptcha
                      sitekey={`${process.env.REACT_APP_CAPTCHA}`}
                      render="explicit"
                      verifyCallback={verifyCallback}
                      onloadCallback={() => setLoadCaptcha(!loadCaptcha)}
                      className="captcha"
                      hl="pt-BR"
                    />
                    <div className="buttons-register">
                      <button id="finish-register" type="submit">
                        {buttonPeople
                          ? "Criar Perfil de Apoiador"
                          : buttonCompany
                          ? "Criar Perfil Empresarial"
                          : "Criar Perfil de Cientista"}
                      </button>
                      <button
                        type="submit"
                        id="finish-register"
                        onClick={handleRegisterClick}
                      >
                        Já tenho um{" "}
                        {buttonPeople
                          ? "Perfil de Apoiador"
                          : buttonCompany
                          ? "Perfil Empresarial"
                          : "Perfil de Cientista"}
                      </button>
                    </div>
                  </Form>
                </Roll>
              ) : (
                <Slide right duration={600} distance="20px">
                  <LoginContent>
                    <Form
                      className="form-login"
                      schema={schema}
                      onSubmit={handleSubmitLogin}
                    >
                      <div className="input-content">
                        <Input name="email" placeholder="E-mail:" />
                      </div>
                      <div className="input-content">
                        <Input
                          name="password"
                          placeholder="Senha:"
                          type="password"
                        />
                      </div>

                      <button id="submit-btn" type="submit">
                        {loading ? "Carregando ..." : "Fazer Login"}
                      </button>
                    </Form>
                    <button
                      id="register-btn"
                      onClick={handleRegisterClick}
                      type="button"
                    >
                      Não tenho uma conta
                    </button>
                    <button
                      id="recover-btn"
                      onClick={() => history.push("recover")}
                      type="button"
                    >
                      Esqueci minha senha
                    </button>
                  </LoginContent>
                </Slide>
              )}
            </RegisterContent>

            <Footer />
          </Content>
        </Bounce>
      </Wrapper>
    </>
  );
}

export default Register;
