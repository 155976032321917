import { keyframes } from "styled-components";

export const TextFocusIn = keyframes`
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
`;

export const Shake = keyframes`
    10%,90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,50%,70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,60% {
        transform: translate3d(4px, 0, 0);
    }
`;

export const bussOut = keyframes`
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }
    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }         
`;

export const textAnimation = keyframes`
    
    0% {
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
    }
       
    0% {
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
        }
    
`;

export const icon = keyframes` 
    from {
      opacity: 0;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
`;
export const check = keyframes` 
  
    0% {
      width: 1.5em;
      height: 1.5em;
      border-width: 5px;
    }
    10% {
      width: 1.5em;
      height: 1.5em;
      opacity: 0.1;
      background: #fff;
      border-width: 15px;
    }
    12% {
      width: 1.5em;
      height: 1.5em;
      opacity: 0.4;
      background: #fff;
      border-width: 1;
    }
    50% {
      width: 2em;
      height: 2em;
      background: #fff;
      border: 0;
      opacity: 0.6;
    }
    100% {
      width: 2em;
      height: 2em;
      background: #fff;
      border: 1;
      opacity: 1;
    }
`;