import styled, { css } from "styled-components";
import { darken } from "polished";
export const Wrapper = styled.div`
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  background: rgb(41, 118, 250);
  background: linear-gradient(
    90deg,
    rgba(41, 118, 250, 1) 22%,
    rgba(68, 169, 252, 1) 71%,
    rgba(75, 183, 253, 1) 95%
  );

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  #second-content-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    margin-bottom: 30px;

    #img-header {
      margin-left: 72px;
    }

    .info-project {
      margin-right: 320px;
      margin-top: 33px;
      #title {
        width: 100%;

        font-size: 40px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;

        margin-bottom: 19px;
        margin-left: 18px;
      }

      .info-project-content {
        display: flex;
        margin-left: 23px;

        .first-content {
          margin-right: 79px;
          div {
            display: flex;
            align-items: baseline;
            margin-bottom: 20px;
            p {
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.875;
              letter-spacing: normal;
              text-align: left;
              color: #ffffff;
              margin-left: 7px;
            }

            #people-icon {
              width: 16px;
              height: 16px;
            }
          }
        }

        .second-content {
          div {
            display: flex;
            align-items: baseline;
            margin-bottom: 20px;
            p {
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.875;
              letter-spacing: normal;
              text-align: left;
              color: #ffffff;
              margin-left: 7px;
            }
          }
        }
      }
    }

    .circles {
      #first-circle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        position: absolute;
        overflow: visible;
        width: 684px;
        height: 684px;
        opacity: 0.05;

        border-radius: 50%;
        background-color: #ffffff;

        margin-top: -170px;
        margin-left: -400px;

        z-index: -0.8;
      }

      #second-circle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: visible;
        position: absolute;

        width: 408px;
        height: 408px;
        opacity: 0.06;

        border-radius: 50%;
        background-color: #ffffff;

        margin-top: -43px;
        margin-left: -285px;

        z-index: -0.8;
      }

      #third-circle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        position: absolute;
        overflow: visible;
        width: 192px;
        height: 192px;
        opacity: 0.06;

        border-radius: 50%;
        background-color: #ffffff;

        margin-top: 75px;
        margin-left: -180px;

        z-index: -0.8;
      }
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 65vh;

    #second-content-header {
      width: 100%;
      margin-left: 5vw;

      .info-project {
        width: 100%;
        margin-right: 0;
        #title {
          font-size: 1.3rem;
        }

        .info-project-content {
          display: flex;
          flex-direction: column;
          margin-left: 2.5vw;

          .first-content,
          .second-content {
            div {
              p {
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      .circles {
        display: none;
      }
    }

    #img-header {
      display: none;
      margin-left: 0;
    }
  }
`;

export const ChatContent = styled.section`
  margin-top: 100px;
  width: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  z-index: 1;
`;

export const Subscribe = styled.section`
  margin-top: -60px;
  margin-bottom: 10px;
  width: 100%;
  height: 642px;
  background-color: #227af1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  #donation-content-1 {
    width: 352px;
    height: 243px;
    position: relative;
    margin-left: 130px;
    margin-top: 80px;

    #donation-text-1 {
      margin-bottom: 24px;

      font-size: 46px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    #donation-text-2 {
      width: 444px;

      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: relative;

      z-index: 1;

      width: 352px;
      height: 60px;
      border-radius: 30px;
      -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
      box-shadow: 0 0 7.5px 0 #45c6e5;
      background: rgb(106, 243, 255);
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(32%, rgba(106, 243, 255, 1)),
        to(rgba(138, 238, 197, 1))
      );
      background: -o-linear-gradient(
        left,
        rgba(106, 243, 255, 1) 32%,
        rgba(138, 238, 197, 1) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(106, 243, 255, 1) 32%,
        rgba(138, 238, 197, 1) 100%
      );

      font-family: GothamPro;
      font-size: 15.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;

      margin-bottom: 60px;
      margin-top: 60px;

      -webkit-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background: linear-gradient(
          90deg,
          ${darken(0.1, "rgba(106,243,255,1)")} 32%,
          ${darken(0.1, "rgba(138,238,197,1)")} 141%
        );
      }
    }

    #first-circle-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: absolute;
      overflow: visible;
      width: 654px;
      height: 654px;
      opacity: 0.05;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -420px;
      margin-left: -300px;

      z-index: -0.8;
    }

    #second-circle-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: visible;
      position: absolute;

      width: 418px;
      height: 418px;
      opacity: 0.06;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -303px;
      margin-left: -185px;

      z-index: -0.8;
    }

    #third-circle-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: absolute;
      overflow: visible;
      width: 212px;
      height: 212px;
      opacity: 0.06;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -195px;
      margin-left: -80px;

      z-index: -0.8;
    }
  }

  #donation-content-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    margin-top: 155px;
    margin-right: 162px;

    #title-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: -70px;

      #text-title-donation {
        margin-top: 12px;
        margin-left: 10px;

        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.5;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
    }

    #donation-image-content-2 {
      display: block;
      position: absolute;
      overflow: visible;
      margin-top: 178px;
      margin-left: 444.49px;

      width: 246px;
      height: 404px;
    }
  }

  #img-project {
    align-self: flex-end;
  }

  @media only screen and (max-width: 550px) {
    width: 100vw;
    height: 105vh;
    margin: 0;

    margin-top: 25vh;

    #donation-content-1 {
      width: 80%;
      margin-left: 10%;

      > img {
        height: 5vh;
      }

      #donation-text-1 {
        font-size: 1.4rem;
      }
      #donation-text-2 {
        width: 100%;
        word-wrap: break-word;
        text-align: justify;
        font-size: 1rem;
      }
      button {
        width: 100%;
      }
    }

    #img-project {
      display: none;
    }
  }
`;

export const Projects = styled.section`
  margin-top: 50px;
  margin-bottom: 150px;
  width: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  section {
    margin-top: 100px;
    margin-bottom: 20px;

    display: flex;
    align-self: center;

    width: 76%;
    height: 2px;
    background: #eee;
  }

  #projects {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .title-projects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 7%;

    @media (min-width: 1300px) {
      margin-left: 11%;
    }

    img {
      margin-bottom: 10px;
    }

    p {
      font-size: 50px;
      color: #227af1;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;

    #projects {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: unset;
      align-items: center;

      margin-left: 5%;
    }

    .title-projects {
      img {
        height: 5vh;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
`;

export const AboutProject = styled.section`
  display: flex;
  flex-direction: row;

  .first-content-about {
    width: 628px;
    margin-right: 108px;

    .title-chat {
      display: flex;

      #title-content-chat {
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.33;
        letter-spacing: normal;
        color: #227af1;

        margin-left: 16.2px;
      }
    }

    .old,
    .now {
      margin-top: 30px;

      #title-box {
        color: #7f7fb9;
        font-size: 18;
      }
    }

    hr {
      width: 100%;
      height: 0.1;
      opacity: 0.08;

      margin-top: 20px;
      margin-bottom: 20px;
      border: dashed 0.1px #707070;
    }

    .messages {
      margin-top: 12px;
      padding-top: 30px;
    }

    .send-message {
      #section-send-msg {
        width: 100%;
        height: 2px;
        background: #eee;
        opacity: 0.56;
      }
    }

    #section-first-content {
      margin-top: 40px;
      margin-bottom: 18px;

      width: 100%;
      height: 2px;
      background: #eee;
    }

    #prev-button {
      width: 168px;
      height: 60px;
      border-radius: 30px;
      border: solid 2px #2976fa;
      background: transparent;

      float: right;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #227af1;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background: ${darken(0.035, "#FFF")};
      }
    }
    .finish-challenge {
      margin-top: 43px;
      margin-bottom: 50px;

      height: 500px;

      #title-finish-challenge {
        font-size: 26px;
        text-align: left;
        color: #227af1;
      }
      #text-finish-challenge {
        font-size: 14px;
        font-family: GothamProRegular;
        line-height: 2;
        text-align: left;
        color: #000000;
      }

      form {
        input {
          width: 628px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.4px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;
          padding-left: 33.6px;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-top: 12px;

          &::-moz-placeholder {
            opacity: 1;
          }
          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }
        textarea {
          width: 628px;
          height: 179px;
          border-radius: 15px;
          border: solid 1px #7f7ebe;
          outline: none;
          resize: none;
          overflow: hidden;
          padding-top: 19px;
          padding-left: 33.6px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #7f7fb9;

          &::placeholder {
            clear: left;
            flex-wrap: wrap;
            color: #7f7fb9;
          }
          &::-moz-placeholder {
            opacity: 1;
          }
        }
        #btn-finish {
          width: 260px;
          height: 60px;

          border-radius: 30px;
          border: solid 2px #2976fa;

          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          text-align: center;
          color: #227af1;

          background: transparent;

          float: right;

          margin-top: 18px;

          transition: all 0.3s ease-in-out 0s;
          /* Webkits */
          -webkit-transition: all 0.3s ease-in-out 0s;
          -o-transition: all 0.3s ease-in-out 0s;
          /* Webkits */
          &:hover {
            background-color: ${darken(0.1, "#fff")};
          }
        }
      }
    }
  }

  .second-content-about {
    width: 352px;
  }

  .buttons-content-about {
    width: 100%;
    height: 60px;

    border-radius: 30px;
    -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
    box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
    border: solid 2px rgba(255, 255, 255, 0.41);

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 7px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    flex-direction: column !important;
    overflow-x: hidden;

    .first-content-about {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 7vh;

      .title-chat {
        width: 100%;

        margin-left: 5vw;

        img {
          height: 5vh;
        }

        #title-content-chat {
          font-size: 0.9rem;
        }
      }

      .old,
      .now {
        width: 100%;

        #title-box {
          font-size: 1.2rem;
          margin-left: 5vw;
        }
      }

      .now {
        .send-message {
          width: 90%;
        }
      }

      > p {
        margin-left: 5%;
        font-size: 1.2rem;
      }

      #img-content-about {
        width: 90%;
        margin-left: 5%;
      }

      .buttons-content-about {
        width: 90%;
        margin-left: 5%;
      }

      #prev-button {
        margin-right: 5vw;
      }

      .finish-challenge {
        width: 90%;

        margin-left: 5%;

        #title-finish-challenge {
          font-size: 1.2rem;
        }
        #text-finish-challenge {
          font-size: 0.9rem;
        }

        form {
          width: 100%;
          input {
            width: 90%;
          }
          textarea {
            width: 90%;
          }
          #btn-finish {
            width: 40%;
            margin-right: 5%;
          }
        }
      }
    }

    .second-content-about {
      width: 100%;
    }
  }
`;

export const Donation = styled.div`
  width: 352px;
  height: 469px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border-radius: 10px;
  border: solid 2px rgba(255, 255, 255, 0.41);

  margin-right: 17px;

  z-index: 1;
  position: relative;

  #title-donation {
    display: flex;
    align-items: center;

    margin-left: 33px;
    margin-top: 10px;

    > img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    > p {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.86;
      letter-spacing: normal;
      color: #227af1;
    }
  }

  section {
    margin-top: 20px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }

  ul {
    margin-top: 17.7px;
    #list-people-donation {
      margin-left: 33px;
      margin-bottom: 25px;

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;

      #position-people {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        margin-right: 20px;

        width: 50.5px;
        height: 50.5px;
        border-radius: 5px;
        border: solid 1px #227af1;

        p {
          -webkit-text-stroke: 0.5px #227af1;
          font-size: 28px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.86;
          letter-spacing: normal;
          color: #227af1;

          margin-left: 6px;
          margin-top: 10px;
        }
      }

      #data-people {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        > p {
          margin-top: 2px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
          word-break: normal;
        }

        div {
          display: flex;
          display: -webkit-box;
          display: -ms-flexbox;
          #cash {
            margin-top: 5px;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }

          #dot {
            margin-top: 8px;
            margin-left: 11px;

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;
  }
`;


export const WhuffieCompany = styled.div`
  margin-top: 20px;

  width: 352px;
  height: 202px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border: solid 2px rgba(255, 255, 255, 0.41);

  margin-right: 17px;

  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    font-size: 19.5px;
    color: #227af1;
    margin-top: 20px;
  }

  section {
    margin-top: 20px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
  .values {
    margin-left: 36px;
    margin-top: 28.6px;
    #values-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    #values-cash {
      margin-top: 10px;

      font-size: 26px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    > p {
      font-size: 1rem;
    }

    .values {
      margin-left: 0;

      #values-text {
        font-size: 0.7rem;
      }
      #values-cash {
        font-size: 1.1rem;
      }
    }
  }
`;

export const AboutContent = styled.section`
  #title {
    color: #227af1;
    font-size: 35px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-family: GothamProRegular;
    font-size: 15.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
    width: 90% !important;
    margin-left: 5%;

    #title-about {
      font-size: 1.6rem;
    }

    .content-what-is {
      width: 100%;

      #title-content-what-is {
        font-size: 1.2rem;
      }
    }

    .galery {
      width: 90%;

      margin-bottom: 10vh;

      .images {
        width: 100%;
      }
    }

    .links {
      width: 100%;
      #title {
        font-size: 1.6rem;
      }

      .link {
        width: 80%;
        a {
          width: 80vw;

          font-size: 0.75rem !important;
        }
      }
    }

    .development-team {
      width: 100%;
    }

    .content-denounce {
      width: 100%;

      #denounce {
        display: flex;
        align-items: center;
        margin-top: 36px;

        p {
          width: 65%;
        }

        button {
          width: 30%;

          font-size: 1rem;
        }
      }
    }
  }
`;

export const Message = styled.div`
  width: 536px;
  height: 201px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  margin-bottom: 17px;
  margin-top: 20px;
  margin-left: 10px;

  display: flex;

  ${(props) =>
    props.sent &&
    css`
      background-color: #fafaff;

      margin-left: 97px;
    `}

  padding-top:20px;
  padding-left: 23px;

  .icon-people {
    .box {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 20px;

      width: 70px;
      height: 70px;
      opacity: 0.31;
      border: solid 2px #7f7ebe;
      background: transparent;
      border-radius: 50%;

      #border {
        width: 54px;
        height: 54px;
        background-color: #7f7fb9;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .content-message {
    margin-top: 18px;

    #day,
    #name {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;

      margin-bottom: 8px;
    }

    #text {
      margin-top: 10px;

      width: 376px;

      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 80vw;

    .content-message {
      width: 100%;

      #text {
        width: 90%;
        font-size: 0.7rem;
        word-wrap: break-word;
      }
    }

    ${(props) =>
      props.sent &&
      css`
        margin-left: 10vw;
      `}
  }
`;
