import React from "react";

import Header from "./containers/Header";
import First from "./containers/First";
import Second from "./containers/Second";
import Third from "./containers/Third";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

function Company() {
  return (
    <>
      <Helmet>
        <title>Empresas</title>
      </Helmet>
      <Header />
      <First />
      <Second />
      <Third />
      <Footer />
    </>
  );
}

export default Company;
