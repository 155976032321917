import React from "react";

import { Container, TitleBox, Content } from "./styles";

import iconEmail from "../../../../assets/icons/Blog/email-icon.svg";
import { toast } from "react-toastify";
import { store } from "../../../../store";
import api from "../../../../services/api";
import { useHistory } from "react-router-dom";

function NewsletterCard({ subscriveNewsletter }) {
  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const history = useHistory();

  async function newsletterSubmit() {
    if (!token) {
      toast.error("É necessário estar logado!");
    } else {
      try {
        const data = Object.assign({ user: profile.id, profile: perfil });

        await api.post("newsletter", data);
        toast.success("Cadastrado no newsletter!");
      } catch (error) {
        toast.error("Erro ao cadastrar no newsletter!");
      }
    }
  }

  async function deleteNewsletter() {
    if (!token) {
      toast.error("É necessário estar logado!");
    } else {
      try {
        const type =
          perfil === "Apoiador/Visitante"
            ? 1
            : perfil === "Apoiador Empresarial/Desafiante"
            ? 2
            : 3;
        await api.delete(`newsletter/${profile && profile.id}/${type}`);
        toast.success("Você não será mais notificado!");
      } catch (error) {
        toast.error("Erro ao desinscrever no newsletter!");
      }
    }
  }

  function handleClick() {
    if (subscriveNewsletter === false) {
      newsletterSubmit();
      setTimeout(()=>{
        history.go(0);
      },1000)
     
    } else {
      deleteNewsletter();
      setTimeout(()=>{
        history.go(0);
      },1000)
    }
  }

  return (
    <Container>
      <TitleBox className="title-newsletter">
        <img src={iconEmail} alt="" />
        <p id="title">Newsletter</p>
      </TitleBox>
      <Content>
        <p className="normal-text">
          {subscriveNewsletter === false
            ? "Acompanhe pelas notificações as novidades que rolam na We4Scientce e conteúdos sobre Ciência produzidos por nossos parceiros!"
            : "Deseja não ser mais notificado pelo Newsletter?"}
        </p>
        <button className="button" type="button" onClick={() => handleClick()}>
          {subscriveNewsletter === false ? "Cadastrar" : "Desinscrever"}
        </button>
      </Content>
    </Container>
  );
}

export default NewsletterCard;
