import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 90px 0;
  background: #227af1;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  z-index: 1;
  flex: 1;

  max-width: 100%;
  flex-basis: 100%;

  h1 {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-family: GothamProRegular;
    font-size: 19.5px;
    line-height: 1.88;
    text-align: center;

    color: #fff;
  }

  @media screen and (max-width: 806px){
    h1 {
      font-size: 1.5rem;
    }
    p {
      font-size: 15px;
      text-align: justify;
    }
    
  }
`;
