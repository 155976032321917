import React, { useCallback, useState } from "react";

// Libraries
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Textarea } from "@rocketseat/unform";
import { Fade } from "react-reveal";
import { Scrollbars } from "react-custom-scrollbars";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import { FormattedNumber, IntlProvider } from "react-intl";

// Reducer Modules
import { signOut } from "../../store/modules/auth/actions";
import { updateProfileRequest } from "../../store/modules/user/actions";

// Redux-Store
import { store } from "../../store";

// Components
import Footer from "../../components/Footer";
import AvatarInput from "../../components/AvatarInput";

import { FaLockOpen } from "react-icons/fa";
// Styled Components
import {
  Wrapper,
  Content,
  BoxProfile,
  ProfileContent,
  MenuList,
  EditComponent,
  DonationsComponent,
  ChallengeComponent,
  CollaborationsComponent,
  HistoricComponent,
  ShoppingComponent,
  Product,
  Project,
  ProjectSecond,
  Challenge,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  DropDownListContainerState,
  ListItem,
  ButtonDonation,
  Cosmos,
  AboutCompanyComponent,
} from "./styles";

// Icons
import iconPerfil from "../../assets/icons/Profile/icon-pencil.svg";
import carStore from "../../assets/icons/Common/car-store.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";
import iconAvatarInput from "../../assets/icons/Common/avatar-input.svg";
import iconDrop from "../../assets/icons/Common/drop-reg.svg";
// Icons from the other Foulders
import iconPencil from "../../assets/icons/Register/pencil.svg";
import iconBlade from "../../assets/icons/Challenges/first.svg";
import iconCalendarPurple from "../../assets/icons/Project/calendar-purple.svg";
import iconCheck from "../../assets/icons/Projects/check.svg";
import iconCompanyProject from "../../assets/icons/Projects/company.svg";
import iconProducts from "../../assets/icons/Common/store-2.svg";
import iconProfile from "../../assets/icons/Profile/profiles/support.svg";

import { FaPhone, FaEnvelope } from "react-icons/fa";
import id from "../../assets/icons/Project/idBlue.svg";
import user from "../../assets/icons/Donors/user.svg";
import logo from "../../assets/images/Common/logo.svg";

import { useEffect } from "react";
import api from "../../services/api";
import Pagination from "../../components/Pagination";

import Header from "./Header";
import { Helmet } from "react-helmet";

function SupporterProfile() {
  const [edit, setEdit] = useState(() => true);
  const [donations, setDonations] = useState(() => false);
  const [challenges, setChallenges] = useState(() => false);
  const [collaborations, setCollaborations] = useState(() => false);
  const [historic, setHistoric] = useState(() => false);
  const [shopping, setShopping] = useState(() => false);

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const { perfil, token } = store.getState().auth;

  const [allMyChallenges, setAllMyChallenges] = useState([]);
  const [cosmos, setCosmos] = useState([]);
  const [extracts, setExtracts] = useState([]);
  const [myCollaborations, setMyCollaborations] = useState([]);

  const [myShoppings, setMyShoppings] = useState([]);

  useEffect(() => {
    const loadMyCollaborations = async () => {
      if (!!token) {
        const res = await api.get(
          `/collaborations/${profile && profile.id}/${1}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyCollaborations(data);
      }
    };
    loadMyCollaborations();
  }, [profile, token]);

  useEffect(() => {
    const loadMyShoppings = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-shoppings/${profile && profile.id}/${1}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyShoppings(data);
      }
    };
    loadMyShoppings();
  }, [profile, token]);

  useEffect(() => {
    const loadChallenges = async () => {
      if (!!token) {
        const resOne = await api.get(
          `/doing-supporters/${profile && profile.id}`
        );

        const resTwo = await api.get(
          `/sended-supporters/${profile && profile.id}`
        );

        var auxAll = [];

        for (let index = 0; index < resOne.data.length; index++) {
          if (
            resOne &&
            auxAll.filter(
              (item) => item.challenge_id === resOne.data[index].challenge_id
            ).length <= 0
          ) {
            auxAll.push(resOne.data[index]);
          }
        }

        for (let index = 0; index < resTwo.data.length; index++) {
          if (
            resTwo &&
            auxAll.filter(
              (item) => item.challenge_id === resTwo.data[index].challenge_id
            ).length <= 0
          ) {
            auxAll.push(resTwo.data[index]);
          }
        }

        setAllMyChallenges(auxAll);
      }
    };
    loadChallenges();
  }, [profile, token]);

  const [myDonations, setMyDonations] = useState([]);

  useEffect(() => {
    const loadMyDonations = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-donations/${profile && profile.id}/${1}`
        );

        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyDonations(data);
      }
    };
    loadMyDonations();
  }, [profile, token]);

  useEffect(() => {
    const loadMyDonations = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-donations-cosmos/${profile && profile.id}/${1}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setCosmos(data);
      }
    };
    loadMyDonations();
  }, [profile, token]);

  useEffect(() => {
    const loadMyDonations = async () => {
      if (!!token) {
        const res = await api.get(`/extracts/${profile && profile.id}/${1}`);
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setExtracts(data);
      }
    };
    loadMyDonations();
  }, [profile, token]);

  function handleEditClick() {
    if (edit === false) {
      setEdit(!edit);

      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleDonationsClick() {
    if (donations === false) {
      setDonations(!donations);

      if (edit === true) {
        setEdit(!edit);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleChallengesClick() {
    if (challenges === false) {
      setChallenges(!challenges);

      if (donations === true) {
        setDonations(!donations);
      }
      if (edit === true) {
        setEdit(!edit);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleCollaborationsClick() {
    if (collaborations === false) {
      setCollaborations(!collaborations);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleHistoricClick() {
    if (historic === false) {
      setHistoric(!historic);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleShoppingClick() {
    if (shopping === false) {
      setShopping(!shopping);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  let history = useHistory();

  function handleSignOut() {
    dispatch(signOut());
    setTimeout(function () {
      history.push("/register");
    }, 1000);
  }

  function handleSubmit(data) {
    data.perfil = perfil;

    if (selectedOption) {
      data.sex = selectedOption;
    }

    if (selectedOptionState) {
      data.state = selectedOptionState;
    }

    try {
      dispatch(updateProfileRequest(data));
    } catch (error) {
      console.log(error);
    }
  }

  const optionsStates = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const optionsSex = ["Feminino", "Masculino", "Outro", "Não Informar"];

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenState, setIsOpenState] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionState, setSelectedOptionState] = useState(null);

  const toggling = () => setIsOpen(!isOpen);
  const togglingState = () => setIsOpenState(!isOpenState);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const onOptionClickedState = (value) => () => {
    setSelectedOptionState(value);
    setIsOpenState(false);
  };

  const [currentPageDepositions, setCurrentPageDepositions] = useState(1);
  const [countriesPerPageDepositions] = useState(4);

  const paginateDepositions = (number) => setCurrentPageDepositions(number);

  const indexLastCountriesDepositions =
    currentPageDepositions * countriesPerPageDepositions;
  const indexFirstCountriesDepositions =
    indexLastCountriesDepositions - countriesPerPageDepositions;
  const currentCountriesDepositions = allMyChallenges.slice(
    indexFirstCountriesDepositions,
    indexLastCountriesDepositions
  );

  const [currentPageMyDonatios, setCurrentPageMyDonatios] = useState(1);
  const [countriesPerPageMyDonatios] = useState(4);

  const paginateMyDonatios = (number) => setCurrentPageMyDonatios(number);

  const indexLastMyDonatios =
    currentPageMyDonatios * countriesPerPageMyDonatios;
  const indexFirstMyDonatios = indexLastMyDonatios - countriesPerPageMyDonatios;
  const currentMyDonatios = myDonations.slice(
    indexFirstMyDonatios,
    indexLastMyDonatios
  );

  const [btnProjectDonations, setBtnProjectDonations] = useState(true);
  const [btnCosmosDonations, setBtnCosmosDonations] = useState(false);

  const toggleBtnProject = () => {
    if (!btnProjectDonations && btnCosmosDonations) {
      setBtnProjectDonations(true);
      setBtnCosmosDonations(false);
    }
  };

  const toggleBtnCosmos = () => {
    if (!btnCosmosDonations && btnProjectDonations) {
      setBtnCosmosDonations(true);
      setBtnProjectDonations(false);
    }
  };

  const [currentPageMyDonatiosCosmos, setCurrentPageMyDonatiosCosmos] =
    useState(1);
  const [countriesPerPageMyDonatiosCosmos] = useState(4);

  const paginateMyDonatiosCosmos = (number) =>
    setCurrentPageMyDonatiosCosmos(number);

  const indexLastMyDonatiosCosmos =
    currentPageMyDonatiosCosmos * countriesPerPageMyDonatiosCosmos;
  const indexFirstMyDonatiosCosmos =
    indexLastMyDonatiosCosmos - countriesPerPageMyDonatiosCosmos;
  const currentMyDonatiosCosmos = cosmos.slice(
    indexFirstMyDonatiosCosmos,
    indexLastMyDonatiosCosmos
  );

  const [currentPageExtracts, setCurrentPageExtracts] = useState(1);
  const [countriesPerPageExtracts] = useState(8);

  const paginateExtracts = (number) => setCurrentPageExtracts(number);

  const indexLastExtracts = currentPageExtracts * countriesPerPageExtracts;
  const indexFirstExtracts = indexLastExtracts - countriesPerPageExtracts;
  const currentExtracts = extracts.slice(indexFirstExtracts, indexLastExtracts);

  /*    PAGINATION USER COLLABORATIONS       */

  const [currentPageCollaborations, setCurrentPageCollaborations] = useState(1);
  const [countriesPerPageCollaborations] = useState(4);

  const paginateCollaborations = (number) =>
    setCurrentPageCollaborations(number);

  const indexLastCollaborations =
    currentPageCollaborations * countriesPerPageCollaborations;
  const indexFirstCollaborations =
    indexLastCollaborations - countriesPerPageCollaborations;
  const currentCollaborations = myCollaborations.slice(
    indexFirstCollaborations,
    indexLastCollaborations
  );

  /*    PAGINATION USER MY SHOPPINGS       */

  const [currentPageShoppings, setCurrentPageShoppings] = useState(1);
  const [countriesPerPageShoppings] = useState(4);

  const paginateShoppings = (number) => setCurrentPageShoppings(number);

  const indexLastShoppings = currentPageShoppings * countriesPerPageShoppings;
  const indexFirstShoppings = indexLastShoppings - countriesPerPageShoppings;
  const currentShoppings = myShoppings.slice(
    indexFirstShoppings,
    indexLastShoppings
  );

  const [aboutCompany, setAboutCompany] = useState(false);
  const [companyInfos, setCompanyInfos] = useState(null);

  const toggle = () => setAboutCompany(true);

  const [content, setContent] = useState(
    profile && profile.curriculum !== null ? profile.curriculum : ""
  );

  const setFormattedContent = useCallback(
    (text) => {
      setContent(text.slice(0, 145));
    },
    [setContent]
  );

  return (
    <>
      <Helmet>
        <title>Perfil Apoiador / Visitante</title>
      </Helmet>
      <Wrapper>
        <Content>
          <Header />
          <ProfileContent>
            <aside>
              <BoxProfile>
                <img src={iconProfile} alt="" />
                <div>
                  <p id="title">Perfil</p>
                  <p id="normal">Apoiador / Visitante</p>
                </div>
              </BoxProfile>

              <div className="avatar">
                <div className="avatar-img">
                  <div className="border">
                    <div className="img-content">
                      {profile && profile.avatar !== null ? (
                        <img
                          id="img-profile"
                          src={profile && profile.avatar.url}
                          alt=""
                        />
                      ) : (
                        <img
                          id="without-img-profile"
                          src={iconAvatarInput}
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  <div className="pencil">
                    <img src={iconPerfil} alt="" />
                  </div>
                </div>

                <hr />
                <div className="infos">
                  {profile && <p>{profile.name.split(" ")[0]}</p>}

                  {profile && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <IntlProvider locale="pt-BR">
                          <p>
                            <FormattedNumber
                              minimumFractionDigits={2}
                              value={profile.whuffie}
                            />
                          </p>
                        </IntlProvider>
                        <p id="whuffie" style={{ marginLeft: 5 }}>
                          W$
                        </p>
                      </div>
                      <p style={{ fontStyle: "italic", color: "#ffe" }}>
                        Saldo Whuffie
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="menu-profile">
                <div className="menu">
                  <MenuList active={edit} onClick={handleEditClick}>
                    Editar Dados Pessoais
                  </MenuList>
                  <MenuList active={donations} onClick={handleDonationsClick}>
                    Minhas Doações
                  </MenuList>
                  <MenuList active={challenges} onClick={handleChallengesClick}>
                    Meus Desafios
                  </MenuList>
                  <MenuList
                    active={collaborations}
                    onClick={handleCollaborationsClick}
                  >
                    Minhas Colaborações
                  </MenuList>
                  <MenuList active={historic} onClick={handleHistoricClick}>
                    Minhas Moedas Whuffie
                  </MenuList>
                  <MenuList
                    active={shopping || aboutCompany}
                    onClick={handleShoppingClick}
                  >
                    Minhas Compras Whuffie
                  </MenuList>
                  <MenuList
                    onClick={() => {
                      setTimeout(() => {
                        history.push("contact?&type=support");
                      }, 300);
                    }}
                  >
                    Suporte W4S
                  </MenuList>
                </div>
              </div>

              <button id="btn-company-profile" onClick={handleSignOut}>
                Cadastrar em outro Perfil / Sair
              </button>
              <button
                id="btn-company-profile"
                onClick={() => {
                  history.push("delete-profile");
                }}
              >
                Excluir Perfil
              </button>
            </aside>

            {edit && (
              <Fade left duration={1600} distance="20px">
                <EditComponent>
                  <p id="title">Editar dados pessoais</p>
                  <p className="text">
                    Aqui você pode editar suas informações pessoais e
                    preferências sempre que quiser!
                    <br />E lembre-se: se surgir alguma dúvida, consulte o FAQ
                    ou envie uma mensagem para nós pela página "Fale Conosco".
                  </p>

                  <div className="form">
                    <Form initialData={profile} onSubmit={handleSubmit}>
                      <p id="title">Dados Pessoais</p>
                      <div className="avatar">
                        <AvatarInput name="avatar_id" />
                        <div className="pencil">
                          <img src={iconPencil} alt="" />
                        </div>
                        <p id="input-photo">Inserir foto de perfil</p>
                      </div>
                      <p style={{ color: "#7f7fb9", fontSize: "0.9rem" }}>
                        obs: utilize uma imagem com pelo menos 112x112 pixels
                      </p>

                      <div className="inputs">
                        <Input type="text" placeholder="Nome: *" name="name" />
                        <DropDownContainer>
                          <DropDownHeader id="small" onClick={toggling}>
                            <p>
                              {selectedOption ||
                                (profile && profile.sex != null
                                  ? profile.sex
                                  : "sexo")}
                            </p>
                            {isOpen ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpen && (
                            <DropDownListContainer>
                              <DropDownList>
                                <Scrollbars style={{ flex: 1, height: 50 }}>
                                  {optionsSex.map((option) => (
                                    <ListItem
                                      onClick={onOptionClicked(option)}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>
                        <Input
                          type="text"
                          placeholder="Telefone: * (ddd) 1234-5678"
                          name="phone"
                        />
                        <Input
                          type="text"
                          placeholder="Whatsapp: (ddd) 1234-5678"
                          name="whatsapp"
                        />

                        <div className="box-obs-social">
                          <p>
                            Você deve preencher suas redes sociais com o link
                            completo, por exemplo:
                            <br />
                            https://www.facebook.com/We4Science/
                          </p>
                        </div>

                        <Input
                          type="text"
                          placeholder="Seu melhor e-mail: *"
                          name="email"
                        />
                        <Input
                          type="text"
                          placeholder="Seu Facebook:"
                          name="facebook"
                        />
                        <Input
                          type="text"
                          placeholder="Seu linkedin:"
                          name="linkedin"
                        />
                        <Input
                          type="text"
                          placeholder="Seu twitter:"
                          name="twitter"
                        />
                        <Input
                          type="text"
                          placeholder="Seu instagram:"
                          name="instagram"
                        />
                        <Input type="text" placeholder="CPF:" name="cpf" />
                        <div className="box-security-datas">
                          <p>
                            <strong>Não se preocupe!</strong>
                            <br />
                            <br /> A We4Science não compartilha seus documentos
                            e informações pessoais, como CPF, endereço e
                            telefone de contato.
                          </p>
                        </div>
                        <div className="id">
                          <p>ID do usuário: {profile && profile.id}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="curriculum-data">
                        <p>Dados curriculares</p>
                        <div className="inputs-curriculum">
                          <Input
                            type="text"
                            placeholder="Profissão"
                            name="profession"
                          />
                          <Input
                            type="text"
                            placeholder="Empresa/instituição:"
                            maxLength={60}
                            name="company"
                          />
                          <Input
                            type="text"
                            placeholder="Nivel de escolaridade:"
                            name="schooling"
                          />
                          <Input
                            type="text"
                            className="full-link-curriculum"
                            placeholder="Link completo para plataforma de currículo: (Lattes, etc)"
                            name="link_curriculum"
                          />
                          <Textarea
                            name="curriculum"
                            className="curriculum"
                            type="text"
                            placeholder="Mini currículo:"
                            maxLength={145}
                            onChange={(event) =>
                              setFormattedContent(event.target.value)
                            }
                            value={content}
                          />
                          <p style={{ fontSize: 16, marginTop: 20 }}>
                            {content.length} / {145} Caracteres
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="adress">
                        <p>Endereço</p>

                        <div className="inputs-adress">
                          <Input
                            type="text"
                            placeholder="Logradouro:"
                            name="adress"
                          />
                          <Input
                            type="text"
                            placeholder="Bairro"
                            name="neighborhood"
                          />
                          <DropDownContainer>
                            <DropDownHeader
                              className="estado"
                              onClick={togglingState}
                            >
                              <p id="text-state" className="small-text">
                                {selectedOptionState ||
                                  (profile && profile.state != null
                                    ? profile.state
                                    : "Estado")}
                              </p>
                              {isOpenState ? (
                                <img
                                  className="icon-drop"
                                  id="up-icon"
                                  src={iconDrop}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="icon-drop"
                                  src={iconDrop}
                                  alt=""
                                />
                              )}
                            </DropDownHeader>
                            {isOpenState && (
                              <DropDownListContainerState className="state-input">
                                <DropDownList>
                                  <Scrollbars
                                    style={{ width: 145, height: 60 }}
                                  >
                                    {optionsStates.map((option) => (
                                      <ListItem
                                        small
                                        onClick={onOptionClickedState(option)}
                                        key={Math.random()}
                                      >
                                        {option}
                                      </ListItem>
                                    ))}
                                  </Scrollbars>
                                </DropDownList>
                              </DropDownListContainerState>
                            )}
                          </DropDownContainer>
                          <Input
                            type="text"
                            placeholder="Cidade"
                            className="city"
                            name="city"
                          />
                          <Input
                            type="text"
                            placeholder="CEP"
                            className="CEP"
                            name="cep"
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="security">
                        <p>Dados de segurança</p>
                        <div className="inputs-security">
                          <Input
                            type="password"
                            placeholder="Senha:"
                            name="password"
                          />
                          <Input
                            type="password"
                            placeholder="Confirmar Senha:"
                            name="confirmPassword"
                          />
                        </div>
                      </div>
                      <p
                        style={{
                          float: "left",
                          color: "#7f7fb9",
                          marginLeft: 20,
                          marginBottom: 10,
                        }}
                      >
                        *Campo obrigatório
                      </p>
                      <button type="submit">Salvar</button>
                    </Form>
                  </div>
                </EditComponent>
              </Fade>
            )}

            {donations && (
              <Fade left duration={1600} distance="20px">
                <DonationsComponent>
                  <p id="title">Minhas Doações</p>
                  <p className="text">
                    Aqui você consulta todas as doações que já fez em nossa
                    plataforma.
                    <br />E lembre-se: além de ajudar no desenvolvimento de
                    <Link
                      to="projects"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      projetos
                    </Link>{" "}
                    e pesquisas científicas importantes, suas doações também
                    rendem moedas Whuffie que você poderá usar para adquirir
                    produtos, serviços e experiências em nosso Mercado Whuffie.
                    Aproveite!
                  </p>
                  <div className="buttons">
                    <ButtonDonation
                      active={btnProjectDonations}
                      onClick={toggleBtnProject}
                    >
                      Projetos
                    </ButtonDonation>
                    <ButtonDonation
                      active={btnCosmosDonations}
                      onClick={toggleBtnCosmos}
                    >
                      Fundo Cosmos
                    </ButtonDonation>
                  </div>
                  {btnProjectDonations && (
                    <>
                      <div className="content" id="normal-content">
                        {myDonations &&
                          currentMyDonatios.map((preview) => (
                            <ProjectSecond key={preview.id}>
                              <div className="content-1">
                                <img
                                  src={
                                    preview.project &&
                                    preview.project.first &&
                                    preview.project.first.url
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content-2">
                                <div className="titles">
                                  <p id="title-project">
                                    {preview.project && preview.project.name}
                                  </p>
                                  {preview && preview.project.special ? (
                                    <div>
                                      <FaLockOpen color="#227af1" size={16} />

                                      <p>Tenho Permissão Especial</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <img src={iconCompanyProject} alt="" />
                                      <p>
                                        Instituição:
                                        {preview && preview.project.institution}
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <img src={iconCheck} alt="" />
                                    <p>
                                      Categoria:
                                      {preview.project &&
                                        preview.project.category}
                                    </p>
                                  </div>
                                  <section> </section>
                                </div>
                                <div className="donation">
                                  <p> Doei para este projeto: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                              <div className="content-3">
                                <img src={atomProject} alt="" />
                                <p>
                                  <Link to={`/project/${preview.project.id}`}>
                                    {" "}
                                    Ver projeto{" "}
                                  </Link>
                                </p>
                              </div>
                            </ProjectSecond>
                          ))}
                      </div>

                      <div className="floatPage">
                        {myDonations.length > 0 && (
                          <Pagination
                            currentPage={currentPageMyDonatios}
                            countriesPerPage={countriesPerPageMyDonatios}
                            totalCountries={myDonations.length}
                            paginate={paginateMyDonatios}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {btnCosmosDonations && (
                    <>
                      <div className="content" id="content-cosmos">
                        {cosmos &&
                          currentMyDonatiosCosmos.map((preview) => (
                            <Cosmos key={preview.id}>
                              <div className="content-1">
                                <img src={logo} alt="" />
                              </div>
                              <p id="title-cosmos">Fundo Cosmos</p>
                              <div className="content-2">
                                <div className="donation">
                                  <p> Doei: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                            </Cosmos>
                          ))}
                      </div>

                      <div className="floatPage">
                        {cosmos.length > 0 && (
                          <Pagination
                            currentPage={currentPageMyDonatiosCosmos}
                            countriesPerPage={countriesPerPageMyDonatiosCosmos}
                            totalCountries={cosmos.length}
                            paginate={paginateMyDonatiosCosmos}
                          />
                        )}
                      </div>
                    </>
                  )}
                </DonationsComponent>
              </Fade>
            )}

            {challenges && (
              <Fade left duration={1600} distance="20px">
                <ChallengeComponent>
                  <p id="title">Meus Desafios</p>
                  <p className="text">
                    Você é do tipo que enxerga nos desafios grandes
                    oportunidades de crescimento profissional e pessoal?
                    <br />
                    Então utilize esse espaço para acompanhar sua participação
                    nos{" "}
                    <Link
                      to="challenges"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      Desafios We4Science
                    </Link>
                    !
                  </p>
                  <div className="content">
                    {currentCountriesDepositions.map((countries, number) => (
                      <Challenge key={number}>
                        <div className="challenge-content-1">
                          <img src={countries.challenge.first.url} alt="" />
                        </div>
                        <div className="challenge-content-2">
                          <div>
                            <p id="title-challenge">
                              {countries.challenge.title}
                            </p>
                            <p id="company">
                              Empresa: {countries.challenge.company.name}
                            </p>
                            <p id="date">
                              Data limite: {countries.challenge.conclusion}
                            </p>
                            <p id="category">
                              Categoria: {countries.challenge.category}
                            </p>
                            <section></section>
                          </div>

                          <div className="challenge-price">
                            <p id="price-text">Prêmio:</p>
                            <p id="price-value">
                              {countries.challenge.premium.toLocaleString(
                                "pt-br",
                                { style: "currency", currency: "BRL" }
                              )}
                            </p>
                            {/* <p id="price-value-2">,00</p> */}
                            <p id="price-text"> W$</p>
                          </div>
                        </div>
                        <button id="challenge-content-3">
                          <img src={iconBlade} alt="" />
                          <Link to={`/challenge/${countries.challenge_id}`}>
                            <p>Ver desafio</p>
                          </Link>
                        </button>
                      </Challenge>
                    ))}
                  </div>
                  {allMyChallenges.length !== 0 && (
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPageDepositions}
                        countriesPerPage={countriesPerPageDepositions}
                        totalCountries={allMyChallenges.length}
                        paginate={paginateDepositions}
                      />
                    </div>
                  )}
                </ChallengeComponent>
              </Fade>
            )}

            {collaborations && (
              <Fade left duration={1600} distance="20px">
                <CollaborationsComponent>
                  <p id="title">Minhas Colaborações</p>
                  <p className="text">
                    Confira aqui suas colaborações!
                    <br />E por falar nisso, que tal enchermos essa página com
                    mais colaborações? A Ciência agradece!
                  </p>
                  <div className="content">
                    {myCollaborations.length > 0 &&
                      currentCollaborations.map((preview) => (
                        <Project key={preview.id}>
                          <div className="content-1">
                            <img
                              src={
                                preview.project.first &&
                                preview.project.first.url
                              }
                              alt=""
                            />
                          </div>

                          <div className="content-2">
                            <div className="titles">
                              <p id="title-project">{preview.project.name}</p>
                              {preview && preview.project.special ? (
                                <div>
                                  <FaLockOpen color="#227af1" size={16} />

                                  <p>Tenho Permissão Especial</p>
                                </div>
                              ) : (
                                <div>
                                  <img src={iconCompanyProject} alt="" />
                                  <p>
                                    Instituição:
                                    {preview && preview.project.institution}
                                  </p>
                                </div>
                              )}

                              <div>
                                <img src={iconCheck} alt="" />
                                <p> Categoria: {preview.project.category}</p>
                              </div>

                              <section></section>
                            </div>

                            <div className="resume">
                              <p id="title-resume">Resumo da colaboração</p>
                              <p id="text-content">{preview.content}</p>
                              <section></section>
                            </div>
                            <div className="data">
                              <p>Data da colaboraçao:{preview.formattedDate}</p>
                            </div>
                          </div>

                          <div className="content-3">
                            <img src={atomProject} alt="" />
                            <p>
                              <Link to={`/project/${preview.project.id}`}>
                                Ver projeto
                              </Link>
                            </p>
                          </div>
                        </Project>
                      ))}
                  </div>
                  <div className="floatPage">
                    {myCollaborations.length > 0 && (
                      <Pagination
                        currentPage={currentPageCollaborations}
                        countriesPerPage={countriesPerPageCollaborations}
                        totalCountries={myCollaborations.length}
                        paginate={paginateCollaborations}
                      />
                    )}
                  </div>
                </CollaborationsComponent>
              </Fade>
            )}

            {historic && (
              <Fade left duration={1600} distance="20px">
                <HistoricComponent>
                  <p id="title"> Minhas Moedas Whuffie</p>
                  <p className="text">
                    Aqui moeda não é dinheiro, mas é um incentivo e tanto!
                    Acompanhe seu saldo e veja o que pode adquirir em nosso
                    Mercado Whuffie!
                  </p>
                  <div className="content">
                    {extracts &&
                      currentExtracts.map((preview) => (
                        <div className="transactions" key={preview.id}>
                          <div className="title-transaction">
                            {preview.type === "cosmos" ? (
                              <p id="text-title-transaction">
                                Doação para Fundo Cosmos
                              </p>
                            ) : preview.type === "project" ? (
                              <p id="text-title-transaction">
                                Doação para projeto: {preview.project}
                              </p>
                            ) : (
                              <p id="text-title-transaction">
                                Colaboração no Projeto: {preview.project}
                              </p>
                            )}
                            <div className="data">
                              <img
                                id="icon-calendar"
                                src={iconCalendarPurple}
                                alt=""
                              />
                              <p id="day"> {preview.formattedDate}</p>
                            </div>
                          </div>
                          <p className="text"> Whuffies adquiridos:</p>
                          <div className="value">
                            <p id="big-letter">{preview.whuffies}</p>
                            <p className="small-letter"> W$ </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="floatPage">
                    {extracts.length > 0 && (
                      <Pagination
                        currentPage={currentPageExtracts}
                        countriesPerPage={countriesPerPageExtracts}
                        totalCountries={extracts.length}
                        paginate={paginateExtracts}
                      />
                    )}
                  </div>
                </HistoricComponent>
              </Fade>
            )}

            {shopping && (
              <Fade left duration={1600} distance="20px">
                <ShoppingComponent>
                  <p id="title">Minhas Compras Whuffie</p>
                  <p className="text">
                    Aqui você confere todas as suas compras realizadas no
                    Mercado Whuffie.
                    <br />
                    Lembre-se de navegar pelo nosso Mercado de vez em quando
                    para encontrar novos produtos, serviços e experiências que
                    lhe agradem!
                    <br />
                    Porém, se o seu saldo Whuffie estiver pequeno, é só
                    encontrar projetos para doar ou colaborar!
                  </p>
                  <div className="content">
                    {myShoppings.length > 0 &&
                      currentShoppings.map((preview) => (
                        <div className="product" key={preview.id}>
                          <Product>
                            <div id="product-content-1">
                              <img
                                src={
                                  preview.product.first &&
                                  preview.product.first.url
                                }
                                alt=""
                              />
                            </div>
                            <div id="product-content-2">
                              <div>
                                <p id="product-text-1-content-2">
                                  {preview.product.name}
                                </p>
                                <p id="category">
                                  Categoria: {preview.product.category}
                                </p>
                                <p id="category">
                                  Ofertante: {preview.product.company.name}
                                </p>
                                <p id="category">
                                  Status:{" "}
                                  {preview.status === true
                                    ? "Entregue"
                                    : "Pendente"}
                                </p>
                                <section></section>
                              </div>

                              <div id="product-price">
                                <p id="product-text-3-content-2">
                                  {preview.product.value}
                                </p>
                                <p id="product-text-4-content-2">W$</p>
                              </div>
                              <section></section>
                              <div className="data">
                                <p>Data da compra: {preview.formattedDate}</p>
                              </div>
                            </div>
                            <button id="product-content-3">
                              <img src={carStore} alt="" />
                              <Link to={`/product/${preview.product.id}`}>
                                <p>Ver produto</p>
                              </Link>
                            </button>
                          </Product>
                          <button
                            className="company-infos"
                            onClick={() => {
                              setShopping(false);
                              toggle();
                              setCompanyInfos(
                                preview &&
                                  preview.product &&
                                  preview.product.company
                              );
                            }}
                          >
                            <img src={iconProducts} alt="" /> Ofertante
                          </button>
                        </div>
                      ))}
                  </div>
                  <div className="floatPage">
                    {myShoppings.length > 0 && (
                      <Pagination
                        currentPage={currentPageShoppings}
                        countriesPerPage={countriesPerPageShoppings}
                        totalCountries={myShoppings.length}
                        paginate={paginateShoppings}
                      />
                    )}
                  </div>
                </ShoppingComponent>
              </Fade>
            )}
            {aboutCompany && (
              <Fade left duration={1600} distance="20px">
                <AboutCompanyComponent>
                  <p id="title">
                    Informações Sobre a Ampresa Ofertante do Seu Produto
                  </p>
                  <div className="infos">
                    <div className="box-img">
                      <img
                        id="avatar"
                        src={companyInfos.avatar && companyInfos.avatar.url}
                        alt=""
                      />
                    </div>

                    <div className="datas">
                      <p id="title-data">Dados</p>

                      <div className="all">
                        <div className="about">
                          <div className="info">
                            <img src={id} alt="" />
                            <p id="type">Id: </p>
                            <p>{companyInfos.id}</p>
                          </div>
                          <div className="info">
                            <img src={user} alt="" />
                            <p id="type">Nome: </p>
                            <p>{companyInfos.name}</p>
                          </div>
                          <div className="info">
                            <FaEnvelope color="#227af1" />
                            <p id="type">Email: </p>
                            <p>{companyInfos.email}</p>
                          </div>
                          <div className="info">
                            <FaPhone color="#227af1" />
                            <p id="type">Telefone: </p>
                            <p>{companyInfos.phone}</p>
                          </div>
                        </div>

                        <div className="adress">
                          <div className="adress-info">
                            <p id="type">Logradouro:</p>
                            <p>{companyInfos.adress}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Bairro:</p>
                            <p> {companyInfos.neighborhood}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Cidade: </p>
                            <p>{companyInfos.city}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">CEP:</p>
                            <p>{companyInfos.cep}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Estado: </p>
                            <p>{companyInfos.state}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AboutCompanyComponent>
              </Fade>
            )}
          </ProfileContent>

          <Footer />
        </Content>
      </Wrapper>
    </>
  );
}

export default SupporterProfile;
