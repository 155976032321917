import React,{ useState, useEffect, useMemo } from "react";
import { MdNotifications } from "react-icons/md";

import { Container, Badge } from "./styles";

import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from "../../services/api";

import { useHistory } from "react-router-dom";
import { store } from "../../store";

export default function Notifications() {
  const { perfil,signed } = store.getState().auth;
  const { profile } = store.getState().user;

  const history = useHistory();

  const [notifications, setNotifications] = useState([]);
  const hasUnread = useMemo(
    () => !!notifications.find((notification) => notification.read === false),
    [notifications]
  );

  useEffect(() => {
    async function loadNotifications() {
      if(signed){
        const type =
        perfil === "Apoiador/Visitante"
          ? 1
          : perfil === "Apoiador Empresarial/Desafiante"
          ? 2
          : 3;

      const response = await api.get(`my-notifications/${profile&&profile.id}/${type}`);

      const data = response.data.map((notification) => ({
        ...notification,
        timeDistance: formatDistance(
          parseISO(notification.createdAt),
          new Date(),
          {
            addSuffix: true,
            locale: pt,
          }
        ),
      }));

      setNotifications(data);
      }
    }
    loadNotifications();
  }, [perfil,profile,signed]);

  return (
    <Container
      onClick={() => {
        history.push('notifications')
      }}
    >
      <Badge hasUnread={hasUnread}>
        <MdNotifications color="#fff" />
      </Badge>
    </Container>
  );
}
