import React from "react";
import { Link } from "react-router-dom";

import { Container } from "./styles";

import iconHeader from "../../../../assets/icons/Challenges/first.svg";

function Third({ id }) {
  return (
    <Container>
      <img id="icon-challenge" src={iconHeader} alt="" />
      <Link id="link-challenge" to={`/challenge/${id}`}>
        Ver Desafio
      </Link>
    </Container>
  );
}

export default Third;
