import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 80px 0;
  background-color: #fafaff;
`;

export const WrapperTwo = styled.div`
  padding: 25px 0;
  background-color: #227af1;
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  flex-direction: row;

  ${(props) =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}

  .text-footer-2 {
    font-family: GothamProRegular;
    opacity: 0.65;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
  #link-fac {
    color: #fff;
    font-family: GothamPro;
    font-weight: 500;
    text-decoration: underline;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  .logo {
    max-width: 300px;
    max-height: 72px;
    margin-bottom: 50px;
  }

  .container-links-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .fs-container {
    width: 100%;

    .social-networks {
      width: 100%;

      > p {
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      > div {
        width: 100%;
        display: flex;
        align-items: center;

        margin-top: 10px;
        svg {
          margin-right: 30px;
          color: #227af1;
          -webkit-transition: all 0.3s ease-in-out 0s;
          -o-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s;

          height: 25px;
          width: 25px;

          &:hover {
            color: ${darken(0.2, "#227af1")};
          }
        }

        #small {
          height: 22px;
          width: 22px;
        }
        #big {
          height: 27px;
          width: 27px;
        }
      }
    }
  }

  button {
    width: 168px;
    height: 67px;
    border-radius: 60px;
    background-color: #227af1;
    transition: all 0.3s ease-in-out 0s;

    padding: 5px;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }

    font-size: 15.4px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  @media screen and (max-width: 900px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;

    .container-links-footer {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 566px) {
    button {
      margin-top: 10px;
      width: 100%;
    }

    .container-links-footer {
      flex-direction: column;
      align-items: center;
    }

    .fs-container {
      margin:0;
      .social-networks {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;

        margin-bottom: 30px;

        p{
          font-size: 1.6rem;
          margin-bottom: 10px;
        }
        > div {
          justify-content: space-around;
          svg {
            height: 30px;
            width: 30px;
            margin: 0;
          }

          #small {
            height: 27px;
            width: 27px;
          }
          #big {
            height: 32px;
            width: 32px;
          }
        }
      }
    }
  }
`;
export const FooterLinksItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 33.3%;
  box-sizing: border-box;
  color: #000;

  @media screen and (max-width: 566px) {
    padding: 0px;
    width: 100%;
    align-items: center;
  }
`;

export const FooterLink = styled(Link)`
  text-decoration: none;

  margin-bottom: 20px;

  font-size: 15.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;

  color: #227af1;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    color: ${darken(0.2, "#227af1")};
  }

  @media screen and (max-width: 566px) {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }
`;
