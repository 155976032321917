import React, { useEffect, useMemo, useRef, useState } from "react";

// Libraries
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Bounce } from "react-reveal";
//API
import api from "../../services/api";

//Redux
import { useDispatch } from "react-redux";
import { store } from "../../store";
import { socialNetworksRequest } from "../../store/modules/user/actions";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Project from "../../components/NewProject";

import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  ProjectContent,
  Projects,
  Subscribe,
  AboutProject,
  Donation,
  WhuffieCompany,
  TypeContent,
} from "./styles";

// External Icons
import {
  FaWhatsapp,
  FaTelegramPlane,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaEnvelope,
  FaLockOpen,
} from "react-icons/fa";

// Icons
import iconFirstType from "../../assets/icons/Collaboration/first.svg";
import iconSecondType from "../../assets/icons/Collaboration/second.svg";
import iconThirdType from "../../assets/icons/Collaboration/third.svg";
import iconFourthType from "../../assets/icons/Collaboration/fourth.svg";
import iconOtherProjects from "../../assets/icons/Common/projects-2.svg";

import projectIcon from "../../assets/icons/Common/project-icon.svg";
import donationIcon from "../../assets/icons/Common/donation-icon-2.svg";
// Icons from the other Foulder
import iconPeople from "../../assets/icons/Project/people.svg";
import iconCompany from "../../assets/icons/Project/company.svg";
import iconId from "../../assets/icons/Project/id.svg";
import iconCheck from "../../assets/icons/Project/check.svg";
import iconCalendar from "../../assets/icons/Project/calendar.svg";

// Images -> Common Foulder
import imgHeader from "../../assets/images/Project/img-header.svg";
import imgProject from "../../assets/images/Project/second.svg";

import InfoDonation from "../Project/components/InfoDonation";
import { Helmet } from "react-helmet";
import { FormattedNumber, IntlProvider } from "react-intl";

function Collaboration({ match }) {
  const textAreaRef = useRef(null);
  const dispath = useDispatch();

  const { perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    toast.success("Link copiado para área de transferência!");
  }

  const [project, setProject] = useState();
  const [loaded, setLoaded] = useState([]);
  const [totalScientistWhuffies, setTotalScientistWhuffies] = useState([]);

  const id = useMemo(
    () => ({
      value: match.params.id,
    }),
    [match.params.id]
  );

  useEffect(() => {
    async function loadingProjects() {
      try {
        const { data } = await api.get(`project/${id.value}`);
        setProject({
          ...data.project,
        });
        const res = await api.get(
          `scientists-total-whuffies/${data.project.scientist_id}`
        );
        setTotalScientistWhuffies(res.data);
      } catch (e) {
        console.log("");
      }
    }
    loadingProjects();
  }, [id]);

  useEffect(() => {
    async function loadingRichsProjects() {
      try {
        const { data } = await api.get(`rich-projects/${id.value}`);
        setLoaded(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingRichsProjects();
  }, [id]);

  const history = useHistory();

  function goBackToProject(id) {
    setTimeout(function () {
      history.push(`/project/${id}`);
    }, 50);
  }

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    async function getOtherProjects() {
      const res = await api.get(
        `other-projects/${project && project.id}/${
          project && project.category
        }/${project && project.scientist_id}`
      );

      const aux = [];

      for (let index = 0; index < 3; index++) {
        if (res.data[index] !== undefined) {
          aux.push(res.data[index]);
        }
      }
      setProjects(aux);
    }
    getOtherProjects();
  }, [id, project]);

  async function share() {
    const { perfil } = store.getState().auth;
    const { profile } = store.getState().user;

    const data = Object.assign({ perfil, id: profile.id });
    const dataCollaboration = Object.assign({
      perfil,
      user: profile.id,
      link: "rede social",
      accept: true,
      project_id: project.id,
      kind: "Social Networks",
      content: "Compartilhou o projeto nas redes sociais",
    });
    const dataExtract = Object.assign({
      perfil,
      user_id: profile.id,
      type: "collaboration",
      project: project.name,
      whuffies: 5,
    });

    await api.post("collaborations", dataCollaboration);

    await api.post("new-extract", dataExtract);

    dispath(socialNetworksRequest(data));

    toast.success("Obrigado por compartilhar!");
  }

  function changePerfilToNum() {
    if (perfil === "Apoiador/Visitante") {
      return 1;
    } else if (perfil === "Apoiador Empresarial/Desafiante") {
      return 2;
    } else if (perfil === "Cientista/Pesquisador") {
      return 3;
    }
  }

  const perfilInt = changePerfilToNum();

  return (
    <>
      <Helmet>
        <title>Colaborar com Projeto</title>
      </Helmet>
      <Bounce right duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section id="second-content-header">
                <img id="img-header" src={imgHeader} alt="" />
                <div className="info-project">
                  <p id="title">{project && project.name}</p>
                  <div className="info-project-content">
                    <div className="first-content">
                      <div>
                        <img id="people-icon" src={iconPeople} alt="" />
                        <p>Autor: {project && project.scientist.name}</p>
                      </div>
                      {project && project.special ? (
                        <div>
                          <FaLockOpen color="#fff" size={18} />

                          <p>Tenho Permissão Especial</p>
                        </div>
                      ) : (
                        <div>
                          <img src={iconCompany} alt="" />
                          <p>
                            Instituição:
                            {project && project.institution.split(";")[0]}
                          </p>
                        </div>
                      )}
                      <div>
                        <img src={iconId} alt="" />
                        <p>ID do projeto: {project && project.id}</p>
                      </div>
                    </div>
                    <div className="second-content">
                      <div>
                        <img src={iconCheck} alt="" />
                        <p>Categoria: {project && project.category}</p>
                      </div>
                      {/*
                      <div>
                        <img src={iconCalendar} alt="" />
                        <p>Data de início: {project && project.start}</p>
                      </div>
                      */}
                      <div>
                        <img src={iconCalendar} alt="" />
                        <p>Data de término: {project && project.end}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="circles">
                  <div id="first-circle"></div>
                  <div id="second-circle"></div>
                  <div id="third-circle"></div>
                </div>
              </section>
            </Header>

            <ProjectContent>
              <AboutProject>
                <div className="first-content-about">
                  <p id="title">Colaboração</p>

                  <TypeContent large={true}>
                    <div>
                      <img src={iconFirstType} alt="" />
                    </div>
                    <div className="type-content">
                      <p id="title-type-content">Divulgação em redes sociais</p>
                      <div className="box-share">
                        <div className="social-networks">
                          <WhatsappShareButton
                            onShareWindowClose={share}
                            windowPosition={"screenCenter"}
                            title={`Conheça o projeto ${
                              project && project.name
                            } na We4science`}
                            url={`https://we4science.com/project/${
                              project && project.id
                            }`}
                            separator=" - "
                          >
                            <FaWhatsapp size={23} />
                          </WhatsappShareButton>
                          <TelegramShareButton
                            onShareWindowClose={share}
                            windowPosition={"screenCenter"}
                            title={`Conheça o projeto ${
                              project && project.name
                            } na We4science`}
                            url={`https://we4science.com/project/${
                              project && project.id
                            }`}
                            separator=" - "
                          >
                            <FaTelegramPlane size={23} />
                          </TelegramShareButton>
                          <TwitterShareButton
                            onShareWindowClose={share}
                            windowPosition={"screenCenter"}
                            title={`Conheça o projeto ${
                              project && project.name
                            } na We4science`}
                            url={`https://we4science.com/project/${
                              project && project.id
                            }`}
                            separator=" - "
                          >
                            <FaTwitter size={23} />
                          </TwitterShareButton>
                          <FacebookShareButton
                            onShareWindowClose={share}
                            windowPosition={"screenCenter"}
                            title={`Conheça o projeto ${
                              project && project.name
                            } na We4science`}
                            url={`https://we4science.com/project/${
                              project && project.id
                            }`}
                            separator=" - "
                          >
                            <FaFacebookF size={20} />
                          </FacebookShareButton>
                          <LinkedinShareButton
                            onShareWindowClose={share}
                            windowPosition={"screenCenter"}
                            title={`Conheça o projeto ${
                              project && project.name
                            } na We4science`}
                            url={`https://we4science.com/project/${
                              project && project.id
                            }`}
                            separator=" - "
                            summary={project && project.summary}
                            source={`https://we4science.com/project/${id}`}
                          >
                            <FaLinkedinIn size={22} />
                          </LinkedinShareButton>
                          <EmailShareButton
                            onShareWindowClose={share}
                            windowPosition={"screenCenter"}
                            title={`Conheça o projeto ${
                              project && project.name
                            } na We4science`}
                            url={`https://we4science.com/project/${
                              project && project.id
                            }`}
                            separator=""
                            body={`Conheça o projeto ${
                              project && project.name
                            } na We4science\n${
                              project && project.summary
                            }\n Acesse em:`}
                          >
                            <FaEnvelope size={25} />
                          </EmailShareButton>
                        </div>
                        <section></section>
                        <div className="share-project">
                          <textarea
                            ref={textAreaRef}
                            value={`https://we4science.com/project/${
                              project && project.id
                            }`}
                          />
                          {
                            /* Logical shortcut for only displaying the 
                                               button if the copy command exists */
                            document.queryCommandSupported("copy") && (
                              <div>
                                <button onClick={copyToClipboard}>
                                  Copiar
                                </button>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </TypeContent>

                  <TypeContent large={false}>
                    <div>
                      <img src={iconSecondType} alt="" />
                    </div>
                    <div className="type-content">
                      <p id="title-type-content">Colaboração técnica</p>
                      <p id="text-type-content">
                        Você acredita que possui algum conhecimento ou
                        habilidade técnica que poderá somar ao desenvolvimento
                        deste projeto? Faça contato agora mesmo com a equipe
                        desenvolvedora, pois além de ajudar, você ainda
                        aumentará seu saldo de moedas Whuffie!
                      </p>
                      <Link
                        to={`/chat?id=${id.value}&type=technical&room=project:${
                          id.value
                        }-collaboration:technical-user_name:${
                          profile.name.split(" ")[0]
                        }-from:${profile.id}-perfil:${perfilInt}`}
                      >
                        <button id="contact">Entrar em contato</button>
                      </Link>
                    </div>
                  </TypeContent>

                  <TypeContent>
                    <div>
                      <img src={iconThirdType} alt="" />
                    </div>
                    <div className="type-content">
                      <p id="title-type-content">Prestação de serviços</p>
                      <p id="text-type-content">
                        Você acredita que possa prestar seus serviços
                        profissionais para o desenvolvimento desse projeto? Seja
                        como profissional contratado ou voluntário, clique
                        abaixo e converse com os responsáveis pelo projeto. Se o
                        serviço for prestado, você será recompensado com muitas
                        moedas Whuffie!
                      </p>
                      <Link
                        to={`/chat?id=${id.value}&type=service&room=project:${
                          id.value
                        }-collaboration:service-user_name:${
                          profile.name.split(" ")[0]
                        }-from:${profile.id}-perfil:${perfilInt}`}
                      >
                        <button id="contact">Entrar em contato</button>
                      </Link>
                    </div>
                  </TypeContent>

                  <TypeContent>
                    <div>
                      <img src={iconFourthType} alt="" />
                    </div>
                    <div className="type-content">
                      <p id="title-type-content">Parcerias Institucionais</p>
                      <p id="text-type-content">
                        Se você representa uma Instituição de Ensino e Pesquisa,
                        ou uma empresa privada, que tal apertarem as mãos e
                        darem início a uma grande parceria em prol da Ciência?
                        Clique abaixo para iniciarem um bate-papo sem
                        compromisso. Se a parceria for concretizada, gerará
                        moedas Whuffie para ambos!
                      </p>
                      <Link
                        to={`/chat?id=${
                          id.value
                        }&type=institutional&room=project:${
                          id.value
                        }-collaboration:institutional-user_name:${
                          profile.name.split(" ")[0]
                        }-from:${profile.id}-perfil:${perfilInt}`}
                      >
                        <button id="contact">Entrar em contato</button>
                      </Link>
                    </div>
                  </TypeContent>
                  <section id="section-first-content"></section>
                  <button
                    onClick={() => goBackToProject(project && project.id)}
                    id="prev-button"
                  >
                    Voltar
                  </button>
                </div>

                <aside id="second-content-about">
                  <InfoDonation data={project} />
                  {loaded.length > 0 && (
                    <Donation>
                      <div id="title-donation">
                        <img src={donationIcon} alt="" />
                        <p id="text-title-donation">Ranking de doações</p>
                      </div>
                      <section></section>
                      <ul>
                        {loaded.map((preview) => (
                          <li id="list-people-donation" key={preview.id}>
                            <div id="position-people">
                              <p> {loaded.indexOf(preview) + 1} º </p>
                            </div>
                            <div id="data-people">
                              <p> {preview.name} </p>
                              <div>
                                <p id="cash"> {preview.value} </p>{" "}
                                <p id="dot"> Wuffys </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Donation>
                  )}

                  {project && !project.special && (
                    <WhuffieCompany>
                      <p>Whuffies da instituição</p>
                      <section></section>
                      <div className="values">
                        <p id="values-text">
                          A instituição já acumulou em todos seus projetos:
                        </p>
                        <IntlProvider locale="pt-BR">
                          <p id="values-cash">
                            {" "}
                            W${" "}
                            <FormattedNumber
                              minimumFractionDigits={2}
                              value={totalScientistWhuffies.total}
                            />{" "}
                          </p>
                        </IntlProvider>
                      </div>
                    </WhuffieCompany>
                  )}
                </aside>
              </AboutProject>

              <Projects>
                <section></section>
                <div className="title-projects">
                  <img src={iconOtherProjects} alt="" />
                  <p>VEJA OUTROS PROJETOS</p>
                </div>
                <div id="projects">
                  {projects &&
                    projects.map((preview) => <Project data={preview} />)}
                </div>
              </Projects>

              <Subscribe>
                <div id="donation-content-1">
                  <img src={projectIcon} alt="" />
                  <p id="donation-text-1">
                    INSCREVA
                    <br /> SEU PROJETO
                  </p>
                  <p id="donation-text-2">
                    Ao inscrever um breve e simples resumo do seu projeto ou
                    pesquisa científica gratuitamente em nossa plataforma, você
                    abre um vasto horizonte para a captação de recursos
                    financeiros, intercâmbio de conhecimentos, busca de
                    parceiros institucionais nacionais e internacionais, além de
                    impulsionar sua carreira profissional!
                  </p>

                  <button>Faça uma doação agora</button>

                  <div id="first-circle-donation"> </div>
                  <div id="second-circle-donation"></div>
                  <div id="third-circle-donation"></div>
                </div>
                <img id="img-project" src={imgProject} alt="" />
              </Subscribe>
            </ProjectContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Collaboration;
