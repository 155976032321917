import React from "react";

// Pages
import Dashboard from "../DashboardAdmin";
import LoginAdmin from "../LoginAdmin";

// Redux-Store
import { store } from "../../store";

function Profile() {
    const { signed, perfil } = store.getState().auth;

    function Page() {
        if (signed && perfil === "admin") {
            return <Dashboard />;
        } else {
            return <LoginAdmin />;
        }
    }
    return Page();
}

export default Profile;
