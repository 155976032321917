import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.section`
  width: 628px;

  #title-about {
    color: #227af1;

    font-size: 49px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;

    margin-top: 20px;
  }

  .content-what-is {
    width: 628px;

    margin-top: 10px;
    margin-bottom: 13px;

    #title-content-what-is {
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    #section-content-what-is {
      width: 100%;
      height: 2px;
      background: #eee;
    }

    #text {
      width: 100%;
      word-wrap: break-word;
      white-space: pre-wrap;
      text-align: justify;
    }
  }

  .links {
    width: 628px;
    margin-top: 48px;

    #title {
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    #section-links {
      margin-top: 10px;
      margin-bottom: 30px;

      width: 100%;
      height: 2px;
      background: #eee;
    }

    .link {
      display: flex;
      align-items: center;
      margin-bottom: 27px;

      img {
        width: 22px;
        height: 22px;
      }

      a {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;

        text-decoration: underline;
      }
    }
  }

  .development-team {
    width: 628px;
    margin-top: 48px;

    #title {
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    #section-development-team {
      margin-top: 10px;
      margin-bottom: 30px;

      width: 100%;
      height: 2px;
      background: #eee;
    }
    .content-development-team {
      display: flex;
      flex-direction: column;
    }
  }

  .content-denounce {
    #title-content-what-is {
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    #section-content-what-is {
      width: 100%;
      height: 2px;
      background: #eee;
    }

    #denounce {
      display: flex;
      align-items: center;
      margin-top: 36px;

      p {
        width: 423px;
      }

      button {
        width: 168px;
        height: 60px;
        border-radius: 30px;
        border: solid 2px #2976fa;
        background: transparent;

        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #227af1;

        margin-left: 37px;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.05, "#FFF")};
        }
      }
    }
  }

  p {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-top: 13px;
  }

  .galery {
    width: 628px;
    height: 422px;

    margin-top: 33px;
    margin-bottom: 20px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      .icons {
        display: flex;
        width: 111px;
        justify-content: space-around;

        .icons-gallery {
          width: 48px;
          height: 48px;
          background-color: #fff;
          border-radius: 50%;

          transition: all 0.3s ease-in-out 0s;

          cursor: pointer;
          &:hover {
            background-color: ${darken(0.1, "#FFF")};
          }

          cursor: pointer;

          transition: all 0.3s ease-in-out 0s;

          .icon-prev {
            margin-right: 15px;
          }

          &:hover {
            color: ${darken(0.1, "#FFF")};
          }
        }
      }
    }

    .images {
      margin-top: 17px;
      width: 628px;
      height: 357px;

      border: 1px solid #eee;

      border-radius: 15px;

      img {
        width: 100%;
        height: 100%;

        border-radius: 15px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90% !important;
    margin-left: 5%;

    #title-about {
      font-size: 1.6rem;
    }

    .content-what-is {
      width: 100%;

      #title-content-what-is {
        font-size: 1.2rem;
      }
    }

    .galery {
      width: 90%;

      margin-bottom: 10vh;

      .images {
        width: 100%;
      }
    }

    .links {
      width: 100%;
      #title {
        font-size: 1.6rem;
      }

      .link {
        width: 80%;
        a {
          width: 80vw;

          font-size: 0.75rem !important;
        }
      }
    }

    .development-team {
      width: 100%;
    }

    .content-denounce {
      width: 100%;

      #title-content-what-is {
        font-size: 0.9rem;
      }

      #denounce {
        display: flex;
        align-items: center;
        margin-top: 36px;

        p {
          width: 65%;
        }

        button {
          width: 30%;

          font-size: 1rem;
        }
      }
    }
  }
`;
