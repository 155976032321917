import React from "react";

import { Container } from "./styles";
import Challenge from "../../../../components/Challenge";
import { useHistory } from "react-router";

function OtherChallenges({ data }) {
  const history = useHistory();
  return (
    <Container>
      <p id="title">Mais desafios dessa empresa</p>
      <section id="section-challenges"></section>
      <div className="chalenges">
        {data &&
          data.map((challenge) => (
            <Challenge key={challenge.id} data={challenge} />
          ))}
      </div>
      <button id="more-challenges" onClick={() => history.push("/challenges")}>
        Ver mais desafios
      </button>
    </Container>
  );
}

export default OtherChallenges;
