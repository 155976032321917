import React from "react";

import { Fade } from "react-reveal";

import { Wrapper, Content, Header, MainContent } from "./styles";

import { Link, useHistory } from "react-router-dom";

//Icons
import iconHeader from "../../assets/icons/Success/check.svg";

// Images -> Common Foulder
import imgMain from "../../assets/images/congrat.svg";

import {
  FaWhatsapp,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

export default function Congratulations() {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Projeto enviado para análise!</title>
      </Helmet>
      <Fade top duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <div className="congratulations">
                <div className="box">
                  <div className="box-img">
                    <img src={iconHeader} alt="" />
                  </div>
                </div>
                <p id="text-congrat"> Projeto enviado para análise! </p>
              </div>
              <div className="circles">
                <div id="first-circle"> </div> <div id="second-circle"> </div>
                <div id="third-circle"> </div>
              </div>
            </Header>
            <MainContent>
              <div className="content">
                <p>
                  <strong> Parabéns! </strong>
                  <br />
                  <br />
                  Seu projeto foi enviado com sucesso!
                  <br />
                  <br />
                  Por questões de segurança, em breve nossa equipe fará contato
                  com você para checarmos algumas informações antes de
                  publicarmos seu projeto na plataforma We4Science. Por essa
                  razão, verifique em seu Perfil se seus dados cadastrais estão
                  corretos, principalmente número de telefone, Whatsapp e email.
                  <br />
                  <br />
                  <strong>IMPORTANTÍSSIMO (LEIA)</strong>:
                  <br />
                  <br />
                  Caso seu projeto esteja sendo desenvolvido em uma instituição
                  pública, como uma Universidade Federal/Estadual, ou mesmo em
                  uma organização privada, pode ser necessário providenciarmos
                  uma autorização formal antecipada dessa instituição para
                  podermos publicar seu projeto. Essa autorização pode ser
                  formalizada, por exemplo, a partir da assinatura de um Termo
                  de Cooperação e um Plano de Trabalho. Esses são procedimentos
                  legais que nossa organização já realiza com outras
                  instituições públicas e privadas e que buscam assegurar todas
                  as exigências previstas pela legislação brasileira. Caso sua
                  instituição ou empresa já esteja entre as muitas instituições
                  parcerias da We4Science, esse processo de autorização
                  acontecerá rapidamente. Caso ainda não seja, pode tomar um
                  tempo maior a depender da velocidade e condições de tramitação
                  desses procedimentos burocráticos dentro dos setores
                  responsáveis em sua instituição ou empresa.
                  <br />
                  <br />
                  Mas não se preocupe! Nossa equipe fará contato com você e, se
                  necessário, com os departamentos responsáveis em sua
                  instituição ou empresa para garantir toda a legalidade e
                  tranquilidade da sua campanha de arrecadação de doações e
                  colaborações para o desenvolvimento do seu projeto científico.
                  <br />
                  <br />
                  Em caso de dúvidas, você também pode consultar nossos Termos
                  de Uso e Termo de Doação, Aportes e Saques, ou enviar uma
                  mensagem por meio do canal Fale Conosco.
                  <br />
                  <br />
                  Por fim, nós agradecemos sua contribuição à{" "}
                  <strong>Iniciativa We4Science – Todos pela Ciência!</strong> e
                  esperamos ajudar você a desenvolver este e muitos outros
                  projetos científicos!
                  <br />
                  Enquanto isso, acompanhe e compartilhe com seus amigos as
                  novidades sobre a We4Science e a Ciência em nossas redes
                  sociais e também pelo{" "}
                  <Link style={{ color: "#227af1" }} to="blog">
                    Radar
                  </Link>
                  !
                </p>
                <button
                  type="button"
                  onClick={() => {
                    setTimeout(() => {
                      history.push("/");
                    }, 150);
                  }}
                >
                  Home
                </button>
              </div>
              <div className="second-content">
                <img src={imgMain} alt="" />
                <div className="social-networks">
                  <p id="text-social-networks"> Siga-nos nas redes sociais: </p>
                  <hr />
                  <div className="icons">
                    <a
                      href="https://api.whatsapp.com/send?phone=+55038988016970"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp size={23} />
                    </a>
                    <a
                      href="https://www.instagram.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram size={23} />
                    </a>
                    <a
                      href="https://twitter.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter size={23} />
                    </a>
                    <a
                      href="https://pt-br.facebook.com/We4Science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebookF size={23} />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC3SaYAjljSoSu-Aj0XsSq-w"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube size={23} />
                    </a>
                  </div>
                </div>
              </div>
            </MainContent>

            <Footer />
          </Content>
        </Wrapper>
      </Fade>
    </>
  );
}
