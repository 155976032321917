import { darken } from "polished";
import styled, { css } from "styled-components";
import { TextFocusIn, Shake } from "../../styles/animations";

export const Wrapper = styled.div`
  padding: 20px 0;
  background: #227af1;

  @media screen and (max-width: 1010px) {
    padding: 80px 0 0 0;
  }
  @media screen and (max-width: 850px) {
    padding: 10px 0 0 0;
  }
`;

export const WrapperTwo = styled.div`
  padding: 10px 0 100px 0;
  background: #fff;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;

  position: relative;
  z-index: 0;

  @media screen and (max-width: 472px) {
    align-items: flex-start;
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  z-index: 1;

  h1 {
    margin-top: 10px;
    font-size: 52px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
  #text-2 {
    max-width: 60%;

    font-family: GothamProRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .buttons {
    z-index: 1;

    margin-top: 30px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    #title-button {
      color: #fff;
      margin-right: 30px;
    }

    #button-1 {
      width: 260px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #fff;
      background: transparent;

      padding-left: 33.6px;

      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #fff;

      &::-moz-placeholder {
        opacity: 1;
      }

      &::placeholder {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }

      margin-right: 20px;
    }

    #button-3 {
      background: #fff;
      -webkit-box-shadow: 0 0 7.5px 0 rgba(49, 22, 159, 0.38);
      box-shadow: 0 0 7.5px 0 rgba(49, 22, 159, 0.38);

      width: 168px;
      height: 60px;

      border-radius: 30px;

      font-size: 15.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: center;
      color: #227af1;

      margin-left: 15px;
      margin-right: 20px;

      -webkit-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background: ${darken(0.05, "#FFF")};
        color: ${darken(0.05, "#227af1")};
        -webkit-transform: translate3d(0px, 1px, 2px);
        transform: translate3d(0px, 1px, 2px);
      }
    }

    .img-header {
      display: block;
      position: absolute;

      right: 0;

      z-index: -1;

      transform: translateY(-39px);
    }
  }

  @media screen and (max-width: 1149px) {
    #text-2 {
      max-width: 100%;
    }
    .buttons {
      flex-direction: column;
      #title-button {
        margin-bottom: 15px;
        margin-right: 0;
      }

      #button-1 {
        margin-bottom: 15px;
        margin-right: 0;
      }

      .img-header {
        transform: translateY(0);
        align-self: flex-end;
      }
    }
  }

  @media screen and (max-width: 955px) {
    .buttons {
      .img-header {
        display: none;
      }
    }
  }

  @media screen and (max-width: 765px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 508px) {
    h1 {
      font-size: 1.5rem;
      text-align: center;
    }
    #text-2 {
      text-align: justify;
      font-size: 15px;
      line-height: 1.4;
    }
  }

  @media screen and (max-width: 472px) {
    .buttons {
      width: 100%;
      #button-1 {
        width: 100%;
        padding-left: 0;
        text-align: center;

        &::placeholder {
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1132px) {
    align-items: none;
  }
`;

export const Img = styled.img`
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;

export const DropDownContainer = styled.div`
  margin-top: 12px;
  z-index: 1;
  @media screen and (max-width: 1132px) {
    margin-top: 0;
  }
  @media screen and (max-width: 472px) {
    width: 100%;
  }
`;

export const DropDownHeader = styled.div`
  margin-bottom: 0.8em;

  width: 260px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 30px;
  padding-right: 30px;

  font-family: GothamProRegular;
  font-size: 16.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  z-index: 1;

  cursor: pointer;

  #up-icon {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 472px) {
    width: 100%;
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 260px;

  padding: 0;

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    margin-left: 77px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
  }

  animation: ${TextFocusIn} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @media screen and (max-width: 472px) {
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      left: calc(50% - 10px);
      top: -8px;
      margin-left: 0;
    }
  }
`;

export const DropDownList = styled.ul`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #227af1;
  font-weight: 500;

  height: 75px;
  z-index: 1;

  text-align: center;

  border-radius: 5px;

  &:first-child {
    padding-top: 10px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 5%;
  margin-top: 2%;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 85%;
  height: 80%;

  z-index: 1;

  margin-left: 15px;

  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  border-bottom: 1.2px solid #eee;

  font-size: 16px;

  &:hover {
    color: ${darken(0.15, "#227af1")};
    animation: ${Shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`;

export const RowTwo = styled.div`
  margin-top: 30px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;

  ${(props) =>
    props.lassTwo &&
    css`
      justify-content: center;
      gap:15px;
    `}

  ${(props) =>
    !props.lassTwo &&
    css`
      justify-content: space-between;
    `}

  ${(props) =>
    props.float &&
    css`
      float: right;
    `}
  @media screen and (max-width: 764px) {
    justify-content: center;
  }
`;

export const Cosmos = styled.div`
  display: flex;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  width: 100%;

  .rocket-content {
    width: 30%;

    background-image: linear-gradient(to top, #6af3ff, #44a9fc 71%, #2976fa);

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 28px;

    border-radius: 10px 0 0 10px;

    #cosmos-1 {
      margin-top: 25px;
      margin-bottom: 19.2px;
      max-width: 100%;
    }
    #cosmos-2 {
      max-width: 100%;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;

    width: 70%x;

    #title-cosmos {
      font-size: 26px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;

      margin-top: 30px;
      margin-bottom: 11px;
      margin-left: 42px;
    }

    #text-cosmos {
      max-width: 100%;
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #000000;

      margin-left: 42px;
    }

    hr {
      width: 100%;
      height: 0;
      opacity: 0.08;
      border: solid 1px #707070;

      margin-top: 12.6px;
      margin-bottom: 28.6px;
    }

    #btn-cosmos {
      align-self: center;

      width: 261px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  @media screen and (max-width: 885px) {
    .rocket-content {
      display: none;
    }

    .main-content {
      width: 100%;
      padding: 20px;

      #title-cosmos {
        margin-left: 0;
        text-align: center;
      }

      #text-cosmos {
        margin-left: 0;
        text-align: justify;
      }

      #btn-cosmos {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    padding-bottom: 35px;
  }

  @media screen and (max-width: 885px) {
    .main-content {
      #title-cosmos {
        font-size: 1.2rem;
      }
      #text-cosmos {
        line-height: 1.4;
        font-size: 13.5px;
      }

      #btn-cosmos {
        width: 100%;
      }
    }
  }
`;

export const WrapperThree = styled.div`
  padding: 50px 0 0 0;
  background: #fff;
`;
