import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

import { IconContext } from "react-icons/lib";

import logo from "../../assets/images/Common/logo.svg";
import login from "../../assets/icons/Common/login.svg";
import { Link } from "react-router-dom";

import {
  Nav,
  NavContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  ButtonProfile,
  NavItemBtn,
} from "./styles";

import { store } from "../../store";

import loginScientist from "../../assets/icons/Profile/profiles/scientist.svg";
import loginSupport from "../../assets/icons/Profile/profiles/support.svg";
import loginCompany from "../../assets/icons/Profile/profiles/company.svg";
import Notifications from "../Notifications";

function SubHeader() {
  const [click, setClick] = useState(false);
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const { signed, perfil } = store.getState().auth;

  function whatIsProfile() {
    if (perfil === "Apoiador/Visitante") {
      return loginSupport;
    } else if (perfil === "Apoiador Empresarial/Desafiante") {
      return loginCompany;
    } else if (perfil === "Cientista/Pesquisador") {
      return loginScientist;
    }
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
              <img src={logo} alt="Logo" />
            </NavLogo>

            {window.innerWidth <= 849 && signed && <Notifications />}

            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to={"/projects"}>Projetos e Pesquisas</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={"/store"}>Mercado Whuffie</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={"/challenges"}>Desafios</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={"/supporters"}>Apoiadores</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={"/scientists"}>Cientistas</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={"/company"}>Empresas</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={"/blog"}>Radar</NavLinks>
              </NavItem>

              {window.innerWidth > 849 && signed && <Notifications />}

              <NavItemBtn>
                {signed && perfil !== "admin" ? (
                  <Link to={"/profile"}>
                    <ButtonProfile
                      support={perfil === "Apoiador/Visitante" ? true : false}
                      company={
                        perfil === "Apoiador Empresarial/Desafiante"
                          ? true
                          : false
                      }
                      scientist={
                        perfil === "Cientista/Pesquisador" ? true : false
                      }
                    >
                      <img id="img-login" src={whatIsProfile()} alt="" />
                      <p>Meu Perfil</p>
                    </ButtonProfile>
                  </Link>
                ) : (
                  <Link to={"/register"}>
                    <ButtonProfile>
                      <img src={login} alt="" />
                      <p>Login / Cadastrar</p>
                    </ButtonProfile>
                  </Link>
                )}
              </NavItemBtn>
            </NavMenu>
          </NavContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default SubHeader;
