import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Webkits */
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  /* Webkits */

  #title {
    align-self: flex-start;

    margin-left: 135px;
    margin-bottom: 18px;

    font-size: 49px;
    font-weight: 500;
    line-height: 1.43;
    text-align: left;
    color: #227af1;

    /* Webkits */
    -ms-flex-item-align: start;
    /* Webkits */
  }

  #section-challenges {
    width: 79%;
    height: 2px;

    margin-left: 7px;
    margin-bottom: 36px;

    background: #eee;
  }

  .chalenges {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    /*Webkits  */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -ms-flex-wrap: wrap;
    /* Webkits */
  }

  #more-challenges {
    width: 350px;
    height: 55px;

    margin: 22px 0px 40px 10px;

    border-radius: 30px;

    background: transparent;
    border: 2px solid #227af1;

    font-size: 16.5px;
    font-weight: bold;
    line-height: 1.88;
    text-align: center;

    color: #227af1;

    transition: all 0.3s ease-in-out 0s;

    /* Webkits */
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    /* Webkits */

    &:hover {
      background: #227af1;
      color: #fff;
      -webkit-transform: translate3d(0px, 2px, 1px);
      transform: translate3d(0px, 2px, 1px);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    #title {
      font-size: 1.2rem;
      margin-left: 0;
    }

    .chalenges {
      width: 100%;
      display: flex;
      flex-direction: column !important;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: unset;
    }

    #more-challenges {
      width: 80%;
      margin: 0;
      margin-bottom: 5vh;
      font-size: 0.85rem !important;

      display: block;
    }
  }
`;
