import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import NavBar from "../../../../components/SubHeader";

import imgHeader from "../../../../assets/images/Project/img-header.svg";
import storeWuffie from "../../../../assets/images/Home/store-wuffie.svg";
import iconId from "../../../../assets/icons/Project/id.svg";

function Header({ name, id }) {
  return (
    <Wrapper>
      <NavBar />
      <Container>
        <Row>
          <Column>
            <ImgWrapper>
              <Img src={imgHeader} alt="" />
            </ImgWrapper>
          </Column>
          <Column>
            <h1>Fazer uma Doação</h1>
            <p id="project-name">{name}</p>
            <div id="id-project">
              <img src={iconId} alt="" />
              <p id="normal">ID do projeto: {id}</p>
            </div>
          </Column>
          <Column flexEnd>
            <ImgWrapper small>
              <Img src={storeWuffie} alt="" />
            </ImgWrapper>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Header;
