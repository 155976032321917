import React from "react";

import { Container } from "./styles";

import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

import iconEmail from "../../../../assets/icons/Blog/email-icon.svg";

function DevelopmentTeam({ data }) {
  function completeLink(link) {
    var finalLink;
    if (link !== null) {
      if (
        link.substring(0, 7) === "http://" ||
        link.substring(0, 8) === "https://"
      ) {
        finalLink = link;
      } else {
        finalLink = "https://www." + link;
      }
      return finalLink;
    } else {
      return link;
    }
  }
  return (
    <Container>
      <div className="profile">
        <div id="border">
          <div id="img-profile">
            <img src={data && data.avatar != null && data.avatar.url} alt="" />
          </div>
        </div>
      </div>
      <div className="content-profile">
        <p> Nome: {data && data.name} </p>
        <p> Profissão: {data && data.profession} </p>
        <p> Local de trabalho: {data && data.company}</p>
        <p> Função no projeto: Idealizador </p>
        <p id="description">{data && data.curriculum}</p>
        <section id="section-content-profile"></section>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${data && completeLink(data.link_curriculum)}`}
        >
          Currículo completo
        </a>
        <section id="section-content-profile"></section>
        <div className="social-networks">
          <p> Redes sociais: </p>
          <div className="icons-social">
            {data.youtube && (
              <a
                href={data && data.youtube}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube size={25} />
              </a>
            )}

            {data && data.instagram && (
              <a
                href={data && completeLink(data.instagram)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={25} />
              </a>
            )}
            {data && data.twitter && (
              <a
                href={data && completeLink(data.twitter)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter size={25} />
              </a>
            )}

            {data && data.facebook && (
              <a
                href={data && completeLink(data.facebook)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF size={22} />
              </a>
            )}

            {data && data.linkedin && (
              <a
                href={data && completeLink(data.linkedin)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn size={24} />
              </a>
            )}

            {data && data.email && (
              <img
                id="icon-email"
                src={iconEmail}
                alt={`${data && data.email}`}
                title={data && data.email}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default DevelopmentTeam;