import React from "react";

import { Bounce } from "react-reveal";
import Project from "../../components/NewProject";

import {
  Wrapper,
  Content,
  Header,
  ProjectContent,
  Projects,
  Subscribe,
  MainContent,
} from "./styles";

//Redux
import { store } from "../../store";
import { creditRequest } from "../../store/modules/user/actions";
import { useDispatch } from "react-redux";

import projectIcon from "../../assets/icons/Common/project-icon.svg";
import iconOtherProjects from "../../assets/icons/Common/projects-2.svg";
import iconHeader from "../../assets/icons/Success/check.svg";

// Images -> Common Foulder
import imgProject from "../../assets/images/Project/second.svg";
import imgMain from "../../assets/icons/Success/main.svg";

import {
  FaWhatsapp,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";
// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import useProjectsHome from "../../hooks/useProjectsHome";
import { Helmet } from "react-helmet";

function Success() {
  const { projects } = useProjectsHome();

  const dispath = useDispatch();

  const { perfil, token } = store.getState().auth;
  const { profile } = store.getState().user;
  var data;
  token && (data = Object.assign({ perfil, id: profile.id }));

  setTimeout(() => {
    token && dispath(creditRequest(data));
  }, 1000);

  return (
    <>
      <Helmet>
        <title>Doação realizada com sucesso</title>
      </Helmet>
      <Bounce bottom duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <div className="congratulations">
                <div className="box">
                  <div className="box-img">
                    <img src={iconHeader} alt="" />
                  </div>
                </div>
                <p id="text-congrat"> Doação realizada com sucesso </p>
              </div>
              <div className="circles">
                <div id="first-circle"> </div> <div id="second-circle"> </div>
                <div id="third-circle"> </div>
              </div>
            </Header>
            <MainContent>
              <div className="content">
                <p>
                  Nós, da equipe W4S, e todos os cientistas e pesquisadores
                  responsáveis por este projeto agradecemos muito sua
                  contribuição e lhe damos as boas vindas a nossa comunidade de
                  apoiadores da Ciência!
                  <br />
                  <br />
                  Acompanhe as novidades sobre este projeto em nossa plataforma
                  e siga a W4S nas redes sociais para ficar por dentro dos
                  principais assuntos científicos da atualidade!
                </p>
              </div>
              <div className="second-content">
                <img src={imgMain} alt="" />
                <div className="social-networks">
                  <p id="text-social-networks">
                    {" "}
                    Siga - nos nas redes sociais:{" "}
                  </p>
                  <hr />
                  <div className="icons">
                    <a
                      href="https://api.whatsapp.com/send?phone=+55038988016970"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp size={23} />
                    </a>
                    <a
                      href="https://www.instagram.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram size={23} />
                    </a>
                    <a
                      href="https://twitter.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter size={23} />
                    </a>
                    <a
                      href="https://pt-br.facebook.com/We4Science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebookF size={23} />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC3SaYAjljSoSu-Aj0XsSq-w"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube size={23} />
                    </a>
                  </div>
                </div>
              </div>
            </MainContent>
            <ProjectContent>
              <Projects lessTwo={projects.length > 2 ? false : true}>
                <section> </section>
                <div className="title-projects">
                  <img src={iconOtherProjects} alt="" />
                  <p> VEJA OUTROS PROJETOS </p>
                </div>
                <div id="projects">
                  {projects &&
                    projects.map((preview) => (
                      <Project key={preview.id} data={preview.project} />
                    ))}
                </div>
              </Projects>
              <Subscribe>
                <div id="donation-content-1">
                  <img src={projectIcon} alt="" />
                  <p id="donation-text-1">
                    INSCREVA <br /> SEU PROJETO
                  </p>
                  <p id="donation-text-2">
                    Ao inscrever um breve e simples resumo do seu projeto ou
                    pesquisa científica gratuitamente em nossa plataforma, você
                    abre um vasto horizonte para a captação de recursos
                    financeiros, intercâmbio de conhecimentos, busca de
                    parceiros institucionais nacionais e internacionais, além de
                    impulsionar sua carreira profissional!
                  </p>
                  <button> Faça uma doação agora </button>
                  <div id="first-circle-donation"> </div>
                  <div id="second-circle-donation"> </div>
                  <div id="third-circle-donation"> </div>
                </div>
                <img id="img-project" src={imgProject} alt="" />
              </Subscribe>
            </ProjectContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Success;
