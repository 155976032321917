import React, { useState, useRef, useEffect } from "react";
import { useField } from "@rocketseat/unform";

import { Container } from "./styles";
import api from "../../services/api";

import iconAvatarInput from "../../assets/icons/Common/avatar-input.svg";

export default function AvatarInput({ profile }) {
  const { defaultValue, registerField } = useField("avatar");

  const [preview, setPreview] = useState(defaultValue && defaultValue.url);
  const [file, setFile] = useState(defaultValue && defaultValue.id);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: "avatar_id",
        ref: ref.current,
        path: "dataset.file",
      });
    }
  }, [ref, registerField]);

  async function handleChange(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    setFile(id);
    setPreview(url);
  }

  return (
    <Container active={true}>
      <div className="first-div">
        <div className="second-div">
          <label htmlFor="avatar">
            <div>
              {preview ? (
                <img id="teste" src={preview || iconAvatarInput} alt="avatar" />
              ) : (
                <img src={iconAvatarInput} alt="avatar" />
              )}

              <input
                name="avatar_id"
                type="file"
                id="avatar"
                accept="image/*"
                onChange={handleChange}
                data-file={file}
                ref={ref}
              />
            </div>
          </label>
        </div>
      </div>
    </Container>
  );
}
