import { useEffect, useState } from "react";

import api from "../services/api";

export default function useProjectsHome() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const loadProjects = async () => {
      const { data } = await api.get("/v2/projects-home");

      if (data !== null && data.length !== 0) {
        setProjects(data);
      }
    };
    loadProjects();
  }, []);
  return { projects };
}
