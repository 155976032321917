import React from "react";

import { Container } from "./styles";

import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";

import GoBackTerms from "../../components/GoBackTerms";

function UseTerms() {
  return (
    <>
      <Helmet>
        <title>Termos de uso e condições de navegação</title>
      </Helmet>
      <Fade left duration={1600} distance="20px">
        <Container>
          <GoBackTerms />
          <div className="term">
            <p align="center">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <span style={{ fontSize: "large" }}>
                    <strong>TERMOS DE USO E CONDIÇÕES DE NAVEGAÇÃO</strong>
                  </span>
                </span>
              </span>
              <span style={{ color: "#0d0d0d", marginLeft: 5 }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <span style={{ fontSize: "large" }}>
                    <em>
                      <strong>We4Science</strong>
                    </em>
                  </span>
                </span>
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Olá, seja bem-vindo à Plataforma{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>A </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                é uma organização de apoio ao desenvolvimento científico
                nacional e internacional a partir da promoção, colaboração e
                financiamento coletivo a projetos e pesquisas científicas. Nesse
                sentido, construímos e disponibilizamos uma Plataforma online
                segura e intuitiva, que facilita e incentiva a divulgação do
                conhecimento científico ao público leigo, bem como a interação
                direta entre cientistas, pesquisadores, instituições de ensino e
                pesquisa, empresas privadas e apoiadores científicos.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Criamos um ambiente para </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Investimento e Colaboração
                </span>
              </em>{" "}
              <em>
                <span style={{ fontWeight: 400 }}>Coletiva</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                {" "}
                na Ciência que une Instituições de Ensino, Cientistas,
                Pesquisadores, Empreendedores e Entusiastas da Ciência, ou seja,
                um ambiente em que boas ideias podem ser impulsionadas por
                pessoas comuns interessadas em contribuir com o progresso da
                Ciência e da humanidade, mesmo que não representem grandes
                empresas ou organizações de Estado.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Ainda assim, empresas e organizações com ou sem fins lucrativos,
                públicas ou privadas, de todos os tamanhos e diversos segmentos
                também encontrarão aqui opções valiosas de investimento,
                traduzidas em iniciativas inovadoras, profissionais altamente
                capacitados e oportunidades de negócio com enorme potencial de
                diferenciação e vantagem competitiva num mundo cada vez mais
                dependente da Ciência e Tecnologia.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Agora que você já sabe quem somos, vamos lhe convidar a conhecer
                um pouco mais sobre{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  o que pode e o que não se pode fazer{" "}
                </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                neste ambiente, o que esperamos que você faça e outras tantas
                coisas que você também precisa saber para poder utilizar a
                plataforma We4Science da melhor maneira possível.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Assim, vamos combinar que você só irá clicar no botão “li e
                aceito os Termos de Uso” após{" "}
              </span>
              <strong>REALMENTE</strong>
              <strong>
                <em> ter lido e aceitado</em>
              </strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                o que está escrito aqui, combinado!?
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se, entretanto, você não concordar com nossos termos, se tiver
                alguma dúvida ou quiser conversar sobre nossas condições de uso,
                envie um e-mail para{" "}
              </span>
              <a href="mailto:contato@we4science.com">
                <strong>contato@we4science.com</strong>
              </a>{" "}
              <span style={{ fontWeight: 400 }}>
                ou por meio de nossa ferramenta{" "}
              </span>
              <strong>“Fale Conosco” </strong>
              <strong>(inserir link para fale conosco).</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Todavia, para garantir sua melhor experiência conosco, você
                somente poderá usar nossa Plataforma ao{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>LER</span>
              </em>
              <span style={{ fontWeight: 400 }}> e aceitar esses </span>
              <strong>Termos de Uso</strong>
              <span style={{ fontWeight: 400 }}> e nossas </span>
              <strong>Políticas de Privacidade</strong>
              <span style={{ fontWeight: 400 }}>. Vamos lá?</span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>A) QUEM SOMOS?</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Somos fruto do empenho e colaboração de dezenas de pessoas,
                empresas e instituições. Em especial, da iniciativa
                empreendedora de um professor, escritor e entusiasta da Ciência
                e da Educação – Emerson César – e do inestimável apoio da
                Universidade Federal dos Vales do Jequitinhonha e Mucuri
                (UFVJM), localizada na cidade de Diamantina, no estado de Minas
                Gerais (Brasil). A{" "}
              </span>
              <strong>
                <em>We4Science Projetcs and Innovation Ltda</em>
              </strong>
              <span style={{ fontWeight: 400 }}>
                . é uma empresa brasileira inscrita sob o CNPJ
                40.400.208/0001-12.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Em nossa equipe partilhamos de uma devoção ferrenha a uma utopia
                que vislumbra no progresso científico e na democratização do
                conhecimento, um futuro de paz e prosperidade para todos, capaz
                de reconciliar a humanidade consigo mesma e com o meio ambiente,
                e nos lançar aos confins do Cosmos e do nosso infinito potencial
                humano.&nbsp;
              </span>
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  <br />
                </span>
                <span style={{ fontWeight: 400 }}>
                  <br />
                </span>
                <strong>B) EM QUE ACREDITAMOS?</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Acreditamos que a Ciência é o principal motor para o progresso
                da humanidade e a solução para alguns dos grandes problemas e
                desafios que ameaçam a existência humana e de todos os seres
                vivos em nosso planeta. Por meio da Ciência, podemos produzir
                mais e melhores alimentos, desenvolver medicamentos e
                tratamentos que salvarão vidas, produtos e máquinas que
                melhorarão nossa qualidade de vida, procedimentos e tecnologias
                que ajudarão a preservar o meio ambiente e levar a humanidade
                aos confins do universo. Promover o progresso da Ciência é
                promover o nosso próprio progresso!
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Também acreditamos que o progresso da humanidade passa,
                necessariamente, pelo acesso e democratização do conhecimento
                científico e que apoiar a Ciência não precisa – e nem deve – ser
                uma responsabilidade{" "}
              </span>
              <span style={{ fontWeight: 400 }}>exclusiva</span>
              <span style={{ fontWeight: 400 }}>
                {" "}
                do Estado ou de grandes corporações econômicas. Todos nós
                podemos contribuir com a Ciência e com o progresso da nossa
                civilização!
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>C) COMO FAZEMOS?&nbsp;</strong>
              </li>
            </ol>
            <p>
              <strong>
                <br />
              </strong>
              <strong>
                <br />
              </strong>
              <strong>
                <br />
              </strong>
              <span style={{ fontWeight: 400 }}>
                Já que não podemos transformar todas as{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>pessoas </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                em Cientistas (ou podemos?), temos certeza de que podemos ajudar{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>todos</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                {" "}
                os Cientistas por meio das{" "}
              </span>
              <strong>PESSOAS</strong>
              <span style={{ fontWeight: 400 }}>!</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Sabemos que muitos projetos científicos precisam apenas chegar
                aos olhos e ouvidos de quem está disposto a ajudar, seja
                divulgando e dando visibilidade àquela iniciativa ou cientista,
                seja colaborando de forma técnica ou ajudando financeiramente, a
                partir de doações em dinheiro para aquele projeto. Por essa
                razão, trabalhamos na fronteira de um{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>novo mercado</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                : o do investimento colaborativo (crowdfunding) para projetos e
                pesquisas científicas.{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Para ajudar a preencher a enorme lacuna existente entre o número
                de projetos e pesquisas científicas e os investimentos públicos
                necessários para desenvolvê-los, surgiu a{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                - primeira plataforma de colaboração e financiamento
                colaborativo em Ciência e Educação do Brasil,{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  baseada no conceito de captação de recursos na multidão
                  (crowdfunding)
                </span>
              </em>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>O que é </strong>
              <strong>
                <em>Crowdfunding</em>
              </strong>
              <strong>?</strong>
              <span style={{ fontWeight: 400 }}>
                &nbsp;O termo Crowdfunding vem da junção de duas palavras do
                inglês, que de forma isolada designam Multidão (Crowd) e
                Capitalização (Funding), referência à ideia de colaboração
                financeira vinda de um número indeterminado de pessoas para o
                financiamento (fomento, capitalização) de um projeto apresentado
                por seu idealizador. Eventos como o concerto{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>Live Aid</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                {" "}
                e iniciativas de doação coletiva como o{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>Teleton</span>
              </em>
              <span style={{ fontWeight: 400 }}> e o </span>
              <em>
                <span style={{ fontWeight: 400 }}>Criança Esperança</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                {" "}
                são exemplos bem sucedidos desse modelo de financiamento.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Assim, a We4Science é uma plataforma eletrônica para promoção,
                colaboração e financiamento coletivo de projetos e pesquisas
                científicas, sendo de propriedade da WE4SCIENCE PROJETCS AND
                INNOVATION LTDA., inscrita sob o CNPJ: 40.400.208/0001-12,
                sediada na Rua Gutemberg, 179, Centro de Diamantina-MG, CEP
                39100-000.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Nossa Plataforma online e suas conexões offline permitem o
                desenvolvimento da pesquisa científica por meio de um
                ecossistema seguro, intuitivo, ágil e inovador, seja para quem
                deseja doar recursos financeiros para iniciativas científicas,
                seja para quem deseja investir em negócios baseados em Ciência e
                Tecnologia, criando um ambiente de possibilidades que até pouco
                tempo era restrito a investidores profissionais ou mesmo ao
                Governo.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Oferecemos uma plataforma que permite a Cientistas e
                Pesquisadores: I) apresentar/divulgar projetos de pesquisa
                científica; II) captar recursos técnicos; III) captar recursos
                financeiros. IV) Criar conexões e redes de relacionamento
                estratégicas com outros cientistas, instituições e
                empresas.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Como as antigas cafeterias de Paris na aurora das grandes
                revoluções, a{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                cria o ambiente e oferece as facilidades para que boas ideias
                encontrem as pessoas certas.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Como a We4Science funciona para os Doadores?</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Caso você queira doar para algum projeto ou pesquisa científica
                em específico ou para o Fundo Cosmos (a conta geral da W4S),
                você terá primeiro que se cadastrar na Plataforma. Feito isso,
                você poderá buscar de maneira simples, rápida e intuitiva{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  pelo projeto, instituição ou cientista
                </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                {" "}
                da sua preferência. Também é possível pesquisar pelos projetos
                em nosso catálogo ou pelas diferentes categorias de assuntos em
                que cada projeto é cadastrado.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Escolhido o projeto para realizar a doação, você deverá informar
                o valor que deseja doar, então escolher o meio e a forma de
                pagamento a serem utilizados para transferir o valor à
                instituição responsável por aquele projeto ou pesquisa.{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <strong>IMPORTANTE:</strong>
              <span style={{ fontWeight: 400 }}> Nós </span>
              <strong>NÃO</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                transferimos recursos financeiros para contas de pessoas
                físicas! Após o fim da campanha de doações para aquele projeto
                ou pesquisa, os valores são transferidos para a conta jurídica
                da respectiva instituição ou empresa responsável por aquele
                projeto,{" "}
              </span>
              <strong>SALVO</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                nos casos em que o cientista/cadastrante tenha{" "}
              </span>
              <strong>Permissão Especial</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                conferida pela Plataforma W4S.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>E a Plataforma? Ela estará sempre disponível?</strong>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>Nós, da </span>
              <strong>We4Science,</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                vamos nos esforçar ao máximo para garantir que nossa Plataforma
                se mantenha sempre disponível para seu acesso e utilização.
                Entretanto, não podemos assegurar de que ela estará disponível
                online 100% do tempo, já que, muitas vezes, a sua
                disponibilidade dependerá exclusivamente de fatores, agentes e
                circunstâncias externas que não estão totalmente sob nosso
                controle, como serviços de telecomunicações geridos por
                terceiros, disponibilidade da rede elétrica, falhas ou problemas
                em seu equipamento.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Há também a necessidade de manutenção e atualização periódica ou
                de aprimoramentos em nossa Plataforma e seus recursos, o que
                pode resultar em interrupção temporária, dificuldades ou até
                mesmo impedir o seu acesso, mesmo que por um curto espaço de
                tempo. Por essa razão, não podemos nos responsabilizar por danos
                ou frustrações causadas nessas situações, caso a Plataforma não
                esteja totalmente acessível ou funcionando de maneira limitada.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                E como é possível entrar em contato com a equipe administrativa
                da Plataforma W4S?
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>Nós, da </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                , estamos sempre aguardando seu contato para conversarmos sobre
                sua experiência em nossa plataforma, esclarecer dúvidas ou
                tratarmos sobre oportunidades de parceria, participação em
                eventos, ações dirigidas e o que mais pudermos contribuir em
                matéria de desenvolvimento e promoção científica.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Assim, você poderá entrar em contato conosco por meio das
                ferramentas de comunicação disponibilizadas na própria
                Plataforma em seu{" "}
              </span>
              <strong>Fale Conosco </strong>
              <strong>(inserir link),</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                ou ainda, por meio do nosso e-mail institucional:{" "}
              </span>
              <strong>contato@we4science.com.</strong>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Ah, não se esqueça! Você deve ajustar seu sistema de “
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>AntiSpam</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                ” e incluir o nosso domínio para que não deixe de receber
                nenhuma de nossas mensagens por email.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Links disponíveis na Plataforma.</strong>
            </p>
            <p>
              <strong>
                <br />
              </strong>
              <strong>
                <br />
              </strong>
              <span style={{ fontWeight: 400 }}>
                A Plataforma W4S pode indicar links externos para acesso dos
                Usuários, entretanto, não realizamos qualquer verificação,
                controle, aprovação e/ou garantia sobre tais conteúdos, seja de
                adequação ou exatidão, ou mesmo segurança das informações e
                dados disponíveis por meio desses links externos. Logo, não
                somos responsáveis por eventuais problemas ou danos ocorridos em
                decorrência do seu acesso, nem por prejuízos, perdas, atrasos,
                defeitos, erros e/ou omissões que existam e/ou possam existir,
                nos sistemas e/ou serviços disponibilizados ou mesmo pelo acesso
                (visita) a tais sites ou pelas funcionalidades oferecidas por
                meio deles.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>D) O QUE NÃO FAZEMOS</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>Não aceitamos Projetos e Pesquisas Científicas que:</p>
            <p>
              <strong>&nbsp;</strong>
              <strong>
                <br />
                <br />
              </strong>
            </p>
            <ol>
              <li>
                <span>
                  {" "}
                  Infrinjam qualquer lei ou norma jurídica brasileira vigente;
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  Façam apologia ao uso de bebidas alcoólicas, cigarros ou
                  outras drogas (lícitas ou não);
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  Explorem trabalho infantil ou exponham pessoas a condições de
                  trabalho degradante e/ou escravo;
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  Que evidenciem discriminação de raça, credo, origem,
                  orientação sexual ou preconceito de qualquer natureza;
                </span>
              </li>
              <li>
                <span> Que violem os direitos humanos;</span>
              </li>
              <li>
                <span>
                  {" "}
                  Que façam uso de nomes, símbolos ou imagens que caracterizem
                  promoção pessoal de autoridades ou servidores públicos.
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  Que violem ou infrinjam direitos de autor e/ou propriedade de
                  terceiros;
                </span>
              </li>
            </ol>
            <p></p>
            <ol>
              <li>
                <strong>E) QUEM PODE DOAR E/OU COLABORAR</strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Qualquer pessoa acima de 18 anos, natural ou jurídica, que
                preencha o cadastro de usuário.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Preciso ser um </strong>
              <strong>
                <em>Cientista</em>
              </strong>
              <strong>
                {" "}
                para colaborar com algum projeto ou pesquisa?&nbsp;
              </strong>
            </p>
            <p>
              <strong>
                <br />
              </strong>
              <span style={{ fontWeight: 400 }}>
                Não. Você só precisa ser alguém interessado em colaborar com a
                Ciência, independente do seu nível escolar, profissão, renda,
                inclinação política, crença ou descrença religiosa, time de
                futebol favorito, etc..
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                De que forma eu posso colaborar com um projeto ou pesquisa
                científica?
              </strong>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                De várias formas. Você pode coloborar:
              </span>
            </p>
            <ol>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  fazendo doações em dinheiro, a partir de R$ 5,00 (cinco
                  reais)&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  oferecendo conhecimento ou informações relevantes aos
                  pesquisadores responsáveis por determinado projeto,
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  estabelecendo parcerias entre empresas e instituições, ou
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  apenas divulgando aquele projeto ou pesquisa científica em
                  suas redes sociais para que outras pessoas possam conhecê-lo e
                  colaborar com ele.
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>
                  F) SOBRE CADASTRO DE PROJETOS CIENTÍFICOS E RECEBIMENTO DE
                  DOAÇÕES
                </strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>
                Quem pode apresentar projetos e pesquisas científicas na
                Plataforma?&nbsp;
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Apenas Cientistas, Pesquisadores, Professores, Profissionais ou
                Alunos autorizados por Universidades e Instituições Públicas
                e/ou Privadas de Educação, Pesquisa e Ciência.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Exceções: </strong>
              <span style={{ fontWeight: 400 }}>
                Empresas privadas não ligadas diretamente à Educação, Pesquisa e
                Ciência ou pessoas físicas não ligadas à instituições
                científicas também podem apresentar projetos e pesquisas
                científicas em nossa plataforma. No entanto, é necessário
                solicitar uma “Permissão Especial” aos administradores da
                plataforma. Para liberação dessa permissão, o candidato deverá
                comprovar que possui as capacidades necessárias para tocar o
                projeto/pesquisa adiante, além de outras garantias.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Por que apenas universidades, centros de pesquisa, empresas ou
                pessoas físicas/entidadades com Permissão Especial podem
                cadastrar projetos e pesquisas científicas em nossa Plataforma?
              </strong>
            </p>
            <p>
              <strong>&nbsp;</strong>
              <strong>
                <br />
              </strong>
              <strong>
                <br />
              </strong>
              <span style={{ fontWeight: 400 }}>
                Nosso objetivo é tentar garantir para a nossa comunidade de
                apoiadores científicos que os projetos e pesquisas encontrados
                aqui possuem procedência confiável e são desenvolvidos por
                instituições e profissionais sérios e comprometidos. Todos os
                criadores têm os dados checados pela administração da
                plataforma, e cada projeto e pesquisa passa por uma cuidadosa
                análise da administração antes de ser apresentado aos usuários.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>
                  G) O QUE VOCÊ NÃO PODE DEIXAR DE SABER ANTES DE USAR A
                  PLATAFORMA W4S
                </strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Para usar a Plataforma.</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Respeitamos a legislação brasileira, por esse motivo, para que
                você possa começar a usar os serviços oferecidos pela{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                em sua Plataforma, você deverá se enquadrar em todas as
                situações listadas abaixo:
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Deve ser maior de 18 anos;
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Se Pessoa Jurídica, o representante deve ser maior de 18 anos;
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Ler e estar ciente dos termos e especificações do Projeto ou
                  Pesquisa para o qual deseja DOAR/COLABORAR, bem como ler e
                  estar ciente dos Termos de Uso e das Políticas de Privacidade
                  da Plataforma W4S;
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Se o meio de pagamento selecionado for cartão de crédito, este
                  deverá ter sido emitido por empresa autorizada a operar este
                  tipo de serviço no Brasil e o cartão utilizado deverá ter sido
                  emitido em seu próprio nome;
                </span>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Da Taxa de Administração</strong>
              <span style={{ fontWeight: 400 }}>
                . A Plataforma We4Science descontará o percentual de 15% (quinze
                por cento) sobre o valor total arrecadado em cada campanha de
                arrecadação de doações para PROJETO/PESQUISA cadastrados na
                Plataforma, o que chamará de Taxa de Administração.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Sobre os valores totais ou parciais referentes à taxa de
                administração da plataforma, não caberá devolução, estorno ou
                reembolso ao(s) doador(es), ou na forma de repasse ao donatário,
                ainda que o projeto/campanha venha a ser interrompido e/ou
                cancelado a qualquer tempo. Também não haverá reajuste sobre o
                percentual de 15% (quinze por cento) para menos ou para mais,
                após lançada a campanha de doações, ainda que o projeto/campanha
                venha a ser interrompido e/ou cancelado a qualquer tempo, tendo
                alcançado ou não a meta de doações definida originalmente pelo
                usuário/cadastrante.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>Doações para a Plataforma We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                . Os usuários da Plataforma podem fazer doações para a própria
                Plataforma{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>, através do </span>
              <strong>Fundo Cosmos</strong>
              <span style={{ fontWeight: 400 }}>
                , de quaisquer valores que desejarem, com a finalidade de
                financiar{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>outros</span>
              </em>{" "}
              <strong>PROJETOS/PESQUISAS</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                (novos ou já cadastrados).&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                A responsabilidade por todas as informações contidas nos
                PROJETOS/PESQUISAS é do usuário que o cadastrar na Plataforma.
              </span>
              <em>
                <span style={{ fontWeight: 400 }}> A </span>
              </em>
              <strong>We4Science</strong>{" "}
              <span style={{ fontWeight: 400 }}>
                não tem qualquer ingerência sobre tais informações, portanto,
                não pode ser responsabilizada, nem mesmo pelo sucesso das
                campanhas de doação e captação de recursos. Caso o valor
                solicitado seja alcançado,{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  a instituição ou empresa responsável pelo projeto ou pesquisa{" "}
                </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                receberá os valores descontados as taxas pertinentes e seguindo,
                quando assim firmado, as diretrizes do{" "}
              </span>
              <strong>Plano de Trabalho </strong>
              <span style={{ fontWeight: 400 }}>
                eventualmente acordado entre a instituição e a We4Sciente
                Ltda.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>
                  <br />
                </strong>
                <strong>
                  H) O QUE ESPERAMOS QUE VOCÊ FAÇA POR MEIO DESTA PLATAFORMA
                </strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Esperamos que você apresente apenas dados e declarações
                verdadeiras em todas as suas interações por aqui, desde o
                momento do cadastro em nossa Plataforma e, principalmente, antes
                de aderir aos termos e políticas da We4Science.{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Ser ético significa agir de acordo com as regras que orientam a
                sociedade, portanto, todos os Usuários devem sempre respeitar as
                condições que regem a utilização desta Plataforma, sob pena de
                serem responsabilizados (civil e/ou criminalmente) se causarem
                qualquer tipo de prejuízo e/ou dano à terceiros, sejam usuários,
                Instituições ou mesmo à própria Plataforma.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Os Usuários devem utilizar nossos recursos e ferramentas para a
                finalidade na qual foram desenvolvidas, sendo vedado qualquer
                tipo de uso diferente da proposta desta Plataforma.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Além disso, é importante que você mantenha sigilo sobre seus
                dados de acesso, sua senha e demais dispositivos de autenticação
                em relação à terceiros, não os divulgando ou compartilhando com
                quem quer que seja.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Caso os Usuários suspeitem que a confidencialidade da senha foi
                quebrada ou que houve o comprometimento de algum dispositivo de
                autenticação, deverão proceder imediatamente com a troca ou
                atualização de sua senha de acesso o quanto antes.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se você não conseguir acessar a Plataforma para obter suporte,
                você deverá buscar atendimento por e-mail através do seguinte
                endereço:{" "}
              </span>
              <a href="about:blank">
                <span style={{ fontWeight: 400 }}>contato@we4science.com.</span>
              </a>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                A We4Science não oferece qualquer tipo de acesso à rede mundial
                de computadores, então esperamos que todos os Usuários disponham
                de recursos próprios para acessar a internet e a essa
                Plataforma.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Por fim, também é importante que você, enquanto usuário desta
                Plataforma e grande apoiador do progresso científico, sempre que
                possível, faça uma divulgação positiva sobre ela e sobre os
                Projetos e Pesquisas Científicas com os quais você está
                apoiando, compartilhando esses projetos em suas redes sociais,
                conversando sobre eles com seus amigos, professores e colegas de
                trabalho. Lembre-se que o sucesso desses projetos e pesquisas
                depende muito da quantidade de apoiadores que cada um consegue
                alcançar.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>
                  H) O QUE NÃO DESEJAMOS QUE SEJA FEITO POR MEIO DESTA
                  PLATAFORMA
                </strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Você já sabe que poderá usar a Plataforma W4S para postar
                determinados conteúdos, acessar informações e utilizar todos os
                nossos serviços, ferramentas e facilidades. Ao fazê-lo, porém,
                você também nos garante que possui todas as autorizações legais
                para publicar tais conteúdos e fazer uso de nossa Plataforma,
                assegurando que seu conteúdo e ações expressos aqui:
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não violam nenhum direito de propriedade intelectual de
                  terceiros;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não são abusivos ou ilegais, contendo textos, imagens, fotos
                  ou vídeos que tenham, ainda que implicitamente, caráter
                  calunioso, difamatório, discriminatório, obsceno,
                  pornográfico, ofensivo, ameaçador, vexatório, prejudicial, que
                  manifeste ódio contra pessoas ou grupos específicos, tais como
                  insultos religiosos, raciais, de gênero ou de orientação
                  sexual, ou que violem ou incentivem a violação a quaisquer
                  direitos humanos;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não contenham banners publicitários ou qualquer outro tipo de
                  publicidade ou propaganda de terceiros, sem a expressa
                  autorização do autor/proprietário e da própria Plataforma, por
                  escrito;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não carregam ou redirecionam outros usuários para vírus ou
                  códigos maliciosos (
                </span>
                <em>
                  <span style={{ fontWeight: 400 }}>malware</span>
                </em>
                <span style={{ fontWeight: 400 }}> e </span>
                <em>
                  <span style={{ fontWeight: 400 }}>spyware</span>
                </em>
                <span style={{ fontWeight: 400 }}>);</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não configuram mensagens repetitivas em discussões que possam
                  se caracterizar como SPAM;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não fornecem ou divulgam, de maneira voluntária e consentida,
                  informações e materiais enganosos com a intenção de fraudar e
                  enganar os demais Usuários;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não acessam ou coletam dados de maneira automatizada sem a
                  prévia autorização da Plataforma ou faz “
                </span>
                <em>
                  <span style={{ fontWeight: 400 }}>raspagem</span>
                </em>
                <span style={{ fontWeight: 400 }}>
                  ” de informações, incluindo, mas não se limitando, a{" "}
                </span>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    spiders, scripts, crawlers
                  </span>
                </em>
                <span style={{ fontWeight: 400 }}> e/ou quaisquer outros </span>
                <em>
                  <span style={{ fontWeight: 400 }}>softwares</span>
                </em>
                <span style={{ fontWeight: 400 }}>;</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não utilizam procedimentos e técnicas que impliquem em uma
                  carga desproporcional em nossa rede ou infraestrutura, capazes
                  de prejudicar a estabilidade dos nossos sistemas e/ou site(s);
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Não violam estes Termos de Uso nem a Política de Privacidade
                  da Plataforma.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                A Plataforma oferece Serviços que são, muitas vezes,{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>abertos</span>
              </em>
              <span style={{ fontWeight: 400 }}>
                {" "}
                ao público. Dessa forma, mesmo que uma pessoa não tenha uma
                conta cadastrada, é possível que ela possa acessar algumas
                funcionalidades, informações, conteúdos e/ou nosso(s) Site(s).
                Nesse contexto, recomendamos aos Usuários que evitem revelar
                dados pessoais, tais como nome, e-mail, endereço e CPF nos
                comentários e nas interações realizadas de modo “aberto” na
                Plataforma. Nesses casos, não nos responsabilizamos por
                eventuais danos sofridos e/ou causados pelos Usuários.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Ressaltamos ainda que você, como Usuário dos nossos Serviços,
                sob pena de suspensão de todo ou parte de sua utilização,
                concorda em não se passar por outra pessoa, deturpando a sua
                identidade, seus dados pessoais, cadastrais, endereço, número de
                documentos, etc.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Você sabe ainda que não deve acessar ou usar a Plataforma
                visando tomar qualquer ação que possa nos prejudicar ou causar
                danos à terceiros. Assim, você concorda que não deve tentar,
                executar e/ou encorajar qualquer procedimento de engenharia
                reversa sob nossos sistemas.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                O uso da Plataforma de maneira contrária à estes Termos de Uso
                poderá resultar na suspensão e/ou exclusão de seu cadastro, sem
                prejuízo de eventuais sanções civis, criminais e/ou
                administrativas, incluindo, mas não se limitando, ao
                ressarcimento de danos por meio do pagamento de multas e/ou
                indenizações pelo Usuário e por aqueles que o auxiliaram a
                violar estes Termos de Uso.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Denúncia e abusos de violação.&nbsp;</strong>
            </p>
            <p>
              <strong>
                <br />
              </strong>
              <span style={{ fontWeight: 400 }}>Você </span>
              <span style={{ fontWeight: 400 }}>se compromete</span>
              <span style={{ fontWeight: 400 }}>
                {" "}
                a denunciar abusos e violações a estes Termos e Condições de Uso
                praticados (por e) contra terceiros e/ou contra você. Para
                reportar violações, por favor, fale conosco
              </span>{" "}
              <span style={{ fontWeight: 400 }}>por meio do e-mail: </span>
              <a href="mailto:contato@we4science.com">
                <span style={{ fontWeight: 400 }}>contato@we4science.com</span>
              </a>
              <span style={{ fontWeight: 400 }}>
                {" "}
                ou pelos nossos canais de atendimento.
              </span>
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>
                  I) O QUE VOCÊ AINDA DEVE SABER SOBRE A UTILIZAÇÃO DA
                  PLATAFORMA W4S
                </strong>
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>Atendimento e Suporte de acesso à Plataforma:</strong>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Dúvidas e/ou esclarecimentos sobre as condições estabelecidas
                nestes Termos e/ou em quaisquer outros documentos desta
                Plataforma, por favor, envie uma mensagem pelo{" "}
              </span>
              <strong>Fale Conosco</strong> <strong>(inserir link)</strong>
              <span style={{ fontWeight: 400 }}> e-mail para o </span>
              <strong>contato@we4science.com</strong>
              <span style={{ fontWeight: 400 }}>
                . Será um prazer ajudá-lo(a)!
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Modificações destes Termos e Condições:</strong>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Os presentes Termos de Uso, Condições de Navegação e/ou
                Utilização, bem como nossas Políticas de Privacidade, estão
                sujeitos à constantes modificações, sejam de melhoria e/ou
                aprimoramento, seja para incluir e/ou excluir direitos ou
                deveres. Portanto, a{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                se reserva no direito de modificá-los a qualquer tempo de forma
                unilateral, sem aviso prévio.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Documentos que regem as atividades na Plataforma:</strong>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Ao navegar por esta Plataforma e utilizar suas funcionalidades
                você aceita e concorda com os Termos e Condições de Uso da
                Plataforma, com os Termos e Condições de Doações, Aportes,
                Saques, bem como com as Políticas de Privacidade e Uso de Dados
                vigentes na data do seu acesso e cadastro, devendo, portanto,
                verificá-los cada vez que acessar esta Plataforma.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Direitos autorais e propriedade intelectual da We4Science:&nbsp;
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>Ao acessar a Plataforma </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                todos os Usuários se comprometem a respeitar os direitos de
                propriedade intelectual e os decorrentes da proteção de marcas,
                patentes e/ou identidade visual depositados ou registrados em
                nome da{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                , bem como todos os direitos referentes a terceiros que
                porventura estejam ou estiveram de alguma forma disponíveis na
                Plataforma.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                É autorizada a reprodução, distribuição, impressão de cópias
                para distribuição e divulgação, total ou parcial, dos textos,
                imagens, cartilhas e vídeos que compõem a presente Plataforma,
                desde que o conteúdo não seja alterado, particionado ou de
                qualquer forma manipulado de modo que impossibilite o fiel e
                real entendimento de seu conteúdo e objetivo, salvo disposição
                específica para cada conteúdo em página própria dentro da
                própria Plataforma
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Os Usuários assumem toda e qualquer responsabilidade, de caráter
                civil e/ou criminal, pela utilização indevida de dados e
                conteúdo publicados na Plataforma, a exemplo, mas não se
                restringindo às informações, textos, gráficos, marcas, obras e
                imagens.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                O uso comercial das expressões{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>, </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Projetcs and Innovation,{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}> Crowdfunding e </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                Investimento Colaborativo como marca, nome empresarial ou nomes
                de domínio, além do conteúdo e aparência das telas da
                Plataforma, assim como os programas, bancos de dados, redes e
                seus arquivos são de propriedade exclusiva da{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                e estão protegidos pelas leis e tratados internacionais de
                direito autoral, marcas, patentes, modelos e desenhos
                industriais. Sua utilização, replicação, compilação ou qualquer
                forma de reprodução não poderá ser feita sem a autorização
                prévia da{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                A eventual retirada de qualquer conteúdo ou funcionalidade desta
                Plataforma em decorrência de reclamação deverá ser sempre
                compreendida como uma demonstração de nossa intenção de evitar
                dissabores e jamais como reconhecimento de culpa ou de qualquer
                infração pela{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}> a direito de terceiros.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Caso o Usuário envie conteúdo ou ideias para{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                por meio das ferramentas de comunicação disponíveis na
                Plataforma ou pelos canais divulgados que diga respeito a
                possíveis novos produtos ou serviços, melhorias daqueles já
                existentes, campanhas de divulgação ou qualquer outra ação de
                marketing, elas deixarão de pertencer ao patrimônio imaterial do
                Usuário, ingressando no da{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                , nada podendo ser reclamado neste sentido, sobretudo recompensa
                pecuniária pelo que foi enviado ou revisão nos modelos usados.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Sobre Cancelamento ou Congelamento do Perfil de Usuário.
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Todo usuário é livre para cancelar seu perfil a qualquer momento
                em nossa plataforma, por meio de mecanismos próprios para essa
                finalidade disponíveis em seu perfil. No entanto, caso haja
                pendências financeiras e/ou burocráticas, estas deverão ser
                sanadas antes do cancelamento do perfil.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>Disposições Gerais</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                A tolerância ao eventual descumprimento de quaisquer das
                cláusulas e condições do presente instrumento não constituirá
                novação das obrigações aqui estipuladas e tampouco impedirá ou
                inibirá a exigibilidade das mesmas a qualquer tempo.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Esta Plataforma usa como base o horário oficial de Brasília, na
                zona horário GMT -3 no padrão UTC.
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Caso alguma disposição destes Termos de Uso seja considerada
                ilegítima por autoridade da localidade que o Usuário acessa a
                Plataforma ou que mantém vínculo, as demais condições
                permanecerão em pleno vigor e efeito até segunda ordem
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>A </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                poderá alterar os critérios de elegibilidade de sua Plataforma
                ou da realização de atividades nela a qualquer momento. Também
                possui a faculdade de se recusar a conceder conta de acesso a
                qualquer pessoa ou entidade que tiver descumprido previamente os
                Termos de Uso ou documento legal exigido pela Plataforma e teve
                sua conta de acesso suspensa, excluída ou banida por esta razão.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Os conteúdos compartilhados e eventuais ofertas de serviços
                nesta plataforma não devem ser interpretados como uma promessa
                ou garantia de ganhos. Não há garantia de resultados específicos
                e os resultados podem variar de pessoa para pessoa. Seu nível de
                sucesso em alcançar os resultados divulgados com o uso de nossos
                serviços e informações depende do tempo que você dedica à
                campanha, técnicas utilizadas, seu empenho, conhecimento e
                habilidades. Como esses fatores diferem entre os indivíduos, não
                podemos garantir o sucesso e/ou quaisquer níveis de retorno, não
                somos, nem podemos ser, responsáveis por quaisquer de suas
                ações. Todas e quaisquer declarações prospectivas contidas neste
                ambiente e/ou em qualquer um de nossos serviços destinam-se a
                expressar a nossa opinião sobre os ganhos potenciais que algumas
                pessoas podem alcançar.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Limitação de Responsabilidade e Garantias.&nbsp;</strong>
            </p>
            <br /> <br />
            <p>
              <strong>MERCADO WHUFFIE</strong>
              <br /> <br />
              A WE4SCIENCE NÃO É RESPONSÁVEL PELA QUALIDADE, VERACIDADE, ENTREGA
              OU PRESTAÇÃO DOS PRODUTOS, SERVIÇOS E EXPERIÊNCIAS
              DISPONIBILIZADOS EM NOSSA PLATAFORMA. ESSA RESPONSABILIDADE É
              EXCLUSIVA DAS EMPRESAS E ORGANIZAÇÕES CADASTRANTES. QUALQUER
              PROBLEMA, EQUÍVOCO E/OU FALHA ENVOLVENDO A ENTREGA, PRESTAÇÃO OU
              NATUREZA DO PRODUTO, SERVIÇO E EXPERIÊNCIA ADQUIRIDO NO MERCADO
              WHUFFIE, DEVE SER RESOLVIDO ENTRE AS PARTES - USUÁRIO E/OU EMPRESA
              CADASTRANTE – E ISENTA A WE4SCIENCE DE QUALQUER RESPONSABILIDADE,
              RESSALTADO SEU PAPEL DE INTERMEDIADORA. A RESPONSABILIDADE DA
              WE4SCIENCE SE RESUME EM FACILITAR O CONTATO ENTRE A
              EMPRESA/ORGANIZAÇÃO CADASTRANTE E OS USUÁRIOS DA PLATAFORMA, BEM
              COMO, POR MEIO DE ALGORITMOS PRÓPRIOS, REALIZAR A TRANSFERÊNCIA DE
              PONTOS (MOEDAS WHUFFIE) ENTRE OS USUÁRIOS ENVOLVIDOS NAS
              TRANSAÇÕES DENTRO DO MERCADO WHUFFIE.
              <br /> <br />
              <strong>DESAFIOS</strong>
              <br /> <br />
              A WE4SCIENCE NÃO É RESPONSÁVEL PELA VERACIDADE E/OU QUALIDADE DOS
              CONTEÚDOS PUBLICADOS COMO DESAFIOS NA PLATAFORMA, TAMPOUCO SE
              RESPONSABILIZA PELA ENTREGA E/OU PRESTAÇÃO ENTREGA DE PRÊMIOS
              “ESPECIAIS”. TAIS RESPONSABILIDADES COMPETEM EXCLUSIVAMENTE À
              EMPRESA/ORGANIZAÇÃO CADASTRANTE.
              <br /> <br />
              QUALQUER PROBLEMA, EQUÍVOCO E/OU FALHA ENVOLVENDO O CONTEÚDO,
              PROCESSO E/OU PRÊMIOS “ESPECIAIS” DO RECURSO/DESAFIO, DEVE SER
              DIRIMIDO ENTRE AS PARTES - USUÁRIO E/OU EMPRESA CADASTRANTE –
              ISENTANDO A WE4SCIENCE DE QUALQUER RESPONSABILIDADE, RESSALTADO
              SEU PAPEL DE INTERMEDIADORA.
              <br /> <br />A RESPONSABILIDADE DA WE4SCIENCE SE RESUME EM
              FACILITAR O CONTATO ENTRE A EMPRESA/ORGANIZAÇÃO CADASTRANTE E OS
              USUÁRIOS DA PLATAFORMA W4S, BEM COMO, POR MEIO DE ALGORITMOS
              PRÓPRIOS EM REALIZAR A TRANSFERÊNCIA DE PONTOS (MOEDAS WHUFFIE)
              ENTRE OS USUÁRIOS ENVOLVIDOS NAS TRANSAÇÕES DENTRO DO RECURSO
              DESAFIOS.
            </p>
            <p>
              <strong>
                <br />
              </strong>
              <strong>
                <br />
              </strong>
              <span style={{ fontWeight: 400 }}>
                VOCÊ GARANTE QUE NENHUM MATERIAL PUBLICADO POR MEIO DE SUA CONTA
                VIOLA, COPIA OU INFRINGE QUAISQUER DIREITOS DE TERCEIROS,
                INCLUINDO DIREITOS AUTORAIS, DIREITOS DE PROPRIEDADE INTELECTUAL
                E INDUSTRIAL, DIREITO À PRIVACIDADE (SUA OU DE TERCEIROS) OU
                QUALQUER OUTRO DIREITO DE PERSONALIDADE. VOCÊ GARANTE QUE NENHUM
                MATERIAL PUBLICADO POR MEIO DE SUA CONTA VIOLA QUALQUER REGRA,
                ATO OU DECISÃO ADMINISTRATIVA OU LEI. VOCÊ DECLARA SER MAIOR DE
                DEZOITO ANOS DE IDADE.{" "}
              </span>
              <strong>
                CASO VOCÊ TENHA ENTRE DEZESSEIS E DEZOITO ANOS DE IDADE, SEUS
                TUTORES LEGAIS DECLARAM QUE LERAM ESTES TERMOS DE USO COM VOCÊ,
                AUXILIANDO-O(A) NA COMPREENSÃO DESTE DOCUMENTO, E QUE VOCÊ E
                SEUS TUTORES LEGAIS CONCORDAM COM TODOS OS TERMOS AQUI
                DESCRITOS.
              </strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                POR MEIO DESTE ATO VOCÊ ISENTA A PLATAFORMA, SUAS AFILIADAS,
                ACIONISTAS, SÓCIOS, DIRETORES, AGENTES, REPRESENTANTES LEGAIS,
                FUNCIONÁRIOS, CONTRATADOS E/OU PREPOSTOS, DE QUAISQUER
                RESPONSABILIDADES INCLUINDO, SEM LIMITAÇÃO, DE HONORÁRIOS
                ADVOCATÍCIOS.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                EM NENHUM EVENTO A PLATAFORMA, E/OU SUAS AFILIADAS, SEUS
                ACIONISTAS, SÓCIOS, DIRETORES, AGENTES, REPRESENTANTES LEGAIS,
                FUNCIONÁRIOS, CONTRATADOS E/OU PREPOSTOS SÃO RESPONSÁVEIS
                (CONJUNTA OU ISOLADAMENTE) À VOCÊ OU À OUTRA PESSOA DE SEU GRUPO
                SOCIAL POR QUAISQUER PERDA DE LUCRO, DANOS INDIRETOS,
                CONSEQUÊNCIAS, ESPECIAIS, INCIDENTAIS, PUNITIVOS E/OU
                EXEMPLARES, SEM LIMITAÇÃO (INCLUINDO EM CASO DE NEGLIGÊNCIA,
                PREJUÍZO E/OU QUALQUER OUTRA TEORIA DE RESPONSABILIDADE).
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Lei aplicável e Jurisdição</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Estes Termos de Uso, no idioma português, são regidos de acordo
                com a legislação brasileira. Quaisquer disputas e/ou
                controvérsias oriundas de quaisquer atos praticados no âmbito da
                utilização da Plataforma{" "}
              </span>
              <strong>We4Science</strong>
              <span style={{ fontWeight: 400 }}>
                , seus sites, sistemas e/ou dos Serviços prestados pela
                Plataforma e/ou pelos seus usuários, inclusive em caso de
                descumprimento ou violação de outros direitos, serão processadas
                de acordo com essa legislação.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Se qualquer disposição destes Termos de Uso for considerada
                inválida ou inexequível, todas as outras disposições
                permanecerão em pleno vigor e efeito. Neste caso, tal disposição
                inválida ou inexequível será substituída por outra similar, com
                base no contexto e demais condições destes Termos de Uso.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Por fim, fica eleito o Foro da Comarca de Belo Horizonte no
                Estado de Minas Gerais para dirimir qualquer litígio, questão
                e/ou dúvida superveniente, acerca dos presentes termos, com
                expressa renúncia de qualquer outro, por mais privilegiado que
                seja.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>Última atualização: 20/01/2021.</strong>
            </p>
            <p>&nbsp;</p>
          </div>
        </Container>
      </Fade>
    </>
  );
}

export default UseTerms;
