import React, { useState } from "react";
import { toast } from "react-toastify";

import { Container, Page, Content } from "./stylePagination";

const Pagination = ({
  countriesPerPage,
  totalCountries,
  paginate,
  currentPage,
}) => {
  const [pageNumber, setPageNumber] = useState([1, 2, 3, 4, 5]);

  return (
    <Content>
      <Container className="pagination">
        {pageNumber.map((number) => (
          <Page
            key={number}
            active={number === currentPage}
            className="pageItem"
            onClick={() => {
              if (number > Math.ceil(totalCountries / countriesPerPage)) {
                toast.error("Página não Existe!");
              } else {
                paginate(number);
              }

              if (
                number === pageNumber[4] &&
                number < Math.ceil(totalCountries / countriesPerPage)
              ) {
                let arr = [];
                for (let index = 0; index < pageNumber.length; index++) {
                  arr.push(pageNumber[index] + 1);
                }
                setPageNumber(arr);
              } else if (number === pageNumber[0] && number > 1) {
                let arr = [];
                for (let index = 0; index < pageNumber.length; index++) {
                  arr.push(pageNumber[index] - 1);
                }
                setPageNumber(arr);
              }
            }}
          >
            <p className="page-link">{number}</p>
          </Page>
        ))}
      </Container>
    </Content>
  );
};

export default Pagination;
