import styled, { css } from 'styled-components';

export const Message = styled.div `
    width: 536px;
    height: 201px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    margin-bottom:27px;
    margin-top:30px;
    margin-left:10px;

    display:flex;

    ${props => props.sent && css`
        background-color: #fafaff;
        
        margin-left:97px;
    `}

    padding-top:20px;
    padding-left:23px;

    .icon-people{
        .box{
            display:flex;
            align-items:center;
            justify-content:center;

            margin-right:20px;

            width: 70px;
            height: 70px;
            opacity: 0.31;
            border: solid 2px #7f7ebe;
            background:transparent;
            border-radius:50%;

            #border{
                width: 54px;
                height: 54px;
                background-color: #7f7fb9;
                border-radius:50%;

                display:flex;
                align-items:center;
                justify-content:center;
            }
        }
    }

    .content-message{
        margin-top:18px;

        #day,#name{
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;

            margin-bottom:8px;
        }

        #text{
            
            margin-top:10px;

            width: 376px;

            font-family:GothamProRegular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
    }


    @media only screen and (max-width:768px){
        width:80vw ;

        .content-message{
            width:100%;

            #text{
                width:90%;
                font-size:0.7rem;
                word-wrap:break-word;
            }
        }

        ${props => props.sent && css`
            
            
            margin-left:10vw;
        `}
    }
`