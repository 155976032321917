import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 352px;
  height: 624px;
  box-shadow: 0 0 13px 0 rgba(49, 22, 159, 0.09);
  background-color: #fafaff;

  margin-bottom: 39px;
  margin-right: 16px;

  border-radius: 10px;

  @media screen and (max-width: 768px) {
    width: 90vw;
    margin-right: 0;
  }

  @media screen and (max-width: 472px) {
    ${(props) =>
      props.special &&
      css`
        width: 100%;
      `}

    ${(props) =>
      !props.special &&
      css`
        width: 85vw;
      `}
  }
`;
