import React, { useState, useRef, useEffect, useCallback } from "react";

// Libraries
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Fade } from "react-reveal";
import { Form, Input, Textarea, useField } from "@rocketseat/unform";
import { Scrollbars } from "react-custom-scrollbars";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import YouTube from "react-youtube";
import { FormattedNumber, IntlProvider } from "react-intl";
import * as Yup from "yup";
// Redux Modules
import { signOut } from "../../store/modules/auth/actions";
import { updateProfileRequest } from "../../store/modules/user/actions";

// Redux-Store
import { store } from "../../store";

// API
import api from "../../services/api";

// Components
import Footer from "../../components/Footer";
import AvatarInput from "../../components/AvatarInput";
import Pagination from "../../components/Pagination";

import Header from "./Header";

// Styled Components
import {
  Wrapper,
  Content,
  ProfileContent,
  MenuList,
  EditComponent,
  DonationsComponent,
  ProjectsComponent,
  ChallengesComponent,
  CollaborationsComponent,
  HistoricComponent,
  ShoppingComponent,
  NewProjectComponent,
  Product,
  Project,
  ProjectSecond,
  Challenge,
  Button,
  AboutComponent,
  NewsComponent,
  DonersComponent,
  MessagesComponent,
  New,
  DevelopmentTeam,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainerState,
  ListItem,
  DropDownListContainer,
  BoxLabel,
  Preview,
  PreviewsInstitution,
  PreviewsList,
  DevelopmentList,
  ProjectContent,
  Projects,
  BtnNew,
  BoxLabelNew,
  ButtonDonation,
  Cosmos,
  ProjectCollaborations,
  ButtonCollaboration,
  ButtonChamp,
  Collaboration,
  MyProject,
  AboutComponentNew,
  AboutCompanyComponent,
  InputContent,
  BoxProfile,
  AllUsersSearch,
  ContentSelectMember,
} from "./styles";

// External Icons
import {
  FaWhatsapp,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaPhone,
  FaEnvelope,
  FaCheck,
  FaLockOpen,
} from "react-icons/fa";

// Icons
import iconPerfil from "../../assets/icons/Profile/icon-pencil.svg";
import iconClips from "../../assets/icons/Profile/clips.svg";
import iconDonor from "../../assets/icons/Common/avatar-input.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";
import carStore from "../../assets/icons/Common/car-store.svg";
import iconAvatarInput from "../../assets/icons/Common/avatar-input.svg";
import iconDrop from "../../assets/icons/Common/drop-reg.svg";
import iconTrash from "../../assets/icons/Common/trash.svg";
import iconYT from "../../assets/icons/Common/yt.svg";
import iconCam from "../../assets/icons/Common/cam.svg";
// Icons from the other Foulders
import iconEmail from "../../assets/icons/Blog/email-icon.svg";
import iconChat from "../../assets/icons/Chat/icon-chat.svg";
import iconBlade from "../../assets/icons/Challenges/first.svg";
import iconSecondType from "../../assets/icons/Collaboration/second.svg";
import iconThirdType from "../../assets/icons/Collaboration/third.svg";
import iconFourthType from "../../assets/icons/Collaboration/fourth.svg";
import iconCalendarPurple from "../../assets/icons/Project/calendar-purple.svg";
import iconDonor1 from "../../assets/icons/Project/donor-1.svg";
import iconDonor2 from "../../assets/icons/Project/donor-2.svg";
import iconDonor3 from "../../assets/icons/Project/donor-3.svg";
import iconCompanyProject from "../../assets/icons/Projects/company.svg";
import iconCheck from "../../assets/icons/Projects/check.svg";
import iconPencil from "../../assets/icons/Register/pencil.svg";
import iconCollaboration from "../../assets/icons/Profile/icon-supporters.svg";
import iconCollaborationBlue from "../../assets/icons/Collaboration/fourth.svg";
import iconCollaborationWhite from "../../assets/icons/Collaboration/fourthWhite.svg";
import iconTec from "../../assets/icons/Collaboration/second.svg";
import iconTecWhite from "../../assets/icons/Collaboration/secondWhite.svg";
import iconService from "../../assets/icons/Collaboration/third.svg";
import iconServiceWhite from "../../assets/icons/Collaboration/thirdWhite.svg";
import iconProfile from "../../assets/icons/Profile/profiles/scientist.svg";

import id from "../../assets/icons/Project/idBlue.svg";
import user from "../../assets/icons/Donors/user.svg";

import iconProducts from "../../assets/icons/Common/store-2.svg";

// Images
import logo from "../../assets/images/Common/logo.svg";
import { Helmet } from "react-helmet";

const schemaScientist = Yup.object().shape({
  name: Yup.string().required("Nome é obrigatório"),
  phone: Yup.string().required("Telefone é obrigatório"),
  email: Yup.string().email().required("Email é obrigatório"),
  cpf: Yup.string().required("CPF é obrigatório"),
  profession: Yup.string().required("Profissão Item sem descrição"),
  company: Yup.string().required("Empresa/instituição é obrigatório"),
  schooling: Yup.string().required("Nivel de escolaridade é obrigatório"),
  link_curriculum: Yup.string().required("Link para currículo é obrigatório"),
  curriculum: Yup.string().required("Mini currículo é obrigatório"),
  whatsapp: Yup.string(),
  facebook: Yup.string().notRequired(),
  youtube: Yup.string().notRequired(),
  linkedin: Yup.string().notRequired(),
  twitter: Yup.string().notRequired(),
  instagram: Yup.string().notRequired(),
  adress: Yup.string(),
  neighborhood: Yup.string(),
  city: Yup.string(),
  cep: Yup.string(),
  avatar_id: Yup.number(),
});

var date_regex =
  /(^((((0[1-9])|([1-2][0-9])|(3[0-1]))|([1-9]))\x2F(((0[1-9])|(1[0-2]))|([1-9]))\x2F(([0-9]{2})|(((19)|([2]([0]{1})))([0-9]{2}))))$)/;

const schemaProject = Yup.object().shape({
  name: Yup.string()
    .required("Informe o nome do Projeto")
    .max(65, "O nome deve ter no máximo 65 caracteres"),
  start: Yup.string()
    .matches(date_regex, "Use o formato dd/mm/aa")
    .required("Informe a data de Início"),
  end: Yup.string()
    .matches(date_regex, "Use o formato dd/mm/aa")
    .required("Informe a data de Término"),
  value_goal: Yup.number()
    .required("Informe o valor da meta")
    .typeError("Informe um valor numérico (em R$)"),
  about: Yup.string().required("Informe um Resumo do Projeto"),
  summary: Yup.string().required("Descreva o Projeto"),
  who_benefits: Yup.string().required("Informe este campo"),
  partners: Yup.string().required("Informe este campo"),
  goals_objectives: Yup.string().required("Informe este campo"),
  stage: Yup.string().required("Informe este campo"),
  necessary_resources: Yup.string().required("Informe este campo"),
  importance_collaboration: Yup.string().required("Informe este campo"),
  technical_collaboration: Yup.string().required("Informe este campo"),
  institutional_partnerships: Yup.string().required("Informe este campo"),
  services_provision: Yup.string().required("Informe este campo"),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ScientistProfile() {
  let query = useQuery();
  var register = query.get("register");
  const [edit, setEdit] = useState(() =>
    register === "project" ? false : true
  );
  const [donations, setdonations] = useState(() => false);
  const [projects, setProjects] = useState(() =>
    register === "project" ? true : false
  );
  const [challenges, setChallenges] = useState(() => false);
  const [collaborations, setCollaborations] = useState(() => false);
  const [historic, setHistoric] = useState(() => false);
  const [shopping, setShopping] = useState(() => false);
  const [newProject, setNewProject] = useState(() => false);
  const [editProject, setEditProject] = useState(() => false);
  const [collaborationsProject, setCollaborationsProject] = useState(
    () => false
  );

  const [buttonAbout, setButtonAbout] = useState(() => true);
  const [buttonNew, setButtonNew] = useState(() => false);
  const [buttonDoners, setButtonDoners] = useState(() => false);
  const [buttonMessages, setButtonMessages] = useState(() => false);

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const { perfil, token } = store.getState().auth;

  const [myDonations, setMyDonations] = useState([]);
  const [cosmos, setCosmos] = useState([]);
  const [extracts, setExtracts] = useState([]);
  const [arrTechnical, setArrTechnical] = useState([]);
  const [arrService, setArrService] = useState([]);
  const [arrInstitutional, setArrInstitutional] = useState([]);

  const [myCollaborations, setMyCollaborations] = useState([]);

  const [myShoppings, setMyShoppings] = useState([]);
  const [projectDonations, setProjectDonations] = useState([]);

  useEffect(() => {
    const loadMyCollaborations = async () => {
      if (!!token) {
        const res = await api.get(
          `/collaborations/${profile && profile.id}/${3}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyCollaborations(data);
      }
    };
    loadMyCollaborations();
  }, [profile, token]);

  useEffect(() => {
    const loadMyShoppings = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-shoppings/${profile && profile.id}/${3}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyShoppings(data);
      }
    };
    loadMyShoppings();
  }, [profile, token]);

  useEffect(() => {
    const loadMyDonations = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-donations/${profile && profile.id}/${3}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyDonations(data);
      }
    };
    loadMyDonations();
  }, [profile, token]);

  useEffect(() => {
    const loadMyDonations = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-donations-cosmos/${profile && profile.id}/${3}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setCosmos(data);
      }
    };
    loadMyDonations();
  }, [profile, token]);

  useEffect(() => {
    const loadMyDonations = async () => {
      if (!!token) {
        const res = await api.get(`/extracts/${profile && profile.id}/${3}`);
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setExtracts(data);
      }
    };
    loadMyDonations();
  }, [profile, token]);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const FetchCountries = async () => {
      if (!!token) {
        const res = await api.get(`/my-projects/${profile && profile.id}`);
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setCountries(data);
      }
    };
    FetchCountries();
  }, [profile, token]); // [] avoid useEffect() to run and make an never ended loop at each updates

  async function handleGetCollaborationsProject(id) {
    const res = await api.get(`/collaborations-project/${id}`);
    const data = res.data.map((project) => ({
      ...project,
      formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
        locale: pt,
      }),
    }));
    setArrTechnical(data.filter((item) => item.kind === "technical"));
    setArrService(data.filter((item) => item.kind === "service"));
    setArrInstitutional(data.filter((item) => item.kind === "institutional"));
  }

  async function handlePayments(id) {
    const res = await api.get(`payments-project/${id}`);
    const data = res.data.map((project) => ({
      ...project,
      formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
        locale: pt,
      }),
    }));

    setProjectDonations(data);
  }

  function handleEditClick() {
    if (edit === false) {
      setEdit(!edit);

      if (donations === true) {
        setdonations(!donations);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newProject === true) {
        setNewProject(!newProject);
      }
      if (editProject === true) {
        setEditProject(!editProject);
      }
      if (collaborationsProject === true) {
        setCollaborationsProject(!collaborationsProject);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleDonationsClick() {
    if (donations === false) {
      setdonations(!donations);

      if (edit === true) {
        setEdit(!edit);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newProject === true) {
        setNewProject(!newProject);
      }
      if (editProject === true) {
        setEditProject(!editProject);
      }
      if (collaborationsProject === true) {
        setCollaborationsProject(!collaborationsProject);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleProjectsClick() {
    if (projects === false) {
      setProjects(!projects);

      if (donations === true) {
        setdonations(!donations);
      }
      if (edit === true) {
        setEdit(!edit);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newProject === true) {
        setNewProject(!newProject);
      }
      if (editProject === true) {
        setEditProject(!editProject);
      }
      if (collaborationsProject === true) {
        setCollaborationsProject(!collaborationsProject);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleChallengesClick() {
    if (challenges === false) {
      setChallenges(!challenges);

      if (donations === true) {
        setdonations(!donations);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (edit === true) {
        setEdit(!edit);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newProject === true) {
        setNewProject(!newProject);
      }
      if (editProject === true) {
        setEditProject(!editProject);
      }
      if (collaborationsProject === true) {
        setCollaborationsProject(!collaborationsProject);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleCollaborationsClick() {
    if (collaborations === false) {
      setCollaborations(!collaborations);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setdonations(!donations);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newProject === true) {
        setNewProject(!newProject);
      }
      if (editProject === true) {
        setEditProject(!editProject);
      }
      if (collaborationsProject === true) {
        setCollaborationsProject(!collaborationsProject);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleHistoricClick() {
    if (historic === false) {
      setHistoric(!historic);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setdonations(!donations);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newProject === true) {
        setNewProject(!newProject);
      }
      if (editProject === true) {
        setEditProject(!editProject);
      }
      if (collaborationsProject === true) {
        setCollaborationsProject(!collaborationsProject);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleShoppingClick() {
    if (shopping === false) {
      setShopping(!shopping);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setdonations(!donations);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (newProject === true) {
        setNewProject(!newProject);
      }
      if (editProject === true) {
        setEditProject(!editProject);
      }
      if (collaborationsProject === true) {
        setCollaborationsProject(!collaborationsProject);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
    }
  }

  function handleNewProjectClick() {
    if (
      profile.name === null ||
      profile.phone === null ||
      profile.email === null ||
      profile.cpf === null ||
      profile.profession === null ||
      profile.company === null ||
      profile.curriculum === null ||
      profile.schooling === null ||
      profile.link_curriculum === null
    ) {
      toast.error("Edite seus dados pessoais antes de cadastrar um Projeto!");

      setTimeout(() => {
        setProjects(!projects);
        setEdit(true);
      }, 2500);
    } else {
      if (newProject === false) {
        setNewProject(!newProject);
        setInstitutions([]);
        setDevelopment([]);

        setNewItem("");

        if (edit === true) {
          setEdit(!edit);
        }
        if (donations === true) {
          setdonations(!donations);
        }
        if (projects === true) {
          setProjects(!projects);
        }
        if (challenges === true) {
          setChallenges(!challenges);
        }
        if (collaborations === true) {
          setCollaborations(!collaborations);
        }
        if (historic === true) {
          setHistoric(!historic);
        }
        if (shopping === true) {
          setShopping(!shopping);
        }
        if (editProject === true) {
          setEditProject(!editProject);
        }
        if (collaborationsProject === true) {
          setCollaborationsProject(!collaborationsProject);
        }
        if (aboutCompany === true) {
          setAboutCompany(!aboutCompany);
        }
      }
    }
  }

  function handleAboutClick() {
    if (buttonAbout === false) {
      setButtonAbout(!buttonAbout);

      if (buttonNew === true) {
        setButtonNew(!buttonNew);
      }
      if (buttonDoners === true) {
        setButtonDoners(!buttonDoners);
      }
      if (buttonMessages === true) {
        setButtonMessages(!buttonMessages);
      }
    }
  }

  function handleNewClick() {
    if (buttonNew === false) {
      setButtonNew(!buttonNew);

      if (buttonAbout === true) {
        setButtonAbout(!buttonAbout);
      }
      if (buttonDoners === true) {
        setButtonDoners(!buttonDoners);
      }
      if (buttonMessages === true) {
        setButtonMessages(!buttonMessages);
      }
    }
  }

  function handleDonersClick() {
    if (buttonDoners === false) {
      setButtonDoners(!buttonDoners);

      if (buttonNew === true) {
        setButtonNew(!buttonNew);
      }
      if (buttonAbout === true) {
        setButtonAbout(!buttonAbout);
      }
      if (buttonMessages === true) {
        setButtonMessages(!buttonMessages);
      }
    }
  }

  function handleMessagesClick() {
    if (buttonMessages === false) {
      setButtonMessages(!buttonMessages);

      if (buttonNew === true) {
        setButtonNew(!buttonNew);
      }
      if (buttonDoners === true) {
        setButtonDoners(!buttonDoners);
      }
      if (buttonAbout === true) {
        setButtonAbout(!buttonAbout);
      }
    }
  }

  const [technical, setTechnical] = useState(true);
  const [service, setService] = useState(false);
  const [institutional, setInstitutional] = useState(false);

  function handleTechnical() {
    if (technical === false) {
      setTechnical(!technical);
      if (service === true) {
        setService(!service);
      }
      if (institutional === true) {
        setInstitutional(!institutional);
      }
    }
  }
  function handleService() {
    if (service === false) {
      setService(!service);
      if (technical === true) {
        setTechnical(!technical);
      }
      if (institutional === true) {
        setInstitutional(!institutional);
      }
    }
  }
  function handleInstitutional() {
    if (institutional === false) {
      setInstitutional(!institutional);
      if (technical === true) {
        setTechnical(!technical);
      }
      if (service === true) {
        setService(!service);
      }
    }
  }

  let history = useHistory();

  function handleSignOut() {
    dispatch(signOut());
    setTimeout(function () {
      history.push("/register");
    }, 1000);
  }

  function handleSubmit(data) {
    data.perfil = perfil;
    if (selectedOptionSex) {
      data.sex = selectedOptionSex;
    } else if (profile.sex === null && !selectedOptionSex) {
      toast.error("Informe seu Sexo!");
    }

    if (selectedOptionState) {
      data.state = selectedOptionState;
    }

    dispatch(updateProfileRequest(data));
  }

  const optionsStates = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const optionsSex = ["Feminino", "Masculino", "Outro", "Não Informar"];

  const [isOpenSex, setIsOpenSex] = useState(false);
  const [isOpenState, setIsOpenState] = useState(false);

  const [selectedOptionSex, setSelectedOptionSex] = useState(null);
  const [selectedOptionState, setSelectedOptionState] = useState(null);

  const togglingSex = () => setIsOpenSex(!isOpenSex);
  const togglingState = () => setIsOpenState(!isOpenState);

  const onOptionClickedSex = (value) => () => {
    setSelectedOptionSex(value);
    setIsOpenSex(false);
  };

  const onOptionClickedState = (value) => () => {
    setSelectedOptionState(value);
    setIsOpenState(false);
  };

  const [accept, setAccept] = useState(false);
  const [special, setSpecial] = useState(false);

  const toggleAccept = () => setAccept(!accept);

  async function handleSubmitProject(data) {
    if (!accept) {
      toast.error("Termo não aceito!");
    } else {
      data.scientist_id = profile.id;

      if (items[0]) {
        data.first_img = items[0].id;
      }
      if (items[1]) {
        data.second_img = items[1].id;
      }

      if (items[2]) {
        data.third_img = items[2].id;
      }

      var aux = "";

      for (let index = 0; index < instutions.length; index++) {
        aux = aux + instutions[index].company + ";";
      }
      data.institution = aux;

      aux = "";

      if (development.length === 0) {
        toast.error("Adicione um Integrante ao projeto");
      }

      for (let index = 0; index < development.length; index++) {
        aux = aux + development[index].id + ";";
      }
      data.member = aux;

      if (selectedOptionTypeProject) {
        data.category = selectedOptionTypeProject;
      } else {
        toast.error("Adicione uma categoria ao Projeto");
      }

      data.current_value = 0;

      if (special) {
        data.special = true;
      } else {
        if (instutions.length === 0) {
          toast.error("Adicione a instituição");
        }
      }

      try {
        await api.post("projects", data);
        toast.success("Sucesso ao cadastrar projeto!");
        setNewItem("");
        setTitleProject("");
        setTimeout(function () {
          history.push("/congratulations");
        }, 1500);
      } catch (error) {
        toast.error("Erro ao cadastrar projeto!");
      }
    }
  }

  // ------------------------ FILES----------------------
  const { registerField } = useField("first_img");

  const ref = useRef();

  const [items, setItems] = useState([]);

  const addItem = (newItem) => {
    setItems([
      ...items,
      {
        id: newItem.id,
        url: newItem.url,
      },
    ]);
  };

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: "first_img",
        ref: ref.current,
        path: "dataset.file",
      });
    }
  }, [ref, registerField]);

  async function handleChange(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    addItem(newfile);
  }

  // ------------------------PAGINAÇÃO--------------------

  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(2);

  //get the currents countries to display in page

  const paginate = (number) => setCurrentPage(number);

  const indexLastCountries = currentPage * countriesPerPage;
  const indexFirstCountries = indexLastCountries - countriesPerPage;
  const currentCountries = countries.slice(
    indexFirstCountries,
    indexLastCountries
  );
  // --------------------PROJECTS----------------------------

  const [isOpenTypeProject, setIsOpenTypeProject] = useState(false);

  const [selectedOptionTypeProject, setSelectedOptionTypeProject] =
    useState(null);

  const togglingTypeProject = () => setIsOpenTypeProject(!isOpenTypeProject);

  const onOptionClickedTypeProject = (value) => () => {
    setSelectedOptionTypeProject(value);
    setIsOpenTypeProject(false);
  };

  const listOfTypes = [
    "Agronegócio",
    "Meio ambiente e Sustentabilidade",
    "Indústria",
    "Comércio e prestação de serviços",
    "Desenvolvimento Urbano",
    "Artes e Cultura",
    "Ciência e Tecnologia",
    "Economia",
    "Educação",
    "Saúde",
    "Filosofia e Sociologia",
    "Entretenimento",
    "Infantil",
    "Internet",
    "Negócios e Oportunidades",
    "Política",
    "Produtos e Serviços",
    "Responsabilidade Social",
    "Mídia e Comunicação",
  ];

  function deleteImg(item) {
    const newList = items.filter((items) => items.id !== item.id);
    setItems(newList);
    api.delete(`files/${item.id}`);
  }

  const [allScientists, setAllScientists] = useState([]);
  useEffect(() => {
    const loadScientists = async () => {
      if (!!token) {
        const res = await api.get("all-scientists");

        setAllScientists(res.data);
      }
    };
    loadScientists();
  }, [token]);

  const [allInstitutions, setAllInstitutions] = useState([]);
  useEffect(() => {
    const loadScientists = async () => {
      if (!!token) {
        const res = await api.get("all-institutions");

        setAllInstitutions(res.data);
      }
    };
    loadScientists();
  }, [token]);

  const [instutions, setInstitutions] = useState([]);
  const [development, setDevelopment] = useState([]);
  const [searchByNameScientists, setSearchByNameScientists] = useState([]);

  const [searchId, setSearchId] = useState("");

  const [isOpenTypeInstitution, setIsOpenTypeInstitution] = useState(false);

  const [selectedOptionTypeInstitution, setSelectedOptionTypeInstitution] =
    useState(null);

  const togglingTypeInstitution = () => {
    setIsOpenTypeInstitution(!isOpenTypeInstitution);
  };

  const onOptionClickedTypeInstitution = (value) => () => {
    setSelectedOptionTypeInstitution(value);
    let aux = instutions;
    aux.push(
      allInstitutions.filter(
        (institution) => institution.company === value.company
      )[0]
    );

    setInstitutions(aux);
    setIsOpenTypeInstitution(false);
    setSelectedOptionTypeInstitution(null);
  };

  function handleRemoveInstitution(option) {
    setInstitutions(instutions.filter((institution) => institution !== option));
  }

  function addDeveloperScientist() {
    let aux = development;
    aux.push(
      allScientists.filter((scientist) => scientist.id === profile.id)[0]
    );
    setDevelopment(aux);
  }

  function searchScientist(name) {
    if (
      allScientists.filter((scientists) => scientists.name.includes(name))
        .length === 0
    ) {
      toast.error("Nenhum usuário encontrado!");
    } else {
      setSearchByNameScientists(
        allScientists.filter((scientists) => scientists.name.includes(name))
      );
    }
  }

  function addDeveloper(value) {
    if (
      allScientists.filter((scientist) => scientist.id === value)[0] ===
      undefined
    ) {
      toast.error("Usuário não Existe");
    } else {
      let aux = development;
      aux.push(allScientists.filter((scientist) => scientist.id === value)[0]);
      setDevelopment(aux);
      setSearchId(null);
      setSearchByNameScientists([]);
    }
  }

  function handleRemoveDeveloper(option) {
    setDevelopment(development.filter((institution) => institution !== option));
  }

  const [getProject, setGetProject] = useState(null);
  const [newsProject, setNewsProject] = useState([]);

  async function getNewsProject(id) {
    const res = await api.get(`project-news/${id}`);

    const data = res.data.map((project) => ({
      ...project,
      formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
        locale: pt,
      }),
    }));

    setNewsProject(data);
  }

  async function handleUpdateProject(data) {
    try {
      data.first_img = arrayEdit[0].id;
      data.second_img = arrayEdit[1].id;
      data.third_img = arrayEdit[2].id;

      var aux = "";

      for (let index = 0; index < instutions.length; index++) {
        aux = aux + instutions[index].company + ";";
      }
      data.institution = aux;
      aux = "";

      for (let index = 0; index < development.length; index++) {
        aux = aux + development[index].id + ";";
      }

      data.member = aux;

      if (selectedOptionTypeProject) {
        data.category = selectedOptionTypeProject;
      }


      if (
        !data.first_img ||
        !data.second_img ||
        !data.third_img
      ) {
        toast.error("Erro ao editar Projeto! Cadastre 3 imagens");
      } else {
        await api.put(`project/${getProject && getProject[0].id}`, data);
        toast.success("Projeto Atualizado");
        setNewItem("");
        setTimeout(function () {
          history.go(0);
        }, 1500);
      }
    } catch (error) {
      toast.success(`Erro ao editar Projeto!`);
    }
  }

  const [arrayEdit, setArrayEdit] = useState([]);
  var auxArrayEdit = [];

  const addArr = (newItem) => {
    setArrayEdit([
      ...arrayEdit,
      {
        id: newItem.id,
        url: newItem.url,
      },
    ]);
  };

  async function handleChangeEdit(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    addArr(newfile);
  }

  function changeArrForInstitutions(arr) {
    var temporary = [];

    for (let index = 0; index < arr.length; index++) {
      if (
        allInstitutions.filter(
          (scientist) => scientist.company === arr[index]
        )[0] !== null ||
        allInstitutions.filter(
          (scientist) => scientist.company === arr[index]
        )[0] !== undefined
      ) {
        temporary.push(
          allInstitutions.filter(
            (scientist) => scientist.company === arr[index]
          )[0]
        );
      }
    }
    setInstitutions(temporary);
  }

  function changeArrForMembers(arr) {
    var temporary = [];

    for (let index = 0; index < arr.length; index++) {
      if (
        allScientists.filter(
          (scientist) => scientist.id === parseInt(arr[index])
        )[0] !== null ||
        allScientists.filter(
          (scientist) => scientist.id === parseInt(arr[index])
        )[0] !== undefined
      ) {
        temporary.push(
          allScientists.filter(
            (scientist) => scientist.id === parseInt(arr[index])
          )[0]
        );
      }
    }
    setDevelopment(temporary);
  }

  const [addLinkYt, setAddLinkYt] = useState(false);
  const [addImageNew, setAddImageNew] = useState(false);
  const [linkYT, setLinkYT] = useState("");
  const [imageNew, setImageNew] = useState(null);

  const togglingYt = () => {
    if (linkYT !== "" && addLinkYt && !imageNew) {
      toast.error("Opção de vídeo já selecionada!");
    } else {
      setAddLinkYt(!addLinkYt);
    }
  };

  const togglingImage = () => {
    setAddImageNew(!addImageNew);
  };

  async function handleChangeEditImage(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    setImageNew(newfile);
  }

  function deleteImgNew() {
    setImageNew(null);
    api.delete(`files/${imageNew.id}`);
  }

  async function handleAddNew(data) {
    if (imageNew) {
      data.image = imageNew.id;
    }

    if (linkYT) {
      data.yt_link = linkYT;
    }

    data.project_id = getProject[0].id;

    await api.post("project-news", data);
    toast.success("Novidade Inserida");
    setTimeout(function () {
      history.go(0);
    }, 1500);
    setAddLinkYt(false);
    setAddImageNew(false);
    setLinkYT("");
    setImageNew(null);
  }

  const opts = {
    height: "335",
    width: "634",
    playerVars: {
      autoplay: 0,
    },
  };

  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/); // eslint-disable-next-line
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  async function deleteNews(id) {
    try {
      await api.delete(`project-news/${id}`);
      toast.success("Novidade excluída!");
      setTimeout(function () {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir novidade!");
    }
  }

  // ------------------------PAGINAÇÃO--------------------
  const [countriesDepositions, setCountriesDepositions] = useState([]);
  const [currentPageDepositions, setCurrentPageDepositions] = useState(1);
  const [countriesPerPageDepositions] = useState(3);

  async function getDepositions(id) {
    const res = await api.get(`/depositions-all/${id}`);

    const data = res.data.map((project) => ({
      ...project,
      formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
        locale: pt,
      }),
    }));
    setCountriesDepositions(data);
  }

  const paginateDepositions = (number) => setCurrentPageDepositions(number);

  const indexLastCountriesDepositions =
    currentPageDepositions * countriesPerPageDepositions;
  const indexFirstCountriesDepositions =
    indexLastCountriesDepositions - countriesPerPageDepositions;
  const currentCountriesDepositions = countriesDepositions.slice(
    indexFirstCountriesDepositions,
    indexLastCountriesDepositions
  );

  async function deleteDeposition(id) {
    try {
      await api.delete(`depositions/${id}`);
      toast.success("Depoimento excluido!");
    } catch (error) {
      toast.success("Depoimento excluido!");
    }
  }

  async function acceptsDeposition(id) {
    try {
      await api.put(`depositions/${id}`);
      toast.success("Depoimento aceito!");
    } catch (error) {
      toast.success("erro ao Aceitar Depoimento!");
    }
  }

  const [allMyChallenges, setAllMyChallenges] = useState([]);

  useEffect(() => {
    const loadChallenges = async () => {
      if (!!token) {
        const resOne = await api.get(
          `/doing-scientist/${profile && profile.id}`
        );

        const resTwo = await api.get(
          `/sended-scientists/${profile && profile.id}`
        );

        var auxAll = [];
        resOne.data.map(
          (item) =>
            auxAll.filter((i) => i.challenge_id === item.challenge_id).length <
              1 && auxAll.push(item)
        );
        resTwo.data.map(
          (item) =>
            auxAll.filter((i) => i.challenge_id === item.challenge_id).length <
              1 && auxAll.push(item)
        );

        setAllMyChallenges(auxAll);
      }
    };
    loadChallenges();
  }, [profile, token]);

  const [currentPageChallenges, setCurrentPageChallenges] = useState(1);
  const [countriesPerPageChallenges] = useState(1);

  const paginateChallenges = (number) => setCurrentPageChallenges(number);

  const indexLastChallenges =
    currentPageChallenges * countriesPerPageChallenges;
  const indexFirstChallenges = indexLastChallenges - countriesPerPageChallenges;
  const currentChallenges = allMyChallenges.slice(
    indexFirstChallenges,
    indexLastChallenges
  );

  const [currentPageMyDonatios, setCurrentPageMyDonatios] = useState(1);
  const [countriesPerPageMyDonatios] = useState(1);

  const paginateMyDonatios = (number) => setCurrentPageMyDonatios(number);

  const indexLastMyDonatios =
    currentPageMyDonatios * countriesPerPageMyDonatios;
  const indexFirstMyDonatios = indexLastMyDonatios - countriesPerPageMyDonatios;
  const currentMyDonatios = myDonations.slice(
    indexFirstMyDonatios,
    indexLastMyDonatios
  );

  const [btnProjectDonations, setBtnProjectDonations] = useState(true);
  const [btnCosmosDonations, setBtnCosmosDonations] = useState(false);

  const toggleBtnProject = () => {
    if (!btnProjectDonations && btnCosmosDonations) {
      setBtnProjectDonations(true);
      setBtnCosmosDonations(false);
    }
  };

  const toggleBtnCosmos = () => {
    if (!btnCosmosDonations && btnProjectDonations) {
      setBtnCosmosDonations(true);
      setBtnProjectDonations(false);
    }
  };

  const [currentPageMyDonatiosCosmos, setCurrentPageMyDonatiosCosmos] =
    useState(1);
  const [countriesPerPageMyDonatiosCosmos] = useState(1);

  const paginateMyDonatiosCosmos = (number) =>
    setCurrentPageMyDonatiosCosmos(number);

  const indexLastMyDonatiosCosmos =
    currentPageMyDonatiosCosmos * countriesPerPageMyDonatiosCosmos;
  const indexFirstMyDonatiosCosmos =
    indexLastMyDonatiosCosmos - countriesPerPageMyDonatiosCosmos;
  const currentMyDonatiosCosmos = cosmos.slice(
    indexFirstMyDonatiosCosmos,
    indexLastMyDonatiosCosmos
  );

  const [currentPageExtracts, setCurrentPageExtracts] = useState(1);
  const [countriesPerPageExtracts] = useState(8);

  const paginateExtracts = (number) => setCurrentPageExtracts(number);

  const indexLastExtracts = currentPageExtracts * countriesPerPageExtracts;
  const indexFirstExtracts = indexLastExtracts - countriesPerPageExtracts;
  const currentExtracts = extracts.slice(indexFirstExtracts, indexLastExtracts);

  /*   pagination collaborations           */

  /*   pagination collaborations technical */

  const [currentPageTechnical, setCurrentPageTechnical] = useState(1);
  const [countriesPerPageTechnical] = useState(2);

  const paginateTechnical = (number) => setCurrentPageTechnical(number);

  const indexLastTechnical = currentPageTechnical * countriesPerPageTechnical;
  const indexFirstTechnical = indexLastTechnical - countriesPerPageTechnical;
  const currentTechnical = arrTechnical.slice(
    indexFirstTechnical,
    indexLastTechnical
  );

  /*   pagination collaborations service   */

  const [currentPageService, setCurrentPageService] = useState(1);
  const [countriesPerPageService] = useState(2);

  const paginateService = (number) => setCurrentPageService(number);

  const indexLastService = currentPageService * countriesPerPageService;
  const indexFirstService = indexLastService - countriesPerPageService;
  const currentService = arrService.slice(indexFirstService, indexLastService);

  /*   pagination collaborations  institutional  */

  const [currentPageInstitutional, setCurrentPageInstitutional] = useState(1);
  const [countriesPerPageInstitutional] = useState(2);

  const paginateInstitutional = (number) => setCurrentPageInstitutional(number);

  const indexLastInstitutional =
    currentPageInstitutional * countriesPerPageInstitutional;
  const indexFirstInstitutional =
    indexLastInstitutional - countriesPerPageInstitutional;
  const currentInstitutional = arrInstitutional.slice(
    indexFirstInstitutional,
    indexLastInstitutional
  );

  async function handleAcceptCollaboration(id) {
    try {
      await api.put(`accept-collaboration/${id}`);
      toast.success("Colaboração aceita!");
      setTimeout(function () {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("erro ao Aceitar colaboração!");
    }
  }

  /*    PAGINATION USER COLLABORATIONS       */

  const [currentPageCollaborations, setCurrentPageCollaborations] = useState(1);
  const [countriesPerPageCollaborations] = useState(8);

  const paginateCollaborations = (number) =>
    setCurrentPageCollaborations(number);

  const indexLastCollaborations =
    currentPageCollaborations * countriesPerPageCollaborations;
  const indexFirstCollaborations =
    indexLastCollaborations - countriesPerPageCollaborations;
  const currentCollaborations = myCollaborations.slice(
    indexFirstCollaborations,
    indexLastCollaborations
  );

  /*    PAGINATION USER MY SHOPPINGS       */

  const [currentPageShoppings, setCurrentPageShoppings] = useState(1);
  const [countriesPerPageShoppings] = useState(4);

  const paginateShoppings = (number) => setCurrentPageShoppings(number);

  const indexLastShoppings = currentPageShoppings * countriesPerPageShoppings;
  const indexFirstShoppings = indexLastShoppings - countriesPerPageShoppings;
  const currentShoppings = myShoppings.slice(
    indexFirstShoppings,
    indexLastShoppings
  );

  /* PROJECT DONATIONS PAGINATIONS */

  const [currentPageDonations, setCurrentPageDonatios] = useState(1);
  const [countriesPerPageDonations] = useState(4);
  const paginateDonations = (number) => setCurrentPageDonatios(number);

  const indexLastCountriesDonations =
    currentPageDonations * countriesPerPageDonations;
  const indexFirstCountriesDonations =
    indexLastCountriesDonations - countriesPerPageDonations;
  const currentCountriesDonations = projectDonations.slice(
    indexFirstCountriesDonations,
    indexLastCountriesDonations
  );

  const [newGoal, setNewGoal] = useState(false);

  const toogleNewGoal = () => setNewGoal(!newGoal);

  const [newGoalValue, setNewGoalValue] = useState("");
  const [newGoalJustify, setNewGoalJustify] = useState("");

  async function handleNewGoal() {
    if (newGoalValue === "" || newGoalJustify === "") {
      toast.error("Complete as informações");
    } else {
      var data = Object.assign({
        value_goal: newGoalValue,
        justify: newGoalJustify,
        project_id: getProject[0].id,
      });
      try {
        await api.post("goals", data);
        toast.success("Solicitação enviada!");
      } catch (error) {
        toast.error("Erro ao enviar nova meta!");
      }
      setNewGoal(false);
    }
  }

  const [aboutCompany, setAboutCompany] = useState(false);
  const [companyInfos, setCompanyInfos] = useState(null);

  const toggle = () => setAboutCompany(true);

  const [roomTechnical, setRoomTechnical] = useState([]);
  const [roomService, setRoomService] = useState([]);
  const [roomInstitutional, setRoomInstitutional] = useState([]);

  const [chatTech, setChatTec] = useState(false);
  const [chatSerc, setChatSerc] = useState(false);
  const [chatInst, setChatInst] = useState(false);

  const toogleTec = () => setChatTec(!chatTech);
  const toogleSer = () => setChatSerc(!chatSerc);
  const toogleIns = () => setChatInst(!chatInst);

  async function handleChats(id) {
    const { data } = await api.get(`messages-project/${id}`);

    setRoomTechnical(data.technical);
    setRoomService(data.service);
    setRoomInstitutional(data.institutional);
  }

  const [content, setContent] = useState(
    profile && profile.curriculum !== null ? profile.curriculum : ""
  );
  const [newItem, setNewItem] = useState("");

  const setFormattedContent = useCallback(
    (text) => {
      setContent(text.slice(0, 145));
    },
    [setContent]
  );

  const [titleProject, setTitleProject] = useState("");

  const setFormattedProject = useCallback(
    (text) => {
      setTitleProject(text.slice(0, 145));
    },
    [setTitleProject]
  );

  function completeLink(link) {
    var finalLink;
    if (link !== null) {
      if (
        link.substring(0, 7) === "http://" ||
        link.substring(0, 8) === "https://"
      ) {
        finalLink = link;
      } else {
        finalLink = "https://www." + link;
      }
      return finalLink;
    } else {
      return link;
    }
  }

  return (
    <>
      <Helmet>
        <title>Perfil Cientista</title>
      </Helmet>
      <Wrapper>
        <Content>
          <Header />

          <ProfileContent>
            <aside>
              <BoxProfile>
                <img src={iconProfile} alt="" />
                <div>
                  <p id="title">Perfil</p>
                  <p id="normal">Cientista</p>
                </div>
              </BoxProfile>

              <div className="avatar">
                <div className="avatar-img">
                  <div className="border">
                    <div className="img-content">
                      {profile && profile.avatar !== null ? (
                        <img
                          id="img-profile"
                          src={profile && profile.avatar.url}
                          alt=""
                        />
                      ) : (
                        <img
                          id="without-img-profile"
                          src={iconAvatarInput}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="pencil">
                    <img src={iconPerfil} alt="" />
                  </div>
                </div>
                <hr />
                <div className="infos">
                  {profile && <p>{profile.name.split(" ")[0]}</p>}

                  {profile && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <IntlProvider locale="pt-BR">
                          <p>
                            <FormattedNumber
                              minimumFractionDigits={2}
                              value={profile.whuffie}
                            />
                          </p>
                        </IntlProvider>
                        <p id="whuffie" style={{ marginLeft: 5 }}>
                          {" "}
                          W${" "}
                        </p>
                      </div>
                      <p style={{ fontStyle: "italic", color: "#ffe" }}>
                        Saldo Whuffie
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="menu-profile">
                <div className="menu">
                  <MenuList active={edit} onClick={handleEditClick}>
                    Editar Dados
                  </MenuList>
                  <MenuList
                    active={
                      projects ||
                      newProject ||
                      editProject ||
                      collaborationsProject
                    }
                    onClick={handleProjectsClick}
                  >
                    Meus Projetos
                  </MenuList>
                  <MenuList active={challenges} onClick={handleChallengesClick}>
                    Meus Desafios
                  </MenuList>
                  <MenuList active={donations} onClick={handleDonationsClick}>
                    Minhas Doações
                  </MenuList>

                  <MenuList
                    active={collaborations}
                    onClick={handleCollaborationsClick}
                  >
                    Minhas Colaborações
                  </MenuList>
                  <MenuList active={historic} onClick={handleHistoricClick}>
                    Minhas Moedas Whuffie
                  </MenuList>
                  <MenuList
                    active={shopping || aboutCompany}
                    onClick={handleShoppingClick}
                  >
                    Minhas Compras Whuffie
                  </MenuList>
                  <MenuList
                    onClick={() => {
                      setTimeout(() => {
                        history.push("contact?&type=support");
                      }, 300);
                    }}
                  >
                    Suporte W4S
                  </MenuList>
                </div>
              </div>
              <button id="btn-company-profile" onClick={handleSignOut}>
                Cadastrar em outro Perfil / Sair
              </button>
              <button
                id="btn-company-profile"
                onClick={() => {
                  history.push("delete-profile");
                }}
              >
                Excluir Perfil
              </button>
            </aside>
            {edit && (
              <Fade left duration={1600} distance="20px">
                <EditComponent>
                  <p id="title"> Editar Dados Pessoais </p>
                  <p className="text">
                    Aqui você pode editar suas informações pessoais e
                    preferências sempre que quiser!
                    <br />E lembre-se: se surgir alguma dúvida, consulte o FAQ
                    ou envie uma mensagem para nós pela página{" "}
                    <Link
                      to="contact?&type=normal"
                      style={{
                        color: "#227af1",
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      Fale Conosco
                    </Link>
                    .
                  </p>
                  <div className="form">
                    <Form
                      schema={schemaScientist}
                      initialData={profile}
                      onSubmit={handleSubmit}
                    >
                      <p id="title"> Dados Pessoais </p>
                      <div className="avatar">
                        <AvatarInput name="avatar_id" />
                        <div className="pencil">
                          <img src={iconPencil} alt="" />
                        </div>
                        <p id="input-photo"> Inserir foto de perfil </p>
                      </div>
                      <p style={{ color: "#7f7fb9", fontSize: "0.9rem" }}>
                        Obs: utilize uma imagem com pelo menos 112x112 pixels
                      </p>
                      <div className="inputs">
                        <InputContent>
                          <Input
                            className="margin"
                            type="text"
                            name="name"
                            placeholder="Nome Completo (sem abreviações): *"
                          />
                        </InputContent>
                        <DropDownContainer>
                          <DropDownHeader id="small" onClick={togglingSex}>
                            <p>
                              {selectedOptionSex ||
                                (profile && profile.sex != null
                                  ? profile.sex
                                  : "Sexo")}
                            </p>
                            {isOpenSex ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpenSex && (
                            <DropDownListContainer>
                              <DropDownList>
                                <Scrollbars style={{ width: 340, height: 50 }}>
                                  {optionsSex.map((option) => (
                                    <ListItem
                                      onClick={onOptionClickedSex(option)}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>
                        <InputContent>
                          <Input
                            type="text"
                            name="phone"
                            placeholder="Telefone (com DDD): * (ddd) 1234-5678"
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            type="text"
                            name="email"
                            placeholder="Seu melhor e-mail: *"
                          />
                        </InputContent>
                        <Input
                          type="text"
                          name="whatsapp"
                          placeholder="Whatsapp: (ddd) 1234-5678"
                        />
                        <div className="box-obs-social">
                          <p>
                            Você deve preencher suas redes sociais com o link
                            completo, por exemplo:
                            <br />
                            https://www.facebook.com/We4Science/
                          </p>
                        </div>
                        <InputContent>
                          <Input
                            type="text"
                            name="facebook"
                            placeholder="Seu Facebook:"
                          />
                        </InputContent>

                        <InputContent>
                          <Input
                            type="text"
                            name="linkedin"
                            placeholder="Seu Linkedin:"
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            type="text"
                            placeholder="Seu Canal no Youtube:"
                            name="youtube"
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            type="text"
                            name="twitter"
                            placeholder="Seu Twitter:"
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            type="text"
                            name="instagram"
                            placeholder="Seu Instagram:"
                          />
                        </InputContent>
                        <InputContent>
                          <Input type="text" name="cpf" placeholder="CPF: *" />
                        </InputContent>
                        <div className="box-security-datas">
                          <p>
                            <strong>Não se preocupe!</strong>
                            <br />
                            <br /> A We4Science não compartilha seus documentos
                            e informações pessoais, como CPF, endereço e
                            telefone de contato.
                          </p>
                        </div>

                        <div className="id">
                          <p> ID do usuário: {profile && profile.id} </p>
                        </div>
                      </div>
                      <hr />
                      <div className="curriculum-data">
                        <p> Dados curriculares </p>
                        <div className="inputs-curriculum">
                          <InputContent>
                            <Input
                              className="margin"
                              name="profession"
                              type="text"
                              placeholder="Profissão: *"
                            />
                          </InputContent>
                          <InputContent>
                            <Input
                              name="company"
                              type="text"
                              maxLength={60}
                              placeholder="Empresa/instituição: *"
                            />
                          </InputContent>
                          <InputContent>
                            <Input
                              name="schooling"
                              type="text"
                              placeholder="Nivel de escolaridade: *"
                            />
                          </InputContent>
                          <InputContent>
                            <Input
                              name="link_curriculum"
                              type="text"
                              className="full-link-curriculum"
                              placeholder="Link para acessar seu currículo Lattes: *"
                            />
                          </InputContent>
                          <InputContent active>
                            <Textarea
                              name="curriculum"
                              className="message"
                              type="text"
                              maxLength={145}
                              placeholder="Mini currículo: *"
                              onChange={(event) =>
                                setFormattedContent(event.target.value)
                              }
                              value={content}
                            />
                          </InputContent>
                          <p style={{ fontSize: 16, marginTop: 20 }}>
                            {content.length} / {145} Caracteres
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="adress">
                        <p> Endereço </p>
                        <div className="inputs-adress">
                          <Input
                            name="adress"
                            type="text"
                            placeholder="Logradouro:"
                          />
                          <Input
                            name="neighborhood"
                            type="text"
                            placeholder="Bairro"
                          />
                          <DropDownContainer>
                            <DropDownHeader small onClick={togglingState}>
                              <p id="text-state" className="small-text">
                                {selectedOptionState ||
                                  (profile && profile.state !== null
                                    ? profile.state
                                    : "Estado")}
                              </p>
                              {isOpenState ? (
                                <img
                                  className="icon-drop"
                                  id="up-icon"
                                  src={iconDrop}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="icon-drop"
                                  src={iconDrop}
                                  alt=""
                                />
                              )}
                            </DropDownHeader>
                            {isOpenState && (
                              <DropDownListContainerState>
                                <DropDownList>
                                  <Scrollbars
                                    style={{ width: 145, height: 60 }}
                                  >
                                    {optionsStates.map((option) => (
                                      <ListItem
                                        small
                                        onClick={onOptionClickedState(option)}
                                        key={Math.random()}
                                      >
                                        {option}
                                      </ListItem>
                                    ))}
                                  </Scrollbars>
                                </DropDownList>
                              </DropDownListContainerState>
                            )}
                          </DropDownContainer>
                          <Input
                            name="city"
                            type="text"
                            placeholder="Cidade"
                            className="city"
                          />
                          <Input
                            name="cep"
                            type="text"
                            placeholder="CEP"
                            className="CEP"
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="security">
                        <p> Dados de segurança </p>
                        <div className="inputs-security">
                          <Input
                            name="password"
                            type="password"
                            placeholder="Senha:"
                          />
                          <Input
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirmar Senha:"
                          />
                        </div>
                      </div>
                      <p
                        style={{
                          float: "left",
                          color: "#7f7fb9",
                          marginLeft: 20,
                          marginBottom: 10,
                        }}
                      >
                        *Campo obrigatório
                      </p>
                      <button type="submit">Salvar</button>
                    </Form>
                  </div>
                </EditComponent>
              </Fade>
            )}
            {donations && (
              <Fade left duration={1600} distance="20px">
                <DonationsComponent>
                  <p id="title"> Minhas Doações </p>
                  <p className="text">
                    Aqui você consulta todas as doações que já fez em nossa
                    plataforma.
                    <br />E lembre-se: além de ajudar no desenvolvimento de
                    <Link
                      to="projects"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      Projetos e Pesquisas Científicas
                    </Link>
                    importantes, suas doações também rendem moedas Whuffie que
                    você poderá usar para adquirir produtos, serviços e
                    experiências em nosso{" "}
                    <Link
                      to="store"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      Mercado Whuffie
                    </Link>
                    . Aproveite!
                  </p>
                  <div className="buttons">
                    <ButtonDonation
                      active={btnProjectDonations}
                      onClick={toggleBtnProject}
                    >
                      Projetos
                    </ButtonDonation>
                    <ButtonDonation
                      active={btnCosmosDonations}
                      onClick={toggleBtnCosmos}
                    >
                      Fundo Cosmos
                    </ButtonDonation>
                  </div>
                  {btnProjectDonations && (
                    <>
                      <div className="content">
                        {myDonations &&
                          currentMyDonatios.map((preview) => (
                            <ProjectSecond key={preview.id}>
                              <div className="content-1">
                                <img
                                  src={
                                    preview.project &&
                                    preview.project.first &&
                                    preview.project.first.url
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content-2">
                                <div className="titles">
                                  <p id="title-project">
                                    {preview.project && preview.project.name}
                                  </p>
                                  {preview && preview.project.special ? (
                                    <div>
                                      <FaLockOpen color="#227af1" size={16} />

                                      <p>Tenho Permissão Especial</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <img src={iconCompanyProject} alt="" />
                                      <p>
                                        Instituição:
                                        {preview && preview.project.institution}
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <img src={iconCheck} alt="" />
                                    <p>
                                      Categoria:
                                      {preview.project &&
                                        preview.project.category}
                                    </p>
                                  </div>
                                  <section> </section>
                                </div>
                                <div className="donation">
                                  <p> Doei para este projeto: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                              <div className="content-3">
                                <img src={atomProject} alt="" />
                                <p>
                                  <Link to="project"> Ver projeto </Link>
                                </p>
                              </div>
                            </ProjectSecond>
                          ))}
                      </div>

                      <div className="floatPage">
                        {myDonations.length > 0 && (
                          <Pagination
                            currentPage={currentPageMyDonatios}
                            countriesPerPage={countriesPerPageMyDonatios}
                            totalCountries={myDonations.length}
                            paginate={paginateMyDonatios}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {btnCosmosDonations && (
                    <>
                      <div className="content">
                        {cosmos &&
                          currentMyDonatiosCosmos.map((preview) => (
                            <Cosmos key={preview.id}>
                              <div className="content-1">
                                <img src={logo} alt="" />
                              </div>
                              <p id="title-cosmos">Fundo Cosmos</p>
                              <div className="content-2">
                                <div className="donation">
                                  <p> Doei: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                            </Cosmos>
                          ))}
                      </div>

                      <div className="floatPage">
                        {cosmos.length > 0 && (
                          <Pagination
                            currentPage={currentPageMyDonatiosCosmos}
                            countriesPerPage={countriesPerPageMyDonatiosCosmos}
                            totalCountries={cosmos.length}
                            paginate={paginateMyDonatiosCosmos}
                          />
                        )}
                      </div>
                    </>
                  )}
                </DonationsComponent>
              </Fade>
            )}
            {projects && (
              <Fade left duration={1600} distance="20px">
                <ProjectsComponent>
                  <p id="title"> Meus Projetos </p>
                  <p className="text">
                    Nós sabemos que você é um(a) grande cientista, mas queremos
                    que o mundo inteiro também saiba! Na verdade, eles precisam
                    saber!
                    <br />
                    Então acompanhe aqui seus projetos científicos cadastrados,
                    informe seus fãs e colegas sobre as novidades em cada
                    projeto e nos ajude a popularizar a Ciência!
                  </p>
                  <div className="newProject">
                    <p> Cadastrar novo Projeto Científico </p>
                    <button
                      onClick={() => {
                        handleNewProjectClick();
                        addDeveloperScientist();
                      }}
                    >
                      {" "}
                      Cadastrar{" "}
                    </button>
                  </div>
                  <Projects>
                    <ul className="country">
                      {currentCountries.map((countries) => (
                        <ProjectContent key={countries.id}>
                          <MyProject>
                            <div className="content-1">
                              {countries&&countries.first&& <img
                                id="img-content-1"
                                src={countries.first.url}
                                alt=""
                              />}
                            </div>
                            <div className="content-2">
                              <div className="titles">
                                <p id="title-project"> {countries.name} </p>
                                <div>
                                  {countries.special ? (
                                    <>
                                      <FaLockOpen color="#227af1" size={16} />
                                      <p>Tenho Permissão Especial</p>
                                    </>
                                  ) : (
                                    <>
                                      <img src={iconCompanyProject} alt="" />
                                      <p>
                                        Instituição:
                                        {countries.institution.split(";")[0]}
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div>
                                  <img src={iconCheck} alt="" />
                                  <p> Categoria: {countries.category} </p>
                                </div>
                                <section> </section>
                              </div>
                              <div className="infos">
                                <p id="infos-text">
                                  Data de criação: {countries.formattedDate}
                                </p>
                                <p id="infos-text">
                                  ID do projeto: {countries.id}
                                </p>
                              </div>
                              <section></section>
                              <div className="goals">
                                <div className="value">
                                  <p>
                                    Meta de arrecadação: (não separe o valor com
                                    ponto)
                                  </p>
                                  <p id="value">R$ {countries.value_goal}</p>
                                </div>
                                <div className="current_val">
                                  <p>Valor erracadado:</p>
                                  <p id="current_val">
                                    R$ {countries.current_value}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="content-3">
                              <img src={atomProject} alt="" />
                              <p>
                                <Link to={`project/${countries.id}`}>
                                  Ver projeto
                                </Link>
                              </p>
                            </div>
                          </MyProject>
                          <div className="edit">
                            <div className="box">
                              <img src={iconPerfil} alt="" />
                            </div>
                            <button
                              onClick={() => {
                                setProjects(false);
                                setEditProject(true);
                                setGetProject(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )
                                );

                                getDepositions(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )[0].id
                                );

                                getNewsProject(countries.id);
                                auxArrayEdit.push(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )[0].first
                                );
                                auxArrayEdit.push(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )[0].second
                                );
                                auxArrayEdit.push(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )[0].third
                                );
                                setArrayEdit(auxArrayEdit.filter((item)=>item !=null));
                                auxArrayEdit = [];

                                changeArrForInstitutions(
                                  currentCountries
                                    .filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0]
                                    .institution.split(";")
                                    .filter((option) => option !== "")
                                );

                                changeArrForMembers(
                                  currentCountries
                                    .filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0]
                                    .member.split(";")
                                    .filter((option) => option !== "")
                                );

                                handleChats(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )[0].id
                                );
                                setNewItem(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )[0].summary
                                );
                                handlePayments(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )[0].id
                                );
                              }}
                            >
                              Editar / administrar projeto
                            </button>
                          </div>

                          <div className="edit">
                            <div className="box">
                              <img
                                id="icon-collaboration"
                                src={iconCollaboration}
                                alt=""
                              />
                            </div>
                            <button
                              onClick={() => {
                                setProjects(false);
                                setCollaborationsProject(true);
                                setGetProject(
                                  currentCountries.filter(
                                    (challenge) => challenge.id === countries.id
                                  )
                                );
                                handleGetCollaborationsProject(
                                  parseInt(countries.id)
                                );
                              }}
                            >
                              Colaborações
                            </button>
                          </div>
                        </ProjectContent>
                      ))}
                    </ul>
                  </Projects>
                  <div className="floatPage">
                    <Pagination
                      currentPage={currentPage}
                      countriesPerPage={countriesPerPage}
                      totalCountries={countries.length}
                      paginate={paginate}
                    />
                  </div>
                </ProjectsComponent>
              </Fade>
            )}
            {editProject && (
              <Fade left duration={1600} distance="20px">
                <NewProjectComponent>
                  <p id="title"> Editar / Administrar Projeto </p>
                  <p className="text">
                    Aqui você pode editar e gerenciar as informações dos seus
                    projetos, responder os depoimentos dos seus fãs e ir
                    adicionando Novidades ao seu projeto, na media em que você e
                    sua equipe forem avançando em seu desenvolvimento.
                    <br />
                    <br />
                    Esse trabalho é muito importante para manter seu time de
                    apoiadores cada vez mais engajado e trazer ainda mais
                    prestígio e credibilidade para você, sua equipe e sua
                    instituição.
                  </p>
                  <div className="controllerProject">
                    <Button active={buttonAbout} onClick={handleAboutClick}>
                      Sobre
                    </Button>
                    <Button active={buttonDoners} onClick={handleDonersClick}>
                      Apoiadores
                    </Button>
                    <Button
                      active={buttonMessages}
                      onClick={handleMessagesClick}
                    >
                      Depoimentos
                    </Button>
                    <Button active={buttonNew} onClick={handleNewClick}>
                      Novidades
                    </Button>
                  </div>
                  {buttonAbout && (
                    <Fade top duration={1600} distance="20px">
                      <AboutComponent>
                        <Form
                          initialData={getProject[0]}
                          onSubmit={handleUpdateProject}
                        >
                          <p id="title"> Sobre o projeto </p>
                          <div className="inputs">
                            <Input
                              name="name"
                              className="full"
                              type="text"
                              placeholder="Nome completo do produto/serviço/experiência."
                              maxLength={65}
                            />

                            <DropDownContainer>
                              <DropDownHeader
                                id="small"
                                onClick={togglingTypeProject}
                              >
                                <p>
                                  {selectedOptionTypeProject ||
                                    getProject[0].category ||
                                    "Categoria"}
                                </p>
                                {isOpenTypeProject ? (
                                  <img
                                    className="icon-drop"
                                    id="up-icon"
                                    src={iconDrop}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="icon-drop"
                                    src={iconDrop}
                                    alt=""
                                  />
                                )}
                              </DropDownHeader>
                              {isOpenTypeProject && (
                                <DropDownListContainer className="projects">
                                  <DropDownList className="projects">
                                    <Scrollbars
                                      style={{ width: 340, height: 50 }}
                                    >
                                      {listOfTypes.map((option) => (
                                        <ListItem
                                          onClick={onOptionClickedTypeProject(
                                            option
                                          )}
                                          key={Math.random()}
                                        >
                                          {option}
                                        </ListItem>
                                      ))}
                                    </Scrollbars>
                                  </DropDownList>
                                </DropDownListContainer>
                              )}
                            </DropDownContainer>
                            <Input
                              name="youtube_link"
                              type="text"
                              placeholder="Apresente sua campanha no Youtube (link)"
                            />
                            <Input
                              name="start"
                              type="text"
                              placeholder="DData de início desta campanha: dd/mm/aa"
                            />
                            <Input
                              name="end"
                              type="text"
                              placeholder="Data de término: dd/mm/aa"
                            />

                            <div className="value_goal">
                              <div className="id">
                                <p>
                                  Meta de arrecadação: R$
                                  {getProject[0] && getProject[0].value_goal}
                                </p>
                              </div>

                              <button
                                id="btn-ask"
                                type="button"
                                onClick={toogleNewGoal}
                              >
                                Solicitar nova meta
                              </button>
                            </div>
                            {newGoal && (
                              <>
                                <hr />

                                <Fade top duration={1600} distance="20px">
                                  <form className="new_goal">
                                    <p id="title-new-goal">
                                      Soliticar nova meta para projeto
                                    </p>
                                    <input
                                      name="value_goal"
                                      type="text"
                                      placeholder="Nova meta:"
                                      className="full"
                                      onChange={(event) =>
                                        setNewGoalValue(event.target.value)
                                      }
                                    />
                                    <textarea
                                      name="summary"
                                      placeholder="Justificativa:"
                                      id="height-1"
                                      onChange={(event) =>
                                        setNewGoalJustify(event.target.value)
                                      }
                                    />
                                    <button
                                      type="button"
                                      id="btn-send"
                                      onClick={handleNewGoal}
                                    >
                                      Enviar para análise
                                    </button>
                                  </form>
                                </Fade>
                                <hr />
                              </>
                            )}
                            <InputContent>
                              <div className="input-imgs">
                                <p id="placeholder"> Imagens </p>
                                <BoxLabel
                                  active={arrayEdit.length === 3 && arrayEdit.every(item => item !== null) ? true : false}
                                >
                                  <label htmlFor="avatar">
                                    <div>
                                      <img src={iconClips} id="clips" alt="" />
                                      <input
                                        type="file"
                                        id="avatar"
                                        accept="image/*"
                                        onChange={handleChangeEdit}
                                        disabled={
                                          arrayEdit.length === 3 && arrayEdit.every(item => item !== null) ? true : false
                                        }
                                        ref={ref}
                                      />
                                    </div>
                                  </label>
                                </BoxLabel>
                              </div>
                              <p id="obs-input" style={{ marginTop: 10 }}>
                                Obs: Cadastre necessariamente 03 imagens
                                (dimensões recomendadas: 628x360 pixels).
                              </p>
                            </InputContent>
                          </div>
                          <div className="previews">
                            {arrayEdit.length>0 && arrayEdit.map((preview) => (
                              preview && <div className="preview" key={preview.id}>
                              {preview && preview.url && <img
                                id="teste"
                                src={preview.url}
                                alt="avatar"
                              />}
                              <button
                                type="button"
                                onClick={() => {
                                  deleteImg(preview);
                                  setArrayEdit(
                                    arrayEdit.filter(
                                      (item) => item.id !== preview.id
                                    )
                                  );
                                }}
                              >
                                Exluir
                              </button>
                            </div>
                            ))}
                          </div>
                          {getProject[0].special ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 15,
                              }}
                            >
                              <FaLockOpen color="#227af1" size={18} />
                              <p style={{ color: "#227af1", marginLeft: 10 }}>
                                Tenho Permissão Especial
                              </p>
                            </div>
                          ) : (
                            <div className="institution">
                              <DropDownContainer>
                                <DropDownHeader
                                  onClick={togglingTypeInstitution}
                                >
                                  <p>
                                    {selectedOptionTypeInstitution ||
                                      "Instituição"}
                                  </p>
                                  {isOpenTypeInstitution ? (
                                    <img
                                      className="icon-drop"
                                      id="up-icon"
                                      src={iconDrop}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="icon-drop"
                                      src={iconDrop}
                                      alt=""
                                    />
                                  )}
                                </DropDownHeader>
                                {isOpenTypeInstitution && (
                                  <DropDownListContainer className="projects">
                                    <DropDownList>
                                      <Scrollbars style={{ height: 50 }}>
                                        {allInstitutions.map((option) => (
                                          <ListItem
                                            onClick={onOptionClickedTypeInstitution(
                                              option
                                            )}
                                            key={Math.random()}
                                          >
                                            {option && option.company}
                                          </ListItem>
                                        ))}
                                      </Scrollbars>
                                    </DropDownList>
                                  </DropDownListContainer>
                                )}
                              </DropDownContainer>
                              <PreviewsInstitution>
                                <PreviewsList>
                                  <Scrollbars
                                    style={{ width: 340, height: 80 }}
                                  >
                                    {instutions.map((option) => (
                                      <Preview key={Math.random()}>
                                        <p id="id-university">
                                          {`${option.company} `}
                                        </p>
                                        <div className="trash">
                                          <img src={iconTrash} alt="" />
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleRemoveInstitution(option)
                                            }
                                          >
                                            Excluir
                                          </button>
                                        </div>
                                      </Preview>
                                    ))}
                                  </Scrollbars>
                                </PreviewsList>
                              </PreviewsInstitution>
                            </div>
                          )}

                          <div className="textareas">
                            <Textarea
                              name="summary"
                              maxLength={145}
                              placeholder="Resumo do projeto:"
                              id="height-1"
                              onChange={(event) =>
                                setNewItem(event.target.value)
                              }
                              value={newItem}
                            />
                            <p
                              style={{
                                fontSize: 16,
                                marginBottom: 15,
                                color: "#7f7fb9",
                              }}
                            >
                              {newItem.length} / {145} Caracteres
                            </p>
                            <Textarea
                              name="about"
                              placeholder="O que é:"
                              id="height-2"
                            />
                            <Textarea
                              name="who_benefits"
                              placeholder="Quem se beneficia:"
                              id="height-3"
                            />
                            <Textarea
                              name="goals_objectives"
                              placeholder="Principais objetivos e metas:"
                              id="height-3"
                            />
                            <Textarea
                              name="stage"
                              placeholder="Estágio atual de desenvolvimento "
                              id="height-3"
                            />
                            <Textarea
                              name="partners"
                              placeholder="Parceiros"
                              id="height-3"
                            />
                            <Textarea
                              name="necessary_resources"
                              placeholder="Recursos necessários:"
                              id="height-3"
                            />
                            <Textarea
                              name="importance_collaboration"
                              placeholder="Importância da colaboração para o desenvolvimento deste projeto:"
                              id="height-3"
                            />
                            <Textarea
                              name="useful_links"
                              placeholder="Links úteis(Separe cada link por';' ):"
                              id="height-3"
                            />

                            <hr />
                          </div>
                          <div className="content-development-team">
                            <p id="title"> Equipe do projeto </p>
                            {development.length > 0 && (
                              <DevelopmentList>
                                <Scrollbars style={{ height: 370 }}>
                                  {development.length !== 0 &&
                                    development.map((option) => (
                                      <DevelopmentTeam
                                        key={option && option.id}
                                      >
                                        {parseInt(option.id) ===
                                        parseInt(profile.id)
                                          ? false
                                          : true && (
                                              <img
                                                id="icon-trash"
                                                src={iconTrash}
                                                onClick={() =>
                                                  handleRemoveDeveloper(option)
                                                }
                                                alt=""
                                              />
                                            )}
                                        <div className="profile">
                                          <div id="border">
                                            <div id="img-profile">
                                              <img
                                                src={
                                                  option.avatar &&
                                                  option.avatar.url
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="content-profile">
                                          <p> Nome: {option.name} </p>
                                          <p>
                                            {" "}
                                            Profissão: {option.profession}{" "}
                                          </p>
                                          <p>
                                            {" "}
                                            Local de trabalho: {
                                              option.company
                                            }{" "}
                                          </p>
                                          <p>
                                            {" "}
                                            Função no projeto: Idealizador{" "}
                                          </p>
                                          <p id="description">
                                            {option.curriculum}
                                          </p>
                                          <section id="section-content-profile"></section>
                                          <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={completeLink(
                                              option.link_curriculum
                                            )}
                                          >
                                            Currículo completo
                                          </a>
                                          <section id="section-content-profile"></section>
                                          <div className="social-networks">
                                            <p> Redes sociais: </p>
                                            <div className="icons-social">
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://api.whatsapp.com/send?phone=${option.whatsapp}`}
                                              >
                                                <FaWhatsapp size={25} />
                                              </a>
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://www.instagram.com/${completeLink(
                                                  option.instagram
                                                )}`}
                                              >
                                                <FaInstagram size={25} />
                                              </a>
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://twitter.com/${completeLink(
                                                  option.twitter
                                                )}`}
                                              >
                                                <FaTwitter size={25} />
                                              </a>
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://www.facebook.com/${completeLink(
                                                  option.facebook
                                                )}`}
                                              >
                                                <FaFacebookF size={22} />
                                              </a>
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://www.linkedin.com/in/${completeLink(
                                                  option.linkedin
                                                )}`}
                                              >
                                                <FaLinkedinIn size={24} />
                                              </a>

                                              <img
                                                id="icon-email"
                                                src={iconEmail}
                                                alt={option.email}
                                                title={option.email}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </DevelopmentTeam>
                                    ))}
                                </Scrollbars>
                              </DevelopmentList>
                            )}
                            <div className="new-member">
                              <p id="title-new-member"> Inserir integrante </p>
                              <div className="input">
                                <input
                                  name="member"
                                  type="text"
                                  onChange={(event) =>
                                    setSearchId(event.target.value)
                                  }
                                  placeholder="Nome do participante"
                                />
                                <button
                                  type="button"
                                  onClick={() => {
                                    searchScientist(searchId);
                                  }}
                                >
                                  Inserir
                                </button>
                              </div>
                            </div>
                            {searchByNameScientists.length !== 0 && (
                              <ContentSelectMember>
                                <p>
                                  Clique no usuário que deseja adicionar a
                                  Equipe
                                </p>
                                <ul>
                                  <Scrollbars
                                    style={{ width: 690, height: 160 }}
                                  >
                                    {searchByNameScientists.map((preview) => (
                                      <AllUsersSearch
                                        onClick={() => {
                                          addDeveloper(preview.id);
                                        }}
                                        key={preview.id}
                                      >
                                        <div className="profile">
                                          <div id="border">
                                            <div id="img-profile">
                                              <img
                                                src={
                                                  preview.avatar &&
                                                  preview.avatar.url
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="content-profile">
                                          <p>Nome: {preview.name}</p>
                                          <p>Profissão: {preview.profession}</p>
                                        </div>
                                      </AllUsersSearch>
                                    ))}
                                  </Scrollbars>
                                </ul>
                              </ContentSelectMember>
                            )}

                            <hr />
                          </div>
                          <div className="collaboration">
                            <div className="content-2">
                              <div className="title">
                                <img src={iconSecondType} alt="" />
                                <p id="title"> Colaboração técnica </p>
                              </div>
                              <div className="inputs">
                                <Textarea
                                  name="technical_collaboration"
                                  placeholder="Que tipo de colaboração técnica ajudaria a desenvolver seu projeto?"
                                />
                                {roomTechnical.length > 0 && (
                                  <div
                                    className="chat-change"
                                    onClick={() => toogleTec()}
                                  >
                                    <div className="chat">
                                      <img src={iconChat} alt="" />
                                      <p id="text-title-chat">
                                        Ver chat da colaboração
                                      </p>
                                      <div className="box-amount">
                                        <p> {roomTechnical.length} </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="rooms">
                                {chatTech && (
                                  <Fade top duration={1600} distance="20px">
                                    <Scrollbars style={{ height: 80 }}>
                                      {roomTechnical.map((preview) => (
                                        <Link
                                          to={`/chat?id=${preview.project_id}&type=${preview.type}&room=${preview.room}`}
                                          className="room"
                                          key={preview.id}
                                        >
                                          <img src={user} alt="" />
                                          <p>{preview.name}</p>
                                        </Link>
                                      ))}
                                    </Scrollbars>
                                  </Fade>
                                )}
                              </div>
                            </div>
                            <div className="content-3">
                              <div className="title">
                                <img src={iconThirdType} alt="" />
                                <p id="title"> Prestação de serviços </p>
                              </div>
                              <div className="inputs">
                                <Textarea
                                  name="services_provision"
                                  placeholder="Quais tipos de serviços profissionais lhe ajudariam a desenvolver este projeto?"
                                />
                                {roomService.length > 0 && (
                                  <div
                                    className="chat-change"
                                    onClick={() => toogleSer()}
                                  >
                                    <div className="chat">
                                      <img src={iconChat} alt="" />
                                      <p id="text-title-chat">
                                        Ver chat da colaboração
                                      </p>
                                      <div className="box-amount">
                                        <p> {roomService.length} </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="rooms">
                                {chatSerc && (
                                  <Fade top duration={1600} distance="20px">
                                    <Scrollbars style={{ height: 80 }}>
                                      {roomService.map((preview) => (
                                        <Link
                                          to={`/chat?id=${preview.project_id}&type=${preview.type}&room=${preview.room}`}
                                          className="room"
                                          key={preview.id}
                                        >
                                          <img src={user} alt="" />
                                          <p>{preview.name}</p>
                                        </Link>
                                      ))}
                                    </Scrollbars>
                                  </Fade>
                                )}
                              </div>
                            </div>
                            <div className="content-4">
                              <div className="title">
                                <img src={iconFourthType} alt="" />
                                <p id="title">Parcerias Institucionais</p>
                              </div>
                              <div className="inputs">
                                <Textarea
                                  name="institutional_partnerships"
                                  placeholder="Qual perfil de empresas ou instituições você considera bem-vindo para estabelecer parcerias ao desenvolvimento deste projeto?"
                                />
                                {roomInstitutional.length > 0 && (
                                  <div
                                    className="chat-change"
                                    onClick={() => toogleIns()}
                                  >
                                    <div className="chat">
                                      <img src={iconChat} alt="" />
                                      <p id="text-title-chat">
                                        Ver chat da colaboração
                                      </p>
                                      <div className="box-amount">
                                        <p> {roomInstitutional.length} </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="rooms">
                                {chatInst && (
                                  <Fade top duration={1600} distance="20px">
                                    <Scrollbars style={{ height: 80 }}>
                                      {roomInstitutional.map((preview) => (
                                        <Link
                                          to={`/chat?id=${preview.project_id}&type=${preview.type}&room=${preview.room}`}
                                          className="room"
                                          key={preview.id}
                                        >
                                          <img src={user} alt="" />
                                          <p>{preview.name}</p>
                                        </Link>
                                      ))}
                                    </Scrollbars>
                                  </Fade>
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <button type="submit" id="btn-next" disabled={arrayEdit.filter((item)=>item!=null).length<3}>
                            Editar
                          </button>
                        </Form>
                      </AboutComponent>
                    </Fade>
                  )}
                  {buttonDoners && (
                    <Fade top duration={1600} distance="20px">
                      <DonersComponent>
                        <p id="title-donors">Apoiadores</p>
                        <div className="info-doners">
                          <div className="content-info-doners">
                            <img src={iconDonor1} alt="" />
                            <div>
                              <p id="value">{projectDonations.length}</p>
                              <p>doações</p>
                            </div>
                          </div>

                          <div className="content-info-doners">
                            <img src={iconDonor2} alt="" />
                            <div>
                              <p id="value">
                                {
                                  projectDonations.filter(
                                    (item) =>
                                      item.perfil === "Apoiador/Visitante" ||
                                      item.perfil === "Cientista/Pesquisador"
                                  ).length
                                }
                              </p>
                              <p>doações</p>
                              <p>Individuais</p>
                            </div>
                          </div>

                          <div className="content-info-doners">
                            <img src={iconDonor3} alt="" />
                            <div>
                              <p id="value">
                                {
                                  projectDonations.filter(
                                    (item) =>
                                      item.perfil ===
                                      "Apoiador Empresarial/Desafiante"
                                  ).length
                                }
                              </p>
                              <p>doações</p>
                              <p>corporativa</p>
                            </div>
                          </div>
                        </div>
                        <div className="donors">
                          {currentCountriesDonations.map((preview) => (
                            <div className="donor" key={preview.id}>
                              <div className="donor-content">
                                <div className="icon">
                                  <div id="background-img">
                                    <img src={iconDonor} alt="" />
                                  </div>
                                </div>
                                <div className="info-donor">
                                  <p id="day">{preview.formattedDate}</p>
                                  <p>{preview.name}</p>
                                  {preview.description && (
                                    <p
                                      style={{
                                        fontFamily: "GothamProRegular",
                                        marginTop: 5,
                                      }}
                                    >
                                      {preview.description}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <section></section>
                            </div>
                          ))}
                        </div>
                        {projectDonations.length > 0 && (
                          <div className="floatPage">
                            <Pagination
                              currentPage={currentPageDonations}
                              countriesPerPage={countriesPerPageDonations}
                              totalCountries={projectDonations.length}
                              paginate={paginateDonations}
                            />
                          </div>
                        )}
                      </DonersComponent>
                    </Fade>
                  )}
                  {buttonMessages && (
                    <Fade top duration={1600} distance="20px">
                      <MessagesComponent>
                        <p id="title"> Depoimentos </p>
                        <div className="messages">
                          {currentCountriesDepositions.map((message) => (
                            <div className="message" key={message.id}>
                              <div>
                                <div className="icon">
                                  <div id="background-img">
                                    <img src={iconDonor} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="content">
                                <p> {message.formattedDate} </p>
                                <p> {message.user} </p>
                                <p id="text"> {message.content} </p> <p> </p>
                              </div>
                              <div className="options">
                                <div
                                  className="delete"
                                  onClick={() => deleteDeposition(message.id)}
                                >
                                  <img src={iconTrash} alt="" />
                                  <p id="text-delete"> Excluir </p>
                                </div>
                                {!message.accept && (
                                  <div
                                    className="accept"
                                    onClick={() =>
                                      acceptsDeposition(message.id)
                                    }
                                  >
                                    <img src={iconCheck} alt="" />
                                    <p id="text-delete"> Aceitar </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="floatPage">
                          <Pagination
                            currentPage={currentPageDepositions}
                            countriesPerPage={countriesPerPageDepositions}
                            totalCountries={countriesDepositions.length}
                            paginate={paginateDepositions}
                          />
                        </div>
                        <button
                          id="btn-save"
                          type="button"
                          onClick={() => {
                            toast.success("Depoimentos salvos!");
                            setTimeout(function () {
                              history.go(0);
                            }, 1500);
                          }}
                        >
                          Salvar
                        </button>
                      </MessagesComponent>
                    </Fade>
                  )}
                  {buttonNew && (
                    <Fade top duration={1600} distance="20px">
                      <NewsComponent>
                        <p id="title"> Novidades </p>
                        <div className="news">
                          <ul>
                            {newsProject && newsProject.length > 0 && (
                              <Scrollbars style={{ width: 740, height: 705 }}>
                                {newsProject.map((news) => (
                                  <New
                                    key={news.id}
                                    img={
                                      news.image || news.yt_link ? true : false
                                    }
                                  >
                                    <div className="calendar-content">
                                      <img src={iconCalendarPurple} alt="" />
                                      <p> {news.formattedDate} </p>
                                      <div
                                        className="trash"
                                        onClick={() => deleteNews(news.id)}
                                      >
                                        <img
                                          id="icon-trash"
                                          src={iconTrash}
                                          alt=""
                                        />
                                        <p id="text-trash"> Excluir </p>
                                      </div>
                                    </div>
                                    <p id="title-new-content"> {news.title} </p>
                                    <p> {news.content} </p>
                                    {news.image && (
                                      <img
                                        id="img-new"
                                        src={news.image.url}
                                        alt=""
                                      />
                                    )}
                                    {news.yt_link && (
                                      <YouTube
                                        id="img-new"
                                        videoId={`${YouTubeGetID(
                                          news.yt_link
                                        )}`}
                                        opts={opts}
                                      />
                                    )}
                                  </New>
                                ))}
                              </Scrollbars>
                            )}
                          </ul>
                        </div>
                        <hr id="dotted" />
                        <Form onSubmit={handleAddNew}>
                          <div className="new-msg">
                            <p id="title-new-msg"> Inserir novidade </p>
                            <Input
                              type="text"
                              name="title"
                              placeholder="Título da novidade"
                            />
                            <Textarea
                              name="content"
                              className="message"
                              placeholder="Escrever conteúdo..."
                            />
                            <div className="buttons">
                              {addLinkYt && !imageNew && (
                                <Fade right duration={1000} distance="20px">
                                  <input
                                    name="link"
                                    onChange={(event) => {
                                      setLinkYT(event.target.value);
                                    }}
                                    placeholder="Link do vídeo no Youtube"
                                    id="input-link"
                                  />
                                </Fade>
                              )}
                              {imageNew && (
                                <div className="preview">
                                  <img
                                    id="teste"
                                    src={imageNew.url}
                                    alt="avatar"
                                  />
                                  <button type="button" onClick={deleteImgNew}>
                                    Exluir
                                  </button>
                                </div>
                              )}
                              <BtnNew
                                type="button"
                                active={addLinkYt}
                                disabled={imageNew}
                                onClick={togglingYt}
                              >
                                <img src={iconYT} alt="" />
                              </BtnNew>
                              <BoxLabelNew
                                active={imageNew}
                                onClick={togglingImage}
                              >
                                <label htmlFor="avatar">
                                  <div>
                                    <img src={iconCam} id="clips" alt="" />
                                    <input
                                      type="file"
                                      id="avatar"
                                      accept="image/*"
                                      onChange={handleChangeEditImage}
                                      ref={ref}
                                      disabled={imageNew || addLinkYt}
                                    />
                                  </div>
                                </label>
                              </BoxLabelNew>
                              <button id="btn-insert"> Inserir </button>
                            </div>
                          </div>
                          <hr id="dotted" />
                          <button type="submit" id="btn-save">
                            Salvar
                          </button>
                        </Form>
                      </NewsComponent>
                    </Fade>
                  )}
                </NewProjectComponent>
              </Fade>
            )}
            {collaborationsProject && (
              <Fade left duration={1600} distance="20px">
                <ProjectCollaborations>
                  <p id="title"> Colaborações - {getProject[0].name}</p>
                  <p className="text">
                    Parabéns!
                    <br />
                    Você recebeu uma nova colaboração!
                    <br /> Agora analise o conteúdo dessa colaboração e, se
                    julgar pertinente ao seu projeto, clique no botão "validar"
                    para que o responsável pela colaboração receba sua
                    recompensa em Moedas Whuffie.
                    <br />
                    Aproveite e envie uma mensagem para agradecer, tirar dúvidas
                    ou avançar nas conversas sobre o seu projeto com esse
                    colaborador.
                  </p>
                  <div className="content">
                    <div className="buttons">
                      <ButtonCollaboration
                        active={technical}
                        onClick={handleTechnical}
                      >
                        {technical ? (
                          <img src={iconTecWhite} alt="" />
                        ) : (
                          <img src={iconTec} alt="" />
                        )}
                      </ButtonCollaboration>
                      <ButtonCollaboration
                        active={service}
                        onClick={handleService}
                      >
                        {service ? (
                          <img src={iconServiceWhite} alt="" />
                        ) : (
                          <img src={iconService} alt="" />
                        )}
                      </ButtonCollaboration>
                      <ButtonCollaboration
                        active={institutional}
                        onClick={handleInstitutional}
                      >
                        {institutional ? (
                          <img src={iconCollaborationWhite} alt="" />
                        ) : (
                          <img src={iconCollaborationBlue} alt="" />
                        )}
                      </ButtonCollaboration>
                    </div>
                    {technical && (
                      <Fade top duration={1600} distance="20px">
                        <div className="box-content">
                          <p id="title-content">Colaborações Tecnicas</p>
                          <ul className="items">
                            {arrTechnical.length > 0 &&
                              currentTechnical.map((preview) => (
                                <Collaboration key={preview.id}>
                                  <div id="participant">
                                    <div>
                                      <div className="icon">
                                        <div id="background-img">
                                          <img src={iconDonor} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="content">
                                      <p id="day">{preview.formattedDate}</p>
                                      <div className="user-box">
                                        <img src={id} alt="" />
                                        <p id="name">id : {preview.user}</p>
                                      </div>
                                      <div className="user-box">
                                        <img src={user} alt="" />
                                        <p id="name">
                                          perfil : {preview.perfil}
                                        </p>
                                      </div>
                                      {preview.content && (
                                        <>
                                          <hr />
                                          <p id="message-champ">
                                            {preview.content}
                                          </p>
                                          <hr />
                                        </>
                                      )}
                                      <div className="third-content">
                                        <p>
                                          Link com arquivo ou caminho da entrega
                                          do desafio:
                                        </p>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={`${completeLink(preview.link)}`}
                                        >
                                          {preview.link}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <ButtonChamp
                                    champ={preview.accept}
                                    onClick={() => {
                                      preview.accept
                                        ? toast.error(
                                            "Colaboração já foi aceita!"
                                          )
                                        : handleAcceptCollaboration(
                                            parseInt(preview.id)
                                          );
                                    }}
                                  >
                                    {preview.accept
                                      ? "Colaboração Confirmada"
                                      : "Confimar Colaboração"}
                                  </ButtonChamp>
                                  <hr />
                                </Collaboration>
                              ))}
                          </ul>
                        </div>
                        <div className="floatPage">
                          {arrTechnical.length > 0 && (
                            <Pagination
                              currentPage={currentPageTechnical}
                              countriesPerPage={countriesPerPageTechnical}
                              totalCountries={arrTechnical.length}
                              paginate={paginateTechnical}
                            />
                          )}
                        </div>
                      </Fade>
                    )}
                    {service && (
                      <Fade top duration={1600} distance="20px">
                        <div className="box-content">
                          <p id="title-content">Prestações de Serviços</p>
                          <ul className="items">
                            {arrService.length > 0 &&
                              currentService.map((preview) => (
                                <Collaboration key={preview.id}>
                                  <div id="participant">
                                    <div>
                                      <div className="icon">
                                        <div id="background-img">
                                          <img src={iconDonor} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="content">
                                      <p id="day">{preview.formattedDate}</p>
                                      <div className="user-box">
                                        <img src={id} alt="" />
                                        <p id="name">id : {preview.user}</p>
                                      </div>
                                      <div className="user-box">
                                        <img src={user} alt="" />
                                        <p id="name">
                                          perfil : {preview.perfil}
                                        </p>
                                      </div>
                                      {preview.content && (
                                        <>
                                          <hr />
                                          <p id="message-champ">
                                            {preview.content}
                                          </p>
                                          <hr />
                                        </>
                                      )}
                                      <div className="third-content">
                                        <p>
                                          Link com arquivo ou caminho da entrega
                                          do desafio:
                                        </p>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={`${completeLink(preview.link)}`}
                                        >
                                          {preview.link}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <ButtonChamp
                                    champ={preview.accept}
                                    onClick={() => {
                                      preview.accept
                                        ? toast.error(
                                            "Colaboração já foi aceita!"
                                          )
                                        : handleAcceptCollaboration(
                                            parseInt(preview.id)
                                          );
                                    }}
                                  >
                                    {preview.accept
                                      ? "Colaboração Confirmada"
                                      : "Confimar Colaboração"}
                                  </ButtonChamp>
                                  <hr />
                                </Collaboration>
                              ))}
                          </ul>

                          <div className="floatPage">
                            {arrService.length > 0 && (
                              <Pagination
                                currentPage={currentPageService}
                                countriesPerPage={countriesPerPageService}
                                totalCountries={arrService.length}
                                paginate={paginateService}
                              />
                            )}
                          </div>
                        </div>
                      </Fade>
                    )}
                    {institutional && (
                      <Fade top duration={1600} distance="20px">
                        <div className="box-content">
                          <p id="title-content">Parcerias Institucionais</p>
                          <ul className="items">
                            {arrInstitutional.length > 0 &&
                              currentInstitutional.map((preview) => (
                                <Collaboration key={preview.id}>
                                  <div id="participant">
                                    <div>
                                      <div className="icon">
                                        <div id="background-img">
                                          <img src={iconDonor} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="content">
                                      <p id="day">{preview.formattedDate}</p>
                                      <div className="user-box">
                                        <img src={id} alt="" />
                                        <p id="name">id : {preview.user}</p>
                                      </div>
                                      <div className="user-box">
                                        <img src={user} alt="" />
                                        <p id="name">
                                          perfil : {preview.perfil}
                                        </p>
                                      </div>
                                      {preview.content && (
                                        <>
                                          <hr />
                                          <p id="message-champ">
                                            {preview.content}
                                          </p>
                                          <hr />
                                        </>
                                      )}
                                      <div className="third-content">
                                        <p>
                                          Link com arquivo ou caminho da entrega
                                          do desafio:
                                        </p>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={`${completeLink(preview.link)}`}
                                        >
                                          {preview.link}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <ButtonChamp
                                    champ={preview.accept}
                                    onClick={() => {
                                      preview.accept
                                        ? toast.error(
                                            "Colaboração já foi aceita!"
                                          )
                                        : handleAcceptCollaboration(
                                            parseInt(preview.id)
                                          );
                                    }}
                                  >
                                    {preview.accept
                                      ? "Colaboração Confirmada"
                                      : "Confimar Colaboração"}
                                  </ButtonChamp>
                                  <hr />
                                </Collaboration>
                              ))}
                          </ul>

                          <div className="floatPage">
                            {arrInstitutional.length > 0 && (
                              <Pagination
                                currentPage={currentPageInstitutional}
                                countriesPerPage={countriesPerPageInstitutional}
                                totalCountries={arrInstitutional.length}
                                paginate={paginateInstitutional}
                              />
                            )}
                          </div>
                        </div>
                      </Fade>
                    )}
                  </div>
                </ProjectCollaborations>
              </Fade>
            )}
            {challenges && (
              <Fade left duration={1600} distance="20px">
                <ChallengesComponent>
                  <p id="title"> Meus Desafios </p>
                  <p className="text">
                    Você é do tipo que enxerga nos desafios grandes
                    oportunidades de crescimento profissional e pessoal?
                    <br />
                    Então utilize esse espaço para acompanhar sua participação
                    nos{" "}
                    <Link
                      to="challenges"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      Desafios We4Science
                    </Link>{" "}
                    !
                  </p>
                  <div className="content">
                    {currentChallenges.map((countries, number) => (
                      <Challenge key={number}>
                        <div className="challenge-content-1">
                          <img src={countries.challenge.first.url} alt="" />
                        </div>
                        <div className="challenge-content-2">
                          <div>
                            <p id="title-challenge">
                              {countries.challenge.title}
                            </p>
                            <p id="company">
                              Empresa: {countries.challenge.company.name}
                            </p>
                            <p id="date">
                              Data limite: {countries.challenge.conclusion}
                            </p>
                            <p id="category">
                              Categoria: {countries.challenge.category}
                            </p>
                            <section> </section>
                          </div>
                          <div className="challenge-price">
                            <p id="price-text"> Prêmio: </p>
                            <p id="price-value">
                              {countries.challenge.premium}
                            </p>
                            <p id="price-value-2"> , 00 </p>
                            <p id="price-text"> W$ </p>
                          </div>
                        </div>
                        <button id="challenge-content-3">
                          <img src={iconBlade} alt="" />
                          <Link to={`/challenge/${countries.challenge_id}`}>
                            <p> Ver desafio </p>
                          </Link>
                        </button>
                      </Challenge>
                    ))}
                  </div>
                  {allMyChallenges.length !== 0 && (
                    <div className="pagination">
                      <Pagination
                        currentPage={currentPageChallenges}
                        countriesPerPage={countriesPerPageChallenges}
                        totalCountries={allMyChallenges.length}
                        paginate={paginateChallenges}
                      />
                    </div>
                  )}
                </ChallengesComponent>
              </Fade>
            )}
            {newProject && (
              <Fade left duration={1600} distance="20px">
                <AboutComponentNew>
                  <Form onSubmit={handleSubmitProject} schema={schemaProject}>
                    <p id="title"> Cadastrar Projeto </p>
                    <div className="inputs">
                      <InputContent active>
                        <Input
                          name="name"
                          className="full"
                          type="text"
                          placeholder="Nome do Projeto: *"
                          onChange={(event) =>
                            setFormattedProject(event.target.value)
                          }
                          value={titleProject}
                          maxLength={65}
                        />
                        <p id="number-chars" style={{ fontSize: 16 }}>
                          {titleProject.length} / {65} Caracteres
                        </p>
                      </InputContent>
                      <DropDownContainer>
                        <DropDownHeader
                          id="small"
                          onClick={togglingTypeProject}
                        >
                          <p> {selectedOptionTypeProject || "Categoria *"} </p>
                          {isOpenTypeProject ? (
                            <img
                              className="icon-drop"
                              id="up-icon"
                              src={iconDrop}
                              alt=""
                            />
                          ) : (
                            <img className="icon-drop" src={iconDrop} alt="" />
                          )}
                        </DropDownHeader>
                        {isOpenTypeProject && (
                          <DropDownListContainer className="projects">
                            <DropDownList className="projects">
                              <Scrollbars style={{ width: 340, height: 50 }}>
                                {listOfTypes.map((option) => (
                                  <ListItem
                                    onClick={onOptionClickedTypeProject(option)}
                                    key={Math.random()}
                                  >
                                    {option}
                                  </ListItem>
                                ))}
                              </Scrollbars>
                            </DropDownList>
                          </DropDownListContainer>
                        )}
                      </DropDownContainer>
                      <Input
                        name="youtube_link"
                        type="text"
                        placeholder="Apresente sua campanha no Youtube (link)"
                      />
                      <InputContent>
                        <Input
                          name="start"
                          type="text"
                          placeholder="Data de início desta campanha:* dd/mm/aa"
                        />
                      </InputContent>
                      <InputContent>
                        <Input
                          name="end"
                          type="text"
                          placeholder="Data de término: * dd/mm/aa"
                        />
                      </InputContent>
                      <InputContent active>
                        <Input
                          name="value_goal"
                          className="full"
                          type="text"
                          placeholder="Meta de arrecadação de doações (em R$): * (não separe o valor com ponto)"
                        />
                      </InputContent>
                      <div className="box-goal">
                        <p id="attention">ATENÇÃO:</p>
                        <p id="text">
                          Calcule o valor da sua meta de arrecadação levando em
                          consideração o desconto da{" "}
                          <strong color="#000">Taxa de Administração</strong> da
                          Plataforma W4S (15%) sobre o valor a ser arrecadado
                        </p>
                      </div>

                      <InputContent>
                        <div className="input-imgs">
                          <p id="placeholder"> Imagens </p>
                          <BoxLabel active={items.length === 3 ? true : false}>
                            <label htmlFor="avatar">
                              <div>
                                <img src={iconClips} id="clips" alt="" />
                                <input
                                  type="file"
                                  id="avatar"
                                  accept="image/*"
                                  onChange={handleChange}
                                  disabled={items.length === 3 ? true : false}
                                  ref={ref}
                                />
                              </div>
                            </label>
                          </BoxLabel>
                        </div>
                        <p id="obs-input" style={{ marginTop: 10 }}>
                          Obs: Cadastre necessariamente 03 imagens (dimensões
                          recomendadas: 628x360 pixels).
                        </p>
                      </InputContent>
                    </div>
                    <div className="previews">
                      {items.map((preview) => (
                        <div className="preview" key={preview.id}>
                          <img id="teste" src={preview.url} alt="avatar" />
                          <button
                            type="button"
                            onClick={() => deleteImg(preview)}
                          >
                            Exluir
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="institution">
                      <DropDownContainer>
                        <DropDownHeader
                          id="small"
                          onClick={togglingTypeInstitution}
                        >
                          <p>
                            {selectedOptionTypeInstitution || "Instituição"}
                          </p>
                          {isOpenTypeInstitution ? (
                            <img
                              className="icon-drop"
                              id="up-icon"
                              src={iconDrop}
                              alt=""
                            />
                          ) : (
                            <img className="icon-drop" src={iconDrop} alt="" />
                          )}
                        </DropDownHeader>
                        {isOpenTypeInstitution && (
                          <DropDownListContainer className="projects">
                            <DropDownList className="projects">
                              <Scrollbars style={{ width: 340, height: 50 }}>
                                {allInstitutions.map((option) => (
                                  <ListItem
                                    onClick={onOptionClickedTypeInstitution(
                                      option
                                    )}
                                    key={Math.random()}
                                  >
                                    {option && option.company}
                                  </ListItem>
                                ))}
                              </Scrollbars>
                            </DropDownList>
                          </DropDownListContainer>
                        )}
                      </DropDownContainer>
                      <PreviewsInstitution>
                        <PreviewsList>
                          <Scrollbars style={{ width: 340, height: 80 }}>
                            {instutions.map((option) => (
                              <Preview key={Math.random()}>
                                <p id="id-university">{`${option.company} `}</p>
                                <div className="trash">
                                  <img src={iconTrash} alt="" />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveInstitution(option)
                                    }
                                  >
                                    Excluir
                                  </button>
                                </div>
                              </Preview>
                            ))}
                          </Scrollbars>
                        </PreviewsList>
                      </PreviewsInstitution>
                    </div>
                    <div className="textareas">
                      <InputContent active>
                        <Textarea
                          name="summary"
                          maxLength={145}
                          placeholder="Resumo do projeto: *"
                          id="height-1"
                          onChange={(event) => setNewItem(event.target.value)}
                          value={newItem}
                        />
                      </InputContent>
                      <p
                        style={{
                          fontSize: 16,
                          marginBottom: 15,
                          color: "#7f7fb9",
                        }}
                      >
                        {newItem.length} / {145} Caracteres
                      </p>

                      <InputContent active>
                        <Textarea
                          name="about"
                          placeholder="O que é: *"
                          id="height-2"
                        />
                      </InputContent>
                      <InputContent active>
                        <Textarea
                          name="who_benefits"
                          placeholder="Quem se beneficia: *"
                          id="height-3"
                        />
                      </InputContent>
                      <InputContent active>
                        <Textarea
                          name="goals_objectives"
                          placeholder="Principais objetivos e metas: *"
                          id="height-3"
                        />
                      </InputContent>
                      <InputContent active>
                        <Textarea
                          name="stage"
                          placeholder="Estágio atual de desenvolvimento: *"
                          id="height-3"
                        />
                      </InputContent>
                      <InputContent active>
                        <Textarea
                          name="partners"
                          placeholder="Parceiros: *"
                          id="height-3"
                        />
                      </InputContent>
                      <InputContent active>
                        <Textarea
                          name="necessary_resources"
                          placeholder="Recursos necessários: *"
                          id="height-3"
                        />
                      </InputContent>
                      <InputContent active>
                        <Textarea
                          name="importance_collaboration"
                          placeholder="Importância da colaboração para o desenvolvimento deste projeto: *"
                          id="height-3"
                        />
                      </InputContent>
                      <Textarea
                        name="useful_links"
                        placeholder="Links úteis ( Separe cada link por ';' ):"
                        id="height-3"
                      />

                      <hr />
                    </div>
                    <div className="content-development-team">
                      <p id="title"> Equipe do projeto </p>
                      {development.length > 0 && (
                        <DevelopmentList>
                          <Scrollbars style={{ width: 740, height: 370 }}>
                            {development.map((option) => (
                              <DevelopmentTeam key={option.id}>
                                {parseInt(option.id) === parseInt(profile.id)
                                  ? false
                                  : true && (
                                      <img
                                        id="icon-trash"
                                        src={iconTrash}
                                        onClick={() =>
                                          handleRemoveDeveloper(option)
                                        }
                                        alt=""
                                      />
                                    )}
                                <div className="profile">
                                  <div id="border">
                                    <div id="img-profile">
                                      <img
                                        src={option.avatar && option.avatar.url}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="content-profile">
                                  <p> Nome: {option.name} </p>
                                  <p> Profissão: {option.profession} </p>
                                  <p> Local de trabalho: {option.company} </p>
                                  <p> Função no projeto: Idealizador </p>
                                  <p id="description">{option.curriculum}</p>
                                  <section id="section-content-profile"></section>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={completeLink(option.link_curriculum)}
                                  >
                                    Currículo completo
                                  </a>
                                  <section id="section-content-profile"></section>
                                  <div className="social-networks">
                                    <p> Redes sociais: </p>
                                    <div className="icons-social">
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://api.whatsapp.com/send?phone=${option.whatsapp}`}
                                      >
                                        <FaWhatsapp size={25} />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://www.instagram.com/${completeLink(
                                          option.instagram
                                        )}`}
                                      >
                                        <FaInstagram size={25} />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://twitter.com/${completeLink(
                                          option.twitter
                                        )}`}
                                      >
                                        <FaTwitter size={25} />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://www.facebook.com/${completeLink(
                                          option.facebook
                                        )}`}
                                      >
                                        <FaFacebookF size={22} />
                                      </a>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://www.linkedin.com/in/${completeLink(
                                          option.linkedin
                                        )}`}
                                      >
                                        <FaLinkedinIn size={24} />
                                      </a>

                                      <img
                                        id="icon-email"
                                        src={iconEmail}
                                        alt={option.email}
                                        title={option.email}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </DevelopmentTeam>
                            ))}
                          </Scrollbars>
                        </DevelopmentList>
                      )}
                      <div className="new-member">
                        <p id="title-new-member"> Inserir integrante </p>
                        <div className="input">
                          <input
                            name="member"
                            type="text"
                            onChange={(event) =>
                              setSearchId(event.target.value)
                            }
                            placeholder="Nome do participante"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              searchScientist(searchId);
                            }}
                          >
                            Inserir
                          </button>
                        </div>
                      </div>
                      {searchByNameScientists.length !== 0 && (
                        <ContentSelectMember>
                          <p>Clique no usuário que deseja adicionar a Equipe</p>
                          <ul>
                            <Scrollbars style={{ width: 690, height: 160 }}>
                              {searchByNameScientists.map((preview) => (
                                <AllUsersSearch
                                  onClick={() => {
                                    addDeveloper(preview.id);
                                  }}
                                  key={preview.id}
                                >
                                  <div className="profile">
                                    <div id="border">
                                      <div id="img-profile">
                                        <img
                                          src={
                                            preview.avatar && preview.avatar.url
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="content-profile">
                                    <p>Nome: {preview.name}</p>
                                    <p>Profissão: {preview.profession}</p>
                                  </div>
                                </AllUsersSearch>
                              ))}
                            </Scrollbars>
                          </ul>
                        </ContentSelectMember>
                      )}
                      <hr />
                    </div>
                    <div className="collaboration">
                      <div className="content-2">
                        <div className="title">
                          <img src={iconSecondType} alt="" />
                          <p id="title"> Colaboração técnica </p>
                        </div>
                        <div className="inputs">
                          <InputContent active>
                            <Textarea
                              name="technical_collaboration"
                              placeholder="Que tipo de colaboração técnica ajudaria a desenvolver seu projeto? *"
                            />
                          </InputContent>
                        </div>
                      </div>
                      <div className="content-3">
                        <div className="title">
                          <img src={iconThirdType} alt="" />
                          <p id="title"> Prestação de serviços </p>
                        </div>
                        <div className="inputs">
                          <InputContent active>
                            <Textarea
                              name="services_provision"
                              placeholder="Quais tipos de serviços profissionais lhe ajudariam a desenvolver este projeto? *"
                            />
                          </InputContent>
                        </div>
                      </div>
                      <div className="content-4">
                        <div className="title">
                          <img src={iconFourthType} alt="" />
                          <p id="title"> Parcerias Institucionais </p>
                        </div>
                        <div className="inputs">
                          <InputContent active>
                            <Textarea
                              name="institutional_partnerships"
                              placeholder="Qual perfil de empresas ou instituições você considera bem-vindo para estabelecer parcerias ao desenvolvimento deste projeto? *"
                            />
                          </InputContent>
                        </div>
                      </div>
                      <p
                        style={{
                          float: "left",
                          color: "#7f7fb9",
                          marginLeft: 15,
                          marginBottom: 30,
                        }}
                      >
                        *Campo obrigatório
                      </p>
                    </div>

                    <hr />

                    <label className="label">
                      <input
                        className="label__checkbox"
                        type="checkbox"
                        onClick={toggleAccept}
                      />

                      <span className="label__text">
                        <span className="label__check">
                          {accept && (
                            <FaCheck
                              className="fa fa-check icon"
                              color={"#7f7fb9"}
                            />
                          )}
                        </span>
                        <p id="text-checkbox">
                          Li e aceito os
                          <Link id="term" to="/terms-money">
                            Termos de Doação, Aporte e Saque
                          </Link>
                          da We4Science
                        </p>
                      </span>
                    </label>

                    <div className="box-sub">
                      <div className="fs-option">
                        <p id="text-sub">
                          Se sua pesquisa ou projeto científico é desenvolvido
                          em uma instituição pública, clique aqui para
                          cadastrá-lo!
                        </p>
                        <button type="submit" id="btn-next" disabled={items.length < 3}>
                          Cadastrar
                        </button>
                      </div>

                      <div className="sec-option">
                        <p id="text-sub">
                          Se o seu projeto científico é desenvolvido de forma
                          independente; se você é o proprietário e/ou
                          responsável legal pelos direitos autorais e de patente
                          deste projeto; e/ou se você é o proprietário legal da
                          instituição ou empresa que está desenvolvendo este
                          projeto, então clique aqui para cadastrá-lo:
                        </p>
                        <button
                          type="submit"
                          id="btn-next"
                          onClick={() => setSpecial(true)}
                        >
                          Solicitar Permissão Especial
                        </button>
                      </div>
                    </div>
                  </Form>
                </AboutComponentNew>
              </Fade>
            )}
            {collaborations && (
              <Fade left duration={1600} distance="20px">
                <CollaborationsComponent>
                  <p id="title"> Minhas Colaborações </p>
                  <p className="text">
                    Confira aqui suas colaborações!
                    <br />E por falar nisso, que tal enchermos essa página com
                    mais colaborações? A Ciência agradece!
                  </p>
                  <div className="content">
                    {myCollaborations.length > 0 &&
                      currentCollaborations.map((preview) => (
                        <Project key={preview.id}>
                          <div className="content-1">
                            <img
                              src={
                                preview.project.first &&
                                preview.project.first.url
                              }
                              alt=""
                            />
                          </div>

                          <div className="content-2">
                            <div className="titles">
                              <p id="title-project">{preview.project.name}</p>
                              {preview && preview.project.special ? (
                                <div>
                                  <FaLockOpen color="#227af1" size={16} />

                                  <p>Tenho Permissão Especial</p>
                                </div>
                              ) : (
                                <div>
                                  <img src={iconCompanyProject} alt="" />
                                  <p>
                                    Instituição:
                                    {preview && preview.project.institution}
                                  </p>
                                </div>
                              )}

                              <div>
                                <img src={iconCheck} alt="" />
                                <p> Categoria: {preview.project.category}</p>
                              </div>

                              <section></section>
                            </div>

                            <div className="resume">
                              <p id="title-resume">Resumo da colaboração</p>
                              <p id="text-content">{preview.content}</p>
                              <section></section>
                            </div>
                            <div className="data">
                              <p>Data da colaboraçao:{preview.formattedDate}</p>
                            </div>
                          </div>

                          <div className="content-3">
                            <img src={atomProject} alt="" />
                            <p>
                              <Link to={`/project/${preview.project.id}`}>
                                Ver projeto
                              </Link>
                            </p>
                          </div>
                        </Project>
                      ))}
                  </div>
                  <div className="floatPage">
                    {myCollaborations.length > 0 && (
                      <Pagination
                        currentPage={currentPageCollaborations}
                        countriesPerPage={countriesPerPageCollaborations}
                        totalCountries={myCollaborations.length}
                        paginate={paginateCollaborations}
                      />
                    )}
                  </div>
                </CollaborationsComponent>
              </Fade>
            )}
            {historic && (
              <Fade left duration={1600} distance="20px">
                <HistoricComponent>
                  <p id="title"> Minhas Moedas Whuffie</p>
                  <p className="text">
                    Aqui moeda não é dinheiro, mas é um incentivo e tanto!
                    Acompanhe seu saldo e veja o que pode adquirir em nosso
                    <Link
                      to="store"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      Mercado Whuffie
                    </Link>
                    !
                  </p>
                  <div className="content">
                    {extracts &&
                      currentExtracts.map((preview) => (
                        <div className="transactions" key={preview.id}>
                          <div className="title-transaction">
                            {preview.type === "cosmos" ? (
                              <p id="text-title-transaction">
                                Doação para Fundo Cosmos
                              </p>
                            ) : preview.type === "project" ? (
                              <p id="text-title-transaction">
                                Doação para projeto: {preview.project}
                              </p>
                            ) : preview.type === "collaboration" ? (
                              <p id="text-title-transaction">
                                Colaboração no Projeto: {preview.project}
                              </p>
                            ) : (
                              <p id="text-title-transaction">
                                Doação para o Projeto: {preview.project}
                              </p>
                            )}

                            <div className="data">
                              <img
                                id="icon-calendar"
                                src={iconCalendarPurple}
                                alt=""
                              />
                              <p id="day"> {preview.formattedDate}</p>
                            </div>
                          </div>
                          <p className="text"> Whuffies adquiridos:</p>
                          <div className="value">
                            <p id="big-letter">{preview.whuffies}</p>
                            <p className="small-letter"> W$ </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="floatPage">
                    {extracts.length > 0 && (
                      <Pagination
                        currentPage={currentPageExtracts}
                        countriesPerPage={countriesPerPageExtracts}
                        totalCountries={extracts.length}
                        paginate={paginateExtracts}
                      />
                    )}
                  </div>
                </HistoricComponent>
              </Fade>
            )}
            {shopping && (
              <Fade left duration={1600} distance="20px">
                <ShoppingComponent>
                  <p id="title"> Minhas Compras Whuffie </p>
                  <p className="text">
                    Aqui você confere todas as suas compras realizadas no
                    <Link
                      to="store"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      Mercado Whuffie
                    </Link>
                    .
                    <br />
                    Lembre-se de navegar pelo nosso Mercado de vez em quando
                    para encontrar novos produtos, serviços e experiências que
                    lhe agradem! Porém, se o seu saldo Whuffie estiver pequeno,
                    é só encontrar projetos para doar ou colaborar!
                  </p>
                  <div className="content">
                    {myShoppings.length > 0 &&
                      currentShoppings.map((preview) => (
                        <div className="product" key={preview.id}>
                          <Product>
                            <div id="product-content-1">
                              <img
                                src={
                                  preview.product.first &&
                                  preview.product.first.url
                                }
                                alt=""
                              />
                            </div>
                            <div id="product-content-2">
                              <div>
                                <p id="product-text-1-content-2">
                                  {preview.product.name}
                                </p>
                                <p id="category">
                                  Categoria: {preview.product.category}
                                </p>
                                <p id="category">
                                  Ofertante:{" "}
                                  {preview.product &&
                                    preview.product.company &&
                                    preview.product.company.name}
                                </p>
                                <p id="category">
                                  Status:{" "}
                                  {preview.status === true
                                    ? "Entregue"
                                    : "Pendente"}
                                </p>
                                <section></section>
                              </div>

                              <div id="product-price">
                                <p id="product-text-3-content-2">
                                  {preview.product.value}
                                </p>
                                <p id="product-text-4-content-2">W$</p>
                              </div>
                              <section></section>
                              <div className="data">
                                <p>Data da compra: {preview.formattedDate}</p>
                              </div>
                            </div>
                            <button id="product-content-3">
                              <img src={carStore} alt="" />
                              <Link to={`/product/${preview.product.id}`}>
                                <p>Ver produto</p>
                              </Link>
                            </button>
                          </Product>
                          <button
                            className="company-infos"
                            onClick={() => {
                              setShopping(false);
                              toggle();
                              setCompanyInfos(
                                preview &&
                                  preview.product &&
                                  preview.product.company
                              );
                            }}
                          >
                            <img src={iconProducts} alt="" /> Ofertante
                          </button>
                        </div>
                      ))}
                  </div>
                  <div className="floatPage">
                    {myShoppings.length > 0 && (
                      <Pagination
                        currentPage={currentPageShoppings}
                        countriesPerPage={countriesPerPageShoppings}
                        totalCountries={myShoppings.length}
                        paginate={paginateShoppings}
                      />
                    )}
                  </div>
                </ShoppingComponent>
              </Fade>
            )}

            {aboutCompany && (
              <Fade left duration={1600} distance="20px">
                <AboutCompanyComponent>
                  <p id="title">
                    Informações sobre a empresa ofertante do seu produto
                  </p>
                  <div className="infos">
                    <div className="box-img">
                      <img
                        id="avatar"
                        src={companyInfos.avatar && companyInfos.avatar.url}
                        alt=""
                      />
                    </div>

                    <div className="datas">
                      <p id="title-data">Dados</p>

                      <div className="all">
                        <div className="about">
                          <div className="info">
                            <img src={id} alt="" />
                            <p id="type">Id: </p>
                            <p>{companyInfos.id}</p>
                          </div>
                          <div className="info">
                            <img src={user} alt="" />
                            <p id="type">Nome: </p>
                            <p>{companyInfos.name}</p>
                          </div>
                          <div className="info">
                            <FaEnvelope color="#227af1" />
                            <p id="type">Email: </p>
                            <p>{companyInfos.email}</p>
                          </div>
                          <div className="info">
                            <FaPhone color="#227af1" />
                            <p id="type">Telefone: </p>
                            <p>{companyInfos.phone}</p>
                          </div>
                        </div>

                        <div className="adress">
                          <div className="adress-info">
                            <p id="type">Logradouro:</p>
                            <p>{companyInfos.adress}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Bairro:</p>
                            <p> {companyInfos.neighborhood}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Cidade: </p>
                            <p>{companyInfos.city}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">CEP:</p>
                            <p>{companyInfos.cep}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Estado: </p>
                            <p>{companyInfos.state}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AboutCompanyComponent>
              </Fade>
            )}
          </ProfileContent>

          <Footer />
        </Content>
      </Wrapper>
    </>
  );
}

export default ScientistProfile;
