import React, { useRef } from "react";

// Libraries
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Bounce } from "react-reveal";
// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";

import InputMessage from "../../components/Input";
import Messages from "../../components/Messages";
import io from "socket.io-client";

import { format } from "date-fns";
import pt from "date-fns/locale/pt-BR";

import iconProjects from "../../assets/icons/Common/projects-2.svg";
import iconChatPeople from "../../assets/icons/Common/avatar-input.svg";
import { Scrollbars } from "react-custom-scrollbars";
import Project from "../../components/NewProject";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  ChatContent,
  Projects,
  Subscribe,
  AboutProject,
  Donation,
  WhuffieCompany,
  Message as MessagesOld,
} from "./styles";
// External Icons
import { FaLockOpen } from "react-icons/fa";

// Icons
import iconChat from "../../assets/icons/Chat/icon-chat.svg";
import projectIcon from "../../assets/icons/Common/project-icon.svg";
import donationIcon from "../../assets/icons/Common/donation-icon-2.svg";
// Icons from the other Foulder
import iconPeople from "../../assets/icons/Project/people.svg";
import iconCompany from "../../assets/icons/Project/company.svg";
import iconId from "../../assets/icons/Project/id.svg";
import iconCheck from "../../assets/icons/Project/check.svg";
import iconCalendar from "../../assets/icons/Project/calendar.svg";

// Images -> Common Foulder
import imgHeader from "../../assets/images/Project/img-header.svg";
import imgProject from "../../assets/images/Project/second.svg";

// import queryString from 'querystring';
import api from "../../services/api";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Input, Textarea } from "@rocketseat/unform";

import { store } from "../../store";

import InfoDonation from "../Project/components/InfoDonation";
import ShareProject from "../Project/components/ShareProject";
import { Helmet } from "react-helmet";
import { FormattedNumber, IntlProvider } from "react-intl";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let socket;

function Chat() {
  const history = useHistory();
  let query = useQuery();

  var room = query.get("room");
  var type = query.get("type");
  var id = parseInt(query.get("id"));

  useEffect(() => {
    if (
      isNaN(id) ||
      room === undefined ||
      room === null ||
      type === undefined ||
      type === null
    ) {
      history.go(0);
    }
  }, [room, id, history, type]);
  const [all, setAll] = useState([]);
  const { perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  useEffect(() => {
    async function loadMessages() {
      const { data } = await api.get(`messages-room/${room}`);

      const aux = [];

      for (let index = 0; index < data.length; index++) {
        let accept =
          data[index].perfil === perfil && data[index].user === profile.id
            ? true
            : false;

        let auxMessage = Object.assign({
          id: data[index].id,
          name: data[index].name,
          message: data[index].message,
          day: data[index].day,
          accept,
        });

        aux.push(auxMessage);
      }
      setAll(aux);
    }
    loadMessages();
  }, [perfil, profile, history, room]);

  const [project, setProject] = useState();
  const [loaded, setLoaded] = useState([]);
  const [totalScientistWhuffies, setTotalScientistWhuffies] = useState([]);

  const name = profile.name;
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    socket = io(`${process.env.REACT_APP_API_URL}`, {
      transports: ["websocket", "polling", "flashsocket"],
    });

    socket.emit("join", { name, room }, (error) => {
      if (error) {
        //alert(error);
      }
    });
  }, [name, profile, room]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages((messages) => [...messages, message]);
    });
  }, [name]);
  const sendMessage = (event) => {
    event.preventDefault();

    const data = Object.assign({
      user: profile.id,
      name,
      perfil,
      type,
      room,
      project_id: id,
      day: format(new Date(), "dd'/'MM'/'yy", {
        locale: pt,
      }),
      message,
    });

    submitMessage(data);

    if (message) {
      socket.emit("sendMessage", message, () => setMessage(""));
    }
  };

  async function submitMessage(data) {
    try {
      await api.post("messages", data);
    } catch (error) {
      console.log("error");
    }
  }

  useEffect(() => {
    async function loadingProjects() {
      try {
        const { data } = await api.get(`project/${id}`);
        setProject({
          ...data.project,
        });
        const res = await api.get(
          `scientists-total-whuffies/${data.project.scientist_id}`
        );
        setTotalScientistWhuffies(res.data);
      } catch (e) {
        console.log("");
      }
    }
    loadingProjects();
  }, [id]);

  useEffect(() => {
    async function loadingProduct() {
      try {
        const { data } = await api.get(`rich-projects/${id}`);
        setLoaded(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingProduct();
  }, [id]);

  function goBackToProject(id) {
    setTimeout(function () {
      history.push(`/collaboration/${id}`);
    }, 50);
  }

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    async function getOtherProjects() {
      const res = await api.get(
        `other-projects/${project && project.id}/${
          project && project.category
        }/${project && project.scientist_id}`
      );

      const aux = [];

      for (let index = 0; index < 3; index++) {
        if (res.data[index] !== undefined) {
          aux.push(res.data[index]);
        }
      }

      setProjects(aux);
    }
    getOtherProjects();
  }, [project]);

  const formRef = useRef(null);

  function functionThatResetsForm() {
    formRef.current.reset();
  }

  async function handleCollaboration(
    data,
    { resetForm = functionThatResetsForm }
  ) {
    data.project_id = parseInt(id);
    data.kind = type;
    data.perfil = perfil;
    data.user = profile.id;
    data.accept = false;

    try {
      await api.post("collaborations", data);
      toast.success("Colaboração enviada!");
    } catch (error) {
      toast.error("erro ao enviar colaboração!");
    }

    resetForm();
  }

  return (
    <>
      <Helmet>
        <title>Chat</title>
      </Helmet>
      <Bounce bottom duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section id="second-content-header">
                <img id="img-header" src={imgHeader} alt="" />
                <div className="info-project">
                  <p id="title">{project && project.name}</p>
                  <div className="info-project-content">
                    <div className="first-content">
                      <div>
                        <img id="people-icon" src={iconPeople} alt="" />
                        <p>Autor: {project && project.scientist.name}</p>
                      </div>
                      {project && project.special ? (
                        <div>
                          <FaLockOpen color="#fff" size={18} />

                          <p>Tenho Permissão Especial</p>
                        </div>
                      ) : (
                        <div>
                          <img src={iconCompany} alt="" />
                          <p>
                            Instituição:
                            {project && project.institution.split(";")[0]}
                          </p>
                        </div>
                      )}
                      <div>
                        <img src={iconId} alt="" />
                        <p>ID do projeto: {project && project.id}</p>
                      </div>
                    </div>
                    <div className="second-content">
                      <div>
                        <img src={iconCheck} alt="" />
                        <p>Categoria: {project && project.category}</p>
                      </div>
                      {/*
                      <div>
                        <img src={iconCalendar} alt="" />
                        <p>Data de início: {project && project.start}</p>
                      </div>
                      */}
                      <div>
                        <img src={iconCalendar} alt="" />
                        <p>Data de término: {project && project.end}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="circles">
                  <div id="first-circle"></div>
                  <div id="second-circle"></div>
                  <div id="third-circle"></div>
                </div>
              </section>
            </Header>

            <ChatContent>
              <AboutProject>
                <div className="first-content-about">
                  <div className="title-chat">
                    <img src={iconChat} alt="" />
                    <p id="title-content-chat">Chat colaboração</p>
                  </div>

                  {all.length > 0 && (
                    <>
                      <div className="old">
                        <p id="title-box">Mensagens antigas</p>
                        <div className="messages">
                          <Scrollbars style={{ width: 650, height: 500 }}>
                            {all.map((preview) => (
                              <MessagesOld
                                key={preview.id}
                                sent={preview.accept}
                              >
                                <div className="icon-people">
                                  <div className="box">
                                    <section id="border">
                                      <img src={iconChatPeople} alt="" />
                                    </section>
                                  </div>
                                </div>
                                <div className="content-message">
                                  <p id="day">{preview.day}</p>
                                  <p id="name">{preview.name}</p>
                                  <p id="text">{preview.message}</p>
                                </div>
                              </MessagesOld>
                            ))}
                          </Scrollbars>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="now">
                    {messages.length > 0 && (
                      <>
                        <hr />

                        <p id="title-box">Mensagens atuais</p>
                        <div className="messages">
                          <Messages messages={messages} name={name} />
                        </div>
                      </>
                    )}

                    <div className="send-message">
                      <section id="section-send-msg"></section>
                      <InputMessage
                        message={message}
                        setMessage={setMessage}
                        sendMessage={sendMessage}
                      />
                    </div>
                  </div>

                  <section id="section-first-content"></section>

                  <div className="finish-challenge">
                    <p id="title-finish-challenge">Comunicar colaboração</p>
                    <hr />
                    <p id="text-finish-challenge">
                      Que orgulho! Agora você está a poucos cliques de se tornar
                      um apoiador ou apoiadora da Ciência!
                    </p>
                    <Form onSubmit={handleCollaboration} ref={formRef}>
                      <Input
                        type="text"
                        name="link"
                        placeholder="Link com arquivo ou endereço da colaboração"
                      />
                      <Textarea
                        name="content"
                        placeholder="Descreva algo sobre a colaboração (opçional)"
                      />
                      <button type="submit" id="btn-finish">
                        Realizar Entrega
                      </button>
                    </Form>
                  </div>

                  <section id="section-first-content"></section>
                  <button
                    id="prev-button"
                    onClick={() => goBackToProject(project && project.id)}
                  >
                    Voltar
                  </button>
                </div>

                <aside id="second-content-about">
                  <InfoDonation data={project} />
                  {loaded.length > 0 && (
                    <Donation>
                      <div id="title-donation">
                        <img src={donationIcon} alt="" />
                        <p id="text-title-donation">Ranking de doações</p>
                      </div>
                      <section></section>
                      <ul>
                        {loaded.map((preview) => (
                          <li id="list-people-donation" key={preview.id}>
                            <div id="position-people">
                              <p> {loaded.indexOf(preview) + 1} º </p>
                            </div>
                            <div id="data-people">
                              <p> {preview.name} </p>
                              <div>
                                <p id="cash"> {preview.value} </p>{" "}
                                <p id="dot"> Wuffys </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Donation>
                  )}

                  <ShareProject
                    id={project && project.id}
                    name={project && project.name}
                    description={project && project.summary}
                  />

                  {project && !project.special && (
                    <WhuffieCompany>
                      <p>Whuffies da instituição</p>
                      <section></section>
                      <div className="values">
                        <p id="values-text">
                          A instituição já acumulou em todos seus projetos:
                        </p>
                        <IntlProvider locale="pt-BR">
                          <p id="values-cash">
                            {" "}
                            W${" "}
                            <FormattedNumber
                              minimumFractionDigits={2}
                              value={totalScientistWhuffies.total}
                            />{" "}
                          </p>
                        </IntlProvider>
                      </div>
                    </WhuffieCompany>
                  )}
                </aside>
              </AboutProject>

              <Projects>
                <section></section>
                <div className="title-projects">
                  <img src={iconProjects} alt="" />
                  <p>VEJA OUTROS PROJETOS</p>
                </div>
                <div id="projects">
                  {projects &&
                    projects.map((preview) => <Project data={preview} />)}
                </div>
              </Projects>

              <Subscribe>
                <div id="donation-content-1">
                  <img src={projectIcon} alt="" />
                  <p id="donation-text-1">
                    INSCREVA
                    <br /> SEU PROJETO
                  </p>
                  <p id="donation-text-2">
                    Ao inscrever um breve e simples resumo do seu projeto ou
                    pesquisa científica gratuitamente em nossa plataforma, você
                    abre um vasto horizonte para a captação de recursos
                    financeiros, intercâmbio de conhecimentos, busca de
                    parceiros institucionais nacionais e internacionais, além de
                    impulsionar sua carreira profissional!
                  </p>

                  <button>Clique aqui e saiba como</button>

                  <div id="first-circle-donation"> </div>
                  <div id="second-circle-donation"></div>
                  <div id="third-circle-donation"></div>
                </div>
                <img id="img-project" src={imgProject} alt="" />
              </Subscribe>
            </ChatContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Chat;
