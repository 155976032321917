import { takeLatest, all, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "../../../services/api";

import {
    updateProfileSuccess,
    updateProfileFailure,
    creditSuccess,
    creditFailure,
    debitSuccess,
    debitFailure,
    socialNetworksSuccess,
    socialNetworksFailure,
} from "./actions";

export function* updateProfile({ payload }) {
    var response;

    const { perfil } = payload.data;


    if (perfil === "Apoiador/Visitante") {
        const {
            name,
            phone,
            email,
            sex,
            whatsapp,
            facebook,
            linkedin,
            twitter,
            instagram,
            cpf,
            profession,
            company,
            schooling,
            link_curriculum,
            curriculum,
            adress,
            neighborhood,
            state,
            city,
            cep,
            whuffie,
            password,
            avatar_id,
        } = payload.data;

        const profile = Object.assign({
            name,
            phone,
            email,
            sex,
            whatsapp,
            facebook,
            linkedin,
            twitter,
            instagram,
            cpf,
            profession,
            company,
            schooling,
            link_curriculum,
            curriculum,
            adress,
            neighborhood,
            state,
            city,
            cep,
            whuffie,
            password,
            avatar_id,
        });

        try {
            response = yield call(api.put, "supporters", profile);

            toast.success("Perfil atualizado com sucesso!");

            yield put(updateProfileSuccess(response.data));
        } catch (err) {
            toast.error("Erro ao atualizar perfil, confira seus dados!");
            yield put(updateProfileFailure());
        }
    } else if (perfil === "Apoiador Empresarial/Desafiante") {
        const {
            name,
            email,
            password,
            phone,
            whatsapp,
            facebook,
            youtube,
            linkedin,
            twitter,
            instagram,
            cnpj,
            adress,
            neighborhood,
            state,
            city,
            cep,
            about,
            website,
            representative_name,
            representative_sex,
            representative_phone,
            representative_email,
            representative_cpf,
            avatar_id,
        } = payload.data;

        const profile = Object.assign({
            name,
            email,
            password,
            phone,
            whatsapp,
            facebook,
            youtube,
            linkedin,
            twitter,
            instagram,
            cnpj,
            adress,
            neighborhood,
            state,
            city,
            cep,
            about,
            website,
            representative_name,
            representative_sex,
            representative_phone,
            representative_email,
            representative_cpf,
            avatar_id
        });
        try {
            response = yield call(api.put, "companies", profile);

            toast.success("Perfil atualizado com sucesso!");

            yield put(updateProfileSuccess(response.data));
        } catch (err) {
            toast.error("Erro ao atualizar perfil, confira seus dados!");
            yield put(updateProfileFailure());
        }
    } else if (perfil === "Cientista/Pesquisador") {
        const {
            name,
            email,
            password,
            phone,
            sex,
            whatsapp,
            facebook,
            youtube,
            linkedin,
            twitter,
            instagram,
            cpf,
            profession,
            company,
            schooling,
            link_curriculum,
            curriculum,
            adress,
            neighborhood,
            state,
            city,
            cep,
            avatar_id,
        } = payload.data;

        const profile = Object.assign({
            name,
            email,
            password,
            phone,
            sex,
            whatsapp,
            facebook,
            youtube,
            linkedin,
            twitter,
            instagram,
            cpf,
            profession,
            company,
            schooling,
            link_curriculum,
            curriculum,
            adress,
            neighborhood,
            state,
            city,
            cep,
            avatar_id,
        });

        try {
            response = yield call(api.put, "scientists", profile);

            toast.success("Perfil atualizado com sucesso!");

            yield put(updateProfileSuccess(response.data));
        } catch (err) {
            toast.error("Erro ao atualizar perfil, confira seus dados!");
            yield put(updateProfileFailure());
        }
    }
}

export function* credit({ payload }) {
    try {
        var res;

        const { perfil, id } = payload.data;

        if (perfil === "Apoiador/Visitante") {
            res = yield call(api.get, `credit-supporters/${id}`);
        } else if (perfil === "Apoiador Empresarial/Desafiante") {
            res = yield call(api.get, `credit-companies/${id}`);
        } else if (perfil === "Cientista/Pesquisador") {
            res = yield call(api.get, `credit-scientist/${id}`);
        }

        yield put(creditSuccess(res.data));
    } catch (err) {
        yield put(creditFailure());
    }
}

export function* creditSocialNetwoks({ payload }) {
    try {
        var res;

        const { perfil, id } = payload.data;

        if (perfil === "Apoiador/Visitante") {
            res = yield call(api.get, `social-networks-supporters/${id}`);
        } else if (perfil === "Apoiador Empresarial/Desafiante") {
            res = yield call(api.get, `social-networks-companies/${id}`);
        } else if (perfil === "Cientista/Pesquisador") {
            res = yield call(api.get, `social-networks-scientist/${id}`);
        }

        yield put(socialNetworksSuccess(res.data));
    } catch (err) {
        yield put(socialNetworksFailure());
    }
}

export function* debit({ payload }) {
    try {
        var res;

        const { perfil, id, value } = payload.data;

        if (perfil === "Apoiador/Visitante") {
            res = yield call(api.put, `debit-supporters/${id}/${parseFloat(value)}`);
        } else if (perfil === "Apoiador Empresarial/Desafiante") {
            res = yield call(api.put, `debit-companies/${id}/${parseFloat(value)}`);
        } else if (perfil === "Cientista/Pesquisador") {
            res = yield call(api.put, `debit-scientist/${id}/${parseFloat(value)}`);
        }

        yield put(debitSuccess(res.data));
    } catch (err) {
        yield put(debitFailure());
    }
}

export default all([
    takeLatest("@user/UPDATE_PROFILE_REQUEST", updateProfile),
    takeLatest("@user/CREDIT_REQUEST", credit),
    takeLatest("@user/CREDIT_SOCIAL_REQUEST", creditSocialNetwoks),
    takeLatest("@user/DEBIT_REQUEST", debit),
]);