import React from "react";

import { Container } from "./styles";
import carStore from "../../assets/icons/Common/car-store.svg";
import { Link } from "react-router-dom";

function Product({ data, special = false }) {
  return (
    <Container special={special}>
      <div id="product-content-1">
        <img src={data && data.first.url} alt="" />
      </div>
      <div id="product-content-2">
        <div>
          <p id="product-text-1-content-2">{data && data.title}</p>
          <p id="category">Categoria: {data.category}</p>
          <p id="category">Ofertante: {data.company.name}</p>
          <section></section>
        </div>

        <div id="product-price">
          <p id="product-text-3-content-2">{data.value}</p>
          <p id="product-text-4-content-2">,00</p>
          <p id="product-text-5-content-2">Whuffies</p>
        </div>
      </div>
      <button id="product-content-3">
        <div className="content">
          <img id="car-icon" src={carStore} alt="" />
          <Link id="link-product" to={`product/${data.id}`}>
            <p>Quero este!</p>
          </Link>
        </div>
      </button>
    </Container>
  );
}

export default Product;
