import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";
import { Container } from "../../../../styles/global";

import imgSecond from "../../../../assets/images/About/second.svg";
import iconContact from "../../../../assets/icons/About/contact.svg";
import { useHistory } from "react-router";

function Third() {
  const history = useHistory();

  const toggle = () => {
    setTimeout(() => {
      history.push("/contact?&type=normal");
    }, 150);
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column larger>
            <ImgWrapper>
              <Img src={imgSecond} alt="" />
            </ImgWrapper>
          </Column>
          <Column>
            <img src={iconContact} alt="" />
            <h1>Quer ser nosso parceiro(a)?</h1>
            <p>
              Se você é um cientista, pesquisador, professor, se representa uma
              Instituição de Ensino e Pesquisa, como uma Universidade, se
              trabalha na imprensa, se é um influenciador digital, se representa
              uma empresa, ou se não é nada disso, mas assim como nós, acredita
              que pode fazer alguma coisa para apoiar o desenvolvimento da
              Ciência e a construção de um mundo melhor, então faça contato
              conosco clicando no botão a seguir:
            </p>
            <button id="button-more-about" onClick={() => toggle()}>
              Seu apoio é fundamental!
            </button>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Third;
