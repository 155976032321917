import React from "react";
import { useHistory } from "react-router";
import { Container } from "../../../../styles/global";
import { Wrapper, Row, Column, Projects, Circles } from "./styles";

import iconProjects from "../../../../assets/icons/Common/projects.svg";

import Project from "../../../../components/NewProject";
import useProjectsHome from "../../../../hooks/useProjectsHome";

function Second() {
  const history = useHistory();
  const { projects } = useProjectsHome();

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <img className="img-1" src={iconProjects} alt="" />
            <h1> Projetos e pesquisas </h1>
            <p id="science-2">
              Conheça os projetos e pesquisas científicas cadastrados em nossa
              plataforma, seja informado(a) quando um projeto na sua área de
              interesse for cadastrado, escolha e apoie aqueles que você mais
              gostar!
            </p>

            <Projects lessThree={projects.length < 3 ? true : false}>
              {projects.map((project) => (
                <Project key={project.id} data={project.project} />
              ))}
            </Projects>
            <button onClick={() => history.push("/projects")}>
              Veja todos os projetos
            </button>
            <Circles>
              <div id="first-circle-projects">
                <div id="second-circle-projects">
                  <div id="third-circle-projects"></div>
                </div>
              </div>
            </Circles>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Second;
