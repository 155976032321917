import styled, { keyframes } from 'styled-components';
import { darken } from 'polished'

const bussOut = keyframes `
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }
    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }
    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }
    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }         
`
export const Wrapper = styled.div `
    height: 100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family: GothamPro;
    @media only screen and (max-width:768px){
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const Content = styled.div `
    width:1366px;
    height: 100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    @media only screen and (max-width:768px){
        width:100vw;
    }
`

export const Header = styled.header `
    width:100%;
    height: 392px;

    background: rgb(41,118,250);
    background: linear-gradient(90deg, rgba(41,118,250,1) 22%, rgba(68,169,252,1) 71%, rgba(75,183,253,1) 95%);

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    .congratulations{
        align-self:flex-start;
        display:flex;
        flex-direction:column;
        align-items:center;

        margin-left:155px;
        margin-top:32px;

        .box{
            width: 116px;
            height: 116px;
            border-radius:50%;

            display:flex;
            align-items:center;
            justify-content:center;

            -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
            box-shadow: 0 0 7.5px 0 #45c6e5;
                    background: -webkit-gradient(linear, left top, right top, color-stop(32%, rgba(106,243,255,0.67)), color-stop(141%, rgba(138,238,197,1)));
                    background: -o-linear-gradient(left, rgba(106,243,255,0.67) 32%, rgba(138,238,197,0.67) 141%);
                    background: linear-gradient(90deg, rgba(106,243,255,0.67) 32%, rgba(138,238,197,0.67) 141%);

            .box-img{
                width: 100px;
                height: 100px;
                border-radius:50%;

                display:flex;
                align-items:center;
                justify-content:center;

                box-shadow: 0 0 15px 0 #45c6e5;
                background: linear-gradient(90deg, rgba(106,243,255,1) 98%, rgba(138,238,197,1) 100%);
                -webkit-box-shadow: 0 0 15px 0 #45c6e5;
                background: -webkit-gradient(linear, left top, right top, color-stop(32%, rgba(106,243,255,1)), color-stop(141%, rgba(138,238,197,1)));
                background: -o-linear-gradient(left, rgba(106,243,255,1) 32%, rgba(138,238,197,1) 141%);
            }
        }

        #text-congrat{
            margin-top:19px;

            width: 410px;
            font-size: 45px;
            font-weight: 500;
          
            line-height: 1.11;

            text-align: center;
            color: #ffffff;
            
        }
    }

    .circles{
        align-self:flex-end;
        #first-circle{
            display:-webkit-box;
            display:-ms-flexbox;
            display:flex;
            -webkit-box-align:center;
            -ms-flex-align:center;
            align-items:center;
            -webkit-box-pack:center;
            -ms-flex-pack:center;
            justify-content:center;

            position:absolute;
            overflow:visible;
            width: 684px;
            height: 684px;
            opacity: 0.05;

            border-radius:50%;
            background-color: #ffffff;

            margin-top:-380px;
            margin-left:-400px;

            z-index:-0.8;
        } 

        #second-circle{
            display:-webkit-box;
            display:-ms-flexbox;
            display:flex;
            -webkit-box-align:center;
            -ms-flex-align:center;
            align-items:center;
            -webkit-box-pack:center;
            -ms-flex-pack:center;
            justify-content:center;
            overflow:visible;
            position:absolute;

            width: 408px;
            height: 408px;
            opacity: 0.06;

            border-radius:50%;
            background-color: #ffffff;

            margin-top:-243px;
            margin-left:-295px;

            z-index:-0.8;
        }

        #third-circle{
            display:-webkit-box;
            display:-ms-flexbox;
            display:flex;
            -webkit-box-align:center;
            -ms-flex-align:center;
            align-items:center;
            -webkit-box-pack:center;
            -ms-flex-pack:center;
            justify-content:center;

            position:absolute;
            overflow:visible;
            width: 192px;
            height: 192px;
            opacity: 0.06;

            border-radius:50%;
            background-color: #ffffff;

            margin-top:-100px;
            margin-left:-175px;

            z-index:-0.8;
        }
    }   

    @media only screen and (max-width: 849px) {
        padding-top:10px;
    }

    @media only screen and (max-width:768px){
        width:100vw;
        margin:0;

        .circles{
            display:none;
        }

        .congratulations{
            width:100%;
            margin:0;

            #text-congrat{
                width:90%;
                font-size:2rem;
            }

            .box{
                width: 14vh;
                height: 14vh;

                .box-img{
                    width: 90%;
                    height: 90%;

                    img{
                        width: 75%;
                        height: 75%;
                    }
                }
            }
        }
    }
`

export const MainContent = styled.section `
    width: 100%;
    height:1866px;

    padding-left:102px;
    padding-right:92.4px;

    display:flex;
    justify-content:space-between;

    .content{
        width:100%;

        margin-top:61px;
        margin-right:37px;

        p{
            width: 682px;
            font-size: 18px;
            font-family:GothamProRegular;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
        }

        button{
            margin-top:25px;
            width: 200px;
            height: 70px;
            border-radius:15px  15px 0 15px;
            background-color: #227af1;
        
            transition: all .3s ease-in-out 0s;
            z-index:1;
            font-family: GothamPro;
            font-size: 15.5px;
            font-weight: bold;
            line-height: 1.5;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            &:hover{
                background-color: ${darken(0.1,'#227af1')};
            }
        }
    }
    .second-content{
        z-index:1;
        margin-top:-315.2px;
        display:flex;
        flex-direction:column;

        >img{
            margin-top:25px;
            margin-left:-30px;
            height: 360px;
        }

        .social-networks{
            margin-top:25.5px;
            margin-left:70.4px;

            display:flex;
            flex-direction:column;
            align-items:center;

            width: 352px;
            height:180px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
            background-color: #ffffff;

            #text-social-networks{
                font-size: 22px;
                font-weight: 500;
                line-height: 1.36;
                text-align: left;
                font-family:GothamProRegular;
                color: #227af1;

                margin-top:42px;
                margin-bottom:21px;
            }

            hr{
                width: 352px;
                height: 0;
           
                opacity: 0.08;
                border: solid 0.5px #707070;
                
            }

            .icons{
                width:278px;

                margin-top:34px;

                display:flex;
                flex-direction:row;
                align-items:center;
                justify-content:space-between;

                svg{
                    color:#227af1;
                    transition: all .3s ease-in-out 0s;

                    &:hover{
                        color:  ${darken(0.2, '#227af1')};
                        animation:${bussOut} 1.5s;
                    }
                }
            }

        }
    }

    @media only screen and (max-width:768px){
        width:100vw;

        height:2266px;

        display:flex;
        flex-direction:column;
        justify-content:unset;
        align-items:center;

        padding:0;

        .content{
            width:100%;
            margin:0;
            padding:0;

            margin-left:10%;
            margin-top:10%;
            p{
                width:90%;
                word-wrap:break-word;
                font-size:0.85rem;

                text-align:justify;

                margin:0;
            }
        }

        .second-content{
            width:100%;
            margin:0;
            padding:0;

            width:80%;
            height:10%;
            margin-top:20%;
            >img{
                display:none;
            }

            .social-networks{
                width:100%;
                margin:0;
                margin-left:-2.5vw;

                #text-social-networks{
                    font-size:0.9rem;
                    margin-top:20px;
                }

                hr{
                    width:100%;
                }

                .icons{

                    width:80%;
                    margin-top:15px;
                    margin-bottom:10px;
                }
            }
        }
    }
`