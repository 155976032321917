import React from "react";

// Pages
import SupporterProfile from "../SupporterProfile";
import CompanyProfile from "../CompanyProfile";
import ScientistProfile from "../ScientistProfile";

// Redux-Store
import { store } from "../../store";

function Profile() {
  const { perfil } = store.getState().auth;

  function Page() {
    if (perfil === "Apoiador/Visitante") {
      return <SupporterProfile />;
    } else if (perfil === "Apoiador Empresarial/Desafiante") {
      return <CompanyProfile />;
    } else if (perfil === "Cientista/Pesquisador") {
      return <ScientistProfile />;
    }
  }
  return Page();
}

export default Profile;
