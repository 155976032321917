import React, { useEffect, useState } from "react";
// Libraries
import { Bounce } from "react-reveal";
import { Scrollbars } from "react-custom-scrollbars";
// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Product from "../../components/Product";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  StoreContent,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  BtnFilter,
  ProductsContainer,
} from "./styles";

// Icons
import iconSubmit from "../../assets/icons/Store/icon-submit.svg";
import iconFilter from "../../assets/icons/Common/filter.svg";
import iconFilterDown from "../../assets/icons/Common/filter-down.svg";
import iconFilterUp from "../../assets/icons/Common/filter-up.svg";
import iconDrop from "../../assets/icons/Common/drop.svg";
import storeIcon from "../../assets/icons/Common/store-2.svg";

// Images
import imgHeader from "../../assets/images/Store/header-img.svg";
// import product1 from "../../assets/images/Common/product-1.png";
// import product2 from "../../assets/images/Common/product-2.png";
import api from "../../services/api";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";
import { store } from "../../store";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

function Store() {
  const optionsCategory = ["Produto", "Serviço", "Experiência"];

  const history = useHistory();
  const { perfil } = store.getState().auth;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCompany, setIsOpenCompany] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCompany, setSelectedOptionCompany] = useState(null);

  const toggling = () => setIsOpen(!isOpen);
  const togglingCompany = () => setIsOpenCompany(!isOpenCompany);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const onOptionClickedCompany = (value) => () => {
    setSelectedOptionCompany(value);
    setIsOpenCompany(false);
  };

  // ------------------------PAGINAÇÃO--------------------

  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(16);
  const [filters, setFilters] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [optionsCompany, setOptionsCompany] = useState("");

  useEffect(() => {
    const FetchCountries = async () => {
      const res = await api.get("companies");
      var arrAux = [];

      for (let index = 0; index < res.data.length; index++) {
        arrAux.push(res.data[index].name);
      }
      setOptionsCompany(arrAux);
    };
    FetchCountries();
  }, []);

  useEffect(() => {
    const FetchCountries = async () => {
      const res = await api.get("products");
      setCountries(res.data);
      setFilters(res.data);
    };
    FetchCountries();
  }, []); // [] avoid useEffect() to run and make an never ended loop at each updates

  //get the currents countries to display in page
  const indexLastCountries = currentPage * countriesPerPage;
  const indexFirstCountries = indexLastCountries - countriesPerPage;
  const currentCountries = countries.slice(
    indexFirstCountries,
    indexLastCountries
  );

  const paginate = (number) => setCurrentPage(number);

  function handleFilter() {
    setCountries(filters);
    //Nome de produto
    if (
      searchName !== "" &&
      selectedOption === null &&
      selectedOptionCompany === null
    ) {
      if (
        countries.filter((countrie) => countrie.title.includes(searchName))
          .length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter((countrie) => countrie.title.includes(searchName))
        );
        setSearchName("");
      }
    }
    //categoria
    else if (
      searchName === "" &&
      selectedOption &&
      selectedOptionCompany === null
    ) {
      if (
        selectedOption &&
        countries.filter(
          (countrie) => countrie.category === `${selectedOption}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) => countrie.category === `${selectedOption}`
          )
        );
        setSelectedOption(null);
      }
    }
    //Empresa
    else if (
      searchName === "" &&
      selectedOption === null &&
      selectedOptionCompany !== null
    ) {
      if (
        countries.filter(
          (countrie) => countrie.company.name === selectedOptionCompany
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSelectedOptionCompany(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) => countrie.company.name === selectedOptionCompany
          )
        );
        setSelectedOptionCompany(null);
      }
    }
    //Nome e Categoria
    else if (
      searchName !== "" &&
      selectedOption !== null &&
      selectedOptionCompany === null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.title.includes(searchName) &&
            countrie.category === `${selectedOption}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.title.includes(searchName) &&
              countrie.category === `${selectedOption}`
          )
        );
        setSearchName("");
        setSelectedOption(null);
      }
    }
    //Nome e Company
    else if (
      searchName !== "" &&
      selectedOption === null &&
      selectedOptionCompany !== null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.title.includes(searchName) &&
            countrie.company.name === selectedOptionCompany
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.title.includes(searchName) &&
              countrie.company.name === selectedOptionCompany
          )
        );
        setSearchName("");
        setSelectedOptionCompany(null);
      }
    }
    //Categoria e Company
    else if (
      searchName === "" &&
      selectedOption !== null &&
      selectedOptionCompany !== null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.category === `${selectedOption}` &&
            countrie.company.name === selectedOptionCompany
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.category === `${selectedOption}` &&
              countrie.company.name === selectedOptionCompany
          )
        );
        setSearchName("");
        setSelectedOptionCompany(null);
      }
    }
    //Todos
    else if (
      searchName !== "" &&
      selectedOption !== null &&
      selectedOptionCompany !== null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.title.includes(searchName) &&
            countrie.category === `${selectedOption}` &&
            countrie.company.name === selectedOptionCompany
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.title.includes(searchName) &&
              countrie.category === `${selectedOption}` &&
              countrie.company.name === selectedOptionCompany
          )
        );
        setSearchName("");
        setSelectedOptionCompany(null);
      }
    }
  }

  function handleTeste() {
    if (filterOne || filterTwo || filterThree) {
      toast.error("Outro filtro já esta sendo utilizado");
    } else {
      if (
        selectedOption !== null ||
        searchName !== "" ||
        selectedOptionCompany !== null
      ) {
        handleFilter();
      } else {
        toast.error("Nenhuma opção selecionada!");
      }
    }
  }

  const [filterOne, setFilterOne] = useState(false);
  const [filterTwo, setFilterTwo] = useState(false);
  const [filterThree, setFilterThree] = useState(false);

  const toogleFilterOne = () => {
    //verifica se outros filtros já estão sendo aplicados
    if (
      selectedOption !== null ||
      searchName !== "" ||
      selectedOptionCompany !== null
    ) {
      toast.error("Outro filtro já esta sendo utilizado");
    } else {
      if (filterOne === false && !filterTwo && !filterThree) {
        var total = 0;
        //adiciona o total de valores dos produtos
        countries.map((item) => (total += parseFloat(item.value)));
        //calcula a média
        const average = parseFloat(total / countries.length);

        if (
          countries.filter((item) => parseFloat(item.value) <= average)
            .length === 0
        ) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterOne(true);
          setCountries(
            countries.filter((item) => parseFloat(item.value) <= average)
          );
        }
      } else if (filterOne) {
        setFilterOne(false);
        setCountries(filters);
      }
    }
  };

  const toogleFilterTwo = () => {
    if (searchName !== "" || selectedOption || selectedOptionCompany) {
      toast.error("Outros filtros estão sendo utilizados!");
    } else {
      if (filterTwo === false && !filterOne && !filterThree) {
        var total = 0;
        countries.map((item) => (total += parseFloat(item.value)));
        const average = parseFloat(total / countries.length);
        if (
          countries.filter((item) => parseFloat(item.value) >= average)
            .length === 0
        ) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterTwo(true);
          setCountries(
            countries.filter((item) => parseFloat(item.value) >= average)
          );
        }
      } else if (filterTwo) {
        setFilterTwo(false);
        setCountries(filters);
      }
    }
  };

  const toogleFilterThree = () => {
    if (searchName !== "" || selectedOption || selectedOptionCompany) {
      toast.error("Outros filtros estão sendo utilizados!");
    } else {
      if (filterThree === false && !filterOne && !filterTwo) {
        if (
          countries.filter(
            (item) =>
              item.current_quantity <= parseInt(item.available_units / 2)
          ).length === 0
        ) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterThree(true);
          setCountries(
            countries.filter(
              (item) =>
                item.current_quantity <= parseInt(item.available_units / 2)
            )
          );
        }
      } else if (filterThree) {
        setFilterThree(false);
        setCountries(filters);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Mercado Whuffie</title>
      </Helmet>
      <Bounce top duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section id="second-content-header">
                <img src={storeIcon} alt="" />
                <p id="text-1">MERCADO WHUFFIE</p>
                <p id="text-2">
                  Aqui você troca suas moedas Whuffie por produtos, serviços e
                  experiências reais!
                </p>
                <div className="buttons">
                  <p>Buscar no Mercado:</p>
                  <input
                    placeholder="Buscar por Nome"
                    onChange={(event) => {
                      setSearchName(event.target.value);
                    }}
                  ></input>
                  <DropDownContainer>
                    <DropDownHeader onClick={toggling}>
                      <p>{selectedOption || "Categoria"}</p>
                      {isOpen ? (
                        <img
                          className="icon-drop"
                          id="up-icon"
                          src={iconDrop}
                          alt=""
                        />
                      ) : (
                        <img className="icon-drop" src={iconDrop} alt="" />
                      )}
                    </DropDownHeader>
                    {isOpen && (
                      <DropDownListContainer>
                        <DropDownList>
                          <Scrollbars style={{ width: 240, height: 45 }}>
                            {optionsCategory.map((option) => (
                              <ListItem
                                onClick={onOptionClicked(option)}
                                key={Math.random()}
                              >
                                {option}
                              </ListItem>
                            ))}
                          </Scrollbars>
                        </DropDownList>
                      </DropDownListContainer>
                    )}
                  </DropDownContainer>
                  <DropDownContainer>
                    <DropDownHeader onClick={togglingCompany}>
                      <p>{selectedOptionCompany || "Empresa"}</p>
                      {isOpenCompany ? (
                        <img
                          className="icon-drop"
                          id="up-icon"
                          src={iconDrop}
                          alt=""
                        />
                      ) : (
                        <img className="icon-drop" src={iconDrop} alt="" />
                      )}
                    </DropDownHeader>
                    {isOpenCompany && (
                      <DropDownListContainer>
                        <DropDownList>
                          <Scrollbars style={{ width: 240, height: 45 }}>
                            {optionsCompany.map((option) => (
                              <ListItem
                                onClick={onOptionClickedCompany(option)}
                                key={Math.random()}
                              >
                                {option}
                              </ListItem>
                            ))}
                          </Scrollbars>
                        </DropDownList>
                      </DropDownListContainer>
                    )}
                  </DropDownContainer>
                  <button className="button-submit" onClick={handleTeste}>
                    <img src={iconSubmit} alt="" />
                  </button>
                </div>

                <img id="img-header" src={imgHeader} alt="" />
              </section>
            </Header>
            <StoreContent>
              <div className="filter">
                <img id="first-filter-icon" src={iconFilter} alt="" />
                <p id="first-filter-text">Filtro de produtos por:</p>

                <BtnFilter active={filterOne} onClick={toogleFilterOne}>
                  <img
                    className="second-filter-icon"
                    src={iconFilterDown}
                    alt=""
                  />
                  <p className="second-filter-text">Menor preço</p>
                </BtnFilter>
                <BtnFilter active={filterTwo} onClick={toogleFilterTwo}>
                  <img
                    className="second-filter-icon"
                    src={iconFilterUp}
                    alt=""
                  />
                  <p className="second-filter-text">Maior preço</p>
                </BtnFilter>
                <BtnFilter
                  active={filterThree}
                  id="third"
                  onClick={toogleFilterThree}
                >
                  <img
                    className="second-filter-icon"
                    src={iconFilterUp}
                    alt=""
                  />
                  <p className="second-filter-text">Mais comprado</p>
                </BtnFilter>
              </div>

              <section id="filter-section"></section>

              {perfil && perfil === "Apoiador Empresarial/Desafiante" && (
                <div className="box-for-scientists">
                  <p>Cadastre Seus Produtos, Serviços ou Experiências!</p>
                  <button
                    type="button"
                    onClick={() => history.push("profile?register=product")}
                  >
                    Clique Aqui!
                  </button>
                </div>
              )}

              <ProductsContainer>
                {currentCountries.map((countries, number) => (
                  <ul className="country" key={number}>
                    <Product data={countries} />
                  </ul>
                ))}
              </ProductsContainer>

              {countries.length !== 0 && (
                <Pagination
                  style={{ display: "none" }}
                  currentPage={currentPage}
                  countriesPerPage={countriesPerPage}
                  totalCountries={countries.length}
                  paginate={paginate}
                />
              )}
            </StoreContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Store;
