import styled, { css, keyframes } from "styled-components";
import { darken } from "polished";

const primaryColor = "#227af1";

const textFocus = keyframes`
    0% {
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
    }
`;

const shake = keyframes`

    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
    
`;

export const Wrapper = styled.div`
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 211px;

  background: ${primaryColor};

  .content-header {
    width: 100%;
    height: 137px;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-content {
      height: 37.2px;
      display: flex;
      align-items: center;

      margin-bottom: 35px;
      margin-left: 507px;

      #title {
        margin-top: 9px;
        margin-left: 17.2px;

        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;

        text-transform: uppercase;
      }
    }

    #img-header {
      margin-top: -50px;
      margin-left: 157px;
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .content-header {
      width: 100%;
      .title-content {
        margin-left: 0;
        #title {
          font-size: 1.5rem;
        }

        #icon-title {
          width: 36.4px;
          height: 32.8px;
        }
      }

      #img-header {
        display: none;
        margin-left: 0;
      }
    }
  }
`;

export const BoxProfile = styled.div`
  width: 261px;
  height: 91px;
  background: #add948;
  border-radius: 10px;

  display: flex;
  align-items: center;

  padding: 17px;
  margin-bottom: 10.92px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 21.2px;

    p {
      line-height: 1.5;
      text-align: left;
      color: #ffffff;
      font-size: 16px;
    }

    #normal {
      font-weight: 500;
      font-family: GothamProRegular;
    }

    #title {
      font-family: GothamPro;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    p {
      font-size: 0.9rem;
      text-align: center;
    }
  }
`;

export const ProfileContent = styled.div`
  width: 100%;
  display: flex;

  margin-left: 139px;
  margin-top: 52px;

  position: relative;
  z-index:0;

  aside {
    width: 261px;
    margin-right: 107px;

    display: flex;
    flex-direction: column;
    margin-top: 32.5px;

    #perfil {
      margin-left: 50px;
      margin-bottom: 40px;

      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
      color: ${primaryColor};
    }

    .avatar {
      height: 290px;
      border-radius: 10px;
      background-color: ${primaryColor};
      padding-top: 24.4px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      z-index: 5;

      .avatar-img {
        width: 126.2px;
        height: 126.2px;

        margin-bottom: 15px;

        .border {
          width: 100%;
          height: 100%;
          border-radius: 50%;

          border: solid 2px #ffffff;

          display: flex;
          align-items: center;
          justify-content: center;

          .img-content {
            width: 101.2px;
            height: 101.2px;
            border-radius: 50%;
            background-color: ${darken(0.1, `${primaryColor}`)};

            display: flex;
            align-items: center;
            justify-content: center;

            #img-profile {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            #without-img-profile {
              width: 50%;
              height: 50%;
            }
          }
        }
        .pencil {
          width: 43px;
          height: 42px;
          border-radius: 50%;
          background-color: #5cf3ff;

          display: block;
          position: absolute;
          z-index: 1;

          margin-top: -123px;
          margin-left: 85px;

          padding-left: 12.2px;
          padding-top: 10.6px;
        }
      }

      hr {
        width: 100%;
        height: 1px;
        background-color: ${darken(0.1, `${primaryColor}`)};

        margin-bottom: 20.6px;
      }

      .infos {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin-bottom: 7px;

          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
        }
      }
    }

    .menu-profile {
      width: 100%;
      height: 384px;

      margin-top: -8px;

      border-radius: 0px 0px 10px 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.05);
      background-color: #fff;

      padding-top: 18px;

      .menu {
        display: flex;
        flex-direction: column;
      }
    }

    #btn-company-profile {
      width: 261px;
      height: 60px;
      border-radius: 30px;
      background-color: ${primaryColor};

      margin-top: 18px;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    margin-left: 0;
    flex-direction: column;

    align-items: center;

    aside {
      width: 90%;
      margin-right: 0;

      #perfil {
        margin-left: 0;
        text-align: center;
      }

      .avatar {
        .avatar-img {
          .border {
          }
          .pencil {
            width: 43px;
            height: 42px;
            border-radius: 50%;
            background-color: #5cf3ff;

            display: inline-block;
            position: unset;
            z-index: 20;

            padding-left: 12.2px;
            padding-top: 10.6px;
          }
        }
      }
      hr {
        margin-top: 20px;
        margin-bottom: 0px;
      }
      #btn-company-profile {
        width: 100%;
      }
    }
  }
`;

export const MenuList = styled.button`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.21;
  letter-spacing: normal;
  text-align: left;
  color: #7f7fb9;
  background: transparent;

  margin-left: 25px;
  margin-bottom: 2px;

  ${(props) =>
    props.active &&
    css`
      color: ${primaryColor};
    `}

  @media only screen and (max-width:768px) {
    font-size: 0.8rem !important;
    margin-left: 5%;
  }
`;

export const EditComponent = styled.main`
  width: 720px;
  height: 3005.6px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .form {
    margin-top: 35.6px;
    form {
      #title {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }

      .avatar {
        .pencil {
          width: 50px;
          height: 50px;

          border-radius: 50%;

          padding-left: 14.8px;
          padding-top: 15.2px;

          background-color: ${primaryColor};

          display: block;
          position: absolute;

          margin-top: -55px;
          margin-left: 95px;
        }

        #input-photo {
          margin-top: 17px;

          font-family: GothamProRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
        }
      }

      .box-goal {
        height: 110px;
        width: 720px;

        border-radius: 30px;
        box-shadow: 0 0 2.5px 0 rgba(49, 22, 159, 0.12);

        border: 2px dashed #eee;
        padding: 25px;

        display: flex;
        align-items: center;

        word-wrap: break-word;

        margin-top: 20px;
        margin-bottom: 10px;

        #attention {
          font-size: 16px;
          font-weight: 500;
          margin-right: 10px;
          text-align: left;
          color: #227af1;
        }

        #text {
          font-family: GothamProRegular;
          font-size: 14px;
          line-height: 1.71;
          text-align: left;
          color: #000;
        }
      }

      .box-obs-social {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        p {
          line-height: 1.73;
          color: #7f7fb9;
          font-size: 0.9rem;
          text-align: start;
        }
      }

      .inputs {
        display: flex;
        flex-wrap: wrap;
        margin-right: -16px;
        margin-top: 41px;
        margin-bottom: 15px;

        input {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.65px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;

          padding-left: 33.6px;

          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          &::-moz-placeholder {
            opacity: 1;
          }

          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }

        .box-security-datas {
          width: 100%;
          height: 130px;
          border-radius: 30px;

          border: dashed 2.5px #eee;

          display: flex;
          align-items: center;

          margin-bottom: 15px;

          p {
            font-size: 14px;

            line-height: 1.71;
            font-family: GothamProRegular;
            text-align: center;
            color: #000;

            strong {
              color: #227af1;
            }
          }
        }

        .id {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          opacity: 0.45;
          border: solid 1.4px #7f7ebe;
          background: transparent;

          display: flex;
          align-items: center;
          padding-left: 26.6px;

          p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: #7f7fb9;
          }
        }
      }
      .about-company {
        margin-top: 35.6px;
        p {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-bottom: 30px;
        }

        .textarea {
          width: 715px;
          height: 179px;

          border-radius: 15px;
          border: solid 1px #7f7ebe;

          outline: none;
          resize: none;
          overflow: hidden;

          padding-top: 19px;
          padding-left: 33.6px;

          margin-bottom: 19px;

          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #7f7fb9;

          &::placeholder {
            clear: left;
            flex-wrap: wrap;
            color: #7f7fb9;
          }

          &::-moz-placeholder {
            opacity: 1;
          }
        }
      }

      hr {
        background: #eee;
        height: 2px;
        width: 100%;
      }

      .adress {
        margin-top: 72px;
        margin-bottom: 13.5px;

        p {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-bottom: 30px;
        }

        .inputs-adress {
          display: flex;
          flex-wrap: wrap;
          margin-right: -16px;

          input {
            width: 352px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.65px #7f7ebe;
            margin-right: 16px;
            margin-bottom: 20px;

            padding-left: 33.6px;

            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;

            &::-moz-placeholder {
              opacity: 1;
            }

            &::placeholder {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;

              letter-spacing: normal;
              text-align: left;
              color: #7f7fb9;
            }
          }

          .estado {
            width: 168px;
          }

          .city,
          .CEP {
            width: 260px;
          }
        }
      }

      .security {
        margin-top: 72px;
        margin-bottom: 13.5px;

        p {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-bottom: 30px;
        }

        .inputs-security {
          display: flex;

          input {
            width: 352px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.65px #7f7ebe;
            margin-right: 16px;
            margin-bottom: 20px;

            padding-left: 33.6px;

            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;

            &::-moz-placeholder {
              opacity: 1;
            }

            &::placeholder {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;

              letter-spacing: normal;
              text-align: left;
              color: #7f7fb9;
            }
          }
        }
      }
      button {
        float: right;

        width: 352px;
        height: 60px;
        border-radius: 30px;
        background-color: ${primaryColor};

        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;

        margin-right: 16px;
        &:hover {
          background-color: ${darken(0.1, `${primaryColor}`)};
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 4150px;

    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    input {
      width: 100% !important;
    }

    .curriculum {
      width: 95% !important;
    }

    .textarea {
      width: 95% !important;
    }

    .form {
      form {
        #title {
        }

        .inputs {
          .id,
          .box-security-datas {
            width: 95% !important;
          }

          .box-goal {
            width: 95%;
            height: 260px;
            flex-direction: column;

            #text {
              font-size: 0.75rem;
            }
          }
        }
        .security {
          width: 100%;

          .inputs-security {
            flex-direction: column;
          }
        }

        .avatar {
          .pencil {
            display: none;
          }
        }

        button {
          width: 100% !important;
          float: none !important;
          margin: 0;
        }
      }
    }
  }
`;

export const DonationsComponent = styled.main`
  width: 720px;
  height: 1964px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .buttons {
    width: 100%;
    align-self: center;

    display: flex;
    align-items: center;

    margin: 15px 0 20px 0;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    margin-right: -16px;
  }

  .pagination {
    margin-top: 42px;
    margin-right: -6px;

    width: 249px;
    height: 45px;

    display: flex;

    float: right;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;

    height: 2964px;
    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    #content-cosmos {
      margin-bottom: 100px;
    }

    .text {
      text-align: justify;
    }
  }
`;
export const Cosmos = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 16px;

  z-index: 1;

  width: 393.8px;
  height: 272.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background-color: ${primaryColor};

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin-top: 10px;
    }
  }
  #title-cosmos {
    text-align: center;
    color: ${primaryColor};

    font-size: 20px;
    margin-top: 20px;
  }
  .content-2 {
    width: 100%;
    height: 273.8px;
    .donation {
      margin-top: 10px;

      p {
        margin-left: 33px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }

      #value {
        font-size: 20px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;

    .content-1 {
      img {
        width: 80%;
      }
    }
  }
`;

export const ButtonDonation = styled.button`
  width: 352px;
  height: 60px;
  border-radius: 30px;

  ${(props) =>
    !props.active &&
    css`
      background: transparent;
      border: 2px solid ${primaryColor};
      color: ${primaryColor};

      &:hover {
        background-color: ${darken(0.1, "#fff")};
      }
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${primaryColor};
      border: none;
      color: #fff;

      &:hover {
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    `}

    font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;

  transition: all 0.3s ease-in-out 0s;

  margin-right: 30px;
  @media only screen and (max-width: 768px) {
    margin-right: 3vw;
  }
`;

export const ButtonStatus = styled.button`
  width: 220px;
  height: 50px;
  border-radius: 30px;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #e71d36;

  &:hover {
    background-color: ${darken(0.1, "#e71d36")};
  }

  ${(props) =>
    props.delivered &&
    css`
      background-color: #06d6a0;

      &:hover {
        background-color: ${darken(0.1, "#06d6a0")};
      }
    `}

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;

  transition: all 0.3s ease-in-out 0s;

  margin-right: 30px;
  @media only screen and (max-width: 768px) {
    margin-right: 3vw;
    width: 40vw;
  }
`;

export const ChampComponent = styled.main`
  width: 720px;
  padding-bottom: 110px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }
  #sub-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 2.25;
    text-align: left;
    color: ${primaryColor};
  }

  .be-champion {
    margin-top: 43px;

    #title-be-champ {
      font-size: 20px;
      font-weight: 500;
      line-height: 2.25;
      text-align: left;
      color: ${primaryColor};
    }

    #text-be-champ {
      font-size: 14px;
      font-family: GothamProRegular;
      line-height: 2;
      text-align: justify;
      color: #000000;

      width: 100%;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }

  .participants-content {
    margin-top: 54.6px;
    margin-bottom: 84.6px;

    #title-participants-content {
      font-size: 30px;
      font-weight: 500;
      text-align: left;
      color: #7f7fb9;
    }

    hr {
      width: 710px;
      height: 0;
      opacity: 0.24;
      border: solid 1px #707070;
    }
    .participants {
      margin-top: 28px;

      .item-participant {
        margin-bottom: 70px;

        #participant {
          width: 710px;
          height: 391px;
          padding: 19px 50px 25px 18px;
          border-radius: 10px;
          box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
          background-color: #ffffff;

          display: flex;

          .icon {
            width: 70px;
            height: 70px;
            border: solid 2px rgba(127, 126, 190, 0.31);
            border-radius: 50%;

            display: -webkit-box;

            display: -ms-flexbox;

            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            margin-right: 10px;

            #background-img {
              width: 50px;
              height: 50px;
              border-radius: 50%;

              background-color: rgba(127, 127, 185, 0.31);

              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
            }
          }

          .content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-left: 5px;

            #day {
              font-size: 17px;
              font-weight: 500;
              text-align: left;
              color: #7f7fb9;
              margin-top: 20px;
              margin-bottom: 8px;
            }

            #name {
              font-size: 14px;
              font-weight: 500;
              text-align: left;
              color: ${primaryColor};
            }

            .second-content {
              display: flex;

              margin-top: 11px;
              margin-bottom: 18px;

              p {
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                color: ${primaryColor};

                margin-right: 60px;
              }
            }
            hr {
              width: 553px;
              height: 0;
              opacity: 0.24;
              border: solid 1px #707070;
            }

            #message-champ {
              max-height: 162px;
              margin: 28px 0 28px 0;

              font-family: GothamProRegular;
              font-size: 14px;
              line-height: 2;
              text-align: left;
              color: #000000;
            }

            .third-content {
              display: flex;
              flex-direction: column;

              margin-top: 30px;

              a {
                margin-top: 8px;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: ${primaryColor};
              }
            }
          }
        }
      }

      .floatPage {
        float: right;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1rem;
    }
    #sub-title {
      font-size: 0.85rem;
    }

    .be-champion {
      #title-be-champ {
        font-size: 0.85rem;
      }

      #text-be-champ {
        font-size: 0.85rem;
      }
    }
    .participants-content {
      width: 100;
      #title-participants-content {
        font-size: 1rem;
      }

      hr {
        width: 90%;
      }
      .participants {
        .item-participant {
          display: flex;
          flex-direction: column;
          align-items: center;

          #participant {
            width: 100%;
            height: 491px;
            flex-direction: column;

            p {
              font-size: 0.7rem !important;
              word-wrap: break-word;
            }

            .content {
              width: 100%;

              hr {
                width: 100%;
              }

              .second-content {
                width: 90%;
                p {
                  font-size: 0.7rem !important;
                  word-wrap: break-word;
                  word-break: break-all;
                  margin-right: 10px;
                }
              }

              .third-content {
                width: 90%;
                a {
                  width: 80%;
                  word-wrap: break-word;
                  word-break: break-all;
                  font-size: 0.7rem !important;
                }
              }
            }
          }
        }
        .floatPage {
          float: none;
        }
      }
    }
  }
`;

export const ButtonChamp = styled.button`
  width: 261px;
  height: 60px;
  border-radius: 30px;
  background-color: ${primaryColor};

  ${(props) =>
    props.champ &&
    css`
      background-color: ${darken(0.15, `${primaryColor}`)};
    `}

  margin-top:20px;
  margin-bottom: 23px;
  margin-left: 20px;

  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  text-align: left;
  color: #ffffff;

  transition: all 0.3s ease-in-out 0s;

  float: right;

  padding: 0 21px 0 26px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    height: 30px;
  }

  &:hover {
    background-color: ${darken(0.1, `${primaryColor}`)};
  }

  @media only screen and (max-width: 768px) {
    width: 80%;

    font-size: 0.85rem;

    img {
      height: 20px;
    }
  }
`;

export const ChallengesComponent = styled.main`
  width: 720px;
  height: 2050px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .new-challenge {
    width: 100%;
    height: 101px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #227af1;

    display: flex;
    align-items: center;
    justify-content: space-around;

    margin-top: 13.2px;
    margin-bottom: 44px;

    .main-content {
      display: flex;
      flex-direction: column;

      #title-content {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;

        margin-bottom: 22px;
      }

      .texts {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          margin-right: 8px;
        }

        #simple-text {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }

        #value-text {
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }
      }
    }

    #btn-register {
      width: 124px;
      height: 45px;
      border-radius: 30px;
      background-color: #5cf3ff;

      transition: all 0.3s ease-in-out 0s;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      &:hover {
        background-color: ${darken(0.1, `#5CF3FF`)};
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    margin-left: -14px;

    .challenge {
      .edit {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 42px;

        margin-bottom: 12px;

        .pencil {
          width: 43px;
          height: 42px;
          background-color: ${primaryColor};

          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            background-color: ${darken(0.1, `${primaryColor}`)};
          }
        }

        #text-edit {
          background: transparent;

          margin-left: 13.8px;

          font-size: 14px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 3.21;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            color: ${darken(0.1, `${primaryColor}`)};
          }
        }
      }

      .participants {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 42px;

        margin-bottom: 82px;

        margin-left: 10px;

        .pencil {
          width: 43px;
          height: 42px;
          background-color: ${primaryColor};

          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            background-color: ${darken(0.1, `${primaryColor}`)};
          }

          img {
            height: 22.6px;
          }
        }

        #text-edit {
          background: transparent;

          margin-left: 13.8px;

          font-size: 14px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 3.21;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            color: ${darken(0.1, `${primaryColor}`)};
          }
        }
      }
    }
  }

  hr {
    width: 720px;
    height: 1;
    opacity: 0.24;
    border: solid 1px #707070;
  }

  .pagination {
    margin-top: 42px;
    margin-right: -6px;

    width: 249px;
    height: 45px;

    display: flex;

    float: right;
  }

  .pagination-projects {
    display: flex;
    align-items: center;

    float: right;

    margin-top: 40px;

    #btn-gallery {
      width: 48px;
      height: 48px;
      background-color: #fff;
      border-radius: 50%;

      transition: all 0.3s ease-in-out 0s;

      margin-left: 10px;

      cursor: pointer;
      &:hover {
        background-color: ${darken(0.1, "#FFF")};
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    height: 3844px;

    #title {
      font-size: 1.3rem;
    }

    .text {
      font-size: 0.85rem;
      text-align: justify;
    }
    .new-challenge {
      height: 150px;
      flex-direction: column;
      .main-content {
        #title-content {
          font-size: 0.85rem;
        }

        .texts {
          p {
            font-size: 0.75rem !important;
          }
        }
      }
    }
  }
`;

export const CollaborationsComponent = styled.main`
  width: 720px;
  height: 2510px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    margin-right: -16px;
  }

  .pagination {
    margin-top: 42px;
    margin-right: -6px;

    width: 249px;
    height: 45px;

    display: flex;

    float: right;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;
    height: 3510px;
    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }
  }
`;

export const HistoricComponent = styled.main`
  width: 720px;
  height: 1629px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .content {
    .transactions {
      width: 100%;
      height: 140px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      margin-bottom: 19px;

      padding-left: 35px;
      padding-top: 27px;

      .title-transaction {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 12px;
        #text-title-transaction {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #2976fa;
        }

        .data {
          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 30px;

          #icon-calendar {
            width: 14px;
            height: 13.6px;
            margin-right: 8px;
          }

          #day {
            margin-top: 2px;

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }
      }

      .value {
        display: flex;
        align-items: flex-end;

        #big-letter {
          font-size: 28px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.86;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};
        }

        .small-letter {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};

          margin-left: 3px;
        }
      }
    }
  }

  .pagination {
    margin-top: 42px;
    margin-right: -6px;

    width: 249px;
    height: 45px;

    display: flex;

    float: right;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }
    .content {
      .transactions {
        p {
          font-size: 0.75rem !important;
        }
      }
    }
  }
`;

export const ShoppingComponent = styled.main`
  width: 720px;
  height: 1898px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    margin-right: -16px;

    .product {
      width: 260;
      display: flex;
      flex-direction: column;

      .company-infos {
        cursor: pointer;

        align-self: center;

        width: 220px;
        height: 60px;
        border-radius: 30px;
        background-color: #227af1;

        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.3s ease-in-out 0s;

        img {
          height: 40px;
          margin-right: 20px;
        }

        margin-right: 16px;

        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  .pagination {
    margin-top: 42px;
    margin-right: -6px;

    width: 249px;
    height: 45px;

    display: flex;

    float: right;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 2998px;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }

    .content {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;

      margin-right: 0;

      .product {
        width: 100%;
        margin-bottom: 5vh;
        .company-infos {
          width: 70%;

          img {
            width: 25%;
          }
        }
      }
    }
  }
`;

export const MyProductsComponent = styled.main`
  width: 720px;
  height: 1939px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .content {
    .new-product {
      width: 720px;
      height: 80px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #227af1;

      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 17.6px;
      margin-bottom: 34px;

      padding-left: 35px;
      padding-right: 14px;

      #text-new-product {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 4.38;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }

      #btn-new-product {
        width: 124px;
        height: 45px;
        border-radius: 30px;
        background-color: #5cf3ff;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.1, `#5CF3FF`)};
        }
      }
    }

    .products {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin-right: -16px;

      .product {
        .edit {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 42px;

          margin-bottom: 82px;

          .pencil {
            width: 43px;
            height: 42px;
            background-color: ${primaryColor};

            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              background-color: ${darken(0.1, `${primaryColor}`)};
            }
          }

          #text-edit {
            background: transparent;

            margin-left: 13.8px;

            font-size: 14px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 3.21;
            letter-spacing: normal;
            text-align: left;
            color: ${primaryColor};

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, `${primaryColor}`)};
            }
          }
        }
      }
    }

    hr {
      width: 720px;
      height: 1;
      opacity: 0.24;
      border: solid 1px #707070;
    }

    .pagination-projects {
      display: flex;
      align-items: center;

      float: right;

      margin-top: 40px;

      #btn-gallery {
        width: 48px;
        height: 48px;
        background-color: #fff;
        border-radius: 50%;

        transition: all 0.3s ease-in-out 0s;

        margin-left: 10px;

        cursor: pointer;
        &:hover {
          background-color: ${darken(0.1, "#FFF")};
        }
      }
    }

    .pagination {
      margin-top: 42px;
      margin-right: -6px;

      width: 249px;
      height: 45px;

      display: flex;

      float: right;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    height: 3739px;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }

    .content {
      .new-product {
        width: 100%;
        height: 160px;
        flex-direction: column;
        justify-content: unset;

        padding: 0;

        #text-new-product {
          font-size: 0.7rem;
        }
      }
    }
    .floatPage {
      float: none;
    }
  }
`;

export const NewProductComponent = styled.main`
  width: 720px;
  height: 1579px;

  #title_component {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }
  .content {
    width: 100%;
    form {
      width: 100%;
      .inputs {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        margin-top: 41px;
        margin-bottom: 15px;

        #number-chars {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-bottom: 10px;
          margin-left: 10px;
        }

        input {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.65px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;

          padding-left: 33.6px;

          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          &::-moz-placeholder {
            opacity: 1;
          }

          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }

        .full {
          width: 100%;
        }
      }

      .input-imgs {
        width: 352px;
        height: 60px;
        padding: 1px 0 1px 27.6px;
        border-radius: 30px;
        border: solid 1.65px #7f7ebe;

        margin-left: 16px;
        margin-bottom: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        #placeholder {
          font-family: GothamProRegular;
          font-size: 14px;
          line-height: 1.71;
          text-align: left;
          color: #7f7fb9;
        }
      }

      .previews {
        display: flex;

        .preview {
          height: 97px;
          width: 76px;

          margin-right: 16px;

          display: flex;
          flex-direction: column;
          align-items: center;

          #teste {
            width: 76px;
            height: 65px;
            border-radius: 10px;
          }

          button {
            width: 40px;

            background: transparent;

            font-size: 14px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: ${primaryColor};

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, `${primaryColor}`)};
            }
          }
        }
      }

      .textarea {
        width: 715px;
        height: 179px;

        border-radius: 15px;
        border: solid 1px #7f7ebe;

        outline: none;
        resize: none;
        overflow: hidden;

        padding-top: 19px;
        padding-left: 33.6px;

        margin-bottom: 19px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #7f7fb9;

        &::placeholder {
          clear: left;
          flex-wrap: wrap;
          color: #7f7fb9;
        }

        &::-moz-placeholder {
          opacity: 1;
        }
      }

      #btn-register {
        width: 168px;
        height: 45px;
        border-radius: 30px;
        background-color: ${primaryColor};

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.1, `${primaryColor}`)};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    height: 2079px;

    #title_component {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
      font-size: 0.85rem;
    }

    .content {
      form {
        .inputs {
          flex-wrap: nowrap;
          margin-right: 0;
          flex-direction: column !important;

          input {
            width: 100%;
            margin-right: 0;
          }

          .value-input {
            margin-left: 0;
          }

          .input-imgs {
            width: 100%;
            margin-top: 15px !important;
            margin-left: 0;
          }

          #btn-category {
            float: none !important;

            width: 85% !important;
          }

          .box-goal {
            width: 100%;
            height: 160px;
            flex-direction: column;
            padding: 10px;

            #attention {
              font-size: 1rem;
            }

            #text {
              font-size: 0.7rem;
            }
          }
        }

        .textarea {
          width: 100% !important;
        }
      }
    }
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.small &&
    css`
      width: 260px !important;
    `}

  ${(props) =>
    !props.active &&
    css`
      width: 352px;
    `}


    ${(props) =>
    props.active &&
    css`
      width: 720px;
    `}

    ${(props) =>
    props.margin &&
    css`
      margin-right: 16px;
    `}

    .full {
    width: 100%;
  }

  span {
    text-align: start;
    color: ${darken(0.1, "#227af1")};
    margin-top: 1px;
    margin-bottom: 20px;
    margin-left: 5px;
    font-weight: bold;
    font-style: italic;
    font-family: GothamProRegular;
    font-size: 14px;

    animation: ${textFocus} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  #obs-input {
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: center;
    color: #7f7fb9;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    ${(props) =>
      !props.active &&
      css`
        width: 95%;
      `}

    ${(props) =>
      props.active &&
      css`
        width: 95%;
      `}

        .full {
      width: 95%;
    }

    ${(props) =>
      props.small &&
      css`
        width: 95%;
      `}

    ${(props) =>
      props.margin &&
      css`
        margin-right: 0 !important;
      `}
  }
`;

export const BoxLabel = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: ${primaryColor};

  ${(props) =>
    props.active &&
    css`
      background-color: ${darken(0.2, `${primaryColor}`)};
    `}

  display:flex;
  align-items: center;
  justify-content: center;
  label {
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 58px;
      border-radius: 50%;

      input {
        display: none;
      }

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        opacity: 0.7;
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    }
  }
`;

export const NewChallengeComponent = styled.main`
  width: 720px;
  height: 1729px;

  #title_challenge {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .form {
    margin-top: 35.6px;
    form {
      .inputs {
        display: flex;
        flex-wrap: wrap;
        margin-right: -16px;
        margin-top: 41px;
        margin-bottom: 15px;

        #number-chars {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-bottom: 10px;
          margin-left: 10px;
        }

        input {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.65px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;

          padding-left: 33.6px;

          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          &::-moz-placeholder {
            opacity: 1;
          }

          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }

        .value-input {
          margin-left: 16px;
        }

        .input_yt {
          margin-top: 0;
        }

        #btn-category {
          float: right;
          width: 352px;
          height: 60px;
          border-radius: 30px;
          background-color: #227af1;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          transition: all 0.3s ease-in-out 0s;
          margin-right: 16px;
          &:hover {
            background-color: ${darken(0.1, "#227af1")};
          }
        }

        .full {
          width: 100%;
        }

        .box-goal {
          height: 110px;
          width: 720px;

          border-radius: 15px;
          box-shadow: 0 0 2.5px 0 rgba(49, 22, 159, 0.12);

          border: 2px dashed #eee;
          padding: 25px;

          display: flex;
          align-items: center;

          word-wrap: break-word;

          margin-top: 20px;
          margin-bottom: 35px;

          #attention {
            font-size: 16px;
            font-weight: 500;
            margin-right: 10px;
            text-align: left;
            color: #227af1;
          }

          #text {
            font-family: GothamProRegular;
            font-size: 14px;
            line-height: 1.71;
            text-align: left;
            color: #000;
          }
        }

        .img {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          border: solid 1px #7f7ebe;

          display: flex;
          align-items: center;
          justify-content: space-around;

          p {
            font-size: 7px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #7f7fb9;
          }

          #img-input {
            width: 58px;
            height: 58px;
            background-color: ${primaryColor};

            border-radius: 50%;
          }
        }
      }
      .textarea {
        width: 715px;
        height: 179px;

        border-radius: 15px;
        border: solid 1px #7f7ebe;

        outline: none;
        resize: none;
        overflow: hidden;

        padding-top: 19px;
        padding-left: 33.6px;

        margin-bottom: 19px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #7f7fb9;

        &::placeholder {
          clear: left;
          flex-wrap: wrap;
          color: #7f7fb9;
        }

        &::-moz-placeholder {
          opacity: 1;
        }
      }

      .input-imgs {
        width: 352px;
        height: 60px;
        padding: 1px 0 1px 27.6px;
        border-radius: 30px;
        border: solid 1.65px #7f7ebe;

        margin-right: 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        #placeholder {
          font-family: GothamProRegular;
          font-size: 14px;
          line-height: 1.71;
          text-align: left;
          color: #7f7fb9;
        }
      }

      .previews {
        display: flex;

        .preview {
          height: 97px;
          width: 76px;

          margin-right: 16px;

          display: flex;
          flex-direction: column;
          align-items: center;

          #teste {
            width: 76px;
            height: 65px;
            border-radius: 10px;
          }

          button {
            width: 40px;

            background: transparent;

            font-size: 14px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: ${primaryColor};

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, `${primaryColor}`)};
            }
          }
        }
      }
      #btn-register {
        width: 168px;
        height: 45px;
        border-radius: 30px;
        background-color: ${primaryColor};

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.1, `${primaryColor}`)};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;
    height: 3510px;
    #title_challenge {
      font-size: 1rem;
    }

    .form {
      width: 100% !important;
      form {
        width: 100% !important;
        .inputs {
          width: 100% !important;
          flex-wrap: nowrap;
          margin-right: 0;
          flex-direction: column !important;

          input {
            width: 100%;
          }

          .value-input {
            margin-left: 0;
          }

          .input_yt {
            margin-top: 15px !important;
          }
          .input-imgs {
            width: 100%;
            margin-top: 15px !important;
          }

          #btn-category {
            float: none !important;

            width: 100%;
          }

          .box-goal {
            width: 100%;
            height: 160px;
            flex-direction: column;
            padding: 10px;

            #attention {
              font-size: 1rem;
            }

            #text {
              font-size: 0.7rem;
            }
          }
        }

        .textarea {
          width: 100% !important;
        }
      }
    }
  }
`;

export const NewCategoryComponent = styled.div`
  width: 720px;

  margin-top: 20px;
  margin-bottom: 25px;

  #title {
    margin-top: 30px;
    margin-bottom: 15px;

    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;
  }

  input {
    width: 352px;
    height: 60px;
    border-radius: 30px;
    border: solid 1.65px #7f7ebe;
    margin-right: 16px;

    padding-left: 33.6px;

    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;

    &::-moz-placeholder {
      opacity: 1;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
  }

  button {
    width: 260px;
    height: 60px;

    margin-left: 40px;

    border-radius: 30px;
    background-color: #227af1;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out 0s;
    margin-right: 16px;
    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  hr {
    background: #eee;
    height: 2px;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        width: 100%;
        margin-bottom: 15px;
        margin-left: 0;
      }
    }

    #title {
      font-size: 1rem;
    }
  }
`;

export const Shoppers = styled.main`
  width: 720px;
  height: 1729px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  #text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    margin-top: 25px;

    .box-shopper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
    }

    li {
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);

      height: 250px;

      width: 100%;
      border-radius: 15px;

      display: flex;
      flex-direction: row;

      margin-bottom: 30px;

      padding-left: 25px;

      .boxes {
        display: flex;
        flex-direction: column;

        margin-top: 15px;
        margin-bottom: 8px;

        height: 210px;
        width: 300px;

        border-right: solid 2px #eee;

        #title-box {
          font-size: 17px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.33;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};

          margin-bottom: 17.6px;
        }

        .box {
          display: flex;
          flex-direction: row;
          align-items: center;

          margin-bottom: 25px;

          #info {
            font-family: GothamProRegular;
            margin-left: 18px;
            font-size: 15px;
          }

          img {
            height: 20px;
          }
        }
      }
      .adress {
        display: flex;
        flex-direction: column;

        margin-top: 15px;
        margin-bottom: 8px;
        margin-left: 20px;

        height: 210px;

        width: 400px;

        border-right: solid 2px #eee;

        #title-box {
          font-size: 17px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.33;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};

          margin-bottom: 8px;
        }

        .infos {
          #info {
            font-family: GothamProRegular;

            font-size: 14px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  .floatPage {
    float: right;

    margin-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    height: 3129px;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }
    .content {
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;

      li {
        height: 560px;
        flex-direction: column;
        padding-left: 10px;
        p {
          font-size: 0.8rem !important;
          word-wrap: break-word;
          word-break: break-all;
        }

        .boxes {
          width: 90%;
          border-right: none !important;
        }

        .adress {
          margin-top: 35px;
          width: 90%;
          border-right: none !important;
        }
      }
    }

    .floatPage {
      float: none;
    }
  }
`;

export const EditChallengeComponent = styled.main`
  width: 720px;
  height: 2329px;

  #title_challenge {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 8.6px;
  }

  .form {
    margin-top: 35.6px;
    form {
      .inputs {
        display: flex;
        flex-wrap: wrap;
        margin-right: -16px;
        margin-top: 41px;
        margin-bottom: 15px;

        input {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.65px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;

          padding-left: 33.6px;

          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          &::-moz-placeholder {
            opacity: 1;
          }

          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }

        .full {
          width: 100%;
        }

        .value-challenge {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          opacity: 0.45;
          border: solid 1.4px #7f7ebe;
          background: transparent;

          display: flex;
          align-items: center;
          padding-left: 26.6px;

          p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: #7f7fb9;
          }
        }

        .img {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          border: solid 1px #7f7ebe;

          display: flex;
          align-items: center;
          justify-content: space-around;

          p {
            font-size: 7px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #7f7fb9;
          }

          #img-input {
            width: 58px;
            height: 58px;
            background-color: ${primaryColor};

            border-radius: 50%;
          }
        }
      }
      .textarea {
        width: 715px;
        height: 179px;

        border-radius: 15px;
        border: solid 1px #7f7ebe;

        outline: none;
        resize: none;
        overflow: hidden;

        padding-top: 19px;
        padding-left: 33.6px;

        margin-bottom: 19px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #7f7fb9;

        &::placeholder {
          clear: left;
          flex-wrap: wrap;
          color: #7f7fb9;
        }

        &::-moz-placeholder {
          opacity: 1;
        }
      }

      .input-imgs {
        width: 352px;
        height: 60px;
        padding: 1px 0 1px 27.6px;
        border-radius: 30px;
        border: solid 1.65px #7f7ebe;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-right: 16px;

        #placeholder {
          font-family: GothamProRegular;
          font-size: 14px;
          line-height: 1.71;
          text-align: left;
          color: #7f7fb9;
        }
      }

      .previews {
        display: flex;

        .preview {
          height: 97px;
          width: 76px;

          margin-right: 16px;

          display: flex;
          flex-direction: column;
          align-items: center;

          #teste {
            width: 76px;
            height: 65px;
            border-radius: 10px;
          }

          button {
            width: 40px;

            background: transparent;

            font-size: 14px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: ${primaryColor};

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, `${primaryColor}`)};
            }
          }
        }
      }
      #btn-register {
        width: 168px;
        height: 45px;
        border-radius: 30px;
        background-color: ${primaryColor};

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.1, `${primaryColor}`)};
        }
      }
    }
  }

  .questions {
    display: flex;
    flex-direction: column;

    margin-top: 71px;

    #title-questions {
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    hr {
      width: 720px;
      height: 1;
      opacity: 0.12;
      border: solid 1px #707070;

      margin-bottom: 31px;
    }

    .question {
      margin-bottom: 38.5px;

      .options {
        float: right;
        margin-right: 30px;

        button {
          font-size: 14px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          line-height: 3.21;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};

          transition: all 0.3s ease-in-out 0s;

          background: transparent;
          &:hover {
            color: ${darken(0.1, `${primaryColor}`)};
          }
        }

        #btn-del {
          margin-left: 33px;
        }
      }
    }

    .write {
      margin-top: 20px;

      .message {
        width: 720px;
        height: 179px;

        border-radius: 15px;

        outline: none;
        resize: none;
        overflow: hidden;

        padding: 19px 33.6px 0px 33.6px;

        margin-bottom: 19px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;

        box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);

        &::placeholder {
          clear: left;
          flex-wrap: wrap;

          color: #d6d6e3;
        }

        &::-moz-placeholder {
          opacity: 1;
        }
      }

      #btn-send {
        width: 168px;
        height: 45px;
        border-radius: 30px;
        background-color: ${primaryColor};

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.1, `${primaryColor}`)};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;
    height: 2529px;

    #title_challenge {
      font-size: 1rem;
    }

    .form {
      width: 100% !important;
      form {
        width: 100% !important;
        .inputs {
          width: 100% !important;
          flex-wrap: nowrap;
          margin-right: 0;
          flex-direction: column !important;

          .value-challenge,
          input {
            width: 100%;
          }
          .value-challenge {
            margin-bottom: 15px;
          }

          .value-input {
            margin-left: 0;
          }

          .input_yt {
            margin-top: 15px !important;
          }
          .input-imgs {
            width: 100%;
            margin-top: 15px !important;
          }

          #btn-category {
            float: none !important;

            width: 100%;
          }

          .box-goal {
            width: 100%;
            height: 160px;
            flex-direction: column;
            padding: 10px;

            #attention {
              font-size: 1rem;
            }

            #text {
              font-size: 0.7rem;
            }
          }
        }

        .textarea {
          width: 100% !important;
        }
      }
    }

    .questions {
      width: 100% !important;

      .write {
        width: 90%;
        .message {
          width: 100% !important;
        }
      }
    }
  }
`;

export const Question = styled.div`
  width: 720px;
  height: 226px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  padding: 30px;
  margin-bottom: 14px;
  margin-top: 7px;
  margin-left: 5px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  ${(props) =>
    props.active &&
    css`
      background-color: #fafaff;
    `}

  .icon {
    width: 70px;
    height: 70px;
    border: solid 2px rgba(127, 126, 190, 0.31);
    border-radius: 50%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    margin-right: 10px;

    #background-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      background-color: rgba(127, 127, 185, 0.31);

      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    margin-top: 19px;

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: ${primaryColor};
      margin-bottom: 10px;
    }

    #text {
      width: 484px;

      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const ProjectSecond = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 16px;

  z-index: 1;

  width: 352px;
  height: 472.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;

      margin-top: 10px;
    }
  }

  .content-2 {
    width: 100%;
    height: 243.8px;
    .titles {
      width: 100%;
      margin-top: 26px;

      #title-project {
        margin-left: 33px;
        font-size: 100%;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }

      div {
        align-self: flex-start;

        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 8.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .donation {
      margin-top: 26.6px;

      p {
        margin-left: 33px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }

      #value {
        font-size: 20px;
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 40px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 4vh;

    p {
      font-size: 0.8rem !important;
    }
  }
`;

export const Challenge = styled.div`
  margin-bottom: 39px;
  margin-left: 14px;

  width: 352px;
  height: 520px;

  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 13px 0 rgba(49, 22, 159, 0.09);
  background-color: #fafaff;
  border-radius: 10px;
  .challenge-content-1 {
    width: 100%;
    height: 170px;

    border-radius: 10px;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .challenge-content-2 {
    width: 100%;
    height: 291px;

    background-color: #fafaff;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      #title-challenge {
        margin-top: 30px;
        margin-bottom: 5px;
        margin-left: 26px;

        width: 293px;

        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }

      #company,
      #date,
      #category {
        margin-top: 5px;
        margin-left: 26px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }

      section {
        margin-top: 20px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .challenge-price {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;

      margin-left: 26px;
      margin-top: 30px;

      #price-value {
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }
      #price-value-2 {
        margin-right: 10px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }
      #price-text {
        margin-right: 10px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }
    }

    .special-premium-box {
      margin-left: 26px;
      display: flex;
      flex-direction: column;

      p {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }
    }
  }
  #challenge-content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin: 38px 9.4px 40px 26px;

      height: 29px;
    }
    p {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  section {
    margin-top: 10px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const Project = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 16px;

  z-index: 1;

  width: 352px;
  height: 651.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin-top: 10px;

      max-height: 100%;
      max-width: 100%;
    }
  }

  .content-2 {
    width: 100%;
    height: 422.8px;

    .titles {
      width: 100%;
      margin-top: 26px;

      #title-project {
        margin-left: 33px;
        font-size: 100%;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }

      div {
        align-self: flex-start;

        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 16.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .resume {
      #title-resume {
        margin-top: 26.6px;
        margin-left: 33px;
        margin-bottom: 11.3px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }

      #text-content {
        margin-left: 33px;

        width: 302px;

        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000000;

        word-wrap: break-word;
      }
      section {
        margin-top: 8.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .data {
      p {
        margin-left: 27px;
        margin-top: 12px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 40px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const Product = styled.div`
  margin-bottom: 39px;
  margin-right: 16px;

  width: 352px;
  height: 480px;
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 13px 0 rgba(49, 22, 159, 0.09);
  background-color: #fafaff;
  border-radius: 10px;

  #product-content-1 {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  #product-content-2 {
    width: 100%;
    height: 261px;
    background-color: #fafaff;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      #product-text-1-content-2 {
        margin-top: 20px;
        margin-left: 26px;
        width: 209px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
      #category {
        margin-left: 26px;
        margin-top: 7px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }
      #product-text-2-content-2 {
        margin-top: -40px;
        margin-left: -70px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
        img {
          height: 16px;
          width: 16px;
          margin-right: 6px;
        }
      }
      section {
        margin-top: 20px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    #product-price {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      #product-text-3-content-2 {
        margin-left: 26px;
        margin-top: 20px;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};
      }
      #product-text-4-content-2 {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: ${primaryColor};

        margin-left: 10px;
      }
      #product-text-5-content-2 {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #b0b3ce;
      }
    }

    .data {
      p {
        margin-left: 27px;
        margin-top: 12px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }
  }
  #product-content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin: 38px 9.4px 40px 26px;
    }
    p {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  section {
    margin-top: 10px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const DropDownList = styled.ul`
  background: #ffffff;
  border: solid 1.4px #7f7ebe;
  box-sizing: border-box;
  color: #7f7ebe;

  z-index: 1;
  height: 68px;

  border-radius: 5px;

  &:first-child {
    padding-top: 5px;
  }
`;

export const ListItem = styled.li`
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 85%;
  height: 100%;

  ${(props) =>
    props.big &&
    css`
      width: 85%;
      height: 52%;
    `}

  z-index:1;

  margin-left: 15px;
  border-bottom: 1.2px solid #eee;

  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    color: ${darken(0.15, "#7f7ebe")};
    animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    font-size: 0.85rem;
  }
`;

export const DropDownListContainer = styled.div`
  margin-top: 1px;
  position: absolute;
  z-index: 1;
  width: 22em;

  ${(props) =>
    props.small &&
    css`
      width: 11em;
    `}

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    margin-left: 145px;

    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #7f7ebe;
    @media only screen and (max-width: 768px) {
      left: 60vw;
      margin-left: 10vw;
    }
  }

  animation: ${textFocus} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @media only screen and (max-width: 768px) {
    position: unset !important;
    width: 80vw;
    margin-left: 5vw;
    margin-bottom: 20px;
  }
`;

export const DropDownListContainerState = styled.div`
  margin-top: 1px;
  position: absolute;
  z-index: 1;

  width: 11em;

  padding: 0;

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    margin-left: 55px;

    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #7f7ebe;
    @media only screen and (max-width: 768px) {
      left: 25vw;
      margin-left: 0;
    }
  }

  animation: ${textFocus} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @media only screen and (max-width: 768px) {
    position: unset !important;
    width: 90%;
    margin-left: 2%;
    margin-bottom: 20px;
  }
`;
export const DropDownContainer = styled.div`
  z-index: 1;
`;

export const DropDownHeader = styled.div`
  margin-bottom: 0.8em;

  width: 352px;

  .estado {
    width: 168px;
  }

  #text-state {
    margin-top: 30px;
  }

  height: 60px;

  border-radius: 30px;
  border: solid 1.4px #7f7ebe;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 25px;
  padding-right: 15px;

  font-size: 14px;
  text-align: left;
  color: #7f7fb9;

  .small {
    font-size: 15.5px !important;
  }

  text-align: center;

  margin-right: 16px;

  z-index: 1;

  cursor: pointer;

  .icon-drop {
    margin-bottom: 5px;
  }

  #up-icon {
    transform: rotate(180deg);
  }
  @media only screen and (max-width: 768px) {
    width: 85vw;
    margin-left: 2vw;
  }
`;

export const MyChallenges = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const MyProducts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ProductContent = styled.div`
  .edit {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 42px;

    margin-bottom: 15px;

    .pencil {
      width: 43px;
      height: 42px;
      background-color: ${primaryColor};

      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    }

    #text-edit {
      background: transparent;

      margin-left: 13.8px;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.21;
      letter-spacing: normal;
      text-align: left;
      color: ${primaryColor};

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, `${primaryColor}`)};
      }
    }
  }
  .shopers {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 42px;

    margin-bottom: 32px;

    .pencil {
      width: 43px;
      height: 42px;
      background-color: ${primaryColor};

      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    }

    #text-edit {
      background: transparent;

      margin-left: 13.8px;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.21;
      letter-spacing: normal;
      text-align: left;
      color: ${primaryColor};

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, `${primaryColor}`)};
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }

    .floatPage {
      float: none;
    }
  }
`;

export const AboutCompanyComponent = styled.main`
  width: 720px;
  height: 1000px;

  padding-top: 60px;

  #title {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .infos {
    .box-img {
      height: 110px;
      width: 110px;
      border-radius: 50%;
      border: 2px dashed #eee;

      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 30px;

      #avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .datas {
      margin-top: 50px;
      width: 100%;
      #title-data {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
        font-size: 20px;
      }
      .all {
        display: flex;
        margin-top: 40px;

        .about {
          width: 300px;
          height: 190px;
          margin-bottom: 25px;

          border-right: 2px dashed #eee;

          .info {
            display: flex;
            align-items: center;

            margin-bottom: 30px;

            img,
            svg {
              margin-right: 10px;
              height: 20px;
              width: 20px;
            }

            #type {
              font-weight: 500;
              text-align: left;

              margin-right: 10px;
            }

            p {
              font-family: GothamProRegular;
            }
          }
        }

        .adress {
          display: flex;
          flex-direction: column;

          padding-left: 30px;
          .adress-info {
            display: flex;

            #type {
              margin-right: 15px;
              color: #227af1;
              font-family: GothamPro;
            }
            p {
              margin-bottom: 25px;
              font-family: GothamProRegular;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1rem;
    }

    .text {
      text-align: justify;
    }

    .datas {
      p {
        font-size: 0.85rem !important;
      }

      .all {
        flex-direction: column;

        .about {
          border-right: unset !important;
        }
      }
    }
  }
`;

export const SpecialPremiumComponent = styled.main`
  width: 720px;
  height: 1254px;

  #title-special {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: ${primaryColor};

    margin-bottom: 17.6px;
  }
  #sub-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 2.25;
    text-align: left;
    color: #000;
    font-family: GothamProRegular;
  }

  form {
    margin-top: 30px;
  }

  input {
    width: 352px;
    height: 60px;
    border-radius: 30px;
    border: solid 1.65px #7f7ebe;
    margin-right: 16px;
    margin-bottom: 20px;

    padding-left: 33.6px;

    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;

    &::-moz-placeholder {
      opacity: 1;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
  }

  .message {
    width: 720px;
    height: 179px;

    border-radius: 15px;

    outline: none;
    resize: none;
    overflow: hidden;

    padding: 19px 33.6px 0px 33.6px;

    margin-bottom: 19px;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;

    border: solid 1.65px #7f7ebe;

    &::-moz-placeholder {
      opacity: 1;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
  }

  #btn-send {
    width: 168px;
    height: 45px;
    border-radius: 30px;
    background-color: ${primaryColor};

    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: ${darken(0.1, `${primaryColor}`)};
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    p {
      font-size: 0.9rem;
    }

    input,
    textarea {
      width: 90% !important;
    }
  }
`;
