import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
`;

export const Content = styled.div`
  width: 1366px;
  height: 2081px;

  ${(props) =>
    props.active &&
    css`
      height: 1150px;
    `}

  display:-webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 462px;

  background: #227af1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  padding-bottom: 25px;

  #second-content-header {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-right: 39.4px;

    #icon-header {
      width: 63px;
      height: 63.8px;
    }

    .titles {
      margin-top: 11.1px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      #title {
        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }

      #sub-title {
        font-family: GothamProRegular;

        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        width: 505px;
        height: 60px;
      }
    }

    #img-header {
      display: block;
      position: absolute;

      align-self: flex-end;

      transform: translateY(25px);

      z-index: 0.5;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-top: 28px;

      margin-left: 39.4px;

      z-index: 1;
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 90vh;
    align-items: unset;
    justify-content: unset;

    z-index: 0;

    #second-content-header {
      width: 100%;
      height: 85%;

      margin-left: 0;
      padding-right: 0;

      #icon-header {
        width: 36.4px;
        height: 32.8px;
      }

      .titles {
        width: 80%;
        margin: 0;
        #title {
          font-size: 1.5rem;
        }

        #sub-title {
          width: 100%;
          word-wrap: break-word;
        }
      }

      #img-header {
        display: none;
        margin-left: 0;
      }

      .buttons {
        margin: 0;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-top: 10vh;

        z-index: 0;

        button {
          width: 80%;
          margin-bottom: 40px;

          font-size: 1rem;
        }
      }
    }
  }
`;

export const Button = styled.button`
  width: 352px;
  height: 60px;
  border-radius: 30px;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  margin-right: 16px;

  background-color: #fff;
  color: #227af1;

  transition: all 0.3s ease-in-out 0s;

  &:hover {
    background-color: ${darken(0.1, "#FFF")};
  }

  ${(props) =>
    props.active &&
    css`
      color: #fff;

      background: rgb(106, 243, 255);
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(32%, rgba(106, 243, 255, 1)),
        to(rgba(138, 238, 197, 1))
      );
      background: -o-linear-gradient(
        left,
        rgba(106, 243, 255, 1) 32%,
        rgba(138, 238, 197, 1) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(106, 243, 255, 1) 32%,
        rgba(138, 238, 197, 1) 100%
      );
      box-shadow: 0 0 15px 0 #45c6e5;

      &:hover {
        background: linear-gradient(
          90deg,
          ${darken(0.1, "rgba(106,243,255,1)")} 32%,
          ${darken(0.1, "rgba(138,238,197,1)")} 100%
        );
      }
    `}
`;

export const RegisterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 1709px;

  position: relative;
  z-index: -0.5;

  ${(props) =>
    props.active &&
    css`
      height: 350px;
    `}

  margin-bottom:70px;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 11px;
    margin-bottom: 45px;

    .login-btn {
      display: block;
      position: absolute;
      align-self: flex-end;
      margin-top: 50px;
      margin-right: 50px;

      width: 260px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }

    .down-avatar {
      .pencil {
        margin-top: -73px;
        margin-left: 105px;

        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #227af1;

        display: flex;
        align-items: center;
        justify-content: center;

        z-index: 1;
      }

      p {
        margin-top: 17px;

        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .inputs {
      display: flex;
      flex-direction: row;

      margin-left: 131px;
      margin-top: 43px;

      flex-wrap: wrap;

      input {
        width: 352px;
        height: 60px;
        border-radius: 30px;
        border: solid 1.4px #7f7ebe;
        margin-right: 16px;
        margin-bottom: 20px;

        padding-left: 33.6px;

        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;

        &::-moz-placeholder {
          opacity: 1;
        }

        &::placeholder {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
        }
      }

      .input-content {
        display: flex;
        flex-direction: column;

        span {
          text-align: start;
          color: ${darken(0.1, "#227af1")};
          margin-top: 1px;
          margin-bottom: 20px;
          margin-left: 5px;
          font-weight: bold;
          font-style: italic;
          font-family: GothamProRegular;
          font-size: 14px;

          animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
            both;
        }
      }
    }
  }

  .interests {
    display: flex;
    flex-direction: column;

    align-self: flex-start;

    margin-top: 69.4px;
    margin-left: 135px;

    width: 100%;

    .title {
      display: flex;
      flex-direction: row;

      > div {
        margin-left: 14.6px;
        margin-top: 5px;

        p {
          font-size: 30px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.33;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
    }

    .sub-title {
      width: 552px;
      height: 61px;
      p {
        font-family: GothamProRegular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
    }

    .buttons-interests {
      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
      margin-top: 32px;

      max-width: 1104px;

      button {
        margin-right: 16px;
        margin-bottom: 23px;
        height: 60px;

        padding-left: 5px;
      }

      #first {
        width: 230px;
      }

      #second {
        width: 168px;
      }

      #fourth {
        width: 138px;
      }

      #third {
        width: 290px;
      }
    }

    .box-add-scientist {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      width: 100%;

      margin-bottom: 30px;

      .content-scrool {
        width: 310px;
        height: 167px;
        margin-top: 50px;
      }

      .inputs {
        display: flex;
        flex-direction: column;

        margin-left: 0px;

        margin-right: 90px;

        #sub-title {
          font-family: GothamProRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          margin-bottom: 10px;
        }

        .title {
          display: flex;
          flex-direction: row;
          margin-bottom: 11px;

          img {
            height: 56.2px;
          }
          p {
            font-size: 30px;
            line-height: 2.33;
            text-align: left;
            color: #227af1;

            margin-left: 14px;
          }
        }
        .new-member {
          input {
            width: 510px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.4px #7f7ebe;
            margin-right: 16px;
            margin-bottom: 20px;
            padding-left: 33.6px;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;

            margin-right: 15px;

            &::-moz-placeholder {
              opacity: 1;
            }
            &::placeholder {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #7f7fb9;
            }
          }

          span {
            display: block;
            position: absolute;

            text-align: start;
            color: ${darken(0.1, "#227af1")};
            margin-top: 1px;
            margin-bottom: 20px;
            margin-left: 5px;
            font-weight: bold;
            font-style: italic;
            font-family: GothamProRegular;
            font-size: 16px;

            animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
              both;
          }

          button {
            width: 142px;
            height: 60px;
            border-radius: 30px;
            background-color: #227af1;

            font-size: 15px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              background-color: ${darken(0.1, "#227af1")};
            }
          }
        }
      }
    }
  }

  section {
    width: 80%;
    height: 2px;

    opacity: 0.56;
    background-color: #eee;

    margin-bottom: 50px;
  }

  .label {
    margin-bottom: 15px;
  }

  .label__checkbox {
    display: none;
  }

  .label__check {
    display: inline-block;
    border-radius: 3px;
    border: solid 1px #7f7fb9;
    background: white;
    vertical-align: middle;
    margin-right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out 0s;

    margin-left: 5px;

    i.icon {
      opacity: 0.2;
      font-size: calc(1rem + 1vw);
      color: transparent;
      transition: all 0.3s ease-in-out 0s;
      -webkit-text-stroke: 3px #fff;
    }

    &:hover {
      border: solid 2.5px #7f7fb9;
    }
  }

  .label__text {
    display: flex;
    align-items: center;

    #text-checkbox {
      font-size: 14px;
      text-align: left;
      color: #7f7fb9;

      a {
        margin-left: 5px;
        color: #227af1;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  .label__checkbox:checked + .label__text .label__check {
    animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;

    .icon {
      opacity: 1;
      transform: scale(0);
      color: white;
      -webkit-text-stroke: 0;
      animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.4s 1 forwards;
    }
  }

  @keyframes icon {
    from {
      opacity: 0;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes check {
    0% {
      width: 1.5em;
      height: 1.5em;
      border-width: 5px;
    }
    10% {
      width: 1.5em;
      height: 1.5em;
      opacity: 0.1;
      background: #fff;
      border-width: 15px;
    }
    12% {
      width: 1.5em;
      height: 1.5em;
      opacity: 0.4;
      background: #fff;
      border-width: 1;
    }
    50% {
      width: 2em;
      height: 2em;
      background: #fff;
      border: 0;
      opacity: 0.6;
    }
    100% {
      width: 2em;
      height: 2em;
      background: #fff;
      border: 1;
      opacity: 1;
    }
  }

  .buttons-register {
    display: flex;

    #finish-register {
      margin-top: 39px;
      margin-bottom: 61px;

      width: 260px;

      margin-left: 12px;

      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 2709px;

    .form {
      width: 100%;
      margin: 0;
      padding: 0;

      display: flex;
      flex-direction: column;
      align-items: center;

      #obs {
        width: 80%;
        text-align: center;
      }

      .login-btn {
        display: none;
      }
      .inputs {
        width: 80vw;
        margin: 0;
        margin-left: 10vw;
        margin-top: 6vh;
        flex-direction: column;

        input {
          width: 80%;
          margin-right: 0;
        }
      }

      .interests {
        width: 95%;
        padding: 0;
        margin: 0;
        width: 92vw;

        margin-left: 8vw;

        .content-scrool {
          width: 90% !important;
        }
        .title {
          width: 100%;
          > div {
            p {
              width: 100%;
              word-wrap: break-word;
              font-size: 1.2rem;
            }
          }
        }
        .sub-title {
          width: 100%;

          p {
            width: 80%;
            word-wrap: break-word;
            font-size: 1rem;
          }
        }
        .buttons-interests {
          width: 100%;
          margin-top: 10vh;

          button {
            padding: 0;
            text-align: center;

            margin-right: 2vw;
          }

          #first {
            width: 24%;
            font-size: 0.6rem;
          }

          #second {
            width: 30%;
          }

          #fourth {
            width: 25%;
          }

          #third {
            width: 33%;
          }
        }

        .box-add-scientist {
          width: 100%;
          flex-direction: column;
          .inputs {
            margin: 0;

            .title {
              img {
                height: 36.2px;
              }
            }

            .new-member {
              input {
                width: 100%;
                padding-left: 15px;
                &::placeholder {
                  font-size: 0.8rem;
                }
              }

              button {
                width: 40%;
              }
            }
          }
        }
      }

      .label {
        width: 100%;
        margin: 0;

        margin-left: 40%;

        .label__text {
          width: 90%;
          margin: 0;

          #text-checkbox {
            margin: 0;
            width: 60%;
            word-wrap: break-word;
            font-size: 0.8rem;

            a {
              margin-left: 0;
            }
          }
        }
      }

      .captcha {
        margin-top: 30px;
        align-self: center;
      }

      .buttons-register {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin: 0;

        #finish-register {
          width: 50%;
          margin-bottom: 1.5vh;
        }
      }
    }
  }
`;
export const BoxScientist = styled.li`
  width: 284px;
  height: 149px;

  box-shadow: 0 0 6px 0 rgba(49, 22, 159, 0.12);

  border-radius: 10px;

  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 1;
  cursor: pointer;

  ${(props) =>
    props.select &&
    css`
      border: 2px solid #227af1;
    `}

  .fs-box {
    width: 100%;
    display: flex;

    padding-left: 107px;
    padding-top: 10px;

    .first-div {
      width: 70px;
      height: 70px;
      border: solid 1px rgba(127, 126, 190, 0.31);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 35%;

      #profile {
        height: 85%;
        width: 85%;
        border-radius: 50%;
      }
    }

    #icon-trash {
      height: 20px;
      margin-bottom: 40px;
      cursor: pointer;
    }
  }
  #name-user {
    max-width: 90%;

    margin-top: 13px;

    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-style: italic;

    color: #7f7fb9;
  }

  @media only screen and (max-width: 768px) {
    width: 92%;

    .fs-box {
      padding-left: 40%;

      #icon-trash {
        width: 4vw;
      }
    }

    #name-user {
      font-size: 0.85rem;
      word-break: break-all;
    }
  }
`;

export const LoginContent = styled.div`
  margin-top: 50px;
  width: 100%;

  .form-login {
    display: flex;
    flex-direction: row;

    input {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #7f7ebe;
      margin-right: 16px;
      margin-bottom: 20px;

      padding-left: 33.6px;

      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      &::-moz-placeholder {
        opacity: 1;
      }

      &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }
    .input-content {
      display: flex;
      flex-direction: column;

      span {
        text-align: start;
        color: ${darken(0.1, "#227af1")};
        margin-top: 1px;
        margin-bottom: 20px;
        margin-left: 5px;
        font-weight: bold;
        font-style: italic;
        font-family: GothamProRegular;
        font-size: 14px;

        animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      }
    }

    #submit-btn {
      width: 140px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #fff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  #register-btn,
  #recover-btn {
    margin-top: 30px;

    width: 352px;
    height: 60px;
    border-radius: 30px;
    background-color: #227af1;

    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: center;
    color: #fff;

    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  #recover-btn {
    margin-left: 16px;
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    padding-left: 10vw;
    .form-login {
      width: 100%;

      flex-direction: column;

      input {
        width: 80%;
      }
    }

    #register-btn,
    #recover-btn {
      width: 80%;
    }
    #recover-btn {
      margin-left: 0;
    }
  }
`;

export const ButtonInterests = styled.button`
  border-radius: 30px;
  background: transparent;
  border: solid 2px #d6d6e5;

  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #d6d6e5;

  transition: all 0.3s ease-in-out 0s;

  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    opacity: 0;
  }

  ${(props) =>
    props.active &&
    css`
      border: solid 2px #2976fa;
      color: #227af1;

      img {
        margin-top: 2px;

        width: 16.6px;
        height: 16.6px;

        opacity: 1;
      }
    `}

  @media only screen and (max-width:768px) {
    font-size: 0.8rem;

    img {
      display: none;
    }
  }
`;
