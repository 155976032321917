import React from "react";

// import { Container } from './styles';

import iconGear from "../../../../assets/icons/About/gear.svg";

import { Wrapper, Row, Column } from "./styles";

import { Container } from "../../../../styles/global";

function Second() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <img id="icon-gear" src={iconGear} alt="" />
            <h1>Quem são os responsáveis pela plataforma We4Science?</h1>
            <p id="sub-title">
              A equipe de profissionais da empresa brasileira{" "}
              <strong>We4Science Projects and Innovation Ltda</strong>. é a
              única responsável pelo desenvolvimento e operação desta plataforma
              e também responde pela Iniciativa We4Science – Todos pela Ciência.
              Contamos também com muitos parceiros e apoiadores, em especial, o
              Centro de Inovação e Tecnologia (CITEC) da{" "}
              <strong>
                Universidade Federal dos Vales do Jequitinhonha e Mucuri (UFVJM)
              </strong>{" "}
              e a empresa mineira de desenvolvimento web,{" "}
              <strong>Fac Consulting</strong>.
            </p>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Second;
