import styled, { css } from 'styled-components';

export const Container = styled.div `
    margin-top:50px;
    align-self:center;
    margin-bottom:30px;

    z-index:0.8;    

    .first-div{
        width: 132px;
        height: 132px;
        border: solid 1px rgba(127,126,190,0.31);
        border-radius:50%;

        display:flex;
        align-items:center;
        justify-content:center;

        .second-div{
            label {
                cursor: pointer;

                >div{
                    width: 112px;
                    height: 112px;
                    background-color: rgba(127,126,190,0.31);
                    border-radius:50%;

                    display:flex;
                    align-items:center;
                    justify-content:center;

                    #icon{
                        width: 35.2px;
                        height: 44px;   
                    }

                    img{
                        width: 35.2px;
                        height: 44px;
                        
                    }

                    input{
                        display:none;
                    }

                    transition: all .3s ease-in-out 0s;

                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
        }
        
    }

    ${props => props.active && css`
        #teste{
            width: 100%;
            height: 100%;
            border-radius:50%;
        }
    `}
`;