import { useEffect, useState } from "react";

import api from "../services/api";

export default function useProject(id) {
  const [project, setProject] = useState([]);

  useEffect(() => {
    async function loadingProduct() {
      try {
        const { data } = await api.get(`project/${id}`);
        setProject({
          ...data.project,
        });
      } catch (e) {
        console.log("");
      }
    }
    loadingProduct();
  }, [id]);
  return { project };
}
