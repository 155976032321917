import React from "react";

import { Container } from "./styles";

import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


import GoBackTerms from "../../components/GoBackTerms";

function moneyTerms() {
  return (
    <>
      <Helmet>
        <title>Termos e Condições de uso – doações, aportes e saques</title>
      </Helmet>
      <Fade left duration={1600} distance="20px">
        <Container>
        <GoBackTerms />
          <div className="term">
            <p align="center">
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>
                  TERMOS E CONDIÇÕES DE USO – DOAÇÕES, APORTES E SAQUES
                </strong>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginRight: "10px",
                }}
              >
                O Participante não é autorizado a pronunciar-se em nome da
                Plataforma We4Science, devendo direcionar todos os seus
                questionamentos, solicitações de suporte, auxílio e/ou outras
                necessidades de comunicação em relação aos serviços prestados
                pela Plataforma We4Science para o respectivo serviço de
                tendimento
              </span>
              <Link to={"/contact?&type=normal"}>Fale Conosco</Link>

              <span style={{ fontFamily: "Arial, serif" }}>.</span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Como condição de acesso aos Serviços, o Participante deverá
                possuir os meios necessários para acessar a Plataforma
                We4Science, incluindo computador conectado à internet, provedor
                de conexão à internet, equipamentos, acessórios e softwares,
                sendo o único responsável pela obtenção, manutenção e custeio
                destes equipamentos, da conexão à internet e dos acessórios e/ou
                softwares mencionados acima, bem como, por certificar-se de que
                suas configurações satisfazem os requisitos necessários à
                fruição de todos os Serviços.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Fica estabelecido que o
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>
                  <strong>Participante Doador</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                poderá realizar
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                para fins de contribuição mediante formação prévia de saldo em
                moeda eletrônica em sua Conta. Os
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                somente poderão ser realizados a partir de um Meio de Pagamento
                emitido em nome do Participante, quando pessoa física, ou desde
                que tenha poderes para tanto, quando tal Meio de Pagamento
                pertença a uma pessoa jurídica.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A realização de
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                poderá ser precedida de análise de cadastro e informações do
                Participante, bem como poderá envolver a realização de
                procedimentos de&nbsp;
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>know your customer</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                &nbsp;(KYC) e de prevenção à lavagem de dinheiro.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <u>
                      <strong>Importante:</strong>
                    </u>
                  </em>
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>O</span>
              </span>
              <strong>
                <span style={{ color: "#000000" }}>
                  <span
                    style={{
                      fontFamily: "Arial, serif",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    KYC
                  </span>
                </span>
              </strong>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  &nbsp;está regulamentado no Brasil pela Lei nº 9.613/98, que
                  dispõe sobre a prevenção da utilização do sistema financeiro
                  para fins ilícitos, bem como, pelo ato
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>Normativo SARB 011/2013</strong>
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  da Federação Brasileira de Bancos (Febraban). Ambos podem ser
                  acessados
                </span>
              </span>
              <span style={{ color: "#0000ff", marginLeft: "10px" }}>
                <u>
                  <a href="https://www.legiscompliance.com.br/images/pdf/normativo_sarb_11_alterado_deliberacao_16_2018.pdf">
                    <span style={{ color: "#292929" }}>
                      <span style={{ fontFamily: "Arial, serif" }}>
                        <strong>aqui</strong>
                      </span>
                    </span>
                  </a>
                </u>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  &nbsp;e&nbsp;
                </span>
              </span>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="http://www.planalto.gov.br/ccivil_03/leis/l9613.htm">
                    <span style={{ color: "#292929" }}>
                      <span style={{ fontFamily: "Arial, serif" }}>
                        <strong>aqui</strong>
                      </span>
                    </span>
                  </a>
                </u>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  , respectivamente
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>.</span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                São condições indispensáveis para a validade do cadastro de
                usuário da Plataforma W4S:
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <li>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Pessoas Físicas: ter no mínimo 18 anos (ou estar legalmente
                  representado, quando menor de 18 anos, na forma da lei),
                  possuir número de inscrição ativo sob o CPF; ou
                </span>
              </li>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <li>
                {" "}
                <span style={{ fontFamily: "Arial, serif" }}>
                  Pessoas Jurídicas: possuir número de inscrição ativo sob o
                  CNPJ e ser representada por representante legal, devidamente
                  autorizado e com poderes para tal.
                </span>
              </li>
            </p>
            <p>
              <br />
            </p>
            <p align="justify">
              <li>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Pessoas Jurídicas Estrangeiras: deverão fazer contato prévio
                  com a administração da Plataforma We4Science por meio dos seus
                  canais de atendimento
                </span>
                <span
                  style={{
                    fontFamily: "Arial, serif",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <Link to={"/contact?&type=normal"}>Fale Conosco</Link>
                </span>
                <span style={{ fontFamily: "Arial, serif" }}>
                  para consulta de regramento legal e procedimentos para doação
                  específicos, no que concerne transações financeiras dessa
                  natureza entre o Brasil e o seu respectivo país.
                </span>
              </li>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A Plataforma We4Science se reserva no direito de utilizar todo e
                qualquer meio válido e legal que entender necessário para
                confirmar os dados fornecidos pelo Participante, bem como, sua
                situação de crédito, tendo em vista a natureza do serviço da
                Plataforma We4Science e a necessidade de confiabilidade e
                segurança dos registros das transações eletrônicas e demais
                funcionalidades da Plataforma.
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Dessa forma, a Plataforma We4Science poderá, entre outras
                medidas, solicitar ao Participante dados adicionais e/ou
                documentos que julgue necessários e, ainda, consultar bancos de
                dados mantidos por terceiros, tais como SPC e SERASA e outros.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Em caso de fornecimento de informações incorretas, inverídicas
                e/ou inércia do Participante no envio da documentação solicitada
                ou em caso de restrições ao crédito, a Plataforma We4Science
                poderá bloquear o acesso do Participante à Plataforma, sem
                prejuízo de outras medidas previstas nestes e nos demais Termos
                do site, sem que assista ao Participante qualquer tipo de
                indenização e/ou ressarcimento.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Sem prejuízo das autorizações acima, o Participante desde já
                autoriza a Plataforma We4Science a realizar o estorno e o
                cancelamento de todas as transações realizadas de forma
                irregular ou em violação às condições previstas em seus termos e
                condições de uso.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Caso o Aporte tenha sido realizado por meio de boleto bancário,
                o Participante também autoriza a Plataforma We4Science a
                descontar (debitar), dos valores estornados, todas e quaisquer
                despesas relacionadas à emissão e liquidação do referido
                instrumento de pagamento.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Fica, desde já, estabelecido que eventuais estornos realizados
                nos termos deste instrumento serão creditados na Conta do
                Participante doador junto à Plataforma We4Science e estarão
                sujeitos aos mesmos procedimentos aplicáveis às operações de
                Saques realizadas em relação a valores aportados normalmente.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Todos os participantes da Plataforma We4Science declaram e
                reconhecem estarem cientes e manterem-se regulares em relação à
                tributação e declaração de eventuais doações recebidas por esta
                Plataforma para os órgãos competentes, isentando a Plataforma
                We4Science de qualquer responsabilidade fiscal sobre as
                transações aqui realizadas.
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <br /> Os Participantes poderão utilizar quaisquer dos Meios de
                Pagamento aceitos pela Plataforma We4Science para a realização
                de
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>em</span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Projetos</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                , observadas as regras específicas contidas na descrição do
                respectivo Projeto, neste Site, bem como em todos os{" "}
                <Link
                  to={"/contact?&type=normal"}
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  Termos e Condições de Uso da Plataforma
                </Link>
              </span>

              <span style={{ fontFamily: "Arial, serif" }}>.</span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Quando o Meio de Pagamento escolhido pelo Participante donatário
                for o
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                }}
              >
                <em>
                  <strong>CARTÃO DE CRÉDITO</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                , o respectivo subadquirente cobra uma taxa de desconto sobre o
                valor transacionado por meio da Plataforma We4Science ("Taxa de
                Desconto"). Esta Taxa de Desconto remunerará o subadquirente
                pelos serviços de captura e liquidação da transação realizada no
                âmbito da Plataforma We4Science.
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Quando o Meio de Pagamento escolhido pelo Participante donatário
                for o
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                }}
              >
                <em>
                  <strong>BOLETO BANCÁRIO</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                , será devido ao respectivo banco emissor uma tarifa pela
                emissão e liquidação do boleto bancário ("Tarifa Boleto").
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>O valor dos</span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                realizados por meio de
              </span>
              <span style={{ fontFamily: "Arial, serif", marginLeft: "10px" }}>
                <em>
                  <strong>BOLETO BANCÁRIO </strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>será</span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>
                  <strong>(i)</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                convertido em saldo de moeda eletrônica;
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>
                  <strong>(ii)</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                registrado na Conta do Participante doador; e
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>
                  <strong>(iii)</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                vinculado ao Projeto Participante donatário, após o pagamento e
                compensação do boleto bancário, nos prazos definidos pela
                regulamentação. Uma vez registrado o saldo de moeda eletrônica
                na Conta do Participante doador, referido saldo será transferido
                para a Conta do Participante donatário, para fins de
                contribuição ao Projeto correspondente.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Caso o Aporte seja feito por meio de boleto bancário e este não
                seja pago pelo Participante doador até sua data de vencimento,
                tal boleto bancário será automaticamente cancelado, sem custos
                para o Participante doador.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A realização de
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                por Participantes por meio de
              </span>
              <span
                style={{
                  fontFamily: "Arial, serif",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <em>
                  <strong>CONTAS CORRENTES</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                somente poderá ser realizada se a conta remetente for de
                titularidade do próprio Participante.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O custo com Meio de Pagamento será deduzido de cada Aporte.
                Todos os Participantes autorizam a contratação, pela Plataforma
                We4Science e em seu favor, dos serviços de Meios de Pagamento
                que se fizerem necessários para a prestação dos Serviços objeto
                de todos os{" "}
                <Link
                  to={"/contact?&type=normal"}
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  Termos de Uso e Condições da Plataforma
                </Link>
              </span>

              <span style={{ fontFamily: "Arial, serif" }}>.</span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Os prazos para recebimento dos recursos pela Plataforma
                We4Science dependem do Meio de Pagamento escolhido pelo
                Participante, bem como, de eventuais restrições que a Plataforma
                We4Science possa identificar durante o processamento do Aporte.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O Participante que desejar sacar seu saldo (tal operação
                doravante denominada "Saque") deverá indicar conta bancária apta
                em instituição financeira nacional adequada para o registro e
                transferência dos recursos, sendo de sua exclusiva
                responsabilidade os dados fornecidos para tanto.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Todos os Participantes declaram e reconhecem a obrigatoriedade
                de possuir conta bancária apta, em instituição financeira
                nacional, regularmente escriturada e adequada para receber os
                recursos da Campanha iniciada na Plataforma.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Ficam sujeitos às regras específicas de todos os
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>
                  <strong>Termos de Uso e Condições desta Plataforma</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>(links Termo de Uso e Termo de Aporte)</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                , os recursos recebidos a título de Aporte.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O prazo para saque dos valores de contribuição para um Projeto,
                pelo Participante donatário, é de 30 (trinta) dias contados a
                partir do dia de encerramento da campanha, prazo esse
                pré-estipulado pelo próprio participante-donatário no momento do
                cadastro do projeto/campanha.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Para saques realizados a partir da solicitação de interrupção da
                campanha, que pode acontecer antes do prazo fixado, desde que
                devidamente autorizado pela administração da Plataforma
                We4Science, será observado o mesmo prazo de 30 (trinta) dias.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A Plataforma We4Science terá o direito de, caso sejam
                identificadas irregularidades (tais como, a ocorrência de
                fraudes,&nbsp;
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>chargebacks</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                , erros de processamento e/ou falhas de transação) e/ou
                situações que, a seu critério exclusivo, infrinjam as condições
                em seus Termos, cancelar e/ou realizar estornos de quaisquer
                valores aportados por todos os Participantes.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Caso tais irregularidades sejam decorrentes de fraude de
                responsabilidade da Plataforma We4Science, a Plataforma será
                responsável por todos os custos e despesas relacionadas à
                devolução dos recursos aos Participantes direta e
                comprovadamente prejudicados. Nas demais hipóteses, a Plataforma
                We4Science poderá cobrar dos Participantes doadores eventuais
                custos e/ou despesas relacionadas ao cancelamento e/ou estornos
                dos valores por eles aportados.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Caso tais irregularidades e/ou descumprimentos sejam
                identificados pela Plataforma We4Science após a realização de um
                Saque, a Plataforma poderá, a seu exclusivo critério, realizar a
                cobrança dos valores indevidamente pagos ao Participante
                donatário, por meio de envio de boleto de cobrança e/ou qualquer
                outro meio de cobrança admitido, ressalvado o seu direito de
                fazer a cobrança pela via judicial.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A Plataforma We4Science se reserva ao direito de, na hipótese do
                não pagamento dos valores acima mencionados, utilizar todos os
                meios de cobranças aceitos pela legislação brasileira e,
                inclusive, comunicar a inadimplência do Participante aos órgãos
                e entidades de proteção ao crédito.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O Participante donatário poderá consultar seu saldo, em moeda
                eletrônica, registrado em sua Conta a qualquer momento, por meio
                dos mecanismos disponibilizados pela Plataforma We4Science no
                Site.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O saldo total de moeda eletrônica detido em Projetos não sofrerá
                qualquer correção monetária, acréscimo de juros e/ou qualquer
                tipo de atualização financeira, permanecendo inalterado por todo
                o prazo em que for mantido na Conta na Plataforma.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A Plataforma We4Science não tem qualquer responsabilidade pela
                desvalorização e/ou desatualização monetária do valor dos saldos
                de moeda eletrônica mantidos pelos Participantes em suas Contas,
                na Plataforma.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A Plataforma We4Science poderá, a seu exclusivo critério e a
                qualquer tempo, conceder condições promocionais para utilização
                de seus Serviços, tais como: descontos, reduções temporárias de
                remuneração, bonificações de remunerações, períodos de testes
                e/ou ofertas de brindes, cujas regras para fruição estarão
                disponíveis em seu Site e deverão ser observadas por todos os
                Participantes a partir da contratação dos Serviços.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Na hipótese de o Participante não efetuar o pagamento de
                quaisquer quantias devidas à Plataforma, a We4Science, por si
                e/ou por intermédio de terceiros, poderá se utilizar todas e
                quaisquer medidas judiciais e/ou extrajudiciais, para receber os
                pagamentos devidos, com o acréscimo de atualização monetária e
                dos juros legais, além de multa não-compensatória de 10% (dez
                por cento) sobre o total do débito, estando a Plataforma
                We4Science autorizada, a seu exclusivo critério, a apontar o
                nome do Participante aos órgãos de proteção ao crédito, bem
                como, protestar os títulos que sejam emitidos com base na
                prestação dos Serviços e, ainda, a ajuizar as ações judiciais
                adequadas à satisfação de seus créditos.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>Da Taxa de Administração. </strong>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>A Plataforma</span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>We4Science</strong>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                descontará o percentual de 15% (quinze por cento) sobre o valor
                total arrecadado em cada campanha de arrecadação de doações para
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>PROJETO/PESQUISA</strong>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                cadastrados na Plataforma, o que chamará de
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>
                  <strong>Taxa de Administração</strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>.</span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Sobre os valores totais ou parciais referentes à taxa de
                administração da plataforma, não caberá devolução, estorno ou
                reembolso ao(s) doador(es), ou na forma de repasse ao donatário,
                ainda que o projeto/campanha venha a ser interrompido e/ou
                cancelado a qualquer tempo. Também não haverá reajuste sobre o
                percentual de 15% (quinze por cento) para menos ou para mais,
                após lançada a campanha de doações, ainda que o projeto/campanha
                venha a ser interrompido e/ou cancelado a qualquer tempo, tendo
                alcançado ou não a meta de doações definida originalmente pelo
                usuário/cadastrante.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>
                  <br /> Restrições Específicas aos Participantes&nbsp;da
                  Plataforma We4Science
                </strong>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Os Participantes declaram e reconhece que não poderão:</em>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Infringir estes Termos e/ou qualquer outro contrato ou políticas
                adotadas pela Plataforma We4Science e disponibilizadas aos
                Participantes;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Violar qualquer Lei, Regulamento, Decreto, Portaria, Ordem,
                Decisão e/ou Regulamentação vigente, emitida por qualquer
                autoridade governamental no Brasil;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Infringir os direitos autorais, de patente, de marca comercial,
                de segredo comercial nem outros direitos de propriedade
                intelectual, industrial, de publicidade e/ou confidencialidade,
                seja da Plataforma We4Science e/ou de
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Terceiros</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>;</span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Agir de maneira difamatória, caluniosa, ameaçadora e/ou praticar
                qualquer tipo de assédio na Plataforma;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Fornecer informações falsas, incompletas e/ou incorretas à
                Plataforma e/ou aos outros Participantes, na utilização da
                Plataforma;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Utilizar a Plataforma para a realização de
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                com objetivo de simulação;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Envolver-se em atividades e/ou transações potencialmente
                fraudulentas e/ou suspeitas;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Não cooperar com a Plataforma We4Science em eventuais
                investigações internas e/ou demais solicitações de informações
                acerca de Projetos e/ou Aportes;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Utilizar a Plataforma para fins que, a critério exclusivo e
                julgamento Plataforma We4Science, façam publicidade negativa e,
                consequentemente, prejudicar de qualquer forma a Plataforma.
                Neste caso as campanhas poderão ser interrompidas e/ou
                encerradas a critério exclusivo da Plataforma We4Science;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Utilizar a marca We4Science e/ou de quaisquer empresas de seu
                grupo econômico, fora dos estritos limites que eventualmente lhe
                sejam autorizados;
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Facilitar a disseminação de vírus, cavalos de troia,&nbsp;
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>malware</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>,&nbsp;</span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>worms</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                &nbsp;e/ou outras rotinas de programação de computador que
                tentem ou possam danificar, interromper, corromper, utilizar
                indevidamente, interferir adversamente, interceptar ou
                expropriar fraudulentamente, ou obter acesso sem autorização a
                quaisquer sistemas, dados, informações e/ou Serviços da
                Plataforma We4Science.
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Quaisquer informações, conteúdo e/ou dados inseridos pelos
                Participantes na Plataforma que possam violar a legislação e/ou
                regulamentação aplicável, estes Termos e/ou quaisquer outras
                políticas da Plataforma We4Science, podendo, a Plataforma
                We4Science, a seu exclusivo critério, sem necessidade de prévio
                aviso ao Participante (e até que se esclareça a situação),
                deixar qualquer Projeto fora do âmbito dos Serviços da
                Plataforma, podendo excluir, parcial e/ou integralmente,
                qualquer conteúdo disponibilizado pelo Participante, bem como
                suspender a realização de qualquer Aporte em seu benefício.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O Participante será integralmente responsável perante a
                Plataforma We4Science em razão do descumprimento das condições e
                obrigações estabelecidas nestes Termos.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>Do Prazo e Rescisão</strong>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Estes Termos são celebrados por prazo indeterminado, iniciando
                sua vigência com o aceite, pelo Participante, que ocorrerá no
                momento da abertura de uma Conta, pelo Participante.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Estes termos poderão ser rescindidos, a qualquer tempo, por
                qualquer uma das Partes, sem ônus, mediante utilização dos
                mecanismos disponibilizados pela Plataforma e de acordo com suas
                condições.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Estes Termos serão resolvidos em caso de descumprimento de
                quaisquer das obrigações aqui estabelecidas, bem como pela
                decretação de falência, recuperação judicial, extrajudicial ou
                insolvência civil de qualquer das partes e pela ocorrência de
                caso fortuito ou força maior que impossibilite a prestação dos
                Serviços, total ou parcialmente, por mais de 10 (dez) dias
                consecutivos.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                A&nbsp;rescisão destes Termos, por qualquer motivo, não
                prejudicará o direito da Plataforma We4Science de haver as
                quantias porventura devidas pelos Participantes em razão da
                prestação dos Serviços, nem o de haver a indenização porventura
                devida pelo Participante.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O Participante poderá encerrar sua Conta junto à Plataforma
                We4Science a qualquer momento, observadas
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>
                  <strong>
                    as regras de encerramento de cadastro aqui previstas
                  </strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                , seguindo as instruções contidas no Site.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Todos os recursos depositados em uma Conta deverão ser
                retirados, por meio de "requisição de saque", antes da
                efetivação do seu respectivo encerramento. Além disso, todos os
                débitos eventualmente existentes junto da Plataforma We4Science
                deverão ser quitados antes do encerramento da conta.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Com o intuito de manter a transparência dos valores arrecadados
                na Plataforma, mesmo em casos de exclusão de contas e/ou
                encerramento de Campanhas, todos os dados relativos aos Aportes
                recebidos pelo Projeto serão preservados na Plataforma
                We4Science, mesmo que não seja mais permitido aos Participantes
                realizar
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>novos</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Aportes</em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                por meio da Plataforma We4Science.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>
                  Sobre Cancelamento ou Congelamento do Perfil de Usuário.
                </strong>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Todo usuário é livre para cancelar seu perfil a qualquer momento
                em nossa plataforma, por meio de mecanismos próprios para essa
                finalidade disponíveis em seu perfil. No entanto, caso haja
                pendências financeiras e/ou burocráticas, estas deverão ser
                sanadas antes do cancelamento do perfil.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <strong>Disposições Gerais e Transitórias</strong>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>Envio de Informações.&nbsp;</em>
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                O Participante declara e reconhece expressamente que aceita que
                a Plataforma We4Science e/ou qualquer de seus parceiros poderá
                enviar mensagens de e-mail de caráter informativo, referentes a
                comunicações específicas referentes aos seus Serviços, bem como,
                outras quaisquer outras mensagens de e-mail de natureza
                comercial e/ou de marketing, tais como ofertas dos parceiros
                Plataforma We4Science e novidades da Plataforma, novas Campanhas
                e novos Projetos. Caso o Participante não deseje mais receber
                mensagens, este deverá solicitar o cancelamento do seu envio,
                clicando no local específico na mensagem enviada pela Plataforma
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                As disposições destes Termos, assim como as de seus anexos,
                consideradas partes integrantes e complementares deste
                instrumento, refletem a integridade dos entendimentos e acordos
                entre as Partes.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Estes Termos são celebrados em caráter irrevogável, e suas
                obrigações são legais, válidas e vinculam as Partes, seus
                sucessores e herdeiros, a qualquer título e é exequível de
                acordo com seus respectivos termos.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>
                  <strong>Última atualização: </strong>
                </em>
              </span>
              <span style={{ fontFamily: "Arial, serif" }}>
                <em>
                  <strong>20/01/2021</strong>
                </em>
              </span>
            </p>
          </div>
        </Container>
      </Fade>
    </>
  );
}

export default moneyTerms;
