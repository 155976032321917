import React, { useState } from "react";

//Libraries
import { Bounce } from "react-reveal";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";

// Styled Components
import { Wrapper, Content, Header, RecoverContent, Button } from "./styles";

import securityImg from "../../assets/images/security.svg";
import { FaLock } from "react-icons/fa";
import { Form, Input } from "@rocketseat/unform";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function Recover() {
  const [buttonPeople, setButtonPeople] = useState(() => true);
  const [buttonCompany, setButtonCompany] = useState(() => false);
  const [buttonScientist, setButtonScientist] = useState(() => false);

  function handlePeopleClick() {
    if (buttonPeople === false) {
      setButtonPeople(!buttonPeople);

      if (buttonCompany === true) {
        setButtonCompany(!buttonCompany);
      }
      if (buttonScientist === true) {
        setButtonScientist(!buttonScientist);
      }
    }
  }

  function handleCompanyClick() {
    if (buttonCompany === false) {
      setButtonCompany(!buttonCompany);

      if (buttonPeople === true) {
        setButtonPeople(!buttonPeople);
      }
      if (buttonScientist === true) {
        setButtonScientist(!buttonScientist);
      }
    }
  }

  function handleScientistClick() {
    if (buttonScientist === false) {
      setButtonScientist(!buttonScientist);

      if (buttonPeople === true) {
        setButtonPeople(!buttonPeople);
      }
      if (buttonCompany === true) {
        setButtonCompany(!buttonCompany);
      }
    }
  }

  const phoneRegex =
    /^1\d\d(\d\d)?$|^0800 ?\d{3} ?\d{4}$|^(\(0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d\) ?|0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d[ .-]?)?(9|9[ .-])?[2-9]\d{3}[ .-]?\d{4}$/gm;

  const schemaReg = Yup.object().shape({
    email: Yup.string()
      .email("Insira um e-mail válido")
      .required("O e-mail é obrigatório"),
    phone: Yup.string()
      .required("O Telefone é obrigatório")
      .matches(phoneRegex, "Formato de telefone inválida"),
    password: Yup.string().required("A senha é obrigatória"),
    confirmPassword: Yup.string().when("password", (password, field) =>
      password
        ? field
            .required("Confirmação de senha Obrigatória")
            .oneOf([Yup.ref("password")], "Senhas não batem")
        : field
    ),
  });

  const history = useHistory();

  async function handleSubmit(data) {
    if (buttonPeople) {
      try {
        await api.put("recover-pass-supporter", data);
        toast.success("Senha recuperada!");

        setTimeout(() => {
          history.push("register");
        }, 500);
      } catch (error) {
        toast.error("Erro ao recuperar senha!");
      }
    } else if (buttonCompany) {
      try {
        await api.put("recover-pass-company", data);
        toast.success("Senha recuperada!");
        setTimeout(() => {
          history.push("register");
        }, 500);
      } catch (error) {
        toast.error("Erro ao recuperar senha!");
      }
    } else if (buttonScientist) {
      try {
        await api.put("recover-pass-scientist", data);
        toast.success("Senha recuperada!");
        setTimeout(() => {
          history.push("register");
        }, 500);
      } catch (error) {
        toast.error("Erro ao recuperar senha!");
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Recuperar Senha</title>
      </Helmet>
      <Bounce bottom duration={1600} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section id="second-content-header">
                <div className="header-content">
                  <FaLock color="#fff" alt="" />
                  <p id="title">RECUPERAR SENHA</p>
                </div>
                <img id="img-header" src={securityImg} alt="" />
              </section>
            </Header>
            <RecoverContent>
              <Form schema={schemaReg} onSubmit={handleSubmit}>
                <hr />
                <div className="attention">
                  <p>
                    <strong>ATENÇÃO</strong>: Antes de alterar sua senha, marque
                    o tipo de Perfil que está usando
                  </p>
                </div>

                <div className="buttons">
                  <p id="text-buttons">Perfil</p>
                  <Button
                    type="button"
                    active={buttonPeople}
                    onClick={handlePeopleClick}
                  >
                    Apoiador / Visitante
                  </Button>
                  <Button
                    type="button"
                    active={buttonCompany}
                    onClick={handleCompanyClick}
                  >
                    Apoiador Empresarial / Desafiante
                  </Button>
                  <Button
                    type="button"
                    active={buttonScientist}
                    onClick={handleScientistClick}
                  >
                    Cientista / Pesquisador
                  </Button>
                </div>

                <p id="title-form" style={{ marginTop: 20 }}>
                  Dados pessoais
                </p>

                <div className="inputs">
                  <div className="input-content">
                    <p>Email</p>
                    <Input name="email" placeholder="Email:" />
                  </div>
                  <div className="input-content">
                    <p>Telefone</p>
                    <Input name="phone" placeholder="Telefone:" />
                  </div>
                  <div className="break-inputs">
                    <div className="input-content">
                      <p>Nova Senha</p>
                      <Input
                        name="password"
                        placeholder="Nova Senha:"
                        type="password"
                      />
                    </div>
                    <div className="input-content">
                      <p>Confirmar nova Senha</p>
                      <Input
                        name="confirmPassword"
                        placeholder="Confirmar nova Senha:"
                        type="password"
                      />
                    </div>
                    <button id="btn-submit" type="submit">
                      Recuperar
                    </button>
                  </div>
                </div>

                <div className="obs">
                  <p>
                    Caso você não se lembre dos seus dados, solicite ao Suporte
                    pelo
                  </p>
                  <button
                    type="button"
                    onClick={() => history.push("contact?&type=recover")}
                  >
                    Fale Conosco
                  </button>
                </div>
              </Form>
            </RecoverContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}
