import React from "react";

import { Container } from "./styles";
import First from "./containers/First";
import Second from "./containers/Second";
import Third from "./containers/Third";

function Challenge({ data, special = false }) {
  
  return (
    <Container special={special}>
      <First image={data.first && data.first.url} />
      <Second
        title={data.title}
        category={data.category}
        company={data.company.name}
        conclusion={data.conclusion}
        description={data.description}
        premium={data.premium}
        specialPremium={
          data.special_premium !== null &&
            data.special_premium.trim() !== "" &&
            data.special_premium
            ? true
            : false
        }
      />
      <Third id={data.id} />
    </Container>
  );
}

export default Challenge;
