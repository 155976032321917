import { useEffect, useState } from "react";
import api from "../services/api";

export default function useChallenges() {
  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    const loadChallenges = async () => {
      const { data } = await api.get("/challenges");

      if (data !== null && data.length !== 0) {
        setChallenges(data);
      }
    };
    loadChallenges();
  }, []);

  return {challenges};
}
