export function updateProfileRequest(data) {
    return {
        type: '@user/UPDATE_PROFILE_REQUEST',
        payload: { data },
    }
}

export function updateProfileSuccess(profile) {
    return {
        type: '@user/UPDATE_PROFILE_SUCCESS',
        payload: { profile },
    }
}

export function updateProfileFailure() {
    return {
        type: '@user/UPDATE_PROFILE_REQUEST',
    }
}

export function creditRequest(data) {
    return {
        type: '@user/CREDIT_REQUEST',
        payload: { data },
    }
}

export function creditSuccess(profile) {
    return {
        type: '@user/CREDIT_SUCCESS',
        payload: { profile },
    }
}

export function creditFailure() {
    return {
        type: '@user/CREDIT_REQUEST',
    }
}

export function socialNetworksRequest(data) {
    return {
        type: '@user/CREDIT_SOCIAL_REQUEST',
        payload: { data },
    }
}

export function socialNetworksSuccess(profile) {
    return {
        type: '@user/CREDIT_SOCIAL_SUCCESS',
        payload: { profile },
    }
}

export function socialNetworksFailure() {
    return {
        type: '@user/CREDIT_SOCIAL_REQUEST',
    }
}

export function debitRequest(data) {
    return {
        type: '@user/DEBIT_REQUEST',
        payload: { data },
    }
}

export function debitSuccess(profile) {
    return {
        type: '@user/DEBIT_SUCCESS',
        payload: { profile },
    }
}

export function debitFailure() {
    return {
        type: '@user/DEBIT_REQUEST',
    }
}