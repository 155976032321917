import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished'

const focus = keyframes `
    0% {
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
    }
`

export const Wrapper = styled.div `
    height: 100%;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    
    font-family: GothamPro;
    @media only screen and (max-width:768px){
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const Content = styled.div `
    width:1366px;
    height: 1600px;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    @media only screen and (max-width:768px){
        width:100vw;
        height: 1850px;
    }
`

export const Header = styled.header `
    width:100%;
    height: 462px;

    background:#227af1;

    display:flex;
    flex-direction:column;

    margin-bottom:97.6px;

    /* Webkits */
    display:-webkit-box;
    display:-ms-flexbox;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    /* Webkits */

    #second-content-header{
        width:100%;
        height: 347px;

        display:flex;
        justify-content:space-between;

        /* Webkits */
        display:-webkit-box;
        display:-ms-flexbox;
        /* Webkits */

        .header-content{
            width: 402px;
            
            display:flex;
            flex-direction:column;
            align-items:flex-start;

            margin-left:139px;
            margin-top:48px;

            /* Webkits */
            display:-webkit-box;
            display:-ms-flexbox;
            -webkit-box-orient:vertical;
            -webkit-box-direction:normal;
            -ms-flex-direction:column;
            -webkit-box-align:start;
            -ms-flex-align:start;
            /* Webkits */

            svg{
                width: 56.4px;
                height: 62.8px;

                margin-bottom:8px;
            }

            #title{
                font-size: 50px;
                font-weight: 500;
                line-height: 1.4;
                text-align: left;

                color: #ffffff;

                margin-bottom:8px;
            }
        }

        #img-header{
            width: 675px;
            height: 497.6px;
        }
    }  

    @media only screen and (max-width: 849px) {
        padding-top:10px;
    }

    @media only screen and (max-width:768px){
        width:100vw;
        height:50vh;

        #second-content-header{
            width:100%;
            .header-content{
                width:100%;
                margin-left:40px;

                svg{
                    width: 36.4px;
                    height: 32.8px;
                }

                #title{
                    width:75%;
                    
                    font-size:1.2rem;
                    word-wrap:break-word;
                }
            }
        }

        #img-header{
            display:none;
            margin-left:0;
        }
    }
`

export const RecoverContent = styled.section `
    width:100%;
    height: 808px;

    padding-top:50px;

    display:flex;
    flex-direction:column;
    align-items:center;

    /* Webkits */
    display:-webkit-box;
    display:-ms-flexbox;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    /* Webkits */

    .attention{
        width:100%;
        margin-top:50px;
        margin-bottom:25px;

        display:flex;
        justify-content:center;

        >p{
            >strong{
                color:#227af1;
            }
        }
    }

    form{
        margin-left:10%;
        #title-form,#text-buttons{
            font-size: 18px;
            margin-bottom:20px;
            line-height: 1.5;
            text-align: left;
            color: #7f7fb9;
        }
        #text-buttons{
            font-size:16px;
        }

        hr{
            margin-top:20px;
            margin-bottom:20px;
            width:90%;
            opacity:0.56;
            height: 0.5px;
            border: dashed #eee 1px;

        }

        .buttons{
            
        }
     
        .inputs{
            margin-top:30px;

            width:100%;

            display:flex;
            flex-wrap:wrap;
            flex-direction:row;

           
        }

        .break-inputs{
            display:flex;
            flex-direction:row;
        }

        input{
            width: 352px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.4px #7f7ebe;
            margin-right:16px;
            margin-bottom:20px;

            padding-left:33.6px;

            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
            
            &::-moz-placeholder{
                opacity: 1;
            }

            &::placeholder{
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;

                letter-spacing: normal;
                text-align: left;
                color: #7f7fb9;
            }
        }

        .input-content{
            display:flex;
            flex-direction:column;

            p{
                margin-bottom:10px;
                margin-left:10px;
                font-weight: bold;
                font-style:italic;
                font-family:GothamPro;
                font-size:14px;
                color: #7f7fb9;
            }

            span {
            
                text-align:start;
                color: ${darken(0.1, '#227af1')};
                margin-top: 1px;
                margin-bottom:20px;
                margin-left:5px;
                font-weight: bold;
                font-style:italic;
                font-family:GothamProRegular;
                font-size:14px;

                animation: ${focus} 0.7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

            }
        }
        #btn-submit{
            width: 142px;
            height: 60px;
            border-radius: 30px;
            background-color: #227af1;

            font-size: 15px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #FFF;

            transition: all .3s ease-in-out 0s;
            &:hover{
                background-color:${darken(0.1, '#227af1')};
            }
        }
    
        .obs{
            margin-top:30px;
            display:flex;
            align-items:center;

            button{
                width: 142px;
                height: 60px;
                border-radius: 30px;
                background-color: #227af1;

                margin-left:20px;

                font-size: 15px;
                font-weight: 700;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #FFF;

            transition: all .3s ease-in-out 0s;
            &:hover{
                background-color:${darken(0.1, '#227af1')};
            }
            }
        }
    }
    @media only screen and (max-width:768px){
        width:100vw;
        height: 1318px;
        padding-bottom:50px;
        form{
            width:90%;
            margin-left:5%;

            .inputs{
                margin:0;
                margin-top:6vh;
                flex-direction:column;

                input{
                    width:100%;
                    margin-right:0;
                }
                
            }
            .break-inputs{
                flex-direction:column;
            }
        }
    }
`;

export const Button = styled.button `
    width: 352px;
    height: 60px;
    border-radius: 30px;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;

    margin-right:16px;

    background-color:#FFF;
    color: #227af1;
    border:2px solid #227af1;

    transition: all .3s ease-in-out 0s;

    &:hover{
        background-color:${darken(0.1, '#FFF')};
    }

    ${props => props.active && css`
        color:#fff;
        border:none;

        background-color:#227af1;

        &:hover{
            background-color:${darken(0.1,'#227af1')};
        }
    `}
    @media only screen and (max-width:768px){
        width:85vw;
        font-size:0.8rem !important;

        margin-bottom:20px;
        
    }
`