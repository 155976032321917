import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { darken } from "polished";

import { Container } from "../../styles/global";

export const Nav = styled.nav`
  padding-top: 30px;
  padding-bottom: 30px;

  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 999;
`;

export const NavContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  ${Container}
`;

export const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-self: flex-start;
  cursor: pointer;
  > img {
    max-width: 236.8px;
    margin-right: 5px;
  }

  @media screen and (min-width: 900px) and (max-width: 1189px) {
    > img {
      max-width: 170.8px;
    }
  }

  @media screen and (min-width: 849px) and (max-width: 900px) {
    > img {
      max-width: 130.8px;
    }
  }

  @media screen and (max-width: 400px) {
    > img {
      max-width: 70%;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;
  transition: all 0.5s ease;

  @media screen and (max-width: 849px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 100%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 849px) {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 90px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #227af1;
  }
`;

export const NavItem = styled.li`
  height: 80px;

  @media screen and (max-with: 849px) {
    width: 100%;
    &:hover {
      border: none;
    }
  }
`;

export const NavLinks = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  height: 100%;

  font-size: 13.7px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;

  color: #fff;
  transition: all 0.45s ease-in-out 0s;

  -webkit-transition: all 0.45s ease-in-out 0s;
  -o-transition: all 0.45s ease-in-out 0s;

  &:hover {
    color: ${darken(0.1, "#3242d0")};
  }
  @media screen and (max-width: 1189px) {
    font-size: 12px;
  }

  @media screen and (min-width: 849px) and (max-width: 1038px) {
    font-size: 11px;
  }

  @media screen and (max-width: 849px) {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    height: 50%;
    display: table;

    font-size: 17px;

    line-height: 1.2;

    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 849px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

export const ButtonProfile = styled.button`
  background: transparent;

  width: 185px;
  height: 49px;

  margin-left: 60px;

  border-radius: 30px;
  border: solid 2px #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;

  position: relative;
  z-index: 1;

  ${(props) =>
    props.company &&
    css`
      background-color: #add948;
    `}

  ${(props) =>
    props.scientist &&
    css`
      background-color: #ffcc43;
    `} 
    ${(props) =>
    props.support &&
    css`
      background-color: #227af1;
    `}

    p {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: GothamProRegular;
    margin-top: 6.2px;
  }

  img {
    height: 18.5px;
    margin-right: 13.6px;
    width: 18.5px;
  }

  #img-login {
    height: 24.5px;
    margin-right: 13.6px;
    width: 24.5px;
  }

  &:hover {
    ${(props) =>
      props.company &&
      css`
        background-color: ${darken(0.1, "#add948")};
      `}

    ${(props) =>
      props.scientist &&
      css`
        background-color: ${darken(0.1, "#FFCC43")};
      `} 
        ${(props) =>
      props.support &&
      css`
        background-color: ${darken(0.1, "#227AF1")};
      `}
  }

  @media screen and (max-width: 1190px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 1038px) {
    width: 115px;
    img {
      display: none;
      visibility: hidden;
    }
    p {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 849px) {
    width: 185px;
    margin-left: 0;
    img {
      display: inline-block;
      visibility: visible;
    }
    p {
      font-size: 14px;
    }
  }
`;
