import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import iconSupporters from "../../../../assets/icons/Donors/icon-supporters.svg";
import headerImg from "../../../../assets/images/Donors/first.svg";

import NavBar from "../../../../components/SubHeader";

function Header() {
  return (
    <Wrapper>
      <NavBar />
      <Container>
        <Row margin>
          <Column>
            <img src={iconSupporters} alt="" />
            <h1>Apoiadores</h1>
            <p id="text-subcontent">
              Juntos podemos acelerar o desenvolvimento científico e superar
              algumas das maiores ameaças ao planeta e a nossa civilização!
            </p>
          </Column>
          <Column>
            <ImgWrapper>
              <Img src={headerImg} alt="" />
            </ImgWrapper>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Header;
