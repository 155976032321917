import React from "react";

import { Container, WhuffieCompany, Donation, Collaboration } from "./styles";

import atomProject from "../../../../assets/icons/Common/atom-project.svg";
import donationIcon from "../../../../assets/icons/Common/donation-icon-2.svg";

import InfoDonation from "../InfoDonation";
import ShareProject from "../ShareProject";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { store } from "../../../../store";
import { FormattedNumber, IntlProvider } from "react-intl";

function Aside({ project, loaded, whuffies }) {
  const history = useHistory();
  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;
  function checkIsAuth(type) {
    if (!!token) {
      if (
        perfil === "Cientista/Pesquisador" &&
        profile.id === project.scientist_id &&
        type === "colaboração"
      ) {
        toast.error("Você é o dono do projeto!");
      } else {
        type === "colaboração"
          ? setTimeout(function () {
              history.push(`/collaboration/${project && project.id}`);
            }, 50)
          : project.current_value >= project.value_goal
          ? toast.error("O projeto já atingiu a meta!")
          : setTimeout(function () {
              history.push(`/payment-project/${project && project.id}`);
            }, 50);
      }
    } else {
      toast.error(`É necessário estar logado para fazer uma ${type}!`);
      setTimeout(function () {
        history.push("/register");
      }, 2300);
    }
  }
  return (
    <Container>
      <InfoDonation data={project} />
      <Collaboration>
        <div className="content-1">
          <p>
            Você também pode ajudar este projeto de outras forma, clique aqui e
            descubra como:
          </p>
        </div>
        <div className="content-3">
          <img src={atomProject} alt="" />
          <p>
            <button
              id="btn-collaboration"
              onClick={() => checkIsAuth("colaboração")}
            >
              Colaborar com o projeto
            </button>
          </p>
        </div>
      </Collaboration>
      {loaded.length > 0 && (
        <Donation>
          <div id="title-donation">
            <img src={donationIcon} alt="" />
            <p id="text-title-donation"> Ranking de Doações </p>
          </div>
          <section> </section>
          <ul className="list">
            {loaded.map((preview) => (
              <li id="list-people-donation" key={preview.id}>
                <div id="position-people">
                  <p> {loaded.indexOf(preview) + 1} º </p>
                </div>
                <div id="data-people">
                  <p> {preview.name} </p>
                  <div>
                    <p id="cash"> {preview.value} </p> <p id="dot"> Wuffys </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Donation>
      )}
      <ShareProject
        id={project && project.id}
        name={project && project.name}
        description={project && project.summary}
      />
      {project && !project.special && (
        <WhuffieCompany>
          <p> Whuffie(s) da Instituição </p> <section> </section>
          <div className="values">
            <p id="values-text">
              A instituição já acumulou em todos seus projetos:
            </p>
            <IntlProvider locale="pt-BR">
              <p id="values-cash">
                {" "}
                W${" "}
                <FormattedNumber
                  minimumFractionDigits={2}
                  value={whuffies}
                />{" "}
              </p>
            </IntlProvider>
          </div>
        </WhuffieCompany>
      )}
    </Container>
  );
}

export default Aside;
