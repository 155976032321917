import React from "react";

import { Wrapper, Column, Row, RowTwo, RowThree,Img } from "./styles";
import { Container } from "../../../../styles/global";
import Partners from "../../../Home/containers/Partners";
import useSupporters from "../../../../hooks/useSupporters";
import { useHistory } from "react-router-dom";

import iconPartners from "../../../../assets/icons/Scientists/partners.svg";
import fourthImg from "../../../../assets/images/Scientists/fourth.svg";

function Third() {
  const { images } = useSupporters();
  const history = useHistory();

  return (
    <Wrapper>
      <Container margin>
        <Row>
          <Column>
            <Img src={fourthImg} alt="" />
          </Column>
          <Column larger>
            <img id="icon" src={iconPartners} alt="" />
            <p id="title"> parceiros </p>
            <p id="text">
              Conheça algumas das instituições, universidades, cientistas,
              personalidades e empresas que já apoiam a Ciência e junte-se você
              também à{" "}
              <strong style={{ fontStyle: "italic" }}>
                Iniciativa We4Science – Todos pela Ciência!
              </strong>
            </p>
          </Column>
        </Row>
        <hr />
        <RowTwo>
          <Partners data={images} />
        </RowTwo>
        <RowThree>
          <button id="btn-partner" onClick={() => history.push("contact")}>
            Torne-se um Parceiro!
          </button>
        </RowThree>
      </Container>
    </Wrapper>
  );
}

export default Third;
