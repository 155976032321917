import React, { useEffect, useMemo, useState } from "react";

//Libraries
import { Link } from "react-router-dom";
import { Bounce } from "react-reveal";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";

import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  InfoContent,
  New,
  NewAside,
  SocialNetworks,
} from "./styles";

// External Icons
import {
  FaWhatsapp,
  FaTelegramPlane,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

// Icons
import newsPaperAside from "../../assets/icons/Blog/jornal-sidebar.svg";
import iconPlay from "../../assets/icons/Blog/play.svg";
import iconEmail from "../../assets/icons/Blog/email-icon.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";

// Images
import shadowImg from "../../assets/images/Blog/shadow.svg";
import imgHeader from "../../assets/images/Blog/header-img.svg";

import { format, parseISO } from "date-fns";
import { pt } from "date-fns/esm/locale";
import api from "../../services/api";
import { store } from "../../store";

import NewsletterCard from "../Blog/components/NewsletterCard";
import ArticleCard from "../Blog/components/ArticleCard";
import { Helmet } from "react-helmet";

function InfoBlog({ match }) {
  const { perfil, signed } = store.getState().auth;
  const { profile } = store.getState().user;

  const [news, setNews] = useState();
  const [newsSide, setNewsSide] = useState([]);
  const [others, setOthers] = useState([]);
  const [subscriveNewsletter, setSubscribeNewsletter] = useState(false);

  const id = useMemo(
    () => ({
      value: match.params.id,
    }),
    [match.params.id]
  );
  useEffect(() => {
    async function loadingProduct() {
      const res = await api.get(`new/${id.value}`);
      setNews({ ...res.data });
    }
    loadingProduct();
  }, [id]);

  useEffect(() => {
    async function checkNewsletter() {
      if (signed) {
        const type =
          perfil === "Apoiador/Visitante"
            ? 1
            : perfil === "Apoiador Empresarial/Desafiante"
            ? 2
            : 3;

        try {
          const { data } = await api.get(
            `/check-newsletter/${profile && profile.id}/${type}`
          );
          setSubscribeNewsletter(data.register);
        } catch (e) {
          console.log("Erro newsletter");
        }
      }
    }
    checkNewsletter();
  }, [perfil, profile, signed]);

  useEffect(() => {
    async function loadingChallenge() {
      try {
        const res = await api.get(`news-side/${id.value}`);

        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setNewsSide(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingChallenge();
  }, [id]);

  useEffect(() => {
    async function loadingChallenge() {
      try {
        const res = await api.get(`news-others/${id.value}`);

        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setOthers(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingChallenge();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{news ? news.title : "Radar"}</title>
      </Helmet>
      <Bounce bottom duration={1600} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section className="second-content-header">
                <img id="img-header" src={imgHeader} alt="" />
              </section>
            </Header>

            <InfoContent>
              <div className="first-content-about">
                <div className="content-image">
                  <img id="first-img" src={news && news.first.url} alt="" />
                  <img id="shadow" src={shadowImg} alt="" />
                </div>

                <div className="info-content">
                  <div className="titles">
                    <p id="day">
                      {news &&
                        format(parseISO(news.createdAt), "dd'/'MM'/'yy", {
                          locale: pt,
                        })}
                    </p>
                    <p id="title">{news && news.title}</p>
                    <p id="text">{news && news.introduction}</p>
                  </div>
                </div>

                <SocialNetworks>
                  <div className="content">
                    <p>Compartilhar esta notícia</p>
                    <div id="icons">
                      <WhatsappShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                        windowPosition={"screenCenter"}
                      >
                        <FaWhatsapp size={25} />
                      </WhatsappShareButton>
                      <TelegramShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                        windowPosition={"screenCenter"}
                      >
                        <FaTelegramPlane size={25} />
                      </TelegramShareButton>
                      <TwitterShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                        windowPosition={"screenCenter"}
                      >
                        <FaTwitter size={25} />
                      </TwitterShareButton>

                      <FacebookShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                        windowPosition={"screenCenter"}
                      >
                        <FaFacebookF size={22} />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                        windowPosition={"screenCenter"}
                        summary={news && news.summary}
                        source={`https://www.we4science.com/information/${id}`}
                      >
                        <FaLinkedinIn size={24} />
                      </LinkedinShareButton>
                      <EmailShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                        windowPosition={"screenCenter"}
                        body={`Conheça esta novidade: ${
                          news && news.title
                        } da We4Science\n${news && news.summary}\n Acesse em:`}
                      >
                        <img id="icon-email" src={iconEmail} alt="" />
                      </EmailShareButton>
                    </div>
                  </div>
                  <hr />
                </SocialNetworks>

                <div className="content">
                  <p id="text">{news && news.paragraph_one}</p>
                  <img id="second-img" src={news && news.second.url} alt="" />
                  <p id="text">{news && news.paragraph_two}</p>
                </div>

                <SocialNetworks>
                  <div className="content">
                    <p>Compartilhar esta notícia</p>
                    <div id="icons">
                      <WhatsappShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                      >
                        <FaWhatsapp size={25} />
                      </WhatsappShareButton>
                      <TelegramShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                      >
                        <FaTelegramPlane size={25} />
                      </TelegramShareButton>
                      <TwitterShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                      >
                        <FaTwitter size={25} />
                      </TwitterShareButton>

                      <FacebookShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                      >
                        <FaFacebookF size={22} />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                      >
                        <FaLinkedinIn size={24} />
                      </LinkedinShareButton>
                      <EmailShareButton
                        className="item"
                        title={`Notícia ${news && news.title} da We4Science`}
                        url={`https://we4science.com/information/${
                          news && news.id
                        }`}
                        separator=" - "
                      >
                        <img id="icon-email" src={iconEmail} alt="" />
                      </EmailShareButton>
                    </div>
                  </div>
                  <hr />
                </SocialNetworks>

                {others.length !== 0 && (
                  <>
                    <div className="title-news">
                      <img src={newsPaperAside} alt="" />
                      <p>Publicações relacionadas</p>
                    </div>

                    <div className="news">
                      {others.map((preview) => (
                        <New key={preview.id}>
                          <div className="content-1">
                            <img src={preview.first.url} alt="" />
                          </div>
                          <div className="content-2">
                            <p id="day">{preview.formattedDate}</p>
                            <p id="title">{preview.title}</p>
                            <p id="text">{preview.summary}</p>
                          </div>
                          <div className="content-3">
                            <img src={atomProject} alt="" />
                            <p>
                              <Link to={`information/${preview.id}`}>
                                Ler a notícia
                              </Link>
                            </p>
                          </div>
                        </New>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <aside className="second-content-about">
                <div className="news">
                  <div className="title">
                    <img src={newsPaperAside} alt="" />
                    <p>Notícias Destaque</p>
                  </div>
                  <div className="content">
                    {newsSide &&
                      newsSide.length !== 0 &&
                      newsSide.map((preview) => (
                        <NewAside key={preview.id}>
                          <p id="day">{preview.formattedDate}</p>
                          <p id="title">{preview.title}</p>
                          <p id="text">{preview.summary}</p>
                          <div>
                            <img src={iconPlay} alt="" />
                            <Link to={`/information/${preview.id}`}>
                              Ler a notícia
                            </Link>
                          </div>
                          <section></section>
                        </NewAside>
                      ))}
                  </div>
                </div>

                <NewsletterCard subscriveNewsletter={subscriveNewsletter} />

                <ArticleCard />
              </aside>
            </InfoContent>

            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default InfoBlog;
