import React, { useRef, useState, useEffect, useCallback } from "react";

// Libraries
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Bounce, Fade } from "react-reveal";
import { Form, Input, Textarea } from "@rocketseat/unform";
import { toast } from "react-toastify";
import Scrollbars from "react-custom-scrollbars";
import { CircleProgress } from "react-gradient-progress";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import { FormattedNumber, IntlProvider } from "react-intl";
import iconPerfil from "../../assets/icons/Profile/icon-pencil.svg";
// Reducer Modules
import { signOut } from "../../store/modules/auth/actions";

//api
import api from "../../services/api";

//components
import Pagination from "../../components/Pagination";
import Project from "../../components/Project";
import DeleteProjectComponent from "../../components/NewProject";
import Challenge from "../../components/Challenge";
import Product from "../../components/Product";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  ProfileContent,
  MenuList,
  ContactComponent,
  DonationsComponent,
  ChallengeComponent,
  CollaborationsComponent,
  ShoppingComponent,
  Contact,
  BoxLabel,
  New,
  NewBlogComponent,
  Footer,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  ButtonDonation,
  Cosmos,
  ProjectSecond,
  GoalsComponent,
  ProjectGoals,
  ButtonAccept,
  Notification,
  CategoriesComponent,
  Category,
  ArticleComponent,
  Article,
  ButtonContacts,
  RequestComponent,
  ProjectsComponent,
  NormalProject,
  SearchComponent,
  ChallengesNotAprovedComponent,
  DeletedUsersComponent,
  DeletedUser,
  SupportComponent,
  NotificationsComponent,
  UsersComponents,
  MyCategories,
  UserComponent,
  CreditWhuffieComponent,
  ReportsComponent,
  ButtonCSV,
  BoxProfile,
  DeleteProjectsContainer,
  DeleteChallengesContainer,
  DeleteProjectWrapper,
  DeleteChallengeWrapper,
  ProjectsHomeContainer,
  ProjectHomeWrapper,
  ButtonSelectProjectHomeWrapper,
} from "./styles";

import logo from "../../assets/images/Common/logo.svg";
import iconCalendarPurple from "../../assets/icons/Project/calendar-purple.svg";
import newsPaperAside from "../../assets/icons/Blog/jornal-sidebar.svg";

import {
  FaCheck,
  FaPaypal,
  FaEnvelope,
  FaLink,
  FaYoutube,
  FaLockOpen,
  FaTrashAlt,
} from "react-icons/fa";

import { BsFileEarmarkSpreadsheet } from "react-icons/bs";

import iconComplaintChallenges from "../../assets/icons/Challenges/first.svg";
import iconComplaintProducts from "../../assets/icons/Common/store-2.svg";
import iconComplaintProjects from "../../assets/icons/Common/projects.svg";

import iconComplaintChallengesBlue from "../../assets/icons/Challenges/firstBlue.svg";
import iconComplaintProductsBlue from "../../assets/icons/Common/store-2-blue.svg";
import iconComplaintProjectsBlue from "../../assets/icons/Common/projects-2.svg";
//import iconHeader from "../../assets/icons/Challenges/first.svg";
import iconCompanyProject from "../../assets/icons/Payment/company.svg";
import iconCheck from "../../assets/icons/Payment/check.svg";

import iconCompanyNormalProject from "../../assets/icons/Projects/company.svg";
import iconCheckNormal from "../../assets/icons/Projects/check.svg";

import iconCheckBlue from "../../assets/icons/Projects/check.svg";

import iconUser from "../../assets/icons/Projects/user.svg";
import iconId from "../../assets/icons/Project/idBlue.svg";

import iconClips from "../../assets/icons/Profile/clips.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";
import iconDrop from "../../assets/icons/Common/drop-reg.svg";
import id from "../../assets/icons/Payment/id.svg";
import user from "../../assets/icons/Payment/user.svg";
import userBlue from "../../assets/icons/Donors/user.svg";

import iconSupporter from "../../assets/icons/Profile/profiles/support.svg";
import iconScientists from "../../assets/icons/Profile/profiles/scientist.svg";
import iconCompany from "../../assets/icons/Profile/profiles/company.svg";

import footer from "../../assets/icons/footer/logo.svg";
import { Helmet } from "react-helmet";

function SupporterProfile() {
  const [contact, setContact] = useState(true);
  const [supporters, setSupporters] = useState(() => false);
  const [blog, setBlog] = useState(() => false);
  const [collaborations, setCollaborations] = useState(() => false);
  const [historic, setHistoric] = useState(() => false);
  const [shopping, setShopping] = useState(() => false);
  const [newBlog, setNewBlog] = useState(() => false);
  const [editBlog, setEditBlog] = useState(() => false);
  const [goals, setGoals] = useState(() => false);
  const [categories, setCategories] = useState(false);
  const [articles, setArticles] = useState(false);
  const [texts, setTexts] = useState(false);
  const [requests, setRequests] = useState(false);
  const [projects, setProjects] = useState(false);
  const [challenges, setChallenges] = useState(false);
  const [searchUser, setSearchUser] = useState(false);
  const [deletedUsers, setDeletedUsers] = useState(false);
  const [contactSupporters, setContactSupporters] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [users, setUsers] = useState(false);
  const [creditWhuffie, setCreditWhuffie] = useState(false);
  const [reports, setReports] = useState(false);

  const [deleteProjects, setDeleteProjects] = useState(false);
  const [deleteChalenges, setDeleteChalenges] = useState(false);

  const [projectsHome, setProjectsHome] = useState(false);

  const [complaintChallenge, setComplaintChallenge] = useState([]);
  const [complaintProject, setComplaintProject] = useState([]);
  const [complaintProduct, setComplaintProduct] = useState([]);
  const [arrCategories, setArrCategories] = useState([]);
  const [arrArticles, setArrArticles] = useState([]);
  const [arrProjects, setArrProjects] = useState([]);
  const [arrProjectsAnalysis, setArrProjectsAnalysis] = useState([]);
  const [notAprovedChallenges, setNotAprovedChallenges] = useState([]);
  const [deletedUsersArr, setDeletedUsersArr] = useState([]);
  const [support, setSupport] = useState([]);
  const [arrRecover, setArrRecover] = useState([]);
  const [myRegisterCategories, setMyRegisterCategories] = useState([]);

  const [allSupporters, setAllSupporters] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allScientists, setAllScientists] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allCollaborations, setAllCollaborations] = useState([]);
  const [allChallenges, setAllChallenges] = useState([]);
  const [allProjectsHome, setAllProjectsHome] = useState([]);

  const [payments, setPayments] = useState([]);
  const [cosmos, setCosmos] = useState([]);

  useEffect(() => {
    const loadAllSupporters = async () => {
      const res = await api.get("all-supporters");

      const data = res.data.map((user) => ({
        ...user,
        register_in: format(parseISO(user.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setAllSupporters(data);
    };
    loadAllSupporters();
  }, []);

  useEffect(() => {
    const loadAllCompanies = async () => {
      const res = await api.get("companies");

      const data = res.data.map((user) => ({
        ...user,
        register_in: format(parseISO(user.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setAllCompanies(data);
    };
    loadAllCompanies();
  }, []);

  useEffect(() => {
    const loadAllScientists = async () => {
      const res = await api.get("scientists");

      const data = res.data.map((user) => ({
        ...user,
        register_in: format(parseISO(user.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setAllScientists(data);
    };
    loadAllScientists();
  }, []);

  useEffect(() => {
    const loadAllProjects = async () => {
      const res = await api.get("projects");
      const data = res.data.map((item) => ({
        ...item,
        formmated: format(parseISO(item.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setAllProjects(data);
    };
    loadAllProjects();
  }, []);

  useEffect(() => {
    const loadAllProducts = async () => {
      const res = await api.get("products");
      const data = res.data.map((item) => ({
        ...item,
        formmated: format(parseISO(item.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setAllProducts(data);
    };
    loadAllProducts();
  }, []);

  useEffect(() => {
    const loadAllCollaborations = async () => {
      const res = await api.get("all-collaborations");
      const data = res.data.map((item) => ({
        ...item,
        register_in: format(parseISO(item.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setAllCollaborations(data);
    };
    loadAllCollaborations();
  }, []);

  useEffect(() => {
    const loadAllChallenges = async () => {
      const res = await api.get("challenges");
      const data = res.data.map((item) => ({
        ...item,
        register_in: format(parseISO(item.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setAllChallenges(data);
    };
    loadAllChallenges();
  }, []);

  useEffect(() => {
    const loadMyRegisterCategories = async () => {
      const { data } = await api.get("categories-admin");

      setMyRegisterCategories(data);
    };
    loadMyRegisterCategories();
  }, []);

  useEffect(() => {
    const loadNotAprovedChallenges = async () => {
      const res = await api.get("challenges-analysis");

      if (res.data !== null && res.data.length !== 0) {
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        console.log(data)

        setNotAprovedChallenges(data);
      }
    };
    loadNotAprovedChallenges();
  }, []);

  useEffect(() => {
    const loadComplaintChallenges = async () => {
      const res = await api.get("complaint-challenges");

      if (res.data !== null && res.data.length !== 0) {
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setComplaintChallenge(data);
      }
    };
    loadComplaintChallenges();
  }, []);

  useEffect(() => {
    const loadArticles = async () => {
      const { data } = await api.get("articles");

      if (data !== null && data.length !== 0) {
        setArrArticles(data);
      }
    };
    loadArticles();
  }, []);

  useEffect(() => {
    const loadComplaintProjects = async () => {
      const res = await api.get("complaint-projects");

      if (res.data !== null && res.data.length !== 0) {
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setComplaintProject(data);
      }
    };
    loadComplaintProjects();
  }, []);

  useEffect(() => {
    const loadComplaintProducts = async () => {
      const res = await api.get("complaint-products");

      if (res.data != null && res.data.length !== 0) {
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setComplaintProduct(data);
      }
    };
    loadComplaintProducts();
  }, []);

  useEffect(() => {
    const loadPayments = async () => {
      const res = await api.get("payments");

      if (res.data != null && res.data.length !== 0) {
        const data = res.data.map((payment) => ({
          ...payment,
          formattedDate: format(parseISO(payment.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setPayments(data);
      }
    };
    loadPayments();
  }, []);

  useEffect(() => {
    const loadPayments = async () => {
      const res = await api.get("cosmos");
      if (res.data != null && res.data.length !== 0) {
        const data = res.data.map((payment) => ({
          ...payment,
          formattedDate: format(parseISO(payment.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setCosmos(data);
      }
    };
    loadPayments();
  }, []);

  useEffect(() => {
    const loadContacts = async () => {
      const res = await api.get("projects");
      if (res.data != null && res.data.length !== 0) {
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setArrProjects(
          data.filter(
            (item) =>
              parseFloat(item.current_value) >= parseFloat(item.value_goal)
          )
        );
      }
    };
    loadContacts();
  }, []);

  useEffect(() => {
    const loadContacts = async () => {
      const res = await api.get("projects-analysis");
      if (res.data != null && res.data.length !== 0) {
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setArrProjectsAnalysis(data);
      }
    };
    loadContacts();
  }, []);

  useEffect(() => {
    const loadDeletedUser = async () => {
      const res = await api.get("deleted-user");
      if (res.data != null && res.data.length !== 0) {
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setDeletedUsersArr(data);
      }
    };
    loadDeletedUser();
  }, []);

  const [contacts, setContacts] = useState([]);
  const [contactsSpecial, setContactsSpecial] = useState([]);

  useEffect(() => {
    const loadContacts = async () => {
      const res = await api.get("contacts");

      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setContacts(data.filter((item) => item.type === "normal"));
      setContactsSpecial(data.filter((item) => item.type === "especial"));
      setSupport(data.filter((item) => item.type === "support"));
      setArrRecover(data.filter((item) => item.type === "recover"));
    };
    loadContacts();
  }, []);

  useEffect(() => {
    const fetchProjectsHome = async () => {
      const res = await api.get("v2/projects-home");
      if (res.data != null && res.data.length !== 0) {
        setAllProjectsHome(
          res.data
        );

        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setAllProjectsHome(data)
      }
    };
    fetchProjectsHome();
  }, []);

  const options = [
    "Ciência",
    "Projetos em destaque",
    "Instituições",
    "Pesquisa",
    "Institucional",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicke = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  function handleContactClick() {
    if (contact === false) {
      setContact(!contact);

      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }

      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleSupportersClick() {
    if (supporters === false) {
      setSupporters(!supporters);

      if (contact === true) {
        setContact(!contact);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleBlogClick() {
    if (blog === false) {
      setBlog(!blog);

      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (contact === true) {
        setContact(!contact);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleCollaborationsClick() {
    if (collaborations === false) {
      setCollaborations(!collaborations);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleShoppingClick() {
    if (shopping === false) {
      setShopping(!shopping);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleNewBlogClick() {
    if (newBlog === false) {
      setNewBlog(!newBlog);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleGoalsClick() {
    if (goals === false) {
      setGoals(!goals);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleCategoriesClick() {
    if (categories === false) {
      setCategories(!categories);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleArticlesClick() {
    if (articles === false) {
      setArticles(!articles);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleRequestClick() {
    if (requests === false) {
      setRequests(!requests);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleProjectsClick() {
    if (projects === false) {
      setProjects(!projects);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleSearchClick() {
    if (searchUser === false) {
      setSearchUser(!searchUser);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleChallengesClick() {
    if (challenges === false) {
      setChallenges(!challenges);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }
  function handleDeletedUserClick() {
    if (deletedUsers === false) {
      setDeletedUsers(!deletedUsers);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleContactsSupportersClick() {
    if (contactSupporters === false) {
      setContactSupporters(!contactSupporters);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleNotificationsClick() {
    if (notifications === false) {
      setNotifications(!notifications);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleUsersClick() {
    if (users === false) {
      setUsers(!users);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleCreditWhuffielick() {
    if (creditWhuffie === false) {
      setCreditWhuffie(!creditWhuffie);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleReportsclick() {
    if (reports === false) {
      setReports(!reports);

      if (contact === true) {
        setContact(!contact);
      }
      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleDeleteProjectsClick() {
    if (deleteProjects === false) {
      setDeleteProjects(!deleteProjects);

      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }

      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (contact === true) {
        setContact(!contact);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleDeleteChallengesClick() {
    if (deleteChalenges === false) {
      setDeleteChalenges(!deleteChalenges);

      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }

      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (contact === true) {
        setContact(!contact);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (projectsHome === true) {
        setProjectsHome(!projectsHome);
      }
    }
  }

  function handleProjectsHomeClick() {
    if (projectsHome === false) {
      setProjectsHome(!projectsHome);

      if (supporters === true) {
        setSupporters(!supporters);
      }
      if (blog === true) {
        setBlog(!blog);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newBlog === true) {
        setNewBlog(!newBlog);
      }
      if (goals === true) {
        setGoals(!goals);
      }
      if (categories === true) {
        setCategories(!categories);
      }
      if (articles === true) {
        setArticles(!articles);
      }
      if (texts === true) {
        setTexts(!texts);
      }
      if (requests === true) {
        setRequests(!requests);
      }
      if (projects === true) {
        setProjects(!projects);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (searchUser === true) {
        setSearchUser(!searchUser);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (deletedUsers === true) {
        setDeletedUsers(!deletedUsers);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }
      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (users === true) {
        setUsers(!users);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (contactSupporters === true) {
        setContactSupporters(!contactSupporters);
      }

      if (notifications === true) {
        setNotifications(!notifications);
      }
      if (creditWhuffie === true) {
        setCreditWhuffie(!creditWhuffie);
      }
      if (reports === true) {
        setReports(!reports);
      }
      if (editBlog === true) {
        setEditBlog(!editBlog);
      }
      if (contact === true) {
        setContact(!contact);
      }

      if (deleteProjects === true) {
        setDeleteProjects(!deleteProjects);
      }

      if (deleteChalenges === true) {
        setDeleteChalenges(!deleteChalenges);
      }
    }
  }

  let history = useHistory();
  const ref = useRef();

  function handleSignOut() {
    dispatch(signOut());
    setTimeout(function () {
      history.push("/");
    }, 1000);
  }

  const [arrGoals, setArrGoals] = useState([]);

  useEffect(() => {
    const loadGoals = async () => {
      const res = await api.get("goals");

      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setArrGoals(data);
    };
    loadGoals();
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      const res = await api.get("categories");

      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setArrCategories(data);
    };
    loadCategories();
  }, []);

  const [assistants, setAssistants] = useState([]);

  useEffect(() => {
    const loadAssistants = async () => {
      const { data } = await api.get("assistants");

      setAssistants(data);
    };
    loadAssistants();
  }, []);

  const [buttonNormal, setButtonNormal] = useState(true);
  const [buttonSpecial, setButtonSpecial] = useState(false);
  //buttons contacts

  const toogleBtnNormal = () => {
    if (buttonNormal === false) {
      setButtonNormal(!buttonNormal);

      if (buttonSpecial === true) {
        setButtonSpecial(!buttonSpecial);
      }
    }
  };

  const toogleBtnSpecial = () => {
    if (buttonSpecial === false) {
      setButtonSpecial(!buttonSpecial);

      if (buttonNormal === true) {
        setButtonNormal(!buttonNormal);
      }
    }
  };

  //PAGINATE PROJECTS NOT APROVED
  const [currentPageAnalysis, setCurrentPageAnalysis] = useState(1);
  const [countriesPerPageAnalysis] = useState(4);

  const paginateAnalysis = (number) => setCurrentPageAnalysis(number);
  const indexLastCountriesAnalysis =
    currentPageAnalysis * countriesPerPageAnalysis;
  const indexFirstCountriesAnalysis =
    indexLastCountriesAnalysis - countriesPerPageAnalysis;
  const currentCountriesAnalysis = arrProjectsAnalysis.slice(
    indexFirstCountriesAnalysis,
    indexLastCountriesAnalysis
  );

  //CONTACTS PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(3);

  const paginate = (number) => setCurrentPage(number);
  const indexLastCountries = currentPage * countriesPerPage;
  const indexFirstCountries = indexLastCountries - countriesPerPage;
  const currentCountries = contacts.slice(
    indexFirstCountries,
    indexLastCountries
  );

  const [currentPageSpecial, setCurrentPageSpecial] = useState(1);
  const [countriesPerPageSpecial] = useState(3);

  const paginateSpecial = (number) => setCurrentPageSpecial(number);
  const indexLastCountriesSpecial =
    currentPageSpecial * countriesPerPageSpecial;
  const indexFirstCountriesSpecial =
    indexLastCountriesSpecial - countriesPerPageSpecial;
  const currentCountriesSpecial = contactsSpecial.slice(
    indexFirstCountriesSpecial,
    indexLastCountriesSpecial
  );

  const [currentPageSupport, setCurrentPageSupport] = useState(1);
  const [countriesPerPageSupport] = useState(2);

  const paginateSupport = (number) => setCurrentPageSupport(number);
  const indexLastCountriesSupport =
    currentPageSupport * countriesPerPageSupport;
  const indexFirstCountriesSupport =
    indexLastCountriesSupport - countriesPerPageSupport;
  const currentCountriesSupport = support.slice(
    indexFirstCountriesSupport,
    indexLastCountriesSupport
  );

  const [imageNew, setImageNew] = useState([]);

  async function handleChangeEdit(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    setImageNew([...imageNew, newfile]);
  }

  async function handleChangeEditUpdate(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    addArr(newfile);
  }

  const [company, setCompany] = useState(false);
  const [institution, setInstitution] = useState(false);

  const toggleCompany = () => !institution && setCompany(!company);
  const toggleInstitution = () => !company && setInstitution(!institution);

  async function handleSubmitAssitant() {
    if (imageNew[0] === null) {
      toast.error("Adicione a imagem");
    } else {
      if (!institution && !company) {
        toast.error("Selecione o tipo de apoiador");
      } else {
        var typeAssitant =
          institution === true ? "Instituição" : "Empresa privada";

        var auxAss = Object.assign({
          image_id: imageNew[0].id,
          type: typeAssitant,
        });

        setCompany(false);
        setInstitution(false);

        try {
          await api.post("assistants", auxAss);
          toast.success("Apoiador cadastrado!");
          setTimeout(() => {
            history.go(0);
          }, 1500);
        } catch (error) {
          toast.error("Erro ao cadastrar apoiador!");
        }
      }
    }
  }

  function deleteImg(id) {
    api.delete(`files/${id}`);
  }

  function deleteImgAssistants(id) {
    api.delete(`assistants/${id}`);
  }

  const [news, setNews] = useState([]);

  useEffect(() => {
    const loadAssistants = async () => {
      const res = await api.get("all-news");

      const data = res.data.map((news) => ({
        ...news,
        formattedDate: format(parseISO(news.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setNews(data);
    };
    loadAssistants();
  }, []);

  async function handleChangeBlog(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    setImageNew([...imageNew, newfile]);
  }

  async function handleSubmitBlog(data) {
    if (imageNew === null || imageNew[0] === null || imageNew[1] === null) {
      toast.error("A notícia está sem 2 imagens");
    } else {
      data.first_img = imageNew[0].id;
      data.second_img = imageNew[1].id;

      if (selectedOption) {
        data.category = selectedOption;
      }

      setContent("");

      try {
        await api.post("news", data);
        toast.success("Sucesso ao cadastrar notícia!");
        setTimeout(function () {
          history.go(0);
        }, 1500);
      } catch (error) {
        toast.error("Erro ao cadastrar notícia!");
      }
    }
  }

  const [currentPageNews, setCurrentPageNews] = useState(1);
  const [countriesPerPageNews] = useState(4);

  const paginateNews = (number) => setCurrentPageNews(number);
  const indexLastCountriesNews = currentPageNews * countriesPerPageNews;
  const indexFirstCountriesNews = indexLastCountriesNews - countriesPerPageNews;
  const currentCountriesNews = news.slice(
    indexFirstCountriesNews,
    indexLastCountriesNews
  );

  //PAGINATION COMPLAINT CHALLENGES

  const [currentPageCChallenge, setCurrentPageCChallenge] = useState(1);
  const [countriesPerPageCChallenge] = useState(1);

  const paginateCChallenge = (number) => setCurrentPageCChallenge(number);
  const indexLastCountriesCChallenge =
    currentPageCChallenge * countriesPerPageCChallenge;
  const indexFirstCountriesCChallenge =
    indexLastCountriesCChallenge - countriesPerPageCChallenge;
  const currentCountriesCChallenge = complaintChallenge.slice(
    indexFirstCountriesCChallenge,
    indexLastCountriesCChallenge
  );

  //PAGINATION COMPLAINT PROJECTS

  const [currentPageCProject, setCurrentPageCProject] = useState(1);
  const [countriesPerPageCProject] = useState(1);

  const paginateCProject = (number) => setCurrentPageCProject(number);
  const indexLastCountriesCProject =
    currentPageCProject * countriesPerPageCProject;
  const indexFirstCountriesCProject =
    indexLastCountriesCProject - countriesPerPageCProject;
  const currentCountriesCProject = complaintProject.slice(
    indexFirstCountriesCProject,
    indexLastCountriesCProject
  );
  //PAGINATION COMPLAINT PRODUCTS

  const [currentPageCProduct, setCurrentPageCProduct] = useState(1);
  const [countriesPerPageCProduct] = useState(1);

  const paginateCProduct = (number) => setCurrentPageCProduct(number);
  const indexLastCountriesCProduct =
    currentPageCProduct * countriesPerPageCProduct;
  const indexFirstCountriesCProduct =
    indexLastCountriesCProduct - countriesPerPageCProduct;
  const currentCountriesCProduct = complaintProduct.slice(
    indexFirstCountriesCProduct,
    indexLastCountriesCProduct
  );

  //PAGINATION ARTICLES PROJECTS

  const [currentPageArticles, setCurrentPageArticles] = useState(1);
  const [countriesPerPageArticles] = useState(4);

  const paginateArticles = (number) => setCurrentPageArticles(number);
  const indexLastCountriesArticles =
    currentPageArticles * countriesPerPageArticles;
  const indexFirstCountriesArticles =
    indexLastCountriesArticles - countriesPerPageArticles;
  const currentCountriesArticles = arrArticles.slice(
    indexFirstCountriesArticles,
    indexLastCountriesArticles
  );

  /*-------------------*/

  const [currentPagePayments, setCurrentPagePayments] = useState(1);
  const [countriesPerPagePayments] = useState(2);

  const paginatePayments = (number) => setCurrentPagePayments(number);
  const indexLastCountriesPayments =
    currentPagePayments * countriesPerPagePayments;
  const indexFirstCountriesPayments =
    indexLastCountriesPayments - countriesPerPagePayments;
  const currentCountriesPayments = payments.slice(
    indexFirstCountriesPayments,
    indexLastCountriesPayments
  );

  const [currentPageCosmos, setCurrentPageCosmos] = useState(1);
  const [countriesPerPageCosmos] = useState(4);

  const paginateCosmos = (number) => setCurrentPageCosmos(number);
  const indexLastCountriesCosmos = currentPageCosmos * countriesPerPageCosmos;
  const indexFirstCountriesCosmos =
    indexLastCountriesCosmos - countriesPerPageCosmos;
  const currentCountriesCosmos = cosmos.slice(
    indexFirstCountriesCosmos,
    indexLastCountriesCosmos
  );

  const [btnProjectDonations, setBtnProjectDonations] = useState(true);
  const [btnCosmosDonations, setBtnCosmosDonations] = useState(false);

  const toggleBtnProject = () => {
    if (!btnProjectDonations && btnCosmosDonations) {
      setBtnProjectDonations(true);
      setBtnCosmosDonations(false);
    }
  };

  const toggleBtnCosmos = () => {
    if (!btnCosmosDonations && btnProjectDonations) {
      setBtnCosmosDonations(true);
      setBtnProjectDonations(false);
    }
  };

  /*   PAGINATE GOALS       */
  const [currentPageGoals, setCurrentPageGoals] = useState(1);
  const [countriesPerPageGoals] = useState(4);

  const paginateGoals = (number) => setCurrentPageGoals(number);
  const indexLastCountriesGoals = currentPageGoals * countriesPerPageGoals;
  const indexFirstCountriesGoals =
    indexLastCountriesGoals - countriesPerPageGoals;
  const currentCountriesGoals = arrGoals.slice(
    indexFirstCountriesGoals,
    indexLastCountriesGoals
  );

  /*   PAGINATE CATEGORIES       */
  const [currentPageCategories, setCurrentPageCategories] = useState(1);
  const [countriesPerPageCategories] = useState(6);

  const paginateCategories = (number) => setCurrentPageCategories(number);
  const indexLastCountriesCategories =
    currentPageCategories * countriesPerPageCategories;
  const indexFirstCountriesCategories =
    indexLastCountriesCategories - countriesPerPageCategories;
  const currentCountriesCategories = arrCategories.slice(
    indexFirstCountriesCategories,
    indexLastCountriesCategories
  );

  /*   PAGINATE Projects       */
  const [currentPageProjects, setCurrentPageProjects] = useState(1);
  const [countriesPerPageProjects] = useState(6);

  const paginateProjects = (number) => setCurrentPageProjects(number);
  const indexLastCountriesProjects =
    currentPageProjects * countriesPerPageProjects;
  const indexFirstCountriesProjects =
    indexLastCountriesProjects - countriesPerPageProjects;
  const currentCountriesProjects = arrProjects.slice(
    indexFirstCountriesProjects,
    indexLastCountriesProjects
  );

  /*   PAGINATE Challenges Not Aproved      */
  const [currentPageChallengesNot, setCurrentPageChallengesNot] = useState(1);
  const [countriesPerPageChallengesNot] = useState(4);

  const paginateChallengesNot = (number) => setCurrentPageChallengesNot(number);
  const indexLastCountriesChallengesNot =
    currentPageChallengesNot * countriesPerPageChallengesNot;
  const indexFirstCountriesChallengesNot =
    indexLastCountriesChallengesNot - countriesPerPageChallengesNot;
  const currentCountriesChallengesNot = notAprovedChallenges.slice(
    indexFirstCountriesChallengesNot,
    indexLastCountriesChallengesNot
  );

  /*   PAGINATE Recover   */
  const [currentPageRecover, setCurrentPageRecover] = useState(1);
  const [countriesPerPageRecover] = useState(4);

  const paginateRecover = (number) => setCurrentPageRecover(number);
  const indexLastCountriesRecover =
    currentPageRecover * countriesPerPageRecover;
  const indexFirstCountriesRecover =
    indexLastCountriesRecover - countriesPerPageRecover;
  const currentCountriesRecover = arrRecover.slice(
    indexFirstCountriesRecover,
    indexLastCountriesRecover
  );

  //PAGINATION DELETED USERS PRODUCTS

  const [currentPageDeletedUsers, setCurrentPageDeletedUsers] = useState(1);
  const [countriesPerPageDeletedUsers] = useState(2);

  const paginateDeletedUsers = (number) => setCurrentPageDeletedUsers(number);
  const indexLastCountriesDeletedUsers =
    currentPageDeletedUsers * countriesPerPageDeletedUsers;
  const indexFirstCountriesDeletedUsers =
    indexLastCountriesDeletedUsers - countriesPerPageDeletedUsers;
  const currentCountriesDeletedUsers = deletedUsersArr.slice(
    indexFirstCountriesDeletedUsers,
    indexLastCountriesDeletedUsers
  );

  //PAGINATION CATEGORIES
  const [currentPageMyRegisterCategories, setCurrentPageMyRegisterCategories] =
    useState(1);
  const [countriesPerPageMyRegisterCategories] = useState(6);

  const paginateMyRegisterCategories = (number) =>
    setCurrentPageMyRegisterCategories(number);
  const indexLastCountriesMyRegisterCategories =
    currentPageMyRegisterCategories * countriesPerPageMyRegisterCategories;
  const indexFirstCountriesMyRegisterCategories =
    indexLastCountriesMyRegisterCategories -
    countriesPerPageMyRegisterCategories;
  const currentCountriesMyRegisterCategories = myRegisterCategories.slice(
    indexFirstCountriesMyRegisterCategories,
    indexLastCountriesMyRegisterCategories
  );
  async function acceptGoals(id) {
    try {
      await api.put(`goals/${id}`);
      toast.success("Solicitação aprovada!");
      setTimeout(() => {
        history.go(0);
      }, 2500);
    } catch (error) {
      toast.error("Erro ao aprovar solicitação!");
    }
  }

  async function acceptCategories(id) {
    try {
      await api.put(`categories/${id}`);
      toast.success("Solicitação aprovada!");
      setTimeout(() => {
        history.go(0);
      }, 2500);
    } catch (error) {
      toast.error("Erro ao aprovar solicitação!");
    }
  }

  async function disapproveGoals(id) {
    try {
      await api.delete(`goals/${id}`);
      toast.success("Solicitação excluida!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir solicitação!");
    }
  }

  async function disapproveCategories(id) {
    try {
      await api.delete(`categories/${id}`);
      toast.success("Solicitação excluida!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir solicitação!");
    }
  }

  const [btnProjectC, setBtnProjectC] = useState(true);
  const [btnProductC, setBtnProductC] = useState(false);
  const [btnChallengeC, setBtnChallengeC] = useState(false);

  const toogleBtnProject = () => {
    if (btnProjectC === false) {
      setBtnProjectC(!btnProjectC);

      if (btnProductC === true) {
        setBtnProductC(!btnProductC);
      }
      if (btnChallengeC === true) {
        setBtnChallengeC(!btnChallengeC);
      }
    }
  };

  const toogleBtnProduct = () => {
    if (btnProductC === false) {
      setBtnProductC(!btnProductC);

      if (btnChallengeC === true) {
        setBtnChallengeC(!btnChallengeC);
      }
      if (btnProjectC === true) {
        setBtnProjectC(!btnProjectC);
      }
    }
  };

  const toogleBtnChallenge = () => {
    if (btnChallengeC === false) {
      setBtnChallengeC(!btnChallengeC);

      if (btnProductC === true) {
        setBtnProductC(!btnProductC);
      }
      if (btnProjectC === true) {
        setBtnProjectC(!btnProjectC);
      }
    }
  };

  async function handleDeleteComplaintProject(id) {
    try {
      await api.delete(`complaint-projects/${parseInt(id)}`);
      toast.success("Sucesso ao excluir denúncia!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir denúncia!");
    }
  }

  async function handleDeleteComplaintProduct(id) {
    try {
      await api.delete(`complaint-products/${parseInt(id)}`);
      toast.success("Sucesso ao excluir denúncia!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir denúncia!");
    }
  }

  async function handleDeleteComplaintChallenge(id) {
    try {
      await api.delete(`complaint-challenges/${parseInt(id)}`);
      toast.success("Sucesso ao excluir denúncia!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir denúncia!");
    }
  }

  async function aproveProject(id) {
    try {
      await api.put(`approved/${id}`);
      toast.success("Projeto Aprovado!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao aprovar Projeto!");
    }
  }

  const [profileOne, setProfileOne] = useState(true);
  const [profileTwo, setProfileTwo] = useState(false);
  const [profileThree, setProfileThree] = useState(false);

  function handleProfileOneClick() {
    if (profileOne === false) {
      setProfileOne(!profileOne);

      if (profileTwo === true) {
        setProfileTwo(!profileTwo);
      }
      if (profileThree === true) {
        setProfileThree(!profileThree);
      }
    }
  }

  function handleProfileTwoClick() {
    if (profileTwo === false) {
      setProfileTwo(!profileTwo);

      if (profileOne === true) {
        setProfileOne(!profileOne);
      }
      if (profileThree === true) {
        setProfileThree(!profileThree);
      }
    }
  }

  function handleProfileThreeClick() {
    if (profileThree === false) {
      setProfileThree(!profileThree);

      if (profileOne === true) {
        setProfileOne(!profileOne);
      }
      if (profileTwo === true) {
        setProfileTwo(!profileTwo);
      }
    }
  }

  async function aproveChallenge(id) {
    try {
      await api.put(`approved-challenge/${id}`);
      toast.success("Desafio Aprovado!");
      setTimeout(() => {
        history.go(0);
      }, 2500);
    } catch (error) {
      toast.error("Erro ao aprovar Desafio!");
    }
  }

  async function deleteUser(id) {
    try {
      await api.put(`deleted-user/${id}`);
      toast.success("Usuário excluido!");
      setTimeout(() => {
        history.go(0);
      }, 500);
    } catch (error) {
      toast.error("Erro ao excluir Usuário!");
    }
  }

  async function deleteContact(id) {
    try {
      await api.delete(`contact-us/${id}`);
      toast.success("Contato excluido!");
      setTimeout(() => {
        history.go(0);
      }, 500);
    } catch (error) {
      toast.error("Erro ao excluir Contato!");
    }
  }

  const [buttonOne, setButtonOne] = useState(true);
  const [buttonAll, setButtonAll] = useState(false);

  function handleBtnOne() {
    if (buttonOne === false) {
      setButtonOne(!buttonOne);

      if (buttonAll === true) {
        setButtonAll(!buttonAll);
      }
    }
  }

  function handleBtnAll() {
    if (buttonAll === false) {
      setButtonAll(!buttonAll);

      if (buttonOne === true) {
        setButtonOne(!buttonOne);
      }
    }
  }

  const [fsProfile, setFsProfile] = useState(true);
  const [secProfile, setSecProfile] = useState(false);
  const [thirdProfile, setThirdProfile] = useState(false);

  function handleFsProfile() {
    if (fsProfile === false) {
      setFsProfile(!fsProfile);

      if (secProfile === true) {
        setSecProfile(!secProfile);
      }
      if (thirdProfile === true) {
        setThirdProfile(!thirdProfile);
      }
    }
  }

  function handleSecProfile() {
    if (secProfile === false) {
      setSecProfile(!secProfile);

      if (fsProfile === true) {
        setFsProfile(!fsProfile);
      }
      if (thirdProfile === true) {
        setThirdProfile(!thirdProfile);
      }
    }
  }

  function handleThirdProfile() {
    if (thirdProfile === false) {
      setThirdProfile(!thirdProfile);

      if (secProfile === true) {
        setSecProfile(!secProfile);
      }
      if (fsProfile === true) {
        setFsProfile(!fsProfile);
      }
    }
  }

  const [btnCosmos, setBtnCosmos] = useState(false);
  const [btnPayments, setBtnPayments] = useState(true);

  function handleBtnPayments() {
    if (btnPayments === false) {
      setBtnPayments(!btnPayments);

      if (btnCosmos === true) {
        setBtnCosmos(!btnCosmos);
      }
    }
  }

  function handleBtnCosmos() {
    if (btnCosmos === false) {
      setBtnCosmos(!btnCosmos);
      if (btnPayments === true) {
        setBtnPayments(!btnPayments);
      }
    }
  }

  async function submitIndividual(data) {
    data.profile = fsProfile
      ? "Apoiador/Visitante"
      : secProfile
        ? "Apoiador Empresarial/Desafiante"
        : "Cientista/Pesquisador";
    try {
      await api.post("notifications", data);
      toast.success("Sucesso ao enviar notificação!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao enviar notificação!");
    }
  }

  async function submitAll(data) {
    data.profile = fsProfile
      ? "Apoiador/Visitante"
      : secProfile
        ? "Apoiador Empresarial/Desafiante"
        : "Cientista/Pesquisador";
    try {
      if (fsProfile) {
        await api.post("notifications-supporters", data);
      } else if (secProfile) {
        await api.post("notifications-companies", data);
      } else if (thirdProfile) {
        await api.post("notifications-scientists", data);
      }

      toast.success("Sucesso ao enviar notificação!");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao enviar notificação!");
      console.log(error);
    }
  }

  const [sendedCategories, setSendedCategories] = useState(true);
  const [myNewCategories, setMyNewCategories] = useState(false);

  function handleSendedCategories() {
    if (sendedCategories === false) {
      setSendedCategories(!sendedCategories);

      if (myNewCategories === true) {
        setMyNewCategories(!myNewCategories);
      }
    }
  }

  function handleMyNewCategories() {
    if (myNewCategories === false) {
      setMyNewCategories(!myNewCategories);

      if (sendedCategories === true) {
        setSendedCategories(!sendedCategories);
      }
    }
  }

  async function submitCategories(data) {
    data.accept = true;
    data.name = "ADMIN";

    try {
      await api.post("categories", data);
      toast.success("Sucesso ao cadastrar Categoria !");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao cadastrar categoria");
    }
  }

  async function deleteCategories(id) {
    try {
      await api.delete(`categories/${id}`);
      toast.success("Sucesso ao excluir Categoria !");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir categoria!");
    }
  }
  const [editCategoryValue, setEditCategoryValue] = useState("");

  async function updateCategory(id) {
    const data = Object.assign({ category: editCategoryValue });

    try {
      await api.put(`categories-admin/${id}`, data);
      toast.success("Sucesso ao editar Categoria !");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao editar categoria!");
    }
  }

  async function deleteSupportUser(id) {
    try {
      await api.delete(`delete-supporters/${id}`);
      toast.success("Usuário Excluído");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir usuário!");
    }
  }

  async function deleteScientistUser(id) {
    try {
      await api.delete(`delete-scientist/${id}`);
      toast.success("Usuário Excluído");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir usuário!");
    }
  }

  async function deleteCompanyUser(id) {
    try {
      await api.delete(`delete-companies/${id}`);
      toast.success("Usuário Excluído");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir usuário!");
    }
  }

  async function creditPeople(data) {
    try {
      if (fsProfile) {
        await api.put(`add-whuffie-supporters`, data);
        toast.success("Tranferencia concluída");
        setTimeout(() => {
          history.go(0);
        }, 1500);
      } else if (secProfile) {
        await api.put(`add-whuffie-company`, data);
        toast.success("Tranferencia concluída");
        setTimeout(() => {
          history.go(0);
        }, 1500);
      } else if (thirdProfile) {
        await api.put(`add-whuffie-scientist`, data);
        toast.success("Tranferencia concluída");
        setTimeout(() => {
          history.go(0);
        }, 1500);
      }
    } catch (error) {
      toast.error("Erro ao efetuar transferência!");
    }
  }

  async function creditProject(data) {
    try {
      await api.put(`add-whuffie-project`, data);
      toast.success("Tranferencia concluída");
      setTimeout(() => {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao efetuar transferência!");
    }
  }

  const [searchUsers, setSearchUsers] = useState([]);

  async function handleSearchUserSupporters(data) {
    const res = await api.get(`supporters-by-name/${data.name}`);
    setSearchUsers([]);

    if (res.data.error) {
      setTimeout(() => {
        toast.error("Nenhum usuário encontrado!");
      }, 50);
    } else {
      setSearchUsers(res.data);
    }
  }

  async function handleSearchUserCompanies(data) {
    const res = await api.get(`companies-by-name/${data.name}`);
    setSearchUsers([]);
    if (res.data.error) {
      setTimeout(() => {
        toast.error("Nenhum usuário encontrado!");
      }, 50);
    } else {
      setSearchUsers(res.data);
    }
  }
  async function handleSearchUserScientists(data) {
    const res = await api.get(`scientist/${data.name}`);
    setSearchUsers([]);
    if (res.data.error) {
      setTimeout(() => {
        toast.error("Nenhum usuário encontrado!");
      }, 50);
    } else {
      setSearchUsers(res.data);
    }
  }

  const [content, setContent] = useState("");

  const setFormattedContent = useCallback(
    (text) => {
      setContent(text.slice(0, 145));
    },
    [setContent]
  );
  const [editPublication, setEditPublication] = useState([]);

  const [arrayEdit, setArrayEdit] = useState([]);
  var auxArrayEdit = [];
  const addArr = (newItem) => {
    setArrayEdit([
      ...arrayEdit,
      {
        id: newItem.id,
        url: newItem.url,
      },
    ]);
  };

  async function updateNew(data) {
    data.first_img = arrayEdit[0].id;
    data.second_img = arrayEdit[1].id;

    if (data.first_img === null || data.second_img === null) {
      toast.error("A publicação necessita de 2 imagens");
    } else {
      try {
        await api.put(`news/${editPublication[0].id}`, data);
        setArrayEdit([]);
        setContent("");
        toast.success("Publicação editada com sucesso!");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      } catch (error) {
        toast.error("Erro ao editar publicação!");
      }
    }
  }

  async function deleteNew(id) {
    try {
      await api.delete(`news/${id}`);
      toast.success("Publicação excluída permanentemente!");
      setTimeout(() => {
        history.go(0);
      }, 2000);
    } catch (error) {
      toast.error("Erro ao excluir publicação!");
    }
  }

  //PAGINATION DELETED Projects
  const [currentPageDeletedProjects, setCurrentPageDeleteProjects] = useState(1);
  const [countriesPerPageDeletedProjects] = useState(2);

  const paginateDeletedProjects = (number) => setCurrentPageDeleteProjects(number);
  const indexLastCountriesDeletedProjects =
    currentPageDeletedProjects * countriesPerPageDeletedProjects;
  const indexFirstCountriesDeletedProjects =
    indexLastCountriesDeletedProjects - countriesPerPageDeletedProjects;
  const currentCountriesDeletedProjects = allProjects.slice(
    indexFirstCountriesDeletedProjects,
    indexLastCountriesDeletedProjects
  );

  async function deleteProject(id) {
    try {
      await api.delete(`project/${id}`);
      toast.success("Projeto excluído permanentemente!");
      setTimeout(() => {
        history.go(0);
      }, 2000);
    } catch (error) {
      toast.error("Erro ao excluir projeto!");
    }
  }

  //PAGINATION DELETED Challenges
  const [currentPageDeletedChalenges, setCurrentPageDeleteChalenges] = useState(1);
  const [countriesPerPageDeletedChalenges] = useState(2);

  const paginateDeletedChalenges = (number) => setCurrentPageDeleteChalenges(number);
  const indexLastCountriesDeletedChalenges =
    currentPageDeletedChalenges * countriesPerPageDeletedChalenges;
  const indexFirstCountriesDeletedChalenges =
    indexLastCountriesDeletedChalenges - countriesPerPageDeletedChalenges;
  const currentCountriesDeletedChalenges = allChallenges.slice(
    indexFirstCountriesDeletedChalenges,
    indexLastCountriesDeletedChalenges
  );

  async function deleteChallenge(id) {
    try {
      await api.delete(`challenge/${id}`);
      toast.success("Desafio excluído permanentemente!");
      setTimeout(() => {
        history.go(0);
      }, 2000);
    } catch (error) {
      toast.error("Erro ao excluir desafio!!");
    }
  }

  //PAGINATION DELETED Projects
  const [currentPageDeletedProjectsHome, setCurrentPageDeleteProjectsHome] = useState(1);
  const [countriesPerPageDeletedProjectsHome] = useState(2);

  const paginateDeletedProjectsHome = (number) => setCurrentPageDeleteProjectsHome(number);
  const indexLastCountriesDeletedProjectsHome =
    currentPageDeletedProjectsHome * countriesPerPageDeletedProjectsHome;
  const indexFirstCountriesDeletedProjectsHome =
    indexLastCountriesDeletedProjectsHome - countriesPerPageDeletedProjectsHome;
  const currentCountriesDeletedProjectsHome = allProjects.slice(
    indexFirstCountriesDeletedProjectsHome,
    indexLastCountriesDeletedProjectsHome
  );

  async function registerProjectsHome(id) {
    try {
      await api.post(`/v2/projects-home`, {
        project_id: id
      });
      toast.success("Projeto Home cadastrado com sucesso!!");
      setTimeout(() => {
        history.go(0);
      }, 2000);
    } catch (error) {
      toast.error("Erro ao cadastrado projeto!");
    }
  }


  async function deleteProjectsHome(id) {
    try {
      await api.delete(`/v2/projects-home/${id}`);
      toast.success("Projeto Home excluido com sucesso!!");
      setTimeout(() => {
        history.go(0);
      }, 2000);
    } catch (error) {
      toast.error("Erro ao excluir projeto!");
    }
  }


  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Wrapper>
        <Bounce top duration={1600} distance="20px">
          <Content>
            <Header>
              <img id="logo" src={logo} alt="" />
              <p id="text-header">ADMIN</p>
            </Header>

            <ProfileContent>
              <aside>
                <div className="menu-profile">
                  <div className="menu">
                    <MenuList active={contact} onClick={handleContactClick}>
                      Fale Conosco
                    </MenuList>
                    <MenuList
                      active={supporters}
                      onClick={handleSupportersClick}
                    >
                      Apoiadores
                    </MenuList>
                    <MenuList
                      active={notifications}
                      onClick={handleNotificationsClick}
                    >
                      Notificações
                    </MenuList>
                    <MenuList
                      active={blog || newBlog || editBlog}
                      onClick={handleBlogClick}
                    >
                      Radar
                    </MenuList>
                    <MenuList active={articles} onClick={handleArticlesClick}>
                      Artigos
                    </MenuList>
                    <MenuList
                      active={collaborations}
                      onClick={handleCollaborationsClick}
                    >
                      Denuncias
                      <Notification>
                        <p id="value">
                          {complaintChallenge.length +
                            complaintProject.length +
                            complaintProduct.length}
                        </p>
                      </Notification>
                    </MenuList>

                    <MenuList
                      active={creditWhuffie}
                      onClick={handleCreditWhuffielick}
                    >
                      Transferência Whuffie
                    </MenuList>

                    <MenuList active={shopping} onClick={handleShoppingClick}>
                      Pagamentos
                    </MenuList>
                    <MenuList active={users} onClick={handleUsersClick}>
                      Usuários
                    </MenuList>
                    <MenuList
                      active={categories}
                      onClick={handleCategoriesClick}
                    >
                      Novas Categorias
                      {arrCategories.filter((item) => item.accept === false)
                        .length > 0 && (
                          <Notification>
                            <p id="value">
                              {
                                arrCategories.filter(
                                  (item) => item.accept === false
                                ).length
                              }
                            </p>
                          </Notification>
                        )}
                    </MenuList>
                    <MenuList active={goals} onClick={handleGoalsClick}>
                      Novas Metas - Projetos{" "}
                      {arrGoals.filter((item) => item.accept === false).length >
                        0 && (
                          <Notification>
                            <p id="value">
                              {
                                arrGoals.filter((item) => item.accept === false)
                                  .length
                              }
                            </p>
                          </Notification>
                        )}
                    </MenuList>


                    <MenuList active={requests} onClick={handleRequestClick}>
                      Aprovar Projetos
                      {arrProjectsAnalysis.length > 0 && (
                        <Notification>
                          <p id="value">{arrProjectsAnalysis.length}</p>
                        </Notification>
                      )}
                    </MenuList>

                    <MenuList active={projects} onClick={handleProjectsClick}>
                      Projetos - Atingiram Meta
                      {arrProjects.length > 0 && (
                        <Notification>
                          <p id="value">{arrProjects.length}</p>
                        </Notification>
                      )}
                    </MenuList>
                    <MenuList active={deleteProjects} onClick={handleDeleteProjectsClick}>
                      Excluir Projetos
                    </MenuList>
                    <MenuList active={projectsHome} onClick={handleProjectsHomeClick}>
                      Projetos - Home
                    </MenuList>
                    <MenuList
                      active={challenges}
                      onClick={handleChallengesClick}
                    >
                      Aprovar Desafios
                      {notAprovedChallenges.length > 0 && (
                        <Notification>
                          <p id="value">{notAprovedChallenges.length}</p>
                        </Notification>
                      )}
                    </MenuList>
                    <MenuList
                      active={deleteChalenges}
                      onClick={handleDeleteChallengesClick}
                    >
                      Excluir Desafios
                    </MenuList>
                    <MenuList active={searchUser} onClick={handleSearchClick}>
                      Recuperar Senha
                    </MenuList>
                    <MenuList
                      active={deletedUsers}
                      onClick={handleDeletedUserClick}
                    >
                      Usuários Excluídos{" "}
                      {deletedUsersArr.length > 0 && (
                        <Notification>
                          <p id="value">
                            {
                              deletedUsersArr.filter(
                                (item) => item.aproved === false
                              ).length
                            }
                          </p>
                        </Notification>
                      )}
                    </MenuList>
                    <MenuList active={reports} onClick={handleReportsclick}>
                      Relatórios
                    </MenuList>
                    <MenuList
                      active={contactSupporters}
                      onClick={handleContactsSupportersClick}
                    >
                      Suporte{" "}
                      {support.length > 0 && (
                        <Notification>
                          <p id="value">{support.length}</p>
                        </Notification>
                      )}
                    </MenuList>
                  </div>
                </div>

                <button id="btn-company-profile" onClick={handleSignOut}>
                  Sair do Admin
                </button>
              </aside>

              {contact && (
                <Fade left duration={1600} distance="20px">
                  <ContactComponent>
                    <p id="title">Fale Conosco</p>
                    <div className="buttons">
                      <ButtonContacts
                        active={buttonNormal}
                        onClick={toogleBtnNormal}
                      >
                        Fale Conosco
                      </ButtonContacts>
                      <ButtonContacts
                        active={buttonSpecial}
                        onClick={toogleBtnSpecial}
                      >
                        Solicitar Permissão Especial
                      </ButtonContacts>
                    </div>
                    {buttonNormal && (
                      <>
                        {" "}
                        {currentCountries.map((preview) => (
                          <Contact key={preview.id}>
                            <div className="title-transaction">
                              <p id="text-title-transaction">
                                {preview.subject}
                              </p>
                              <div className="data">
                                <img
                                  id="icon-calendar"
                                  src={iconCalendarPurple}
                                  alt=""
                                />
                                <p id="day">{preview.formattedDate}</p>
                              </div>
                            </div>
                            <hr />
                            <p id="message">{preview.message}</p>
                            <hr />
                            <div className="infos">
                              <p>Nome: {preview.name}</p>
                              <p>Email: {preview.email}</p>
                              <p>Perfil: {preview.profile}</p>
                              <p>Telefone: {preview.phone}</p>
                            </div>
                          </Contact>
                        ))}
                        {currentCountries.length !== 0 && (
                          <div className="floatPage">
                            <Pagination
                              currentPage={currentPage}
                              countriesPerPage={countriesPerPage}
                              totalCountries={contacts.length}
                              paginate={paginate}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {buttonSpecial && (
                      <>
                        {currentCountriesSpecial.map((preview) => (
                          <Contact key={preview.id}>
                            <div className="title-transaction">
                              <p id="text-title-transaction">
                                {preview.subject}
                              </p>
                              <div className="data">
                                <img
                                  id="icon-calendar"
                                  src={iconCalendarPurple}
                                  alt=""
                                />
                                <p id="day">{preview.formattedDate}</p>
                              </div>
                            </div>
                            <hr />
                            <p id="message">{preview.message}</p>
                            <hr />
                            <div className="infos">
                              <p>Nome: {preview.name}</p>
                              <p>Email: {preview.email}</p>
                              <p>Perfil: {preview.profile}</p>
                              <p>Telefone: {preview.phone}</p>
                            </div>
                          </Contact>
                        ))}
                        {contactsSpecial.length !== 0 && (
                          <div className="floatPage">
                            <Pagination
                              currentPage={currentPageSpecial}
                              countriesPerPage={countriesPerPageSpecial}
                              totalCountries={contactsSpecial.length}
                              paginate={paginateSpecial}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </ContactComponent>
                </Fade>
              )}

              {supporters && (
                <Fade left duration={1600} distance="20px">
                  <DonationsComponent>
                    <p id="title">Apoiadores</p>

                    <div className="new-assistant">
                      <p id="new-assistant-title">Cadastrar Novo Apoiador</p>

                      <div className="input-imgs">
                        <p id="placeholder"> Imagens </p>
                        <BoxLabel>
                          <label className="label-box" htmlFor="avatar">
                            <div>
                              <img src={iconClips} id="clips" alt="" />
                              <input
                                type="file"
                                id="avatar"
                                accept="image/*"
                                disabled={imageNew.length === 1}
                                onChange={handleChangeEdit}
                                ref={ref}
                              />
                            </div>
                          </label>
                        </BoxLabel>
                      </div>
                      <p
                        style={{
                          color: "#7f7fb9",
                          marginTop: 15,
                          width: 352,
                          textAlign: "center",
                        }}
                      >
                        Dimensões recomendadas: utilize imagens de no máximo
                        146x205
                      </p>

                      <div className="previews">
                        {imageNew.map((preview) => (
                          <div className="preview" key={preview.id}>
                            <img id="teste" src={preview.url} alt="avatar" />
                            <button
                              type="button"
                              onClick={() => {
                                deleteImg(preview.id);
                                setImageNew(
                                  imageNew.filter(
                                    (item) => item.id !== preview.id
                                  )
                                );
                              }}
                            >
                              Exluir
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className="labels">
                        <label className="label">
                          <input
                            className="label__checkbox"
                            type="checkbox"
                            onClick={toggleCompany}
                          />

                          <span className="label__text">
                            <span className="label__check">
                              {company && (
                                <FaCheck
                                  className="fa fa-check icon"
                                  color={"#7f7fb9"}
                                />
                              )}
                            </span>
                            <p id="text-checkbox">Empresa privada</p>
                          </span>
                        </label>
                        <label className="label">
                          <input
                            className="label__checkbox"
                            type="checkbox"
                            onClick={toggleInstitution}
                          />

                          <span className="label__text">
                            <span className="label__check">
                              {institution && (
                                <FaCheck
                                  className="fa fa-check icon"
                                  color={"#7f7fb9"}
                                />
                              )}
                            </span>
                            <p id="text-checkbox">Instituição</p>
                          </span>
                        </label>
                      </div>

                      <button
                        type="button"
                        id="btn-assistant"
                        onClick={handleSubmitAssitant}
                      >
                        Cadastrar
                      </button>
                    </div>

                    <hr />

                    <div className="companies-assistants">
                      <p id="text-ass">
                        Apoiadores Empresáriais já cadastrados
                      </p>
                      <div className="assistants">
                        {assistants
                          .filter((item) => item.type === "Empresa privada")
                          .map((preview) => (
                            <div className="preview" key={preview.id}>
                              <img
                                id="teste"
                                src={preview.image.url}
                                alt="avatar"
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  deleteImgAssistants(preview.id);
                                  setAssistants(
                                    assistants.filter(
                                      (item) => item.id !== preview.id
                                    )
                                  );
                                }}
                              >
                                Exluir
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="institutions-assistants">
                      <p id="text-ass">
                        Apoiadores Institucionais já cadastrados
                      </p>
                      <div className="assistants">
                        {assistants
                          .filter((item) => item.type === "Instituição")
                          .map((preview) => (
                            <div className="preview" key={preview.id}>
                              <img
                                id="teste"
                                src={preview.image.url}
                                alt="avatar"
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  deleteImgAssistants(preview.id);
                                  setAssistants(
                                    assistants.filter(
                                      (item) => item.id !== preview.id
                                    )
                                  );
                                }}
                              >
                                Exluir
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </DonationsComponent>
                </Fade>
              )}

              {blog && (
                <Fade left duration={1600} distance="20px">
                  <ChallengeComponent>
                    <p id="title">Radar</p>
                    <div className="newProject">
                      <p> Cadastrar Nova Publicação </p>
                      <button onClick={handleNewBlogClick}> Cadastrar </button>
                    </div>
                    <div className="content">
                      {currentCountriesNews.map((preview) => (
                        <div className="new-box" key={preview.id}>
                          <New>
                            <div className="content-1">
                              <img src={preview.first.url} alt="" />
                            </div>
                            <div className="content-2">
                              <p id="day">{preview.formattedDate}</p>
                              <p id="title">{preview.title}</p>
                              <p id="text">{preview.summary}</p>
                            </div>
                            <div className="content-3">
                              <img src={atomProject} alt="" />
                              <p>
                                <Link to={`/information/${preview.id}`}>
                                  Ler a notícia
                                </Link>
                              </p>
                            </div>
                          </New>
                          <div className="infos">
                            <div
                              onClick={() => {
                                setBlog(false);
                                setEditBlog(true);
                                var arr = [];
                                arr.push(preview);
                                setEditPublication(arr);
                                setContent(preview.summary);
                                auxArrayEdit.push(preview.first);
                                auxArrayEdit.push(preview.second);
                                setArrayEdit(auxArrayEdit);
                                auxArrayEdit = [];
                              }}
                            >
                              <div>
                                <img alt="" src={iconPerfil} />
                              </div>
                              <p>Editar</p>
                            </div>
                            <div onClick={() => deleteNew(preview.id)}>
                              <div>
                                <FaTrashAlt alt="" color="#fff" />
                              </div>
                              <p>Excluir Permanentemente</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="floatPage">
                      <Pagination
                        currentPage={currentPageNews}
                        countriesPerPage={countriesPerPageNews}
                        totalCountries={news.length}
                        paginate={paginateNews}
                      />
                    </div>
                  </ChallengeComponent>
                </Fade>
              )}

              {editBlog && (
                <Fade left duration={1600} distance="20px">
                  <NewBlogComponent>
                    <p id="title-component">Editar Publicação</p>
                    <Form
                      className="form"
                      onSubmit={updateNew}
                      initialData={editPublication[0]}
                    >
                      <div className="inputs">
                        <Input name="title" type="text" placeholder="Título:" />
                        <DropDownContainer>
                          <DropDownHeader onClick={toggling}>
                            <p>
                              {selectedOption ||
                                editPublication.category ||
                                "Categoria"}
                            </p>
                            {isOpen ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpen && (
                            <DropDownListContainer>
                              <DropDownList>
                                <Scrollbars style={{ width: 340, height: 50 }}>
                                  {options.map((option) => (
                                    <ListItem
                                      onClick={onOptionClicke(option)}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>
                      </div>
                      <div className="input-imgs">
                        <p id="placeholder"> Imagens </p>
                        <BoxLabel>
                          <label className="label-box" htmlFor="avatar">
                            <div>
                              <img src={iconClips} id="clips" alt="" />
                              <input
                                type="file"
                                id="avatar"
                                accept="image/*"
                                onChange={handleChangeEditUpdate}
                                disabled={arrayEdit.length === 2 ? true : false}
                                ref={ref}
                              />
                            </div>
                          </label>
                        </BoxLabel>
                      </div>
                      <p
                        style={{
                          color: "#7f7fb9",
                          marginTop: 15,
                          width: 352,
                          textAlign: "center",
                        }}
                      >
                        Cadastre necessariamente 2 imagens (Dimensões
                        recomendadas:720x412)
                      </p>

                      <div className="previews">
                        {arrayEdit.map((preview) => (
                          <div className="preview" key={preview.id}>
                            <img id="teste" src={preview.url} alt="avatar" />
                            <button
                              type="button"
                              onClick={() => {
                                deleteImg(preview.id);
                                setArrayEdit(
                                  arrayEdit.filter(
                                    (item) => item.id !== preview.id
                                  )
                                );
                              }}
                            >
                              Exluir
                            </button>
                          </div>
                        ))}
                      </div>

                      <Textarea
                        className="message"
                        name="summary"
                        type="text"
                        maxLength={145}
                        placeholder="Resumo (card da publicação):"
                        onChange={(event) =>
                          setFormattedContent(event.target.value)
                        }
                        value={content}
                      />
                      <p
                        style={{
                          fontSize: 16,
                          color: "#7f7fb9",
                          marginBottom: 10,
                        }}
                      >
                        {content.length} / {145} Caracteres
                      </p>
                      <Textarea
                        className="message"
                        name="introduction"
                        type="text"
                        placeholder="Introdução (Abaixo do Título da Notícia):"
                      />
                      <Textarea
                        className="message"
                        name="paragraph_one"
                        type="text"
                        placeholder="Primeiro Bloco do Texto (Primeiro parágrafo) :"
                      />
                      <Textarea
                        className="message"
                        name="paragraph_two"
                        type="text"
                        placeholder="Segundo Bloco do Texto (Segundo parágrafo) :"
                      />
                      <button id="btn-submit" type="submit">
                        Cadastrar
                      </button>
                    </Form>
                  </NewBlogComponent>
                </Fade>
              )}

              {articles && (
                <Fade left duration={1600} distance="20px">
                  <ArticleComponent>
                    <p id="title">Artigos</p>
                    <div className="articles">
                      <div className="content">
                        {currentCountriesArticles.map((preview) => (
                          <Article
                            key={preview.id}
                            active={preview.image !== null}
                          >
                            <div className="infos">
                              <div className="info">
                                <img src={userBlue} alt="" />
                                <p>{preview.name}</p>
                              </div>
                              <div className="info">
                                <FaEnvelope color="#227af1" size={20} />
                                <p>{preview.email}</p>
                              </div>
                            </div>

                            {preview.image && (
                              <>
                                <hr />

                                <img
                                  id="image"
                                  src={preview.image.url}
                                  alt=""
                                />
                              </>
                            )}

                            <hr />

                            <div className="curriculum">
                              <p id="title-curriculum">Mini currículo:</p>
                              <p id="curriculum">{preview.curriculum}</p>
                            </div>

                            <hr />

                            <div className="links">
                              <a
                                href={preview.article_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={newsPaperAside} alt="" />
                              </a>

                              {preview.aditional_link && (
                                <a
                                  href={preview.aditional_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaLink size={30} />
                                </a>
                              )}

                              {preview.yt_link && (
                                <a
                                  href={preview.yt_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaYoutube size={35} />
                                </a>
                              )}
                            </div>
                          </Article>
                        ))}
                      </div>
                      {arrArticles.length !== 0 && (
                        <div className="floatPage">
                          <Pagination
                            currentPage={currentPageArticles}
                            countriesPerPage={countriesPerPageArticles}
                            totalCountries={arrArticles.length}
                            paginate={paginateArticles}
                          />
                        </div>
                      )}
                    </div>
                  </ArticleComponent>
                </Fade>
              )}

              {newBlog && (
                <Fade left duration={1600} distance="20px">
                  <NewBlogComponent>
                    <p id="title-component">Cadastrar Publicação</p>
                    <Form className="form" onSubmit={handleSubmitBlog}>
                      <div className="inputs">
                        <Input name="title" type="text" placeholder="Título:" />
                        <DropDownContainer>
                          <DropDownHeader onClick={toggling}>
                            <p>{selectedOption || "Categoria"}</p>
                            {isOpen ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpen && (
                            <DropDownListContainer>
                              <DropDownList>
                                <Scrollbars style={{ width: 340, height: 50 }}>
                                  {options.map((option) => (
                                    <ListItem
                                      onClick={onOptionClicke(option)}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>
                      </div>
                      <div className="input-imgs">
                        <p id="placeholder"> Imagens </p>
                        <BoxLabel>
                          <label className="label-box" htmlFor="avatar">
                            <div>
                              <img src={iconClips} id="clips" alt="" />
                              <input
                                type="file"
                                id="avatar"
                                accept="image/*"
                                onChange={handleChangeBlog}
                                disabled={imageNew.length === 2 ? true : false}
                                ref={ref}
                              />
                            </div>
                          </label>
                        </BoxLabel>
                      </div>
                      <p
                        style={{
                          color: "#7f7fb9",
                          marginTop: 15,
                          width: 352,
                          textAlign: "center",
                        }}
                      >
                        Cadastre necessariamente 2 imagens (Dimensões
                        recomendadas:720x412)
                      </p>
                      <div className="previews">
                        {imageNew.map((preview) => (
                          <div className="preview" key={preview.id}>
                            <img id="teste" src={preview.url} alt="avatar" />
                            <button
                              type="button"
                              onClick={() => {
                                deleteImg(preview.id);
                                setImageNew(
                                  imageNew.filter(
                                    (item) => item.id !== preview.id
                                  )
                                );
                              }}
                            >
                              Exluir
                            </button>
                          </div>
                        ))}
                      </div>
                      <Textarea
                        className="message"
                        name="summary"
                        type="text"
                        maxLength={145}
                        placeholder="Resumo (card da publicação):"
                        onChange={(event) =>
                          setFormattedContent(event.target.value)
                        }
                        value={content}
                      />
                      <p
                        style={{
                          fontSize: 16,
                          color: "#7f7fb9",
                          marginBottom: 10,
                        }}
                      >
                        {content.length} / {145} Caracteres
                      </p>
                      <Textarea
                        className="message"
                        name="introduction"
                        type="text"
                        placeholder="Introdução (Abaixo do Título da Notícia):"
                      />
                      <Textarea
                        className="message"
                        name="paragraph_one"
                        type="text"
                        placeholder="Primeiro Bloco do Texto (Primeiro parágrafo) :"
                      />
                      <Textarea
                        className="message"
                        name="paragraph_two"
                        type="text"
                        placeholder="Segundo Bloco do Texto (Segundo parágrafo) :"
                      />
                      <button id="btn-submit" type="submit">
                        Cadastrar
                      </button>
                    </Form>
                  </NewBlogComponent>
                </Fade>
              )}

              {collaborations && (
                <Fade left duration={1600} distance="20px">
                  <CollaborationsComponent>
                    <p id="title">Denuncias</p>
                    <div className="buttons">
                      <ButtonDonation
                        active={btnProjectC}
                        onClick={toogleBtnProject}
                      >
                        {btnProjectC ? (
                          <img src={iconComplaintProjects} alt="" />
                        ) : (
                          <img src={iconComplaintProjectsBlue} alt="" />
                        )}
                      </ButtonDonation>
                      <ButtonDonation
                        active={btnProductC}
                        onClick={toogleBtnProduct}
                      >
                        {btnProductC ? (
                          <img src={iconComplaintProducts} alt="" />
                        ) : (
                          <img src={iconComplaintProductsBlue} alt="" />
                        )}
                      </ButtonDonation>
                      <ButtonDonation
                        active={btnChallengeC}
                        onClick={toogleBtnChallenge}
                      >
                        {btnChallengeC ? (
                          <img src={iconComplaintChallenges} alt="" />
                        ) : (
                          <img src={iconComplaintChallengesBlue} alt="" />
                        )}
                      </ButtonDonation>
                    </div>

                    {((currentCountriesCChallenge !== null &&
                      currentCountriesCChallenge.length !== 0) ||
                      (currentCountriesCProduct !== null &&
                        currentCountriesCProduct.length !== 0) ||
                      (currentCountriesCProject !== null &&
                        currentCountriesCProject.length !== 0)) && (
                        <>
                          {currentCountriesCProject !== null &&
                            currentCountriesCProject.length !== 0 &&
                            btnProjectC && (
                              <Fade top duration={1600} distance="20px">
                                <p id="title-complaints">Denuncias - Projetos</p>
                                {currentCountriesCProject.map((preview) => (
                                  <div className="project" key={preview.id}>
                                    <Project data={preview.project} />
                                    <div className="buttons">
                                      <button
                                        onClick={() =>
                                          handleDeleteComplaintProject(preview.id)
                                        }
                                      >
                                        Excluir
                                      </button>
                                    </div>
                                  </div>
                                ))}
                                {complaintProject.length > 0 && (
                                  <div className="floatPage">
                                    <Pagination
                                      currentPage={currentPageCProject}
                                      countriesPerPage={countriesPerPageCProject}
                                      totalCountries={complaintProject.length}
                                      paginate={paginateCProject}
                                    />
                                  </div>
                                )}
                              </Fade>
                            )}

                          {currentCountriesCProduct !== null &&
                            currentCountriesCProduct.length !== 0 &&
                            btnProductC && (
                              <Fade top duration={1600} distance="20px">
                                <p id="title-complaints">Denuncias - Produtos</p>
                                {currentCountriesCProduct.map((preview) => (
                                  <div className="product" key={preview.id}>
                                    <Product data={preview.product} />
                                    <div className="buttons">
                                      <button
                                        onClick={() =>
                                          handleDeleteComplaintProduct(preview.id)
                                        }
                                      >
                                        Excluir
                                      </button>
                                    </div>
                                  </div>
                                ))}

                                <div className="floatPage">
                                  <Pagination
                                    currentPage={currentPageCProduct}
                                    countriesPerPage={countriesPerPageCProduct}
                                    totalCountries={complaintProduct.length}
                                    paginate={paginateCProduct}
                                  />
                                </div>
                              </Fade>
                            )}

                          {currentCountriesCChallenge !== null &&
                            currentCountriesCChallenge.length !== 0 &&
                            btnChallengeC && (
                              <Fade top duration={1600} distance="20px">
                                <p id="title-complaints">Denuncias - Desafios</p>
                                {currentCountriesCChallenge.map((preview) => (
                                  <div className="challenge" key={preview.id}>
                                    <Challenge
                                      key={preview.id}
                                      data={preview.challenge}
                                    />

                                    <div className="buttons">
                                      <button
                                        onClick={() =>
                                          handleDeleteComplaintChallenge(
                                            preview.id
                                          )
                                        }
                                      >
                                        Excluir
                                      </button>
                                    </div>
                                  </div>
                                ))}

                                <div className="floatPage">
                                  <Pagination
                                    currentPage={currentPageCChallenge}
                                    countriesPerPage={countriesPerPageCChallenge}
                                    totalCountries={complaintChallenge.length}
                                    paginate={paginateCChallenge}
                                  />
                                </div>
                              </Fade>
                            )}
                        </>
                      )}
                  </CollaborationsComponent>
                </Fade>
              )}

              {shopping && (
                <Fade left duration={1600} distance="20px">
                  <ShoppingComponent>
                    <p id="title">Pagamentos</p>
                    <div className="buttons">
                      <ButtonDonation
                        active={btnProjectDonations}
                        onClick={toggleBtnProject}
                      >
                        Projetos
                      </ButtonDonation>
                      <ButtonDonation
                        active={btnCosmosDonations}
                        onClick={toggleBtnCosmos}
                      >
                        Fundo Cosmos
                      </ButtonDonation>
                    </div>

                    {btnProjectDonations && (
                      <>
                        <div className="content">
                          {currentCountriesPayments.map((preview) => (
                            <ProjectSecond
                              key={preview.id}
                              success={
                                preview.state === "approved" ? true : false
                              }
                              cancel={preview.state === "cancel" ? true : false}
                            >
                              <div className="content-1">
                                <img
                                  src={
                                    preview.project &&
                                    preview.project.first &&
                                    preview.project.first.url
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content-2">
                                <div className="titles">
                                  <p id="title-project">
                                    {preview.project && preview.project.name}
                                  </p>
                                  {preview && preview.project.special ? (
                                    <div>
                                      <FaLockOpen color="#227af1" size={16} />

                                      <p>Tenho Permissão Especial</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <img src={iconCompanyProject} alt="" />
                                      <p>
                                        Instituição:
                                        {preview &&
                                          preview.project.institution.split(
                                            ";"
                                          )[0]}
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <img src={iconCheck} alt="" />
                                    <p>
                                      Categoria:
                                      {preview.project &&
                                        preview.project.category}
                                    </p>
                                  </div>
                                  <section> </section>
                                </div>
                                <div className="about-user">
                                  <div className="box">
                                    <img src={id} alt="" />
                                    <p>
                                      id: {preview.user_id} - {preview.perfil}
                                    </p>
                                  </div>
                                  <div className="box">
                                    <img src={user} alt="" />
                                    <p>Nome: {preview.name}</p>
                                  </div>
                                  <div className="box">
                                    <FaPaypal size={20} color="#000" />
                                    <p>{preview.paypal_id}</p>
                                  </div>
                                </div>
                                <section> </section>
                                <div className="donation">
                                  <p> Doou para este projeto: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                              <div className="content-3">
                                <img src={atomProject} alt="" />
                                <p>
                                  <Link to="project"> Ver projeto </Link>
                                </p>
                              </div>
                            </ProjectSecond>
                          ))}
                        </div>

                        {payments.length !== 0 && (
                          <div className="floatPage">
                            <Pagination
                              currentPage={currentPagePayments}
                              countriesPerPage={countriesPerPagePayments}
                              totalCountries={payments.length}
                              paginate={paginatePayments}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {btnCosmosDonations && (
                      <>
                        <div className="content">
                          {currentCountriesCosmos.map((preview) => (
                            <Cosmos
                              key={preview.id}
                              success={
                                preview.state === "approved" ? true : false
                              }
                              cancel={preview.state === "cancel" ? true : false}
                            >
                              <div className="content-1">
                                <img src={logo} alt="" />
                              </div>
                              <p id="title-cosmos">Fundo Cosmos</p>
                              <div className="content-2">
                                <div className="about-user">
                                  <div className="box">
                                    <img src={id} alt="" />
                                    <p>
                                      id: {preview.user_id} - {preview.perfil}
                                    </p>
                                  </div>
                                  <div className="box">
                                    <img src={user} alt="" />
                                    <p>Nome: {preview.name}</p>
                                  </div>
                                  <div className="box">
                                    <FaPaypal size={20} color="#000" />
                                    <p>{preview.paypal_id}</p>
                                  </div>
                                </div>

                                <section></section>

                                <div className="donation">
                                  <p> Doou: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                            </Cosmos>
                          ))}
                        </div>

                        {cosmos.length !== 0 && (
                          <div className="floatPage">
                            <Pagination
                              currentPage={currentPageCosmos}
                              countriesPerPage={countriesPerPageCosmos}
                              totalCountries={cosmos.length}
                              paginate={paginateCosmos}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </ShoppingComponent>
                </Fade>
              )}
              {goals && (
                <Fade left duration={1600} distance="20px">
                  <GoalsComponent>
                    <p id="title">Novas Metas - Projetos</p>
                    <div className="content">
                      {currentCountriesGoals.map((preview) => (
                        <div className="project" key={preview.id}>
                          <ProjectGoals>
                            <div className="content-1">
                              <img
                                src={
                                  preview.project &&
                                  preview.project.first &&
                                  preview.project.first.url
                                }
                                alt=""
                              />
                            </div>

                            <div className="content-2">
                              <div className="titles">
                                <p id="title-project">{preview.project.name}</p>
                                {preview && preview.project.special ? (
                                  <div>
                                    <FaLockOpen color="#227af1" size={16} />

                                    <p>Tenho Permissão Especial</p>
                                  </div>
                                ) : (
                                  <div>
                                    <img src={iconCompanyProject} alt="" />
                                    <p>
                                      Instituição:
                                      {preview &&
                                        preview.project.institution.split(
                                          ";"
                                        )[0]}
                                    </p>
                                  </div>
                                )}

                                <div>
                                  <img src={iconCheckBlue} alt="" />
                                  <p> Categoria: {preview.project.category}</p>
                                </div>
                                <section></section>
                              </div>

                              <div className="text-content">
                                <p id="text-content-justify">
                                  Justificativa para solicitação:
                                </p>
                                <p>{preview && preview.justify}</p>
                                <section></section>
                              </div>

                              <div className="cash">
                                <div className="values">
                                  <p id="meta">
                                    Meta Antiga: R$ {preview.project.value_goal}
                                  </p>
                                  <p id="meta">
                                    Meta Solicitada: R$ {preview.value_goal}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="content-3">
                              <img src={atomProject} alt="" />
                              <p>
                                <Link to={`project/${preview.project.id}`}>
                                  Conheça o projeto
                                </Link>
                              </p>
                            </div>
                          </ProjectGoals>
                          <div className="buttons">
                            {preview.accept ? (
                              <ButtonAccept accept={preview.accept}>
                                Aprovado
                              </ButtonAccept>
                            ) : (
                              <>
                                <ButtonAccept
                                  onClick={() =>
                                    acceptGoals(parseInt(preview.id))
                                  }
                                >
                                  Aprovar
                                </ButtonAccept>
                                <ButtonAccept
                                  onClick={() =>
                                    disapproveGoals(parseInt(preview.id))
                                  }
                                >
                                  Reprovar
                                </ButtonAccept>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    {arrGoals.length !== 0 && (
                      <div className="floatPage">
                        <Pagination
                          currentPage={currentPageGoals}
                          countriesPerPage={countriesPerPageGoals}
                          totalCountries={arrGoals.length}
                          paginate={paginateGoals}
                        />
                      </div>
                    )}
                  </GoalsComponent>
                </Fade>
              )}
              {categories && (
                <Fade left duration={1600} distance="20px">
                  <CategoriesComponent>
                    <p id="title">Novas Categorias</p>
                    <div className="buttons">
                      <ButtonDonation
                        type="button"
                        active={sendedCategories}
                        onClick={handleSendedCategories}
                      >
                        Categorias Enviadas
                      </ButtonDonation>
                      <ButtonDonation
                        type="button"
                        active={myNewCategories}
                        onClick={handleMyNewCategories}
                      >
                        Cadastrar nova Categoria
                      </ButtonDonation>
                    </div>

                    {sendedCategories && (
                      <>
                        <div className="content">
                          {arrCategories.length > 0 &&
                            currentCountriesCategories.map((preview) => (
                              <div className="category" key={preview.id}>
                                <Category>
                                  <div className="box">
                                    <img src={iconId} alt="" />
                                    <p>Id: {preview.id}</p>
                                  </div>
                                  <div className="box">
                                    <img src={iconUser} alt="" />
                                    <p>Nome: {preview.name}</p>
                                  </div>

                                  <hr />
                                  <div className="box-category">
                                    <p>Categoria Solicitada:</p>
                                    <p id="category">{preview.category}</p>
                                  </div>
                                </Category>
                                <div className="buttons">
                                  {preview.accept ? (
                                    <ButtonAccept accept={preview.accept}>
                                      Aprovado
                                    </ButtonAccept>
                                  ) : (
                                    <>
                                      <ButtonAccept
                                        onClick={() =>
                                          acceptCategories(parseInt(preview.id))
                                        }
                                      >
                                        Aprovar
                                      </ButtonAccept>
                                      <ButtonAccept
                                        onClick={() =>
                                          disapproveCategories(
                                            parseInt(preview.id)
                                          )
                                        }
                                      >
                                        Reprovar
                                      </ButtonAccept>
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                        {arrCategories.length !== 0 && (
                          <div className="floatPage">
                            <Pagination
                              currentPage={currentPageCategories}
                              countriesPerPage={countriesPerPageCategories}
                              totalCountries={arrCategories.length}
                              paginate={paginateCategories}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {myNewCategories && (
                      <>
                        <Form onSubmit={submitCategories}>
                          <Input
                            maxLength={32}
                            name="category"
                            placeholder="Nome da Categoria:"
                          />
                          <button id="btn-submit">Cadastrar</button>
                        </Form>

                        <hr />
                        {currentCountriesMyRegisterCategories.map((preview) => (
                          <MyCategories key={preview.id}>
                            <input
                              defaultValue={preview.category}
                              onChange={(event) =>
                                setEditCategoryValue(event.target.value)
                              }
                              placeholder="Categoria: "
                              className="input-teste"
                              maxLength={32}
                            />

                            <div className="buttons-category">
                              <button
                                type="submit"
                                onClick={() => updateCategory(preview.id)}
                              >
                                Alterar
                              </button>
                              <button
                                type="button"
                                onClick={() => deleteCategories(preview.id)}
                              >
                                Excluir
                              </button>
                            </div>
                          </MyCategories>
                        ))}
                        {myRegisterCategories.length !== 0 && (
                          <div className="floatPage">
                            <Pagination
                              currentPage={currentPageMyRegisterCategories}
                              countriesPerPage={
                                countriesPerPageMyRegisterCategories
                              }
                              totalCountries={myRegisterCategories.length}
                              paginate={paginateMyRegisterCategories}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </CategoriesComponent>
                </Fade>
              )}

              {requests && (
                <Fade left duration={1600} distance="20px">
                  <RequestComponent>
                    <p id="title">Projetos Para Aprovação</p>
                    <div className="content">
                      <div className="projects">
                        {arrProjectsAnalysis.length !== 0 &&
                          currentCountriesAnalysis.map((preview) => (
                            <div className="project" key={preview.id}>
                              <NormalProject>
                                <div className="content-1">
                                  {preview.first && <img src={preview.first.url} alt="" />}
                                </div>

                                <div className="content-2">
                                  <div className="titles">
                                    <p id="title-project">{preview.name}</p>
                                    {preview && preview.special ? (
                                      <div>
                                        <FaLockOpen color="#227af1" size={16} />

                                        <p>Tenho Permissão Especial</p>
                                      </div>
                                    ) : (
                                      <div>
                                        <img
                                          src={iconCompanyNormalProject}
                                          alt=""
                                        />
                                        <p>
                                          Instituição:
                                          {preview &&
                                            preview.institution.split(";")[0]}
                                        </p>
                                      </div>
                                    )}

                                    <div>
                                      <img src={iconCheckNormal} alt="" />
                                      <p> Categoria: {preview.category}</p>
                                    </div>
                                    <section></section>
                                  </div>

                                  <div className="text-content">
                                    <p>{preview && preview.summary}</p>
                                    <section></section>
                                  </div>

                                  <div className="cash">
                                    <div className="values">
                                      <p id="meta">
                                        Meta: R$ {preview.value_goal}
                                      </p>
                                      <div className="donation">
                                        <p>Doado:</p>
                                        <p id="donation-value">
                                          R$ {preview.current_value}
                                        </p>
                                      </div>
                                    </div>
                                    {parseInt(
                                      (preview.current_value * 100) /
                                      preview.value_goal
                                    ) === 100 ? (
                                      <CircleProgress
                                        percentage={parseInt(
                                          (preview.current_value * 100) /
                                          preview.value_goal
                                        )}
                                        strokeWidth={7}
                                        primaryColor={["#227af1", "#227af1"]}
                                        width={100}
                                        fontColor={"#227af1"}
                                        fontSize={"20px"}
                                        fontFamily={"GothamProRegular"}
                                      />
                                    ) : (
                                      <CircleProgress
                                        percentage={parseInt(
                                          (preview.current_value * 100) /
                                          preview.value_goal
                                        )}
                                        strokeWidth={7}
                                        primaryColor={["#d6d6e3", "#d6d6e3"]}
                                        width={100}
                                        fontColor={"#d6d6e3"}
                                        fontSize={"20px"}
                                        fontFamily={"GothamProRegular"}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="content-3">
                                  <img src={atomProject} alt="" />
                                  <p>
                                    <Link to={`project/${preview.id}`}>
                                      Conheça o projeto
                                    </Link>
                                  </p>
                                </div>
                              </NormalProject>
                              <div className="buttons">
                                <button
                                  onClick={() =>
                                    aproveProject(parseInt(preview.id))
                                  }
                                >
                                  Aprovar
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                      {arrProjectsAnalysis.length !== 0 && (
                        <div className="floatPage">
                          <Pagination
                            currentPage={currentPageAnalysis}
                            countriesPerPage={countriesPerPageAnalysis}
                            totalCountries={arrProjectsAnalysis.length}
                            paginate={paginateAnalysis}
                          />
                        </div>
                      )}
                    </div>
                  </RequestComponent>
                </Fade>
              )}
              {projects && (
                <Fade left duration={1600} distance="20px">
                  <ProjectsComponent>
                    <p id="title">Projetos que Atingiram a Meta</p>
                    <div className="content">
                      <div className="projects">
                        {arrProjects.length >= 0 &&
                          currentCountriesProjects.map((preview) => (
                            <NormalProject key={preview.id}>
                              <div className="content-1">
                                <img src={preview.first.url} alt="" />
                              </div>

                              <div className="content-2">
                                <div className="titles">
                                  <p id="title-project">{preview.name}</p>
                                  {preview && preview.special ? (
                                    <div>
                                      <FaLockOpen color="#227af1" size={16} />

                                      <p>Tenho Permissão Especial</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <img src={iconCompanyProject} alt="" />
                                      <p>
                                        Instituição:
                                        {preview &&
                                          preview.institution.split(";")[0]}
                                      </p>
                                    </div>
                                  )}

                                  <div>
                                    <img src={iconCheckNormal} alt="" />
                                    <p> Categoria: {preview.category}</p>
                                  </div>
                                  <section></section>
                                </div>

                                <div className="text-content">
                                  <p>{preview && preview.summary}</p>
                                  <section></section>
                                </div>

                                <div className="cash">
                                  <div className="values">
                                    <p id="meta">
                                      Meta: R$ {preview.value_goal}
                                    </p>
                                    <div className="donation">
                                      <p>Doado:</p>
                                      <p id="donation-value">
                                        R$ {preview.current_value}
                                      </p>
                                    </div>
                                  </div>
                                  {parseInt(
                                    (preview.current_value * 100) /
                                    preview.value_goal
                                  ) === 100 ? (
                                    <CircleProgress
                                      percentage={parseInt(
                                        (preview.current_value * 100) /
                                        preview.value_goal
                                      )}
                                      strokeWidth={7}
                                      primaryColor={["#227af1", "#227af1"]}
                                      width={100}
                                      fontColor={"#227af1"}
                                      fontSize={"20px"}
                                      fontFamily={"GothamProRegular"}
                                    />
                                  ) : (
                                    <CircleProgress
                                      percentage={parseInt(
                                        (preview.current_value * 100) /
                                        preview.value_goal
                                      )}
                                      strokeWidth={7}
                                      primaryColor={["#d6d6e3", "#d6d6e3"]}
                                      width={100}
                                      fontColor={"#d6d6e3"}
                                      fontSize={"20px"}
                                      fontFamily={"GothamProRegular"}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="content-3">
                                <img src={atomProject} alt="" />
                                <p>
                                  <Link to={`project/${preview.id}`}>
                                    Conheça o projeto
                                  </Link>
                                </p>
                              </div>
                            </NormalProject>
                          ))}
                      </div>
                    </div>
                    {arrProjects.length > 0 && (
                      <div className="floatPage">
                        <Pagination
                          currentPage={currentPageProjects}
                          countriesPerPage={countriesPerPageProjects}
                          totalCountries={arrProjects.length}
                          paginate={paginateProjects}
                        />
                      </div>
                    )}
                  </ProjectsComponent>
                </Fade>
              )}

              {searchUser && (
                <Fade left duration={1600} distance="20px">
                  <SearchComponent>
                    <p id="title">Recuperar Senha</p>
                    {arrRecover.length !== 0 && (
                      <>
                        <div className="fs-content">
                          <p id="title-content">Solicitações</p>
                          <div className="contacts">
                            {currentCountriesRecover.map((preview) => (
                              <Contact key={preview.id}>
                                <div className="title-transaction">
                                  <p id="text-title-transaction">
                                    {preview.subject}
                                  </p>
                                  <div className="data">
                                    <img
                                      id="icon-calendar"
                                      src={iconCalendarPurple}
                                      alt=""
                                    />
                                    <p id="day">{preview.formattedDate}</p>
                                  </div>
                                </div>
                                <hr />
                                <p id="message">{preview.message}</p>
                                <hr />
                                <div className="infos">
                                  <p>Nome: {preview.name}</p>
                                  <p>Email: {preview.email}</p>
                                  <p>Perfil: {preview.profile}</p>
                                  <p>Telefone: {preview.phone}</p>
                                </div>
                              </Contact>
                            ))}
                          </div>
                        </div>
                        <div className="floatPage">
                          <Pagination
                            currentPage={currentPageRecover}
                            countriesPerPage={countriesPerPageRecover}
                            totalCountries={arrRecover.length}
                            paginate={paginateRecover}
                          />
                        </div>
                      </>
                    )}

                    <hr />
                    <div className="sec-content">
                      <p id="title-content">Pesquisar por Usuário</p>
                      <div className="buttons-content-about">
                        <ButtonContacts
                          active={profileOne}
                          onClick={handleProfileOneClick}
                        >
                          Apoiador
                        </ButtonContacts>
                        <ButtonContacts
                          active={profileTwo}
                          onClick={handleProfileTwoClick}
                        >
                          Empresa
                        </ButtonContacts>
                        <ButtonContacts
                          active={profileThree}
                          onClick={handleProfileThreeClick}
                        >
                          Cientista
                        </ButtonContacts>
                      </div>

                      {profileOne && (
                        <Form
                          className="form-search-user"
                          onSubmit={handleSearchUserSupporters}
                        >
                          <Input placeholder="Nome:" name="name" />
                          <button id="btn-submit-search" type="submit">
                            Pesquisar
                          </button>
                          <button
                            id="btn-clean"
                            type="button"
                            onClick={() => setSearchUsers([])}
                          >
                            Limpar
                          </button>
                        </Form>
                      )}

                      {profileTwo && (
                        <Form
                          className="form-search-user"
                          onSubmit={handleSearchUserCompanies}
                        >
                          <Input placeholder="Nome Fantasia:" name="name" />
                          <button id="btn-submit-search" type="submit">
                            Pesquisar
                          </button>
                          <button
                            id="btn-clean"
                            type="button"
                            onClick={() => setSearchUsers([])}
                          >
                            Limpar
                          </button>
                        </Form>
                      )}

                      {profileThree && (
                        <Form
                          className="form-search-user"
                          onSubmit={handleSearchUserScientists}
                        >
                          <Input placeholder="Nome:" name="name" />
                          <button id="btn-submit-search" type="submit">
                            Pesquisar
                          </button>
                          <button
                            id="btn-clean"
                            type="button"
                            onClick={() => setSearchUsers([])}
                          >
                            Limpar
                          </button>
                        </Form>
                      )}

                      {searchUsers.length !== 0 && (
                        <Fade top duration={1600} distance="20px">
                          <ul className="box-search-users">
                            <Scrollbars>
                              {searchUsers.map((preview) => (
                                <li key={preview.id}>
                                  <div className="datas">
                                    <p id="field">ID:</p>
                                    <p id="data">{preview.id}</p>
                                  </div>

                                  <div className="datas">
                                    <p id="field">Nome:</p>
                                    <p id="data">{preview.name}</p>
                                  </div>

                                  <div className="datas">
                                    <p id="field">E-mail:</p>
                                    <p id="data">{preview.email}</p>
                                  </div>
                                  <div className="datas">
                                    <p id="field">Telefone:</p>
                                    <p id="data">{preview.phone}</p>
                                  </div>
                                </li>
                              ))}
                            </Scrollbars>
                          </ul>
                        </Fade>
                      )}
                    </div>
                  </SearchComponent>
                </Fade>
              )}
              {challenges && (
                <Fade left duration={1600} distance="20px">
                  <ChallengesNotAprovedComponent>
                    <p id="title">Desafios Para Aprovação</p>
                    <div className="content">
                      <div className="challenges">
                        {currentCountriesChallengesNot.map((preview) => (
                          <div className="challenge" key={preview.id}>
                            <Challenge
                              data={preview}
                            />
                            <div className="buttons">
                              <button
                                onClick={() => aproveChallenge(preview.id)}
                              >
                                Aprovar
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>

                      {notAprovedChallenges.length !== 0 && (
                        <div className="floatPage">
                          <Pagination
                            currentPage={currentPageChallengesNot}
                            countriesPerPage={countriesPerPageChallengesNot}
                            totalCountries={notAprovedChallenges.length}
                            paginate={paginateChallengesNot}
                          />
                        </div>
                      )}
                    </div>
                  </ChallengesNotAprovedComponent>
                </Fade>
              )}
              {deletedUsers && (
                <Fade left duration={1600} distance="20px">
                  <DeletedUsersComponent>
                    <p id="title">Usuários Exluídos</p>
                    <div className="content">
                      {currentCountriesDeletedUsers.map((preview) => (
                        <div className="deleted-box" key={preview.id}>
                          <DeletedUser>
                            <div className="fs-content">
                              <p id="info">Id: {preview.user}</p>
                              <p id="info">Nome: {preview.name}</p>
                              <p id="info">Perfil: {preview.profile}</p>
                              <p id="info">Telefone: {preview.phone}</p>
                              <p id="info">Email: {preview.email}</p>
                            </div>

                            <div className="sec-content">
                              <p id="info">CEP: {preview.cep}</p>
                              <p id="info">Rua: {preview.adress}</p>
                              <p id="info">Bairro: {preview.neighborhood}</p>
                              <p id="info">Estado: {preview.state}</p>
                              <p id="info">Cidade: {preview.city}</p>
                            </div>
                          </DeletedUser>
                          <div>
                            {!preview.aproved && (
                              <button
                                type="button"
                                onClick={() => deleteUser(preview.id)}
                              >
                                Excluir Usuário
                              </button>
                            )}
                            <p>Solicitação feita em {preview.formattedDate}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    {deletedUsersArr.length !== 0 && (
                      <div className="floatPage">
                        <Pagination
                          currentPage={currentPageDeletedUsers}
                          countriesPerPage={countriesPerPageDeletedUsers}
                          totalCountries={deletedUsersArr.length}
                          paginate={paginateDeletedUsers}
                        />
                      </div>
                    )}
                  </DeletedUsersComponent>
                </Fade>
              )}
              {contactSupporters && (
                <SupportComponent>
                  <p id="title">Suporte</p>
                  {currentCountriesSupport.map((preview) => (
                    <div className="item" key={preview.id}>
                      <Contact>
                        <div className="title-transaction">
                          <p id="text-title-transaction">{preview.subject}</p>
                          <div className="data">
                            <img
                              id="icon-calendar"
                              src={iconCalendarPurple}
                              alt=""
                            />
                            <p id="day">{preview.formattedDate}</p>
                          </div>
                        </div>
                        <hr />
                        <p id="message">{preview.message}</p>
                        <hr />
                        <div className="infos">
                          <p>Nome: {preview.name}</p>
                          <p>Email: {preview.email}</p>
                          <p>Perfil: {preview.profile}</p>
                          <p>Telefone: {preview.phone}</p>
                        </div>
                      </Contact>
                      <button
                        type="button"
                        onClick={() => deleteContact(preview.id)}
                      >
                        Excluir
                      </button>
                    </div>
                  ))}
                  {support.length !== 0 && (
                    <div className="floatPage">
                      <Pagination
                        currentPage={currentPageSupport}
                        countriesPerPage={countriesPerPageSupport}
                        totalCountries={support.length}
                        paginate={paginateSupport}
                      />
                    </div>
                  )}
                </SupportComponent>
              )}

              {notifications && (
                <Fade left duration={1600} distance="20px">
                  <NotificationsComponent>
                    <p id="title-notifications">Notificações</p>
                    <div className="buttons">
                      <ButtonDonation active={buttonOne} onClick={handleBtnOne}>
                        Individual
                      </ButtonDonation>
                      <ButtonDonation active={buttonAll} onClick={handleBtnAll}>
                        Em Massa
                      </ButtonDonation>
                    </div>

                    <p style={{ color: "#7f7fb9", marginBottom: 15 }}>Perfil</p>

                    <div className="buttons">
                      <ButtonDonation
                        type="button"
                        active={fsProfile}
                        onClick={handleFsProfile}
                      >
                        Apoiador
                      </ButtonDonation>
                      <ButtonDonation
                        type="button"
                        active={secProfile}
                        onClick={handleSecProfile}
                      >
                        Empresa
                      </ButtonDonation>
                      <ButtonDonation
                        type="button"
                        active={thirdProfile}
                        onClick={handleThirdProfile}
                      >
                        Cientista
                      </ButtonDonation>
                    </div>

                    {buttonOne && (
                      <>
                        {fsProfile && (
                          <div>
                            <p style={{ color: "#7f7fb9", marginBottom: 15 }}>
                              Pesquisar Usuário
                            </p>
                            <Form
                              className="form-search-user"
                              onSubmit={handleSearchUserSupporters}
                            >
                              <Input placeholder="Nome:" name="name" />
                              <button id="btn-submit-search" type="submit">
                                Pesquisar
                              </button>
                              <button
                                id="btn-clean"
                                type="button"
                                onClick={() => setSearchUsers([])}
                              >
                                Limpar
                              </button>
                            </Form>
                          </div>
                        )}
                        {secProfile && (
                          <div>
                            <p style={{ color: "#7f7fb9", marginBottom: 15 }}>
                              Pesquisar Usuário
                            </p>
                            <Form
                              className="form-search-user"
                              onSubmit={handleSearchUserCompanies}
                            >
                              <Input placeholder="Nome:" name="name" />
                              <button id="btn-submit-search" type="submit">
                                Pesquisar
                              </button>
                              <button
                                id="btn-clean"
                                type="button"
                                onClick={() => setSearchUsers([])}
                              >
                                Limpar
                              </button>
                            </Form>
                          </div>
                        )}
                        {thirdProfile && (
                          <div>
                            <p style={{ color: "#7f7fb9", marginBottom: 15 }}>
                              Pesquisar Usuário
                            </p>
                            <Form
                              className="form-search-user"
                              onSubmit={handleSearchUserScientists}
                            >
                              <Input placeholder="Nome:" name="name" />
                              <button id="btn-submit-search" type="submit">
                                Pesquisar
                              </button>
                              <button
                                id="btn-clean"
                                type="button"
                                onClick={() => setSearchUsers([])}
                              >
                                Limpar
                              </button>
                            </Form>
                          </div>
                        )}

                        {searchUsers.length !== 0 && (
                          <Fade top duration={1600} distance="20px">
                            <ul className="box-search-users">
                              <Scrollbars>
                                {searchUsers.map((preview) => (
                                  <li key={preview.id}>
                                    <div className="datas">
                                      <p id="field">ID:</p>
                                      <p id="data">{preview.id}</p>
                                    </div>

                                    <div className="datas">
                                      <p id="field">Nome:</p>
                                      <p id="data">{preview.name}</p>
                                    </div>

                                    <div className="datas">
                                      <p id="field">E-mail:</p>
                                      <p id="data">{preview.email}</p>
                                    </div>
                                  </li>
                                ))}
                              </Scrollbars>
                            </ul>
                          </Fade>
                        )}

                        <hr />

                        <p style={{ color: "#7f7fb9", marginBottom: 15 }}>
                          Enviar Notificação
                        </p>
                        <Form onSubmit={submitIndividual}>
                          <Input
                            name="user_id"
                            placeholder="Id do Usuário:"
                            style={{ marginBottom: 20 }}
                          />

                          <Input
                            name="title"
                            placeholder="Título:"
                            style={{ marginBottom: 20 }}
                          />
                          <Textarea
                            className="message"
                            name="content"
                            placeholder="Conteúdo:"
                          />
                          <Input
                            className="full"
                            name="link"
                            style={{ marginBottom: 20 }}
                            placeholder="Link para conteúdo na plataforma (sem www.we4science.com):"
                          />
                          <button id="btn-submit" type="submit">
                            Enviar
                          </button>
                        </Form>
                      </>
                    )}

                    {buttonAll && (
                      <Form onSubmit={submitAll}>
                        <Input
                          name="title"
                          placeholder="Título:"
                          style={{ marginBottom: 20 }}
                        />
                        <Textarea
                          className="message"
                          name="content"
                          placeholder="Conteúdo:"
                        />
                        <Input
                          className="full"
                          name="link"
                          placeholder="Link para conteúdo na plataforma (sem www.we4science.com):"
                          style={{ marginBottom: 20 }}
                        />
                        <button id="btn-submit" type="submit">
                          Enviar
                        </button>
                      </Form>
                    )}
                  </NotificationsComponent>
                </Fade>
              )}

              {users && (
                <Fade left duration={1600} distance="20px">
                  <UsersComponents>
                    <p id="title-component">Usuários</p>

                    <div className="buttons">
                      <ButtonDonation
                        type="button"
                        active={fsProfile}
                        onClick={handleFsProfile}
                      >
                        Apoiador
                      </ButtonDonation>
                      <ButtonDonation
                        type="button"
                        active={secProfile}
                        onClick={handleSecProfile}
                      >
                        Empresa
                      </ButtonDonation>
                      <ButtonDonation
                        type="button"
                        active={thirdProfile}
                        onClick={handleThirdProfile}
                      >
                        Cientista
                      </ButtonDonation>
                    </div>

                    {fsProfile && (
                      <>
                        <div className="content">
                          <BoxProfile support>
                            <img src={iconSupporter} alt="" />
                            <div className="box-profile">
                              <p id="normal">
                                {allSupporters.length} usuários cadastrados no
                              </p>
                              <p id="normal">
                                <strong>Perfil</strong> Apoiador
                              </p>
                            </div>
                          </BoxProfile>
                        </div>

                        {
                          <ul style={{ width: 720, height: 670 }}>
                            <Scrollbars>
                              {allSupporters.map((preview) => (
                                <li key={preview.id} className="user-box">
                                  <UserComponent
                                    active={
                                      preview.avatar !== null ? true : false
                                    }
                                  >
                                    {preview.avatar !== null && (
                                      <img src={preview.avatar.url} alt="" />
                                    )}
                                    <div className="box-infos">
                                      <p id="title-box-infos">Dados Pessoais</p>
                                      <div className="datas">
                                        <p id="field">ID:</p>
                                        <p id="data">{preview.id}</p>
                                      </div>
                                      <div className="datas">
                                        <p id="field">Nome:</p>
                                        <p id="data">{preview.name}</p>
                                      </div>

                                      <div className="datas">
                                        <p id="field">E-mail:</p>
                                        <p id="data">{preview.email}</p>
                                      </div>

                                      <div className="datas">
                                        <p id="field">Telefone: </p>
                                        <p id="data">{preview.phone}</p>
                                      </div>

                                      {preview.cpf !== null && (
                                        <div className="datas">
                                          <p id="field">CPF:</p>
                                          <p id="data">{preview.cpf}</p>
                                        </div>
                                      )}

                                      <div className="datas">
                                        <p id="field">Whuffie:</p>
                                        <p id="data">{preview.whuffie}</p>
                                      </div>
                                    </div>
                                    <hr />

                                    <div className="box-infos">
                                      <p id="title-box-infos">Endereço</p>
                                      {preview.adress !== null && (
                                        <div className="datas">
                                          <p id="field">Rua:</p>
                                          <p id="data">{preview.adress}</p>
                                        </div>
                                      )}
                                      {preview.neighborhood !== null && (
                                        <div className="datas">
                                          <p id="field">Bairro:</p>
                                          <p id="data">
                                            {preview.neighborhood}
                                          </p>
                                        </div>
                                      )}
                                      {preview.state !== null && (
                                        <div className="datas">
                                          <p id="field">Estado:</p>
                                          <p id="data">{preview.state}</p>
                                        </div>
                                      )}
                                      {preview.city !== null && (
                                        <div className="datas">
                                          <p id="field">Cidade:</p>
                                          <p id="data">{preview.city}</p>
                                        </div>
                                      )}
                                      {preview.cep !== null && (
                                        <div className="datas">
                                          <p id="field">CEP:</p>
                                          <p id="data">{preview.cep}</p>
                                        </div>
                                      )}
                                    </div>
                                    <hr />

                                    <div className="box-infos">
                                      <p id="title-box-infos">Redes Sociais</p>
                                      {preview.whatsapp !== null && (
                                        <div className="datas">
                                          <p id="field">Whatsapp:</p>
                                          <p id="data">{preview.whatsapp}</p>
                                        </div>
                                      )}
                                      {preview.facebook !== null && (
                                        <div className="datas">
                                          <p id="field">Facebook:</p>
                                          <p id="data">{preview.facebook}</p>
                                        </div>
                                      )}
                                      {preview.linkedin !== null && (
                                        <div className="datas">
                                          <p id="field">Linkedin:</p>
                                          <p id="data">{preview.linkedin}</p>
                                        </div>
                                      )}
                                      {preview.twitter !== null && (
                                        <div className="datas">
                                          <p id="field">Twitter:</p>
                                          <p id="data">{preview.twitter}</p>
                                        </div>
                                      )}
                                      {preview.instagram !== null && (
                                        <div className="datas">
                                          <p id="field">Instagram:</p>
                                          <p id="data">{preview.instagram}</p>
                                        </div>
                                      )}
                                    </div>
                                  </UserComponent>
                                  <button
                                    id="btn-delete"
                                    type="button"
                                    onClick={() =>
                                      deleteSupportUser(preview.id)
                                    }
                                  >
                                    Excluir
                                  </button>
                                </li>
                              ))}
                            </Scrollbars>
                          </ul>
                        }
                      </>
                    )}

                    {secProfile && (
                      <>
                        <div className="content">
                          <BoxProfile company>
                            <img src={iconCompany} alt="" />
                            <div className="box-profile">
                              <p id="normal">
                                {allCompanies.length} usuários cadastrados no
                              </p>
                              <p id="normal">
                                <strong>Perfil</strong> Empresarial
                              </p>
                            </div>
                          </BoxProfile>
                        </div>

                        {
                          <ul style={{ width: 720, height: 670 }}>
                            <Scrollbars>
                              {allCompanies.map((preview) => (
                                <li key={preview.id} className="user-box">
                                  <UserComponent
                                    active={
                                      preview.avatar !== null ? true : false
                                    }
                                  >
                                    {preview.avatar !== null && (
                                      <img src={preview.avatar.url} alt="" />
                                    )}
                                    <div className="box-infos">
                                      <p id="title-box-infos">Dados </p>
                                      <div className="datas">
                                        <p id="field">ID:</p>
                                        <p id="data">{preview.id}</p>
                                      </div>
                                      <div className="datas">
                                        <p id="field">Nome:</p>
                                        <p id="data">{preview.name}</p>
                                      </div>

                                      <div className="datas">
                                        <p id="field">E-mail:</p>
                                        <p id="data">{preview.email}</p>
                                      </div>

                                      <div className="datas">
                                        <p id="field">Telefone: </p>
                                        <p id="data">{preview.phone}</p>
                                      </div>

                                      {preview.cpf !== null && (
                                        <div className="datas">
                                          <p id="field">CPF:</p>
                                          <p id="data">{preview.cnpj}</p>
                                        </div>
                                      )}

                                      <div className="datas">
                                        <p id="field">Whuffie:</p>
                                        <p id="data">{preview.whuffie}</p>
                                      </div>
                                    </div>
                                    <hr />

                                    <div className="box-infos">
                                      <p id="title-box-infos">Endereço</p>
                                      {preview.adress !== null && (
                                        <div className="datas">
                                          <p id="field">Rua:</p>
                                          <p id="data">{preview.adress}</p>
                                        </div>
                                      )}
                                      {preview.neighborhood !== null && (
                                        <div className="datas">
                                          <p id="field">Bairro:</p>
                                          <p id="data">
                                            {preview.neighborhood}
                                          </p>
                                        </div>
                                      )}
                                      {preview.state !== null && (
                                        <div className="datas">
                                          <p id="field">Estado:</p>
                                          <p id="data">{preview.state}</p>
                                        </div>
                                      )}
                                      {preview.city !== null && (
                                        <div className="datas">
                                          <p id="field">Cidade:</p>
                                          <p id="data">{preview.city}</p>
                                        </div>
                                      )}
                                      {preview.cep !== null && (
                                        <div className="datas">
                                          <p id="field">CEP:</p>
                                          <p id="data">{preview.cep}</p>
                                        </div>
                                      )}
                                    </div>
                                    <hr />

                                    <div className="box-infos">
                                      <p id="title-box-infos">Redes Sociais</p>
                                      {preview.whatsapp !== null && (
                                        <div className="datas">
                                          <p id="field">Whatsapp:</p>
                                          <p id="data">{preview.whatsapp}</p>
                                        </div>
                                      )}
                                      {preview.facebook !== null && (
                                        <div className="datas">
                                          <p id="field">Facebook:</p>
                                          <p id="data">{preview.facebook}</p>
                                        </div>
                                      )}
                                      {preview.linkedin !== null && (
                                        <div className="datas">
                                          <p id="field">Linkedin:</p>
                                          <p id="data">{preview.linkedin}</p>
                                        </div>
                                      )}
                                      {preview.twitter !== null && (
                                        <div className="datas">
                                          <p id="field">Twitter:</p>
                                          <p id="data">{preview.twitter}</p>
                                        </div>
                                      )}
                                      {preview.instagram !== null && (
                                        <div className="datas">
                                          <p id="field">Instagram:</p>
                                          <p id="data">{preview.instagram}</p>
                                        </div>
                                      )}
                                    </div>
                                  </UserComponent>
                                  <button
                                    id="btn-delete"
                                    type="button"
                                    onClick={() =>
                                      deleteCompanyUser(preview.id)
                                    }
                                  >
                                    Excluir
                                  </button>
                                </li>
                              ))}
                            </Scrollbars>
                          </ul>
                        }
                      </>
                    )}

                    {thirdProfile && (
                      <>
                        <div className="content">
                          <BoxProfile scientist>
                            <img src={iconScientists} alt="" />
                            <div className="box-profile">
                              <p id="normal">
                                {allScientists.length} usuários cadastrados no
                              </p>
                              <p id="normal">
                                <strong>Perfil</strong> Cientista
                              </p>
                            </div>
                          </BoxProfile>
                        </div>

                        {
                          <ul style={{ width: 720, height: 670 }}>
                            <Scrollbars>
                              {allScientists.map((preview) => (
                                <li key={preview.id} className="user-box">
                                  <UserComponent
                                    active={
                                      preview.avatar !== null ? true : false
                                    }
                                  >
                                    {preview.avatar !== null && (
                                      <img src={preview.avatar.url} alt="" />
                                    )}
                                    <div className="box-infos">
                                      <p id="title-box-infos">Dados </p>
                                      <div className="datas">
                                        <p id="field">ID:</p>
                                        <p id="data">{preview.id}</p>
                                      </div>
                                      <div className="datas">
                                        <p id="field">Nome:</p>
                                        <p id="data">{preview.name}</p>
                                      </div>

                                      <div className="datas">
                                        <p id="field">E-mail:</p>
                                        <p id="data">{preview.email}</p>
                                      </div>

                                      <div className="datas">
                                        <p id="field">Telefone: </p>
                                        <p id="data">{preview.phone}</p>
                                      </div>

                                      {preview.cpf !== null && (
                                        <div className="datas">
                                          <p id="field">CPF:</p>
                                          <p id="data">{preview.cnpj}</p>
                                        </div>
                                      )}

                                      <div className="datas">
                                        <p id="field">Whuffie:</p>
                                        <p id="data">{preview.whuffie}</p>
                                      </div>
                                    </div>
                                    <hr />

                                    <div className="box-infos">
                                      <p id="title-box-infos">Endereço</p>
                                      {preview.adress !== null && (
                                        <div className="datas">
                                          <p id="field">Rua:</p>
                                          <p id="data">{preview.adress}</p>
                                        </div>
                                      )}
                                      {preview.neighborhood !== null && (
                                        <div className="datas">
                                          <p id="field">Bairro:</p>
                                          <p id="data">
                                            {preview.neighborhood}
                                          </p>
                                        </div>
                                      )}
                                      {preview.state !== null && (
                                        <div className="datas">
                                          <p id="field">Estado:</p>
                                          <p id="data">{preview.state}</p>
                                        </div>
                                      )}
                                      {preview.city !== null && (
                                        <div className="datas">
                                          <p id="field">Cidade:</p>
                                          <p id="data">{preview.city}</p>
                                        </div>
                                      )}
                                      {preview.cep !== null && (
                                        <div className="datas">
                                          <p id="field">CEP:</p>
                                          <p id="data">{preview.cep}</p>
                                        </div>
                                      )}
                                    </div>
                                    <hr />

                                    <div className="box-infos">
                                      <p id="title-box-infos">Redes Sociais</p>
                                      {preview.whatsapp !== null && (
                                        <div className="datas">
                                          <p id="field">Whatsapp:</p>
                                          <p id="data">{preview.whatsapp}</p>
                                        </div>
                                      )}
                                      {preview.facebook !== null && (
                                        <div className="datas">
                                          <p id="field">Facebook:</p>
                                          <p id="data">{preview.facebook}</p>
                                        </div>
                                      )}
                                      {preview.linkedin !== null && (
                                        <div className="datas">
                                          <p id="field">Linkedin:</p>
                                          <p id="data">{preview.linkedin}</p>
                                        </div>
                                      )}
                                      {preview.twitter !== null && (
                                        <div className="datas">
                                          <p id="field">Twitter:</p>
                                          <p id="data">{preview.twitter}</p>
                                        </div>
                                      )}
                                      {preview.instagram !== null && (
                                        <div className="datas">
                                          <p id="field">Instagram:</p>
                                          <p id="data">{preview.instagram}</p>
                                        </div>
                                      )}
                                    </div>
                                  </UserComponent>
                                  <button
                                    id="btn-delete"
                                    type="button"
                                    onClick={() =>
                                      deleteScientistUser(preview.id)
                                    }
                                  >
                                    Excluir
                                  </button>
                                </li>
                              ))}
                            </Scrollbars>
                          </ul>
                        }
                      </>
                    )}
                  </UsersComponents>
                </Fade>
              )}

              {creditWhuffie && (
                <Fade left duration={1600} distance="20px">
                  <CreditWhuffieComponent>
                    <p id="title">Tranferência de Moedas Whuffies</p>
                    <div className="content">
                      <div>
                        <p id="sub-title">Tranferir para Usuário</p>
                        <div className="buttons">
                          <ButtonDonation
                            type="button"
                            active={fsProfile}
                            onClick={handleFsProfile}
                          >
                            Apoiador
                          </ButtonDonation>
                          <ButtonDonation
                            type="button"
                            active={secProfile}
                            onClick={handleSecProfile}
                          >
                            Empresa
                          </ButtonDonation>
                          <ButtonDonation
                            type="button"
                            active={thirdProfile}
                            onClick={handleThirdProfile}
                          >
                            Cientista
                          </ButtonDonation>
                        </div>

                        {fsProfile && (
                          <div>
                            <p style={{ color: "#7f7fb9", marginBottom: 15 }}>
                              Pesquisar Usuário
                            </p>
                            <Form
                              className="form-search-user"
                              onSubmit={handleSearchUserSupporters}
                            >
                              <Input placeholder="Nome:" name="name" />
                              <button id="btn-submit-search" type="submit">
                                Pesquisar
                              </button>
                              <button
                                id="btn-clean"
                                type="button"
                                onClick={() => setSearchUsers([])}
                              >
                                Limpar
                              </button>
                            </Form>
                          </div>
                        )}
                        {secProfile && (
                          <div>
                            <p style={{ color: "#7f7fb9", marginBottom: 15 }}>
                              Pesquisar Usuário
                            </p>
                            <Form
                              className="form-search-user"
                              onSubmit={handleSearchUserCompanies}
                            >
                              <Input placeholder="Nome:" name="name" />
                              <button id="btn-submit-search" type="submit">
                                Pesquisar
                              </button>
                              <button
                                id="btn-clean"
                                type="button"
                                onClick={() => setSearchUsers([])}
                              >
                                Limpar
                              </button>
                            </Form>
                          </div>
                        )}
                        {thirdProfile && (
                          <div>
                            <p style={{ color: "#7f7fb9", marginBottom: 15 }}>
                              Pesquisar Usuário
                            </p>
                            <Form
                              className="form-search-user"
                              onSubmit={handleSearchUserScientists}
                            >
                              <Input placeholder="Nome:" name="name" />
                              <button id="btn-submit-search" type="submit">
                                Pesquisar
                              </button>
                              <button
                                id="btn-clean"
                                type="button"
                                onClick={() => setSearchUsers([])}
                              >
                                Limpar
                              </button>
                            </Form>
                          </div>
                        )}

                        {searchUsers.length !== 0 && (
                          <Fade top duration={1600} distance="20px">
                            <ul
                              className="box-search-users"
                              style={{ marginBottom: 40 }}
                            >
                              <Scrollbars>
                                {searchUsers.map((preview) => (
                                  <li key={preview.id}>
                                    <div className="datas">
                                      <p id="field">ID:</p>
                                      <p id="data">{preview.id}</p>
                                    </div>

                                    <div className="datas">
                                      <p id="field">Nome:</p>
                                      <p id="data">{preview.name}</p>
                                    </div>

                                    <div className="datas">
                                      <p id="field">E-mail:</p>
                                      <p id="data">{preview.email}</p>
                                    </div>
                                  </li>
                                ))}
                              </Scrollbars>
                            </ul>
                          </Fade>
                        )}

                        <hr />

                        <Form className="form" onSubmit={creditPeople}>
                          <Input name="email" placeholder="Email: " />
                          <Input name="value" placeholder="Valor: " />
                          <button type="submit" id="btn-submit">
                            Enviar
                          </button>
                        </Form>
                      </div>
                      <hr />
                      <div>
                        <p id="sub-title">Tranferir para Projeto</p>

                        <Form className="form" onSubmit={creditProject}>
                          <Input name="id" placeholder="ID: " />
                          <Input name="value" placeholder="Valor: " />
                          <button type="submit" id="btn-submit">
                            Enviar
                          </button>
                        </Form>
                      </div>
                    </div>
                  </CreditWhuffieComponent>
                </Fade>
              )}

              {reports && (
                <Fade left duration={1600} distance="20px">
                  <ReportsComponent>
                    <p id="title">Relatórios</p>
                    <div>
                      <p id="sub-title">Usuários</p>
                      <div className="buttons">
                        <ButtonDonation
                          type="button"
                          active={fsProfile}
                          onClick={handleFsProfile}
                        >
                          Apoiador
                        </ButtonDonation>
                        <ButtonDonation
                          type="button"
                          active={secProfile}
                          onClick={handleSecProfile}
                        >
                          Empresa
                        </ButtonDonation>
                        <ButtonDonation
                          type="button"
                          active={thirdProfile}
                          onClick={handleThirdProfile}
                        >
                          Cientista
                        </ButtonDonation>
                      </div>

                      {fsProfile && (
                        <ButtonCSV
                          type="button"
                          data={allSupporters}
                          filename="apoiadores.csv"
                        >
                          <BsFileEarmarkSpreadsheet />
                          Download Relatório
                        </ButtonCSV>
                      )}
                      {secProfile && (
                        <ButtonCSV
                          type="button"
                          data={allCompanies}
                          filename="empresas.csv"
                        >
                          <BsFileEarmarkSpreadsheet />
                          Download Relatório
                        </ButtonCSV>
                      )}
                      {thirdProfile && (
                        <ButtonCSV
                          type="button"
                          data={allScientists}
                          filename="cientistas.csv"
                        >
                          <BsFileEarmarkSpreadsheet />
                          Download Relatório
                        </ButtonCSV>
                      )}
                    </div>
                    <hr />
                    <div>
                      <p id="sub-title">Projetos</p>

                      <ButtonCSV
                        type="button"
                        data={allProjects}
                        filename="projetos.csv"
                      >
                        <BsFileEarmarkSpreadsheet />
                        Download Relatório
                      </ButtonCSV>
                    </div>
                    <hr />
                    <div>
                      <p id="sub-title">Produtos / serviços</p>

                      <ButtonCSV
                        type="button"
                        data={allProducts}
                        filename="produtos.csv"
                      >
                        <BsFileEarmarkSpreadsheet />
                        Download Relatório
                      </ButtonCSV>
                    </div>
                    <hr />
                    <div>
                      <p id="sub-title">Doações</p>
                      <div className="buttons">
                        <ButtonDonation
                          type="button"
                          active={btnPayments}
                          onClick={handleBtnPayments}
                        >
                          Projetos
                        </ButtonDonation>
                        <ButtonDonation
                          type="button"
                          active={btnCosmos}
                          onClick={handleBtnCosmos}
                        >
                          Fundo Cosmos
                        </ButtonDonation>
                      </div>

                      {btnPayments && (
                        <ButtonCSV
                          type="button"
                          data={cosmos}
                          filename="fundo-cosmos.csv"
                        >
                          <BsFileEarmarkSpreadsheet />
                          Download Relatório
                        </ButtonCSV>
                      )}
                      {btnCosmos && (
                        <ButtonCSV
                          type="button"
                          data={payments}
                          filename="pagamentos-projetos.csv"
                        >
                          <BsFileEarmarkSpreadsheet />
                          Download Relatório
                        </ButtonCSV>
                      )}
                    </div>
                    <hr />
                    <div>
                      <p id="sub-title">Colaborações</p>

                      <ButtonCSV
                        type="button"
                        data={allCollaborations}
                        filename="colaborações.csv"
                      >
                        <BsFileEarmarkSpreadsheet />
                        Download Relatório
                      </ButtonCSV>
                    </div>
                    <hr />
                    <div>
                      <p id="sub-title">Desafios</p>

                      <ButtonCSV
                        type="button"
                        data={allChallenges}
                        filename="desafios.csv"
                      >
                        <BsFileEarmarkSpreadsheet />
                        Download Relatório
                      </ButtonCSV>
                    </div>
                  </ReportsComponent>
                </Fade>
              )}

              {deleteProjects && (
                <Fade left duration={1600} distance="20px">
                  <DeleteProjectsContainer>
                    <h1 id="title">Excluir Projetos</h1>
                    <div className="content">
                      {allProjects.length > 0 && currentCountriesDeletedProjects.map((project) => (
                        <DeleteProjectWrapper key={project.id}>
                          <DeleteProjectComponent data={project} />
                          <button className="button-delete-project-wrapper" onClick={() => deleteProject(project.id)}>Excluir</button>
                        </DeleteProjectWrapper>
                      ))
                      }
                    </div>

                    {allProjects.length > 0 && (
                      <div className="floatPage">
                        <Pagination
                          currentPage={currentPageDeletedProjects}
                          countriesPerPage={countriesPerPageDeletedProjects}
                          totalCountries={allProjects.length}
                          paginate={paginateDeletedProjects}
                        />
                      </div>
                    )}

                  </DeleteProjectsContainer>
                </Fade>
              )}

              {deleteChalenges && (
                <Fade left duration={1600} distance="20px">
                  <DeleteChallengesContainer>
                    <h1 id="title">Excluir Desafios</h1>
                    <div className="content">
                      {allChallenges.length > 0 && currentCountriesDeletedChalenges.map((challenge) => (
                        <DeleteChallengeWrapper key={challenge.id}>
                          <Challenge data={challenge} />
                          <button className="button-delete-challenge-wrapper" onClick={() => deleteChallenge(challenge.id)}>Excluir</button>
                        </DeleteChallengeWrapper>
                      ))
                      }
                    </div>

                    {allChallenges.length > 0 && (
                      <div className="floatPage">
                        <Pagination
                          currentPage={currentPageDeletedChalenges}
                          countriesPerPage={countriesPerPageDeletedChalenges}
                          totalCountries={allChallenges.length}
                          paginate={paginateDeletedChalenges}
                        />
                      </div>
                    )}
                  </DeleteChallengesContainer>
                </Fade>
              )}

              {projectsHome && (
                <Fade left duration={1600} distance="20px">
                  <ProjectsHomeContainer>
                    <h1 id="title">Projetos - Home</h1>

                    <p id="subtitle-projects-home-container">A home tem no máximo <strong>3</strong> projetos sendo assim, leve isso em conta, excluindo projetos selecionados para atender essa regra. (Caso exclua uma projeto selecionado posteriormente será possível escolhe-lo novamente)</p>
                    <div className="content">
                      {allProjects.length > 0 && currentCountriesDeletedProjectsHome.map((project) => (
                        <ProjectHomeWrapper key={project.id}>
                          <DeleteProjectComponent data={project} />
                          <ButtonSelectProjectHomeWrapper
                            onClick={() => allProjectsHome.filter((data) => data.project_id === project.id).length > 0 ? deleteProjectsHome(allProjectsHome.filter((data) => data.project_id === project.id)[0].id) : registerProjectsHome(project.id)}
                            selected={allProjectsHome.filter((data) => data.project_id === project.id).length > 0 ? true : false}
                          >
                            {allProjectsHome.filter((data) => data.project_id === project.id).length > 0 ? "Excluir" : "Selecionar"}
                          </ButtonSelectProjectHomeWrapper>
                        </ProjectHomeWrapper>
                      ))
                      }
                    </div>

                    {allProjects.length > 0 && (
                      <div className="floatPage">
                        <Pagination
                          currentPage={currentPageDeletedProjectsHome}
                          countriesPerPage={countriesPerPageDeletedProjectsHome}
                          totalCountries={allProjects.length}
                          paginate={paginateDeletedProjectsHome}
                        />
                      </div>
                    )}

                  </ProjectsHomeContainer>
                </Fade>
              )}
            </ProfileContent>
            <Footer>
              <div id="footer-content-1">
                <div id="content-social-medias">
                  <div id="logo-content">
                    <img src={footer} alt="" />
                  </div>
                </div>
              </div>
            </Footer>
          </Content>
        </Bounce>
      </Wrapper>
    </>
  );
}

export default SupporterProfile;
