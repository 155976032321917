import styled, { css, keyframes } from "styled-components";
import { darken } from "polished";

const primaryColor = "#227af1";

export const Wrapper = styled.div`
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 211px;
  background: #227af1;
  .content-header {
    width: 100%;
    height: 137px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title-content {
      height: 37.2px;
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      margin-left: 507px;
      #title {
        margin-top: 9px;
        margin-left: 17.2px;
        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
    #img-header {
      margin-top: -50px;
      margin-left: 157px;
    }
  }
  @media only screen and (max-width: 849px) {
    padding-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .content-header {
      width: 100%;
      .title-content {
        margin-left: 0;
        #title {
          font-size: 1.5rem;
        }

        #icon-title {
          width: 36.4px;
          height: 32.8px;
        }
      }

      #img-header {
        display: none;
        margin-left: 0;
      }
    }
  }
`;

export const BoxProfile = styled.div`
  width: 261px;
  height: 91px;
  background: #ffcc43;
  border-radius: 10px;

  display: flex;
  align-items: center;

  padding: 17px;
  margin-bottom: 10.92px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 21.2px;

    p {
      line-height: 1.5;
      text-align: left;
      color: #ffffff;
      font-size: 16px;
    }

    #normal {
      font-weight: 500;
      font-family: GothamProRegular;
    }

    #title {
      font-family: GothamPro;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    p {
      font-size: 0.9rem;
      text-align: center;
    }
  }
`;

export const ProfileContent = styled.div`
  width: 100%;
  display: flex;
  margin-left: 139px;
  margin-top: 52px;
  padding-bottom: 20px;

  position: relative;
  z-index: 0;

  aside {
    width: 260px;
    margin-right: 107px;
    display: flex;
    flex-direction: column;

    #perfil {
      margin-top: 5.5px;
      margin-bottom: 19px;

      width: 264px;
      font-size: 15.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: center;
      color: #227af1;
    }

    .avatar {
      height: 290px;
      border-radius: 10px;
      background-color: #227af1;
      padding-top: 24.4px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 5;
      .avatar-img {
        width: 126.2px;
        height: 126.2px;
        margin-bottom: 15px;
        .border {
          width: 100%;
          height: 100%;
          border-radius: 50%;

          border: solid 2px #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          .img-content {
            width: 101.2px;
            height: 101.2px;
            border-radius: 50%;
            background-color: ${darken(0.1, "#227af1")};

            display: flex;
            align-items: center;
            justify-content: center;

            #img-profile {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            #without-img-profile {
              width: 50%;
              height: 50%;
            }
          }
        }
        .pencil {
          width: 43px;
          height: 42px;
          border-radius: 50%;
          background-color: #5cf3ff;
          display: block;
          position: absolute;
          z-index: 1;
          margin-top: -123px;
          margin-left: 85px;
          padding-left: 12.2px;
          padding-top: 10.6px;
        }
      }
      hr {
        width: 100%;
        height: 1px;
        background-color: ${darken(0.1, "#227af1")};
        margin-bottom: 20.6px;
      }
      .infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin-bottom: 7px;

          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
        }
      }
    }
    .menu-profile {
      width: 100%;
      height: 394px;
      margin-top: -8px;
      border-radius: 0px 0px 10px 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.05);
      background-color: #fff;
      padding-top: 18px;
      .menu {
        display: flex;
        flex-direction: column;
      }
    }
    #btn-company-profile {
      width: 261px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;
      margin-top: 18px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;

    margin-left: 0;
    flex-direction: column;

    align-items: center;

    aside {
      width: 90%;
      margin-right: 0;

      #perfil {
        margin-left: 0;
        text-align: center;
      }

      .avatar {
        .avatar-img {
          .pencil {
            width: 43px;
            height: 42px;
            border-radius: 50%;
            background-color: #5cf3ff;

            display: inline-block;
            position: unset;
            z-index: 20;

            padding-left: 12.2px;
            padding-top: 10.6px;
          }
        }
      }
      hr {
        margin-top: 20px;
        margin-bottom: 0px;
      }
      #btn-company-profile {
        width: 100%;
      }
    }
  }
`;

export const MenuList = styled.button`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.21;
  letter-spacing: normal;
  text-align: left;
  color: #7f7fb9;
  background: transparent;
  margin-left: 25px;
  margin-bottom: 2px;
  ${(props) =>
    props.active &&
    css`
      color: #227af1;
    `}

  @media only screen and (max-width:768px) {
    font-size: 0.8rem !important;
    margin-left: 5%;
  }
`;

export const EditComponent = styled.main`
  width: 720px;
  height: 2566px;
  /* +747 */
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }
  .form {
    margin-top: 35.6px;
    form {
      #title {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
      .avatar {
        .pencil {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          padding-left: 14.8px;
          padding-top: 15.2px;
          background-color: #227af1;
          display: block;
          position: absolute;
          margin-top: -55px;
          margin-left: 95px;
        }
        #input-photo {
          margin-top: 17px;
          font-family: GothamProRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
        }
      }

      .box-obs-social {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        p {
          line-height: 1.73;
          color: #7f7fb9;
          font-size: 0.9rem;
          text-align: start;
        }
      }
      .inputs {
        display: flex;
        flex-wrap: wrap;
        margin-right: -16px;
        margin-top: 41px;
        margin-bottom: 15px;

        input {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.4px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;
          padding-left: 33.6px;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          &::-moz-placeholder {
            opacity: 1;
          }
          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }

        .box-security-datas {
          width: 100%;
          height: 130px;
          border-radius: 30px;

          border: dashed 2.5px #eee;

          display: flex;
          align-items: center;

          margin-bottom: 15px;

          p {
            font-size: 14px;

            line-height: 1.71;
            font-family: GothamProRegular;
            text-align: center;
            color: #000;

            strong {
              color: #227af1;
            }
          }
        }

        .id {
          width: 352px;
          height: 60px;
          border-radius: 30px;
          opacity: 0.45;
          border: solid 1.4px #7f7ebe;
          background: transparent;
          display: flex;
          align-items: center;
          padding-left: 26.6px;
          p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: center;
            color: #7f7fb9;
          }
        }
      }
      hr {
        background: #eee;
        height: 2px;
        width: 100%;
      }
      .curriculum-data {
        margin-top: 75px;
        margin-bottom: 15px;
        p {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
          margin-bottom: 30px;
        }
        .inputs-curriculum {
          display: flex;
          flex-wrap: wrap;
          margin-right: -16px;
          input {
            width: 352px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.4px #7f7ebe;
            margin-right: 16px;
            margin-bottom: 20px;
            padding-left: 33.6px;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;

            &::-moz-placeholder {
              opacity: 1;
            }
            &::placeholder {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #7f7fb9;
            }
          }
          .message {
            width: 715px;
            height: 179px;
            border-radius: 15px;
            border: solid 1px #7f7ebe;
            outline: none;
            resize: none;
            overflow: hidden;
            padding-top: 19px;
            padding-left: 33.6px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #7f7fb9;

            &::placeholder {
              clear: left;
              flex-wrap: wrap;
              color: #7f7fb9;
            }
            &::-moz-placeholder {
              opacity: 1;
            }
          }
          .full-link-curriculum {
            width: 100%;
          }
        }
      }
      .adress {
        margin-top: 72px;
        margin-bottom: 13.5px;
        p {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
          margin-bottom: 30px;
        }
        .inputs-adress {
          display: flex;
          flex-wrap: wrap;
          margin-right: -16px;
          input {
            width: 352px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.4px #7f7ebe;
            margin-right: 16px;
            margin-bottom: 20px;
            padding-left: 33.6px;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;

            &::-moz-placeholder {
              opacity: 1;
            }
            &::placeholder {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #7f7fb9;
            }
          }
          #estado {
            width: 168px;
          }
          .city {
            width: 260px;
          }
          .CEP {
            width: 260px;
          }
        }
      }
      .institution {
        margin-top: 75px;
        margin-bottom: 15px;
        p {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
          margin-bottom: 30px;
        }
        .inputs-curriculum {
          display: flex;
          flex-wrap: wrap;
          margin-right: -16px;
          input {
            width: 352px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.4px #7f7ebe;
            margin-right: 16px;
            margin-bottom: 20px;
            padding-left: 33.6px;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;

            &::-moz-placeholder {
              opacity: 1;
            }
            &::placeholder {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #7f7fb9;
            }
          }
          #message {
            width: 715px;
            height: 179px;
            border-radius: 15px;
            border: solid 1px #7f7ebe;
            outline: none;
            resize: none;
            overflow: hidden;
            padding-top: 19px;
            padding-left: 33.6px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #7f7fb9;

            &::placeholder {
              clear: left;
              flex-wrap: wrap;
              color: #7f7fb9;
            }
            &::-moz-placeholder {
              opacity: 1;
            }
          }
          .full {
            width: 100%;
          }
          .estado {
            width: 168px;
          }

          .city,
          .CEP {
            width: 260px;
          }
          .id {
            width: 352px;
            height: 60px;
            border-radius: 30px;
            opacity: 0.45;
            border: solid 1.4px #7f7ebe;
            background: transparent;

            display: flex;
            align-items: center;
            padding-left: 26.6px;
            padding-top: 32px;

            p {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;

              letter-spacing: normal;

              color: #7f7fb9;
            }
          }
        }
        .other-institution {
          width: 720px;
          height: 80px;
          border-radius: 10px;
          box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
          background-color: #ffffff;

          display: flex;
          justify-content: space-between;
          align-items: center;

          margin-top: 36px;
          margin-bottom: 40px;

          p {
            font-size: 16px;
            font-weight: 500;
            color: #2976fa;
            margin-left: 35px;
            margin-top: 33px;
          }

          button {
            width: 124px;
            height: 45px;
            border-radius: 30px;
            background-color: #227af1;

            transition: all 0.3s ease-in-out 0s;

            &:hover {
              background-color: ${darken(0.1, "#227af1")};
            }
          }
        }
      }
      .security {
        margin-top: 72px;
        margin-bottom: 13.5px;
        p {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
          margin-bottom: 30px;
        }

        .inputs-security {
          display: flex;
          input {
            width: 352px;
            height: 60px;
            border-radius: 30px;
            border: solid 1.4px #7f7ebe;
            margin-right: 16px;
            margin-bottom: 20px;
            padding-left: 33.6px;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;

            &::-moz-placeholder {
              opacity: 1;
            }
            &::placeholder {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #7f7fb9;
            }
          }
        }
      }
      button {
        float: right;
        width: 352px;
        height: 60px;
        border-radius: 30px;
        background-color: #227af1;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        transition: all 0.3s ease-in-out 0s;
        margin-right: 16px;
        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 3600px;

    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    input {
      width: 100% !important;
    }

    .message {
      width: 100% !important;
    }

    .form {
      form {
        #title {
        }

        .inputs {
          .id,
          .box-security-datas {
            width: 95% !important;
          }
        }
        .security {
          width: 100%;

          .inputs-security {
            flex-direction: column;
          }
        }

        .avatar {
          .pencil {
            display: none;
          }
        }

        button {
          width: 100% !important;
          float: none !important;
          margin: 0;
        }
      }
    }
  }
`;

export const DonationsComponent = styled.main`
  width: 720px;
  height: 1964px;
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }

  .buttons {
    margin: 15px 0 20px 0;

    width: 100%;
    align-self: center;

    display: flex;
    align-items: center;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
  }
  .pagination {
    margin-top: 42px;
    margin-right: -6px;
    width: 249px;
    height: 45px;
    display: flex;
    float: right;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;

    height: 2964px;
    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    #content-cosmos {
      margin-bottom: 100px;
    }

    .text {
      text-align: justify;
    }
  }
`;

export const Cosmos = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 16px;

  z-index: 1;

  width: 393.8px;
  height: 272.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background-color: ${primaryColor};

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin-top: 10px;
    }
  }
  #title-cosmos {
    text-align: center;
    color: ${primaryColor};

    font-size: 20px;
    margin-top: 20px;
  }
  .content-2 {
    width: 100%;
    height: 273.8px;
    .donation {
      margin-top: 10px;

      p {
        margin-left: 33px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }

      #value {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;

    .content-1 {
      img {
        width: 80%;
      }
    }
  }
`;

export const ButtonDonation = styled.button`
  width: 352px;
  height: 60px;
  border-radius: 30px;

  ${(props) =>
    !props.active &&
    css`
      background: transparent;
      border: 2px solid ${primaryColor};
      color: ${primaryColor};

      &:hover {
        background-color: ${darken(0.1, "#fff")};
      }
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${primaryColor};
      border: none;
      color: #fff;

      &:hover {
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    `}

    font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;

  transition: all 0.3s ease-in-out 0s;

  margin-right: 30px;
  @media only screen and (max-width: 768px) {
    margin-right: 3vw;
  }
`;

export const ProjectsComponent = styled.main`
  width: 720px;
  height: 1290px;
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }

  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }

  .newProject {
    width: 720px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #227af1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 14px 0px 35px;

    margin: 17.6px 0px 31.2px 0px;

    p {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 4.38;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    button {
      width: 124px;
      height: 45px;
      border-radius: 30px;
      background-color: #5cf3ff;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#5CF3FF")};
      }
    }
  }

  .projects {
    display: flex;

    .project {
      display: flex;
      flex-direction: column;
      align-items: center;

      .edit {
        display: flex;
        align-items: center;

        .box {
          width: 43px;
          height: 42px;
          background-color: #227af1;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 50%;

          margin-right: 13.8px;
        }

        button {
          font-size: 14px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 3.21;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;

          background-color: transparent;

          transition: all 0.3s ease-in-out 0s;

          &:hover {
            color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }
  }
  .floatPage {
    float: right;

    margin-top: 30px;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 3200px;

    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }
    .floatPage {
      float: none;
    }
    .newProject {
      width: 100%;
      padding: 0 6px 0px 13px;
      p {
        font-size: 0.8rem;
      }

      button {
        width: 75px;
        font-size: 0.8rem;
      }
    }
  }
`;

export const ChallengesComponent = styled.main`
  width: 720px;
  height: 1944px;
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -14px;
  }
  .pagination {
    margin-top: 42px;
    margin-right: -6px;
    width: 249px;
    height: 45px;
    display: flex;
    float: right;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    height: 2644px;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }
  }
`;

export const CollaborationsComponent = styled.main`
  width: 720px;
  height: 2510px;
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
  }
  .pagination {
    margin-top: 42px;
    margin-right: -6px;
    width: 249px;
    height: 45px;
    display: flex;
    float: right;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;
    height: 3510px;
    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }
  }
`;

export const HistoricComponent = styled.main`
  width: 720px;
  height: 1629px;
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }
  .content {
    .transactions {
      width: 100%;
      height: 140px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;
      margin-bottom: 19px;
      padding-left: 35px;
      padding-top: 27px;
      .title-transaction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        #text-title-transaction {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #2976fa;
        }
        .data {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          #icon-calendar {
            width: 14px;
            height: 13.6px;
            margin-right: 8px;
          }
          #day {
            margin-top: 2px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }
      }
      .value {
        display: flex;
        align-items: flex-end;
        #big-letter {
          font-size: 28px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.86;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
        .small-letter {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
          margin-left: 3px;
        }
      }
    }
  }
  .pagination {
    margin-top: 42px;
    margin-right: -6px;
    width: 249px;
    height: 45px;
    display: flex;
    float: right;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }
    .content {
      .transactions {
        p {
          font-size: 0.75rem !important;
        }
      }
    }
  }
`;

export const ShoppingComponent = styled.main`
  width: 720px;
  height: 1898px;
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;

    .product {
      width: 260;
      display: flex;
      flex-direction: column;

      .company-infos {
        cursor: pointer;

        align-self: center;

        width: 220px;
        height: 60px;
        border-radius: 30px;
        background-color: #227af1;

        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.3s ease-in-out 0s;

        img {
          height: 40px;
          margin-right: 20px;
        }

        margin-right: 16px;

        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }
  .pagination {
    margin-top: 42px;
    margin-right: -6px;
    width: 249px;
    height: 45px;
    display: flex;
    float: right;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 2998px;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }

    .content {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;

      margin-right: 0;

      .product {
        width: 100%;
        margin-bottom: 5vh;
        .company-infos {
          width: 70%;

          img {
            width: 25%;
          }
        }
      }
    }
  }
`;

export const AboutCompanyComponent = styled.main`
  width: 720px;
  height: 1000px;

  padding-top: 60px;

  #title {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .infos {
    .box-img {
      height: 110px;
      width: 110px;
      border-radius: 50%;
      border: 2px dashed #eee;

      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 30px;

      #avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .datas {
      margin-top: 50px;
      width: 100%;
      #title-data {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
        font-size: 20px;
      }
      .all {
        display: flex;
        margin-top: 40px;

        .about {
          width: 300px;
          height: 190px;
          margin-bottom: 25px;

          border-right: 2px dashed #eee;

          .info {
            display: flex;
            align-items: center;

            margin-bottom: 30px;

            img,
            svg {
              margin-right: 10px;
              height: 20px;
              width: 20px;
            }

            #type {
              font-weight: 500;
              text-align: left;

              margin-right: 10px;
            }

            p {
              font-family: GothamProRegular;
            }
          }
        }

        .adress {
          display: flex;
          flex-direction: column;

          padding-left: 30px;
          .adress-info {
            display: flex;

            #type {
              margin-right: 15px;
              color: #227af1;
              font-family: GothamPro;
            }
            p {
              margin-bottom: 25px;
              font-family: GothamProRegular;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1rem;
    }

    .text {
      text-align: justify;
    }

    .datas {
      p {
        font-size: 0.85rem !important;
      }

      .all {
        flex-direction: column;

        .about {
          border-right: unset !important;
        }
      }
    }
  }
`;

export const NewProjectComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }
  .controllerProject {
    /* margin-left:46px; */
    margin: 20px 0 20px 0;

    /* width: 628px; */
    width: 720px;
    height: 68px;
    border-radius: 50px;
    box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 5700px;

    margin-top: 10vh;
    #title {
      font-size: 1.3rem;
    }

    .text {
      text-align: justify;
    }

    .controllerProject {
      width: 100%;
    }
  }
`;

export const ProjectCollaborations = styled.main`
  width: 720px;
  height: 1650px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }
  .text {
    width: 100%;
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 8.6px;
  }
  .content {
    width: 100%;

    .buttons {
      margin-top: 15px;

      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .box-content {
      width: 100%;

      margin-top: 30px;

      #title-content {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }

      .items {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .floatPage {
      float: right;

      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 2650px;
    margin-left: 2.5vw;
    margin-top: 10vh;

    #title {
      font-size: 1rem;
    }

    .text {
      text-align: justify;
    }
    .content {
      .box-content {
        #title-content {
          font-size: 0.8rem !important;
        }
      }
      .floatPage {
        margin-top: 350px;
        float: none;
      }
    }
  }
`;

export const Collaboration = styled.li`
  height: 485px;

  margin-bottom: 30px;
  margin-top: 15px;

  #participant {
    width: 710px;
    height: 391px;
    padding: 19px 50px 25px 18px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    display: flex;

    .icon {
      width: 70px;
      height: 70px;
      border: solid 2px rgba(127, 126, 190, 0.31);
      border-radius: 50%;

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      margin-right: 10px;

      #background-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        background-color: rgba(127, 127, 185, 0.31);

        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }

    .content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: 5px;

      #day {
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        color: #7f7fb9;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .user-box {
        display: flex;
        align-items: flex-end;

        margin-bottom: 15px;

        #name {
          margin-left: 15px;

          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: ${primaryColor};
        }
      }
      hr {
        width: 553px;
        height: 0;
        opacity: 0.24;
        border: solid 1px #707070;
      }

      #message-champ {
        max-height: 162px;
        margin: 28px 0 28px 0;

        width: 85%;

        word-wrap: break-word;

        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 2;
        text-align: left;
        color: #000000;
      }

      .third-content {
        display: flex;
        flex-direction: column;

        margin-top: 30px;

        a {
          margin-top: 8px;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: ${primaryColor};
        }
      }
    }
  }
  hr {
    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;

    p,
    a {
      font-size: 0.75rem !important;
      word-wrap: break-word;
      word-break: break-all;
    }

    #participant {
      width: 100%;
      height: 520px;
      flex-direction: column;
      .content {
        hr {
          width: 100%;
        }
      }
    }
  }
`;
export const ButtonChamp = styled.button`
  width: 261px;
  height: 60px;
  border-radius: 30px;
  background-color: ${primaryColor};

  ${(props) =>
    props.champ &&
    css`
      background-color: ${darken(0.15, `${primaryColor}`)};
    `}

  margin-top:20px;
  margin-bottom: 23px;

  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  text-align: left;
  color: #ffffff;

  transition: all 0.3s ease-in-out 0s;

  float: right;

  padding: 0 21px 0 26px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    height: 30px;
  }

  &:hover {
    background-color: ${darken(0.1, `${primaryColor}`)};
  }
  @media only screen and (max-width: 768px) {
    width: 40vw;

    font-size: 0.8rem !important;

    img {
      height: 4vh;
    }
  }
`;

export const ProjectSecond = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 16px;
  z-index: 1;
  width: 352px;
  height: 472.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;
  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      margin-top: 10px;

      max-height: 100%;
      max-width: 100%;
    }
  }
  .content-2 {
    width: 100%;
    height: 243.8px;

    .titles {
      width: 100%;
      margin-top: 26px;

      #title-project {
        margin-left: 33px;
        font-size: 100%;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }

      div {
        align-self: flex-start;

        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 8.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    .donation {
      margin-top: 16.6px;
      p {
        margin-left: 33px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
      #value {
        font-size: 20px;
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin: 38px 20px 40px 40px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 2.5vw;
    margin-top: 4vh;

    p {
      font-size: 0.8rem !important;
    }
  }
`;

export const Challenge = styled.div`
  margin-bottom: 39px;
  margin-left: 14px;
  width: 352px;
  height: 505px;
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 13px 0 rgba(49, 22, 159, 0.09);
  background-color: #fafaff;
  border-radius: 10px;
  .challenge-content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 170px;
    }
  }
  .challenge-content-2 {
    width: 100%;
    height: 276px;

    background-color: #fafaff;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      #title-challenge {
        margin-top: 30px;
        margin-bottom: 5px;
        margin-left: 26px;
        width: 293px;

        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #company,
      #date,
      #category {
        margin-top: 5px;
        margin-left: 26px;
        width: 209px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      section {
        margin-top: 20px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    .challenge-price {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-left: 26px;
      margin-top: 30px;
      #price-value {
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #price-value-2 {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #price-text {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
    }
  }
  #challenge-content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin: 38px 9.4px 40px 26px;
      height: 29px;
    }
    p {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  section {
    margin-top: 10px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const Project = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;

  z-index: 1;
  width: 352px;
  height: 651.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;
  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    #img-content-1 {
      margin-top: 10px;
      max-width: 160px;
      max-height: 160px;
    }
  }
  .content-2 {
    width: 100%;
    height: 422.8px;
    .titles {
      width: 100%;
      margin-top: 26px;

      #title-project {
        margin-left: 33px;
        font-size: 100%;

        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }

      div {
        align-self: flex-start;

        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 16.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    .resume {
      #title-resume {
        margin-top: 26.6px;
        margin-left: 33px;
        margin-bottom: 11.3px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
      #text-content {
        margin-left: 33px;
        width: 302px;

        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000000;

        word-wrap: break-word;
      }
      section {
        margin-top: 8.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    .data {
      p {
        margin-left: 27px;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin: 38px 20px 40px 40px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;
export const MyProject = styled.div`
  margin-bottom: 61px;
  margin-top: 40px;

  z-index: 1;
  width: 352px;
  height: 592.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;
  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    #img-content-1 {
      margin-top: 10px;
      max-width: 160px;
      max-height: 160px;
    }
  }
  .content-2 {
    width: 100%;
    height: 389.8px;
    .titles {
      width: 100%;
      margin-top: 26px;
      #title-project {
        margin-left: 33px;
        font-size: 100%;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }
      div {
        align-self: flex-start;
        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }
        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 16.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    .infos {
      margin-left: 33px;
      #infos-text {
        margin-top: 10px;

        font-size: 14px;
        font-weight: 500;
        line-height: 2;
        text-align: left;
        color: #7f7fb9;
      }
    }

    section {
      margin-top: 8.3px;
      width: 100%;
      height: 0.1px;
      background: #eee;
    }
    .goals {
      margin-left: 33px;
      margin-top: 14.6px;

      p {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }

      .value {
        #value {
          font-size: 20px;
          line-height: 1.4;
        }
      }
      .current_val {
        margin-top: 14px;
        #current_val {
          font-size: 20px;
          line-height: 1.4;
          color: #227af1;
        }
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 40px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const Product = styled.div`
  margin-bottom: 39px;
  margin-right: 16px;
  width: 352px;
  height: 480px;
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 13px 0 rgba(49, 22, 159, 0.09);
  background-color: #fafaff;
  border-radius: 10px;
  #product-content-1 {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  #product-content-2 {
    width: 100%;
    height: 261px;
    background-color: #fafaff;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      #product-text-1-content-2 {
        margin-top: 20px;
        margin-left: 26px;
        width: 209px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
      #category {
        margin-left: 26px;
        margin-top: 7px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #product-text-2-content-2 {
        margin-top: -40px;
        margin-left: -70px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        img {
          height: 16px;
          width: 16px;
          margin-right: 6px;
        }
      }
      section {
        margin-top: 20px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }
    #product-price {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      #product-text-3-content-2 {
        margin-left: 26px;
        margin-top: 20px;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
      #product-text-4-content-2 {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;

        margin-left: 10px;
      }
      #product-text-5-content-2 {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #b0b3ce;
      }
    }
    .data {
      p {
        margin-left: 27px;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }
  }
  #product-content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin: 38px 9.4px 40px 26px;
    }
    p {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  section {
    margin-top: 10px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const Button = styled.button`
  width: 140px;
  height: 45px;

  background-color: transparent;
  border: solid 1.5px #227af1;
  border-radius: 30px;

  font-family: GothamProRegular;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #227af1;

  transition: all 0.3s ease-in-out 0s;
  &:hover {
    border: solid 1.5px ${darken(0.1, "#227af1")};
    color: ${darken(0.1, "#227af1")};
    background-color: ${darken(0.05, "#fff")};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #227af1;
      color: #fff;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
        color: #fff;
      }
    `}
  @media only screen and (max-width:768px) {
    width: 25%;
    font-size: 0.55rem;
  }
`;

export const ButtonCollaboration = styled.button`
  width: 220px;
  height: 60px;

  background-color: transparent;
  border: solid 2px #227af1;
  border-radius: 30px;

  img {
    height: 40px;
  }

  transition: all 0.3s ease-in-out 0s;

  &:hover {
    border: solid 2px ${darken(0.1, "#227af1")};
    color: ${darken(0.1, "#227af1")};
    background-color: ${darken(0.05, "#fff")};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #227af1;
      color: #fff;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
        color: #fff;
      }
    `}

  @media only screen and (max-width:768px) {
    width: 30%;

    img {
      height: 5vh;
    }
  }
`;

export const AboutComponent = styled.main`
  width: 100%;

  height: 5584px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }

  .inputs {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-top: 41px;
    margin-bottom: 15px;

    input {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #7f7ebe;
      margin-right: 16px;
      margin-bottom: 20px;
      padding-left: 33.6px;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      &::-moz-placeholder {
        opacity: 1;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .input-imgs {
      width: 352px;
      height: 60px;
      padding: 1px 0 1px 27.6px;
      border-radius: 30px;
      border: solid 1px #7f7ebe;

      display: flex;
      align-items: center;
      justify-content: space-between;

      #placeholder {
        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 1.71;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .full {
      width: 720px;
    }

    .value_goal {
      width: 100%;

      display: flex;

      margin-bottom: 40px;
      .id {
        width: 352px;
        height: 61.4px;
        border-radius: 30px;
        opacity: 0.45;
        border: solid 1.4px #7f7ebe;
        background: transparent;
        display: flex;
        align-items: center;
        padding-left: 26.6px;
        p {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: #7f7fb9;
        }
      }

      #btn-ask {
        margin-left: 15px;

        width: 352px;
        height: 60px;
        border-radius: 30px;
        background-color: #227af1;

        font-size: 15px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  .new_goal {
    width: 100%;

    margin-top: 30px;
    margin-bottom: 30px;

    #title-new-goal {
      margin-top: 12px;
      margin-bottom: 20px;

      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    textarea {
      width: 715px;
      height: 179px;
      border-radius: 15px;
      border: solid 1px #7f7ebe;
      outline: none;
      resize: none;
      overflow: hidden;
      padding-top: 19px;
      padding-left: 33.6px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #7f7fb9;

      margin-bottom: 22px;

      &::placeholder {
        clear: left;
        flex-wrap: wrap;
        color: #7f7fb9;
      }
      &::-moz-placeholder {
        opacity: 1;
      }
    }

    #height-1 {
      height: 171px;
      margin-bottom: 22px;
    }

    #btn-send {
      float: right;

      margin-right: 10px;

      width: 220px;
      height: 55px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 15px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  .previews {
    display: flex;

    .preview {
      height: 97px;
      width: 76px;

      margin-right: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;

      #teste {
        width: 76px;
        height: 65px;
        border-radius: 10px;
      }

      button {
        width: 40px;

        background: transparent;

        font-size: 14px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #227af1;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }
  .institution {
    display: flex;

    input {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #7f7ebe;
      margin-right: 16px;
      margin-bottom: 20px;
      padding-left: 33.6px;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      &::-moz-placeholder {
        opacity: 1;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .previews-institution {
      display: flex;
      flex-direction: column;

      width: 357px;

      .preview-university {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 15px;

        #id-university {
          width: 193px;

          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }

        .trash {
          margin-left: 56.7px;

          display: flex;
          flex-direction: row;
          align-items: center;

          button {
            margin-left: 13.8px;

            font-size: 15px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;

            background: transparent;

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, "#227af1")};
            }
          }
        }
      }
    }
  }

  .textareas {
    margin-top: 45px;

    textarea {
      width: 715px;
      height: 179px;
      border-radius: 15px;
      border: solid 1px #7f7ebe;
      outline: none;
      resize: none;
      overflow: hidden;
      padding-top: 19px;
      padding-left: 33.6px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #7f7fb9;

      margin-bottom: 22px;

      &::placeholder {
        clear: left;
        flex-wrap: wrap;
        color: #7f7fb9;
      }
      &::-moz-placeholder {
        opacity: 1;
      }
    }

    #height-1 {
      height: 131px;
      margin-bottom: 22px;
    }

    #height-2 {
      height: 229px;
      margin-bottom: 15px;
    }

    #height-3 {
      height: 179px;
      margin-bottom: 22px;
    }

    hr {
      margin-top: 18px;

      width: 720px;
      height: 1;
      opacity: 0.24;
      border: solid 1px #707070;
    }
  }

  .content-development-team {
    margin-top: 66px;

    #title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.5;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    .new-member {
      width: 720px;
      height: 149px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      display: flex;
      flex-direction: column;
      margin-top: 15px;

      padding: 28px 0 29px 26px;

      #title-new-member {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2976fa;

        margin-bottom: 12px;
      }

      .input {
        input {
          width: 510px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.4px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;
          padding-left: 33.6px;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-right: 15px;

          &::-moz-placeholder {
            opacity: 1;
          }
          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }

        button {
          width: 142px;
          height: 60px;
          border-radius: 30px;
          background-color: #227af1;

          font-size: 15px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            background-color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }

    hr {
      margin-top: 26px;

      width: 720px;
      height: 1;
      opacity: 0.24;
      border: solid 1px #707070;
    }
  }

  .collaboration {
    display: flex;
    width: 100%;

    flex-direction: column;

    margin-top: 66px;

    .rooms {
      display: flex;
      flex-direction: column;

      .room {
        margin-top: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          height: 18px;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          margin-left: 15px;
          color: #227af1;

          &:hover {
            color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }

    .content-1,
    .content-2,
    .content-3,
    .content-4 {
      width: 100%;

      margin-bottom: 35.8px;

      .title {
        display: flex;
        align-items: baseline;

        height: 57.8px;

        #title {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: end;
          color: #227af1;

          margin-left: 26px;
        }
      }

      .chat-change {
        cursor: pointer;

        .chat {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            height: 27.6px;
            margin-right: 12.6px;
          }

          #text-title-chat {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;

            margin-right: 34px;
          }

          .box-amount {
            display: flex;

            align-items: center;
            justify-content: center;

            width: 27px;
            height: 27px;
            border-radius: 2px;
            background-color: #227af1;

            p {
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #ffffff;
            }
          }
        }
      }

      .inputs {
        textarea {
          width: 715px;
          height: 179px;
          border-radius: 15px;
          border: solid 1px #7f7ebe;
          outline: none;
          resize: none;
          overflow: hidden;
          padding-top: 19px;
          padding-left: 33.6px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #7f7fb9;

          margin-bottom: 22px;

          &::placeholder {
            clear: left;
            flex-wrap: wrap;
            color: #7f7fb9;
          }
          &::-moz-placeholder {
            opacity: 1;
          }
        }

        button {
          margin-left: 542px;

          width: 168px;
          height: 55px;
          border-radius: 30px;
          border: solid 1px #2976fa;
          background-color: #227af1;

          font-size: 15px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            background-color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }

    .content-2 {
    }
  }

  hr {
    margin-top: 13px;
    margin-bottom: 13px;

    width: 720px;
    height: 1;
    opacity: 0.24;
    border: solid 1px #707070;
  }

  #btn-next {
    margin-top: 28px;

    width: 168px;
    height: 55px;
    border-radius: 30px;
    border: solid 1px #2976fa;
    background-color: #227af1;

    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;

    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    input,
    textarea {
      width: 100% !important;
    }
    .inputs {
      margin-right: 0;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;

      .input-imgs {
        width: 100% !important;
      }

      .box-goal {
        width: 100%;
        height: 260px;
        flex-direction: column;

        #text {
          font-size: 0.75rem;
        }
      }
    }
    .institution {
      margin: 0;
      padding: 0;

      flex-direction: column;
      align-items: center;
    }

    .collaboration {
      .content-1,
      .content-2,
      .content-3,
      .content-4 {
        width: 100%;

        margin-bottom: 35.8px;

        .title {
          display: flex;
          align-items: baseline;

          height: 5vh;

          #title {
            font-size: 0.8rem;

            margin-left: 5%;
          }
          img {
            height: 4vh;
          }
        }
      }
    }

    .content-development-team {
      .new-member {
        width: 100%;
        height: 249px;

        padding: 28px 0 14.5px 13px;

        .input {
          width: 100%;
          display: flex;
          flex-direction: column;

          input {
            width: 90% !important;
          }
        }
      }
    }
    .text-checkbox {
      font-size: 0.8rem !important;
    }
  }
`;
export const AboutComponentNew = styled.main`
  width: 100%;

  height: 5644px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    margin-bottom: 17.6px;
  }

  .inputs {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-top: 41px;
    margin-bottom: 15px;

    #number-chars {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      margin-bottom: 10px;
      margin-left: 10px;
    }

    input {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #7f7ebe;
      margin-right: 16px;
      margin-bottom: 20px;
      padding-left: 33.6px;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      &::-moz-placeholder {
        opacity: 1;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .input-imgs {
      width: 352px;
      height: 60px;
      padding: 1px 0 1px 27.6px;
      border-radius: 30px;
      border: solid 1px #7f7ebe;

      display: flex;
      align-items: center;
      justify-content: space-between;

      #placeholder {
        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 1.71;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .full {
      width: 720px;
    }

    .box-goal {
      height: 110px;
      width: 720px;

      border-radius: 15px;
      box-shadow: 0 0 2.5px 0 rgba(49, 22, 159, 0.12);

      border: 2px dashed #eee;
      padding: 25px;

      display: flex;
      align-items: center;

      word-wrap: break-word;

      margin-top: 20px;
      margin-bottom: 35px;

      #attention {
        font-size: 16px;
        font-weight: 500;
        margin-right: 10px;
        text-align: left;
        color: #227af1;
      }

      #text {
        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 1.71;
        text-align: left;
        color: #000;
      }
    }
  }

  .previews {
    display: flex;

    .preview {
      height: 97px;
      width: 76px;

      margin-right: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;

      #teste {
        width: 76px;
        height: 65px;
        border-radius: 10px;
      }

      button {
        width: 40px;

        background: transparent;

        font-size: 14px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #227af1;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }
  .institution {
    display: flex;

    .previews-institution {
      display: flex;
      flex-direction: column;

      width: 357px;

      .preview-university {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 15px;

        #id-university {
          width: 193px;

          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }

        .trash {
          margin-left: 56.7px;

          display: flex;
          flex-direction: row;
          align-items: center;

          button {
            margin-left: 13.8px;

            font-size: 15px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;

            background: transparent;

            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, "#227af1")};
            }
          }
        }
      }
    }
  }

  .textareas {
    margin-top: 45px;

    textarea {
      width: 715px;
      height: 179px;
      border-radius: 15px;
      border: solid 1px #7f7ebe;
      outline: none;
      resize: none;
      overflow: hidden;
      padding-top: 19px;
      padding-left: 33.6px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #7f7fb9;

      margin-bottom: 22px;

      &::placeholder {
        clear: left;
        flex-wrap: wrap;
        color: #7f7fb9;
      }
      &::-moz-placeholder {
        opacity: 1;
      }
    }

    #height-1 {
      height: 131px;
      margin-bottom: 22px;
    }

    #height-2 {
      height: 229px;
      margin-bottom: 15px;
    }

    #height-3 {
      height: 179px;
      margin-bottom: 22px;
    }

    hr {
      margin-top: 18px;

      width: 720px;
      height: 1;
      opacity: 0.24;
      border: solid 1px #707070;
    }
  }

  .content-development-team {
    margin-top: 66px;

    #title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.5;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    .new-member {
      width: 720px;
      height: 149px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      display: flex;
      flex-direction: column;
      margin-top: 15px;

      padding: 28px 0 29px 26px;

      #title-new-member {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2976fa;

        margin-bottom: 12px;
      }

      .input {
        input {
          width: 510px;
          height: 60px;
          border-radius: 30px;
          border: solid 1.4px #7f7ebe;
          margin-right: 16px;
          margin-bottom: 20px;
          padding-left: 33.6px;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;

          margin-right: 15px;

          &::-moz-placeholder {
            opacity: 1;
          }
          &::placeholder {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }

        button {
          width: 142px;
          height: 60px;
          border-radius: 30px;
          background-color: #227af1;

          font-size: 15px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            background-color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }

    hr {
      margin-top: 26px;

      width: 720px;
      height: 1;
      opacity: 0.24;
      border: solid 1px #707070;
    }
  }

  .collaboration {
    display: flex;
    width: 100%;

    flex-direction: column;

    margin-top: 66px;

    .content-1,
    .content-2,
    .content-3,
    .content-4 {
      width: 100%;

      margin-bottom: 35.8px;

      .title {
        display: flex;
        align-items: baseline;

        height: 57.8px;

        #title {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: end;
          color: #227af1;

          margin-left: 26px;
        }
      }

      .inputs {
        textarea {
          width: 715px;
          height: 179px;
          border-radius: 15px;
          border: solid 1px #7f7ebe;
          outline: none;
          resize: none;
          overflow: hidden;
          padding-top: 19px;
          padding-left: 33.6px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: #7f7fb9;

          margin-bottom: 22px;

          &::placeholder {
            clear: left;
            flex-wrap: wrap;
            color: #7f7fb9;
          }
          &::-moz-placeholder {
            opacity: 1;
          }
        }

        button {
          margin-left: 542px;

          width: 168px;
          height: 55px;
          border-radius: 30px;
          border: solid 1px #2976fa;
          background-color: #227af1;

          font-size: 15px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            background-color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }

    .content-2 {
      .chat-change {
        .chat {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            height: 27.6px;
            margin-right: 12.6px;
          }

          #text-title-chat {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;

            margin-right: 34px;
          }

          .box-amount {
            display: flex;

            align-items: center;
            justify-content: center;

            width: 27px;
            height: 27px;
            border-radius: 2px;
            background-color: #227af1;

            p {
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .label__checkbox {
    display: none;
  }

  .label__check {
    display: inline-block;
    border-radius: 3px;
    border: solid 1px #7f7fb9;
    background: white;
    vertical-align: middle;
    margin-right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out 0s;

    margin-left: 5px;

    i.icon {
      opacity: 0.2;
      font-size: calc(1rem + 1vw);
      color: transparent;
      transition: all 0.3s ease-in-out 0s;
      -webkit-text-stroke: 3px #fff;
    }

    &:hover {
      border: solid 2.5px #7f7fb9;
    }
  }

  .label__text {
    display: flex;
    align-items: center;

    #text-checkbox {
      font-size: 14px;
      text-align: left;
      color: #7f7fb9;

      display: flex;

      #term {
        cursor: pointer;

        color: #227af1;
        transition: all 0.3s ease-in-out 0s;

        margin-left: 5px;
        margin-right: 5px;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  .label__checkbox:checked + .label__text .label__check {
    animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;

    .icon {
      opacity: 1;
      transform: scale(0);
      color: white;
      -webkit-text-stroke: 0;
      animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.4s 1 forwards;
    }
  }

  @keyframes icon {
    from {
      opacity: 0;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes check {
    0% {
      width: 1.5em;
      height: 1.5em;
      border-width: 5px;
    }
    10% {
      width: 1.5em;
      height: 1.5em;
      opacity: 0.1;
      background: #fff;
      border-width: 15px;
    }
    12% {
      width: 1.5em;
      height: 1.5em;
      opacity: 0.4;
      background: #fff;
      border-width: 1;
    }
    50% {
      width: 2em;
      height: 2em;
      background: #fff;
      border: 0;
      opacity: 0.6;
    }
    100% {
      width: 2em;
      height: 2em;
      background: #fff;
      border: 1;
      opacity: 1;
    }
  }

  hr {
    margin-top: 13px;
    margin-bottom: 33px;

    width: 720px;
    height: 1;
    opacity: 0.24;
    border: solid 1px #707070;
  }

  #btn-next {
    margin-top: 48px;

    width: 168px;
    height: 55px;
    border-radius: 30px;
    border: solid 1px #2976fa;
    background-color: #227af1;

    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
    &:disabled {
      opacity: 0.6;
      background-color: ${darken(0.4, "#227af1")};
      cursor: not-allowed;
    }
  }

  .box-sub {
    width: 710px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .sec-option,
    .fs-option {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 40px;

      #text-sub {
        width: 90%;
        word-wrap: break-word;
        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 1.71;
        text-align: left;
        color: #000;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 5900px;

    margin-top: 10vh;

    #title {
      font-size: 1.3rem;
    }

    input,
    textarea {
      width: 100% !important;
    }
    .inputs {
      margin-right: 0;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;

      .input-imgs {
        width: 100% !important;
      }

      .box-goal {
        width: 100%;
        height: 260px;
        flex-direction: column;

        #text {
          font-size: 0.75rem;
        }
      }
    }
    .institution {
      margin: 0 !important;
      padding: 0;

      width: 90vw;

      flex-direction: column;
      align-items: center;
    }

    .collaboration {
      .content-1,
      .content-2,
      .content-3,
      .content-4 {
        width: 100%;

        margin-bottom: 35.8px;

        .title {
          display: flex;
          align-items: baseline;

          height: 5vh;

          #title {
            font-size: 0.8rem;

            margin-left: 5%;
          }
          img {
            height: 4vh;
          }
        }
      }
    }

    .content-development-team {
      .new-member {
        width: 100%;
        height: 249px;

        padding: 28px 0 14.5px 13px;

        .input {
          width: 100%;
          display: flex;
          flex-direction: column;

          input {
            width: 90% !important;
          }
        }
      }
    }
    .text-checkbox {
      font-size: 0.8rem !important;
    }

    .box-sub {
      width: 100% !important;
    }
  }
`;

export const BoxLabel = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: #227af1;

  ${(props) =>
    props.active &&
    css`
      background-color: ${darken(0.2, "#227af1")};
    `}

  display:flex;
  align-items: center;
  justify-content: center;
  label {
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 58px;
      border-radius: 50%;

      input {
        display: none;
      }

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        opacity: 0.7;
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }
`;

export const BoxLabelNew = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #227af1;
  margin-right: 5px;

  ${(props) =>
    props.active &&
    css`
      background-color: ${darken(0.2, "#227af1")};
    `}

  display:flex;
  align-items: center;
  justify-content: center;
  label {
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 50%;

      input {
        display: none;
      }

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        opacity: 0.7;
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }
`;

export const NewsComponent = styled.main`
  width: 100%;
  height: 1477px;

  #title {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;
  }

  #dotted {
    width: 720px;
    height: 1;
    opacity: 0.19;
    border: 1px dotted #707070;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .news {
    width: 100%;

    margin-top: 20px;
    padding: 15px;

    ul {
      width: 100%;
    }
  }

  .new-msg {
    #title-new-msg {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.89;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
    input {
      width: 720px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #7f7ebe;
      margin-right: 16px;
      margin-bottom: 20px;
      padding-left: 33.6px;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      &::-moz-placeholder {
        opacity: 1;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }

    #input-link {
      border: solid 1.4px #7f7ebe;
      width: 262px;
      height: 47px;
      border-radius: 21px;
      margin-bottom: 0;

      padding-right: 15px;
    }

    .message {
      width: 720px;
      height: 179px;

      border-radius: 15px;
      border: solid 1px #7f7ebe;
      outline: none;
      resize: none;
      overflow: hidden;
      padding-top: 19px;
      padding-left: 33.6px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #7f7fb9;

      margin-bottom: 22px;

      &::placeholder {
        clear: left;
        flex-wrap: wrap;
        color: #7f7fb9;
      }
      &::-moz-placeholder {
        opacity: 1;
      }
    }

    .buttons {
      float: right;
      display: flex;

      margin-bottom: 27px;
      margin-top: 10px;

      .preview {
        height: 97px;
        width: 76px;

        margin-right: 16px;

        display: flex;
        flex-direction: column;
        align-items: center;

        #teste {
          width: 76px;
          height: 65px;
          border-radius: 10px;
        }

        button {
          width: 40px;

          background: transparent;

          font-size: 14px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #227af1;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            color: ${darken(0.1, "#227af1")};
          }
        }
      }

      #btn-insert {
        width: 168px;
        height: 45px;
        border-radius: 30px;
        border: solid 1px #2976fa;
        background-color: #227af1;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  #btn-save {
    width: 168px;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;
    margin-top: 18px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    #title-new {
      font-size: 1.4rem;
    }

    .news {
      width: 100%;

      ul {
        width: 100%;
      }
    }

    .new-msg {
      #title-new-msg {
        font-size: 0.9rem;
      }
      input {
        width: 100%;
      }

      .buttons {
        flex-direction: column;
        float: none;
      }

      .message {
        width: 100%;
      }

      #btn-insert {
        margin-top: 15px;
      }
    }
  }
`;

export const DonersComponent = styled.main`
  width: 100%;
  height: 970px;

  #title-donors {
    font-size: 45px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-top: 21.5px;
    margin-bottom: 19px;
  }

  .info-doners {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 628px;
    height: 135px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    padding: 0px 40px 0 30px;

    z-index: 1;
    position: relative;

    margin-bottom: 60px;

    .content-info-doners {
      display: flex;
      align-items: center;

      img {
        margin-right: 8.6px;
      }

      #value {
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2976fa;

        margin-bottom: 6px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2976fa;
        margin-bottom: 5px;
      }
    }
  }

  .donors {
    width: 100%;
    height: 420px;

    .donor {
      .donor-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          width: 70px;
          height: 70px;
          border: solid 2px rgba(127, 126, 190, 0.31);
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 10px;

          #background-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            background-color: rgba(127, 127, 185, 0.31);

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .info-donor {
          #day {
            margin-top: 10px;
            margin-bottom: 10px;

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            opacity: 0.31;
            color: #7f7fb9;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }
      }
      section {
        margin-top: 20px;
        margin-bottom: 20px;

        display: flex;
        align-self: center;

        width: 100%;

        border: 1px dotted #eee;
      }
    }
  }

  .floatPage {
    float: right;

    margin-top: 30px;
  }

  #btn-save {
    width: 168px;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;
    margin-top: 18px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    #title-donors {
      font-size: 1.4rem;
    }

    .info-doners {
      width: 100%;

      padding: 0;

      padding: 2.5%;

      .content-info-doners {
        > img {
          display: none;
        }

        #value {
          font-size: 1rem;
        }

        p {
          font-size: 0.8rem;
        }
      }
    }
    .floatPage {
      float: none !important;
    }
  }
`;

export const MessagesComponent = styled.main`
  width: 100%;
  height: 1103px;

  #title {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;
  }

  .messages {
    .message {
      width: 720px;
      height: 226px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      padding: 30px;
      margin-bottom: 24px;

      display: flex;

      .icon {
        width: 70px;
        height: 70px;
        border: solid 2px rgba(127, 126, 190, 0.31);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 10px;

        #background-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;

          background-color: rgba(127, 127, 185, 0.31);

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .content {
        display: flex;
        flex-direction: column;

        margin-top: 19px;

        p {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
          margin-bottom: 10px;
        }

        #text {
          width: 484px;

          font-family: GothamProRegular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }

      .options {
        display: flex;
        align-items: center;

        margin-bottom: 180px;

        margin-left: -100px;

        .delete {
          display: flex;
          align-items: center;

          margin-right: 34.6px;

          cursor: pointer;

          #text-delete {
            margin-left: 10.8px;

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;
            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, "#227af1")};
            }
          }
        }
        .accept {
          display: flex;
          align-items: center;
          cursor: pointer;
          #text-delete {
            margin-left: 10.8px;

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;
            transition: all 0.3s ease-in-out 0s;
            &:hover {
              color: ${darken(0.1, "#227af1")};
            }
          }
        }
      }
    }
  }

  #dotted {
    width: 720px;
    height: 1;
    opacity: 0.19;
    border: 1px dotted #707070;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 22px;
    margin-bottom: 26px;

    display: flex;
    float: right;
  }

  #btn-save {
    width: 168px;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;
    margin-top: 18px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    #title-messages {
      font-size: 1.4rem;
    }

    .messages {
      width: 100%;
      .message {
        width: 100% !important;

        .icon {
          display: none;
          margin: 0;
        }

        .content {
          width: 55%;
        }
        .options {
          margin: 0;
          width: 20%;
          .delete {
            margin-right: 0;
          }
          .accept {
          }
        }
      }
    }
    .write {
      width: 100%;
      #title-write {
        font-size: 1rem;
      }

      .message {
        width: 100% !important;
      }
    }
  }
`;

export const New = styled.li`
  width: 710px;
  height: 212px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  z-index: 1;
  position: relative;

  padding: 20.8px 0 0 44px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 5px;

  ${(props) =>
    props.img &&
    css`
      height: 559px;

      #img-new {
        margin-top: 7.8px;
        max-width: 542px;
        max-height: 335px;
      }
    `}

  .calendar-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    margin-bottom: 5px;

    p {
      margin-left: 10px;

      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
    .trash {
      display: flex;
      align-items: center;
      margin-left: 442.6px;
      cursor: pointer;

      #text-trash {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #227af1;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  #title-new-content {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #2976fa;
  }

  p {
    width: 633px;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  @media only screen and (max-width: 768px) {
    width: 80vw !important;

    padding: 2vh 0 0 5vw;

    p {
      width: 100%;
      word-wrap: break-word;
    }

    .trash {
      margin-left: 0 !important;
      margin-right: 5vw;
    }

    ${(props) =>
      props.img &&
      css`
        #img-new {
          max-width: 80%;
          max-height: 80%;
        }
      `}
  }
`;

export const DropDownContainer = styled.div`
  z-index: 1;
`;

export const DropDownHeader = styled.div`
  margin-bottom: 0.8em;

  width: 352px;
  ${(props) =>
    props.small &&
    css`
      width: 168px;
    `}
  #text-state {
    margin-top: 30px;
    font-size: 14px;
  }

  height: 60px;
  border-radius: 30px;
  border: solid 1.4px #7f7ebe;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 25px;
  padding-right: 15px;

  font-size: 14px;
  text-align: left;
  color: #7f7fb9;

  #small {
    font-size: 15.5px;
  }

  margin-right: 16px;

  z-index: 1;

  cursor: pointer;

  .icon-drop {
    margin-bottom: 5px;
  }

  #up-icon {
    transform: rotate(180deg);
  }
  @media only screen and (max-width: 768px) {
    width: 90vw !important;
    ${(props) =>
      props.small &&
      css`
        width: 40vw !important;
      `}
  }
`;

export const DropDownListContainer = styled.div`
  margin-top: 1px;
  position: absolute;
  z-index: 1;
  width: 22em;

  ${(props) =>
    props.small &&
    css`
      width: 11em;
    `}

  .projects {
    font-size: 14px;
  }

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    margin-left: 145px;

    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #7f7ebe;

    @media only screen and (max-width: 768px) {
      left: 60vw;
      margin-left: 10vw;
    }
  }

  animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 768px) {
    position: unset !important;
    width: 80vw;
    margin-left: 5vw;
    margin-bottom: 20px;
  }
`;

export const DropDownListContainerState = styled.div`
  margin-top: 1px;
  position: absolute;
  z-index: 1;

  width: 11em;

  padding: 0;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
    position: unset !important;
    margin-bottom: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    margin-left: 55px;

    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #7f7ebe;
  }

  animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
`;

export const DropDownList = styled.ul`
  background: #ffffff;
  border: solid 1.4px #7f7ebe;
  box-sizing: border-box;
  color: #7f7ebe;

  z-index: 1;

  height: 68px;

  border-radius: 5px;

  &:first-child {
    padding-top: 5px;
  }
`;

export const ListItem = styled.li`
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  margin-left: 15px;

  border-bottom: 1.2px solid #eee;

  list-style: none;

  width: 85%;
  height: 100%;

  ${(props) =>
    props.small &&
    css`
      height: 52%;
    `}

  cursor:pointer;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    color: ${darken(0.15, "#7f7ebe")};
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    font-size: 0.85rem;
  }
`;

export const BtnNew = styled.button`
  width: 44px;
  height: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background-color: #227af1;

  ${(props) =>
    props.active &&
    css`
      background-color: ${darken(0.1, "#227af1")};
      &:hover {
        background-color: ${darken(0.15, "#227af1")};
      }
    `}

  margin-right:7px;

  transition: all 0.3s ease-in-out 0s;
  &:hover {
    background-color: ${darken(0.1, "#227af1")};
  }

  @media only screen and (max-width: 768px) {
    margin: 15px 0 15px 0;
  }
`;

export const PreviewsInstitution = styled.div`
  width: 337px;
  height: 90px;
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export const PreviewsList = styled.ul`
  z-index: 1;
  &:first-child {
    padding-top: 0.8em;
  }
`;

export const Preview = styled.li`
  list-style: none;
  margin-bottom: 0.8em;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 15px;

  #id-university {
    width: 193px;

    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
  }

  .trash {
    margin-left: 56.7px;

    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin-left: 13.8px;

      font-size: 15px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;

      background: transparent;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, "#227af1")};
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    #id-university {
      width: 55% !important;
      font-size: 0.8rem !important;
    }
    .trash {
      margin-left: 0 !important;
    }
  }
`;

export const DevelopmentList = styled.ul`
  display: flex;
`;

export const DevelopmentTeam = styled.li`
  width: 700px;
  height: 362px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  padding-top: 32px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-top: 4px;

  display: flex;

  #icon-trash {
    display: block;
    position: absolute;
    margin-left: 650px;
    margin-right: 10px;
    margin-top: 10px;

    cursor: pointer;
  }

  .profile {
    margin-left: 23px;

    #border {
      width: 120px;
      height: 120px;
      border: solid 2px rgba(127, 126, 190, 0.31);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      #img-profile {
        width: 101px;
        height: 101px;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 85%;
          height: 85%;
          border-radius: 50%;
        }
      }
    }
  }

  .content-profile {
    margin-left: 35px;

    p {
      font-family: GothamPro;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;

      margin-bottom: 12px;
      margin-top: 12px;
    }

    #description {
      margin-top: 13px;

      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }

    #section-content-profile {
      width: 90%;
      height: 2px;
      background: #eee;

      margin-bottom: 10px;
      margin-top: 10px;
    }

    a {
      margin-bottom: 12px;
      margin-top: 12px;

      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.5px;
      text-align: left;
      color: #227af1;
    }

    .social-networks {
      display: flex;
      align-items: center;

      p {
        margin-right: 10px;

        margin-bottom: 12px;
        margin-top: 25px;
      }

      .icons-social {
        margin-top: 15px;
        svg {
          color: #227af1;
          margin-left: 13.8px;
          margin-right: 13.8px;

          transition: all 0.3s ease-in-out 0s;

          &:hover {
            color: ${darken(0.1, "#227af1")};
          }
        }

        #icon-email {
          width: 24px;
          height: 19.8px;

          margin-left: 13.8px;
          margin-bottom: 2px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 89vw;
    height: 562px;
    flex-direction: column;
    align-items: center;
    margin-left: 0 !important;
    margin-left: 2vw;

    .content-profile {
      margin-left: 10%;

      p {
        font-size: 0.7rem !important;
      }
      .social-networks {
        .icons-social {
          svg,
          #icon-email {
            width: 3vw;
          }
        }
      }
    }
    #icon-trash {
      margin-left: 65vw;
    }
  }
`;

export const ProjectContent = styled.li`
  display: flex;
  flex-direction: column;

  margin-right: 8px;

  .edit {
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-bottom: 15px;

    .box {
      width: 43px;
      height: 42px;
      background-color: #227af1;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;

      margin-right: 13.8px;

      #icon-collaboration {
        width: 35px;
        height: 35px;
      }
    }

    button {
      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.21;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;

      background-color: transparent;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        color: ${darken(0.1, "#227af1")};
      }
    }
  }
`;

export const Projects = styled.div`
  width: 100%;

  .country {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const textFocus = keyframes`
 
    0% {
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
    }
    
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    !props.active &&
    css`
      width: 352px;
      margin-right: 16px;
    `}

  ${(props) =>
    props.active &&
    css`
      width: 720px;
    `}

    .full {
    width: 100%;
  }

  span {
    text-align: start;
    color: ${darken(0.1, "#227af1")};
    margin-top: 6px;
    margin-bottom: 15px;
    margin-left: 5px;
    font-weight: bold;
    font-style: italic;
    font-family: GothamProRegular;
    font-size: 14px;

    animation: ${textFocus} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  #obs-input {
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: center;
    color: #7f7fb9;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    ${(props) =>
      !props.active &&
      css`
        width: 100%;
      `}

    ${(props) =>
      props.active &&
      css`
        width: 90vw !important;
      `}
  }
`;
export const ContentSelectMember = styled.div`
  border: 2px dashed #eee;
  padding: 10px;
  border-radius: 15px;
  width: 720px;
  height: 200px;

  margin-top: 20px;
  margin-bottom: 10px;

  > p {
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
    text-align: center;
    color: #7f7fb9;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const AllUsersSearch = styled.li`
  width: 660px;
  height: 130px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;

  cursor: pointer;

  display: flex;
  align-items: center;

  .profile {
    margin-left: 23px;

    #border {
      width: 90px;
      height: 90px;
      border: solid 2px rgba(127, 126, 190, 0.31);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      #img-profile {
        width: 71px;
        height: 71px;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 85%;
          height: 85%;
          border-radius: 50%;
        }
      }
    }
  }

  .content-profile {
    margin-left: 35px;

    p {
      font-family: GothamPro;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;

      margin-bottom: 12px;
      margin-top: 12px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 75vw;

    .content-profile {
      margin-left: 10px;
    }
  }
`;
