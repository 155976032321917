import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;

  font-family: GothamPro;

  /*Webkits*/
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  /*Webkits*/

  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;

  display: flex;
  flex-direction: column;

  /*Webkits*/
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  /*Webkits*/

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 430px;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  background: #227af1;

  /*Webkits*/
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  /*Webkits*/
  .second-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    /*Webkits*/
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /*Webkits*/

    #icon-header {
      width: 53.6px;
      height: 41.8px;

      margin-top: 20px;
    }
    #title {
      margin-top: 21.8px;
      margin-bottom: 10px;

      color: #fff;
      font-size: 50px;
    }

    #text {
      width: 50%;

      font-family: GothamProRegular;
      font-size: 16px;
      line-height: 2;
      text-align: center;
      color: #ffffff;
    }

    #img-header {
      display: block;

      margin-top: -188px;
      margin-left: 1132px;
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    z-index: 10;

    .second-content-header {
      width: 100%;
      height: 30%;

      img {
        width: 36.4px;
        height: 32.8px;
      }

      #title {
        font-size: 1.5rem;
      }

      #text {
        width: 95%;
        text-align: justify;
        word-wrap: break-word;
        font-size: 0.9rem !important;
      }
      #img-header {
        display: none;
        margin-left: 0;
      }
    }
  }
`;
export const BlogContent = styled.section`
  width: 100%;
  height: 2800px;
  display: flex;
  flex-direction: row;

  margin-left: 139px;

  /*Webkits*/
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  /*Webkits*/

  .first-content-about {
    width: 738px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-right: 90px;

    /*Webkits*/
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /*Webkits*/
    .new-content {
      width: 720px;
      height: 357px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: -80px;
      padding: 0;

      position: relative;
      z-index: 1;

      object-fit: contain;
      border-radius: 10px;

      box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 33%,
        #000000
      );

      /*Webkits*/
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(33%, rgba(0, 0, 0, 0)),
        to(#000000)
      );
      background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 33%, #000000);
      /*Webkits*/

      #first-img {
        width: 720px;
        height: 355px;
        border-radius: 10px;
        position: absolute;
      }

      #shadow {
        width: 750px;
        height: 392px;
        z-index: 5;

        position: absolute;
        margin-left: 0px;
        margin-top: 0px;
        border-radius: 10px;
      }

      .about-new {
        display: flex;
        flex-direction: column;

        position: relative;
        z-index: 6;

        margin-top: 94px;
        margin-left: 07.5px;

        /*Webkits*/
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        /*Webkits*/

        #day {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.6;
          text-align: left;
          color: #227af1;
        }

        #title {
          margin-top: 0px;

          font-size: 28px;
          font-weight: 500;
          line-height: 1.14;
          text-align: left;
          color: #ffffff;
        }

        #text {
          width: 614px;

          margin-top: 18px;

          font-family: GothamProRegular;
          font-size: 16px;
          line-height: 2;
          text-align: left;
          color: #ffffff;
        }
      }
    }

    .news {
      width: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin-top: 80px;
      margin-bottom: 30px;

      /*Webkits*/
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      -ms-flex-wrap: wrap;
      /*Webkits*/
    }

    .floatPage {
      align-self: flex-end;
      margin-left: 420px;
    }
  }
  .second-content-about {
    width: 260px;
    .search {
      margin-top: 50px;

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      padding-bottom: 5px;
      border-bottom: 1.5px solid rgba(34, 122, 241, 0.56);

      input {
        color: #227af1;
        font-size: 16px;
        font-weight: 600;
        opacity: 0.95;
        height: 50px;

        &::placeholder {
          font-size: 16px;
          font-weight: 600;
          opacity: 0.95;
          color: #227af1;
        }
      }

      .box-search {
        cursor: pointer;
        margin-top: 15px;

        img {
          margin-left: 13px;
        }
      }
    }

    .category {
      width: 260px;
      height: 301px;

      margin-top: 51px;

      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 20px 20px 0 20px;

        /*Webkits*/
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        /*Webkits*/

        img {
          margin-right: 9.4px;
        }

        #title {
          color: #227af1;
          font-size: 23px;
        }
      }

      > section {
        margin-top: 20px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }

      ul {
        margin-top: 40px;
        margin-left: 20px;
      }
    }

    .news {
      margin-top: 79px;
      .title {
        img {
          height: 40px;
          width: 40px;
        }
        p {
          margin-top: 10px;
          font-size: 20px;
          color: #227af1;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 4200px;
    margin: 0;

    display: flex;
    flex-direction: column !important;

    .first-content-about {
      height: 80%;
      width: 100%;

      .new-content {
        display: none;
      }

      .news {
        width: 100%;

        display: flex;
        flex-direction: column;

        margin: 0;

        margin-top: 20px;
        margin-left: 5%;
      }

      .floatPage {
        margin: 0;
        align-self: center;
      }
    }

    .second-content-about {
      width: 55%;
      height: 35%;

      align-self: center;

      margin-top: 0 !important;

      .search,
      .category {
        display: none;
      }

      .news {
        display: none;
        margin-bottom: 5vh;
      }
      .newsletter {
        .title {
          width: 100%;
          img {
            width: 10%;
          }
          #title {
            font-size: 1rem;
            margin-left: 0px;
          }
        }
        .content {
          width: 100%;
          padding: 0 0 0 5%;

          #text {
            font-size: 0.7rem;
          }

          #button {
            width: 80%;
          }
        }
      }
    }
  }
`;

export const Categories = styled.li`
  margin-bottom: 23px;

  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #7f7fb9;

  cursor: pointer;

  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;

  ${(props) =>
    props.active &&
    css`
      color: #227af1;
    `}

  &:hover {
    color: ${darken(0.1, "#7f7fb9")};

    ${(props) =>
      props.active &&
      css`
        color: ${darken(0.1, "#227af1")};
      `}
  }
`;

export const NewAside = styled.div`
  width: 260px;

  margin-top: 50px;
  margin-bottom: 30px;

  #day {
    font-size: 17px;
    color: #b0b3ce;
    margin-bottom: 10px;
  }

  #title {
    color: #227af1;
    font-size: 19px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
  }

  #text {
    margin-top: 10px;
    margin-bottom: 20px;

    font-family: GothamProRegular;
    letter-spacing: 2;
    line-height: 2;
    font-size: 14px;
  }

  div {
    display: flex;
    align-items: center;

    /*Webkits*/
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /*Webkits*/

    img {
      margin-right: 10px;
    }

    a {
      color: #227af1;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
    }
  }

  section {
    margin-top: 20px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
`;

export const New = styled.div`
  width: 352px;
  height: 513px;

  margin-bottom: 30px;
  margin-right: 17px;

  z-index: 1;

  background: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px 10px 0 0;

    /*Webkits*/
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    /*Webkits*/

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .content-2 {
    width: 100%;
    height: 230px;
    background-color: #fff;

    padding: 30px;

    #day {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      text-align: left;
      color: #bbbbc7;

      margin-bottom: 10px;
    }

    #title {
      color: #227af1;
      font-size: 21px;
      font-weight: 500;
      line-height: 1.5;
      text-align: left;
    }

    #text {
      margin-top: 10px;
      font-family: GothamProRegular;
      letter-spacing: 2;
      line-height: 2;
      font-size: 14px;
    }
  }

  .content-3 {
    width: 100%;
    height: 59px;

    display: flex;
    align-items: center;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);

    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    margin-top: 60px;

    /*Webkits*/
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /*Webkits*/

    img {
      margin: 15px 20px 20px 40px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 2;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90%;

    p {
      word-wrap: break-word;
    }
  }
`;