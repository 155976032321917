import React from "react";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter, Router } from "react-router-dom";

import GlobalStyle from "./styles/global";
import Routes from "./routes";
import history from "./services/history";

import { store, persistor } from "./store";

import "./styles/fonts.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <BrowserRouter>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoClose={3500} />
         
          </BrowserRouter>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
