import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 170px;

  border-radius: 10px 10px 0 0;
  background-color: #fff;
  
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
