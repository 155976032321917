import { darken } from "polished";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 0;
  background: #fff;
  margin-bottom: 20px;
  margin-top: 20px;

  @media screen and (max-width: 348px) {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;

  flex-basis: 100%;
  max-width: 100%;
`;

export const Gallery = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 48px;
    height: 48px;
    background-color: #fff;
    border-radius: 50%;

    transition: all 0.3s ease-in-out 0s;

    cursor: pointer;
    &:hover {
      background-color: ${darken(0.1, "#FFF")};
    }

    &:disabled {
      opacity: 0.6;
      background-color: ${darken(0.1, "#FFF")};
      cursor: not-allowed;
    }
  }

  .images {
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 96px);

    img {
      max-height: 144px;
      max-width: 205px;
    }
  }

  @media screen and (max-width: 835px) {
    .images {
      img {
        max-height: 104px;
        max-width: 104px;
      }
    }
  }

  @media screen and (max-width: 610px) {
    .images {
      img {
        max-height: 74px;
        max-width: 74px;
      }
    }
  }

  @media screen and (max-width: 495px) {
    button {
      width: 35px;
      height: 35px;

      img {
        width: 35px;
        height: 35px;
      }
    }

    .images {
      padding: 0 5px;
      width: calc(100% - 70px);

      img {
        max-height: 44px;
        max-width: 44px;
      }
    }
  }
`;
