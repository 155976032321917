import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 0 17px 0;
  background: rgb(41, 118, 250);
  background: linear-gradient(
    90deg,
    rgba(41, 118, 250, 1) 22%,
    rgba(68, 169, 252, 1) 71%,
    rgba(75, 183, 253, 1) 95%
  );
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 54px;
  position: relative;
  z-index: 0;
`;

export const Column = styled.div`
  flex: 1;
  max-width: 33.3%;
  flex-basis: 33.3%;

  display: flex;
  flex-direction: column;

  ${(props) =>
    props.flexEnd &&
    css`
      align-items: flex-end;
    `}

  position: relative;
  z-index: 1;

  h1 {
    font-size: 45px;
    font-weight: 500;
    line-height: 1.56;
    text-align: left;
    color: #ffffff;

    margin-bottom: 19px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.875;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    max-width: 50%;
    flex-basis: 50%;
  }

  @media screen and (max-width: 532px) {
    justify-content: center;
    h1 {
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 356px) {
    h1 {
      font-size: 1rem;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 70%;

  ${(props) =>
    props.small &&
    css`
      max-width: 50%;
    `}

  @media screen and (max-width: 768px) {
    ${(props) =>
      props.small &&
      css`
        display: none;
      `}
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;
