import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 180px 0 0 0;
  background: #fff;
  position: relative;
  z-index:-1;

  @media screen and (max-width: 1010px) {
    padding: 80px 0 0 0;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;

  ${(props) =>
    props.margin &&
    css`
      margin-top: 66.4px;
    `}
  @media screen and (max-width: 1010px) {
    ${(props) =>
      props.margin &&
      css`
        margin-top: 0;
      `}
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  position: relative;
  z-index: 1;

  h1 {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;

    color: #227af1;

    text-transform: uppercase;

    margin-top: 15.6px;
  }

  p {
    font-family: GothamProRegular;
    font-size: 14px;
    line-height: 2;
    text-align: left;
    color: #000;

    margin-top: 10px;
  }

  @media screen and (max-width: 1010px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
  @media screen and (min-width: 314px) and (max-width: 410px) {
    h1{
      font-size:1.5rem;
    }
  }

  @media screen and (max-width: 314px) {
    h1{
      font-size:1rem;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;

  transform: translateY(69px);

  @media screen and (max-width: 1010px) {
    transform: translateY(0);
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;
