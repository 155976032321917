import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 34px 0 93px 0;
  background: #fff;
  position: relative;
  z-index: 0;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  z-index: 1;

  max-width: 100%;
  flex-basis: 100%;

  h1 {
    font-size: 50px;
    color: #227af1;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;

    margin-top: 19px;
  }

  > img {
    max-height: 55px;
  }

  @media screen and (max-width:762px) {
    h1 {
    font-size: 2rem;
    }
  }
  @media screen and (max-width:488px) {
    h1 {
    font-size: 1rem;
    }
  }
`;

export const Projects = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;

  flex-wrap: wrap;

  ${(props) =>
    !props.lessTwo &&
    css`
      justify-content: space-between;
      gap: 0;
    `}

  @media screen and (max-width:762px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`;
