import React, { useEffect, useState } from "react";

import {
  Wrapper,
  WrapperTwo,
  WrapperThree,
  Row,
  Column,
  RowTwo,
  ListItem,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  Img,
  ImgWrapper,
  Cosmos,
} from "./styles";

import { Container } from "../../styles/global";

import projects from "../../assets/icons/Common/projects.svg";
import iconDrop from "../../assets/icons/Common/drop.svg";

import imgHeader from "../../assets/images/Projects/header.svg";

import { toast } from "react-toastify";
import { useHistory } from "react-router";
import api from "../../services/api";
import { store } from "../../store";
import Scrollbars from "react-custom-scrollbars";

import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Project from "../../components/NewProject";
import Pagination from "../../components/Pagination";

import cosmos1 from "../../assets/icons/Projects/cosmos-1.svg";
import cosmos2 from "../../assets/icons/Projects/cosmos-2.svg";
import { Helmet } from "react-helmet";

function Projetos() {
  var options = [
    "Agronegócio",
    "Meio ambiente e Sustentabilidade",
    "Indústria",
    "Comércio e prestação de serviços",
    "Desenvolvimento Urbano",
    "Artes e Cultura",
    "Ciência e Tecnologia",
    "Economia",
    "Educação",
    "Saúde",
    "Filosofia e Sociologia",
    "Entretenimento",
    "Infantil",
    "Internet",
    "Negócios e Oportunidades",
    "Política",
    "Produtos e Serviços",
    "Responsabilidade Social",
    "Mídia e Comunicação",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(9);
  const [filters, setFilters] = useState([]);
  const [value, setValue] = useState(null);
  const [valueScientist, setValueScientist] = useState(null);

  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const [newCategories, setNewCategories] = useState([]);

  useEffect(() => {
    const loadCategories = async () => {
      const res = await api.get("new-categories");
      setNewCategories(res.data);
    };
    loadCategories();
  }, []); // [] avoid useEffect() to run and make an never ended loop at each updates

  newCategories.map((item) => options.push(item.category));

  useEffect(() => {
    const FetchCountries = async () => {
      if (!!token && perfil !== "admin") {
        var numberType = () => {
          if (perfil === "Apoiador/Visitante") {
            return 1;
          } else if (perfil === "Apoiador Empresarial/Desafiante") {
            return 2;
          } else if (perfil === "Cientista/Pesquisador") {
            return 3;
          }
        };

        var type = numberType();

        const res = await api.get(`/projects-auth/${profile.id}/${type}`);
        setCountries(res.data);
        setFilters(res.data);
      } else {
        const res = await api.get("projects");
        setCountries(res.data);
        setFilters(res.data);
      }
    };
    FetchCountries();
  }, [perfil, profile, token]); // [] avoid useEffect() to run and make an never ended loop at each updates

  //get the currents countries to display in page

  const history = useHistory();

  const paginate = (number) => setCurrentPage(number);

  function handleFilter() {
    setCountries(filters);

    //Só o id do projeto
    if (
      value != null &&
      (valueScientist === null || valueScientist === "") &&
      selectedOption === null
    ) {
      if (
        countries.filter((countrie) => countrie.id === parseInt(value))
          .length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setValue(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter((countrie) => countrie.id === parseInt(value))
        );
        setValue(null);
      }
    }

    //Só nome do Cientista
    else if (
      (value === null || value === "") &&
      (valueScientist !== null || valueScientist !== "") &&
      selectedOption === null
    ) {
      if (
        countries.filter((countrie) =>
          countrie.scientist.name.includes(valueScientist)
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter((countrie) =>
            countrie.scientist.name.includes(valueScientist)
          )
        );
        setSelectedOption(null);
      }
    }

    //Só categoria
    else if (
      (value === null || value === "") &&
      (valueScientist === null || valueScientist === "") &&
      selectedOption
    ) {
      if (
        selectedOption &&
        countries.filter(
          (countrie) => countrie.category === `${selectedOption}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) => countrie.category === `${selectedOption}`
          )
        );
        setSelectedOption(null);
      }
    }

    //ID e Cientista
    else if (
      (value !== null || value !== "") &&
      (valueScientist !== null || valueScientist !== "") &&
      selectedOption === null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.id === parseInt(value) &&
            countrie.scientist.name.includes(valueScientist)
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setValue(null);
        setValueScientist(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.id === parseInt(value) &&
              countrie.scientist.name.includes(valueScientist)
          )
        );
        setValue(null);
        setValueScientist(null);
      }
    }

    //ID e categoria
    else if (
      value != null &&
      (valueScientist === null || valueScientist === "") &&
      selectedOption != null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.id === parseInt(value) &&
            countrie.category === `${selectedOption}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setValue(null);
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.id === parseInt(value) &&
              countrie.category === `${selectedOption}`
          )
        );
        setValue(null);
        setSelectedOption(null);
      }
    }
    //categoria e Cientista
    else if (
      (value === null || value === "") &&
      (valueScientist !== null || valueScientist !== "") &&
      selectedOption != null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.scientist.name.includes(valueScientist) &&
            countrie.category === `${selectedOption}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setValueScientist(null);
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.scientist.name.includes(valueScientist) &&
              countrie.category === `${selectedOption}`
          )
        );
        setValueScientist(null);
        setSelectedOption(null);
      }
    }

    //Todos
    else if (
      (value !== null || value !== "") &&
      (valueScientist !== null || valueScientist !== "") &&
      selectedOption != null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.id === parseInt(value) &&
            countrie.scientist.name.includes(valueScientist) &&
            countrie.category === `${selectedOption}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setValueScientist(null);
        setSelectedOption(null);
        setValue(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.id === parseInt(value) &&
              countrie.scientist.name.includes(valueScientist) &&
              countrie.category === `${selectedOption}`
          )
        );
        setValue(null);
        setValueScientist(null);
        setSelectedOption(null);
      }
    }
  }

  function handleTeste() {
    if (selectedOption != null || value != null || valueScientist != null) {
      handleFilter();
    } else {
      toast.error("Nenhuma opção selecionada!");
    }
  }

  const indexLastCountries = currentPage * countriesPerPage;
  const indexFirstCountries = indexLastCountries - countriesPerPage;
  const currentCountries = countries.slice(
    indexFirstCountries,
    indexLastCountries
  );

  return (
    <>
      <Helmet>
        <title>Projetos e Pesquisas</title>
      </Helmet>
      <Wrapper>
        <Container margin>
          <SubHeader />
        </Container>
      </Wrapper>
      <Wrapper>
        <Container>
          <Row>
            <Column>
              <img src={projects} alt="" />
              <h1>PROJETOS E PESQUISAS</h1>
              <p id="text-2">
                Apenas cientistas e pesquisadores vinculados à Organizações
                Científicas, Instituições de Ensino e Pesquisa ou com Permissões
                Especiais podem cadastrar projetos científicos em nossa
                plataforma, que verifica e avalia a procedência e confiabilidade
                de cada projeto antes de torná-lo público aos nossos visitantes
                e apoiadores.
                <br />
                <br />
                Por isso fique tranquilo(a) para apoiar qualquer projeto ou
                pesquisa científica que encontrar aqui!
              </p>

              <div className="buttons">
                <p id="title-button">Encontrar projetos: </p>

                <input
                  id="button-1"
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                  placeholder="Identificação do Projeto"
                />

                <input
                  id="button-1"
                  onChange={(event) => {
                    setValueScientist(event.target.value);
                  }}
                  placeholder="Nome do Cientista"
                />

                <DropDownContainer>
                  <DropDownHeader onClick={toggling}>
                    <p>{selectedOption || "Categoria"}</p>
                    {isOpen ? (
                      <img
                        className="icon-drop"
                        id="up-icon"
                        src={iconDrop}
                        alt=""
                      />
                    ) : (
                      <img className="icon-drop" src={iconDrop} alt="" />
                    )}
                  </DropDownHeader>
                  {isOpen && (
                    <DropDownListContainer>
                      <DropDownList>
                        <Scrollbars style={{ height: 50 }}>
                          {options.map((option) => (
                            <ListItem
                              onClick={onOptionClicked(option)}
                              key={Math.random()}
                            >
                              {option}
                            </ListItem>
                          ))}
                        </Scrollbars>
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </DropDownContainer>

                <button id="button-3" type="submit" onClick={handleTeste}>
                  Filtrar
                </button>

                <div className="img-header">
                  <ImgWrapper>
                    <Img id="" src={imgHeader} alt="" />
                  </ImgWrapper>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <WrapperThree>
        <Container>
          <Row>
            <Cosmos>
              <div className="rocket-content">
                <img src={cosmos2} id="cosmos-2" alt="" />
                <img src={cosmos1} id="cosmos-1" alt="" />
              </div>

              <div className="main-content">
                <p id="title-cosmos">Doe para o FUNDO COSMOS!</p>
                <p id="text-cosmos">
                  O Fundo Cosmos é a conta universal da plataforma We4Science.
                  Aqui os apoiadores da Ciência podem fazer doações sem precisar
                  escolher um projeto científico específico. Quando houver
                  doações suficientes, nós lançaremos editais e desafios
                  periodicamente para destinar os recursos desse fundo, que
                  terão como finalidade projetos ligados à Sustentabilidade e
                  Meio Ambiente, Saúde, Educação e também Exploração Espacial.
                </p>
                <hr />
                <button
                  id="btn-cosmos"
                  onClick={() => {
                    if (!!token) {
                      history.push("cosmos");
                    } else {
                      toast.error("É necessário estar logado!");
                    }
                  }}
                >
                  Clique aqui!
                </button>
              </div>
            </Cosmos>
          </Row>
        </Container>
      </WrapperThree>
      <WrapperTwo>
        <Container margin>
          <RowTwo lassTwo={countries.length > 2 ? false : true}>
            {currentCountries.map((data, key) => (
              <Project data={data} key={key} />
            ))}
          </RowTwo>
          <RowTwo float>
            {countries.length > 0 && (
              <Pagination
                currentPage={currentPage}
                countriesPerPage={countriesPerPage}
                totalCountries={countries.length}
                paginate={paginate}
              />
            )}
          </RowTwo>
        </Container>
      </WrapperTwo>

      <Footer />
    </>
  );
}

export default Projetos;
