import React from "react";

import { Container } from "./styles";

import { useHistory } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function GoBackTerms() {
  const history = useHistory();

  return (
    <Container
      onClick={() =>
        history.length <= 1
          ? history.push("/contact?&type=normal")
          : history.goBack()
      }
    >
      <FaArrowLeft size={25} />
      <p>Voltar</p>
    </Container>
  );
}

export default GoBackTerms;
