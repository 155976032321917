import { darken } from "polished";
import styled from "styled-components";
import { bussOut } from "../../../../styles/animations";

export const Container = styled.div`
  margin-top: 20px;

  width: 352px;
  height: 232px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border: solid 2px rgba(255, 255, 255, 0.41);

  margin-right: 17px;

  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    font-size: 19.5px;
    color: #227af1;
    margin-top: 20px;
  }

  section {
    margin-top: 20px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }

  .social-networks {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 20px;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    svg {
      color: #227af1;
      transition: all 0.3s ease-in-out 0s;

      &:hover {
        color: ${darken(0.2, "#227af1")};
        animation: ${bussOut} 1.5s;
      }
    }
  }

  .share-project {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 27.6px;

    textarea {
      outline: none;
      resize: none;
      overflow: hidden;
      width: 270px;
      background: transparent;
      font-size: 14px;
      color: #7f7fb9;

      margin-left: 0px;
    }

    button {
      background: transparent;
      color: #227af1;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        color: ${darken(0.2, "#227af1")};
        animation: ${bussOut} 1.5s;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;
    > p {
      font-size: 1rem;
    }

    .share-project {
      width: 100%;
      textarea {
        width: 90%;
        font-size: 0.9rem;
      }
    }
  }
`;
