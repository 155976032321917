import React from "react";

import { Scrollbars } from "react-custom-scrollbars";

import Message from "./Message";

const Messages = ({ messages, name }) => (
  <Scrollbars style={{ width: 650, height: 500 }}>
    {messages.map((message, i) => (
      <div key={i}>
        <Message message={message} name={name} />
      </div>
    ))}
  </Scrollbars>
);

export default Messages;
