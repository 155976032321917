import React, { useRef, useState } from "react";
import { Form, Textarea } from "@rocketseat/unform";
import { toast } from "react-toastify";
import Pagination from "../../../../components/Pagination";
import api from "../../../../services/api";
import { store } from "../../../../store";

import { Container } from "./styles";

import iconDonor from "../../../../assets/icons/Common/avatar-input.svg";

function MessagesContent({ data,projectId,scientistId }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [countriesPerPage] = useState(3);
    const paginate = (number) => setCurrentPage(number);

    const indexLastCountries = currentPage * countriesPerPage;
    const indexFirstCountries = indexLastCountries - countriesPerPage;
    const currentCountries = data.slice(
      indexFirstCountries,
      indexLastCountries
    );

    const formRef = useRef(null);

    function functionThatResetsForm() {
      formRef.current.reset();
      formRef.current.clearField("email");
    }

    const { token, perfil } = store.getState().auth;
    const { profile } = store.getState().user;
    
    async function handleDeposition(
        data,
        { resetForm = functionThatResetsForm }
      ) {
        data.project_id = parseInt(projectId);
        data.user = profile.name;
    
        try {
          if (
            perfil === "Cientista/Pesquisador" &&
            profile.id === scientistId
          ) {
            toast.error("O criador do projeto não pode fazer um depoimento!");
          } else {
            await api.post("depositions", data);
            toast.success("Depoimento enviado!");
          }
        } catch (error) {
          toast.error("Erro ao enviar Depoimento");
        }
        resetForm();
      }
  return (
    <Container>
      <p id="title-messages"> Depoimentos </p>
      <div className="messages">
        {currentCountries.map((message) => (
          <div className="message" key={message.id}>
            <div>
              <div className="icon">
                <div id="background-img">
                  <img src={iconDonor} alt="" />
                </div>
              </div>
            </div>
            <div className="content">
              <p> {message.formattedDate} </p>
              <p> {message.user} </p>
              <p id="text"> {message.content} </p> <p> </p>
            </div>
          </div>
        ))}
      </div>
      {currentCountries.length > 0 && (
        <div className="floatPage">
          <Pagination
            currentPage={currentPage}
            countriesPerPage={countriesPerPage}
            totalCountries={data.length}
            paginate={paginate}
          />
        </div>
      )}
      {token && (
        <div className="write">
          <p id="title-write"> Enviar depoimento </p>
          <Form onSubmit={handleDeposition} ref={formRef}>
            <Textarea
              className="message"
              name="content"
              placeholder="Escrever Mensagem..."
            />
            <button type="submit" id="btn-send">
              Enviar
            </button>
          </Form>
        </div>
      )}
    </Container>
  );
}

export default MessagesContent;
