import React from "react";

import Header from "./components/Header";
import First from "./components/First";
import Second from "./components/Second";
import Third from "./components/Third";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

function Supporters() {
  return (
    <>
      <Helmet>
        <title>Apoiadores</title>
      </Helmet>
      <Header />
      <First />
      <Second />
      <Third />
      <Footer />
    </>
  );
}

export default Supporters;
