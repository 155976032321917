import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 0 40px 0;
  background: #fff;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
  ${(props) =>
    props.center &&
    css`
      align-items: center;
    `}
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  position: relative;
  z-index: 1;

  h1 {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.1;
    text-align: left;
    color: #227af1;
    margin-top: 10px;
  }

  #icons-projects {
    max-height: 58.8px;
  }

  button {
    margin-top: 30px;

    width: 350px;
    height: 55px;

    align-self: center;

    background: transparent;
    border: 2px solid #227af1;

    border-radius: 30px;

    font-size: 16.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #227af1;

    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background: #227af1;
      color: #fff;
      -webkit-transform: translate3d(0px, 2px, 1px);
      transform: translate3d(0px, 2px, 1px);
    }
  }

  @media screen and (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
    align-items: center;
    h1 {
      font-size: 2rem;
    }
    button {
      width: 100%;
    }
  }
`;

export const Projects = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${(props) =>
    props.lassTwo &&
    css`
      justify-content: flex-start;
      gap:15px;
    `}

  ${(props) =>
    !props.lassTwo &&
    css`
      justify-content: space-between;
    `}

  @media screen and (max-width: 803px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
