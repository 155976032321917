import React, { useEffect, useRef, useState } from "react";

import { Container, Doubts } from "./styles";

import iconNext from "../../../../assets/icons/Common/product-right.svg";
import iconPrev from "../../../../assets/icons/Common/product-left.svg";
import iconCheck from "../../../../assets/icons/Challenges/check.svg";
import iconDonor from "../../../../assets/icons/Common/avatar-input.svg";

import YouTubeVideo from "../../../../lib/youtubevideo";
import Scrollbars from "react-custom-scrollbars";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { store } from "../../../../store";
import { Form, Textarea } from "@rocketseat/unform";

function Main({ challenge, doubts }) {
  var galery = [];

  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const [check, setCheck] = useState(null);
  const [positionImg, setPositionImg] = useState(0);

  function completeLink(link) {
    var finalLink;
    if (link !== null) {
      if (
        link.substring(0, 7) === "http://" ||
        link.substring(0, 8) === "https://"
      ) {
        finalLink = link;
      } else {
        finalLink = "https://www." + link;
      }
      return finalLink;
    } else {
      return link;
    }
  }

  var link = challenge && challenge.link_youtube;
  const usersData = [
    {
      id: 1,
      username: `${link}`,
    },
  ];

  /*
  Youtube Components
  */
  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/); // eslint-disable-next-line
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
  const usersIds = usersData.map((user) => YouTubeGetID(user.username));
  const a = Object.values(usersIds);

  const videoIDs = a;

  useEffect(() => {
    async function chechTrue() {
      var img = new Image();
      img.src = "http://img.youtube.com/vi/" + videoIDs + "/mqdefault.jpg";
      img.onload = function () {
        if (this.width === 120) {
          setCheck(false);
        } else {
          setCheck(true);
        }
      };
    }
    chechTrue();
  }, [videoIDs]);
  /*
  Youtube Components
  */

  if (check) {
    galery.push(0);
    challenge &&
      challenge.first != null &&
      galery.push(challenge && challenge.first.url);
    challenge &&
      challenge.second != null &&
      galery.push(challenge && challenge.second.url);
    challenge &&
      challenge.third != null &&
      galery.push(challenge && challenge.third.url);
  } else {
    challenge &&
      challenge.first != null &&
      galery.push(challenge && challenge.first.url);
    challenge &&
      challenge.second != null &&
      galery.push(challenge && challenge.second.url);
    challenge &&
      challenge.third != null &&
      galery.push(challenge && challenge.third.url);
  }

  function nextImg() {
    if (positionImg < galery.length - 1) {
      setPositionImg(positionImg + 1);
    }
  }

  function prevImg() {
    if (positionImg > 0) {
      setPositionImg(positionImg - 1);
    }
  }

  async function handleComplaint() {
    if (!!token) {
      var data = Object.assign({
        user: profile.id,
        perfil: perfil,
        challenge_id: parseInt(challenge.id),
      });
      try {
        await api.post("complaint-challenges", data);
        toast.success("Desafio denunciado, nossa equipe irá averiguar!");
      } catch (error) {
        toast.error("Erro ao denunciar desafio!");
      }
    } else {
      toast.error("É necessário estar logado!");
    }
  }

  const formRef = useRef(null);

  function functionThatResetsForm() {
    formRef.current.reset();
    formRef.current.clearField("email");
  }

  async function handleDeposition(
    data,
    { resetForm = functionThatResetsForm }
  ) {
    try {
      if (
        perfil === "Apoiador Empresarial/Desafiante" &&
        profile.id === challenge.company_id
      ) {
        toast.error("O criador do Desafio não pode enviar uma dúvida!");
      } else {
        data.user = profile.name;
        data.challenge_id = challenge && challenge.id;
        data.answer = false;
        await api.post("/doubts", data);
        toast.success("Dúvida enviada!");
      }
    } catch (error) {
      toast.error("Erro ao enviar Depoimento");
    }

    resetForm();
  }

  return (
    <Container>
      <p id="title-challenge">{challenge && challenge.title}</p>
      <div className="img-challenge">
        <img
          src={challenge && challenge.first.url}
          alt=""
          id="image-about-challenge"
        />
      </div>

      <div className="about-challenge">
        <p id="title-about">O que é o Desafio?</p>
        <section id="section-about"></section>
        <p id="text-about">{challenge && challenge.description}</p>
      </div>

      <div className="galery">
        <div className="title">
          <p>Galeria</p>
          <div className="icons">
            <div
              className="icons-gallery"
              onClick={() => {
                prevImg();
              }}
            >
              <img className="icon-prev" src={iconPrev} alt="" />
            </div>

            <div
              className="icons-gallery"
              onClick={() => {
                nextImg();
              }}
            >
              <img src={iconNext} alt="" />
            </div>
          </div>
        </div>

        <div className="images">
          {check && positionImg === 0 ? (
            <YouTubeVideo id="yt" users={usersData} videoIDs={videoIDs} />
          ) : (
            <img id="img" src={galery[positionImg]} alt="" />
          )}
        </div>
      </div>

      {challenge && challenge.useful_links !== null && (
        <div className="links">
          <p id="title">Links úteis</p>
          <section id="section-links"></section>
          {challenge && (
            <Scrollbars style={{ width: 628, height: 183 }}>
              {challenge.useful_links
                .split(";")
                .filter((link) => link !== "")
                .map((option) => (
                  <div className="link" key={Math.random()}>
                    <img src={iconCheck} alt="" />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${option}`}
                    >
                      {option}
                    </a>
                  </div>
                ))}
            </Scrollbars>
          )}
        </div>
      )}

      <div className="about-seller">
        <p id="title-about-seller">Quem será o Vencedor?</p>
        <section id="section-about-seller"></section>
        <p id="text-about-seller">{challenge && challenge.to_win}</p>
      </div>

      <div className="about-company">
        <p id="title-about-company">Empresa Desafiante</p>
        <section id="section-about-company"></section>
        <div className="about-company-content">
          <div className="logo">
            <img
              src={
                challenge &&
                challenge.company.avatar !== null &&
                challenge.company.avatar.url
              }
              alt=""
            />
          </div>
          <div className="content-info-company">
            <p>Empresa: {challenge && challenge.company.name}</p>
            <p style={{ width: "90%" }}>
              Site da empresa:{" "}
              <a
                href={challenge && completeLink(challenge.company.website)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {challenge && challenge.company.website}
              </a>
            </p>
            <p>O que fazemos:</p>
            <p id="text-content-info-company">
              {challenge && challenge.company.about}
            </p>
            <div className="social-networks">
              <p>Redes sociais:</p>
              <div className="icons-social">
                <a
                  href={challenge && completeLink(challenge.company.instagram)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram
                    title={challenge && `${challenge.company.instagram}`}
                    size={21}
                    color="#7f7fb9"
                  />
                </a>
                <a
                  href={challenge && completeLink(challenge.company.twitter)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter
                    title={challenge && `${challenge.company.twitter}`}
                    size={21}
                    color="#7f7fb9"
                  />
                </a>
                <a
                  href={challenge && completeLink(challenge.company.facebook)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF
                    title={challenge && `${challenge.company.facebook}`}
                    size={19}
                    color="#7f7fb9"
                  />
                </a>
                <a
                  href={challenge && completeLink(challenge.company.linkedin)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn
                    title={challenge && `${challenge.company.linkedin}`}
                    size={21}
                    color="#7f7fb9"
                  />
                </a>
                <a
                  href={challenge && completeLink(challenge.company.youtube)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube
                    title={challenge && `${challenge.company.youtube}`}
                    size={21}
                    color="#7f7fb9"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="denounce-content">
        <p id="title-denounce">Denunciar Desafio</p>
        <section id="section-denounce"></section>
        <div className="content-about-denounce">
          <p id="text-about-denounce">
            Encontrou aqui alguma informação que viola nossos Termos de Uso?
            Vamos analisar sua denúncia com muita atenção e responsabilidade, e
            assim que possível dar um retorno para você e para o responsável
            pelo conteúdo.
          </p>
          <button onClick={handleComplaint}>Denunciar</button>
        </div>
      </div>

      {doubts.length !== 0 && (
        <div className="questions-content">
          <p id="title-questions">Dúvidas sobre o desafio</p>
          <section id="section-questions"></section>

          <div className="questions">
            {doubts.length > 0 && (
              <Scrollbars style={{ width: 650, height: 490 }}>
                {doubts.map((question) => (
                  <Doubts active={question.answer} key={question.id}>
                    <div>
                      <div className="icon">
                        <div id="background-img">
                          <img src={iconDonor} alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="content">
                      <p>{question.formattedDate}</p>
                      <p>{question.user}</p>
                      <p id="text">{question.content}</p>
                      <p></p>
                    </div>
                  </Doubts>
                ))}
              </Scrollbars>
            )}
          </div>
          {token && (
            <div className="write">
              <p id="title-write">Enviar dúvida</p>
              <Form onSubmit={handleDeposition} ref={formRef}>
                <Textarea
                  className="message"
                  name="content"
                  placeholder="Escrever Mensagem..."
                />
                <button type="submit" id="btn-send">
                  Enviar
                </button>
              </Form>
            </div>
          )}
        </div>
      )}
    </Container>
  );
}

export default Main;
