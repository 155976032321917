import styled from 'styled-components';
import { darken } from 'polished'


export const Wrapper = styled.div `
    height: 100%;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    
    font-family: GothamPro;
    @media only screen and (max-width:768px){
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const Content = styled.div `
    width:1366px;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    @media only screen and (max-width:768px){
        width:100vw;
    }
`

export const Header = styled.header `
    width:100%;
    height: 430px;

    background:#227af1;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    .second-content-header{
       
        #img-header{
            margin-top:40px;
            float:right;
        }
    }

    @media only screen and (max-width: 849px) {
        padding-top:10px;
    }
    @media only screen and (max-width:768px){
        width:100vw;
        height: 230px;

        .second-content-header{
            #img-header{
                display:none;
                margin-left:0;
            }
        }

    }
`
export const InfoContent = styled.section `
    display:flex;

    flex-direction:row;
    margin-left:140px;
    padding-bottom:50px;

    .first-content-about{
        width:738px;
        margin-right:150px;
        display:flex;
        flex-direction:column;
        align-items:center;

        .content-image{
            margin-top:-280px;
            margin-bottom:50px;

            display:flex;
            align-items:center;
            justify-content:center;

            
            width: 720px;
            height: 357px;
            object-fit: contain;
            border-radius: 10px;
            box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 33%, #000000);

            padding:0;

            img{
                width: 750px;
                height: 385px;
               

                position: absolute;
            }

            #first-img{
                max-width: 716.5px;
                max-height: 355px;
                border-radius: 10px;
            }


            #shadow{
                position: absolute;
            }

            
        }

        .info-content{
            .titles{
                margin-bottom:67px;

                #day{
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: normal;
                    text-align: left;
                    color: #227af1;

                    margin-bottom:8px;
                }

                #title{
                    width: 670px;
                    
                    font-family: GothamPro;
                    font-size: 30px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.07;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000000;
                }

                #text{
                    width: 716px;

                    font-family:GothamProRegular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2;
                    letter-spacing: normal;
                    text-align: justify;
                    color: #000000;
                    white-space: pre-wrap;

                    margin-top:14px;
                }
            }
        }

        .content{
            #text{
                width: 716px;

                font-family:GothamProRegular;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: justify;
                color: #000000;
                white-space: pre-wrap;
            }

            #second-img{
                width:716px;
                height: 355px;

                margin-top:25px;
                margin-bottom:37px;
                border-radius:10px;
            }


            margin-bottom:53.8px;
        }

        .title-news{
            margin-top:30.5px;

            display:flex;
            flex-direction:row;

            align-items:center;
            align-self:flex-start;

            img{
                width: 60.2px;
                height: 47px;

                margin-bottom:15px;
                margin-right:20px;
            }

            p{ 
                color:  #227af1;
                letter-spacing:2;
                line-height:2;
                font-size:50px;
            }

        }

        .news{
            
            margin-bottom:30px;

            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
        }
    }

    .second-content-about{
        width: 260px;
        .search{
            margin-top:50px;

            display:flex;

            padding-bottom:5px;
            border-bottom:1.5px solid rgba(34, 122, 241, 0.56);

            input{
                color:#227af1;
                font-size:16px;
                font-weight:600;
                opacity:0.95;
                height: 50px;

                &::placeholder {
                    font-size:16px;
                    font-weight:600;
                    opacity:0.95;
                    color:#227af1;
                }
            }

            div{
                margin-top:15px;

                img{
                    margin-left:13px;   
                }
            }            
        }

        .category{
            margin-top:51px;

            width: 260px;
            height: 301px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
            background-color: #ffffff;

            >div{
                display:flex;
                flex-direction:row;
                align-items:center;

                padding:20px 20px 0 20px;

                img{
                    margin-right:9.4px;
                }

                #title{
                    color:#227af1;
                    font-size:23px;
                }
            }

            >section{
                margin-top:20px;
                width:100%;
                height: 0.1px;  
                background: #eee;
            }

            ul{
                margin-top:40px;
                margin-left:20px;

                li{
                    margin-bottom:23px;

                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                   
                    letter-spacing: normal;
                    text-align: left;
                    color: #7f7fb9;

                }
            }
        }

        .news{
            margin-top:79px;
            .title{
                img{
                    height: 40px;
                    width:  40px;
                }
                p{
                    margin-top:10px;
                    font-size:20px; 
                    color:#227af1;
                }
            }
        }
    }
    @media only screen and (max-width:768px){
        width:100vw;
        margin-left:0;
        display:flex;
        flex-direction:column !important;
        align-items:center;

        .first-content-about{
            width:100%;
            margin-right:0;

            .content-image{
                margin:0;
                display:none;
            }

            .info-content{
                width:90%;

                margin-top:5vh;
                margin-left:5%;

                .titles{
                    width:100%;

                    #text{
                        width:100%;
                        font-size:0.8rem;
                        word-wrap:break-word;
                    }
                }
            }

            .content{
                width:90% ;
                margin-left:5%;

                #text{
                    width:100%;
                    font-size:0.8rem;
                    word-wrap:break-word;
                }

                #second-img{
                    max-width:100%;
                    max-height:30vh;
                }
            }

            .title-news{
                width:90%;
                margin-left:5%;

                img{
                    width:10% !important;
                    height: 5vh;
                }

                p{ 
                    font-size:1.2rem;
                }
            }

            .news{
                width:90%;
                margin-left:5%;

                flex-direction:column;
                flex-wrap:nowrap;
                align-items:center;
            }
        }

        .second-content-about{
            width:55%;

            .news{
                display:none;
                margin-bottom:5vh;
            }
        }
    }
`

export const SocialNetworks = styled.div `
    margin-bottom:30.5px;
    width:734px;

    .content{
        display:flex;
        align-items:center;
    
        p{
            color:  #227af1;
            font-family:GothamProRegular;
            letter-spacing:2;
            font-size:22px;
        }

        #icons{
            display:flex;
            flex-direction:row;
            align-items:center;
            width:374px;

            margin-left:60px;

            .item{
                height: 25px;
                width:25px;

                margin:0 30px 0 0;

                svg{
                    transition: all .3s ease-in-out 0s;

                    color:#227af1;

                    &:hover{

                        color:${darken(0.1,'#227af1')};
                    
                    }
                }
            }

            #icon-email{
                width: 27px;
                height: 19.8px;

                margin-left:10px;
                margin-bottom:2px;
            }
        }
    }

    hr{
        margin-top:-20px;
        margin-bottom:20px;
        width:734px;
        opacity:0.56;
        height: 2px;  
        background: #227af1;
    }

    @media only screen and (max-width:768px){
        width:90vw !important;

        .content{
            width:100%;

            p{
                font-size:0.9rem;
            }

            #icons{
                width:80% !important;
                margin-left:0;

                .item{
                    height:2.7vh !important;
                    margin:0;
                    margin-left:0.8vw;

                    svg{
                        height:100%;
                    }
                    #icon-email{
                        height:80%;

                    }
                    
                }
            }
        }

        hr{
            width:100%;
        }
    }
`

export const NewAside = styled.div `
        width: 260px;
        margin-top:50px;
        margin-bottom:30px;

        #day{
            font-size:17px;
            color: #b0b3ce;
            margin-bottom:10px;
        }

        #title{
            color:#227af1;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height:1.5;
            letter-spacing: normal;
            text-align: left;
        }

        #text{
            margin-top:10px;
            font-family:GothamProRegular;
            letter-spacing:2;
            line-height:2;
            font-size:14px;

            margin-bottom:20px;
        }

        div{
            display:flex;
            align-items:center;

            img{
                margin-right:10px;
            }

            a{  
                color:#227af1;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
            }
        }

        section{
                margin-top:20px;
                width:100%;
                height: 0.1px;  
                background: #eee;
        }
`

export const New = styled.div `
    margin-bottom:30px; 
    /* margin-top:40px;  */
    margin-right:17px;

    z-index:1;

    width: 352px;
    height: 513px;
    background:#FFF;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
    border-radius:10px;

    .content-1{
        width: 100%;
        height: 170px;
        border-radius:10px 10px 0 0 ;

        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-align:center;
        -ms-flex-align:center;
        align-items:center;
        -webkit-box-pack:center;
        -ms-flex-pack:center;
        justify-content:center;

        img{
            /* margin-bottom:40px; */
            max-width:100%;
            max-height: 100%;
        }
    }

    .content-2{
        width: 100%;
        height: 230px;
        background-color: #FFF;

        padding:30px;

        #day{
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #bbbbc7;

            margin-bottom:10px;
        }

        #title{
            color:#227af1;
            font-size: 21px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height:1.5;
            letter-spacing: normal;
            text-align: left;
        }

        #text{
            margin-top:10px;
            font-family:GothamProRegular;
            letter-spacing:2;
            line-height:2;
            font-size:14px;
        }
    }

    .content-3{
        width: 100%;
        height: 59px;
       
        border-radius: 0  0  10px 10px;
        background: rgb(41,118,250);
        background: -webkit-gradient(linear, left top, right top, color-stop(54%, rgba(41,118,250,1)), to(rgba(68,169,252,1)));
        background: -o-linear-gradient(left, rgba(41,118,250,1) 54%, rgba(68,169,252,1) 100%);
        background: linear-gradient(90deg, rgba(41,118,250,1) 54%, rgba(68,169,252,1) 100%);

        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-align:center;
        -ms-flex-align:center;
        align-items:center;

        margin-top:60px;

        img{
            margin:38px  20px  40px 40px;
        }

        p{
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            
            a{
                color: #ffffff;
                -webkit-transition: all .3s ease-in-out 0s;
                -o-transition: all .3s ease-in-out 0s;
                transition: all .3s ease-in-out 0s;
                &:hover{
                    color:${darken(0.1, '#FFF')};
                }
            }
        }
    } 
    @media only screen and (max-width:768px){
        width:80%;
        .content-2{
            width:100%;
            padding:0;
            padding-left:5%;

            #text{
                width:100%;
                font-size:0.75rem !important;
                word-wrap:break-word;
            }
        }
    }
`