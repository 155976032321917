import React from "react";

import { Container } from "./styles";
import iconHeader from "../../../assets/icons/Profile/icon-header.svg";
import imgHeader from "../../../assets/images/Profile/img-header.svg";

import SubHeader from "../../../components/SubHeader";

function Header() {
  return (
    <Container>
      <SubHeader />
      <div className="content-header">
        <div className="title-content">
          <img id="icon-title" src={iconHeader} alt="" />
          <p id="title">Meu Perfil</p>
        </div>
        <img id="img-header" src={imgHeader} alt="" />
      </div>
    </Container>
  );
}

export default Header;
