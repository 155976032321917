import React from "react";

import { Message as MessageBox } from "./styles";

import { format } from "date-fns";
import pt from "date-fns/locale/pt-BR";

import iconChatPeople from "../../../assets/icons/Common/avatar-input.svg";

const Message = ({ message: { text, user }, name }) => {
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();

  if (user === trimmedName) {
    isSentByCurrentUser = true;
  }

  return (
    <MessageBox sent={isSentByCurrentUser}>
      <div className="icon-people">
        <div className="box">
          <section id="border">
            <img src={iconChatPeople} alt="" />
          </section>{" "}
        </div>{" "}
      </div>{" "}
      <div className="content-message">
        <p id="day">
          {" "}
          {format(new Date(), "dd'/'MM'/'yy", {
            locale: pt,
          })}{" "}
        </p>{" "}
        <p id="name"> {user} </p> <p id="text"> {text} </p>{" "}
      </div>{" "}
    </MessageBox>
  );
};

export default Message;
