import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 0px 0 50px 0;
  background: #fff;
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;

  ${(props) =>
    props.larger &&
    css`
      max-width: 56.6%;
    `}
    ${(props) =>
    !props.larger &&
    css`
      max-width: 43.3%;
    `}



  .img-1 {
    margin-bottom: 24px;
  }

  > h1 {
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    text-transform: uppercase;
  }

  #science-2 {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 40px;
    margin-top: 11px;
  }

  button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    border-radius: 30px;
    background-color: #227af1;

    font-family: GothamPro;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 60px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 352px;
    height: 60px;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  .images-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  @media screen and (min-width: 767px) and (max-width: 1210px) {
    ${(props) =>
    props.larger &&
    css`
      max-width: 50%;
    `}
    ${(props) =>
    !props.larger &&
    css`
     max-width: 50%;   
    `}
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;

    .img-1{
      width: 40px;
    }

    ${(props) =>
    props.larger &&
    css`
      max-width: 100%;
    `}
    ${(props) =>
    !props.larger &&
    css`
     max-width: 100%;   
    `}

    > h1 {
      font-size: 1.5rem;
    }

    #science-2 {
      text-align: justify;
    }

    button {
      padding: 20px 33px;
      width: 100%;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  align-items: center;

  margin-left: 46px;

  ${(props) =>
    props.larger &&
    css`
      margin-left: 0;
    `}


  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 30px;
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;

  ${(props) =>
    props.larger &&
    css`
      max-height: 543.4px;
      transform: translateY(-180px);
    `}

  ${(props) =>
    !props.larger &&
    css`
      max-height: 500px;
      transform: translateX(120px);
    `}

  @media screen and (max-width: 1210px) {
    ${(props) =>
    props.larger &&
    css`
      display: none;
      visibility: hidden;
    `}

  ${(props) =>
    !props.larger &&
    css`

      max-height: 500px;
      transform: translateX(0);
    `}
  }

`;
