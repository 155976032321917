import styled from 'styled-components';
import { darken } from 'polished'

export const Wrapper = styled.div `
    height: 100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family: GothamPro;
`;

export const Content = styled.div `
    width:1366px;
    height: 100%;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    


    .form{
     
      display:flex;
      flex-direction:column;
      align-items:center;
      margin-top:150px;


      
      .inputs{
        width: 352px;
        height: 60px;
        border-radius: 30px;
        border: solid 1.4px #7f7ebe;
        margin-right:16px;
        margin-bottom:20px;

        padding-left:33.6px;

        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
        
        &::-moz-placeholder{
            opacity: 1;
        }

        &::placeholder{
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
        }
      }
      .buttons{
        display:flex;

        #other-btn{
          width:200px;
          margin-left:10px;
        }
      }
      span{
        color:#227af1;
        align-self:flex-start;
        margin: 0 0 10px;
        font-weight:bold;
      }

      button{
        width: 142px;
        height: 60px;
        border-radius: 30px;
        background-color: #227af1;

        margin-top:20px;

        font-size: 15px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #FFF;

        transition: all .3s ease-in-out 0s;
        &:hover{
            background-color:${darken(0.1, '#227af1')};
        }
      }

      a {
        color:#FFF;
        margin-top:15px;
        font-size:16px;
        opacity:0.8;

        &:hover{
          opacity:1;
        }
      }
  }
`

export const Header = styled.header `
    width:100%;
    height: 231px;

    background:#227af1;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    padding-bottom:25px;

    align-items: center;
    justify-content: center;

    #logo{
      max-width: 50%;
      margin-top:30px;
    }

    #text-header{
      font-size: 50px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;

      align-self:center;

      margin-top:30px;
    }
`