import React from "react";

import { Wrapper, Row, Column, Img } from "./styles";

import { Container } from "../../../../styles/global";

import imgFirst from "../../../../assets/images/Company/second.svg";

function Header() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <Img className="second" src={imgFirst} alt="" />
          </Column>
          <Column larger>
            <h1 id="title">Por que sua empresa deve apoiar a Ciência?</h1>
            <p id="text">
              Porque apoiar a Ciência é apoiar o próprio futuro, mas porque
              apoiar a Ciência também pode representar grandes oportunidades de
              negócio, gerar inovações e diferenciais competitivos e conquistar
              um espaço importante para sua marca na mente e no coração dos
              consumidores, cada vez mais conscientes e exigentes quanto a
              responsabilidade socioambiental das marcas que consomem.
            </p>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Header;
