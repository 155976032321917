import React, { useEffect, useState } from "react";

import { Container } from "../../../../styles/global";
import { Wrapper, Row, Column, Gallery } from "./styles";

import prev from "../../../../assets/icons/Common/product-left.svg";
import next from "../../../../assets/icons/Common/product-right.svg";

function Fifth({ data }) {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setImages(data);
  }, [data]);

  function nextImg() {
    setIndex(index + 1);
  }

  function prevImg() {
    if (index >= 1) {
      setIndex(index - 1);
    }
  }

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <Gallery>
              <button onClick={prevImg} disabled={index === 0 ? true : false}>
                <img src={prev} alt="" />
              </button>

              <div className="images">
                <img
                  src={
                    images[index] &&
                    images[index].image &&
                    images[index].image.url
                  }
                  alt="Parceiro logo"
                />
                <img
                  src={
                    images[index + 1] &&
                    images[index + 1].image &&
                    images[index + 1].image.url
                  }
                  alt="Parceiro logo"
                />
                <img
                  src={
                    images[index + 2] &&
                    images[index + 2].image &&
                    images[index + 2].image.url
                  }
                  alt="Parceiro logo"
                />
                <img
                  src={
                    images[index + 3] &&
                    images[index + 3].image &&
                    images[index + 3].image.url
                  }
                  alt="Parceiro logo"
                />
              </div>

              <button
                onClick={nextImg}
                disabled={images[index + 4] === undefined ? true : false}
              >
                <img src={next} alt="" />
              </button>
            </Gallery>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Fifth;
