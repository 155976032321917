import { darken } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 352px;

  ${(props) =>
    !props.active &&
    css`
      height: 703px;
    `}

  ${(props) =>
    props.active &&
    css`
      height: 813px;
    `}

    .content-1-challenge {
    width: 100%;
    height: 276px;

    border-radius: 10px;

    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    z-index: 1;

    position: relative;

    /* Webkits */
    -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    /* Webkits */
    .title-content-1 {
      padding-top: 27px;

      p {
        margin-left: 33px;
        margin-bottom: 22.6px;

        font-size: 20px;
        font-weight: 500;
        text-align: left;

        color: #227af1;
      }

      section {
        width: 100%;
        height: 1px;

        background: #eee;
      }
    }

    .content-1-about-challenge {
      margin-top: 24.6px;
      p {
        margin-left: 33px;
        margin-bottom: 14px;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      section {
        width: 100%;
        height: 1px;

        margin-top: 23.2px;

        background: #eee;
      }
    }

    .timer {
      margin-top: 24.6px;

      #title-timer {
        margin-left: 33px;
        margin-bottom: 13px;

        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #227af1;
      }

      .counter {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        margin-left: 33px;

        /* Webkits */
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        /* Webkits */

        #number {
          font-size: 40px;
          color: #227af1;
        }
        #unity {
          margin-right: 1.5px;

          font-size: 16px;
          font-weight: 500;
          text-align: left;

          color: #227af1;
        }

        p {
          margin-right: 1.5px;
          margin-left: 1.5px;

          font-size: 16px;
          font-weight: 500;
          text-align: left;

          color: #227af1;
        }
      }
    }
  }

  .content-2-challenge {
    width: 100%;
    position: relative;
    z-index: 1;

    height: 132px;

    margin-top: -6px;
    padding-top: 32px;

    z-index: -1;
    position: relative;

    box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
    background-color: #227af1;

    /* Webkits  */
    -webkit-box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
    /* Webkits */

    #title-value-content {
      margin-left: 33px;
      margin-bottom: 18px;

      font-size: 16px;
      font-weight: 500;
      text-align: left;

      color: #ffffff;
    }

    .value-content {
      display: flex;
      align-items: baseline;

      margin-left: 33px;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      /* Webkits */

      #value {
        margin-top: 9.6px;

        font-size: 60px;
        font-weight: 500;
        line-height: 0.4;
        text-align: left;

        color: #5cf3ff;
      }

      p {
        font-size: 25px;
        font-weight: 500;
        line-height: 0.96;
        text-align: left;

        color: #5cf3ff;
      }
    }
  }
  .special-premium {
    width: 100%;
    ${(props) =>
      props.active &&
      css`
        height: 100px;
      `}

    background-color: #227af1;

    padding-left: 33px;
    padding-right: 10px;

    #title-special {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #fff;
      margin-left: 5px;
    }

    #about {
      width: 95%;
      max-height: 100%;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.16;
      text-align: justify;
      white-space: pre-wrap;
      color: #5cf3ff;
    }
  }

  .content-3-challenge {
    width: 100%;
    height: 135px;

    padding-top: 26px;

    border-radius: 0 0 10px 10px;

    background: rgb(44, 86, 227);
    background: linear-gradient(
      90deg,
      rgba(44, 86, 227, 1) 32%,
      rgba(68, 169, 252, 1) 77%,
      rgba(106, 192, 255, 1) 96%
    );

    /* Webkits */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(32%, rgba(44, 86, 227, 1)),
      color-stop(77%, rgba(68, 169, 252, 1)),
      color-stop(96%, rgba(106, 192, 255, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(44, 86, 227, 1) 32%,
      rgba(68, 169, 252, 1) 77%,
      rgba(106, 192, 255, 1) 96%
    );
    /* Webkits */

    p {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.27;
      text-align: center;

      color: #ffffff;
    }
  }

  .content-4-challenge {
    width: 166px;
    height: 166px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 90px;
    margin-top: -79px;

    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 50%;

    z-index: -1;

    /* Webkits */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* Webkits */

    div {
      width: 140px;
      height: 140px;

      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 0.5;

      box-shadow: 0 0 7.5px 0 #45c6e5;
      background-image: linear-gradient(
        to right,
        rgba(106, 243, 255, 0.67) 30%,
        rgba(138, 238, 197, 0.67) 157%
      );
      border-radius: 50%;

      /* Webkits */
      -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(30%, rgba(106, 243, 255, 0.67)),
        color-stop(157%, rgba(138, 238, 197, 0.67))
      );
      background-image: -o-linear-gradient(
        left,
        rgba(106, 243, 255, 0.67) 30%,
        rgba(138, 238, 197, 0.67) 157%
      );
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      /* Webkits */

      button {
        width: 120px;
        height: 120px;

        z-index: 1;

        box-shadow: 0 0 7.5px 0 #45c6e5;
        background-image: linear-gradient(to right, #6af3ff 30%, #8aeec5 157%);
        border-radius: 50%;

        #img-button {
          height: 58.8px;
        }

        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(30%, ${darken(0.08, "#6af3ff")}),
            color-stop(157%, $darken(0.08, "#8aeec5"))
          );
          background-image: -o-linear-gradient(
            left,
            ${darken(0.08, "#6af3ff")} 30%,
            ${darken(0.08, "#8aeec5")} 157%
          );
          background-image: linear-gradient(
            to right,
            ${darken(0.08, "#6af3ff")} 30%,
            ${darken(0.08, "#8aeec5")} 157%
          );
        }

        /* Webkits */
        -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
        background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(30%, #6af3ff),
          color-stop(157%, #8aeec5)
        );
        background-image: -o-linear-gradient(left, #6af3ff 30%, #8aeec5 157%);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        /* Webkits */
      }
    }
  }
`;
