import styled from "styled-components";

export const Container = styled.div`
  width: 628px;

  margin-right: 150px;
  margin-top: 50.6px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  #title-product {
    width: 628px;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
  }

  .img-product {
    width: 628px;
    height: 333px;

    display: flex;

    margin-top: 23px;
    margin-bottom: 20.5px;

    .other-images {
      margin-right: 22px;
      display: flex;
      flex-direction: column;
      align-self: center;

      .image-box {
        width: 108px;
        height: 74px;
        border-radius: 10px;
        border: solid 1.2px #efefef;
        background-color: transparent;

        margin-bottom: 17px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        > img {
          border-radius: 10px;
          padding: 10px;

          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .main-img {
      width: 498px;
      height: 349px;
      object-fit: contain;
      border-radius: 10px;
      border: solid 1.2px #efefef;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 10px;
        padding: 10px;

        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .about-product {
    margin-top: 52px;

    #title-about {
      font-size: 26px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    hr {
      margin-top: 10px;
      margin-bottom: 30px;

      width: 628px;
      height: 2px;
      background: #eee;
    }

    #text-about {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: justify;
      color: #000000;
      white-space: pre-wrap;

      margin-bottom: 13px;
    }
  }

  .galery {
    width: 628px;

    margin-top: 33px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      #icon-prev {
        margin-right: 15px;
      }
    }

    .images {
      margin-top: 17px;
      #first-img {
        margin-left: -10px;

        width: 650px;

        z-index: 0.5;
      }

      #second-img {
        z-index: 1;

        position: absolute;

        margin-top: 129px;
        margin-left: 239px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;

    #title-product {
      max-width: 90%;
      font-size: 1.4rem;
      margin-left: 5%;
    }

    .img-product {
      margin-left: 5%;
      width: 90%;
      height: 40vh;

      margin-top: 5vh;

      .other-images {
        margin-right: 2vw;
        width: 30%;

        .image-box {
          width: 80% !important;
          height: 30% !important;
        }
      }

      .main-img {
        width: 70%;
        height: 100%;
      }
    }

    .about-product {
      width: 95%;
      padding-left: 5%;

      hr {
        width: 95%;
      }

      #title-about {
        font-size: 1.3rem;
      }
      #text-about {
        font-size: 1rem;
        width: 100%;
        text-align: justify;
        word-wrap: break-word;
      }
    }
  }
`;

export const Denounce = styled.div`
  width: 628px;

  margin-top: 50px;
  margin-bottom: 50px;

  #title-denounce {
    font-size: 26px;
    font-weight: 500;
    text-align: left;

    color: #227af1;
  }

  #section-denounce {
    width: 100%;
    height: 2px;

    margin-top: 10px;
    margin-bottom: 30px;

    background: #eee;
  }

  .content-about-denounce {
    display: flex;
    align-items: center;

    /* Webkits */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* Webkits */

    #text-about-denounce {
      width: 423px;

      font-family: GothamProRegular;
      font-size: 14px;
      line-height: 2;
      text-align: left;

      color: #000000;
    }

    button {
      width: 168px;
      height: 60px;

      margin-left: 37px;

      border-radius: 30px;
      border: solid 2px #2976fa;

      background: transparent;

      font-size: 16px;
      font-weight: bold;

      line-height: 1.5;

      color: #227af1;

      transition: all 0.3s ease-in-out 0s;

      /* Webkits */
      -webkit-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      /* Webkits */

      &:hover {
        background: #227af1;
        color: #fff;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw !important;
    margin-left: 5vw;

    .content-about-denounce {
      width: 100%;

      #text-about-denounce {
        width: 55%;
        text-align: justify;

        font-size: 1rem;
      }

      button {
        width: 40% !important;
        margin-left: 5%;
      }
    }
  }
`;
