import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 90px 0 0 0;
  background: #fff;
  position: relative;
  z-index: 0;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Column = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;
    text-transform: uppercase;

    color: #227af1;

    margin-top: 15.6px;
  }

  #text {
    width: 100%;

    margin-top: 17px;
    margin-bottom: 20px;

    font-family: GothamProRegular;
    font-size: 14px;
    line-height: 2;
    text-align: left;

    color: #000000;
  }

  ul {
    li {
      width: 100%;

      display: flex;
      flex-direction: row;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      /* Webkits */

      p {
        width: 80%;

        margin-top: 10px;
        margin-left: 21px;

        font-family: GothamProRegular;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.88;
        text-align: left;

        color: #227af1;
      }
    }
  }

  ${(props) =>
    props.larger &&
    css`
      max-width: 56.6%;
      flex-basis: 56.6%;
      justify-content: flex-end;

      padding-left: 15px;
    `}

  ${(props) =>
    !props.larger &&
    css`
      max-width: 43.3%;
      flex-basis: 43.3%;
      margin-bottom: 50px;
      align-items: flex-start;
      padding-left: 15px;
    `} 

  @media screen and (max-width: 1054px) {
    h2 {
      font-size: 1.5rem;
    }

    ${(props) =>
      props.larger &&
      css`
        justify-content: center;
      `}
  }

  @media screen and (max-width: 805px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    ul {
      li {
        img{
          max-width: 40px;
        }

        p {
          margin-left: 10.5px;
          font-size:0.9rem;
        }
      }
    }
  }

  @media screen and (max-width: 340px) {
    h2 {
      font-size: 0.9rem;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 100%;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;
