import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 395px;
  background-color: #fafaff;
`;

export const FirstContent = styled.div`
  width: 100%;

  border-bottom: 1px solid #eee;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px 5px 11px 22px;

  #title-challenge {
    max-width: 100%;
    width: 100%;

    word-wrap: break-word;

    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 5px;
  }

  .normal-text {
    max-width: 100%;

    word-wrap: break-word;
    word-break: break-all;

    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
  }

  @media screen and (max-width: 360px) {
    padding: 10px 5px 11px 11px;

    #title {
      font-size: 14px;

      line-height: 1.2;
    }

    .normal-text {
      font-size: 13px;
      line-height: 1.2;
    }
  }
`;
export const SecondContent = styled.div`
  width: 100%;

  border-bottom: 1px solid #eee;

  padding: 23px 2px 16px 22px;

  p {
    max-width: 100%;

    word-wrap: break-word;

    font-family: GothamProRegular;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
    color: #000000;
  }

  @media screen and (max-width: 360px) {
    padding: 23px 2px 16px 11px;

    p {
      font-size: 13px;
      line-height: 1.2;
    }
  }
`;
export const ThirdContent = styled.div`
  width: 100%;
  padding: 20px 2px 10px 22px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .normal-text {
    margin-right: 10px;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    text-align: left;
    color: #227af1;
  }

  .value {
    width: 100%;

    display: flex;
    align-items: baseline;

    #price-value {
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.86;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }
  }

  @media screen and (max-width: 360px) {
    padding: 20px 2px 10px 11px;

    .normal-text {
      font-size: 13px;
      line-height: 1.2;
    }

    .value {
      #price-value {
        font-size: 25px;
      }
    }
  }
`;
