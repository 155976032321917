import React from "react";
import { Fade } from "react-reveal";

import { Wrapper, Content } from "./styles";

import logo from "../../assets/images/Common/logo.svg";
import error from "../../assets/images/Error/error.svg";
import shape from "../../assets/images/Error/shape.svg";
import lines from "../../assets/images/Error/lines.svg";

import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

function NotFound() {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Página não encontrada</title>
      </Helmet>
      <Fade top duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <div id="logo">
              <img
                src={logo}
                alt=""
                onClick={() => {
                  setTimeout(() => {
                    history.push("/");
                  }, 150);
                }}
              />
            </div>

            <div className="box">
              <div className="message">
                <img id="lines" src={lines} alt="" />
                <p id="ops-text">Oooops !</p>
              </div>
              <p id="justify">
                Não conseguimos encontrar a página que você está procurando.
              </p>
              <button
                id="btn-home"
                onClick={() => {
                  setTimeout(() => {
                    history.push("/");
                  }, 150);
                }}
              >
                Voltar para Home
              </button>
            </div>
            <img id="img-error" src={error} alt="" />

            <img id="shape" src={shape} alt="" />
          </Content>
        </Wrapper>
      </Fade>
    </>
  );
}

export default NotFound;
