import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
// Libraries
import { Bounce } from "react-reveal";

// Date-fns
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Aside from "./components/Aside";
import Main from "./components/Main";
import OtherChallenges from "./components/OtherChallenges";

// API
import api from "../../services/api";

// Styled Components
import { Wrapper, Content, Header, ChallengeContent } from "./styles";

// Icons
import iconHeader from "../../assets/icons/Challenges/first.svg";

function Challenge({ match }) {
  /* STATES */
  const [challenge, setChallenge] = useState();
  const [idCompanyChallenge, setIdCompanyChallenge] = useState(0);
  const [otherChallenges, setOtherChallenges] = useState();
  const [doubts, setDoubts] = useState([]);

  const id = useMemo(
    () => ({
      value: match.params.id,
    }),
    [match.params.id]
  );

  useEffect(() => {
    async function loadingChallenge() {
      try {
        const { data } = await api.get(`challenge/${id.value}`);
        setChallenge({
          ...data,
        });
        setIdCompanyChallenge(data.company.id);
      } catch (e) {
        console.log("");
      }
    }
    loadingChallenge();
  }, [id]);

  useEffect(() => {
    async function loadingOtherChallenges() {
      try {
        const { data } = await api.get(
          `other-challenges/${id.value}/${idCompanyChallenge}`
        );
        setOtherChallenges(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingOtherChallenges();
  }, [id, idCompanyChallenge]);

  useEffect(() => {
    async function loadingDoubts() {
      const res = await api.get(`/doubts/${id.value}`);

      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'", {
          locale: pt,
        }),
      }));

      setDoubts(data);
    }
    loadingDoubts();
  }, [id, idCompanyChallenge]);

  return (
    <>
      <Helmet>
        <title>{challenge ? challenge.title : "We4Science"}</title>
      </Helmet>
      <Bounce left duration={1600} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section className="second-content-header">
                <div className="title">
                  <img src={iconHeader} alt="" />
                  <p id="text-1">DESAFIOS</p>
                </div>
              </section>
            </Header>

            <ChallengeContent>
              <Main challenge={challenge} doubts={doubts} />

              <Aside challenge={challenge} />
            </ChallengeContent>

            {challenge && otherChallenges && otherChallenges.length > 0 && (
              <OtherChallenges data={otherChallenges} />
            )}
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Challenge;
