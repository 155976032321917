import React from "react";

import { Container } from "./styles";

function First({ image }) {
  return (
    <Container>
      <img src={image} alt="Imagem Principal do Desafio" />
    </Container>
  );
}

export default First;
