import React from "react";
import { CircleProgress } from "react-gradient-progress";
import { FormattedNumber, IntlProvider } from "react-intl";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { store } from "../../../../store";

import { Container } from "./styles";

import author from "../../../../assets/icons/Common/login.svg";
import atomProject from "../../../../assets/icons/Common/atom-project.svg";

function InfoDonation({ data }) {
  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const history = useHistory();

  function checkIsAuth(type) {
    if (!!token) {
      if (
        perfil === "Cientista/Pesquisador" &&
        profile.id === data.scientist_id &&
        type === "colaboração"
      ) {
        toast.error("Você é o dono do projeto!");
      } else {
        type === "colaboração"
          ? setTimeout(function () {
              history.push(`/collaboration/${data && data.id}`);
            }, 50)
          : data.current_value >= data.value_goal
          ? toast.error("O projeto já atingiu a meta!")
          : setTimeout(function () {
              history.push(`/payment-project/${data && data.id}`);
            }, 50);
      }
    } else {
      toast.error(`É necessário estar logado para fazer uma ${type}!`);
      setTimeout(function () {
        history.push("/register");
      }, 2300);
    }
  }

  return (
    <Container>
      <div className="title-info">
        <p id="text-1-content-2"> {data && data.name} </p>
        <div className="subcontent-2">
          <img src={author} alt="" />
          <p id="text-2-content-2">Autor: {data && data.scientist.name}</p>
        </div>
      </div>
      <div id="percentage">
        <CircleProgress
          percentage={parseInt(
            (data && data.current_value * 100) / (data && data.value_goal)
          )}
          strokeWidth={11}
          primaryColor={["#fff", "#fff"]}
          width={150}
          fontColor={"#fff"}
          fontSize={"32px"}
          fontFamily={"GothamProRegular"}
        />
      </div>

      <div className="values-info">
        <p> Doado: </p>
        <IntlProvider locale="pt-BR">
          <p id="cash-values-info">
            R${" "}
            <FormattedNumber
              minimumFractionDigits={2}
              value={data && data.current_value}
            />
          </p>
        </IntlProvider>

        <IntlProvider locale="pt-BR">
          <p id="mark-values-info">
            Meta: R${" "}
            <FormattedNumber
              minimumFractionDigits={2}
              value={data && data.value_goal}
            />
          </p>
        </IntlProvider>
      </div>
      <div className="content-3">
        <img src={atomProject} alt="" />
        <button
          id="btn-donation"
          onClick={() => {
            checkIsAuth("doação");
          }}
        >
          Fazer uma doação
        </button>
      </div>
    </Container>
  );
}

export default InfoDonation;
