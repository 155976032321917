import React, { useState } from "react";

import { Fade } from "react-reveal";

import { Wrapper, Content, Header, MainContent } from "./styles";

import { useHistory } from "react-router-dom";

//Icons

// Images -> Common Foulder
import imgMain from "../../assets/images/delete.svg";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";

import { store } from "../../store";
import api from "../../services/api";
import { Helmet } from "react-helmet";

export default function DeleteProfile() {
  const history = useHistory();
  const [keepDel, setKeepDel] = useState(false);
  const toogle = () => setKeepDel(!keepDel);

  const { perfil, signed } = store.getState().auth;

  const { profile } = store.getState().user;
  async function handleDelete() {
    if (signed) {
      const userID = profile && profile.id;

      const data = Object.assign({
        user: userID,
        profile: perfil,
        name: profile.name,
        phone: profile.phone,
        email: profile.email,
        adress: profile.adress,
        neighborhood: profile.neighborhood,
        state: profile.state,
        city: profile.city,
        cep: profile.cep,
      });

      try {
        await api.post("deleted-user", data);
        toast.success("Sua requisição foi mandada para nossa equipe!");
      } catch (error) {
        toast.error("Erro ao mandar requisição");
        console.log(error);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Deletar Perfil</title>
      </Helmet>
      <Fade top duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <div className="fs-content">
                <p id="text-congrat">Deletar Perfil </p>
                <img src={imgMain} alt="" />
              </div>
              <div className="circles">
                <div id="first-circle"> </div>
                <div id="second-circle"> </div>
                <div id="third-circle"> </div>
              </div>
            </Header>
            <MainContent>
              <div className="content">
                <p>Você tem certeza que deseja nos deixar?</p>
                <div className="buttons">
                  <button onClick={toogle}>
                    Sim, está tudo acabado entre nós.
                  </button>
                  <button
                    onClick={() => {
                      history.push("profile");
                    }}
                  >
                    Não, eu ainda te amo!
                  </button>
                  <button
                    onClick={() => {
                      history.push("contact?&type=normal");
                    }}
                  >
                    Só estou chateado(a) e quero conversar.
                  </button>
                </div>

                {keepDel && (
                  <Fade top duration={2000} distance="20px">
                    <div className="content-delete" style={{ marginTop: 40 }}>
                      <p>
                        Tudo bem, mas nosso coração estará sempre de portas
                        abertas quanto você quiser voltar!
                        <br />
                        <br />
                        Muito obrigado por seu apoio a Ciência até aqui!{" "}
                      </p>

                      <button
                        type="button"
                        onClick={handleDelete}
                        style={{ borderRadius: 30, height: 60 }}
                      >
                        Excluir Perfil
                      </button>
                    </div>
                  </Fade>
                )}
              </div>
            </MainContent>

            <Footer />
          </Content>
        </Wrapper>
      </Fade>
    </>
  );
}
