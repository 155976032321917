import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import imgFirst from "../../../../assets/images/About/first.svg";
import iconCheckBlue from "../../../../assets/icons/About/check-blue.svg";

function First() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column larger>
            <ImgWrapper>
              <Img src={imgFirst} alt="" />
            </ImgWrapper>
          </Column>
          <Column>
            <h2>
              Apoiar a Ciência não é responsabilidade do Estado / Governo?
            </h2>
            <p id="text">
              Com certeza! Mas também é nosso papel exigir dos Governos para que
              invistam cada vez mais na Ciência e Educação. Também devemos nos
              preocupar em eleger candidatos que defendam essas bandeiras,
              independente da sua inclinação ideológica. No entanto, também
              acreditamos que só isso não basta e sabemos que a Ciência é cada
              vez menos valorizada pela classe política de modo geral, então
              chegou a hora (e a tecnologia) para cada um de nós colaborarmos
              diretamente com o progresso da Ciência!
            </p>

            <ul>
              <li>
                <img src={iconCheckBlue} alt="" />
                <p>
                  Devemos cobrar, fiscalizar e votar em candidatos que defendam
                  a Ciência. Mas não podemos mais esperar por eles!
                </p>
              </li>
              <li>
                <img src={iconCheckBlue} alt="" />
                <p>
                  A responsabilidade pelo progresso da civilização humana, pelo
                  enfrentamento das ameaças ambientais e por um mundo melhor
                  também é nossa!
                </p>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default First;
