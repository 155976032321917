import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 41px;
  margin-bottom: 41px;

  #title {
    margin-left: 135px;
    margin-bottom: 18px;

    align-self: flex-start;

    font-size: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
  }

  #section-challenges {
    margin-left: 7px;
    margin-bottom: 36px;

    width: 80%;
    height: 2px;
    background: #eee;
  }

  .products {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    #title {
      margin-left: 0px;
      font-size: 1.4rem;

      margin-left: 10vw;
    }

    #section-challenges {
      margin-left: 0px;
    }

    .products {
      width: 80%;
      flex-direction: column;
      margin-left: 0;
    }
  }
`;
