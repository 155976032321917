import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  font-family: GothamPro;
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 211px;

  background: #227af1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .second-content-header {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;

      #text-1 {
        margin-top: 23px;
        margin-left: 23px;

        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top:10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .second-content-header {
      .title {
        margin-top: 2.5vh;
        img {
          width: 46.4px;
          height: 42.8px;
        }

        #text-1 {
          font-size: 1.6rem;
        }
      }
    }
  }
`;
export const ProductContent = styled.section`
  display: flex;
  flex-direction: row;
  margin-left: 139px;

  .first-content-about {
    width: 628px;

    margin-right: 150px;
    margin-top: 50.6px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    #title-product {
      width: 628px;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    .img-product {
      width: 628px;
      height: 333px;

      display: flex;

      margin-top: 23px;
      margin-bottom: 20.5px;

      .other-images {
        margin-right: 22px;
        display: flex;
        flex-direction: column;
        align-self: center;

        .image-box {
          width: 108px;
          height: 74px;
          border-radius: 10px;
          border: solid 1.2px #efefef;
          background-color: transparent;

          margin-bottom: 17px;

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;

          > img {
            border-radius: 10px;
            padding: 10px;

            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .main-img {
        width: 498px;
        height: 349px;
        object-fit: contain;
        border-radius: 10px;
        border: solid 1.2px #efefef;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: 10px;
          padding: 10px;

          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .about-product {
      margin-top: 52px;

      #title-about {
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      hr {
        margin-top: 10px;
        margin-bottom: 30px;

        width: 628px;
        height: 2px;
        background: #eee;
      }

      #text-about {
        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: justify;
        color: #000000;
        white-space: pre-wrap;

        margin-bottom: 13px;
      }
    }

    .galery {
      width: 628px;

      margin-top: 33px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 26px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }

        #icon-prev {
          margin-right: 15px;
        }
      }

      .images {
        margin-top: 17px;
        #first-img {
          margin-left: -10px;

          width: 650px;

          z-index: 0.5;
        }

        #second-img {
          z-index: 1;

          position: absolute;

          margin-top: 129px;
          margin-left: 239px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    margin-left: 0px;

    flex-direction: column;
    align-items: center;

  }
`;