import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { store } from "../../../../store";

import { Container, Denounce } from "./styles";

function Main({ product }) {
  const [value, setValue] = useState(0);
  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;
  var images = [];

  product && product.first != null && images.push(product && product.first.url);
  product &&
    product.second != null &&
    images.push(product && product.second.url);
  product && product.third != null && images.push(product && product.third.url);

  async function handleComplaint() {
    if (!!token) {
      var data = Object.assign({
        user: profile.id,
        perfil: perfil,
        product_id: parseInt(product.id),
      });
      try {
        await api.post("complaint-products", data);
        toast.success("Produto denunciado, nossa equipe irá averiguar!");
      } catch (error) {
        console.log(error);
        toast.error("Erro ao denunciar Produto!");
      }
    } else {
      toast.error("É necessário estar logado!");
    }
  }

  return (
    <Container>
      <p id="title-product">{product && product.title}</p>
      <div className="img-product">
        <div className="other-images">
          <div
            className="image-box"
            onClick={() => {
              setValue(0);
            }}
          >
            {product && product.first && (
              <img id="img-1" src={product && images[0]} alt="" />
            )}
          </div>
          <div
            className="image-box"
            onClick={() => {
              setValue(1);
            }}
          >
            {product && product.second && (
              <img src={product && images[1]} alt="" />
            )}
          </div>
          <div
            className="image-box"
            onClick={() => {
              setValue(2);
            }}
          >
            {product && product.third && (
              <img src={product && images[2]} alt="" />
            )}
          </div>
        </div>
        <div className="main-img">
          <img src={product && images[value]} alt="" />
        </div>
      </div>
      <div className="about-product">
        <p id="title-about">Empresa Ofertante</p>
        <section id="section-about"></section>
        <p id="text-about">
          {product && product.company && product.company.about}
        </p>
      </div>
      <div className="about-product">
        <p id="title-about">Descrição do Produto</p>
        <hr />
        <p id="text-about">{product && product.description}</p>
      </div>
      <div className="about-product">
        <p id="title-about">Ficha Técnica</p>
        <hr />
        <p id="text-about">{product && product.datasheet}</p>
      </div>
      <div className="about-product">
        <p id="title-about">Forma de Entrega/Prestação</p>
        <hr />
        <p id="text-about">{product && product.delivery_provision}</p>
      </div>
      <Denounce>
        <p id="title-denounce">Denunciar Produto</p>
        <section id="section-denounce"></section>
        <div className="content-about-denounce">
          <p id="text-about-denounce">
            Encontrou aqui alguma informação que viola nossos Termos de Uso?
            Vamos analisar sua denúncia com muita atenção e responsabilidade, e
            assim que possível dar um retorno para você e para o responsável
            pelo conteúdo.
          </p>

          <button onClick={handleComplaint}>Denunciar</button>
        </div>
      </Denounce>
    </Container>
  );
}

export default Main;
