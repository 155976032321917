import React from "react";

import { Wrapper, Row, Column, Img } from "./styles";

import { Container } from "../../../../styles/global";
import { useHistory } from "react-router-dom";

import iconInstitution from "../../../../assets/icons/Scientists/institution.svg";
import thirdImg from "../../../../assets/images/Scientists/third.svg";

function Second() {
  const history = useHistory();

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <img id="icon" src={iconInstitution} alt="" />
            <p id="title">
              UNIVERSIDADES, CENTROS DE PESQUISA, INOVAÇÃO E CIÊNCIA
            </p>
            <p className="text" id="green">
              Essa plataforma também é para vocês!
            </p>
            <p id="text">
              Vivemos em uma sociedade cada vez mais dependente de Ciência e
              Tecnologia, mas na qual cientistas, professores e instituições de
              pesquisa científica ainda enfrentam enormes desafios para
              desenvolverem seus trabalhos, principalmente no que se refere ao
              acesso a recursos financeiros. <br />
              <br />
              Em outro aspecto, parte considerável da sociedade mergulha no
              obscurantismo, na pseudociência e na ignorância científica,
              produzindo terríveis impactos sociais, econômicos e ambientais.{" "}
              <br />
              <br />
              Por meio da Iniciativa We4Science – Todos pela Ciência!, temos
              finalmente a oportunidade e a tecnologia para criar conexões
              fortes e dinâmicas entre cientistas, professores, pesquisadores,
              universidades, centros de pesquisa, empresas privadas e uma enorme
              parcela da sociedade que deseja apoiar o desenvolvimento
              científico e o progresso da humanidade. <br />
              <br />
              Saiba como trazer sua Instituição de Ensino, Pesquisa ou Ciência
              para a We4Science! <br />
              <br />
              Clique aqui!
            </p>
            <button onClick={() => history.push("/contact?&type=normal")}>
              TORNE-SE UM PARCEIRO
            </button>
            <Img id="img-third" src={thirdImg} alt="" />
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Second;
