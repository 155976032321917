import React from "react";
import { Helmet } from "react-helmet";

import { Rotate } from "react-reveal";

import { Wrapper, Content, Box } from "./styles";

import crying from "../../assets/images/crying.svg";

import { useHistory } from "react-router-dom";

function Success() {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Cancelar Pagamento</title>
      </Helmet>
      <Wrapper>
        <Content>
          <Rotate top duration={1300} distance="20px">
            <Box>
              <img src={crying} alt="" />
              <div className="content">
                <p id="title">Cancelado!</p>
                <p id="content">Algo deu errado,tente novamente!</p>
                <button onClick={() => history.push("/")}>Continue</button>
              </div>
            </Box>
          </Rotate>
        </Content>
      </Wrapper>
    </>
  );
}

export default Success;
