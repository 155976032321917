import React, { useState } from "react";

import {
  Wrapper,
  Row,
  Column,
  ImgWrapper,
  Img,
  PaypalContent,
  FormContent,
} from "./styles";

import { Container } from "../../../../styles/global";
import iconPaypalLogo from "../../../../assets/icons/Payment/paypal.svg";
import iconPaypal from "../../../../assets/icons/Payment/icon-paypal.svg";

import * as Yup from "yup";
import { store } from "../../../../store";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { Form, Input, Textarea } from "@rocketseat/unform";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

function First({ id }) {
  const schema = Yup.object().shape({
    value: Yup.number()
      .transform((_, value) => {
        if (value.includes(".")) {
          return null;
        }
        return +value.replace(/,/, ".");
      })
      .required("Valor é Obrigatório")
      .min(5, "O valor deve ser maior que 5 reais!")
      .typeError("Digite um número"),
    email: Yup.string()
      .email("Insira um e-mail válido")
      .required("O e-mail é obrigatório"),
    name: Yup.string().required("O nome é obrigatório"),
    surname: Yup.string().required("O sobrenome é obrigatório"),
    phone: Yup.string().required("O Telefone é obrigatório"),
    birth: Yup.string().required("Data de nascimento é obrigatória"),
    cpf: Yup.string().required("CPF é obrigatório"),
  });

  // const history = useHistory();

  const { perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const [accept, setAccept] = useState(true);

  const toggle = () => setAccept(!accept);

  async function handleSubmit(data) {
    data.visible = accept;
    data.user_id = profile.id;
    data.perfil = perfil;
    data.project_id = parseInt(id);

    try {
      toast.success("Obrigado por ajudar a ciência!");

      const link = await api.post("/buy", data);

      window.open(`${link.data.link}`, "_self");
    } catch (error) {
      toast.error("erro!");
    }
  }
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <PaypalContent>
              <ImgWrapper>
                <Img src={iconPaypalLogo} alt="" />
              </ImgWrapper>
              <p>
                Parabéns!
                <br />
                <br />
                Você está a poucos cliques de contribuir para o progresso da
                Ciência e da humanidade!
                <br />
                <br />
                Em nosso mundo cada vez mais dependente da ciência e do
                conhecimento, seu apoio é fundamental. Por isso siga adiante em
                sua contribuição e convide seus amigos e familiares a fazerem o
                mesmo, pois não há limites para o que podemos construir juntos!
              </p>
            </PaypalContent>
          </Column>
          <Column>
            <FormContent>
              <Form
                initialData={profile}
                onSubmit={handleSubmit}
                schema={schema}
              >
                <p id="text-form">Valor da contribuição</p>
                <div className="inputs">
                  <div className="content-input">
                    <Input
                      name="value"
                      className="value-input"
                      type="text"
                      placeholder="R$ 00,00"
                    />
                    <p id="obs">
                      Obs: Separe os centavos por vírgula e não utiliza (.) no
                      valor .
                    </p>
                  </div>
                  <div className="content-input" id="margin">
                    <Input
                      name="name"
                      className="normal-input"
                      type="text"
                      placeholder="Nome:"
                    />
                  </div>
                  <div className="content-input">
                    <Input
                      name="surname"
                      className="normal-input"
                      type="text"
                      placeholder="Sobrenome:"
                    />
                  </div>
                  <div className="content-input" id="margin">
                    <Input
                      name="birth"
                      className="normal-input"
                      type="text"
                      placeholder="Data de nascimento: dd/mm/aaaa"
                    />
                  </div>
                  <div className="content-input">
                    <Input
                      name="phone"
                      className="normal-input"
                      type="text"
                      placeholder="Telefone celular:"
                    />
                  </div>
                  <div className="content-input" id="margin">
                    <Input
                      name="cpf"
                      className="normal-input"
                      type="text"
                      placeholder="CPF:"
                    />
                  </div>
                  <div className="content-input">
                    <Input
                      name="email"
                      className="normal-input"
                      type="text"
                      placeholder="Email:"
                    />
                  </div>
                </div>
                <label className="label">
                  <input
                    className="label__checkbox"
                    type="checkbox"
                    onClick={toggle}
                  />

                  <span className="label__text">
                    <span className="label__check">
                      {accept && (
                        <FaCheck
                          className="fa fa-check icon"
                          color={"#7f7fb9"}
                        />
                      )}
                    </span>
                    <p id="text-checkbox">
                      Aceito que minha doação seja pública
                    </p>
                  </span>
                </label>
                <div className="content-input">
                  <Textarea
                    name="message"
                    className="textarea-form"
                    placeholder="Deixe uma mensagem (opcional):"
                  />
                </div>

                <p id="text-content">
                  Ao clicar no botão abaixo, você concorda que leu e aceita as
                  <Link to="/terms-money" style={{ marginRight: 5 }}>
                    Condições e Termos de Uso
                  </Link>
                  da plataforma We4Science.
                </p>

                <div className="button">
                  <div className="paypal-content">
                    <img src={iconPaypal} alt="" id="icon-paypal" />
                  </div>
                  <button type="submitR" id="button-paypal">
                    Fazer doação
                  </button>
                </div>
              </Form>
            </FormContent>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default First;
