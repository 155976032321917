import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.aside`
  width: 352px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const WhuffieCompany = styled.div`
  margin-top: 20px;

  width: 352px;
  height: 202px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border: solid 2px rgba(255, 255, 255, 0.41);

  margin-right: 17px;

  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    font-size: 19.5px;
    color: #227af1;
    margin-top: 20px;
  }

  section {
    margin-top: 20px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
  .values {
    margin-left: 36px;
    margin-top: 28.6px;
    #values-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    #values-cash {
      margin-top: 10px;

      font-size: 26px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    > p {
      font-size: 1rem;
    }

    .values {
      margin-left: 0;

      #values-text {
        font-size: 0.7rem;
      }
      #values-cash {
        font-size: 1.1rem;
      }
    }
  }
`;

export const Donation = styled.div`
  width: 352px;
  height: 469px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border-radius: 10px;
  border: solid 2px rgba(255, 255, 255, 0.41);

  margin-right: 17px;

  z-index: 1;
  position: relative;

  #title-donation {
    display: flex;
    align-items: center;

    margin-left: 33px;
    margin-top: 10px;

    > img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    > p {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.86;
      letter-spacing: normal;
      color: #227af1;
    }
  }

  section {
    margin-top: 20px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }

  .list {
    margin-top: 17.7px;
    #list-people-donation {
      margin-left: 33px;
      margin-bottom: 25px;

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;

      #position-people {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        margin-right: 20px;

        width: 50.5px;
        height: 50.5px;
        border-radius: 5px;
        border: solid 1px #227af1;

        p {
          -webkit-text-stroke: 0.5px #227af1;
          font-size: 28px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.86;
          letter-spacing: normal;
          color: #227af1;

          margin-left: 6px;
          margin-top: 10px;
        }
      }

      #data-people {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        > p {
          margin-top: 2px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #7f7fb9;
          word-break: normal;
        }

        div {
          display: flex;
          display: -webkit-box;
          display: -ms-flexbox;
          #cash {
            margin-top: 5px;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }

          #dot {
            margin-top: 8px;
            margin-left: 11px;

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;
  }
`;

export const Collaboration = styled.div`
  width: 352px;
  height: 174px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  background-color: #227af1;

  margin-bottom: 34px;

  .content-1 {
    height: 115px;
    padding-top: 28px;
    padding-left: 37px;

    p {
      width: 268px;

      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.71;
      text-align: left;
      color: #ffffff;
    }
  }

  .content-3 {
    width: 100%;
    height: 59px;
    -webkit-box-shadow: 0 0 2px 0 rgba(35, 11, 114, 0.28);
    box-shadow: 0 0 2px 0 rgba(35, 11, 114, 0.28);
    border-radius: 0 0 10px 10px;
    background: rgb(44, 86, 227);
    background: linear-gradient(
      90deg,
      rgba(44, 86, 227, 1) 32%,
      rgba(68, 169, 252, 1) 77%,
      rgba(106, 192, 255, 1) 96%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 33px;
      width: 32.4px;
      height: 32.4px;
    }

    #btn-collaboration {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;

      background: transparent;

      -webkit-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, "#FFF")};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    .content-1 {
      width: 95%;

      p {
        width: 100%;
      }
    }
  }
`;
