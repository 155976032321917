import styled from "styled-components";

export const Container = styled.section`
  #title-donors {
    font-size: 45px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-top: 21.5px;
    margin-bottom: 19px;
  }

  .info-doners {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 628px;
    height: 135px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    padding: 0px 40px 0 30px;

    z-index: 1;
    position: relative;

    margin-bottom: 60px;

    .content-info-doners {
      display: flex;
      align-items: center;

      img {
        margin-right: 8.6px;
      }

      #value {
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2976fa;

        margin-bottom: 6px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2976fa;
        margin-bottom: 5px;
      }
    }
  }

  .donors {
    width: 100%;
    height: 420px;

    .donor {
      .donor-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          width: 70px;
          height: 70px;
          border: solid 2px rgba(127, 126, 190, 0.31);
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 10px;

          #background-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            background-color: rgba(127, 127, 185, 0.31);

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .info-donor {
          #day {
            margin-top: 10px;
            margin-bottom: 10px;

            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            opacity: 0.31;
            color: #7f7fb9;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
          }
        }
      }
      section {
        margin-top: 20px;
        margin-bottom: 20px;

        display: flex;
        align-self: center;

        width: 100%;

        border: 1px dotted #eee;
      }
    }
  }

  .floatPage {
    float: right;

    margin-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    #title-donors {
      font-size: 1.4rem;
    }

    .info-doners {
      width: 100%;

      padding: 0;

      padding: 2.5%;

      .content-info-doners {
        > img {
          display: none;
        }

        #value {
          font-size: 1rem;
        }

        p {
          font-size: 0.8rem;
        }
      }
    }
  }
`;
