import produce from 'immer'

const INITIAL_STATE = {
    cookie:false
}

export default function auth(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@cookies/CHANGE_TO_TRUE':
                {
                    draft.cookie = action.payload.accept;
                    break;
                }
            default:
                return state
        }
    })
}