import React, { useEffect, useMemo, useState } from "react";

// Libraries
import { useHistory } from "react-router-dom";
import { Bounce } from "react-reveal";
import { store } from "../../store";
import { toast } from "react-toastify";
import { Form, Input, Textarea } from "@rocketseat/unform";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import ItemChallenge from "../../components/Challenge";

// API
import api from "../../services/api";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  ChallengeContent,
  OtherChallenges,
} from "./styles";

// External icons
import {
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

// Icons
import iconHeader from "../../assets/icons/Challenges/first.svg";
import { Helmet } from "react-helmet";

function Challenge({ match }) {
  /* STATES */
  const [challenge, setChallenge] = useState();
  const [idCompanyChallenge, setIdCompanyChallenge] = useState(0);

  const id = useMemo(
    () => ({
      value: match.params.id,
    }),
    [match.params.id]
  );
  useEffect(() => {
    async function loadingChallenge() {
      try {
        const { data } = await api.get(`challenge/${id.value}`);
        setChallenge({
          ...data,
        });
        setIdCompanyChallenge(data.company.id);
      } catch (e) {
        console.log("");
      }
    }
    loadingChallenge();
  }, [id]);

  const { perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const history = useHistory();

  const [otherChallenges, setOtherChallenges] = useState();

  useEffect(() => {
    async function loadingChallenge() {
      try {
        const { data } = await api.get(
          `other-challenges/${id.value}/${idCompanyChallenge}`
        );
        setOtherChallenges(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingChallenge();
  }, [id, idCompanyChallenge]);

  async function handleSendChallenge(data) {
    if (
      perfil === "Apoiador Empresarial/Desafiante" &&
      profile.id === challenge.company_id
    ) {
      toast.error("Você não pode participar do desafio!");
    } else {
      try {
        data.user = profile.id;
        data.perfil = perfil;
        data.challenge_id = parseInt(id.value);
        await api.post("send-challenge", data);
        toast.success("Você está participando desse Desafio! Boa sorte!");
      } catch (error) {
        toast.error("Erro ao realizar entrega");
        console.log(error);
      }
    }
  }

  function completeLink(link) {
    var finalLink;
    if (link !== null) {
      if (
        link.substring(0, 7) === "http://" ||
        link.substring(0, 8) === "https://"
      ) {
        finalLink = link;
      } else {
        finalLink = "https://www." + link;
      }
      return finalLink;
    } else {
      return link;
    }
  }
  return (
    <>
      <Helmet>
        <title>{challenge ? challenge.title : "Desfios"}</title>
      </Helmet>
      <Bounce left duration={1600} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section className="second-content-header">
                <div className="title">
                  <img src={iconHeader} alt="" />
                  <p id="text-1">DESAFIOS</p>
                </div>
              </section>
            </Header>

            <ChallengeContent>
              <div className="first-content-about">
                <p id="challenge-title">{challenge && challenge.title}</p>

                <div className="rules">
                  <p id="title-rules">
                    Regras para determinar o Vencedor do Desafio
                  </p>
                  <hr />
                  <p id="text-rules">{challenge && challenge.to_win}</p>
                </div>

                <div className="finish-challenge">
                  <p id="title-finish-challenge">
                    Enviar sua Solução para o Desafio
                  </p>
                  <hr />
                  <p id="text-finish-challenge">
                    Que orgulho! Agora você está a poucos cliques de se tornar
                    um campeão!
                  </p>
                  <Form onSubmit={handleSendChallenge}>
                    <Input
                      type="text"
                      name="link"
                      placeholder="Insira o Link do arquivo com sua Solução"
                    />

                    <Textarea
                      name="content"
                      placeholder="Descreva aqui um pequeno resumo sobre sua proposta de Solução para este Desafio (opcional). Mas atenção! Este resumo não substitui o envio da Solução no
                    campo acima."
                    />

                    <div className="box">
                      <p>
                        <strong>Dica</strong>: você pode salvar seu arquivo
                        Word/PDF no Google Drive ou salvar um vídeo em seu canal
                        no Youtube e encaminhar o link, por exemplo. Preste
                        atenção às regras do Desafio informadas pela Empresa
                        Desafiadora antes de enviar sua Solução!
                      </p>
                    </div>

                    <button type="submit" id="btn-finish">
                      Enviar Solução
                    </button>
                  </Form>
                </div>
              </div>

              <aside className="second-content-about">
                <div className="company-info">
                  <div className="title-company">
                    <p id="company-name">Empresa Desafiante</p>
                    <section></section>
                  </div>

                  <div className="logo-company">
                    <div className="content-img">
                      <img
                        src={
                          challenge &&
                          challenge.company.avatar !== null &&
                          challenge.company.avatar.url
                        }
                        alt=""
                      />
                    </div>
                    <section></section>
                  </div>

                  <div className="social-networks">
                    <p id="title-social-networks">Redes sociais:</p>
                    <div className="icons">
                      <a
                        href={`https://www.instagram.com/${
                          challenge && completeLink(challenge.company.instagram)
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram
                          title={challenge && `${challenge.company.instagram}`}
                          size={21}
                          color="#7f7fb9"
                        />
                      </a>
                      <a
                        href={`https://www.twitter.com/${
                          challenge && completeLink(challenge.company.twitter)
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter
                          title={challenge && `${challenge.company.twitter}`}
                          size={21}
                          color="#7f7fb9"
                        />
                      </a>
                      <a
                        href={`https://www.facebook.com/${
                          challenge && completeLink(challenge.company.facebook)
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookF
                          title={challenge && `${challenge.company.facebook}`}
                          size={19}
                          color="#7f7fb9"
                        />
                      </a>
                      <a
                        href={`https://www.linkedin.com/in/${
                          challenge && completeLink(challenge.company.linkedin)
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedinIn
                          title={challenge && `${challenge.company.linkedin}`}
                          size={21}
                          color="#7f7fb9"
                        />
                      </a>
                    </div>
                    <section></section>
                  </div>
                  <div className="adress">
                    <p id="title-adress-networks">Website:</p>
                    <a
                      id="text-adress"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        challenge &&
                        challenge.company &&
                        `${completeLink(challenge.company.website)}`
                      }
                    >
                      {challenge &&
                        challenge.company &&
                        challenge.company.website}
                    </a>
                  </div>
                </div>
              </aside>
            </ChallengeContent>

            {challenge && otherChallenges && otherChallenges.length > 0 && (
              <OtherChallenges>
                <p id="title">Mais desafios dessa empresa</p>
                <section id="section-challenges"></section>
                <div className="chalenges">
                  {otherChallenges &&
                    otherChallenges.map((challenge) => (
                      <ItemChallenge key={challenge.id} data={challenge} />
                    ))}
                </div>
                <button
                  id="more-challenges"
                  onClick={() => history.push("/challenges")}
                >
                  Ver mais desafios
                </button>
              </OtherChallenges>
            )}

            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Challenge;
