import styled, { css } from "styled-components";
import { darken } from "polished";

import csv from "react-json-to-csv";

const primaryColor = "#227af1";

export const Wrapper = styled.div`
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;
  height: 231px;

  background: #227af1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  padding-bottom: 25px;

  align-items: center;
  justify-content: center;

  #logo {
    margin-top: 30px;
  }

  #text-header {
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    align-self: center;

    margin-top: 30px;
  }
`;
export const Notification = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #227af1;

  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  #value {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
`;

export const ProfileContent = styled.div`
  width: 100%;
  display: flex;

  margin-left: 139px;
  margin-top: 52px;

  aside {
    width: 261px;

    margin-right: 107px;

    display: flex;
    flex-direction: column;
    margin-top: 32.5px;

    .menu-profile {
      width: 100%;
      height: 1004px;

      margin-top: -8px;

      border: 0.1px solid #eee;

      border-radius: 10px;
      box-shadow: 0 0 30px 0 rgba(49, 22, 159, 0.05);
      background-color: #fff;

      padding-top: 18px;

      .menu {
        display: flex;
        flex-direction: column;
      }
    }

    #btn-company-profile {
      width: 261px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      margin-top: 18px;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }
`;

export const MenuList = styled.button`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.21;
  letter-spacing: normal;
  text-align: left;
  color: #7f7fb9;
  background: transparent;

  margin-left: 25px;
  margin-bottom: 2px;

  display: flex;
  align-items: center;

  ${(props) =>
    props.active &&
    css`
      color: #227af1;
    `}
`;

export const ContactComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .buttons {
    display: flex;
    align-items: center;

    margin-bottom: 40px;
  }

  .floatPage {
    float: right;
  }
`;

export const ArticleComponent = styled.main`
  width: 720px;
  height: 1500px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .articles {
    width: 100%;
    .content {
      width: 100%;

      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
    }
  }

  .floatPage {
    margin-top: 30px;

    float: right;
  }
`;
export const Article = styled.div`
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border-radius: 15px;

  width: 350px;
  margin-bottom: 30px;
  margin-right: 10px;

  ${(props) =>
    props.active &&
    css`
      height: 550px;
    `}

  ${(props) =>
    !props.active &&
    css`
      height: 420px;
    `}

    padding:20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .infos {
    margin-top: 10px;
    margin-left: 20px;

    display: flex;
    flex-direction: column;

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: 20px;
      }

      p {
        margin-left: 20px;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.33;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }
    }
  }

  #image {
    height: 120px;
    width: 140px;

    border-radius: 15px;

    margin-bottom: 20px;
  }

  .curriculum {
    display: flex;
    flex-direction: column;

    #title-curriculum {
      width: 282px;

      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;

      font-size: 16px;
      font-weight: 500;

      color: #227af1;
    }

    #curriculum {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;

      word-wrap: break-word;

      width: 282px;

      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }

  .links {
    width: 250px;
    align-self: center;

    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      img {
        height: 30px;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }

      svg {
        color: #227af1;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  hr {
    margin-bottom: 20px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }
`;

export const CategoriesComponent = styled.main`
  width: 720px;

  .buttons {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  hr {
    margin-bottom: 20px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }

  input {
    width: 352px;
    height: 60px;
    border-radius: 30px;
    border: solid 1.4px #7f7ebe;
    margin-right: 16px;
    margin-bottom: 20px;
    padding-left: 33.6px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #7f7fb9;

    &::-moz-placeholder {
      opacity: 1;
    }
    &::placeholder {
      font-size: 14px;
      text-align: left;
      color: #7f7fb9;
    }
  }

  #btn-submit {
    width: 180px;
    height: 60px;
    border-radius: 30px;
    border: solid 1px #2976fa;
    background-color: #227af1;

    font-size: 14px;
    font-weight: 700;

    line-height: 1.07;

    text-align: center;
    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;

    margin-bottom: 30px;

    .category {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-right: 30px;
      margin-bottom: 20px;

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .floatPage {
    float: right;
  }
`;

export const Category = styled.div`
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);

  height: 220px;
  width: 300px;

  padding: 30px;

  border-radius: 15px;

  .box {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 18px;

    margin-bottom: 20px;

    img {
      margin-right: 15px;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      color: #227af1;
      margin-top: 5px;
    }
  }

  .box-category {
    display: flex;
    flex-direction: column;

    p {
      font-size: 15px;
      font-weight: 500;
      color: #227af1;
      margin-bottom: 20px;
    }

    #category {
      font-size: 25px;
      font-weight: normal;
      color: #227af1;
    }
  }

  hr {
    margin-bottom: 20px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: solid 1px #707070;
  }
`;

export const Contact = styled.div`
  width: 720px;
  height: 250px;

  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  margin-bottom: 19px;

  padding-left: 35px;
  padding-top: 27px;

  .title-transaction {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 12px;
    #text-title-transaction {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2976fa;
    }

    .data {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 30px;

      #icon-calendar {
        width: 14px;
        height: 13.6px;
        margin-right: 8px;
      }

      #day {
        margin-top: 2px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }
  }
  hr {
    width: 650px;
    height: 0;
    opacity: 0.24;
    border: solid 1px #707070;
  }

  #message {
    margin-top: 10px;

    height: 100px;

    font-family: GothamProRegular;
    font-size: 14px;
    line-height: 2;
    text-align: left;
  }
  .infos {
    flex-wrap: wrap;
    display: flex;

    margin-top: 15px;
    p {
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      color: #2976fa;
      margin-right: 10px;
    }
  }
`;

export const BoxLabel = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: #227af1;

  ${(props) =>
    props.active &&
    css`
      background-color: ${darken(0.2, "#227af1")};
    `}

  display:flex;
  align-items: center;
  justify-content: center;
  .label-box {
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 58px;
      border-radius: 50%;

      input {
        display: none;
      }

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        opacity: 0.7;
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }
`;

export const DonationsComponent = styled.main`
  width: 720px;
  height: 1000px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }
  .new-assistant {
    margin-top: 30px;

    #new-assistant-title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      margin-bottom: 20px;
    }

    #btn-assistant {
      margin-top: 40px;

      width: 124px;
      height: 45px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }

    .labels {
      display: flex;
      flex-direction: row;

      margin-top: 20px;

      .label {
        margin-right: 40px;
      }
    }

    .label__checkbox {
      display: none;
    }

    .label__check {
      display: inline-block;
      border-radius: 3px;
      border: solid 1px #7f7fb9;
      background: white;
      vertical-align: middle;
      margin-right: 20px;
      width: 26px;
      height: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out 0s;

      margin-left: 5px;

      i.icon {
        opacity: 0.2;
        font-size: calc(1rem + 1vw);
        color: transparent;
        transition: all 0.3s ease-in-out 0s;
        -webkit-text-stroke: 3px #fff;
      }

      &:hover {
        border: solid 2.5px #7f7fb9;
      }
    }

    .label__text {
      display: flex;
      align-items: center;

      #text-checkbox {
        font-size: 14px;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .label__checkbox:checked + .label__text .label__check {
      animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;

      .icon {
        opacity: 1;
        transform: scale(0);
        color: white;
        -webkit-text-stroke: 0;
        animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.4s 1 forwards;
      }
    }

    @keyframes icon {
      from {
        opacity: 0;
        transform: scale(0.3);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes check {
      0% {
        width: 1.5em;
        height: 1.5em;
        border-width: 5px;
      }
      10% {
        width: 1.5em;
        height: 1.5em;
        opacity: 0.1;
        background: #fff;
        border-width: 15px;
      }
      12% {
        width: 1.5em;
        height: 1.5em;
        opacity: 0.4;
        background: #fff;
        border-width: 1;
      }
      50% {
        width: 2em;
        height: 2em;
        background: #fff;
        border: 0;
        opacity: 0.6;
      }
      100% {
        width: 2em;
        height: 2em;
        background: #fff;
        border: 1;
        opacity: 1;
      }
    }
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    height: 2px;

    border: 1px dashed #eee;
  }

  .companies-assistants,
  .institutions-assistants {
    margin-top: 50px;
    margin-bottom: 20px;

    height: 250px;

    #text-ass {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      margin-bottom: 20px;
    }
  }

  .input-imgs {
    width: 352px;
    height: 60px;
    padding: 1px 0 1px 27.6px;
    border-radius: 30px;
    border: solid 1px #7f7ebe;

    display: flex;
    align-items: center;
    justify-content: space-between;

    #placeholder {
      font-family: GothamProRegular;
      font-size: 14px;
      line-height: 1.71;
      text-align: left;
      color: #7f7fb9;
    }
  }
  .previews {
    display: flex;
    margin-top: 20px;
    .preview {
      height: 97px;
      width: 76px;

      margin-right: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;

      #teste {
        width: 76px;
        height: 65px;
        border-radius: 10px;
      }

      button {
        width: 40px;

        background: transparent;

        font-size: 14px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #227af1;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  #text-ass {
    margin-top: 30px;
  }

  .assistants {
    margin-top: 30px;
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    .preview {
      height: 97px;
      width: 76px;

      margin-right: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;

      #teste {
        width: 76px;
        height: 65px;
        border-radius: 10px;
      }

      button {
        width: 40px;

        background: transparent;

        font-size: 14px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #227af1;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }
`;

export const ChallengeComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }
  .newProject {
    width: 720px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 14px 0px 35px;

    margin: 17.6px 0px 31.2px 0px;

    p {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 4.38;
      letter-spacing: normal;
      text-align: left;
      color: #2976fa;
    }

    button {
      width: 124px;
      height: 45px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;

    .new-box {
      margin-bottom: 30px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .infos {
        margin-top: 25px;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;

          cursor: pointer;
          > div {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #227af1;
            display: flex;
            align-items: center;
            justify-content: center;

            margin-right: 10px;

            svg,
            img {
              height: 16px;
            }
          }

          p {
            color: #227af1;
          }

          transition: all 0.3s ease-in-out 0s;

          &:hover {
            > div {
              background-color: ${darken(0.1, "#227af1")};
            }

            p {
              color: ${darken(0.1, "#227af1")};
            }
          }
        }
      }
    }
  }

  .floatPage {
    float: right;
  }
`;
export const CollaborationsComponent = styled.main`
  width: 720px;
  height: 1000px;
  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  #title-complaints {
    margin-bottom: 50px;
    margin-top: 35px;

    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;
  }

  .project,
  .challenge {
    width: 352px;
  }

  .product {
    width: 260px;
  }

  .project,
  .challenge,
  .product {
    display: flex;
    flex-direction: column;

    margin-bottom: 25px;

    .buttons {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        width: 124px;
        height: 45px;
        border-radius: 30px;
        background-color: #227af1;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }
  .buttons {
    margin: 15px 0 40px 0;

    width: 100%;
    align-self: center;

    display: flex;
    align-items: center;
  }
  .floatPage {
    float: right;
  }
`;
export const GoalsComponent = styled.main`
  width: 720px;
  height: 950px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .project {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 20px;

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .floatPage {
    float: right;

    margin-top: 50px;
  }
`;

export const ButtonAccept = styled.button`
  margin-top: 15px;

  width: 124px;
  height: 45px;
  border-radius: 30px;

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  transition: all 0.3s ease-in-out 0s;

  ${(props) =>
    !props.accept &&
    css`
      margin-right: 25px;
      background-color: #227af1;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    `}

  ${(props) =>
    props.accept &&
    css`
      background-color: ${darken(0.1, "#227af1")};

      &:hover {
        background-color: ${darken(0.2, "#227af1")};
      }
    `}
`;

export const HistoricComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }
  .floatPage {
    float: right;
  }
`;
export const ProjectGoals = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 8px;

  z-index: 1;

  width: 352px;
  height: 586px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin-top: 10px;
      max-width: 352px;
      max-height: 170px;
    }
  }

  .content-2 {
    width: 100%;
    height: 357px;
    .titles {
      margin-top: 26px;

      #title-project {
        margin-left: 33px;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }

      div {
        align-self: flex-start;

        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 16.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .text-content {
      margin-top: 15.3px;

      #text-content-justify {
        font-family: GothamPro;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      p {
        margin-left: 33px;

        width: 282px;

        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000000;

        word-wrap: break-word;
      }
      section {
        margin-top: 18.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .cash {
      display: flex;
      align-items: flex-start;

      margin-top: 7.8px;

      padding-left: 33px;

      .values {
        #meta {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
          margin-bottom: 10px;
        }
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 40px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
`;

export const ShoppingComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .buttons {
    margin: 15px 0 20px 0;

    width: 100%;
    align-self: center;

    display: flex;
    align-items: center;
  }
  .floatPage {
    float: right;

    margin-top: 30px;
  }
`;

export const ButtonDonation = styled.button`
  width: 352px;
  height: 60px;
  border-radius: 30px;

  ${(props) =>
    !props.active &&
    css`
      background: transparent;
      border: 2px solid ${primaryColor};
      color: ${primaryColor};

      &:hover {
        background-color: ${darken(0.1, "#fff")};
      }
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${primaryColor};
      border: none;
      color: #fff;

      &:hover {
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    `}

    img {
    height: 40px;
  }

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;

  transition: all 0.3s ease-in-out 0s;

  margin-right: 30px;
`;

export const ButtonContacts = styled.button`
  width: 270px;
  height: 60px;
  border-radius: 30px;

  ${(props) =>
    !props.active &&
    css`
      background: transparent;
      border: 2px solid ${primaryColor};
      color: ${primaryColor};

      &:hover {
        background-color: ${darken(0.1, "#fff")};
      }
    `}

  ${(props) =>
    props.active &&
    css`
      background: ${primaryColor};
      border: none;
      color: #fff;

      &:hover {
        background-color: ${darken(0.1, `${primaryColor}`)};
      }
    `}


    font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;

  transition: all 0.3s ease-in-out 0s;

  margin-right: 5px;
`;

export const ProjectSecond = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 8px;
  z-index: 1;
  width: 352px;
  height: 542.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      margin-top: 10px;

      max-width: 352px;
      max-height: 170px;
    }
  }
  section {
    margin-top: 16.6px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }
  .content-2 {
    width: 100%;
    height: 313.8px;

    .titles {
      width: 100%;
      margin-top: 26px;
      #title-project {
        margin-left: 33px;
        font-size: 100%;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;

        ${(props) =>
    props.success &&
    css`
            color: #06d6a0;
          `}

        ${(props) =>
    props.cancel &&
    css`
            color: #e71d36;
          `}

                
                margin-top:10px;
      }
      div {
        align-self: flex-start;
        display: flex;
        align-items: center;

        img {
          margin-left: 33px;
          margin-right: 7px;
        }
        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #000;
        }
      }
    }
    .about-user {
      height: 70px;
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      margin-left: 33px;

      .box {
        width: 100%;
        height: 20px;

        display: flex;
        flex-direction: row;

        align-items: flex-end;

        margin-bottom: 10px;

        img {
          height: 15px;
        }

        p {
          margin-left: 5px;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #000;
        }
      }
    }
    .donation {
      margin-top: 2.6px;
      p {
        margin-left: 33px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
      #value {
        font-size: 20px;
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;

    ${(props) =>
    props.success &&
    css`
        background-color: #06d6a0;
      `}

    ${(props) =>
    props.cancel &&
    css`
        background-color: #e71d36;
      `}

        display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    img {
      margin: 38px 20px 40px 40px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
`;

export const Cosmos = styled.div`
  margin-bottom: 20.5px;
  margin-top: 40px;
  margin-right: 8px;

  z-index: 1;

  width: 352px;
  height: 393.8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  section {
    margin-top: 16.6px;
    width: 100%;
    height: 0.1px;
    background: #eee;
  }

  .content-1 {
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background-color: ${primaryColor};

    ${(props) =>
    props.success &&
    css`
        background-color: #06d6a0;
      `}

    ${(props) =>
    props.cancel &&
    css`
        background-color: #e71d36;
      `}


        display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin-top: 10px;
    }
  }
  #title-cosmos {
    text-align: center;
    color: ${primaryColor};

    ${(props) =>
    props.success &&
    css`
        color: #06d6a0;
      `}

    ${(props) =>
    props.cancel &&
    css`
        color: #e71d36;
      `}

        font-size:20px;
    margin-top: 20px;
  }
  .content-2 {
    width: 100%;
    height: 273.8px;

    .about-user {
      height: 70px;
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      margin-left: 33px;

      .box {
        width: 100%;
        height: 20px;

        display: flex;
        flex-direction: row;

        align-items: flex-end;

        margin-bottom: 10px;

        img {
          height: 15px;
        }

        p {
          margin-left: 5px;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #000;
        }
      }
    }

    .donation {
      margin-top: 10px;

      p {
        margin-left: 33px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }

      #value {
        font-size: 20px;
      }
    }
  }
`;

export const NewBlogComponent = styled.main`
  width: 720px;

  #title-component {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .form {
    .inputs {
      display: flex;

      input {
        width: 352px;
        height: 60px;
        border-radius: 30px;
        border: solid 1.4px #7f7ebe;
        margin-right: 16px;
        margin-bottom: 20px;
        padding-left: 33.6px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        color: #7f7fb9;

        &::-moz-placeholder {
          opacity: 1;
        }
        &::placeholder {
          font-size: 14px;
          text-align: left;
          color: #7f7fb9;
        }
      }
    }
    .input-imgs {
      width: 352px;
      height: 60px;
      padding: 1px 0 1px 27.6px;
      border-radius: 30px;
      border: solid 1px #7f7ebe;

      display: flex;
      align-items: center;
      justify-content: space-between;

      #placeholder {
        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 1.71;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .message {
      width: 720px;
      height: 179px;

      border-radius: 15px;

      border: 0.1px solid #eee;

      outline: none;
      resize: none;
      overflow: hidden;

      padding: 19px 33.6px 0px 33.6px;

      margin-bottom: 19px;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      border: solid 1.4px #7f7ebe;

      &::placeholder {
        clear: left;
        flex-wrap: wrap;

        color: #7f7fb9;
      }

      &::-moz-placeholder {
        opacity: 1;
      }
    }

    .previews {
      display: flex;
      margin-top: 20px;
      .preview {
        height: 97px;
        width: 76px;

        margin-right: 16px;

        display: flex;
        flex-direction: column;
        align-items: center;

        #teste {
          width: 76px;
          height: 65px;
          border-radius: 10px;
        }

        button {
          width: 40px;

          background: transparent;

          font-size: 14px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #227af1;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }
    #btn-submit {
      width: 180px;
      height: 60px;
      border-radius: 30px;
      border: solid 1px #2976fa;
      background-color: #227af1;

      font-size: 14px;
      font-weight: 700;

      line-height: 1.07;

      text-align: center;
      color: #ffffff;

      float: right;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }
`;

export const New = styled.div`
  margin-right: 8px;

  z-index: 1;

  width: 352px;
  height: 513px;
  background: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px 10px 0 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;
    }
  }

  .content-2 {
    width: 100%;
    height: 230px;
    background-color: #fff;

    padding: 30px;

    #day {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #bbbbc7;

      margin-bottom: 10px;
    }

    #title {
      color: #227af1;
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
    }

    #text {
      margin-top: 10px;
      font-family: GothamProRegular;
      letter-spacing: 2;
      line-height: 2;
      font-size: 14px;
    }
  }

  .content-3 {
    width: 100%;
    height: 59px;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    margin-top: 60px;

    img {
      margin: 38px 20px 40px 40px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 350px;

  margin-top: 100px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  #footer-content-1 {
    background-color: #fafaff;
    height: 297px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    #content-social-medias {
      margin-left: 100px;

      #logo-content {
        > img {
           margin-left: 30px;
           width: 300px;
           height: 71px;
        }
      }
    }
  }
`;

export const DropDownContainer = styled.div`
  z-index: 1;
`;

export const DropDownHeader = styled.div`
  margin-bottom: 0.8em;

  width: 352px;

  .estado {
    width: 168px;
  }

  #text-state {
    margin-top: 30px;
    font-size: 14px;
  }

  height: 60px;
  border-radius: 30px;
  border: solid 1.4px #7f7ebe;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 25px;
  padding-right: 15px;

  font-size: 14px;
  text-align: left;
  color: #7f7fb9;

  #small {
    font-size: 15.5px;
  }

  margin-right: 16px;

  z-index: 1;

  cursor: pointer;

  .icon-drop {
    margin-bottom: 5px;
  }

  #up-icon {
    transform: rotate(180deg);
  }
`;

export const DropDownListContainer = styled.div`
  margin-top: 1px;
  position: absolute;
  z-index: 1;
  width: 22em;

  ${(props) =>
    props.small &&
    css`
      width: 11em;
    `}

  .projects {
    font-size: 14px;
  }

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    margin-left: 145px;

    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #7f7ebe;
  }

  animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
`;

export const DropDownList = styled.ul`
  background: #ffffff;
  border: solid 1.4px #7f7ebe;
  box-sizing: border-box;
  color: #7f7ebe;

  z-index: 1;

  height: 68px;

  .projects {
    font-size: 12px;
    color: red;
  }

  border-radius: 5px;

  &:first-child {
    padding-top: 5px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 7.5px;
  margin-top: 5.25px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 85%;
  height: 42px;

  z-index: 1;

  margin-left: 15px;

  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    color: ${darken(0.15, "#7f7ebe")};
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }
`;

export const TextsComponent = styled.main`
  width: 720px;
  height: 1500px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .buttons-content-about {
    width: 720px;
    height: 68px;
    border-radius: 50px;
    box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 15px;
  }

  hr {
    margin-top: 40px;
    margin-bottom: 40px;

    width: 100%;
    height: 1;
    opacity: 0.24;
    border: dashed 1px #eee;
  }

  .content {
    margin-top: 50px;

    #title-content {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    textarea {
      width: 715px;
      height: 179px;
      border-radius: 15px;
      border: solid 1px #7f7ebe;
      outline: none;
      resize: none;
      overflow: hidden;
      padding-top: 19px;
      padding-left: 33.6px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #7f7fb9;

      margin-bottom: 22px;

      &::placeholder {
        clear: left;
        flex-wrap: wrap;
        color: #7f7fb9;
      }
      &::-moz-placeholder {
        opacity: 1;
      }
    }

    button {
      float: right;

      width: 260px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      margin-right: 16px;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }

    #info {
      font-size: 18px;
      font-weight: 500;
      line-height: 0.86;
      text-align: left;
      color: #227af1;

      margin-bottom: 20px;
    }
  }

  .floatPage {
    float: right;
  }
`;

export const Button = styled.button`
  width: 130px;
  height: 45px;

  margin-right: 5px;

  background-color: transparent;
  border: solid 1.6px #227af1;
  border-radius: 30px;

  font-family: GothamProRegular;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #227af1;

  transition: all 0.3s ease-in-out 0s;
  &:hover {
    border: solid 1.6px ${darken(0.1, "#227af1")};
    color: ${darken(0.1, "#227af1")};
    background-color: ${darken(0.05, "#fff")};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #227af1;
      color: #fff;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
        color: #fff;
      }
    `}
`;

export const RequestComponent = styled.main`
  width: 720px;
  height: 1200px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .content {
    .projects {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .project {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 20px;

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        button {
          width: 220px;
          height: 45px;
          border-radius: 30px;
          background-color: #227af1;

          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;

          transition: all 0.3s ease-in-out 0s;

          margin-right: 0px;
          &:hover {
            background-color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }
  }

  .floatPage {
    float: right;
  }
`;

export const ProjectsComponent = styled.main`
  width: 720px;
  height: 1200px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .content {
    width: 100%;

    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
  }

  .floatPage {
    float: right;
  }
`;

export const SearchComponent = styled.main`
  width: 720px;
  height: 1350px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .buttons-content-about {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  hr {
    margin-bottom: 20px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }

  .floatPage {
    float: right;
    margin-bottom: 20px;
  }

  .form-search-user {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;

    input {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #7f7ebe;
      margin-top: 20px;
      margin-right: 16px;
      margin-bottom: 20px;
      padding-left: 33.6px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      color: #7f7fb9;

      &::-moz-placeholder {
        opacity: 1;
      }
      &::placeholder {
        font-size: 14px;
        text-align: left;
        color: #7f7fb9;
      }
    }

    #btn-submit-search {
      width: 220px;
      height: 55px;

      border-radius: 30px;

      background: #227af1;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, "#fff")};
      }
    }

    #btn-clean {
      background-color: #fff;
      border-radius: 30px;
      height: 55px;
      width: 105px;
      margin-left: 15px;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      border: 2px solid #227af1;
      color: #227af1;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#fff")};
        color: ${darken(0.1, "#227af1")};
      }
    }
  }

  .box-search-users {
    width: 100%;
    height: 155px;
    border-radius: 15px;
    padding: 20px;

    border: 1.4px dashed #ddd;

    li {
      width: 90%;
      height: 105px;

      padding: 15px;
      border-radius: 10px;

      border: 1px solid #7f7fb9;

      margin-bottom: 5px;

      .datas {
        display: flex;
        flex-direction: row;

        p {
          line-height: 1.2;
        }

        #field {
          color: #227af1;
          font-size: 15px;
          font-weight: normal;
          text-align: left;
          margin-right: 10px;
        }

        #data {
          font-family: GothamProRegular;
          font-size: 15px;
        }
      }
    }
  }

  .fs-content {
    .contacts {
      margin-top: 20px;
    }
    #title-content {
      color: #7f7ebe;
      font-size: 18px;
    }
  }

  .sec-content {
    #title-content {
      color: #7f7ebe;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .recover {
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
    height: 300px;
    width: 350px;
    border-radius: 15px;

    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      color: #7f7ebe;
      font-size: 15px;
    }
  }
`;

export const NormalProject = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 8px;

  z-index: 1;

  width: 352px;
  height: 586px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin-top: 10px;
      max-width: 352px;
      max-height: 170px;
    }
  }

  .content-2 {
    width: 100%;
    height: 357px;

    .titles {
      width: 100%;
      margin-top: 26px;

      #title-project {
        margin-left: 33px;
        font-size: 100%;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }

      div {
        align-self: flex-start;

        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 16.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .text-content {
      margin-top: 15.3px;

      p {
        margin-left: 33px;

        width: 302px;

        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000000;

        word-wrap: break-word;
      }
      section {
        margin-top: 8.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .cash {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .values {
        #meta {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #d6d6e3;
        }
        .donation {
          display: flex;
          align-items: baseline;

          p {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;
          }

          #donation-value {
            margin-left: 5px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 40px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
`;

export const ChallengesNotAprovedComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }
  .content {
    .challenges {
      width: 800px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .challenge {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 20px;

        .buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          button {
            width: 220px;
            height: 45px;
            border-radius: 30px;
            background-color: #227af1;

            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;

            transition: all 0.3s ease-in-out 0s;

            margin-right: 0px;
            &:hover {
              background-color: ${darken(0.1, "#227af1")};
            }
          }
        }
      }
    }

    .floatPage {
      float: right;
    }
  }
`;
export const DeletedUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  box-shadow: 0 0 30px 0 rgba(49, 22, 159, 0.05);
  border-radius: 15px;

  width: 100%;
  height: 250px;
  padding: 25px;

  .fs-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 215px;
    width: 49%;

    border-right: 1px solid #eee;

    #info {
      font-size: 15px;

      font-family: GothamProRegular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
    }
  }

  .sec-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 215px;
    width: 48%;
    margin-left: 2%;
    #info {
      font-size: 15px;

      font-family: GothamProRegular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
    }
  }
`;

export const DeletedUsersComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }
  .content {
    .deleted-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;

      > div {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
          width: 220px;
          height: 45px;
          border-radius: 30px;
          background-color: #227af1;

          margin-right: 20px;

          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;

          transition: all 0.3s ease-in-out 0s;

          &:hover {
            background-color: ${darken(0.1, "#227af1")};
          }
        }
      }
    }
  }
  .floatPage {
    float: right;
  }
`;

export const SupportComponent = styled.main`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 220px;
      height: 45px;
      border-radius: 30px;
      background-color: #227af1;

      margin-right: 20px;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  .floatPage {
    float: right;
  }
`;

export const NotificationsComponent = styled.main`
  width: 720px;

  #title-notifications {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  hr {
    margin-bottom: 50px;
    margin-top: 50px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }

  .box-search-users {
    width: 100%;
    height: 135px;
    border-radius: 15px;
    padding: 20px;

    border: 1.4px dashed #ddd;

    li {
      width: 90%;
      height: 85px;

      padding: 15px;
      border-radius: 10px;

      border: 1px solid #7f7fb9;

      margin-bottom: 5px;

      .datas {
        display: flex;
        flex-direction: row;

        p {
          line-height: 1.2;
        }

        #field {
          color: #227af1;
          font-size: 15px;
          font-weight: normal;
          text-align: left;
          margin-right: 10px;
        }

        #data {
          font-family: GothamProRegular;
          font-size: 15px;
        }
      }
    }
  }

  .form-search-user {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 30px;

    #btn-submit-search {
      width: 220px;
      height: 55px;

      border-radius: 30px;

      background: #227af1;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, "#fff")};
      }
    }

    #btn-clean {
      background-color: #fff;
      border-radius: 30px;
      height: 55px;
      width: 105px;
      margin-left: 15px;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      border: 2px solid #227af1;
      color: #227af1;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#fff")};
        color: ${darken(0.1, "#227af1")};
      }
    }
  }

  input {
    width: 352px;
    height: 60px;
    border-radius: 30px;
    border: solid 1.4px #7f7ebe;
    margin-right: 8px;

    padding-left: 33.6px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #7f7fb9;

    &::-moz-placeholder {
      opacity: 1;
    }
    &::placeholder {
      font-size: 14px;
      text-align: left;
      color: #7f7fb9;
    }
  }

  .full {
    width: 100%;
  }

  #btn-submit {
    width: 220px;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;

    margin-right: 20px;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  .message {
    width: 720px;
    height: 179px;

    border-radius: 15px;

    border: 0.1px solid #eee;

    outline: none;
    resize: none;
    overflow: hidden;

    padding: 19px 33.6px 0px 33.6px;

    margin-bottom: 19px;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;

    border: solid 1.4px #7f7ebe;

    &::placeholder {
      clear: left;
      flex-wrap: wrap;

      color: #7f7fb9;
    }

    &::-moz-placeholder {
      opacity: 1;
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    margin-bottom: 40px;
  }

  .floatPage {
    float: right;
  }
`;

export const UsersComponents = styled.main`
  width: 720px;
  margin-bottom: 40px;

  #title-component {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  .buttons {
    display: flex;
    align-items: center;

    margin-bottom: 40px;
  }

  .user-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 30px;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #btn-delete {
    width: 220px;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;

    margin-top: 15px;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  .floatPage {
    float: right;
  }
`;

export const MyCategories = styled.div`
  height: 95px;
  margin-bottom: 20px;

  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .input-teste {
    width: 352px;
    height: 60px;
    border-radius: 30px;
    border: solid 1.4px #7f7ebe;
    padding-left: 33.6px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #7f7fb9;

    margin-left: 15px;
    margin-top: 20px;

    &::-moz-placeholder {
      opacity: 1;
    }
    &::placeholder {
      font-size: 14px;
      text-align: left;
      color: #7f7fb9;
    }
  }

  .buttons-category {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 10px;
  }

  button {
    background-color: transparent;

    margin-right: 20px;
    margin-bottom: 10px;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #227af1;

    transition: all 0.3s ease-in-out 0s;

    &:hover {
      color: ${darken(0.1, "#227af1")};
    }
  }
`;

export const UserComponent = styled.div`
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  border-radius: 15px;
  height: 570px;
  width: 685px;

  margin-left: 5px;
  margin-top: 10px;

  padding-left: 30px;
  padding-right: 30px;

  ${(props) =>
    !props.active &&
    css`
      padding-top: 30px;
    `}

  ${(props) =>
    props.active &&
    css`
      padding-top: 10px;
    `}


    display:flex;
  flex-direction: column;

  img {
    border: 1px solid #7f7fb9;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    align-self: center;
  }

  .box-infos {
    margin-bottom: 10px;

    #title-box-infos {
      font-size: 15px;
      font-weight: normal;
      text-align: left;
      color: #7f7fb9;
      margin-bottom: 10px;
    }

    .datas {
      display: flex;
      flex-direction: row;

      p {
        line-height: 1.2;
      }

      #field {
        color: #227af1;
        font-size: 15px;
        font-weight: normal;
        text-align: left;
        margin-right: 10px;
      }

      #data {
        font-family: GothamProRegular;
        font-size: 15px;
      }
    }
  }

  hr {
    margin-bottom: 20px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }
`;

export const CreditWhuffieComponent = styled.div`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  hr {
    margin-bottom: 20px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }

  #sub-title {
    font-size: 20px;
    font-weight: normal;
    text-align: left;
    color: #7f7fb9;
    margin-bottom: 25px;
  }

  .form-search-user {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 30px;

    #btn-submit-search {
      width: 220px;
      height: 55px;

      border-radius: 30px;

      background: #227af1;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, "#fff")};
      }
    }

    #btn-clean {
      background-color: #fff;
      border-radius: 30px;
      height: 55px;
      width: 105px;
      margin-left: 15px;

      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      border: 2px solid #227af1;
      color: #227af1;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#fff")};
        color: ${darken(0.1, "#227af1")};
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    margin-bottom: 40px;
  }

  .form {
    display: flex;
    align-items: center;
  }

  .content {
    > div {
      margin-bottom: 80px;
    }
  }

  .box-search-users {
    width: 100%;
    height: 135px;
    border-radius: 15px;
    padding: 20px;

    border: 1.4px dashed #ddd;

    li {
      width: 90%;
      height: 85px;

      padding: 15px;
      border-radius: 10px;

      border: 1px solid #7f7fb9;

      margin-bottom: 5px;

      .datas {
        display: flex;
        flex-direction: row;

        p {
          line-height: 1.2;
        }

        #field {
          color: #227af1;
          font-size: 15px;
          font-weight: normal;
          text-align: left;
          margin-right: 10px;
        }

        #data {
          font-family: GothamProRegular;
          font-size: 15px;
        }
      }
    }
  }

  input {
    width: 352px;
    height: 60px;
    border-radius: 30px;
    border: solid 1.4px #7f7ebe;
    padding-left: 33.6px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #7f7fb9;

    margin-right: 15px;

    &::-moz-placeholder {
      opacity: 1;
    }
    &::placeholder {
      font-size: 14px;
      text-align: left;
      color: #7f7fb9;
    }
  }

  #btn-submit {
    width: 220px;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }
`;

export const ReportsComponent = styled.div`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  hr {
    margin-bottom: 20px;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: dashed 1px #707070;
  }

  #sub-title {
    font-size: 20px;
    font-weight: normal;
    text-align: left;
    color: #7f7fb9;
    margin-bottom: 25px;
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .buttons {
    display: flex;
    align-items: center;

    margin-bottom: 40px;
  }

  .form {
    display: flex;
    align-items: center;
  }

  div {
    margin-bottom: 40px;
  }

  input {
    width: 290px;
    height: 60px;
    border-radius: 30px;
    border: solid 1.4px #7f7ebe;
    padding-left: 33.6px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #7f7fb9;

    margin-right: 10px;
    margin-bottom: 15px;

    &::-moz-placeholder {
      opacity: 1;
    }
    &::placeholder {
      font-size: 14px;
      text-align: left;
      color: #7f7fb9;
    }
  }

  #btn-data {
    width: 40px;
    height: 50px;
    border-radius: 4px;
    background-color: #227af1;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
    svg {
      width: 35px;
      height: 35px;
    }
  }

  #btn-submit {
    width: 220px;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }
`;

export const ButtonCSV = styled(csv)`
  width: 100%;
  height: 100px;
  border: 1.5px dashed #7f7fb9;
  border-radius: 15px;
  background-color: #f8f9fa;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #227af1;

  margin: 20px 0 20px 0;

  svg {
    margin-right: 15px;
    width: 35px;
    height: 35px;
  }

  transition: all 0.3s ease-in-out 0s;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: ${darken(0.02, "#f8f9fa")};
    color: ${darken(0.1, "#227af1")};
  }
`;

export const BoxProfile = styled.div`
  width: 360px;
  height: 91px;

  border-radius: 10px;

  display: flex;
  align-items: center;

  padding: 17px;
  margin-bottom: 30.92px;

  ${(props) =>
    props.support &&
    css`
      background: #227af1;
    `}

  ${(props) =>
    props.company &&
    css`
      background: #add948;
    `}

    ${(props) =>
    props.scientist &&
    css`
      background: #ffcc43;
    `}


    .box-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 21.2px;

    #normal {
      line-height: 1.5;
      text-align: left;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      font-family: GothamProRegular;
    }
  }
`;

export const DeleteProjectsContainer = styled.div`
  width: 720px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }
  
  .content {
    width: 100%;

    display: flex;
    flex-direction: row;

    flex-wrap: wrap;

    gap: 12px;

    margin-bottom: 24px;
  }

  .floatPage {
    float: right;
  }
`;

export const DeleteProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-delete-project-wrapper{
      width: 220px;
      height: 45px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      margin-top: 12px;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
  }

`;

export const DeleteChallengesContainer = styled.div`
  width: 760px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }
 
  .content {
    width: 100%;

    display: flex;
    flex-direction: row;

    flex-wrap: wrap;

    gap: 12px;

    margin-bottom: 24px;
  }

  .floatPage {
    float: right;
  }
`;

export const DeleteChallengeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-delete-challenge-wrapper{
      width: 220px;
      height: 45px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      margin-top: 12px;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
  }

`;


export const ProjectsHomeContainer = styled.div`
  width: 760px;

  #title {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-bottom: 17.6px;
  }

  #subtitle-projects-home-container{
    font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      margin-bottom: 20px;
  }
 
  .content {
    width: 100%;

    display: flex;
    flex-direction: row;

    flex-wrap: wrap;

    gap: 12px;

    margin-bottom: 24px;
  }

  .floatPage {
    float: right;
  }
`;

export const ProjectHomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-select-project-home-wrapper{
      width: 220px;
      height: 45px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      margin-top: 12px;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
  }

`;

export const ButtonSelectProjectHomeWrapper = styled.button`
    width: 220px;
      height: 45px;
      border-radius: 30px;
      

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      margin-top: 12px;

      transition: all 0.3s ease-in-out 0s;

      

      ${(props) =>
    !props.selected &&
    css`
       background-color: #227af1;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
      `}
    
    ${(props) =>
    props.selected &&
    css`
        background-color: #06d6a0;
      &:hover {
        background-color: ${darken(0.1, "#06d6a0")};
      }
      `}
`;