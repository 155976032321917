import React from "react";
import { FormattedNumber, IntlProvider } from "react-intl";
import { CircleProgress } from "react-gradient-progress";
import { Link } from "react-router-dom";

import { Container, First, Second, Third, Fourth, Fifth } from "./styles";

import iconCheck from "../../assets/icons/Projects/check.svg";
import iconCompanyProject from "../../assets/icons/Projects/company.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";
import { FaLockOpen } from "react-icons/fa";

function NewProject({ data }) {
  return (
    <Container>
      <Link to={`project/${data.id}`}>
        <First>
          {data && data.first && <img src={data.first.url} alt="Imagem Principal" />}
        </First>
      </Link>
      <Second>
        <Link to={`project/${data.id}`} id="title-project">
          {data.name}
        </Link>
        <div className="box-infos">
          {data && data.special ? (
            <>
              <FaLockOpen color="#227af1" size={16} />

              <p>Tenho Permissão Especial</p>
            </>
          ) : (
            <>
              <img src={iconCompanyProject} alt="" />
              <p>Instituição: {data && data.institution.split(";")[0]}</p>
            </>
          )}
        </div>
        <div className="box-infos">
          <img src={iconCheck} alt="" />
          <p> Categoria: {data.category}</p>
        </div>
      </Second>
      <Third>
        <p>{data && data.summary}</p>
      </Third>
      <Fourth>
        <div className="values">
          <IntlProvider locale="pt-BR">
            <p id="meta">
              Meta: R${" "}
              <FormattedNumber
                value={data.value_goal}
                minimumFractionDigits={2}
              />
            </p>
          </IntlProvider>

          <div className="donation">
            <p>Doado:</p>
            <IntlProvider locale="pt-BR">
              <p id="donation-value">
                R${" "}
                <FormattedNumber
                  value={data.current_value}
                  minimumFractionDigits={2}
                />
              </p>
            </IntlProvider>
          </div>
        </div>
        {parseInt((data.current_value * 100) / data.value_goal) === 100 ? (
          <CircleProgress
            percentage={parseInt((data.current_value * 100) / data.value_goal)}
            strokeWidth={7}
            primaryColor={["#227af1", "#227af1"]}
            width={100}
            fontColor={"#227af1"}
            fontSize={"20px"}
            fontFamily={"GothamProRegular"}
          />
        ) : (
          <CircleProgress
            percentage={parseInt((data.current_value * 100) / data.value_goal)}
            strokeWidth={7}
            primaryColor={["#d6d6e3", "#d6d6e3"]}
            width={100}
            fontColor={"#d6d6e3"}
            fontSize={"20px"}
            fontFamily={"GothamProRegular"}
          />
        )}
      </Fourth>
      <Fifth>
        <img src={atomProject} alt="" />

        <Link to={`/project/${data.id}`}>Conheça o projeto</Link>
      </Fifth>
    </Container>
  );
}

export default NewProject;
