import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  background-color: rgba(0, 0, 0, 0.02);

  padding: 30px 10px;
  .term {
    width: 100%;
    margin: 0 auto;

    p {
      width: 100%;
      word-wrap: break-word;
      font-family: GothamProRegular;
    }

    strong {
      word-wrap: break-word;
    }
    li {
    }

    span {
      word-wrap: break-word;
    }

    p {
      word-wrap: break-word;
    }
  }
`;
