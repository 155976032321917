import React, { useRef } from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";

import { Container } from "./styles";

function ShareProject({ name, id,description }) {
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    toast.success("Link copiado para área de transferência!");
  }
  return (
    <Container>
      <p> Compartilhar este Projeto </p> <section> </section>
      <div className="social-networks">
        <WhatsappShareButton
          title={`Conheça o projeto ${name} na We4science`}
          url={`https://www.we4science.com/project/${id}`}
          separator=" - "
          windowPosition={"screenCenter"}
        >
          <FaWhatsapp size={23} />
        </WhatsappShareButton>
        <TelegramShareButton
          title={`Conheça o projeto ${name} na We4science`}
          url={`https://www.we4science.com/project/${id}`}
          separator=" - "
          windowPosition={"screenCenter"}

        >
          <FaTelegramPlane size={23} />
        </TelegramShareButton>
        <TwitterShareButton
          title={`Conheça o projeto ${name} na We4science`}
          url={`https://www.we4science.com/project/${id}`}
          separator=" - "
          windowPosition={"screenCenter"}

        >
          <FaTwitter size={23} />
        </TwitterShareButton>
        <FacebookShareButton
          title={`Conheça o projeto ${name} na We4science`}
          url={`https://www.we4science.com/project/${id}`}
          separator=" - "
          windowPosition={"screenCenter"}

        >
          <FaFacebookF size={20} />
        </FacebookShareButton>
        <LinkedinShareButton
          title={`Conheça o projeto ${name} na We4science`}
          url={`https://www.we4science.com/project/${id}`}
          separator=" - "
          windowPosition={"screenCenter"}
          summary={description}
          source={`https://www.we4science.com/project/${id}`}
        >
          <FaLinkedinIn size={22} />
        </LinkedinShareButton>
        <EmailShareButton
          title={`Conheça o projeto ${name} na We4science`}
          url={`https://www.we4science.com/project/${id}`}
          separator=""
          windowPosition={"screenCenter"}
          body={`Conheça o projeto ${name} na We4science\n${description}\n Acesse em:`}
        >
          <FaEnvelope size={25} />
        </EmailShareButton>
      </div>
      <section> </section>
      <div className="share-project">
        <textarea
          ref={textAreaRef}
          value={`https://www.we4science.com/project/${id}`}
        />
        {document.queryCommandSupported("copy") && (
          <div>
            <button onClick={copyToClipboard}> Copiar </button>
          </div>
        )}
      </div>
    </Container>
  );
}

export default ShareProject;
