import styled from "styled-components";
import { darken } from "polished";

export const Project = styled.div`
  margin-bottom: 41px;
  margin-top: 40px;
  margin-right: 18px;

  z-index: 1;

  width: 352px;
  height: 586px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  border-radius: 10px;

  .content-1 {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #fafaff;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      margin-top: 10px;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .content-2 {
    width: 100%;
    height: 357px;

    .titles {
      width: 100%;
      margin-top: 26px;

      #title-project {
        margin-left: 33px;
        font-size: 100%;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
        margin-top: 10px;
      }

      div {
        align-self: flex-start;

        display: flex;
        align-items: center;

        img,
        svg {
          margin-left: 33px;
          margin-right: 7px;
        }

        p {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
        }
      }
      section {
        margin-top: 16.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .text-content {
      margin-top: 15.3px;

      p {
        margin-left: 33px;

        width: 302px;

        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000000;

        word-wrap: break-word;
      }
      section {
        margin-top: 8.6px;
        width: 100%;
        height: 0.1px;
        background: #eee;
      }
    }

    .cash {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .values {
        #meta {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #d6d6e3;
        }
        .donation {
          display: flex;
          align-items: baseline;

          p {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;
          }

          #donation-value {
            margin-left: 5px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 59px;

    border-radius: 0 0 10px 10px;
    background: rgb(41, 118, 250);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(54%, rgba(41, 118, 250, 1)),
      to(rgba(68, 169, 252, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(41, 118, 250, 1) 54%,
      rgba(68, 169, 252, 1) 100%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 40px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;

      a {
        color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#FFF")};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin-left: 5vw;
  }
`;
