import { darken } from "polished";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 53px 0 0 0;
  background: #227af1;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  padding-top: 54px;
  position: relative;

  margin: 0;
  z-index: 0;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  z-index: 1;

  max-width: 100%;
  flex-basis: 100%;

  #icon {
    height: 75px;
  }

  #title {
    margin-top: 27.6px;
    font-size: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .text {
    max-width: 100%;

    font-family: GothamProRegular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  #green {
    font-family: GothamPro;
    font-weight: 500;
    color: #5cf3ff;
  }

  p {
    max-width: 100%;

    margin-top: 21px;

    font-family: GothamProRegular;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  button {
    margin-top: 25px;

    width: 260px;
    height: 60px;
    background-color: #5cf3ff;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    border-radius: 30px;

    transition: all 0.3s ease-in-out 0s;

    cursor: pointer;
    &:hover {
      background-color: ${darken(0.1, "#5cf3ff")};
    }
  }

  @media screen and (max-width: 505px) {
    #title {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 472px) {
    .text {
      text-align: justify;
    }

    #title {
      font-size: 1rem;
    }

    p {
      text-align: justify;
    }
  }
`;

export const Img = styled.img`
  max-width: 70%;
  padding-right: 0;
  border: 0;
  vertical-align: middle;

  align-self: flex-end;

  @media screen and (max-width: 1352px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 472px) {
    max-width: 100%;
  }
`;
