import React from "react";

//components
import Header from "./containers/Header";
import Footer from "../../components/Footer";

//containers
import First from "./containers/First";
import Second from "./containers/Second";
import Third from "./containers/Third";
import Fourth from "./containers/Fourth";
import Fifth from "./containers/Fifth";
import Sixth from "./containers/Sixth";
import Seventh from "./containers/Seventh";
//import Partners from "./containers/Partners";

//api
//import api from "../../services/api";
import { Helmet } from "react-helmet";

function __test__() {
  /*
  const [companyAssistant, setCompanyAssistant] = useState([]);

  useEffect(() => {
    const assistants = async () => {
      const { data } = await api.get("/assistants");
      const filterTwo = data.filter((item) => item.type === "Empresa privada");

      setCompanyAssistant(filterTwo);
    };
    assistants();
  }, []);
  */

  return (
    <>
      <Helmet>
        <title>We4Science</title>
      </Helmet>
      <Header />
      <First />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
      {/*<Partners data={companyAssistant} />*/}
      <Sixth />
      <Seventh />
      <Footer />
    </>
  );
}

export default __test__;
