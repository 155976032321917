import styled from "styled-components";

export const Container = styled.section`
  margin-bottom: 150px;
  width: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  #projects {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;

    gap: 12px;


    flex-wrap: wrap;
  }

  .title-projects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 6%;

    @media (min-width: 1300px) {
      margin-left: 9.7%;
    }

    img {
      margin-bottom: 10px;
    }

    p {
      font-size: 50px;
      color: #227af1;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    #projects {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: unset;
      align-items: center;

      margin-left: 5%;
    }

    .title-projects {
      img {
        height: 5vh;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
`;
