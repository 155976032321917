import React, { useState } from "react";

import { Container } from "./styles";
import Pagination from "../../../../components/Pagination";

import iconDonor from "../../../../assets/icons/Common/avatar-input.svg";
import iconDonor1 from "../../../../assets/icons/Project/donor-1.svg";
import iconDonor2 from "../../../../assets/icons/Project/donor-2.svg";
import iconDonor3 from "../../../../assets/icons/Project/donor-3.svg";

function DonorsContent({ data }) {
  const [currentPageDonations, setCurrentPageDonatios] = useState(1);
  const [countriesPerPageDonations] = useState(4);
  const paginateDonations = (number) => setCurrentPageDonatios(number);

  const indexLastCountriesDonations =
    currentPageDonations * countriesPerPageDonations;
  const indexFirstCountriesDonations =
    indexLastCountriesDonations - countriesPerPageDonations;
  const currentCountriesDonations = data.slice(
    indexFirstCountriesDonations,
    indexLastCountriesDonations
  );
  return (
    <Container>
      <p id="title-donors"> Apoiadores </p>
      <div className="info-doners">
        <div className="content-info-doners">
          <img src={iconDonor1} alt="" />
          <div>
            <p id="value"> {data.length} </p>
            <p> Doações </p>
          </div>
        </div>
        <div className="content-info-doners">
          <img src={iconDonor2} alt="" />
          <div>
            <p id="value">
              {
                data.filter(
                  (item) =>
                    item.perfil === "Apoiador/Visitante" ||
                    item.perfil === "Cientista/Pesquisador"
                ).length
              }
            </p>
            <p> Doações </p> <p> Individuais </p>
          </div>
        </div>
        <div className="content-info-doners">
          <img src={iconDonor3} alt="" />
          <div>
            <p id="value">
              {
                data.filter(
                  (item) => item.perfil === "Apoiador Empresarial/Desafiante"
                ).length
              }
            </p>
            <p> Doações </p> <p> Corporativas </p>
          </div>
        </div>
      </div>
      <div className="donors">
        {currentCountriesDonations.map((preview) => (
          <div className="donor" key={preview.id}>
            <div className="donor-content">
              <div className="icon">
                <div id="background-img">
                  <img src={iconDonor} alt="" />
                </div>
              </div>
              <div className="info-donor">
                <p id="day"> {preview.formattedDate} </p>
                <p> {preview.name} </p>
                {preview.description && (
                  <p
                    style={{
                      fontFamily: "GothamProRegular",
                      marginTop: 5,
                    }}
                  >
                    {preview.description}
                  </p>
                )}
              </div>
            </div>
            <section> </section>
          </div>
        ))}
      </div>
      {data.length > 0 && (
        <div className="floatPage">
          <Pagination
            currentPage={currentPageDonations}
            countriesPerPage={countriesPerPageDonations}
            totalCountries={data.length}
            paginate={paginateDonations}
          />
        </div>
      )}
    </Container>
  );
}

export default DonorsContent;
