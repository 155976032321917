import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
`;

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 20px 20px;
  /*Webkits*/
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /*Webkits*/

  border-bottom: 1px solid #eee;

  img {
    margin-top: 6px;
  }
  #title {
    color: #227af1;
    font-size: 23px;
    margin-left: 10px;
    margin-top: 6px;
  }
  @media screen and (max-width:440px) {
    #title {
      font-size: 1rem;
      word-wrap: break-word;
    }
  }

  @media screen and (max-width: 370px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px 0 20px 0;
     #title {
      font-size: 1rem;
      word-wrap: break-word;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px 12px 20px 20px;
  .normal-text {
    font-size: 14px;
    font-family: GothamProRegular;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .button {
    margin-top: 15px;

    width: 100%;
    height: 45px;
    border-radius: 30px;
    background-color: #227af1;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    &:hover {
      background: ${darken(0.1, "#227af1")};
    }
  }
`;
