import styled, { css } from 'styled-components';
import { darken } from 'polished'

export const Container = styled.ul `
    display:flex;
    flex-direction:row;
   
`;

export const Content = styled.div `
    align-self:flex-end;
`


export const Page = styled.li `

    width: 45px;
    height: 45px;
    border-radius: 5px;
    background-color: #d6d6e3;

    &:hover{
        background-color:${darken(0.1, '#d6d6e3')};
    }

    ${props => props.active && css`
        background-color: #227af1;

        &:hover{
            background-color:${darken(0.1, '#227af1')};
        }
    `}

    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:6px;

    cursor: pointer;

    p{
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
    }
  
`