import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;

  background: rgb(41, 118, 250);
  background: linear-gradient(
    90deg,
    rgba(41, 118, 250, 1) 22%,
    rgba(68, 169, 252, 1) 71%,
    rgba(75, 183, 253, 1) 95%
  );

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  #second-content-header {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    margin-bottom: 30px;

    #img-header {
      margin-left: 72px;
    }

    .circles {
      #first-circle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        position: absolute;
        overflow: visible;
        width: 684px;
        height: 684px;
        opacity: 0.05;

        border-radius: 50%;
        background-color: #ffffff;

        margin-top: -170px;
        margin-left: -400px;

        z-index: -0.8;
      }

      #second-circle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: visible;
        position: absolute;

        width: 408px;
        height: 408px;
        opacity: 0.06;

        border-radius: 50%;
        background-color: #ffffff;

        margin-top: -43px;
        margin-left: -285px;

        z-index: -0.8;
      }

      #third-circle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        position: absolute;
        overflow: visible;
        width: 192px;
        height: 192px;
        opacity: 0.06;

        border-radius: 50%;
        background-color: #ffffff;

        margin-top: 75px;
        margin-left: -180px;

        z-index: -0.8;
      }
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    #second-content-header {
      width: 100%;

      .circles {
        display: none;
      }
    }

    #img-header {
      display: none;
      margin-left: 0;
    }
  }
`;

export const ProjectContent = styled.section`
  margin-top: 49px;
  width: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  z-index: 1;

  #section-project {
    margin-top: 53px;
    margin-bottom: 77px;

    width: 1088px;
    height: 2px;
    background: #eee;
  }
`;

export const Subscribe = styled.section`
  margin-top: -60px;
  margin-bottom: 10px;
  width: 100%;
  height: 642px;
  background-color: #227af1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  #donation-content-1 {
    width: 352px;
    height: 243px;
    position: relative;
    margin-left: 130px;
    margin-top: 80px;

    #donation-text-1 {
      margin-bottom: 24px;

      font-size: 46px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    #donation-text-2 {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: relative;

      z-index: 1;

      width: 352px;
      height: 60px;
      border-radius: 30px;
      -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
      box-shadow: 0 0 7.5px 0 #45c6e5;
      background: rgb(106, 243, 255);
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(32%, rgba(106, 243, 255, 1)),
        to(rgba(138, 238, 197, 1))
      );
      background: -o-linear-gradient(
        left,
        rgba(106, 243, 255, 1) 32%,
        rgba(138, 238, 197, 1) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(106, 243, 255, 1) 32%,
        rgba(138, 238, 197, 1) 100%
      );

      font-family: GothamPro;
      font-size: 15.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;

      margin-bottom: 60px;
      margin-top: 60px;

      -webkit-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background: linear-gradient(
          90deg,
          ${darken(0.1, "rgba(106,243,255,1)")} 32%,
          ${darken(0.1, "rgba(138,238,197,1)")} 141%
        );
      }
    }

    #first-circle-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: absolute;
      overflow: visible;
      width: 654px;
      height: 654px;
      opacity: 0.05;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -420px;
      margin-left: -300px;

      z-index: -0.8;
    }

    #second-circle-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: visible;
      position: absolute;

      width: 418px;
      height: 418px;
      opacity: 0.06;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -303px;
      margin-left: -185px;

      z-index: -0.8;
    }

    #third-circle-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: absolute;
      overflow: visible;
      width: 212px;
      height: 212px;
      opacity: 0.06;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -195px;
      margin-left: -80px;

      z-index: -0.8;
    }
  }

  #donation-content-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    margin-top: 155px;
    margin-right: 162px;

    #title-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: -70px;

      #text-title-donation {
        margin-top: 12px;
        margin-left: 10px;

        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.5;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
    }

    #donation-image-content-2 {
      display: block;
      position: absolute;
      overflow: visible;
      margin-top: 178px;
      margin-left: 444.49px;

      width: 246px;
      height: 404px;
    }
  }

  #img-project {
    align-self: flex-end;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 105vh;
    margin: 0;

    margin-top: 25vh;

    #donation-content-1 {
      width: 80%;
      margin-left: 10%;

      > img {
        height: 5vh;
      }

      #donation-text-1 {
        font-size: 1.4rem;
      }
      #donation-text-2 {
        width: 100%;
        word-wrap: break-word;
        text-align: justify;
        font-size: 1rem;
      }
      button {
        width: 100%;
      }
    }

    #img-project {
      display: none;
    }
  }
`;

export const AboutProject = styled.section`
  display: flex;
  flex-direction: row;

  .first-content-about {
    width: 628px;
    margin-right: 108px;

    > p {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      word-wrap: break-word;
    }

    #img-content-about {
      width: 658px;
      height: 335px;

      align-self: center;

      border-radius: 15px;

      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: -15px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    #title {
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    .type-collaboration {
      width: 628px;
      height: 264px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      display: flex;

      padding-top: 38px;
      padding-left: 25px;
      margin-bottom: 26px;

      .type-content {
        margin-left: 29px;
        #title-type-content {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
          margin-bottom: 6px;
        }

        #text-type-content {
          width: 454px;

          margin-bottom: 18px;

          font-family: GothamProRegular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }

        #contact {
          width: 168px;
          height: 45px;
          border-radius: 30px;
          border: solid 0.5px #2976fa;
          background-color: #227af1;

          font-family: GothamProRegular;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          color: #ffffff;

          transition: all 0.3s ease-in-out 0s;
          &:hover {
            background: ${darken(0.1, "#227af1")};
          }
        }
      }
    }

    #section-first-content {
      margin-top: 40px;
      margin-bottom: 18px;

      width: 100%;
      height: 2px;
      background: #eee;
    }

    #prev-button {
      width: 168px;
      height: 60px;
      border-radius: 30px;
      border: solid 2px #2976fa;
      background: transparent;

      float: right;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #227af1;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background: ${darken(0.035, "#FFF")};
      }
    }
  }

  .second-content-about {
    width: 352px;
  }

  .buttons-content-about {
    width: 628px;
    height: 68px;
    border-radius: 50px;
    box-shadow: 0 0 5px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 15px;
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    flex-direction: column !important;

    .first-content-about {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 7vh;

      > p {
        margin-left: 5%;
        font-size: 1.2rem;
      }

      #img-content-about {
        width: 90%;
        margin-left: 5%;
      }

      .buttons-content-about {
        width: 90%;
        margin-left: 5%;
      }
    }

    .second-content-about {
      width: 100%;
    }
  }
`;

export const Button = styled.button`
  width: 140px;
  height: 45px;

  background-color: transparent;
  border: solid 1.5px #227af1;
  border-radius: 30px;

  font-family: GothamProRegular;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #227af1;

  transition: all 0.3s ease-in-out 0s;
  &:hover {
    border: solid 1.5px ${darken(0.1, "#227af1")};
    color: ${darken(0.1, "#227af1")};
    background-color: ${darken(0.05, "#fff")};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #227af1;
      color: #fff;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
        color: #fff;
      }
    `}

  @media only screen and (max-width:768px) {
    width: 24%;
    font-size: 0.65rem;

    word-wrap: break-word;
  }
`;

export const InfoProjectContainer = styled.div`
  margin-right: 320px;

  max-width: 100%;

  #title-project-container {
    width: 100%;

    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 19px;
    margin-left: 18px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    #title-project-container {
      font-size: 1.3rem;
      width: 85%;
    }
  }
`;

export const InfoProjectContent = styled.div`
  display: flex;
  margin-left: 23px;
  max-width: 100%;

  #people-icon {
    width: 16px;
    height: 16px;
    transform: translateY(0.5px);
  }

  #calendar-icon {
    width: 16px;
    height: 16px;
    transform: translateY(0.52px);
  }

  .first-content {
    margin-right: 59px;
    max-width: 50%;
    div {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;
      p {
        max-width: 100%;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        margin-left: 7px;
      }
    }
  }

  .second-content {
    max-width: 60%;
    div {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;
      p {
        max-width: 100%;
        font-size: 15.5px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        margin-left: 7px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-left: 0;

    padding-left: 18px;

    .first-content,
    .second-content {
      max-width: 100%;
      div {
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
`;
