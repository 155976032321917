import React from "react";

import { Textarea,Button } from './styles'

const Input = ({message,setMessage,sendMessage}) => (
  <form>
    <Textarea
      type="text"
      placeholder="Escrever mensagem..."
      value={message}
      onChange={(e) => setMessage(e.target.value)}
    />
    <Button onClick={(e)=>sendMessage(e)}>Enviar</Button>
  </form>
);

export default Input;
