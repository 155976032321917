import React, { useEffect, useMemo, useState } from "react";
//Libraries
import { Bounce } from "react-reveal";
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";

import api from "../../services/api";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Projects from "./components/Projects";
import AboutContent from "./components/AboutContent";
import DonorsContent from "./components/DonorsContent";
import MessagesContent from "./components/MessagesContent";
import NewContent from "./components/NewContent";
import Aside from "./components/Aside";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  Subscribe,
  AboutProject,
  Button,
  ProjectContent,
  InfoProjectContainer,
  InfoProjectContent,
} from "./styles";

// External Components
import { FaLockOpen } from "react-icons/fa";

// Icons
import iconPeople from "../../assets/icons/Project/people.svg";
import iconCompany from "../../assets/icons/Project/company.svg";
import iconId from "../../assets/icons/Project/id.svg";
import iconCheck from "../../assets/icons/Project/check.svg";
import iconCalendar from "../../assets/icons/Project/calendar.svg";

// Images
import imgHeader from "../../assets/images/Project/img-header.svg";
import imgProject from "../../assets/images/Project/second.svg";
import { Helmet } from "react-helmet";
import { store } from "../../store";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function Project({ match }) {
  const [project, setProject] = useState();
  const [projectDonations, setProjectDonations] = useState([]);
  const [member, setMember] = useState([]);
  const [loaded, setLoaded] = useState([]);
  const [totalScientistWhuffies, setTotalScientistWhuffies] = useState([]);
  const [depositions, setDepositions] = useState([]);
  const [news, setNews] = useState([]);

  const id = useMemo(
    () => ({
      value: match.params.id,
    }),
    [match.params.id]
  );

  useEffect(() => {
    async function loadingProduct() {
      try {
        const { data } = await api.get(`project/${id.value}`);
        setProject({
          ...data.project,
        });

        var inital = data.arr.filter((member) => member !== null);

        var notRepetead = inital.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));

        setMember(notRepetead);

        if (!data.project.special) {
          const res = await api.get(
            `scientists-total-whuffies/${
              data.project.institution.split(";")[0]
            }`
          );

          setTotalScientistWhuffies(res.data);
        }
      } catch (e) {
        console.log("");
      }
    }
    loadingProduct();
  }, [id]);

  useEffect(() => {
    async function loadingProduct() {
      try {
        const { data } = await api.get(`rich-projects/${id.value}`);
        setLoaded(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingProduct();
  }, [id]);

  useEffect(() => {
    async function getProjectDonations() {
      const res = await api.get(`payments-project/${id.value}`);
      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setProjectDonations(data);
    }
    getProjectDonations();
  }, [id]);

  useEffect(() => {
    async function getDepositions() {
      const res = await api.get(`depositions/${id.value}`);

      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));
      setDepositions(data);
    }
    getDepositions();
  }, [id]);

  useEffect(() => {
    async function projectsNews() {
      const res = await api.get(`project-news/${id.value}`);

      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));
      setNews(data);
    }
    projectsNews();
  }, [id]);

  const [buttonAbout, setButtonAbout] = useState(() => true);
  const [buttonNew, setButtonNew] = useState(() => false);
  const [buttonDoners, setButtonDoners] = useState(() => false);
  const [buttonMessages, setButtonMessages] = useState(() => false);

  function handleAboutClick() {
    if (buttonAbout === false) {
      setButtonAbout(!buttonAbout);

      if (buttonNew === true) {
        setButtonNew(!buttonNew);
      }
      if (buttonDoners === true) {
        setButtonDoners(!buttonDoners);
      }
      if (buttonMessages === true) {
        setButtonMessages(!buttonMessages);
      }
    }
  }

  function handleNewClick() {
    if (buttonNew === false) {
      setButtonNew(!buttonNew);

      if (buttonAbout === true) {
        setButtonAbout(!buttonAbout);
      }
      if (buttonDoners === true) {
        setButtonDoners(!buttonDoners);
      }
      if (buttonMessages === true) {
        setButtonMessages(!buttonMessages);
      }
    }
  }

  function handleDonersClick() {
    if (buttonDoners === false) {
      setButtonDoners(!buttonDoners);

      if (buttonNew === true) {
        setButtonNew(!buttonNew);
      }
      if (buttonAbout === true) {
        setButtonAbout(!buttonAbout);
      }
      if (buttonMessages === true) {
        setButtonMessages(!buttonMessages);
      }
    }
  }

  function handleMessagesClick() {
    if (buttonMessages === false) {
      setButtonMessages(!buttonMessages);

      if (buttonNew === true) {
        setButtonNew(!buttonNew);
      }
      if (buttonDoners === true) {
        setButtonDoners(!buttonDoners);
      }
      if (buttonAbout === true) {
        setButtonAbout(!buttonAbout);
      }
    }
  }

  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const history = useHistory();

  function checkIsAuth(type) {
    if (!!token) {
      if (
        perfil === "Cientista/Pesquisador" &&
        profile.id === project.scientist_id &&
        type === "colaboração"
      ) {
        toast.error("Você é o dono do projeto!");
      } else {
        type === "colaboração"
          ? setTimeout(function () {
              history.push(`/collaboration/${project && project.id}`);
            }, 50)
          : project.current_value >= project.value_goal
          ? toast.error("O projeto já atingiu a meta!")
          : setTimeout(function () {
              history.push(`/payment-project/${project && project.id}`);
            }, 50);
      }
    } else {
      toast.error(`É necessário estar logado para fazer uma ${type}!`);
      setTimeout(function () {
        history.push("/register");
      }, 2300);
    }
  }

  return (
    <>
      <Helmet>
        <title>{project ? project.name : "We4Science"}</title>
      </Helmet>
      <Bounce bottom duration={2000} distance="20px">
        <Wrapper>
          <Content marginProject>
            <Header>
              <SubHeader />
              <section id="second-content-header">
                <img id="img-header" src={imgHeader} alt="" />
                <InfoProjectContainer>
                  <p id="title-project-container">
                    {" "}
                    {project && project.name}{" "}
                  </p>
                  <InfoProjectContent>
                    <div className="first-content">
                      <div>
                        <img id="people-icon" src={iconPeople} alt="" />
                        <p> Autor: {project && project.scientist.name} </p>
                      </div>
                      {project && project.special ? (
                        <div>
                          <FaLockOpen color="#fff" size={18} />

                          <p>Tenho Permissão Especial</p>
                        </div>
                      ) : (
                        <div>
                          <img id="people-icon" src={iconCompany} alt="" />
                          <p>
                            Instituição:{" "}
                            {project && project.institution.split(";")[0]}
                          </p>
                        </div>
                      )}

                      <div>
                        <img id="people-icon" src={iconId} alt="" />
                        <p> ID do projeto: {project && project.id} </p>
                      </div>
                    </div>
                    <div className="second-content">
                      <div>
                        <img id="people-icon" src={iconCheck} alt="" />
                        <p> Categoria: {project && project.category} </p>
                      </div>
                      {/*
                      <div>
                        <img id="calendar-icon" src={iconCalendar} alt="" />
                        <p> Data de início: {project && project.start} </p>
                      </div>
                      */}
                      <div>
                        <img id="calendar-icon" src={iconCalendar} alt="" />
                        <p> Data de término: {project && project.end} </p>
                      </div>
                    </div>
                  </InfoProjectContent>
                </InfoProjectContainer>
                <div className="circles">
                  <div id="first-circle"> </div> <div id="second-circle"> </div>
                  <div id="third-circle"> </div>
                </div>
              </section>
            </Header>
            <ProjectContent>
              <AboutProject>
                <div className="first-content-about">
                  <p> {project && project.summary} </p>
                  {project && project.first&& <img
                    id="img-content-about"
                    src={project && project.first.url}
                    alt=""
                  />}
                  <div className="buttons-content-about">
                    <Button active={buttonAbout} onClick={handleAboutClick}>
                      Sobre
                    </Button>
                    <Button active={buttonDoners} onClick={handleDonersClick}>
                      Apoiadores
                    </Button>
                    <Button
                      active={buttonMessages}
                      onClick={handleMessagesClick}
                    >
                      Depoimentos
                    </Button>
                    <Button active={buttonNew} onClick={handleNewClick}>
                      Novidades
                    </Button>
                  </div>
                  {/* Components */}
                  {buttonAbout && (
                    <AboutContent data={project} member={member} />
                  )}
                  {buttonDoners && <DonorsContent data={projectDonations} />}
                  {buttonMessages && (
                    <MessagesContent
                      data={depositions}
                      projectId={id.value}
                      scientistId={project && project.scientist_id}
                    />
                  )}
                  {buttonNew && <NewContent data={news} />}
                </div>

                <Aside
                  project={project}
                  loaded={loaded}
                  whuffies={totalScientistWhuffies.total}
                />
              </AboutProject>
              <section id="section-project"> </section>
              <Projects
                id={project && project.id}
                category={project && project.category}
                scientistId={project && project.scientist_id}
              />
              <Subscribe>
                <div id="donation-content-1">
                  <p id="donation-text-1">
                    INSCREVA <br /> SEU PROJETO
                  </p>
                  <p id="donation-text-2">
                    Ao inscrever um breve e simples resumo do seu projeto ou
                    pesquisa científica gratuitamente em nossa plataforma, você
                    abre um vasto horizonte para a captação de recursos
                    financeiros, intercâmbio de conhecimentos, busca de
                    parceiros institucionais nacionais e internacionais, além de
                    impulsionar sua carreira profissional!
                  </p>
                  <button onClick={() => checkIsAuth("doação")}>
                    {" "}
                    Faça uma doação agora{" "}
                  </button>
                  <div id="first-circle-donation"> </div>
                  <div id="second-circle-donation"> </div>
                  <div id="third-circle-donation"> </div>
                </div>
                <img id="img-project" src={imgProject} alt="" />
              </Subscribe>
            </ProjectContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Project;
