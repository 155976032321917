import styled from 'styled-components';
import { darken } from 'polished'


export const Wrapper = styled.div `
    height: 100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family: GothamPro;
    @media only screen and (max-width:768px){
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const Content = styled.div `
    width:1366px;
    height: 100%;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    @media only screen and (max-width:768px){
        width:100vw;
    }
`

export const Header = styled.header `
    width:100%;
    height: 200px;

    background: rgb(41,118,250);
    background: linear-gradient(90deg, rgba(41,118,250,1) 22%, rgba(68,169,252,1) 71%, rgba(75,183,253,1) 95%);

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
      
    #title{
        font-size: 45px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        margin-bottom:10px;
        margin-top:10px;
    }
    

    @media only screen and (max-width: 849px) {
        padding-top:10px;
    }

    @media only screen and (max-width:768px){
        width:100vw;

        #title{
            font-size:35px;
        }
    }
`

export const NotificationContent = styled.section `
    height:458px;
    width:100%;

    display:flex;
    flex-direction:column;
    align-items:center;
    @media only screen and (max-width:768px){
        height:458px;
    }
`

export const Notification = styled.div`
    width:90%;
    height: 140px;
    border-radius:10px;
    border:1px solid #eee;
    margin:10px 0 10px 0;
    padding:15px;

    align-self:center;

    margin-left:5%;

    display:flex;
    align-items:center;
    justify-content:space-between;

    .fs-content{
        #title{
            color:#227af1;
            margin-bottom:10px;
        }
        #content{
            margin-bottom:10px;
            font-family:GothamProRegular;
            word-wrap: break-word;
            word-break:break-all;
        }
        a{
            color:#000;
        }
    }
    .sec-content{
        width:25%;
        height: 50%;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
       button{
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;

        margin-right:16px;

        background:transparent;
        color: #227af1;

        transition: all .3s ease-in-out 0s;

        &:hover{
            color:${darken(0.1, '#227af1')};
        }
       }
    }

    @media only screen and (max-width:768px){
        p{
            font-size:0.8rem !important;
        }

        button{
            font-size:0.8rem !important;
        }

        a{
            font-size:0.8rem !important;
        }
    }
`
