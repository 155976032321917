import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import imgFirst from "../../../../assets/images/Donors/second.svg";

function First() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column flexEnd>
            <ImgWrapper>
              <Img src={imgFirst} alt="" />
            </ImgWrapper>
          </Column>
          <Column>
            <h1>Quem pode ser um apoiador?</h1>
            <p id="text-fs-content">
              Para ser um apoiador ou uma apoiadora científica, você só precisa
              ser capaz de enxergar a importância da Ciência para todas as
              pessoas, e para isso você não precisa sequer entender sobre
              Ciência ou ser um cientista.
              <br />
              <br />
              Você também irá descobrir que pode apoiar a Ciência mesmo sem
              fazer doações em dinheiro, apenas colaborando com seus
              conhecimentos ou simplesmente compartilhando os projetos
              cadastrados aqui para os seus amigos nas redes sociais, ajudando a
              aumentar e fortalecer nossa grande comunidade de apoiadores
              científicos.
            </p>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default First;
