import React from "react";

import { Container, CompanyInfo, ProductInfo } from "./styles";

import {
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

// Icons
import carStore from "../../../../assets/icons/Common/car-store.svg";
import { useDispatch } from "react-redux";

import { store } from "../../../../store";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import api from "../../../../services/api";

//Redux
import { debitRequest } from "../../../../store/modules/user/actions";

function Aside({ product }) {
  const { token, perfil } = store.getState().auth;
  const { profile } = store.getState().user;
  const history = useHistory();

  function completeLink(link) {
    var finalLink;
    if (link !== null) {
      if (
        link.substring(0, 7) === "http://" ||
        link.substring(0, 8) === "https://"
      ) {
        finalLink = link;
      } else {
        finalLink = "https://www." + link;
      }
      return finalLink;
    } else {
      return link;
    }
  }

  const dispath = useDispatch();

  function checkIsAuth() {
    if (!!token) {
      return true;
    } else {
      toast.error("É necessário estar logado!");
      return false;
    }
  }

  function isNotOwner() {
    if (
      perfil === "Apoiador Empresarial/Desafiante" &&
      profile.id === product.company.id
    ) {
      toast.error("Você é o dono do Produto!");
      return false;
    } else {
      return true;
    }
  }

  function available() {
    if (parseInt(product.current_quantity) > 0) {
      return true;
    } else {
      toast.error("Produto Indisponível!");
      return false;
    }
  }

  function checkIsBalance() {
    if (profile.whuffie >= parseFloat(product.value)) {
      return true;
    } else {
      toast.error("Moedas Whuffie insuficientes!");
      return false;
    }
  }

  function checkIsCompleteData() {
    if (
      profile.adress === null ||
      profile.neighborhood === null ||
      profile.state === null ||
      profile.city === null ||
      profile.cep === null
    ) {
      toast.error("Atualize seus dados!");

      setTimeout(() => {
        history.push("/profile");
      }, 2800);
    }
  }

  async function handleShop() {
    const auth = checkIsAuth();
    const owner = isNotOwner();
    const stock = available();
    const whuffie = checkIsBalance();

    checkIsCompleteData();

    if (auth && owner && stock && whuffie) {
      const data = Object.assign({
        perfil,
        id: profile.id,
        value: parseFloat(product.value),
      });

      dispath(debitRequest(data));

      try {
        const userData = Object.assign({
          perfil,
          user: profile.id,
          product_id: product.id,
          name: profile.name,
          email: profile.email,
          phone: profile.phone,
          adress: profile.adress,
          city: profile.city,
          cep: profile.cep,
          neighborhood: profile.neighborhood,
          state: profile.state,
        });

        await api.post("shopping", userData);
        toast.success("Produto comprado, o responsável irá entrar em contato!");

        setTimeout(() => {
          history.go(0);
        }, 2500);
      } catch (error) {
        toast.error("Erro ao comprar Produto!");
      }
    }
  }
  return (
    <Container>
      <ProductInfo>
        <div className="product-content-2">
          <div className="title-info">
            <p id="info-text">Informações Gerais</p>
          </div>

          <div className="description">
            <p id="infos-description">Tipo: {product && product.category}</p>
            <p id="infos-description">
              Unidades disponíveis: {product && product.current_quantity}
            </p>
          </div>

          <p id="price-text-1">Valor:</p>
          <div className="product-price">
            <p id="price-value">{product && product.value}</p>
            <p id="price-value-2">,00</p>
            <p id="price-text">Whuffies</p>
          </div>
        </div>

        <div id="product-content-3">
          <div className="content">
            <img id="car-icon" src={carStore} alt="" />
            <button id="product-link" onClick={handleShop}>
              Quero este!
            </button>
          </div>
        </div>
      </ProductInfo>

      <CompanyInfo>
        <div className="title-company">
          <p id="company-name">Empresa Ofertante</p>
        </div>

        <div className="logo-company">
          <div className="content-img">
            <img
              src={
                product &&
                product.company.avatar !== null &&
                product.company.avatar.url
              }
              alt=""
            />
          </div>
        </div>

        <div className="social-networks">
          <p id="title-social-networks">Redes sociais:</p>
          <div className="icons">
            {product && product.company.instagram && (
              <a
                href={product && completeLink(product.company.instagram)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram
                  title={product && `${product.company.instagram}`}
                  size={21}
                  color="#7f7fb9"
                />
              </a>
            )}
            {product && product.company.twitter && (
              <a
                href={product && completeLink(product.company.twitter)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter
                  title={product && `${product.company.twitter}`}
                  size={21}
                  color="#7f7fb9"
                />
              </a>
            )}
            {product && product.company.facebook && (
              <a
                href={product && completeLink(product.company.facebook)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF
                  title={product && `${product.company.facebook}`}
                  size={19}
                  color="#7f7fb9"
                />
              </a>
            )}
            {product && product.company.linkedin && (
              <a
                href={product && completeLink(product.company.linkedin)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn
                  title={product && `${product.company.linkedin}`}
                  size={21}
                  color="#7f7fb9"
                />
              </a>
            )}

            {product && product.company.youtube && (
              <a
                href={product && completeLink(product.company.youtube)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube
                  title={product && `${product.company.youtube}`}
                  size={21}
                  color="#7f7fb9"
                />
              </a>
            )}
          </div>
        </div>

        <div className="adress">
          <p id="title-adress-networks">Website:</p>
          <a
            id="text-adress"
            target="_blank"
            rel="noopener noreferrer"
            href={
              product &&
              product.company &&
              completeLink(product.company.website)
            }
          >
            {product && product.company && product.company.website}
          </a>
        </div>
      </CompanyInfo>
    </Container>
  );
}

export default Aside;
