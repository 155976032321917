import React, { useMemo } from "react";

import Header from "./container/Header";
import First from "./container/First";
import Second from "./container/Second";
import Third from "./container/Third";

import Footer from "../../components/Footer";
import useProject from "../../hooks/useProject";
import { Helmet } from "react-helmet";

function Payment({ match }) {
  const id = useMemo(
    () => ({
      value: match.params.id,
    }),
    [match.params.id]
  );

  const { project } = useProject(parseInt(id.value));

  return (
    <>
      <Helmet>
        <title>Fazer uma Doação</title>
      </Helmet>
      <Header name={project && project.name} id={parseInt(id.value)} />
      <First id={parseInt(id.value)} />
      <Second
        id={project && project.id}
        category={project && project.category}
        scientist_id={project && project.scientist_ids}
      />
      <Third />
      <Footer />
    </>
  );
}

export default Payment;
