import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 120px 0 30px 0;
  background: #fff;
  @media screen and (max-width: 500px) {
    padding: 40px 0;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  flex-direction: row;
  @media screen and (max-width: 1074px) {
    flex-direction: column-reverse;
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;

  flex-basis: 50%;

  ${(props) =>
    props.larger &&
    css`
      align-items: flex-end;
      max-width: 66.6%;
    `}

  ${(props) =>
    !props.larger &&
    css`
      max-width: 33.3%;
    `}


  > h1 {
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    text-transform: uppercase;
  }

  .img-1 {
    margin-bottom: 24px;
    margin-top: 80px;
    width: 50px;
    height: 45px;
  }

  #science-2 {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 40px;
    margin-top: 11px;
  }

  #second {
    display: flex;
    flex-direction: row;
  }

  .content-products {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 1074px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;

    > h1 {
      font-size: 1.5rem;
    }

    #science-2 {
      text-align: justify;
    }
  }

  ${(props) =>
    props.larger &&
    css`
      max-width: 100%;
      @media screen and (max-width: 768px) {
        #second {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    `}
`;

export const ChangeProduct = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-start;
  margin-bottom: 40px;

  .paginate-img {
    background: transparent;
    width: 47.2px;
    height: 47.2px;

    border-radius: 50%;

    transition: all 0.3s ease-in-out 0s;

    cursor: pointer;

    &:hover {
      background-color: ${darken(0.1, "#fff")};
    }

    &:disabled {
      opacity: 0.6;
      background-color: ${darken(0.1, "#FFF")};
      cursor: not-allowed;
    }
  }

  #margin {
    margin-right: 40px;
  }
`;
