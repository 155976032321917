import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 211px;

  background: #227af1;

  .content-header {
    width: 100%;
    height: 137px;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-content {
      height: 37.2px;
      display: flex;
      align-items: center;

      margin-bottom: 35px;
      margin-left: 507px;

      #title {
        margin-top: 9px;
        margin-left: 17.2px;

        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;

        text-transform: uppercase;
      }
    }

    #img-header {
      transform: translateY(-18.5px);
      margin-left: 157px;
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .content-header {
      width: 100%;
      .title-content {
        margin-left: 0;
        #title {
          font-size: 1.5rem;
        }

        #icon-title {
          width: 36.4px;
          height: 32.8px;
        }
      }

      #img-header {
        display: none;
        margin-left: 0;
      }
    }
  }
`;