import styled from 'styled-components';
import { darken } from 'polished'

export const Wrapper = styled.div `
    height: 100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family: GothamPro;
    @media only screen and (max-width:768px){
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;


export const Content = styled.div `
    width:1366px;
    height: 100vh;
    background-color:#f0f9ff; 
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-right:100px;
    .box{
        height: 300.4px;
        width:420px;
        display:flex;
        flex-direction:column;
        margin-left:320px;
        .message{
            display:flex;
            flex-direction:row;
            #lines{
                margin-top:-60px;
                margin-left:-25px;
            }
            #ops-text{
                font-family: GothamPro;
                font-size: 56.5px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.32;
                letter-spacing: normal;
                text-align: left;
                color: #160b38;
            }
        }
        #justify{
            margin-top:10px;
            width: 280px;
           
            font-family: GothamProRegular;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            text-align: left;
            color: #160b38;
        }
        #btn-home{
            margin-top:25px;
            margin-left:30px;
            width: 200px;
            height: 70px;
            border-radius:15px  15px 0 15px;
            background-color: #8054f6;
        
            transition: all .3s ease-in-out 0s;
            z-index:1;
            font-family: GothamPro;
            font-size: 15.5px;
            font-weight: bold;
            line-height: 1.5;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            &:hover{
                background-color: ${darken(0.1,'#8054f6')};
            }
        }
    }
    #img-error{
        width: 659px;
        height: 650.2px;
    }
    #shape{
        display:block;
        position: absolute;
        align-self:flex-end;
        height: 700px;
    }
    #logo{
        display:block;
        position: absolute;
        background-color:#aadcff;
        height: 115px;
        width:300px;
        border-radius:30px;
        margin-top:20px;
        margin-left:20px;
        padding-top:15px;
        padding-left:15px;
        z-index:1;
        cursor: pointer;
        img{
            align-self:center;
        }
    
        align-self:flex-start;
    }

    @media only screen and (max-width:768px){
        width:100vw;
        height: 100vh;
        display:flex;
        flex-direction:column-reverse;
        justify-content:center;
        align-items:center;

        padding-right:0;

        #img-error{
            width: 80vw;
            height: 85vh;
            margin:0;
            margin-bottom:8vh;
        }

        #logo{
            display:none;
            margin:0;
        }

        #shape{
            display:none;
            margin:0;
        }

        .box{
            margin:0;
            width:80%;
            height: 40vh;
            align-self:center;
            margin-left:10%;
            margin-bottom:10vh;

            .message{
                width:100%;

                #ops-text{
                    font-size:2rem;
                }
            }

            #justify{
                width:100%;
                font-size:1.2rem;
            }

            #btn-home{
                margin-left:0;
               width:80%;
            }
        }
    }
`