import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 352px;
  border-radius: 10px;
  height: 616px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.08);
  margin-top: 20px;

  margin-right: ${({ margin }) => (margin ? "18px" : "0")};

  z-index: 1;

  @media screen and (max-width: 472px) {
    width: 95%;
  }
`;

export const First = styled.div`
  width: 100%;
  height: 170px;
  border-radius: 10px 10px 0 0;
  background: #fafaff;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    border-radius: 10px;
    height: 100%;
    object-fit: contain;
  }
`;
export const Second = styled.div`
  width: 100%;
  height: 153.6px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 29px;
  padding-top: 13px;

  background: #fff;

  #title-project {
    max-width: 100%;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3;
    color: #227af1;
  }

  .box-infos {
    width: 100%;
    margin-top: 2.5px;
    display: flex;
    align-items: center;

    p {
      margin-left: 7px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }
  }

  @media screen and (max-width: 472px) {
    padding-left: 14px;
    .box-infos {
      p {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 375px) {
    padding: 0 9px;
    .box-infos {
      p {
        font-size: 12px;
      }
    }
  }
`;
export const Third = styled.div`
  width: 100%;
  height: 132px;
  padding-left: 29px;
  padding-right: 8px;
  padding-top: 10px;

  background: #fff;

  border-bottom: 1px solid #eee;

  p {
    width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    word-wrap: break-word;
  }

  @media screen and (max-width: 472px) {
    padding-left: 14px;
    p {
      line-height: 1.3;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 375px) {
    padding: 0 9px;
  }
`;
export const Fourth = styled.div`
  width: 100%;
  height: 99.6px;
  padding-left: 29px;
  padding-right: 22px;

  background: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .values {
    #meta {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #d6d6e3;
    }
    .donation {
      display: flex;
      align-items: baseline;

      p {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      #donation-value {
        margin-left: 5px;
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 472px) {
    padding-left: 14px;
    .values {
      #meta {
        font-size: 12px;
        line-height: 1.3;
      }
    }

    .donation {
      p {
        font-size: 12px;
        line-height: 1.3;
      }
    }
  }
  @media screen and (max-width: 375px) {
    padding: 0 9px;
    .values {
      .donation {
        #donation-value {
          font-size: 15px;
        }
      }
    }
  }
`;

export const Fifth = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 0 0 10px 10px;
  background: rgb(41, 118, 250);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(54%, rgba(41, 118, 250, 1)),
    to(rgba(68, 169, 252, 1))
  );
  background: -o-linear-gradient(
    left,
    rgba(41, 118, 250, 1) 54%,
    rgba(68, 169, 252, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(41, 118, 250, 1) 54%,
    rgba(68, 169, 252, 1) 100%
  );

  display: flex;
  align-items: center;

  padding-left: 29px;

  a {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-left: 20px;

    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      color: ${darken(0.1, "#FFF")};
    }
  }
  @media screen and (max-width: 375px) {
    padding: 0 9px;
  }
`;
