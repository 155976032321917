import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Container } from "../../../../styles/global";
import { Wrapper, Row, Column, Donation, Img, ImgWrapper } from "./styles";

import donationIcon from "../../../../assets/icons/Home/donation-icon.svg";
import peopleDonation from "../../../../assets/icons/Common/people-donation.svg";
import companyDonation from "../../../../assets/icons/Common/company-donation.svg";
import donation from "../../../../assets/images/Home/donation-2-2.svg";

import api from "../../../../services/api";

function First() {
  const history = useHistory();
  const [loadedCompanies, setLoadedCompanies] = useState([]);
  const [loadedPeoples, setLoadedPeoples] = useState([]);

  useEffect(() => {
    const loadeds = async () => {
      const { data } = await api.get("/rich-companies");

      !!data && setLoadedCompanies(data);
    };
    loadeds();
  }, []);

  useEffect(() => {
    const loadedsRichs = async () => {
      const resOne = await api.get("/rich-scientists");

      const resTwo = await api.get("/rich-supporters");

      if (resOne.data !== null && resTwo.data !== null) {
        var aux = resOne.data.concat(resTwo.data);

        var auxTwo = [];

        aux = aux.sort((a, b) =>
          a.whuffie < b.whuffie
            ? 1
            : a.whuffie === b.whuffie
            ? a.size > b.size
              ? 1
              : -1
            : -1
        );

        for (let index = 0; index < 5; index++) {
          if (aux[index] !== undefined && aux[index] !== null) {
            auxTwo.push(aux[index]);
          }
        }

        setLoadedPeoples(auxTwo);
      }
    };
    loadedsRichs();
  }, []);

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <img className="img-1" src={donationIcon} alt="" />
            <h1> Ranking de Apoiadores </h1>
            <p id="donation-text-2">
              Aqui prestamos homenagem aos maiores apoiadores da Ciência em
              nossa plataforma!
              <br />
              <br />
              Que tal colocar seu nome ou o de sua empresa nessa nobre lista de
              heróis da Ciência e do progresso?
            </p>
            <button onClick={() => history.push("/projects")}>
              {" "}
              Apoie um projeto científico agora{" "}
            </button>
            <div id="first-circle-donation">
              <div id="second-circle-donation">
                <div id="third-circle-donation"> </div>
              </div>
            </div>
          </Column>
          <Column larger>
            <div className="donation-content-2">
              <Donation id="margin">
                <div id="title-donation">
                  <img src={peopleDonation} alt="" />
                  <p id="text-title-donation"> PESSOAS </p>
                </div>
                <ul>
                  {loadedPeoples !== null &&
                    loadedPeoples.length > 0 &&
                    loadedPeoples.map((preview) => (
                      <li
                        id="list-people-donation"
                        key={loadedPeoples.indexOf(preview)}
                      >
                        <div id="position-people">
                          <p> {loadedPeoples.indexOf(preview) + 1} º </p>
                        </div>
                        <div id="data-people">
                          <p>
                            {preview.name.split(" ")[1] === undefined
                              ? preview.name.split(" ")[0]
                              : preview.name.split(" ")[0] +
                                " " +
                                preview.name.split(" ")[1]}
                          </p>
                          <div>
                            <p id="cash"> {preview.whuffie} </p>
                            <p id="dot"> W$ </p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Donation>

              <Donation>
                <div id="title-donation">
                  <img src={companyDonation} alt="" />
                  <p id="text-title-donation"> EMPRESAS </p>
                </div>
                <ul>
                  {loadedCompanies !== null &&
                    loadedCompanies.length > 0 &&
                    loadedCompanies.map((preview) => (
                      <li
                        id="list-people-donation"
                        key={loadedCompanies.indexOf(preview)}
                      >
                        <div id="position-people">
                          <p> {loadedCompanies.indexOf(preview) + 1} º </p>
                        </div>
                        <div id="data-people">
                          <p>
                            {" "}
                            {preview.name.split(" ")[1] === undefined
                              ? preview.name.split(" ")[0]
                              : preview.name.split(" ")[0] +
                                " " +
                                preview.name.split(" ")[1]}{" "}
                          </p>
                          <div>
                            <p id="cash"> {preview.whuffie} </p>{" "}
                            <p id="dot"> W$ </p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Donation>

              <ImgWrapper>
                <Img src={donation} alt="Image"></Img>
              </ImgWrapper>
            </div>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default First;
