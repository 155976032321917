import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// Libraries
import { Link } from "react-router-dom";
import { Bounce } from "react-reveal";
import api from "../../services/api";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import { toast } from "react-toastify";
// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  BlogContent,
  New,
  NewAside,
  Categories,
} from "./styles";

// Icons
import newsPaper from "../../assets/icons/Blog/jornal.svg";
import newsPaperAside from "../../assets/icons/Blog/jornal-sidebar.svg";
import iconCategory from "../../assets/icons/Blog/category.svg";
import iconSearch from "../../assets/icons/Blog/icon-search.svg";
import iconPlay from "../../assets/icons/Blog/play.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";

// Images
import imgHeader from "../../assets/images/Blog/header-img.svg";
import shadowImg from "../../assets/images/Blog/shadow.svg";
import { store } from "../../store";

import NewsletterCard from "./components/NewsletterCard";
import ArticleCard from "./components/ArticleCard";

function Blog() {
  const [lastNew, setLastNew] = useState();
  const [news, setNews] = useState([]);
  const [newsSide, setNewsSide] = useState([]);
  const [filter, setFilter] = useState([]);
  const [subscriveNewsletter, setSubscribeNewsletter] = useState(false);

  const { perfil, signed } = store.getState().auth;
  const { profile } = store.getState().user;

  useEffect(() => {
    async function loadingLastNew() {
      try {
        const { data } = await api.get(`last-new`);
        setLastNew({ ...data });
      } catch (e) {
        console.log("");
      }
    }
    loadingLastNew();
  }, []);

  useEffect(() => {
    async function checkNewsletter() {
      if (signed) {
        const type =
          perfil === "Apoiador/Visitante"
            ? 1
            : perfil === "Apoiador Empresarial/Desafiante"
            ? 2
            : 3;

        const { data } = await api.get(
          `/check-newsletter/${profile && profile.id}/${type}`
        );
        setSubscribeNewsletter(data.register);
      }
    }
    checkNewsletter();
  }, [perfil, profile, signed]);

  useEffect(() => {
    async function loadingNews() {
      try {
        const res = await api.get("news");

        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setNews(data);
        setFilter(data);

        var auxAsideNews = [];

        for (let index = 0; index < 3; index++) {
          if (data[index] !== null && data[index] !== undefined) {
            auxAsideNews.push(data[index]);
          }
        }

        setNewsSide(auxAsideNews);
      } catch (e) {
        console.log("");
      }
    }
    loadingNews();
  }, [lastNew]);

  const paginate = (number) => setCurrentPage(number);

  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(6);
  const indexLastCountries = currentPage * countriesPerPage;
  const indexFirstCountries = indexLastCountries - countriesPerPage;
  const currentCountries = news.slice(indexFirstCountries, indexLastCountries);

  const [filterOne, setFilterOne] = useState(false);
  const [filterTwo, setFilterTwo] = useState(false);
  const [filterThree, setFilterThree] = useState(false);
  const [filterFour, setFilterFour] = useState(false);
  const [filterFive, setFilterFive] = useState(false);

  const toggleOne = () => {
    if (filterOne) {
      setNews(filter);
    } else {
      setNews(filter);
      if (news.filter((item) => item.category === "Ciência").length > 0) {
        setNews(news.filter((item) => item.category === "Ciência"));
        toast.success("Filtro selecionado!");
      } else {
        toast.error("Nenhum item encontrado!");
      }
    }

    filterOne ? setFilterOne(false) : setFilterOne(true);
    setFilterTwo(false);
    setFilterThree(false);
    setFilterFour(false);
    setFilterFive(false);
  };

  const toggleTwo = () => {
    if (filterTwo) {
      setNews(filter);
    } else {
      setNews(filter);
      if (
        news.filter((item) => item.category === "Projetos em destaque").length >
        0
      ) {
        setNews(
          news.filter((item) => item.category === "Projetos em destaque")
        );
        toast.success("Filtro selecionado!");
      } else {
        toast.error("Nenhum item encontrado!");
      }
    }

    filterTwo ? setFilterTwo(false) : setFilterTwo(true);
    setFilterOne(false);
    setFilterThree(false);
    setFilterFour(false);
    setFilterFive(false);
  };

  const toggleThree = () => {
    if (filterThree) {
      setNews(filter);
    } else {
      setNews(filter);
      if (news.filter((item) => item.category === "Instituições").length > 0) {
        setNews(news.filter((item) => item.category === "Instituições"));
        toast.success("Filtro selecionado!");
      } else {
        toast.error("Nenhum item encontrado!");
      }
    }

    filterThree ? setFilterThree(false) : setFilterThree(true);
    setFilterOne(false);
    setFilterTwo(false);
    setFilterFour(false);
    setFilterFive(false);
  };

  const toggleFour = () => {
    if (filterFour) {
      setNews(filter);
    } else {
      setNews(filter);
      if (news.filter((item) => item.category === "Pesquisa").length > 0) {
        setNews(news.filter((item) => item.category === "Pesquisa"));
        toast.success("Filtro selecionado!");
      } else {
        toast.error("Nenhum item encontrado!");
      }
    }

    filterFour ? setFilterFour(false) : setFilterFour(true);
    setFilterOne(false);
    setFilterTwo(false);
    setFilterThree(false);
    setFilterFive(false);
  };

  const toggleFive = () => {
    if (filterFive) {
      setNews(filter);
    } else {
      setNews(filter);

      if (news.filter((item) => item.category === "Institucional").length > 0) {
        setNews(news.filter((item) => item.category === "Institucional"));
        toast.success("Filtro selecionado!");
      } else {
        toast.error("Nenhum item encontrado!");
      }
    }

    filterFive ? setFilterFive(false) : setFilterFive(true);
    setFilterOne(false);
    setFilterTwo(false);
    setFilterThree(false);
    setFilterFour(false);
  };

  const search = (word) => {
    setNews(filter);
    if (news.filter((item) => item.title === `${word}`).length !== 0) {
      setNews(news.filter((item) => item.title === `${word}`));
      toast.success("Pesquisa realizada!");
    } else {
      toast.error("Nenhum item encontrado!");
    }
  };

  const [searchNew, setSearchNew] = useState("");

  return (
    <>
      <Helmet>
        <title>Radar</title>
      </Helmet>
      <Bounce top duration={1600} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section className="second-content-header">
                <img id="icon-header" src={newsPaper} alt="" />
                <p id="title">RADAR W4S</p>

                <p id="text">
                  Aqui você encontrará novidades e informações relevantes sobre
                  os projetos científicos cadastrados em nossa plataforma, além
                  de conteúdos sobre Ciência enviados por nossos cientistas e
                  colunistas parceiros.
                </p>

                <img id="img-header" src={imgHeader} alt="" />
              </section>
            </Header>

            <BlogContent>
              {news.length !== 0 && (
                <div className="first-content-about">
                  {JSON.stringify(lastNew) !== "{}" && (
                    <Link to={`/information/${lastNew && lastNew.id}`}>
                      <div className="new-content">
                        <div className="about-new">
                          <p id="day">
                            {lastNew &&
                              lastNew.createdAt &&
                              format(
                                parseISO(lastNew.createdAt),
                                "dd'/'MM'/'yy",
                                {
                                  locale: pt,
                                }
                              )}
                          </p>
                          <p id="title">{lastNew && lastNew.title}</p>
                          <p id="text">{lastNew && lastNew.summary}</p>
                        </div>
                        {lastNew !== null &&
                          JSON.stringify(lastNew) !== "{}" &&
                          lastNew !== undefined && (
                            <img src={shadowImg} alt="" id="shadow" />
                          )}
                        <img
                          id="first-img"
                          src={lastNew && lastNew.first && lastNew.first.url}
                          alt=""
                        />
                      </div>
                    </Link>
                  )}
                  <div className="news">
                    {currentCountries.length > 0 &&
                      currentCountries.map((preview) => (
                        <New key={preview.id}>
                          <div className="content-1">
                            <img src={preview.first.url} alt="" />
                          </div>
                          <div className="content-2">
                            <p id="day">
                              {format(
                                parseISO(preview.createdAt),
                                "dd'/'MM'/'yy",
                                {
                                  locale: pt,
                                }
                              )}
                            </p>
                            <p id="title">{preview.title}</p>
                            <p id="text">{preview.summary}</p>
                          </div>
                          <div className="content-3">
                            <img src={atomProject} alt="" />
                            <p>
                              <Link to={`information/${preview.id}`}>
                                Ler a Publicação
                              </Link>
                            </p>
                          </div>
                        </New>
                      ))}
                  </div>
                  {/* <button>Ver mais Publicaçãos</button> */}
                  {news.length > 0 && (
                    <div className="floatPage">
                      <Pagination
                        currentPage={currentPage}
                        countriesPerPage={countriesPerPage}
                        totalCountries={news.length}
                        paginate={paginate}
                      />
                    </div>
                  )}
                </div>
              )}
              <aside className="second-content-about">
                <div className="search">
                  <input
                    type="text"
                    placeholder="Encontrar Publicação"
                    onChange={(event) => setSearchNew(event.target.value)}
                  />
                  <div className="box-search" onClick={() => search(searchNew)}>
                    <img src={iconSearch} alt="" />
                  </div>
                </div>

                <div className="category">
                  <div>
                    <img src={iconCategory} alt="" />
                    <p id="title">Categorias</p>
                  </div>
                  <section></section>
                  <ul>
                    <Categories active={filterOne} onClick={toggleOne}>
                      Ciência
                    </Categories>

                    <Categories active={filterTwo} onClick={toggleTwo}>
                      Projetos em destaque
                    </Categories>

                    <Categories active={filterThree} onClick={toggleThree}>
                      Instituições
                    </Categories>

                    <Categories active={filterFour} onClick={toggleFour}>
                      Pesquisa
                    </Categories>

                    <Categories active={filterFive} onClick={toggleFive}>
                      Institucional
                    </Categories>
                  </ul>
                </div>

                <div className="news">
                  <div className="title">
                    <img src={newsPaperAside} alt="" />
                    <p>Publicações Destaque</p>
                  </div>
                  <div className="content">
                    {newsSide &&
                      newsSide.length !== 0 &&
                      newsSide.map((preview) => (
                        <NewAside key={preview.id}>
                          <p id="day">{preview.formattedDate}</p>
                          <p id="title">{preview.title}</p>
                          <p id="text">{preview.summary}</p>
                          <div>
                            <img src={iconPlay} alt="" />
                            <Link to={`/information/${preview.id}`}>
                              Ler a Publicação
                            </Link>
                          </div>
                          <section></section>
                        </NewAside>
                      ))}
                  </div>
                </div>

                <NewsletterCard subscriveNewsletter={subscriveNewsletter} />

                <ArticleCard />
              </aside>
            </BlogContent>

            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Blog;
