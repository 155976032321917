import React from "react";
import { useHistory } from "react-router";

import { Container } from "../../../../styles/global";
import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import atom from "../../../../assets/icons/Home/atom.svg";
import iconLogo from "../../../../assets/images/Home/icon-logo-2.svg";
import contentScience from "../../../../assets/images/Home/content-science-2.svg";

function First() {
  const history = useHistory();

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <img className="img-1" src={atom} alt="" />
            <h1> Por que apoiar a Ciência ? </h1>
            <p id="science-2">
              Porque a Ciência é o principal motor para o progresso da
              humanidade e a solução para alguns dos grandes problemas e
              desafios que ameaçam a existência humana e de todos os seres vivos
              em nosso planeta. Por meio da Ciência, podemos produzir mais e
              melhores alimentos, desenvolver medicamentos e tratamentos que
              salvarão vidas, produtos e máquinas que melhorarão nossa qualidade
              de vida, procedimentos e tecnologias que ajudarão a preservar o
              meio ambiente e levar a humanidade aos confins do universo.
              Promover o progresso da Ciência é promover o nosso próprio
              progresso!
            </p>
            <button onClick={() => history.push("/supporters")}>
              {" "}
              Seja um Apoiador!
            </button>
          </Column>
          <Column larger>
            <div className="images-container">
              <ImgWrapper>
                <Img src={contentScience} alt="Image" />
              </ImgWrapper>

              <ImgWrapper larger>
                <Img larger src={iconLogo} alt="Image" />
              </ImgWrapper>
            </div>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default First;
