import styled from 'styled-components';
import { darken } from 'polished'


export const Wrapper = styled.div `
    height: 100%;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    
    font-family: GothamPro;
    @media only screen and (max-width:768px){
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const Content = styled.div `
    height: 100%;

    display:flex;
    flex-direction:column;

    /* Webkits */
    display:-webkit-box;
    display:-ms-flexbox;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    /* Webkits */
    @media only screen and (max-width:768px){
        width:100vw;
    }
`

export const Header = styled.header `
    width:100%;
    height: 211px;

    display:flex;
    flex-direction:column;

    background:#227af1;

    /* Webkits */
    display:-webkit-box;
    display:-ms-flexbox;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    /* Webkits */

    .second-content-header{
        .title{
            display:flex;
            align-items:center;
            justify-content:center;

            /* Webkits */
            display:-webkit-box;
            display:-ms-flexbox;
            -webkit-box-align:center;
            -ms-flex-align:center;
            -webkit-box-pack:center;
            -ms-flex-pack:center;
            /* Webkits */

            #text-1{
                margin-left:23px;
                
                font-size: 50px;
                font-weight: 500;
                line-height: 1.4;
                text-align: left;

                color: #ffffff;
            }
        }

    }

    @media only screen and (max-width: 849px) {
    padding-top:10px;
  }
    @media only screen and (max-width:768px){
        width:100vw;

        .second-content-header{

            .title{
                margin-top:2.5vh;
                img{
                    width: 46.4px;
                    height: 42.8px;
                }

                #text-1{
                    font-size:1.6rem;
                }
            }
            
        }
    }
`
export const ChallengeContent = styled.section `
    display:flex;
    flex-direction:row;
    align-items:flex-start;

    margin-left:139px;
    margin-bottom:110px;

    /* Webkits */
    display:-webkit-box;
    display:-ms-flexbox;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-direction:row;

    
    /* Webkits */

    .first-content-about{
        width: 628px;
        
        margin-right:150px;
        margin-top:50.6px;

        display:flex;
        flex-direction:column;
        /* align-items:center; */

        /* Webkits */

        /* display:-webkit-box;
        display:-ms-flexbox;
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        -webkit-box-align:center;
        -ms-flex-align:center;
        Webkits */

        #challenge-title{
            width: 628px;

            font-size: 30px;
            font-weight: 500;
            line-height: 1.2;
            text-align: left;

            color: #227af1;
            margin-bottom:34.6px;
        }
        .rules{
            #title-rules{
                font-size: 26px;
                text-align: left;
                color: #227af1;
            }

            #text-rules{
                font-size: 14px;
                font-family:GothamProRegular;
                line-height: 2;
                text-align: justify;
                color: #000000;
                white-space: pre-wrap;
            }
        }

        .start-challenge{
            margin-top:29px;


            #title-start-challenge{
                font-size: 26px;
                text-align: left;
                color: #227af1;
            }

            .start{
                display:flex;
                align-items:center;
                

                #text-start{
                    width: 444px;
                    font-size: 14px;
                    font-family:GothamProRegular;
                    line-height: 2;
                    text-align: left;
                    color: #000000;
                }

                #btn-start{
                    width: 168px;
                    height: 60px;
                   
                    border-radius: 30px;
                    border: solid 2px #2976fa;

                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    text-align: center;
                    color: #227af1;

                    background:transparent;


                    transition: all .3s ease-in-out 0s;
                    /* Webkits */
                    -webkit-transition: all .3s ease-in-out 0s;
                    -o-transition: all .3s ease-in-out 0s;
                    /* Webkits */
                    &:hover{
                        background-color:${darken(0.1,'#fff')};
                    }
                }

            }
        }

        .finish-challenge{
            margin-top:43px;

            #title-finish-challenge{
                font-size: 26px;
                text-align: left;
                color: #227af1;
            }
            #text-finish-challenge{
                font-size: 14px;
                font-family:GothamProRegular;
                line-height: 2;
                text-align: left;
                color: #000000;
            }

            .box{
                height: 120px;
                padding:15px;

                >p{
                    font-family:GothamProRegular;
                    margin-top:15px;
                    strong{
                        color:#227af1;
                    }
                }
            }

            form{
                input{
                    width: 628px;
                    height: 60px;
                    border-radius: 30px;
                    border: solid 1.4px #7f7ebe;
                    margin-right:16px;
                    margin-bottom:20px;
                    padding-left:33.6px;
                    font-size: 15px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    
                    letter-spacing: normal;
                    text-align: left;
                    color: #7f7fb9;

                    margin-top:12px;
                    
                    &::-moz-placeholder{
                        opacity: 1;
                    }
                    &::placeholder{
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #7f7fb9;
                    }
                }
                textarea{
                    width:628px;
                    height: 179px;
                    border-radius: 15px;
                    border: solid 1px #7f7ebe;
                    outline: none;
                    resize: none;
                    overflow:hidden;
                    padding-top:19px;
                    padding-left:33.6px;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: #7f7fb9;
                    
                    &::placeholder{
                        clear:left;
                        flex-wrap:wrap;
                        color: #7f7fb9;
                    }
                    &::-moz-placeholder{
                        opacity: 1;
                    }
                    
                }
                #btn-finish{
                    width: 260px;
                    height: 60px;
                   
                    border-radius: 30px;
                    border: solid 2px #2976fa;

                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    text-align: center;
                    color: #227af1;

                    background:transparent;

                    float:right;

                    margin-top:18px;

                    transition: all .3s ease-in-out 0s;
                    /* Webkits */
                    -webkit-transition: all .3s ease-in-out 0s;
                    -o-transition: all .3s ease-in-out 0s;
                    /* Webkits */
                    &:hover{
                        background-color:${darken(0.1,'#fff')};
                    }
                }
            }
        }


        hr{
            width: 627px;
            height: 0;
            
            opacity: 0.24;
            border: solid 1px #707070;

            margin-bottom:31px;
        }
    }

    .second-content-about{
        width: 352px;

        margin-top:125px;
       
        .company-info{
            width: 352px;
            height: 315.8px;
            height: 390.8px;



            border-radius: 10px;
            
            box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
            background-color: #ffffff;

            /* Webkits */
            -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
            /* Webkits */

            .title-company{
                height: 72.6px;
                padding-top:29px;

                #company-name{
                    margin-bottom:17.6px;
                    margin-left:35px;

                    font-size: 20px;
                    font-weight: 500;
                    text-align: left;

                    color: #227af1;
                }

                section{
                    width:100%;
                    height: 1px;  

                    background: #eee;
                }
            }

            .logo-company{
                height:115px;
                width:352px;
                .content-img{
                    display:flex;
                    justify-content:center;

                    /* Webkits */
                    display:-webkit-box;
                    display:-ms-flexbox;
                    -webkit-box-pack:center;
                    -ms-flex-pack:center;
                    /* Webkits */

                    img{
                        max-height:115px;
                        max-width:352px;
                    }
                }

                section{
                    width:100%;
                    height: 1px;  

                    background: #eee;
                }
            }

            .social-networks{
                height: 90.2px;
                padding-top:15.3px;
                margin-top:10px;

                #title-social-networks{
                    margin-left:33px;
                    margin-bottom:10px;

                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.57;
                    text-align: left;
                    color: #7f7fb9;
                }

                .icons{
                    display:flex;
                    align-items:center;

                    margin-left:33px;
                    margin-bottom:10.8px;

                    /* Webkits */
                    display:-webkit-box;
                    display:-ms-flexbox;
                    -webkit-box-align:center;
                    -ms-flex-align:center;
                    /* Webkits */

                    svg{
                        margin-right:27.2px;

                        cursor:pointer;

                        transition: all .3s ease-in-out 0s;
                        /* Webkits */
                        -webkit-transition: all .3s ease-in-out 0s;
                        -o-transition: all .3s ease-in-out 0s;
                        /* Webkits */
                        &:hover{
                            color:${darken(0.1,'#7f7fb9')};
                        }
                    }
                }

                section{
                    width:100%;
                    height: 1px;  
                    background: #eee;
                }
            }

            .adress{
                height: 136.6px;
                padding-top:20.6px;

                #title-adress-networks{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.57;
                    text-align: left;

                    color: #7f7fb9;

                    margin-left:33px;
                    margin-bottom:10px;
                }

                #text-adress{
                    margin-left:33px;
                    cursor: pointer;
                    color: #227af1;
                    transition: all .3s ease-in-out 0s;
                    text-decoration:underline;
                    &:hover {
                        color:${darken(0.1, '#227af1')};
                    }

                }
            }
        }
    }
    
    @media only screen and (max-width:768px){
        width:100vw;
        margin-left:0px;

        display:flex;
        flex-direction:column !important;
        align-items:center;

        .first-content-about{
            width:100%;
            margin-right:0;

            #challenge-title{
                width:100%;
                font-size:1.4rem;
                margin-left:5%;
            }

            .rules{
                width:95%;
                padding-left:5%;

                #title-rules{
                    font-size:1.2rem;
                }

                hr{
                    width:100%;
                }

                #text-rules{
                    font-size:0.9rem;
                }
            }

            .start-challenge{
                width:95%;
                padding-left:5%;

                #title-start-challenge{
                    font-size:1.2rem;
                  
                }

                hr{
                    width:100%;
                }

                .start{
                    width:100%;

                    #text-start{
                        font-size:0.8rem ;
                    }

                    #btn-start{
                        font-size:0.65rem !important;
                         
                    }
                }
            }

            .finish-challenge{
                width:95%;
                padding-left:5%;

                #title-finish-challenge{
                    font-size:1.2rem;
                }

                hr{
                    width:100%;
                }

                #text-finish-challenge{
                    font-size:0.8rem;
                }

                .box{
                    >p{
                        font-size:0.8rem;
                    }
                }
                


                form{
                    width:95%;

                    input{
                        width:100%;
                        padding-left:4%;
                        padding-right:2%;
                    }
                    textarea{
                        width:100%;
                    }

                   

                    #btn-finish{
                        width:75% !important;
                        margin:0;

                        margin-top:5vh;

                        float:unset;
                        margin-left:10%;
                    }
                }
            }
        }

        .second-content-about{
           display:none;
           margin-top:0;
        }
    }
`

export const OtherChallenges = styled.div `
    display:flex;    
    flex-direction:column;
    align-items:center;

    /* Webkits */
    display:-webkit-box;    
    display:-ms-flexbox;  
    -webkit-box-align:center;    
    -ms-flex-align:center;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    /* Webkits */

    #title{
        align-self:flex-start;
        
        margin-left:135px;
        margin-bottom:18px;

        font-size: 49px;
        font-weight: 500;
        line-height: 1.43;
        text-align: left;
        color: #227af1;

        /* Webkits */
        -ms-flex-item-align:start;
        /* Webkits */
    }

    #section-challenges{
        width:79%;
        height: 2px;  

        margin-left:7px;
        margin-bottom:36px;
       
        background: #eee;
    }

    .chalenges{
        display:flex;
        flex-direction:row;
        justify-content:center;
        flex-wrap:wrap;     


        /*Webkits  */
        display:-webkit-box;
        display:-ms-flexbox;
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
        -webkit-box-pack:center;
        -ms-flex-pack:center;
        -ms-flex-wrap:wrap;
        /* Webkits */
    }

    #more-challenges{
        width:350px;
        height: 55px;

        margin:22px 0px 40px 10px;

        border-radius:30px;
    
        background:transparent;
        border:2px solid  #227af1;

        font-size: 16.5px;
        font-weight: bold;
        line-height: 1.88;
        text-align: center;

        color:#227af1;

        transition: all .3s ease-in-out 0s;

        /* Webkits */
        -webkit-transition: all .3s ease-in-out 0s;
        -o-transition: all .3s ease-in-out 0s;
        /* Webkits */

        &:hover{
            background:#227af1;
            color:#FFF;
            -webkit-transform:translate3d(0px,2px,1px);
            transform:translate3d(0px,2px,1px);
        }
    }

    @media only screen and (max-width:768px){
        width:100vw;

        #title{
            font-size:1.2rem;
            margin-left:0;
        }

        .chalenges{

            width:100%;
            display:flex;
            flex-direction:column !important;
            align-items:center;
            flex-wrap:nowrap;
            justify-content:unset;
        }

        #more-challenges{
            width:80%;
            margin:0;
            margin-bottom:5vh;
            font-size:0.85rem !important;

           display:block;
        }
    }
`
