import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";
import iconChamp from "../../../../assets/icons/Common/second.svg";
import iconCheckWhite from "../../../../assets/icons/Common/check-white.svg";
import imgFirst from "../../../../assets/images/Company/third.svg";

function First() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <div className="content">
              <img src={iconChamp} alt="" />
              <h1>VANTAGENS</h1>
              <p id="text-subcontent">
                Aqui você apoia a Ciência e recebe em troca grandes
                oportunidades de negócio, inovações e valorização da sua marca!
              </p>
            </div>
          </Column>
          <Column>
            <ul>
              <li>
                <div className="box-list">
                  <img src={iconCheckWhite} alt="" />
                  <p id="list-title" style={{ marginLeft: 10 }}>
                    Encontre possibilidades <br /> reais de investimento!
                  </p>
                </div>
                <p id="list-text">
                  Com acesso privilegiado a um amplo, diversificado e atualizado
                  catálogo de projetos científicos sendo produzidos por
                  cientistas talentosos e instituições respeitadas, é só uma
                  questão de tempo até você encontrar ótimas oportunidades de
                  investimentos para o seu negócio ou, se preferir, você poderá
                  estimular a criação dessas oportunidades!
                </p>
              </li>

              <li>
                <div className="box-list">
                  <img src={iconCheckWhite} alt="" />
                  <p id="list-title" style={{ marginLeft: 10 }}>
                    Lance <br /> Desafios!
                  </p>
                </div>
                <p id="list-text">
                  Doando para projetos e pesquisas científicas, você adquire
                  “moedas Whuffie” que poderá usar para lançar Desafios que
                  atendam às necessidades da sua empresa, como buscas de
                  soluções tecnológicas, busca por profissionais da ciência,
                  desenvolvimento de produtos e serviços, e muito mais!
                </p>
              </li>

              <li>
                <div className="box-list">
                  <img src={iconCheckWhite} alt="" />
                  <p id="list-title" style={{ marginLeft: 10 }}>
                    Compre e venda produtos, serviços e experiências no Mercado
                    Whuffie!
                  </p>
                </div>
                <p id="list-text">
                  Em nossa plataforma, sua empresa pode comercializar produtos,
                  serviços e experiências, encontrando um novo e proeminente
                  mercado de consumidores e negócios em potencial.
                </p>
              </li>
              <li>
                <div className="box-list">
                  <img src={iconCheckWhite} alt="" />
                  <p id="list-title" style={{ marginLeft: 10 }}>
                    Fortaleça
                    <br /> sua marca!
                  </p>
                </div>
                <p id="list-text">
                  Para 83% dos brasileiros a Ciência é muito importante para a
                  sociedade, ao mesmo tempo em que a falta de financiamento é
                  apontada por 42% dos brasileiros como o principal problema
                  para o avanço da Ciência no país.* Assim, apoiar a Ciência irá
                  fortalecer o marketing socioambiental da sua marca junto aos
                  consumidores brasileiros e criar um importante diferencial
                  competitivo.
                  <br /> <br />
                  *Fonte: Índice Anual da Situação da Ciência, 2018.
                </p>
              </li>
              <ImgWrapper>
                <Img src={imgFirst} alt="" />
              </ImgWrapper>
            </ul>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default First;
