import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { store } from "../../../../store";

import { Container } from "./styles";

import iconHeader from "../../../../assets/icons/Challenges/first.svg";

function ChallengeInfo({ challenge }) {
  const { token } = store.getState().auth;

  const history = useHistory();
  var day = challenge && challenge.conclusion.split("/")[0] / 1;
  var mounth = challenge && challenge.conclusion.split("/")[1] / 1;
  var year = challenge && (20 + challenge.conclusion.split("/")[2]) / 1;

  const calculateTimeLeft = () => {
    const difference = +new Date(`${year}-${mounth}-${day}`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
        horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutos: Math.floor((difference / 1000 / 60) % 60),
        segundos: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { encerrado: true };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  function checkIsAuth() {
    if (challenge.winner) {
      toast.error("O desafio já possui um campeão!");
    } else {
      if (token) {
        setTimeout(function () {
          history.push(`/get-challenge/${challenge && challenge.id}`);
        }, 50);
      } else {
        toast.error("É necessário estar logado para participar do desafio!");
      }
    }
  }
  return (
    <Container active={challenge && challenge.special_premium !== null ? true : false}>
      <div className="content-1-challenge">
        <div className="title-content-1">
          <p>Informações Gerais</p>

          <section></section>
        </div>

        <div className="content-1-about-challenge">
          <p>Empresa: {challenge && challenge.company.name}</p>
          <p>Data limite: {challenge && challenge.conclusion}</p>
          <section></section>
        </div>

        <div className="timer">
          <p id="title-timer">Tempo para encerrar:</p>
          <p></p>
          {timeLeft &&
          !timeLeft.encerrado &&
          challenge &&
          challenge.winner !== true ? (
            <div className="counter">
              <p id="number">{timeLeft.dias}</p>
              <p id="unity">dias</p>
              <p id="number">{timeLeft.horas}</p>
              <p id="unity">h</p>
              <p>e</p>
              <p id="number">{timeLeft.minutos}</p>
              <p id="unity">min</p>
            </div>
          ) : (
            <div className="counter">
              <p id="number">
                {timeLeft.encerrado === true ||
                  (challenge.winner && `Encerrado`)}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="content-2-challenge">
        <p id="title-value-content">Prêmio Whuffie</p>
        <div className="value-content">
          <p id="value">{challenge && challenge.premium}</p>
          <p>,00 W$</p>
        </div>
      </div>
      {challenge &&
        challenge.special_premium !== null &&
        challenge &&
        challenge.special_premium !== "" && (
          <div className="special-premium">
            <p id="title-special">Prêmio Especial</p>
            <Scrollbars style={{ maxHeight: 80 }}>
              <p id="about"> {challenge && challenge.special_premium}</p>
            </Scrollbars>
          </div>
        )}
      <div className="content-3-challenge">
        <p>Participar do Desafio</p>
      </div>
      <div className="content-4-challenge">
        <div>
          <button onClick={checkIsAuth}>
            <img src={iconHeader} alt="" id="img-button" />
          </button>
        </div>
      </div>
    </Container>
  );
}

export default ChallengeInfo;
