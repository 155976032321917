import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 352px;

  border-radius: 10px;
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  background-color: #227af1;

  margin-bottom: 20px;

  .title-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-bottom: 1px solid ${darken(0.1, "#227af1")};

    padding-top: 20px;
    padding-left: 33px;
    padding-bottom: 10px;

    #text-1-content-2 {
      max-width: 100%;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    .subcontent-2 {
      max-width: 100%;
      display: flex;
      align-items: baseline;

      margin-top: 5px;

      img {
        height: 16px;
        width: 16px;
        margin-right: 6px;
      }

      #text-2-content-2 {
        max-width: 100%;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
    }
  }

  .values-info {
    width: 100%;

    padding: 14.3px 0 20.6px 33px;

    > p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #5cf3ff;
      margin-bottom: 5px;
    }
    #cash-values-info {
      font-size: 35px;

      margin-bottom: 10px;
      margin-top: 7px;
    }
    #mark-values-info {
      margin-top: 13px;
      color: #fff;
      font-size: 18px;
    }
  }

  .content-3 {
    width: 100%;
    height: 59px;
    -webkit-box-shadow: 0 0 2px 0 rgba(35, 11, 114, 0.28);
    box-shadow: 0 0 2px 0 rgba(35, 11, 114, 0.28);
    border-radius: 0 0 10px 10px;
    background: rgb(44, 86, 227);
    background: linear-gradient(
      90deg,
      rgba(44, 86, 227, 1) 32%,
      rgba(68, 169, 252, 1) 77%,
      rgba(106, 192, 255, 1) 96%
    );

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      margin: 38px 20px 40px 33px;
      width: 32.4px;
      height: 32.4px;
    }

    #btn-donation {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;

      background: transparent;

      -webkit-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      &:hover {
        color: ${darken(0.1, "#FFF")};
      }
    }
  }

  #percentage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-bottom: 1px solid ${darken(0.1, "#227af1")};
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;
  }
`;
