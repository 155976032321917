import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 100px 0 80px 0;
  background: #fff;

  @media screen and (max-width: 500px) {
    padding: 40px 0 80px 0;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  @media screen and (max-width: 1078px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  .img-1 {
    margin-bottom: 24px;
  }

  .img-2 {
    transform: translate(160px, 80px);
  }

  > h1 {
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    text-transform: uppercase;
  }

  #science-2 {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 40px;
    margin-top: 11px;
  }

  #btn-store {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    border-radius: 30px;
    background-color: #227af1;

    font-family: GothamPro;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 60px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 352px;
    height: 60px;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  .content-products{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
  }
  #second {
    display: flex;
    flex-direction: row;
  }

  @media screen and (min-width: 1078px) and (max-width: 1186px){
    #science-2{
      width: 90%;
    }

  }

  @media screen and (max-width: 1078px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-2 {
      transform: translate(0, 0);
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;

    > h1 {
      font-size: 1.5rem;
    }

    #science-2 {
      text-align: justify;
    }

    #btn-store {
      padding: 20px 33px;
      font-size: 0.9rem;
      width: 100%;
    }
  }

  ${(props) =>
    props.products &&
    css`
      @media screen and (max-width: 600px) {
        #second {
          display: flex;
          align-items: center;
   
          flex-direction: column;
        }
      }
    `}
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: flex-start;

  margin-left: 46px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 30px;
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const ChangeProduct = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 40px;
  z-index:30;
  .paginate-img {
    background: transparent;
    width: 47.2px;
    height: 47.2px;

    border-radius: 50%;

    transition: all 0.3s ease-in-out 0s;

    margin-left: 40px;

    cursor: pointer;

    &:disabled {
      opacity: 0.6;
      background-color: ${darken(0.1, "#FFF")};
      cursor: not-allowed;
    }

    &:hover {
      background-color: ${darken(0.1, "#fff")};
    }
  }
`;
