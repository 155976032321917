import { darken } from "polished";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 0 0 0;
  background: #fff;

  hr {
    align-self: center;

    width: 100%;
    height: 0;
    opacity: 0.24;
    border: solid 1px #707070;
  }

  @media screen and (max-width: 475px) {
    hr {
      margin-left: 10%;
      width: 80%;
    }
  }

  @media screen and (max-width: 475px) {
    hr {
      display: none;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  padding-top: 54px;
  position: relative;

  margin: 0;
  z-index: 0;

  @media screen and (max-width: 823px) {
    flex-direction: row-reverse;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  padding-bottom: 21px;

  ${(props) =>
    !props.larger &&
    css`
      max-width: 43.3%;
      flex-basis: 43.3%;
    `}

  ${(props) =>
    props.larger &&
    css`
      max-width: 56.6%;
      flex-basis: 56.6%;

      justify-content: center;
      align-items: center;
    `}

    #icon {
    width: 71px;
    margin-bottom: 22.2px;
  }

  #title {
    margin-top: 22.2px;
    margin-bottom: 6px;

    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    text-transform: uppercase;
  }

  #text {
    max-width: 100%;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  @media screen and (max-width: 823px) {
    ${(props) =>
      props.larger &&
      css`
        max-width: 100%;
        flex-basis: 100%;
      `}
    #title {
      font-size: 2rem;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 472px) {
    #title {
      font-size: 1rem;
    }

    #text {
      max-width: 90%;

      text-align: justify;
    }
  }
`;

export const RowTwo = styled.div`
  width: 100%;
`;

export const RowThree = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  padding-bottom: 48.6px;

  #btn-partner {
    width: 260px;
    height: 60px;
    border-radius: 30px;
    background-color: #227af1;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;

    margin-top: 62px;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  @media screen and (max-width: 472px) {
    #btn-partner {
      margin-top: 10px;
      width: 90%;
    }
  }
`;

export const Img = styled.img`
  max-width: 70%;
  padding-right: 0;
  border: 0;
  vertical-align: middle;

  @media screen and (max-width: 823px) {
    display: none;
  }
`;
