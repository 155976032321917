import React from "react";

import { Container } from "./styles";
import Product from "../../../../components/Product";

function OtherProduct({ products }) {
  return (
    <Container>
      <p id="title">Produtos similares</p>
      <section id="section-challenges"></section>
      <div className="products">
        {products &&
          products.map((other) => <Product key={other.id} data={other} />)}
      </div>
    </Container>
  );
}

export default OtherProduct;
