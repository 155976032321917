import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 90px 0 0 0;
  background: #fff;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
`;

export const Column = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  ${(props) =>
    props.larger &&
    css`
      max-width: 60.6%;
      flex-basis: 60.6%;
      justify-content: flex-end;

      padding-left: 15px;
    `}

  ${(props) =>
    !props.larger &&
    css`
      max-width: 39.3%;
      flex-basis: 39.3%;
      margin-bottom: 50px;
      align-items: flex-start;

      transform: translateX(-15px);
    `}


    h1 {
    margin-top: 8.3px;
    margin-bottom: 17px;

    font-size: 50px;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;
    text-transform: uppercase;

    color: #227af1;
  }

  p {
    width: 100%;

    margin-bottom: 30px;

    font-family: GothamProRegular;
    font-size: 14px;
    line-height: 2;
    text-align: left;

    color: #000000;
  }

  button {
    width: 352px;
    height: 60px;

    border-radius: 30px;
    background-color: #227af1;

    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;

    color: #ffffff;

    transition: all 0.3s ease-in-out 0s;

    /* Webkits */
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    /* Webkits */

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  @media screen and (max-width: 1076px) {
    h1 {
      font-size: 2rem;
    }
    ${(props) =>
      props.larger &&
      css`
        justify-content: center;
      `}

    ${(props) =>
      !props.larger &&
      css`
        transform: translateX(0);
      `}
  }

  @media screen and (min-width: 805px) and (max-width: 866px) {
    button {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 805px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 1.5rem;
    }

    button {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }

    ${(props) =>
      !props.larger &&
      css`
        margin-top: 20px;
      `}
  }
`;

export const ImgWrapper = styled.div`
  max-width: 100%;

  z-index: -1;
  position: relative;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;
