import styled, { css } from 'styled-components';
import { darken } from 'polished'

export const Wrapper = styled.div `
    height: 100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family: GothamPro;
`;

export const Content = styled.div `
    width:1366px;
    height: 100%;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    justify-content:space-between;

    @media only screen and (max-width:768px){
        width:100vw;
    }
`

export const Header = styled.header `
    width:100%;
    height: 500px;

    background:#227af1;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    padding-bottom:25px;

    #second-content-header{
        display:flex;
        flex-direction:column;

        justify-content:center;

        #icon-header{
            height: 63.8px
        }

        .titles{
            margin-top:11.1px;

            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:center;

            #title{
                font-size: 40px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.4;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;

                width:500px;
            }

            #sub-title{
                font-family:GothamProRegular;

                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.56;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;

                width: 570px;
                height: 60px;
            }
        }

        #img-header{
            display:block;	            
            position: absolute;	          
            width: 293px;	            
            height: 322px;	            
            align-self:flex-end;	         
            top:133.2px;	           
            margin-left:0.5px;    	           
            z-index:0.5;
        }

        .buttons{
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;

            margin-top:28px;

            z-index:1;
        }

        .buttons-two{
            opacity: 0;
        }
    }

    @media only screen and (max-width: 849px) {
    padding-top:10px;
  }

    @media only screen and (max-width:768px){
        width:100vw;
        height: 550px;

        #second-content-header{
           
            #icon-header{
                height: 43.8px
            }
           
            .titles{
                #title{
                    font-size:1.5rem;
                }

                #sub-title{
                    width:80%;
                    font-size:0.9rem;
                    word-wrap:break-word;
                }
            }

            .buttons{
                display:none;
                opacity: 0;
            }

            .buttons-two{
                display:flex;
                flex-direction:row;
                align-items:center;
                justify-content:center;
                opacity: 1;

                margin-top:10px;
            }
        


            #img-header{
                display:none;
                margin-left:0;
            }
        }
    }
`

export const Button = styled.button `
    width: 352px;
    height: 60px;
    border-radius: 30px;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;

    margin-right:16px;

    background-color:#FFF;
    color: #227af1;

    transition: all .3s ease-in-out 0s;

    &:hover{
        background-color:${darken(0.1, '#FFF')};
    }

    ${props => props.active && css`
        color:#fff;

        background: rgb(106,243,255);
        background: -webkit-gradient(linear, left top, right top, color-stop(32%, rgba(106,243,255,1)), to(rgba(138,238,197,1)));
        background: -o-linear-gradient(left, rgba(106,243,255,1) 32%, rgba(138,238,197,1) 100%);
        background: linear-gradient(90deg, rgba(106,243,255,1) 32%, rgba(138,238,197,1) 100%);
        box-shadow: 0 0 15px 0 #45c6e5;

        &:hover{
            background: linear-gradient(90deg, ${darken(0.1, 'rgba(106,243,255,1)')} 32%, ${darken(0.1, 'rgba(138,238,197,1)')} 100%);
        }
    `}


    @media only screen and (max-width:768px){
        width: 25%;
        height: 45px;
        font-size: 0.6rem;
    }

`


export const RegisterContent = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;

    width:100%;
    height: 2000px;

    margin-bottom:70px;

    .form{
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-top:11px;
        margin-bottom:20px;

        .title-form{
            align-self:flex-start;
            margin-left:139px;

            display:flex;

            p{
                margin-left:12px;

                font-size: 30px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.33;
                letter-spacing: normal;
                text-align: left;
                color: #227af1;
            }
        }

        .inputs{
            margin-left:131px;
            margin-top:43px;

            display:flex;

            flex-wrap:wrap;

            input{
                width: 352px;
                height: 60px;
                border-radius: 30px;
                border: solid 1.4px #7f7ebe;
                margin-right:16px;
                margin-bottom:20px;

                padding-left:33.6px;

                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #7f7fb9;

                &::placeholder{
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #7f7fb9;
                }


                &::-moz-placeholder{
                    opacity: 1;
                }
            }

            #message{
                width: 715px;
                height: 179px;
                border-radius: 10px;
                border: solid 1px #7f7ebe;

                outline: none;
                resize: none;
                overflow:hidden;

                padding-top:19px;
                padding-left:33.6px;

                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: #7f7fb9;
                
                &::placeholder{
                    clear:left;
                    flex-wrap:wrap;
                    color: #7f7fb9;
                }

                &::-moz-placeholder{
                    opacity: 1;
                }
            }

            #other-button{
                opacity:0;
            }

            .buttons-inputs{
                display:flex;
                flex-direction:column;
                justify-content:space-between;

                #submit-button{
                    width: 352px;
                    height: 60px;
                    border-radius: 30px;
                    background-color: #227af1;

                    margin-right:25px;
                    padding-left:148.6px;

                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ffffff;

                    align-self:flex-end;

                    transition: all .3s ease-in-out 0s;

                    &:hover{
                        background-color: ${darken(0.1, '#227af1')};
                    }
                }
            }
        }
    }

    .questions{
        display:flex;
        flex-direction:column;
        align-self:flex-start;

        margin-top:69.4px;
        margin-left:135px;

        .title{
            display:flex;
            flex-direction:row;

            >div{
                margin-left:14.6px;
                margin-top:5px;

                p{
                    font-size: 30px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.33;
                    letter-spacing: normal;
                    text-align: left;
                    color: #227af1;
                }
            }
        }
    }
    .content{
        display:flex;
        align-items:center;
    }

    .terms{
        width:100%;
        margin-top:60px;
        margin-bottom:25px;

        padding-left:134px;
        padding-right:139px;

        hr{
            width: 100%;
            height: 0;
            opacity: 0.24;
            border: dashed 1px #707070;
        }

        #title-terms {

            font-size: 30px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.33;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;
        }

        .content-terms{
            width:100%;
            display:flex;
            flex-direction:column;

            margin-bottom:30px;
        }

        a{
            margin-top:20px;

            cursor: pointer;
            color: #227af1;
            transition: all .3s ease-in-out 0s;
            text-decoration:underline;
            &:hover {
                color:${darken(0.1, '#227af1')};
            }
        }
    }

    @media only screen and (max-width:768px){
        width:100vw;
        height: 2600px;

        .form{
            width:100%;
            display:flex;
            flex-direction:column;

            margin-top:20px;

            .title-form{
                margin:0;

                margin-left:10%;

                img{
                    height: 30px;
                }

                p{
                    font-size:1rem;
                }
            }

            .inputs{
                width:100%;
                display:flex;
                flex-direction:column;
                margin-left:10%;

                input,#message{
                    margin-right:0;
                    width:90%;
                }

                #submit-button{
                    display:none;
                }

                #other-button{
                    opacity: 1;
              
                    width:75%;
                    height: 60px;

                    margin-top:20px;

                    border-radius: 30px;
                    background-color: #227af1;

                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;

                    transition: all .3s ease-in-out 0s;

                    &:hover{
                        background-color: ${darken(0.1, '#227af1')};
                    }
                
                }
            }
        }

        .terms{
            width:130% !important;
            margin-right:0;
            margin-left:0;
            hr{
                width:100%;
            }
            .content-terms{
                width:100% !important;
                margin:0;
            }

            #title-terms{
                font-size:1.5rem;
            }

            a{
                font-size:1rem;
                word-break:break-all;
            }
        }

        .questions{
            width:80%;
            margin:0;
            margin-left:10%;
            .title{
                display:flex;
                align-items:center;

                img{
                    height: 35px;
                }
                >div{
                    p{
                        font-size:1.5rem;
                    }
                }
            }
        }

        .content{
            width:80%;
        }
    }
`

export const ButtonInterests = styled.button`
    border-radius: 30px;
    background:transparent;
    border: solid 2px #d6d6e5;

    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #d6d6e5;

    transition: all .3s ease-in-out 0s;

    img{
        position: absolute;
        opacity: 0;
        margin-left:25px;
        margin-top:2px;

        width: 16.6px;
        height: 16.6px;
    }

    ${props => props.active && css`
        border: solid 2px #2976fa;
        color: #227af1;

        img{
            opacity: 1;
        }
    `}
`

export const Question = styled.li`
     margin-bottom:62px;
     width:100%;

    .title-question-box{
        
        display:flex;
        align-items:center;

        #title-question{
            width: 996px;
            word-wrap:break-word;

            margin-right:56px;

            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
            text-align: left;
            color: #227af1;
        }

        .btn-questions{
            cursor: pointer;
            transition: all .3s ease-in-out 0s;

            &:hover {
                background-color:${darken(0.05, '#FFF')};
            }
        }
        
    }

    #text-question{
        width: 996px;
        word-wrap:break-word;

        font-family:GothamProRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000000;

        a{
            cursor: pointer;
            color: #227af1;
            transition: all .3s ease-in-out 0s;

            &:hover {
                color:${darken(0.1, '#227af1')};
            }
        }
    }

    section{
        margin-top:12px;

        width: 1088px;
        height: 2px;
        background-color: #227af1;
        opacity: 0.56;
    }

    @media only screen and (max-width:768px){
        width:75vw;

        .title-question-box{
            width:100%;
            #title-question{
                width:80%;
                font-size: 1.1rem;
            }
        }

        #text-question{
            width:100%;
        }
        section{
            width:100%;
        }
    }
`