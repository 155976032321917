import { darken } from "polished";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 45px 0 0 0;
  background: #227af1;
  position: relative;
  z-index: 0;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  z-index: 1;

  max-width: 50%;
  flex-basis: 50%;

  #donation-text-1 {
    margin-bottom: 24px;

    font-size: 46px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  #donation-text-2 {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: relative;

    z-index: 1;

    width: 352px;
    height: 60px;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
    box-shadow: 0 0 7.5px 0 #45c6e5;
    background: rgb(106, 243, 255);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(32%, rgba(106, 243, 255, 1)),
      to(rgba(138, 238, 197, 1))
    );
    background: -o-linear-gradient(
      left,
      rgba(106, 243, 255, 1) 32%,
      rgba(138, 238, 197, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(106, 243, 255, 1) 32%,
      rgba(138, 238, 197, 1) 100%
    );

    font-family: GothamPro;
    font-size: 15.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 60px;
    margin-top: 60px;

    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background: linear-gradient(
        90deg,
        ${darken(0.1, "rgba(106,243,255,1)")} 32%,
        ${darken(0.1, "rgba(138,238,197,1)")} 141%
      );
    }
  }

  #first-circle-donation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: absolute;
    overflow: visible;
    width: 654px;
    height: 654px;
    opacity: 0.05;

    border-radius: 50%;
    background-color: #ffffff;

    z-index: -0.8;

    transform: translate(-40%, 5%);
  }

  #second-circle-donation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: visible;
    position: absolute;

    width: 418px;
    height: 418px;
    opacity: 0.06;

    border-radius: 50%;
    background-color: #ffffff;

    transform: translate(-35%, 35%);

    z-index: -0.8;
  }

  #third-circle-donation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: absolute;
    overflow: visible;
    width: 212px;
    height: 212px;
    opacity: 0.06;

    border-radius: 50%;
    background-color: #ffffff;

    z-index: -0.8;

    transform: translate(-25%, 110%);
  }

  @media screen and (max-width: 856px) {
    max-width: 100%;
    flex-basis: 100%;
    align-items: center;

    #first-circle-donation,
    #second-circle-donation,
    #third-circle-donation {
      display: none;
    }
  }

  @media screen and (max-width: 472px) {
    align-items: flex-start;
    #donation-text-1 {
      font-size: 1.5rem;
    }

    #donation-text-2 {
      text-align: justify;
    }
    button {
      width: 100%;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 100%;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;
