import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;

  @media screen and (min-width: 850px) {
    margin-left: 30px;
  }

  @media screen and (max-width: 849px) {
    margin-right: 50px;
  }
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;

  svg {
    font-size: 20px;
  }

  @media screen and (max-width: 849px) {
    svg {
      font-size: 1.3rem;
    }
  }

  ${(props) =>
    props.hasUnread &&
    css`
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        width: 9px;
        height: 9px;
        background: #5cf3ff;
        content: "";
        border-radius: 50%;
      }
    `}
`;
