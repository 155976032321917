import React, { useCallback, useEffect, useRef, useState } from "react";

// Libraries
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Fade } from "react-reveal";
import { Form, Input, Textarea, useField } from "@rocketseat/unform";
import { Scrollbars } from "react-custom-scrollbars";
import * as Yup from "yup";
import { FormattedNumber, IntlProvider } from "react-intl";

// Redux Modules
import { signOut } from "../../store/modules/auth/actions";
import { updateProfileRequest } from "../../store/modules/user/actions";

// Redux-Store
import { store } from "../../store";

// API
import api from "../../services/api";

// Date-fns
import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt-BR";

// Components
import Footer from "../../components/Footer";
import AvatarInput from "../../components/AvatarInput";
import Pagination from "../../components/Pagination";
import Header from "./Header";

// Styled Components
import {
  Wrapper,
  Content,
  ProfileContent,
  MenuList,
  EditComponent,
  DonationsComponent,
  ChallengesComponent,
  CollaborationsComponent,
  HistoricComponent,
  ShoppingComponent,
  MyProductsComponent,
  NewProductComponent,
  NewChallengeComponent,
  Product,
  Project,
  ProjectSecond,
  Question,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainerState,
  ListItem,
  DropDownListContainer,
  BoxLabel,
  Challenge,
  EditChallengeComponent,
  MyChallenges,
  MyProducts,
  ProductContent,
  ChampComponent,
  ButtonChamp,
  Shoppers,
  ButtonDonation,
  Cosmos,
  NewCategoryComponent,
  AboutCompanyComponent,
  InputContent,
  ButtonStatus,
  BoxProfile,
  SpecialPremiumComponent,
} from "./styles";

import { FaLockOpen } from "react-icons/fa";
// Icons
import iconChallenge from "../../assets/icons/Challenges/first.svg";
import iconPerfil from "../../assets/icons/Profile/icon-pencil.svg";
import iconClips from "../../assets/icons/Profile/clips.svg";
import iconAvatarInput from "../../assets/icons/Common/avatar-input.svg";
import iconDonor from "../../assets/icons/Common/avatar-input.svg";
import carStore from "../../assets/icons/Common/car-store.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";
import iconDrop from "../../assets/icons/Common/drop-reg.svg";
import iconChamp from "../../assets/icons/Common/second.svg";
import iconCart from "../../assets/icons/Common/car-store.svg";
import id from "../../assets/icons/Project/idBlue.svg";
import user from "../../assets/icons/Donors/user.svg";
// Icons fromt the other Foulder
import iconPencil from "../../assets/icons/Register/pencil.svg";
import iconCalendarPurple from "../../assets/icons/Project/calendar-purple.svg";
import iconCheck from "../../assets/icons/Projects/check.svg";
import iconCompanyProject from "../../assets/icons/Projects/company.svg";
import iconProfile from "../../assets/icons/Profile/profiles/company.svg";
import iconPremium from "../../assets/icons/Common/premium.svg";

import { FaPhone, FaEnvelope, FaCheck, FaTimes } from "react-icons/fa";

import iconProducts from "../../assets/icons/Common/store-2.svg";

// Images -> Common Foulder
import logo from "../../assets/images/Common/logo.svg";

import { toast } from "react-toastify";

import { debitRequest } from "../../store/modules/user/actions";
import { Helmet } from "react-helmet";
const schemaProduct = Yup.object().shape({
  value: Yup.number()
    .min(0, "Digite um valor maior ou igual a 0")
    .required("Informe o valor")
    .typeError("Digite um número"),
  title: Yup.string()
    .required("Informe o nome")
    .max(65, "O título deve ter no máximo 65 caracteres"),
  available_units: Yup.number()
    .min(1, "Digite um valor maior ou igual a 1")
    .required("Informe as unidades disponíveis")
    .typeError("Digite um número"),
  description: Yup.string().required("Item sem descrição"),
  datasheet: Yup.string().required("Item sem ficha técnica"),
  delivery_provision: Yup.string().required("Item sem forma de entrega"),
});

const schemaProductEdit = Yup.object().shape({
  value: Yup.number()
    .min(0, "Digite um valor maior ou igual a 0")
    .typeError("Digite um número"),
  title: Yup.string().max(65, "O título deve ter no máximo 65 caracteres"),
  available_units: Yup.number()
    .min(1, "Digite um valor maior ou igual a 1")
    .typeError("Digite um número"),
  description: Yup.string(),
  datasheet: Yup.string(),
  delivery_provision: Yup.string(),
});

var date_regex =
  /(^((((0[1-9])|([1-2][0-9])|(3[0-1]))|([1-9]))\x2F(((0[1-9])|(1[0-2]))|([1-9]))\x2F(([0-9]{2})|(((19)|([2]([0]{1})))([0-9]{2}))))$)/;

const schemaChallenge = Yup.object().shape({
  title: Yup.string()
    .required("Informe o título")
    .max(65, "O título deve ter no máximo 65 caracteres"),
  conclusion: Yup.string("Digite uma data")
    .matches(date_regex, "Use o formato dd/mm/aa")
    .required("Item sem data de Conclusão"),

  premium: Yup.number()
    .min(0, "Digite um valor maior ou igual a 0")
    .required("Informe o valor")
    .integer("Digite um número inteiro")
    .positive()
    .typeError("Digite um número inteiro"),

  description: Yup.string("Adicione uma descrição").required(
    "Item sem descrição"
  ),
  link_youtube: Yup.string(),
  to_win: Yup.string(),
  useful_links: Yup.string(),
  special_premium: Yup.string(),
});

const schemaChallengeEdit = Yup.object().shape({
  title: Yup.string()
    .required("Informe o título")
    .max(65, "O título deve ter no máximo 65 caracteres"),
  conclusion: Yup.string("Digite uma data")
    .matches(date_regex, "Use o formato dd/mm/aa")
    .required("Item sem data de Conclusão"),

  premium: Yup.number().notRequired(),
  description: Yup.string("Adicione uma descrição").required(
    "Item sem descrição"
  ),
  link_youtube: Yup.string(),
  to_win: Yup.string(),
  useful_links: Yup.string(),
  special_premium: Yup.string(),
});

const schemaCompany = Yup.object().shape({
  name: Yup.string().required("Nome é Obrigatório"),
  phone: Yup.string().required("Telefone é Obrigatório"),
  email: Yup.string().email().required("Email é Obrigatório"),
  cnpj: Yup.string().required("CNPJ é Obrigatório"),
  website: Yup.string().required("Informe este campo"),
  about: Yup.string().required("Informe este campo"),
  adress: Yup.string().required("Informe este campo"),
  neighborhood: Yup.string().required("Informe este campo"),
  city: Yup.string().required("Informe este campo"),
  cep: Yup.string().required("Informe este campo"),
  whatsapp: Yup.string(),
  facebook: Yup.string(),
  youtube: Yup.string(),
  representative_name: Yup.string(),
  representative_phone: Yup.string(),
  representative_email: Yup.string(),
  representative_cpf: Yup.string(),
  twitter: Yup.string(),
  linkedin: Yup.string(),
  instagram: Yup.string(),
  avatar_id: Yup.number(),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CompanyProfile() {
  let query = useQuery();
  var register = query.get("register");

  const [edit, setEdit] = useState(() =>
    register !== null && (register === "challenge" || "product") ? false : true
  );
  const [donations, setDonations] = useState(() => false);
  const [challenges, setChallenges] = useState(() =>
    register !== null && register === "challenge" ? true : false
  );
  const [collaborations, setCollaborations] = useState(() => false);
  const [historic, setHistoric] = useState(() => false);
  const [shopping, setShopping] = useState(() => false);
  const [myProducts, setMyProducts] = useState(() =>
    register !== null && register === "product" ? true : false
  );
  const [newProduct, setNewProduct] = useState(() => false);
  const [newChallenge, setNewChallenge] = useState(() => false);
  const [editMyChallenge, setEditMyChallenge] = useState(() => false);
  const [editMyProduct, setEditMyProduct] = useState(() => false);
  const [champion, setChampion] = useState(() => false);
  const [shoopers, setShoppers] = useState(false);
  const [specialPremium, setSpecialPremium] = useState(false);

  const [myCollaborations, setMyCollaborations] = useState([]);

  const { perfil, token } = store.getState().auth;

  const { profile } = store.getState().user;

  const [extracts, setExtracts] = useState([]);
  const [myShoppings, setMyShoppings] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadMyCollaborations = async () => {
      if (!!token) {
        const res = await api.get(
          `/collaborations/${profile && profile.id}/${2}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyCollaborations(data);
      }
    };
    loadMyCollaborations();
  }, [profile, token]);

  useEffect(() => {
    const loadMyShoppings = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-shoppings/${profile && profile.id}/${2}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));

        setMyShoppings(data);
      }
    };
    loadMyShoppings();
  }, [profile, token]);

  function handleEditClick() {
    if (edit === false) {
      setEdit(!edit);

      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (newProduct === true) {
        setNewProduct(!newProduct);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleDonationsClick() {
    if (donations === false) {
      setDonations(!donations);

      if (edit === true) {
        setEdit(!edit);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (newProduct === true) {
        setNewProduct(!newProduct);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleChallengesClick() {
    if (challenges === false) {
      setChallenges(!challenges);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (newProduct === true) {
        setNewProduct(!newProduct);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleCollaborationsClick() {
    if (collaborations === false) {
      setCollaborations(!collaborations);

      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (edit === true) {
        setEdit(!edit);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (newProduct === true) {
        setNewProduct(!newProduct);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleHistoricClick() {
    if (historic === false) {
      setHistoric(!historic);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (newProduct === true) {
        setNewProduct(!newProduct);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleShoppingClick() {
    if (shopping === false) {
      setShopping(!shopping);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (newProduct === true) {
        setNewProduct(!newProduct);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleMyProductsClick() {
    if (myProducts === false) {
      setMyProducts(!myProducts);

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (newProduct === true) {
        setNewProduct(!newProduct);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleNewProductClick() {
    if (newProduct === false) {
      setNewProduct(!newProduct);

      setNewItem("");

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (champion === true) {
        setChampion(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  function handleNewChallengeClick() {
    if (newChallenge === false) {
      setNewChallenge(!newChallenge);
      setNewItem("");

      if (edit === true) {
        setEdit(!edit);
      }
      if (donations === true) {
        setDonations(!donations);
      }
      if (challenges === true) {
        setChallenges(!challenges);
      }
      if (collaborations === true) {
        setCollaborations(!collaborations);
      }
      if (historic === true) {
        setHistoric(!historic);
      }
      if (shopping === true) {
        setShopping(!shopping);
      }
      if (myProducts === true) {
        setMyProducts(!myProducts);
      }
      if (newChallenge === true) {
        setNewChallenge(!newChallenge);
      }
      if (editMyChallenge === true) {
        setEditMyChallenge(!editMyChallenge);
      }
      if (editMyProduct === true) {
        setEditMyProduct(!editMyProduct);
      }
      if (champion === true) {
        setEditMyProduct(!champion);
      }
      if (shoopers === true) {
        setShoppers(!shoopers);
      }
      if (aboutCompany === true) {
        setAboutCompany(!aboutCompany);
      }
      if (specialPremium === true) {
        setSpecialPremium(!specialPremium);
      }
    }
  }

  const [shoopersProduct, setShoppersProduct] = useState([]);

  async function getShoppers(id) {
    const res = await api.get(`/shoppers/${id}`);
    const data = res.data.map((project) => ({
      ...project,
      formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
        locale: pt,
      }),
    }));

    setShoppersProduct(data);
  }

  let history = useHistory();

  function handleSignOut() {
    dispatch(signOut());
    setTimeout(function () {
      history.push("/register");
    }, 1000);
  }

  function handleSubmit(data) {
    data.perfil = perfil;

    if (selectedOption) {
      data.state = selectedOption;
    }

    if (selectedOptionSex) {
      data.representative_sex = selectedOptionSex;
    }

    dispatch(updateProfileRequest(data));
  }
  const optionsSex = ["Feminino", "Masculino", "Outro", "Não Informar"];
  const optionsStates = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const [isOpenSex, setIsOpenSex] = useState(false);
  const [selectedOptionSex, setSelectedOptionSex] = useState(null);
  const togglingSex = () => setIsOpenSex(!isOpenSex);
  const onOptionClickedSex = (value) => () => {
    setSelectedOptionSex(value);
    setIsOpenSex(false);
  };

  // -------------CATEGORY--------------------
  const optionsCategory = ["Produto", "Serviço", "Experiência"];

  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState(null);

  const togglingCategory = () => setIsOpenCategory(!isOpenCategory);

  const onOptionClickedCategory = (value) => () => {
    setSelectedOptionCategory(value);
    setIsOpenCategory(false);
  };

  // ------------------------ FILES----------------------
  const { registerField } = useField("first_img");

  const ref = useRef();

  const [items, setItems] = useState([]);

  const addItem = (newItem) => {
    setItems([
      ...items,
      {
        id: newItem.id,
        url: newItem.url,
      },
    ]);
  };

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: "first_img",
        ref: ref.current,
        path: "dataset.file",
      });
    }
  }, [ref, registerField]);

  async function handleChange(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    addItem(newfile);
  }
  function deleteImg(item) {
    const newList = items.filter((items) => items.id !== item.id);
    setItems(newList);
    api.delete(`files/${item.id}`);
  }

  async function handleSubmitProduct(data) {
    data.company_id = profile.id;

    if (selectedOptionCategory) {
      data.category = selectedOptionCategory;
    } else {
      toast.error("Selecione a categoria do item!");
    }

    if (items.length !== 3) {
      toast.error("São necessárias 3 imagens por item!");
    } else {
      data.current_quantity = data.available_units;
      data.first_img = items[0].id;
      data.second_img = items[1].id;
      data.third_img = items[2].id;
    }

    if (selectedOptionCategory && items.length === 3) {
      try {
        await api.post("products", data);
        toast.success("Sucesso ao cadastrar produto!");

        setNewItem("");
        setTitleProduct("");

        setTimeout(function () {
          history.go(0);
        }, 1500);
      } catch (error) {
        toast.error("Erro ao cadastrar produto!");
      }
    }
  }

  // ------------------CHALLENGE--------------------------

  async function handleSubmitChallenge(data) {
    data.company_id = profile.id;

    if (items.length !== 3) {
      toast.error("São necessárias 3 imagens por desafio!");
    } else {
      data.first_img = items[0].id;
      data.second_img = items[1].id;
      data.third_img = items[2].id;
    }

    if (selectedOptionCategoryChallenge) {
      data.category = selectedOptionCategoryChallenge;
    } else {
      toast.error("Informe a categoria do desafio!");
    }

    if (parseFloat(data.premium) > profile.whuffie) {
      toast.error("Moedas whuffie insuficentes!");
    } else {
      if (items.length === 3 && selectedOptionCategoryChallenge) {
        try {
          await api.post("challenges", data);
          toast.success("Sucesso ao cadastrar desafio!");

          setNewItem("");
          setTitleChallenge("");

          const dataCredit = Object.assign({
            perfil,
            id: profile.id,
            value: parseFloat(data.premium),
          });

          dispatch(debitRequest(dataCredit));

          setTimeout(function () {
            history.go(0);
          }, 1500);
        } catch (error) {
          toast.error("Erro ao cadastrar desafio!");
        }
      }
    }
  }

  // --------------------CHALLENGES----------------------------

  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(4);

  useEffect(() => {
    const loadMyChallenges = async () => {
      const res = await api.get(`my-challenges/${profile && profile.id}`);
      setCountries(res.data);
    };
    loadMyChallenges();
  }, [profile]); // [] avoid useEffect() to run and make an never ended loop at each updates

  //get the currents countries to display in page

  const paginate = (number) => setCurrentPage(number);

  const indexLastCountries = currentPage * countriesPerPage;
  const indexFirstCountries = indexLastCountries - countriesPerPage;
  const currentCountries = countries.slice(
    indexFirstCountries,
    indexLastCountries
  );

  // -----------------PRODUCTS------------------
  const [countriesProduct, setCountriesProduct] = useState([]);
  const [countriesPerPageProduct] = useState(4);

  useEffect(() => {
    const loadMyProducts = async () => {
      const res = await api.get(`my-products/${profile && profile.id}`);

      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setCountriesProduct(data);
    };
    loadMyProducts();
  }, [profile]); // [] avoid useEffect() to run and make an never ended loop at each updates

  const indexLastCountriesProduct = currentPage * countriesPerPageProduct;
  const indexFirstCountriesProduct =
    indexLastCountriesProduct - countriesPerPageProduct;
  const currentCountriesProduct = countriesProduct.slice(
    indexFirstCountriesProduct,
    indexLastCountriesProduct
  );

  // const [editChallenge, setEditChallenge] = useState(null);
  const [getChallenge, setGetChallenge] = useState(null);
  const [getProduct, setGetProduct] = useState(null);

  // ------------------UPDATE-PRODUCT--------------------------

  async function handleUpdateProduct(data) {
    try {
      data.first_img = arrayEdit[0].id;
      data.second_img = arrayEdit[1].id;
      data.third_img = arrayEdit[2].id;

      if (selectedOptionCategory) {
        data.category = selectedOptionCategory;
      }

      if (
        data.first_img === null ||
        data.second_img === null ||
        data.third_img === null
      ) {
        toast.error("Erro ao editar Produto!");
      } else {
        await api.put(`product/${getProduct && getProduct[0].id}`, data);
        toast.success("Produto Atualizado");

        setNewItem("");

        setTimeout(function () {
          history.go(0);
        }, 1500);
      }
    } catch (error) {
      toast.error("Erro ao editar Produto!");
    }
  }

  async function handleUpdateChallenge(data) {
    try {
      data.first_img = arrayEdit[0].id;
      data.second_img = arrayEdit[1].id;
      data.third_img = arrayEdit[2].id;

      if (selectedOptionCategoryChallenge) {
        data.category = selectedOptionCategoryChallenge;
      }

      if (
        data.first_img === null ||
        data.second_img === null ||
        data.third_img === null
      ) {
        toast.error("Erro ao editar Desafio!");
      } else {
        await api.put(`challenge/${getChallenge && getChallenge[0].id}`, data);
        toast.success("Desafio Atualizado");

        setNewItem("");

        setTimeout(function () {
          history.go(0);
        }, 1500);
      }
    } catch (error) {
      toast.error("Erro ao editar Desafio!");
    }
  }

  const [arrayEdit, setArrayEdit] = useState([]);
  var auxArrayEdit = [];

  const addArr = (newItem) => {
    setArrayEdit([
      ...arrayEdit,
      {
        id: newItem.id,
        url: newItem.url,
      },
    ]);
  };

  async function handleChangeEdit(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    addArr(newfile);
  }

  const [doubts, setDoubts] = useState([]);

  async function addDoubts(id) {
    const res = await api.get(`/doubts/${id}`);

    const data = res.data.map((project) => ({
      ...project,
      formattedDate: format(parseISO(project.createdAt), "dd'/'MM'", {
        locale: pt,
      }),
    }));

    setDoubts(data);
  }

  async function handleCreateDoubt(data) {
    data.user = getChallenge && getChallenge[0].company.name;
    data.challenge_id = getChallenge && getChallenge[0].id;
    data.answer = true;
    try {
      await api.post("/doubts", data);
      toast.success("Dúvida enviada!");
      setTimeout(function () {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro no envio da Dúvida!");
    }
  }

  async function deleteDoubt(id) {
    try {
      await api.delete(`/doubts/${id}`);
      toast.success("Dúvida excluida!");
      setTimeout(function () {
        history.go(0);
      }, 1500);
    } catch (error) {
      toast.error("Erro ao excluir  Dúvida!");
    }
  }

  const [champs, setChamps] = useState([]);
  const [currentPageChamps, setCurrentPageChamps] = useState(1);
  const [champsPerPage] = useState(2);

  const paginateChamps = (number) => setCurrentPageChamps(number);

  async function handleGetChamps(id) {
    const res = await api.get(`/send-challenge/${id}`);

    const data = res.data.map((callenge) => ({
      ...callenge,
      formattedDate: format(parseISO(callenge.createdAt), "dd'/'MM'", {
        locale: pt,
      }),
    }));

    setChamps(data);
  }

  const indexLastchamps = currentPageChamps * champsPerPage;
  const indexFirstChamps = indexLastchamps - champsPerPage;
  const currentChamps = champs.slice(indexFirstChamps, indexLastchamps);

  // -------------CATEGORY--------------------
  var optionsCategoryChallenge = [
    "Agronegócio",
    "Meio Ambiente e Sustentabilidade",
    "Indústria",
    "Comércio e prestação de serviços",
    "Desenvolvimento Urbano",
    "Artes e Cultura",
    "Ciência e Tecnologia",
    "Economia",
    "Educação",
    "Saúde",
    "Filosofia e Sociologia",
    "Entretenimento",
    "Infantil",
    "Internet",
    "Negócios e Oportunidades",
    "Política",
    "Produtos e Serviços",
    "Responsabilidade Social",
    "Mídia e Comunicação",
    "Outros",
  ];

  const [newCategories, setNewCategories] = useState([]);

  useEffect(() => {
    const loadNewCategories = async () => {
      const res = await api.get("new-categories");
      setNewCategories(res.data);
    };
    loadNewCategories();
  }, []); // [] avoid useEffect() to run and make an never ended loop at each updates

  newCategories.map((item) => optionsCategoryChallenge.push(item.category));

  const [isOpenCategoryChallenge, setIsOpenCategoryChallenge] = useState(false);

  const [selectedOptionCategoryChallenge, setSelectedOptionCategoryChallenge] =
    useState(null);

  const togglingCategoryChallenge = () =>
    setIsOpenCategoryChallenge(!isOpenCategoryChallenge);

  const onOptionClickedCategoryChallenge = (value) => () => {
    setSelectedOptionCategoryChallenge(value);
    setIsOpenCategoryChallenge(false);
  };

  async function beChamp(champ) {
    if (getChallenge[0].winner) {
      toast.error("O desafio já possui um campeão");
    } else {
      try {
        var auxId = getChallenge[0].id;
        await api.put(`champion/${auxId}`, champ);
        toast.success("O desafio tem um campeão!");
        setTimeout(function () {
          history.go(0);
        }, 1500);
      } catch (error) {
        toast.error("Erro ao declarar campeão");
      }
    }
  }

  const [myDonations, setMyDonations] = useState([]);
  const [cosmos, setCosmos] = useState([]);

  useEffect(() => {
    const loadMyDonations = async () => {
      const res = await api.get(`/my-donations/${profile && profile.id}/${2}`);
      const data = res.data.map((project) => ({
        ...project,
        formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
          locale: pt,
        }),
      }));

      setMyDonations(data);
    };
    loadMyDonations();
  }, [profile]);

  useEffect(() => {
    const loadMyDonationsCosmos = async () => {
      if (!!token) {
        const res = await api.get(
          `/my-donations-cosmos/${profile && profile.id}/${2}`
        );
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setCosmos(data);
      }
    };
    loadMyDonationsCosmos();
  }, [profile, token]);

  useEffect(() => {
    const loadMyExtracts = async () => {
      if (!!token) {
        const res = await api.get(`/extracts/${profile && profile.id}/${2}`);
        const data = res.data.map((project) => ({
          ...project,
          formattedDate: format(parseISO(project.createdAt), "dd'/'MM'/'yy", {
            locale: pt,
          }),
        }));
        setExtracts(data);
      }
    };
    loadMyExtracts();
  }, [profile, token]);

  const [currentPageMyDonatios, setCurrentPageMyDonatios] = useState(1);
  const [countriesPerPageMyDonatios] = useState(1);

  const paginateMyDonatios = (number) => setCurrentPageMyDonatios(number);

  const indexLastMyDonatios =
    currentPageMyDonatios * countriesPerPageMyDonatios;
  const indexFirstMyDonatios = indexLastMyDonatios - countriesPerPageMyDonatios;
  const currentMyDonatios = myDonations.slice(
    indexFirstMyDonatios,
    indexLastMyDonatios
  );

  const [btnProjectDonations, setBtnProjectDonations] = useState(true);
  const [btnCosmosDonations, setBtnCosmosDonations] = useState(false);

  const toggleBtnProject = () => {
    if (!btnProjectDonations && btnCosmosDonations) {
      setBtnProjectDonations(true);
      setBtnCosmosDonations(false);
    }
  };

  const toggleBtnCosmos = () => {
    if (!btnCosmosDonations && btnProjectDonations) {
      setBtnCosmosDonations(true);
      setBtnProjectDonations(false);
    }
  };

  const [currentPageMyDonatiosCosmos, setCurrentPageMyDonatiosCosmos] =
    useState(1);
  const [countriesPerPageMyDonatiosCosmos] = useState(1);

  const paginateMyDonatiosCosmos = (number) =>
    setCurrentPageMyDonatiosCosmos(number);

  const indexLastMyDonatiosCosmos =
    currentPageMyDonatiosCosmos * countriesPerPageMyDonatiosCosmos;
  const indexFirstMyDonatiosCosmos =
    indexLastMyDonatiosCosmos - countriesPerPageMyDonatiosCosmos;
  const currentMyDonatiosCosmos = cosmos.slice(
    indexFirstMyDonatiosCosmos,
    indexLastMyDonatiosCosmos
  );

  const [currentPageExtracts, setCurrentPageExtracts] = useState(1);
  const [countriesPerPageExtracts] = useState(8);

  const paginateExtracts = (number) => setCurrentPageExtracts(number);

  const indexLastExtracts = currentPageExtracts * countriesPerPageExtracts;
  const indexFirstExtracts = indexLastExtracts - countriesPerPageExtracts;
  const currentExtracts = extracts.slice(indexFirstExtracts, indexLastExtracts);

  /*    PAGINATION USER COLLABORATIONS       */

  const [currentPageCollaborations, setCurrentPageCollaborations] = useState(1);
  const [countriesPerPageCollaborations] = useState(8);

  const paginateCollaborations = (number) =>
    setCurrentPageCollaborations(number);

  const indexLastCollaborations =
    currentPageCollaborations * countriesPerPageCollaborations;
  const indexFirstCollaborations =
    indexLastCollaborations - countriesPerPageCollaborations;
  const currentCollaborations = myCollaborations.slice(
    indexFirstCollaborations,
    indexLastCollaborations
  );

  /*    PAGINATION USER MY SHOPPINGS       */

  const [currentPageShoppings, setCurrentPageShoppings] = useState(1);
  const [countriesPerPageShoppings] = useState(4);

  const paginateShoppings = (number) => setCurrentPageShoppings(number);

  const indexLastShoppings = currentPageShoppings * countriesPerPageShoppings;
  const indexFirstShoppings = indexLastShoppings - countriesPerPageShoppings;
  const currentShoppings = myShoppings.slice(
    indexFirstShoppings,
    indexLastShoppings
  );

  /*    PAGINATION SHOPPER       */

  const [currentPageShoppers, setCurrentPageShoppers] = useState(1);
  const [countriesPerPageShoppers] = useState(4);

  const paginateShoppers = (number) => setCurrentPageShoppers(number);

  const indexLastShoppers = currentPageShoppers * countriesPerPageShoppers;
  const indexFirstShoppers = indexLastShoppers - countriesPerPageShoppers;
  const currentShoppers = shoopersProduct.slice(
    indexFirstShoppers,
    indexLastShoppers
  );

  const [newCategory, setNewCategory] = useState(false);

  const toogleNewCategory = () => setNewCategory(!newCategory);

  const [newCategoryValue, setNewCategoryValue] = useState("");

  async function handleNewCategory() {
    if (newCategoryValue === "") {
      toast.error("Complete as informações");
    } else {
      var data = Object.assign({
        category: newCategoryValue,
        company_id: profile.id,
        name: profile.name,
      });
      try {
        await api.post("categories", data);
        toast.success("Solicitação enviada!");
      } catch (error) {
        toast.error("Erro ao enviar nova categoria!");
      }
      setNewCategory(false);
    }
  }

  const [aboutCompany, setAboutCompany] = useState(false);
  const [companyInfos, setCompanyInfos] = useState(null);

  const toggle = () => setAboutCompany(true);

  const [content, setContent] = useState(
    profile && profile.about !== null ? profile.about : ""
  );
  const [newItem, setNewItem] = useState("");
  const [titleChallenge, setTitleChallenge] = useState("");
  const [titleProduct, setTitleProduct] = useState("");

  const setFormattedContent = useCallback(
    (text) => {
      setContent(text.slice(0, 145));
    },
    [setContent]
  );

  const setFormattedContentChallenge = useCallback(
    (text) => {
      setTitleChallenge(text.slice(0, 65));
    },
    [setTitleChallenge]
  );

  const setFormattedContentProduct = useCallback(
    (text) => {
      setTitleProduct(text.slice(0, 65));
    },
    [setTitleProduct]
  );

  async function updateStatusProdut(id, current, status) {
    if (current === status) {
      toast.error("A compra já possui este status!");
    } else {
      try {
        await api.put(`/change-status/${id}`);
        toast.success("Status atualizado!");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      } catch (error) {
        toast.error("Erro ao atualizar status!");
      }
    }
  }

  const [userSpecialPremium, setUserSpecialPremium] = useState([]);

  async function submitNotification(data) {
    var submit = Object.assign({
      user_id: userSpecialPremium.user,
      profile: userSpecialPremium.perfil,
      title: data.title,
      content: data.content,
      link: `challenge/${userSpecialPremium.challenge_id}`,
    });

    try {
      await api.post("/notifications", submit);
      toast.success("Notificação enviada!");
      setTimeout(() => {
        history.go(0);
      }, 2000);
    } catch (error) {
      toast.error("Erro!");
    }
  }

  return (
    <>
      <Helmet>
        <title>Perfil Empresarial</title>
      </Helmet>
      <Wrapper>
        <Content>
          <Header />

          <ProfileContent>
            <aside>
              <BoxProfile>
                <img src={iconProfile} alt="" />
                <div>
                  <p id="title">Perfil</p>
                  <p id="normal">Empresarial</p>
                </div>
              </BoxProfile>

              <div className="avatar">
                <div className="avatar-img">
                  <div className="border">
                    <div className="img-content">
                      {profile && profile.avatar !== null ? (
                        <img
                          id="img-profile"
                          src={profile && profile.avatar.url}
                          alt=""
                        />
                      ) : (
                        <img
                          id="without-img-profile"
                          src={iconAvatarInput}
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  <div className="pencil">
                    <img src={iconPerfil} alt="" />
                  </div>
                </div>

                <hr />
                <div className="infos">
                  {profile && <p>{profile.name.split(" ")[0]}</p>}

                  {profile && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <IntlProvider locale="pt-BR">
                          <p>
                            <FormattedNumber
                              minimumFractionDigits={2}
                              value={profile.whuffie}
                            />
                          </p>
                        </IntlProvider>
                        <p id="whuffie" style={{ marginLeft: 5 }}>
                          {" "}
                          W${" "}
                        </p>
                      </div>
                      <p style={{ fontStyle: "italic", color: "#ffe" }}>
                        Saldo Whuffie
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="menu-profile">
                <div className="menu">
                  <MenuList active={edit} onClick={handleEditClick}>
                    Editar Dados da Minha Empresa
                  </MenuList>
                  <MenuList active={donations} onClick={handleDonationsClick}>
                    Minhas Doações
                  </MenuList>

                  <MenuList
                    active={collaborations}
                    onClick={handleCollaborationsClick}
                  >
                    Minhas Colaborações
                  </MenuList>
                  <MenuList active={historic} onClick={handleHistoricClick}>
                    Minhas Moedas Whuffie
                  </MenuList>
                  <MenuList
                    active={shopping || aboutCompany}
                    onClick={handleShoppingClick}
                  >
                    Minhas Compras Whuffie
                  </MenuList>
                  <MenuList
                    active={
                      myProducts || newProduct || editMyProduct || shoopers
                    }
                    onClick={handleMyProductsClick}
                  >
                    Meus Produtos/Serviços/XP
                  </MenuList>
                  <MenuList
                    active={
                      challenges ||
                      newChallenge ||
                      editMyChallenge ||
                      champion ||
                      specialPremium
                    }
                    onClick={handleChallengesClick}
                  >
                    Meus Desafios
                  </MenuList>
                  <MenuList
                    onClick={() => {
                      setTimeout(() => {
                        history.push("contact?&type=support");
                      }, 300);
                    }}
                  >
                    Suporte W4S
                  </MenuList>
                </div>
              </div>

              <button id="btn-company-profile" onClick={handleSignOut}>
                Cadastrar em outro Perfil / Sair
              </button>
              <button
                id="btn-company-profile"
                onClick={() => {
                  history.push("delete-profile");
                }}
              >
                Excluir Perfil
              </button>
            </aside>

            {edit && (
              <Fade left duration={1600} distance="20px">
                <EditComponent>
                  <p id="title">Editar Dados Empresariais</p>
                  <p className="text">
                    Aqui você pode editar suas informações empresariais e
                    preferências sempre que quiser!
                    <br />E lembre-se: se surgir alguma dúvida, consulte o FAQ
                    ou envie uma mensagem para nós pela página "Fale Conosco".
                  </p>

                  <div
                    style={{
                      border: "2px dashed #eee",
                      padding: 15,
                      borderRadius: 15,
                    }}
                  >
                    <p className="text">
                      {" "}
                      <strong>IMPORTANTE</strong>:<br />
                      <br />
                      Para cadastrar produtos, serviços e experiências no{" "}
                      <Link
                        to={"/store"}
                        style={{ color: "#227af1", fontWeight: 800 }}
                      >
                        MERCADO WHUFFIE
                      </Link>{" "}
                      é preciso, ANTES, preencher os campos obrigatórios do
                      Perfil Empresarial (abaixo). Depois, clique em{" "}
                      <strong style={{ color: "#227af1" }}>
                        Meus Produtos/Serviços/XP
                      </strong>{" "}
                      (na coluna ao lado) em seguida, em CADASTRAR.
                      <br />
                      <br /> Para lançar DESAFIOS também é preciso, ANTES,
                      preencher os campos obrigatórios do Perfil Empresarial
                      (abaixo). Depois, clique em{" "}
                      <strong style={{ color: "#227af1" }}>
                        {" "}
                        Meus Desafios
                      </strong>{" "}
                      (na coluna ao lado) em seguida, em CRIAR.
                    </p>
                  </div>

                  <div className="form">
                    <Form
                      initialData={profile}
                      schema={schemaCompany}
                      onSubmit={handleSubmit}
                    >
                      <p id="title">Dados da Minha Empresa</p>
                      <div className="avatar">
                        <AvatarInput name="avatar_id" />
                        <div className="pencil">
                          <img src={iconPencil} alt="" />
                        </div>
                        <p id="input-photo">Inserir foto de perfil</p>
                      </div>
                      <p style={{ color: "#7f7fb9", fontSize: "0.9rem" }}>
                        obs: utilize uma imagem com pelo menos 112x112 pixels
                      </p>

                      <div className="inputs">
                        <InputContent margin>
                          <Input
                            type="text"
                            placeholder="Nome Fantasia: *"
                            name="name"
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            type="text"
                            placeholder="Telefone (com DDD): * (ddd) 1234-5678"
                            name="phone"
                          />
                        </InputContent>
                        <InputContent margin>
                          <Input
                            type="text"
                            placeholder="Seu melhor e-mail: *"
                            name="email"
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            type="text"
                            placeholder="CNPJ: *"
                            name="cnpj"
                          />
                        </InputContent>
                        <InputContent margin>
                          <Input
                            type="text"
                            placeholder="Website: *"
                            name="website"
                          />
                        </InputContent>
                        <Input
                          type="text"
                          placeholder="Whatsapp:"
                          name="whatsapp"
                        />

                        <div className="box-goal">
                          <p id="attention">ATENÇÃO:</p> 
                          <p id="text">
                            Cadastre os links para suas redes sociais (Facebook,
                            Instagram, Twitter, Youtube e Linkedin) para que
                            mais apoiadores, cientistas e empresas possam
                            encontrar sua organização!
                          </p>
                        </div>
                        <div className="box-obs-social">
                          <p>
                            Você deve preencher suas redes sociais com o link
                            completo, por exemplo:
                            <br />
                            https://www.facebook.com/We4Science/
                          </p>
                        </div>
                        <Input
                          type="text"
                          placeholder="Seu Facebook :"
                          name="facebook"
                        />
                        <Input
                          type="text"
                          placeholder="Seu Perfil/Canal no Youtube:"
                          name="youtube"
                        />
                        <Input
                          type="text"
                          placeholder="Seu Twitter:"
                          name="twitter"
                        />
                        <Input
                          type="text"
                          placeholder="Seu Linkedin:"
                          name="linkedin"
                        />

                        <Input
                          type="text"
                          placeholder="Seu Instagram:"
                          name="instagram"
                        />

                        <div className="box-security-datas">
                          <p>
                            <strong>Não se preocupe!</strong>
                            <br />
                            <br /> A We4Science não compartilha seus documentos
                            e informações pessoais, como CNPJ, endereço e
                            telefone de contato.
                          </p>
                        </div>

                        <div className="id">
                          <p>ID do usuário: {profile && profile.id}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="about-company">
                        <p>Sobre a Empresa</p>
                        <InputContent>
                          <Textarea
                            name="about"
                            className="textarea"
                            placeholder="Descreva a empresa: *"
                            maxLength={145}
                            onChange={(event) =>
                              setFormattedContent(event.target.value)
                            }
                            value={content}
                          />
                        </InputContent>
                        <p style={{ fontSize: 16 }}>
                          {content.length} / {145} Caracteres
                        </p>
                      </div>
                      <hr />
                      <div className="about-company">
                        <p>Dados pessoais do responsável pelo Perfil</p>

                        <div className="inputs">
                          <Input
                            type="text"
                            placeholder="Nome:"
                            name="representative_name"
                          />
                          <DropDownContainer>
                            <DropDownHeader onClick={togglingSex}>
                              <p id="text-state" className="small">
                                {selectedOptionSex ||
                                  (profile && profile.representative_sex != null
                                    ? profile.representative_sex
                                    : "sexo")}
                              </p>
                              {isOpenSex ? (
                                <img
                                  className="icon-drop"
                                  id="up-icon"
                                  src={iconDrop}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="icon-drop"
                                  src={iconDrop}
                                  alt=""
                                />
                              )}
                            </DropDownHeader>
                            {isOpenSex && (
                              <DropDownListContainer>
                                <DropDownList>
                                  <Scrollbars style={{ flex: 1, height: 50 }}>
                                    {optionsSex.map((option) => (
                                      <ListItem
                                        onClick={onOptionClickedSex(option)}
                                        key={Math.random()}
                                      >
                                        {option}
                                      </ListItem>
                                    ))}
                                  </Scrollbars>
                                </DropDownList>
                              </DropDownListContainer>
                            )}
                          </DropDownContainer>

                          <Input
                            type="text"
                            placeholder="Telefone (com DDD): (ddd) 1234-5678"
                            name="representative_phone"
                          />
                          <Input
                            type="text"
                            placeholder="Seu melhor Email:"
                            name="representative_email"
                          />

                          <Input
                            type="text"
                            placeholder="Seu CPF:"
                            name="representative_cpf"
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="adress">
                        <p>Endereço</p>

                        <div className="inputs-adress">
                          <InputContent margin>
                            <Input
                              type="text"
                              placeholder="Logradouro: *"
                              name="adress"
                            />
                          </InputContent>

                          <InputContent>
                            <Input
                              type="text"
                              placeholder="Bairro: *"
                              name="neighborhood"
                            />
                          </InputContent>

                          <DropDownContainer>
                            <DropDownHeader
                              className="estado"
                              onClick={toggling}
                            >
                              <p id="text-state" className="small">
                                {selectedOption ||
                                  (profile && profile.state != null
                                    ? profile.state
                                    : "Estado *")}
                              </p>
                              {isOpen ? (
                                <img
                                  className="icon-drop"
                                  id="up-icon"
                                  src={iconDrop}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="icon-drop"
                                  src={iconDrop}
                                  alt=""
                                />
                              )}
                            </DropDownHeader>
                            {isOpen && (
                              <DropDownListContainerState className="state-input">
                                <DropDownList>
                                  <Scrollbars
                                    style={{ width: 145, height: 50 }}
                                  >
                                    {optionsStates.map((option) => (
                                      <ListItem
                                        big
                                        onClick={onOptionClicked(option)}
                                        key={Math.random()}
                                      >
                                        {option}
                                      </ListItem>
                                    ))}
                                  </Scrollbars>
                                </DropDownList>
                              </DropDownListContainerState>
                            )}
                          </DropDownContainer>

                          <InputContent small margin>
                            <Input
                              type="text"
                              placeholder="Cidade: *"
                              name="city"
                              className="city"
                            />
                          </InputContent>
                          <InputContent small>
                            <Input
                              type="text"
                              className="CEP"
                              placeholder="CEP: *"
                              name="cep"
                            />
                          </InputContent>
                        </div>
                      </div>
                      <hr />
                      <div className="security">
                        <p>Dados de segurança</p>
                        <div className="inputs-security">
                          <Input
                            type="password"
                            placeholder="Senha:"
                            name="password"
                          />
                          <Input
                            type="password"
                            placeholder="Confirmar Senha:"
                            name="confirmPassword"
                          />
                        </div>
                      </div>
                      <p
                        style={{
                          float: "left",
                          color: "#7f7fb9",
                          marginLeft: 20,
                          marginBottom: 10,
                        }}
                      >
                        *Campo obrigatório
                      </p>
                      <button type="submit">Salvar</button>
                    </Form>
                  </div>
                </EditComponent>
              </Fade>
            )}

            {donations && (
              <Fade left duration={1600} distance="20px">
                <DonationsComponent>
                  <p id="title">Minhas doações</p>
                  <p className="text">
                    Aqui você consulta todas as doações que já fez em nossa
                    plataforma.
                    <br />E lembre-se: além de ajudar no desenvolvimento de
                    <Link
                      to="projects"
                      style={{
                        color: "#227af1",
                        marginLeft: 5,
                        marginRight: 5,
                        fontWeight: 800,
                      }}
                    >
                      projetos
                    </Link>{" "}
                    e pesquisas científicas importantes, suas doações também
                    rendem moedas Whuffie que você poderá usar para adquirir
                    produtos, serviços e experiências em nosso Mercado Whuffie.
                    Aproveite!
                  </p>
                  <div className="buttons">
                    <ButtonDonation
                      active={btnProjectDonations}
                      onClick={toggleBtnProject}
                    >
                      Projetos
                    </ButtonDonation>
                    <ButtonDonation
                      active={btnCosmosDonations}
                      onClick={toggleBtnCosmos}
                    >
                      Fundo Cosmos
                    </ButtonDonation>
                  </div>
                  {btnProjectDonations && (
                    <>
                      <div className="content">
                        {currentMyDonatios.length !== 0 &&
                          currentMyDonatios.map((preview) => (
                            <ProjectSecond key={preview.id}>
                              <div className="content-1">
                                <img
                                  src={
                                    preview.project &&
                                    preview.project.first &&
                                    preview.project.first.url
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content-2">
                                <div className="titles">
                                  <p id="title-project">
                                    {preview.project && preview.project.name}
                                  </p>
                                  {preview && preview.project.special ? (
                                    <div>
                                      <FaLockOpen color="#227af1" size={16} />

                                      <p>Tenho Permissão Especial</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <img src={iconCompanyProject} alt="" />
                                      <p>
                                        Instituição:
                                        {preview && preview.project.institution}
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <img src={iconCheck} alt="" />
                                    <p>
                                      Categoria:
                                      {preview.project &&
                                        preview.project.category}
                                    </p>
                                  </div>
                                  <section> </section>
                                </div>
                                <div className="donation">
                                  <p> Doei para este projeto: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                              <div className="content-3">
                                <img src={atomProject} alt="" />
                                <p>
                                  <Link
                                    to={`/project/${
                                      preview.project && preview.project.id
                                    }`}
                                  >
                                    {" "}
                                    Ver projeto{" "}
                                  </Link>
                                </p>
                              </div>
                            </ProjectSecond>
                          ))}
                      </div>

                      <div className="floatPage">
                        {myDonations.length > 0 && (
                          <Pagination
                            currentPage={currentPageMyDonatios}
                            countriesPerPage={countriesPerPageMyDonatios}
                            totalCountries={myDonations.length}
                            paginate={paginateMyDonatios}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {btnCosmosDonations && (
                    <>
                      <div className="content">
                        {cosmos &&
                          currentMyDonatiosCosmos.map((preview) => (
                            <Cosmos key={preview.id}>
                              <div className="content-1">
                                <img src={logo} alt="" />
                              </div>
                              <p id="title-cosmos">Fundo Cosmos</p>
                              <div className="content-2">
                                <div className="donation">
                                  <p> Doei: </p>
                                  <IntlProvider locale="pt-BR">
                                    <p id="value">
                                      R${" "}
                                      <FormattedNumber
                                        minimumFractionDigits={2}
                                        value={preview.value}
                                      />
                                    </p>
                                  </IntlProvider>
                                  <p> Doado: {preview.formattedDate} </p>
                                </div>
                              </div>
                            </Cosmos>
                          ))}
                      </div>

                      <div className="floatPage">
                        {cosmos.length > 0 && (
                          <Pagination
                            currentPage={currentPageMyDonatiosCosmos}
                            countriesPerPage={countriesPerPageMyDonatiosCosmos}
                            totalCountries={cosmos.length}
                            paginate={paginateMyDonatiosCosmos}
                          />
                        )}
                      </div>
                    </>
                  )}
                </DonationsComponent>
              </Fade>
            )}

            {challenges && (
              <Fade left duration={1600} distance="20px">
                <ChallengesComponent>
                  <p id="title">Meus Desafios</p>
                  <p className="text">
                    Utilize esse recurso para buscar soluções, inovações e
                    oportunidades de negócio para a sua Empresa!
                    <br />
                    Lembre-se: a premiação ao Vencedor? deve ser necessariamente
                    definida em MOEDAS WHUFFIE e, para poder oferecer prêmios
                    maiores, você deve possuir um saldo Whuffie maior.
                    <br />
                    Que tal fazer alguma doação ou colaboração em um ou mais
                    projetos científicos para aumentar seu saldo Whuffie?
                  </p>
                  <div className="new-challenge">
                    <div className="main-content">
                      <p id="title-content">
                        <strong style={{ fontFamily: "GothamProRegular" }}>
                          Criar novo desafio
                        </strong>
                      </p>

                      <div className="texts">
                        <p id="simple-text">Você tem</p>
                        <p id="value-text">
                          {profile && profile.whuffie} Whuffie(s)
                        </p>
                        <p id="simple-text">para criar Desafios</p>
                      </div>
                    </div>

                    <button
                      id="btn-register"
                      onClick={() => {
                        if (profile && profile.whuffie === 0) {
                          toast.error("Quantidade de whuffies insuficiente!");
                        } else {
                          handleNewChallengeClick();
                        }
                      }}
                    >
                      Criar
                    </button>
                  </div>

                  <div className="content">
                    <MyChallenges>
                      {currentCountries.map((countries, number) => (
                        <ul className="country" key={number}>
                          <div className="challenge">
                            <Challenge>
                              <div className="challenge-content-1">
                                <img
                                  src={countries && countries.first.url}
                                  alt=""
                                />
                              </div>
                              <div className="challenge-content-2">
                                <div>
                                  <p id="title-challenge">
                                    {countries && countries.title}
                                  </p>
                                  <p id="company">
                                    Empresa:{" "}
                                    {countries && countries.company.name}
                                  </p>
                                  <p id="date">
                                    Data limite:{" "}
                                    {countries && countries.conclusion}
                                  </p>
                                  <p id="category">
                                    Categoria: {countries && countries.category}
                                  </p>
                                  <section></section>
                                </div>

                                <div className="challenge-price">
                                  <p id="price-text">Prêmio:</p>
                                  <p id="price-value">
                                    {countries && countries.premium}
                                  </p>
                                  <p id="price-value-2">,00</p>
                                  <p id="price-text">W$</p>
                                </div>
                                {countries.special_premium !== null &&
                                  countries.special_premium !== "" && (
                                    <div className="special-premium-box">
                                      <p>+</p>
                                      <p id="price-text">Prêmio Especial</p>
                                    </div>
                                  )}
                              </div>
                              <button id="challenge-content-3">
                                <img src={iconChallenge} alt="" />
                                <Link to={`/challenge/${countries.id}`}>
                                  <p>Ver Desafio</p>
                                </Link>
                              </button>
                            </Challenge>
                            <div className="edit ">
                              <div className="pencil">
                                <img src={iconPerfil} alt="" />
                              </div>
                              <button
                                id="text-edit"
                                onClick={() => {
                                  setChallenges(false);
                                  setEditMyChallenge(true);
                                  setGetChallenge(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )
                                  );

                                  auxArrayEdit.push(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0].first
                                  );
                                  auxArrayEdit.push(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0].second
                                  );
                                  auxArrayEdit.push(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0].third
                                  );
                                  setArrayEdit(auxArrayEdit);
                                  setNewItem(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0].description
                                  );
                                  addDoubts(countries.id);
                                  auxArrayEdit = [];
                                }}
                              >
                                Editar desafio
                              </button>
                            </div>
                            <div className="participants">
                              <div className="pencil">
                                <img src={iconChallenge} alt="" />
                              </div>
                              <button
                                onClick={() => {
                                  setChallenges(false);
                                  setChampion(true);
                                  setGetChallenge(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )
                                  );

                                  auxArrayEdit.push(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0].first
                                  );
                                  auxArrayEdit.push(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0].second
                                  );
                                  auxArrayEdit.push(
                                    currentCountries.filter(
                                      (challenge) =>
                                        challenge.id === countries.id
                                    )[0].third
                                  );

                                  handleGetChamps(countries.id);
                                }}
                                id="text-edit"
                              >
                                Ver participantes
                              </button>
                            </div>
                          </div>
                        </ul>
                      ))}
                    </MyChallenges>
                  </div>
                  <hr />

                  {countries.length > 0 && (
                    <div className="floatPage">
                      <Pagination
                        currentPage={currentPage}
                        countriesPerPage={countriesPerPage}
                        totalCountries={countries.length}
                        paginate={paginate}
                      />
                    </div>
                  )}
                </ChallengesComponent>
              </Fade>
            )}

            {champion && (
              <Fade left duration={1600} distance="20px">
                <ChampComponent>
                  <p id="title">Desafios Concluidos</p>

                  <p id="sub-title">
                    Título do desafio: {getChallenge[0].title}
                  </p>
                  <p id="sub-title">
                    Prêmio: W$
                    {getChallenge[0].premium.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </p>

                  <div className="be-champion">
                    <p id="title-be-champ">Instrução para premiar o campeão</p>
                    <p id="text-be-champ">{getChallenge[0].to_win}</p>
                  </div>

                  <div className="participants-content">
                    <p id="title-participants-content">Participantes</p>
                    <hr />
                    <div className="participants">
                      {currentChamps.map((preview) => (
                        <div className="item-participant" key={preview.id}>
                          <div id="participant">
                            <div>
                              <div className="icon">
                                <div id="background-img">
                                  <img src={iconDonor} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="content">
                              <p id="day">{preview.formattedDate}</p>
                              {preview.perfil === "Apoiador/Visitante"
                                ? preview.supporter && (
                                    <p id="name">{preview.supporter.name}</p>
                                  )
                                : preview.scientist && (
                                    <p id="name"> {preview.scientist.name}</p>
                                  )}
                              <div className="second-content">
                                {preview.perfil === "Apoiador/Visitante"
                                  ? preview.supporter && (
                                      <>
                                        <p>
                                          Telefone: {preview.supporter.phone}
                                        </p>
                                        <p>Email: {preview.supporter.email}</p>
                                      </>
                                    )
                                  : preview.scientist && (
                                      <>
                                        <p>
                                          Telefone: {preview.scientist.phone}
                                        </p>
                                        <p>Email: {preview.scientist.email}</p>
                                      </>
                                    )}
                              </div>
                              {preview.content && (
                                <>
                                  <hr />
                                  <p id="message-champ">{preview.content}</p>
                                  <hr />
                                </>
                              )}
                              <div className="third-content">
                                <p>
                                  Link com arquivo ou caminho da entrega do
                                  desafio:
                                </p>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${preview.link}`}
                                >
                                  {preview.link}
                                </a>
                              </div>
                            </div>
                          </div>
                          <ButtonChamp
                            onClick={() => {
                              setSpecialPremium(true);
                              setChampion(false);
                              setUserSpecialPremium(preview);
                            }}
                          >
                            <img src={iconPremium} alt="" />
                            Prêmio Especial
                          </ButtonChamp>

                          <ButtonChamp
                            champ={getChallenge[0].winner}
                            onClick={() => {
                              var aux = Object.assign({
                                champ_id: preview.user,
                                champ_perfil: preview.perfil,
                              });
                              beChamp(aux);
                            }}
                          >
                            {getChallenge[0].champ_id === preview.user &&
                            getChallenge[0].champ_perfil === preview.perfil ? (
                              <img src={iconChamp} alt="" />
                            ) : (
                              <>
                                <img src={iconChallenge} alt="" />
                                Declarar campeão
                              </>
                            )}
                          </ButtonChamp>

                          <hr />
                        </div>
                      ))}

                      {champs.length !== 0 && (
                        <div className="floatPage">
                          <Pagination
                            currentPage={currentPageChamps}
                            countriesPerPage={champsPerPage}
                            totalCountries={champs.length}
                            paginate={paginateChamps}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </ChampComponent>
              </Fade>
            )}

            {specialPremium && (
              <Fade left duration={1600} distance="20px">
                <SpecialPremiumComponent>
                  <p id="title-special">Prêmio Especial</p>
                  <p id="sub-title">
                    Envie uma notificação para o usuário, informando seu prêmio
                    especial e como entrar em contato com você.
                  </p>

                  <Form onSubmit={submitNotification}>
                    <Input name="title" placeholder="Título da Notificação" />
                    <Textarea
                      className="message"
                      name="content"
                      placeholder="Conteúdo da Notificação"
                    />
                    <button type="submit" id="btn-send">
                      Enviar
                    </button>
                  </Form>
                </SpecialPremiumComponent>
              </Fade>
            )}

            {editMyChallenge && (
              <Fade left duration={1600} distance="20px">
                <EditChallengeComponent>
                  <p id="title_challenge">Editar Desafio</p>
                  <div className="form">
                    <Form
                      initialData={getChallenge[0]}
                      schema={schemaChallengeEdit}
                      onSubmit={handleUpdateChallenge}
                    >
                      <div className="inputs">
                        <Input
                          name="title"
                          type="text"
                          className="full"
                          placeholder="Título do desafio"
                        />
                        <Input
                          name="conclusion"
                          type="text"
                          placeholder="Data limite para participação (dd/mm/aa)"
                        />
                        <div className="value-challenge">
                          <IntlProvider locale="pt-BR">
                            <p>
                              <FormattedNumber
                                minimumFractionDigits={2}
                                value={getChallenge[0].premium}
                              />
                            </p>
                            <p style={{ marginLeft: 5 }}>Whuffie</p>
                          </IntlProvider>
                        </div>

                        <Input
                          name="special_premium"
                          placeholder="Prêmio Especial:"
                        />

                        <DropDownContainer>
                          <DropDownHeader onClick={togglingCategoryChallenge}>
                            <p className="small">
                              {selectedOptionCategoryChallenge ||
                                (getChallenge[0] &&
                                getChallenge[0].category != null
                                  ? getChallenge[0].category
                                  : "Categoria")}
                            </p>
                            {isOpenCategoryChallenge ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpenCategoryChallenge && (
                            <DropDownListContainer>
                              <DropDownList>
                                <Scrollbars style={{ height: 50 }}>
                                  {optionsCategoryChallenge.map((option) => (
                                    <ListItem
                                      big
                                      onClick={onOptionClickedCategoryChallenge(
                                        option
                                      )}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>
                        <Input
                          name="link_youtube"
                          type="text"
                          placeholder="Link de vídeo do YouTube"
                        />

                        <InputContent>
                          <div className="input-imgs">
                            <p id="placeholder">Imagens</p>
                            <BoxLabel
                              active={arrayEdit.length === 3 ? true : false}
                            >
                              <label htmlFor="avatar">
                                <div>
                                  <img src={iconClips} id="clips" alt="" />
                                  <input
                                    type="file"
                                    id="avatar"
                                    accept="image/*"
                                    onChange={handleChangeEdit}
                                    disabled={
                                      arrayEdit.length === 3 ? true : false
                                    }
                                    ref={ref}
                                  />
                                </div>
                              </label>
                            </BoxLabel>
                          </div>
                          <p id="obs-input" style={{ marginTop: 10 }}>
                            Obs: Cadastre necessariamente 03 imagens (dimensões
                            recomendadas: 628x360 pixels).
                          </p>
                        </InputContent>
                      </div>

                      <div className="previews">
                        {arrayEdit.map((preview) => (
                          <div className="preview" key={preview.id}>
                            <img id="teste" src={preview.url} alt="avatar" />
                            <button
                              type="button"
                              onClick={() => {
                                deleteImg(preview);
                                setArrayEdit(
                                  arrayEdit.filter(
                                    (item) => item.id !== preview.id
                                  )
                                );
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        ))}
                      </div>

                      <Textarea
                        name="description"
                        maxLength={145}
                        className="textarea"
                        placeholder="Qual é o desafio?"
                        onChange={(event) => setNewItem(event.target.value)}
                        value={newItem}
                      />
                      <p
                        style={{
                          fontSize: 16,
                          marginBottom: 15,
                          color: "#7f7fb9",
                        }}
                      >
                        {newItem.length} / {145} Caracteres
                      </p>
                      <Textarea
                        name="useful_links"
                        className="textarea"
                        placeholder="Links úteis (Separe cada link por ';'):"
                      />
                      <Textarea
                        name="to_win"
                        className="textarea"
                        placeholder="Quais os critérios para determinar o Vencedor??"
                      />

                      <button type="submit" id="btn-register">
                        Salvar
                      </button>
                    </Form>
                  </div>

                  <div className="questions">
                    <p id="title-questions">Gerenciar dúvidas</p>
                    <hr />
                    {doubts.length > 0 && (
                      <Scrollbars style={{ width: "745px", height: 280 }}>
                        {doubts.map((question) => (
                          <div className="question" key={question.id}>
                            <Question active={question.answer}>
                              <div>
                                <div className="icon">
                                  <div id="background-img">
                                    <img src={iconDonor} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="content">
                                <p>{question.formattedDate}</p>
                                <p>{question.user}</p>
                                <p id="text">{question.content}</p>
                                <p></p>
                              </div>
                            </Question>
                            <div className="options">
                              <button
                                type="button"
                                id="btn-del"
                                onClick={() => deleteDoubt(question.id)}
                              >
                                Excluir
                              </button>
                            </div>
                            <hr />
                          </div>
                        ))}
                      </Scrollbars>
                    )}

                    <div className="write">
                      <Form onSubmit={handleCreateDoubt}>
                        <Textarea
                          className="message"
                          name="content"
                          placeholder="Escrever Mensagem..."
                        />
                        <button type="submit" id="btn-send">
                          Enviar
                        </button>
                      </Form>
                    </div>
                  </div>
                </EditChallengeComponent>
              </Fade>
            )}

            {newChallenge && (
              <Fade left duration={1600} distance="20px">
                <NewChallengeComponent>
                  <p id="title_challenge"> Cadastrar Desafio</p>
                  <p className="text">
                    Para cadastrar produtos, serviços ou experiências no{" "}
                    <Link to="store" style={{ color: "#227af1" }}>
                      Mercado Whuffie
                    </Link>{" "}
                    ou lançar{" "}
                    <Link to="challenges" style={{ color: "#227af1" }}>
                      Desafios
                    </Link>{" "}
                    em nossa plataforma, verifique se você preencheu todos os
                    campos necessários em seu Perfil Empresarial. Cadastre
                    também links para as redes sociais da sua
                    empresa/instituição (como Facebook, Instagram, Linkedin,
                    Twitter e Youtube). Assim, mais apoiadores, cientistas e
                    empresas encontrarão sua organização!
                  </p>
                  <div className="form">
                    <Form
                      onSubmit={handleSubmitChallenge}
                      schema={schemaChallenge}
                    >
                      <div className="inputs">
                        <InputContent active>
                          <Input
                            name="title"
                            type="text"
                            className="full"
                            placeholder="Título do Desafio: *"
                            onChange={(event) =>
                              setFormattedContentChallenge(event.target.value)
                            }
                            value={titleChallenge}
                            maxLength={65}
                          />
                          <p id="number-chars" style={{ fontSize: 16 }}>
                            {titleChallenge.length} / {65} Caracteres
                          </p>
                        </InputContent>

                        <InputContent>
                          <Input
                            name="conclusion"
                            type="text"
                            placeholder="Data limite para participação (dd/mm/aa): *"
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            className="value-input"
                            name="premium"
                            type="text"
                            placeholder="Prêmio Whuffie: * (não separe o valor com ponto)"
                          />
                        </InputContent>

                        <InputContent>
                          <Input
                            name="special_premium"
                            placeholder="Prêmio Especial:"
                          />
                        </InputContent>

                        <div className="box-goal">
                          <p id="attention">ATENÇÃO:</p>
                          <p id="text">
                            É necessário que o usuário possua uma quantidade
                            igual ou superior ao valor da premiação em moedas
                            Whuffie para que o Desafio seja criado!
                          </p>
                        </div>

                        <DropDownContainer>
                          <DropDownHeader onClick={togglingCategoryChallenge}>
                            <p className="small">
                              {selectedOptionCategoryChallenge || "Categoria *"}
                            </p>
                            {isOpenCategoryChallenge ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpenCategoryChallenge && (
                            <DropDownListContainer>
                              <DropDownList>
                                <Scrollbars style={{ height: 50 }}>
                                  {optionsCategoryChallenge.map((option) => (
                                    <ListItem
                                      big
                                      onClick={onOptionClickedCategoryChallenge(
                                        option
                                      )}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>

                        <button
                          id="btn-category"
                          type="button"
                          onClick={toogleNewCategory}
                        >
                          Incluir nova categoria
                        </button>

                        {newCategory && (
                          <Fade top duration={1600} distance="20px">
                            <NewCategoryComponent>
                              <hr />
                              <p id="title">Solicitar nova categoria</p>
                              <form>
                                <input
                                  type="text"
                                  placeholder="Nova Categoria:"
                                  onChange={(event) =>
                                    setNewCategoryValue(event.target.value)
                                  }
                                />
                                <button
                                  type="button"
                                  onClick={handleNewCategory}
                                >
                                  Solicitar
                                </button>
                              </form>
                              <hr />
                            </NewCategoryComponent>
                          </Fade>
                        )}
                        <InputContent style={{ marginRight: 16 }}>
                          <div className="input-imgs">
                            <p id="placeholder">Imagens</p>
                            <BoxLabel
                              active={items.length === 3 ? true : false}
                            >
                              <label htmlFor="avatar">
                                <div>
                                  <img src={iconClips} id="clips" alt="" />
                                  <input
                                    type="file"
                                    id="avatar"
                                    accept="image/*"
                                    onChange={handleChange}
                                    disabled={items.length === 3 ? true : false}
                                    ref={ref}
                                  />
                                </div>
                              </label>
                            </BoxLabel>
                          </div>
                          <p id="obs-input" style={{ marginTop: 10 }}>
                            Obs: Cadastre necessariamente 03 imagens (dimensões
                            recomendadas: 628x360 pixels).
                          </p>
                        </InputContent>
                        <InputContent>
                          <Input
                            className="input_yt"
                            name="link_youtube"
                            type="text"
                            placeholder="Link para vídeo no Youtube:"
                          />
                        </InputContent>
                      </div>

                      <div className="previews">
                        {items.map((preview) => (
                          <div className="preview" key={preview.id}>
                            <img id="teste" src={preview.url} alt="avatar" />
                            <button
                              type="button"
                              onClick={() => deleteImg(preview)}
                            >
                              Excluir
                            </button>
                          </div>
                        ))}
                      </div>
                      <InputContent active>
                        <Textarea
                          name="description"
                          maxLength={145}
                          className="textarea"
                          placeholder="Qual é o desafio? *"
                          onChange={(event) => setNewItem(event.target.value)}
                          value={newItem}
                        />
                      </InputContent>
                      <p
                        style={{
                          fontSize: 16,
                          marginBottom: 15,
                          color: "#7f7fb9",
                        }}
                      >
                        {newItem.length} / {145} Caracteres
                      </p>
                      <InputContent active>
                        <Textarea
                          name="useful_links"
                          className="textarea"
                          placeholder="Links úteis (Separe cada link por ';'):"
                        />
                      </InputContent>
                      <InputContent active>
                        <Textarea
                          name="to_win"
                          className="textarea"
                          placeholder="Quais os critérios para determinar o Vencedor?"
                        />
                      </InputContent>
                      <p
                        style={{
                          float: "right",
                          color: "#7f7fb9",
                          marginRight: 20,
                        }}
                      >
                        *Campo obrigatório
                      </p>
                      <button type="submit" id="btn-register">
                        Cadastrar
                      </button>
                    </Form>
                  </div>
                </NewChallengeComponent>
              </Fade>
            )}

            {collaborations && (
              <Fade left duration={1600} distance="20px">
                <CollaborationsComponent>
                  <p id="title">Minhas colaborações</p>
                  <p className="text">
                    Confira aqui suas colaborações!
                    <br />E por falar nisso, que tal enchermos essa página com
                    mais colaborações? A Ciência agradece!
                  </p>
                  <div className="content">
                    {myCollaborations.length > 0 &&
                      currentCollaborations.map((preview) => (
                        <Project key={preview.id}>
                          <div className="content-1">
                            <img
                              src={
                                preview.project.first &&
                                preview.project.first.url
                              }
                              alt=""
                            />
                          </div>

                          <div className="content-2">
                            <div className="titles">
                              <p id="title-project">{preview.project.name}</p>
                              {preview && preview.project.special ? (
                                <div>
                                  <FaLockOpen color="#227af1" size={16} />

                                  <p>Tenho Permissão Especial</p>
                                </div>
                              ) : (
                                <div>
                                  <img src={iconCompanyProject} alt="" />
                                  <p>
                                    Instituição:
                                    {preview && preview.project.institution}
                                  </p>
                                </div>
                              )}

                              <div>
                                <img src={iconCheck} alt="" />
                                <p> Categoria: {preview.project.category}</p>
                              </div>

                              <section></section>
                            </div>

                            <div className="resume">
                              <p id="title-resume">Resumo da colaboração</p>
                              <p id="text-content">{preview.content}</p>
                              <section></section>
                            </div>
                            <div className="data">
                              <p>Data da colaboraçao:{preview.formattedDate}</p>
                            </div>
                          </div>

                          <div className="content-3">
                            <img src={atomProject} alt="" />
                            <p>
                              <Link to={`/project/${preview.project.id}`}>
                                Ver projeto
                              </Link>
                            </p>
                          </div>
                        </Project>
                      ))}
                  </div>
                  <div className="floatPage">
                    {myCollaborations.length > 0 && (
                      <Pagination
                        currentPage={currentPageCollaborations}
                        countriesPerPage={countriesPerPageCollaborations}
                        totalCountries={myCollaborations.length}
                        paginate={paginateCollaborations}
                      />
                    )}
                  </div>
                </CollaborationsComponent>
              </Fade>
            )}

            {historic && (
              <Fade left duration={1600} distance="20px">
                <HistoricComponent>
                  <p id="title"> Minhas Moedas Whuffie</p>
                  <p className="text">
                    Aqui moeda não é dinheiro, mas é um incentivo e tanto!
                    Acompanhe seu saldo e veja o que pode adquirir em nosso
                    Mercado Whuffie!
                  </p>
                  <div className="content">
                    {extracts &&
                      currentExtracts.map((preview) => (
                        <div className="transactions" key={preview.id}>
                          <div className="title-transaction">
                            {preview.type === "cosmos" ? (
                              <p id="text-title-transaction">
                                Doação para Fundo Cosmos
                              </p>
                            ) : preview.type === "project" ? (
                              <p id="text-title-transaction">
                                Doação para projeto: {preview.project}
                              </p>
                            ) : (
                              <p id="text-title-transaction">
                                Colaboração no Projeto: {preview.project}
                              </p>
                            )}
                            <div className="data">
                              <img
                                id="icon-calendar"
                                src={iconCalendarPurple}
                                alt=""
                              />
                              <p id="day"> {preview.formattedDate}</p>
                            </div>
                          </div>
                          <p className="text"> Whuffies adquiridos:</p>
                          <div className="value">
                            <p id="big-letter">{preview.whuffies}</p>
                            <p className="small-letter"> W$ </p>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="floatPage">
                    {extracts.length > 0 && (
                      <Pagination
                        currentPage={currentPageExtracts}
                        countriesPerPage={countriesPerPageExtracts}
                        totalCountries={extracts.length}
                        paginate={paginateExtracts}
                      />
                    )}
                  </div>
                </HistoricComponent>
              </Fade>
            )}

            {shopping && (
              <Fade left duration={1600} distance="20px">
                <ShoppingComponent>
                  <p id="title">Minhas compras Whuffie</p>
                  <p className="text">
                    Aqui você confere todas as suas compras realizadas no
                    Mercado Whuffie.
                    <br />
                    Lembre-se de navegar pelo nosso Mercado de vez em quando
                    para encontrar novos produtos, serviços e experiências que
                    lhe agradem!
                    <br />
                    Porém, se o seu saldo Whuffie estiver pequeno, é só
                    encontrar projetos para doar ou colaborar!
                  </p>
                  <div className="content">
                    {myShoppings.length > 0 &&
                      currentShoppings.map((preview) => (
                        <div className="product" key={preview.id}>
                          <Product>
                            <div id="product-content-1">
                              <img
                                src={
                                  preview.product.first &&
                                  preview.product.first.url
                                }
                                alt=""
                              />
                            </div>
                            <div id="product-content-2">
                              <div>
                                <p id="product-text-1-content-2">
                                  {preview.product.name}
                                </p>
                                <p id="category">
                                  Categoria: {preview.product.category}
                                </p>
                                <p id="category">
                                  Ofertante: {preview.product.company.name}
                                </p>
                                <p id="category">
                                  Status:{" "}
                                  {preview.status === true
                                    ? "Entregue"
                                    : "Pendente"}
                                </p>
                                <section></section>
                              </div>

                              <div id="product-price">
                                <p id="product-text-3-content-2">
                                  {preview.product.value}
                                </p>
                                <p id="product-text-4-content-2">W$</p>
                              </div>
                              <section></section>
                              <div className="data">
                                <p>Data da compra: {preview.formattedDate}</p>
                              </div>
                            </div>
                            <button id="product-content-3">
                              <img src={carStore} alt="" />
                              <Link to={`/product/${preview.product.id}`}>
                                <p>Ver produto</p>
                              </Link>
                            </button>
                          </Product>
                          <button
                            className="company-infos"
                            onClick={() => {
                              setShopping(false);
                              toggle();
                              setCompanyInfos(
                                preview &&
                                  preview.product &&
                                  preview.product.company
                              );
                            }}
                          >
                            <img src={iconProducts} alt="" /> Ofertante
                          </button>
                        </div>
                      ))}
                  </div>
                  <div className="floatPage">
                    {myShoppings.length > 0 && (
                      <Pagination
                        currentPage={currentPageShoppings}
                        countriesPerPage={countriesPerPageShoppings}
                        totalCountries={myShoppings.length}
                        paginate={paginateShoppings}
                      />
                    )}
                  </div>
                </ShoppingComponent>
              </Fade>
            )}
            {aboutCompany && (
              <Fade left duration={1600} distance="20px">
                <AboutCompanyComponent>
                  <p id="title">
                    Informações sobre a empresa ofertante do seu produto
                  </p>
                  <div className="infos">
                    <div className="box-img">
                      <img
                        id="avatar"
                        src={companyInfos.avatar && companyInfos.avatar.url}
                        alt=""
                      />
                    </div>

                    <div className="datas">
                      <p id="title-data">Dados</p>

                      <div className="all">
                        <div className="about">
                          <div className="info">
                            <img src={id} alt="" />
                            <p id="type">Id: </p>
                            <p>{companyInfos.id}</p>
                          </div>
                          <div className="info">
                            <img src={user} alt="" />
                            <p id="type">Nome: </p>
                            <p>{companyInfos.name}</p>
                          </div>
                          <div className="info">
                            <FaEnvelope color="#227af1" />
                            <p id="type">Email: </p>
                            <p>{companyInfos.email}</p>
                          </div>
                          <div className="info">
                            <FaPhone color="#227af1" />
                            <p id="type">Telefone: </p>
                            <p>{companyInfos.phone}</p>
                          </div>
                        </div>

                        <div className="adress">
                          <div className="adress-info">
                            <p id="type">Logradouro:</p>
                            <p>{companyInfos.adress}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Bairro:</p>
                            <p> {companyInfos.neighborhood}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Cidade: </p>
                            <p>{companyInfos.city}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">CEP:</p>
                            <p>{companyInfos.cep}</p>
                          </div>
                          <div className="adress-info">
                            <p id="type">Estado: </p>
                            <p>{companyInfos.state}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AboutCompanyComponent>
              </Fade>
            )}

            {myProducts && (
              <Fade left duration={1600} distance="20px">
                <MyProductsComponent>
                  <p id="title"> Meus Produtos/Serviços/XP</p>
                  <p className="text">
                    Seja bem-vindo(a) ao Mercado Whuffie!
                    <br />
                    <br />
                    Nessa área você cadastra produtos, serviços e experiências
                    oferecidas pela sua Empresa para que os demais usuários da
                    plataforma possam adquiri-los. Todas as transações aqui são
                    realizadas com Moedas Whuffie, ou seja, quanto mais você
                    "vender", mais moedas Whuffie terá em seu saldo e mais (e
                    melhores) Desafios poderá lançar!
                    <br />
                    <br />
                    Lembre-se: ao incluir sua empresa no Mercado Whuffie, você
                    contribui para o fortalecimento da cadeia de incentivos para
                    novas doações aos projetos científicos em nossa plataforma,
                    além de fortalecer a visibilidade, prestígio e o marketing
                    social da sua empresa!
                  </p>

                  <div className="content">
                    <div className="new-product">
                      <p id="text-new-product">
                        Cadastrar novo produto/serviço/experiência
                      </p>
                      <button
                        id="btn-new-product"
                        onClick={handleNewProductClick}
                      >
                        Cadastrar
                      </button>
                    </div>
                    <div className="products">
                      <MyProducts>
                        {currentCountriesProduct.map((countries, number) => (
                          <ul className="country" key={number}>
                            <ProductContent>
                              <Product key={countries.id}>
                                <div id="product-content-1">
                                  <img src={countries.first.url} alt="" />
                                </div>
                                <div id="product-content-2">
                                  <div>
                                    <p id="product-text-1-content-2">
                                      {countries.title}
                                    </p>
                                    <p id="category">
                                      Categoria: {countries.category}
                                    </p>
                                    <p id="category">
                                      Ofertante: {countries.company.name}
                                    </p>
                                    <section></section>
                                  </div>

                                  <div id="product-price">
                                    <p id="product-text-3-content-2">
                                      {countries.value}
                                    </p>
                                    <p id="product-text-4-content-2">,00</p>
                                  </div>
                                  <section></section>
                                  <div className="data">
                                    <p>
                                      Data do cadastro:{" "}
                                      {countries.formattedDate}
                                    </p>
                                  </div>
                                </div>
                                <button id="product-content-3">
                                  <img src={carStore} alt="" />
                                  <Link to={`product/${countries.id}`}>
                                    <p>Ver produto</p>
                                  </Link>
                                </button>
                              </Product>
                              <div className="edit">
                                <div className="pencil">
                                  <img src={iconPerfil} alt="" />
                                </div>
                                <button
                                  id="text-edit"
                                  onClick={() => {
                                    setMyProducts(false);
                                    setEditMyProduct(true);
                                    setGetProduct(
                                      currentCountriesProduct.filter(
                                        (challenge) =>
                                          challenge.id === countries.id
                                      )
                                    );

                                    auxArrayEdit.push(
                                      currentCountriesProduct.filter(
                                        (challenge) =>
                                          challenge.id === countries.id
                                      )[0].first
                                    );
                                    auxArrayEdit.push(
                                      currentCountriesProduct.filter(
                                        (challenge) =>
                                          challenge.id === countries.id
                                      )[0].second
                                    );
                                    auxArrayEdit.push(
                                      currentCountriesProduct.filter(
                                        (challenge) =>
                                          challenge.id === countries.id
                                      )[0].third
                                    );
                                    setArrayEdit(auxArrayEdit);
                                    setNewItem(
                                      currentCountriesProduct.filter(
                                        (challenge) =>
                                          challenge.id === countries.id
                                      )[0].description
                                    );
                                    auxArrayEdit = [];
                                  }}
                                >
                                  Editar produto
                                </button>
                              </div>
                              <div className="shopers">
                                <div className="pencil">
                                  <img src={iconCart} alt="" />
                                </div>
                                <button
                                  id="text-edit"
                                  onClick={() => {
                                    setMyProducts(false);
                                    setShoppers(true);
                                    getShoppers(countries.id);
                                  }}
                                >
                                  Compradores
                                </button>
                              </div>
                            </ProductContent>
                          </ul>
                        ))}
                      </MyProducts>
                    </div>

                    {countriesProduct.length > 0 && (
                      <>
                        <hr />

                        <div className="floatPage">
                          <Pagination
                            currentPage={currentPage}
                            countriesPerPage={countriesPerPageProduct}
                            totalCountries={countriesProduct.length}
                            paginate={paginate}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </MyProductsComponent>
              </Fade>
            )}

            {shoopers && (
              <Fade left duration={1600} distance="20px">
                <Shoppers>
                  <p id="title">Compradores</p>
                  <p id="text">
                    Lista dos usuários que adquiriram seu produto.
                  </p>
                  <ul className="content">
                    {currentShoppers.map((preview) => (
                      <div className="box-shopper" key={preview.id}>
                        <li>
                          <div className="boxes">
                            <p id="title-box">Informações do Comprador</p>

                            <div className="box">
                              <img src={id} alt="" />
                              <p id="info">
                                id: {preview.user} - {preview.perfil}
                              </p>
                            </div>

                            <div className="box">
                              <img src={user} alt="" />
                              <p id="info">Nome: {preview.name}</p>
                            </div>
                            <div className="box">
                              <FaEnvelope color="#227af1" size={20} alt="" />
                              <p id="info">Email: {preview.email}</p>
                            </div>
                            <div className="box">
                              <FaPhone color="#227af1" size={20} alt="" />
                              <p id="info">Telefone: {preview.phone}</p>
                            </div>
                          </div>
                          <div className="adress">
                            <p id="title-box">Endereço</p>

                            <div className="infos">
                              <p id="info">Estado: {preview.state}</p>
                              <p id="info">Cidade: {preview.city}</p>
                              <p id="info">CEP: {preview.cep}</p>
                              <p id="info">Bairro: {preview.neighborhood}</p>
                              <p id="info">Logradouro: {preview.adress}</p>
                            </div>
                          </div>
                        </li>
                        <div className="buttons">
                          <ButtonStatus
                            delivered={!preview.status}
                            onClick={() => {
                              updateStatusProdut(
                                preview.id,
                                preview.status,
                                false
                              );
                            }}
                          >
                            {preview.status === false ? (
                              <FaCheck
                                alt=""
                                color="#fff"
                                size={25}
                                style={{ marginRight: 10 }}
                              />
                            ) : (
                              <FaTimes
                                alt=""
                                color="#fff"
                                size={25}
                                style={{ marginRight: 10 }}
                              />
                            )}
                            Pendente
                          </ButtonStatus>
                          <ButtonStatus
                            delivered={preview.status}
                            onClick={() => {
                              updateStatusProdut(
                                preview.id,
                                preview.status,
                                true
                              );
                            }}
                          >
                            {preview.status ? (
                              <FaCheck
                                alt=""
                                color="#fff"
                                size={25}
                                style={{ marginRight: 10 }}
                              />
                            ) : (
                              <FaTimes
                                alt=""
                                color="#fff"
                                size={25}
                                style={{ marginRight: 10 }}
                              />
                            )}
                            Entregue
                          </ButtonStatus>
                        </div>
                      </div>
                    ))}
                  </ul>
                  <div className="floatPage">
                    <Pagination
                      currentPage={currentPageShoppers}
                      countriesPerPage={countriesPerPageShoppers}
                      totalCountries={shoopersProduct.length}
                      paginate={paginateShoppers}
                    />
                  </div>
                </Shoppers>
              </Fade>
            )}

            {newProduct && (
              <Fade left duration={1600} distance="20px">
                <NewProductComponent>
                  <p id="title_component">
                    Cadastrar produtos/serviços/experiências
                  </p>
                  <p className="text">
                    Nessa área você cadastra produtos, serviços e experiências
                    oferecidas pela sua Empresa para que os demais usuários da
                    plataforma possam adquiri-los.
                    <br />
                    <br />
                    Para cadastrar produtos, serviços ou experiências no{" "}
                    <Link to="store" style={{ color: "#227af1" }}>
                      Mercado Whuffie
                    </Link>{" "}
                    ou lançar{" "}
                    <Link to="challenges" style={{ color: "#227af1" }}>
                      Desafios
                    </Link>{" "}
                    em nossa plataforma, verifique se você preencheu todos os
                    campos necessários em seu Perfil Empresarial. Cadastre
                    também links para as redes sociais da sua
                    empresa/instituição (como Facebook, Instagram, Linkedin,
                    Twitter e Youtube). Assim, mais apoiadores, cientistas e
                    empresas encontrarão sua organização!
                  </p>
                  <div className="content">
                    <Form onSubmit={handleSubmitProduct} schema={schemaProduct}>
                      <div className="inputs">
                        <DropDownContainer>
                          <DropDownHeader id="small" onClick={togglingCategory}>
                            <p>{selectedOptionCategory || "Categoria *"}</p>
                            {isOpenCategory ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpenCategory && (
                            <DropDownListContainer className="state-input">
                              <DropDownList>
                                <Scrollbars style={{ height: 53 }}>
                                  {optionsCategory.map((option) => (
                                    <ListItem
                                      onClick={onOptionClickedCategory(option)}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>
                        <InputContent>
                          <Input
                            style={{ marginTop: 10 }}
                            name="value"
                            type="text"
                            placeholder="Valor Whuffie: * (não separe o valor com ponto)"
                          />
                        </InputContent>

                        <InputContent active>
                          <Input
                            name="title"
                            type="text"
                            placeholder="Título completo do produto/serviço/experiência. *"
                            className="full"
                            onChange={(event) =>
                              setFormattedContentProduct(event.target.value)
                            }
                            maxLength={65}
                            value={titleProduct}
                          />
                          <p id="number-chars" style={{ fontSize: 16 }}>
                            {titleProduct.length} / {65} Caracteres
                          </p>
                        </InputContent>

                        <InputContent>
                          <Input
                            name="available_units"
                            type="text"
                            placeholder="Unidades disponíveis: *"
                          />
                        </InputContent>
                        <InputContent style={{ marginTop: 25 }}>
                          <div
                            className="input-imgs"
                            style={{ marginBottom: -35 }}
                          >
                            <p id="placeholder">Imagens</p>
                            <BoxLabel
                              active={items.length === 3 ? true : false}
                            >
                              <label htmlFor="avatar">
                                <div>
                                  <img src={iconClips} id="clips" alt="" />
                                  <input
                                    type="file"
                                    id="avatar"
                                    accept="image/*"
                                    onChange={handleChange}
                                    disabled={items.length === 3 ? true : false}
                                    ref={ref}
                                  />
                                </div>
                              </label>
                            </BoxLabel>
                          </div>
                          <p id="obs-input" style={{ marginTop: 50 }}>
                            Obs: Cadastre necessariamente 03 imagens (dimensões
                            recomendadas: 628x360 pixels).
                          </p>
                        </InputContent>
                      </div>

                      <div className="previews">
                        {items.map((preview) => (
                          <div className="preview" key={preview.id}>
                            <img id="teste" src={preview.url} alt="avatar" />
                            <button
                              type="button"
                              onClick={() => deleteImg(preview)}
                            >
                              Excluir
                            </button>
                          </div>
                        ))}
                      </div>
                      <InputContent>
                        <Textarea
                          name="description"
                          className="textarea"
                          placeholder="Descrição: *"
                          maxLength={145}
                          onChange={(event) => setNewItem(event.target.value)}
                          value={newItem}
                        />
                      </InputContent>
                      <p
                        style={{
                          fontSize: 16,
                          marginBottom: 15,
                          color: "#7f7fb9",
                        }}
                      >
                        {newItem.length} / {145} Caracteres
                      </p>

                      <InputContent>
                        <Textarea
                          name="datasheet"
                          className="textarea"
                          placeholder="Ficha Técnica: *"
                        />
                      </InputContent>
                      <InputContent>
                        <Textarea
                          name="delivery_provision"
                          className="textarea"
                          placeholder="Forma de entrega/prestação: *"
                        />
                      </InputContent>
                      <p
                        style={{
                          float: "right",
                          color: "#7f7fb9",
                          marginRight: 20,
                        }}
                      >
                        *Campo obrigatório
                      </p>
                      <button type="submit" id="btn-register">
                        Cadastrar
                      </button>
                    </Form>
                  </div>
                </NewProductComponent>
              </Fade>
            )}

            {editMyProduct && (
              <Fade left duration={1600} distance="20px">
                <NewProductComponent>
                  <p id="title_component">Editar Produto/Serviço/Experiência</p>
                  <p className="text">
                    Utilize essa área para editar informações sobre seus
                    produtos, serviços e experiências cadastrados no Mercado
                    Whuffie.
                    <br />
                    Lembre-se: ao incluir sua empresa no Mercado Whuffie, você
                    contribui para o fortalecimento da cadeia de incentivos para
                    novas doações aos projetos científicos em nossa plataforma,
                    além de fortalecer a visibilidade, prestígio e o marketing
                    social da sua empresa!
                  </p>
                  <div className="content">
                    <Form
                      initialData={getProduct[0]}
                      onSubmit={handleUpdateProduct}
                      schema={schemaProductEdit}
                    >
                      <div className="inputs">
                        <DropDownContainer>
                          <DropDownHeader id="small" onClick={togglingCategory}>
                            <p>
                              {selectedOptionCategory ||
                                getProduct[0].category ||
                                "Categoria"}
                            </p>
                            {isOpenCategory ? (
                              <img
                                className="icon-drop"
                                id="up-icon"
                                src={iconDrop}
                                alt=""
                              />
                            ) : (
                              <img
                                className="icon-drop"
                                src={iconDrop}
                                alt=""
                              />
                            )}
                          </DropDownHeader>
                          {isOpenCategory && (
                            <DropDownListContainer className="state-input">
                              <DropDownList>
                                <Scrollbars style={{ width: 323, height: 53 }}>
                                  {optionsCategory.map((option) => (
                                    <ListItem
                                      onClick={onOptionClickedCategory(option)}
                                      key={Math.random()}
                                    >
                                      {option}
                                    </ListItem>
                                  ))}
                                </Scrollbars>
                              </DropDownList>
                            </DropDownListContainer>
                          )}
                        </DropDownContainer>
                        <InputContent>
                          <Input
                            name="value"
                            type="text"
                            placeholder="Valor: (não separe o valor com ponto)"
                          />
                        </InputContent>
                        <InputContent active>
                          <Input
                            name="title"
                            type="text"
                            placeholder="Título completo do produto/serviço/experiência."
                            className="full"
                            maxLength={65}
                          />
                        </InputContent>
                        <InputContent>
                          <Input
                            name="current_quantity"
                            type="text"
                            placeholder="Unidades disponíveis:"
                          />
                        </InputContent>
                        <InputContent style={{ marginTop: 15 }}>
                          <div
                            className="input-imgs"
                            style={{ marginBottom: -35 }}
                          >
                            <p id="placeholder">Imagens</p>
                            <BoxLabel
                              active={arrayEdit.length === 3 ? true : false}
                            >
                              <label htmlFor="avatar">
                                <div>
                                  <img src={iconClips} id="clips" alt="" />
                                  <input
                                    type="file"
                                    id="avatar"
                                    accept="image/*"
                                    onChange={handleChangeEdit}
                                    disabled={
                                      arrayEdit.length === 3 ? true : false
                                    }
                                    ref={ref}
                                  />
                                </div>
                              </label>
                            </BoxLabel>
                          </div>
                          <p id="obs-input" style={{ marginTop: 40 }}>
                            Obs: Cadastre necessariamente 03 imagens (dimensões
                            recomendadas: 628x360 pixels).
                          </p>
                        </InputContent>
                      </div>

                      <div className="previews">
                        {arrayEdit.map((preview) => (
                          <div className="preview" key={preview.id}>
                            <img id="teste" src={preview.url} alt="avatar" />
                            <button
                              type="button"
                              onClick={() => {
                                deleteImg(preview);
                                setArrayEdit(
                                  arrayEdit.filter(
                                    (item) => item.id !== preview.id
                                  )
                                );
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        ))}
                      </div>
                      <InputContent>
                        <Textarea
                          name="description"
                          className="textarea"
                          placeholder="Descrição:"
                          maxLength={145}
                          onChange={(event) => setNewItem(event.target.value)}
                          value={newItem}
                        />
                      </InputContent>

                      <p
                        style={{
                          fontSize: 16,
                          marginBottom: 15,
                          color: "#7f7fb9",
                        }}
                      >
                        {newItem.length} / {145} Caracteres
                      </p>

                      <InputContent>
                        <Textarea
                          name="datasheet"
                          className="textarea"
                          placeholder="Ficha Técnica"
                        />
                      </InputContent>
                      <InputContent>
                        <Textarea
                          name="delivery_provision"
                          className="textarea"
                          placeholder="Forma de entrega/prestação:"
                        />
                      </InputContent>

                      <button type="submit" id="btn-register">
                        Salvar
                      </button>
                    </Form>
                  </div>
                </NewProductComponent>
              </Fade>
            )}
          </ProfileContent>

          <Footer />
        </Content>
      </Wrapper>
    </>
  );
}

export default CompanyProfile;
