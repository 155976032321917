import produce from 'immer'

const INITIAL_STATE = {
    token: null,
    signed: false,
    loading: false,
    perfil: null
}

export default function auth(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@auth/SIGN_IN_REQUEST':
                {
                    draft.perfil = action.payload.perfil
                    draft.loading = true
                    break
                }

            case '@auth/SIGN_IN_SUCCESS':
                {
                    draft.token = action.payload.token
                    draft.signed = true
                    draft.loading = false
                    break
                }
            case '@auth/SIGN_OUT':
                {
                    draft.token = null
                    draft.signed = false
                    draft.perfil = null
                    draft.loading = false
                    break
                }
            case '@auth/SIGN_UP_FAILURE':
                {
                    draft.loading = false
                    break
                }
            default:
                return state
        }
    })
}