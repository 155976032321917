import styled from 'styled-components';

import { darken } from 'polished'


export const Textarea = styled.textarea `     
    margin-top:49px;
    margin-bottom:22px;

    width: 628px;
    height: 168px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    outline: none;
    resize: none;
    overflow:hidden;

    padding-top:19px;
    padding-left:33.6px;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #7f7fb9;
    
    &::placeholder{
        clear:left;
        flex-wrap:wrap;
        color: #d6d6e3;

    }
    @media only screen and (max-width:768px){
        width:90%;
        margin-left:5%;
    }
`;

export const Button = styled.button `
    width: 168px;
    height: 45px;
    border-radius: 30px;
    border: solid 1px #2976fa;
    background-color: #227af1;

    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;

    transition: all .3s ease-in-out 0s;
    &:hover{
        background-color:${darken(0.1, '#227af1')};
    }

    @media only screen and (max-width:768px){

        width:35%;
        margin-left:5%;
    }
`;