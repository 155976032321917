import React from "react";

// Router
import { Switch, Route } from "react-router-dom";

// Lib
import ScrollToTop from "./lib/ScrollToTop";

// Pages
import Home from "./pages/Home";
import Scientists from "./pages/Scientists";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Collaboration from "./pages/Collaboration";
import Chat from "./pages/Chat";
import Store from "./pages/Store";
import Product from "./pages/Product";
import Supporters from "./pages/Supporters";
import Company from "./pages/Company";
import Blog from "./pages/Blog";
import InfoBlog from "./pages/InfoBlog";
import Challenges from "./pages/Challenges";
import Challenge from "./pages/Challenge";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import About from "./pages/About";
import Payment from "./pages/Payment";
import GetChallenge from "./pages/GetChallenge";
import LoginAdmin from "./pages/LoginAdmin";
import Dashboard from "./pages/DashboardAdmin";
import Admin from "./pages/Admin";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import Cosmos from "./pages/Cosmos";
import NotFound from "./pages/NotFound";
import Article from "./pages/Article";
import Recover from "./pages/Recover";
import Congratulations from "./pages/Congratulations";
import SendChallenge from "./pages/SendChallenge";
import DeleteProfile from "./pages/DeleteProfile";
import Notifications from "./pages/Notifications";

//terms
import MoneyTerms from "./pages/moneyTerms";
import UseTerms from "./pages/UseTerms";
import PoliticTerms from "./pages/politicTerms";

export default function Routes() {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/projects" component={Projects} />
        <Route path="/project/:id" component={Project} />
        <Route path="/collaboration/:id" component={Collaboration} />
        <Route path="/chat" component={Chat} />
        <Route path="/store" component={Store} />
        <Route path="/product/:id" component={Product} />
        <Route path="/challenges" component={Challenges} />
        <Route path="/challenge/:id" component={Challenge} />
        <Route path="/get-challenge/:id" component={GetChallenge} />
        <Route path="/supporters" component={Supporters} />
        <Route path="/scientists" component={Scientists} />
        <Route path="/company" component={Company} />
        <Route path="/blog" component={Blog} />
        <Route path="/information/:id" component={InfoBlog} />
        <Route path="/register" component={Register} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/profile" component={Profile} />
        <Route path="/payment-project/:id" component={Payment} />
        <Route path="/login-admin" component={LoginAdmin} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/admin" component={Admin} />
        <Route path="/cancel" component={Cancel} />
        <Route path="/success" component={Success} />
        <Route path="/cosmos" component={Cosmos} />
        <Route path="/article" component={Article} />
        <Route path="/terms-money" component={MoneyTerms} />
        <Route path="/terms-user" component={UseTerms} />
        <Route path="/terms-politic" component={PoliticTerms} />
        <Route path="/recover" component={Recover} />
        <Route path="/congratulations" component={Congratulations} />
        <Route path="/send-challenge" component={SendChallenge} />
        <Route path="/delete-profile" component={DeleteProfile} />
        <Route path="/notifications" component={Notifications} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
