import React from "react";

import { Project } from "./styles";
import { Link } from "react-router-dom";
import iconCompanyProject from "../../assets/icons/Projects/company.svg";
import atomProject from "../../assets/icons/Common/atom-project.svg";
import iconCheck from "../../assets/icons/Projects/check.svg";
import { CircleProgress } from "react-gradient-progress";

import { FormattedNumber, IntlProvider } from "react-intl";
import { FaLockOpen } from "react-icons/fa";

function Projects({ data }) {
  return (
    <Project>
      <div className="content-1">
        <img src={data.first.url} alt="" />
      </div>

      <div className="content-2">
        <div className="titles">
          <p id="title-project">{data.name}</p>

          {data && data.special ? (
            <div>
              <FaLockOpen color="#227af1" size={16} />

              <p>Tenho Permissão Especial</p>
            </div>
          ) : (
            <div>
              <img src={iconCompanyProject} alt="" />
              <p>
                Instituição:
                {data && data.institution.split(";")[0]}
              </p>
            </div>
          )}

          <div>
            <img src={iconCheck} alt="" />
            <p> Categoria: {data.category}</p>
          </div>
          <section></section>
        </div>

        <div className="text-content">
          <p>{data && data.summary}</p>
          <section></section>
        </div>

        <div className="cash">
          <div className="values">
            <IntlProvider locale="pt-BR">
              <p id="meta">
                Meta: R${" "}
                <FormattedNumber
                  value={data.value_goal}
                  minimumFractionDigits={2}
                />
              </p>
            </IntlProvider>

            <div className="donation">
              <p>Doado:</p>
              <IntlProvider locale="pt-BR">
                <p id="donation-value">
                  R${" "}
                  <FormattedNumber
                    value={data.current_value.toFixed(2)}
                    minimumFractionDigits={2}
                  />
                </p>
              </IntlProvider>
            </div>
          </div>
          {parseInt((data.current_value * 100) / data.value_goal) === 100 ? (
            <CircleProgress
              percentage={parseInt(
                (data.current_value * 100) / data.value_goal
              )}
              strokeWidth={7}
              primaryColor={["#227af1", "#227af1"]}
              width={100}
              fontColor={"#227af1"}
              fontSize={"20px"}
              fontFamily={"GothamProRegular"}
            />
          ) : (
            <CircleProgress
              percentage={parseInt(
                (data.current_value * 100) / data.value_goal
              )}
              strokeWidth={7}
              primaryColor={["#d6d6e3", "#d6d6e3"]}
              width={100}
              fontColor={"#d6d6e3"}
              fontSize={"20px"}
              fontFamily={"GothamProRegular"}
            />
          )}
        </div>
      </div>

      <div className="content-3">
        <img src={atomProject} alt="" />
        <p>
          <Link to={`project/${data.id}`}>Conheça o projeto</Link>
        </p>
      </div>
    </Project>
  );
}

export default Projects;
