import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import NavBar from "../../../../components/SubHeader";

import iconHeader from "../../../../assets/icons/Company/first.svg";

import headerImg from "../../../../assets/images/Company/first.svg";

function Header() {
  return (
    <Wrapper>
      <NavBar />
      <Container>
        <Row>
          <Column>
            <img id="icon" src={iconHeader} alt="" />
            <h1>Empresas</h1>
            <p id="text">
              Empresas podem ser grandes apoiadoras da Ciência. Se você
              representa uma organização empresarial, conheça os benefícios de
              se juntar à{" "}
              <strong>Iniciativa We4Science – Todos pela Ciência</strong>!
            </p>
          </Column>
          <Column larger>
            <ImgWrapper>
              <Img className="translate" src={headerImg} alt="" />
           
            </ImgWrapper>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Header;
