import React, { useEffect, useState } from "react";
import api from "../../../../services/api";

import { Container } from "./styles";

import projects from "../../../../assets/icons/Common/projects-2.svg";
import Project from "../../../../components/NewProject";

function Projects({ id, category, scientistId }) {
  const [otherProjects, setOtherProjects] = useState([]);

  useEffect(() => {
    async function getOtherProjects() {
      const res = await api.get(
        `other-projects/${id}/${category}/${scientistId}`
      );

      const aux = [];

      for (let index = 0; index < 3; index++) {
        if (res.data[index] !== undefined) {
          aux.push(res.data[index]);
        }
      }

      setOtherProjects(aux);
    }
    getOtherProjects();
  }, [id, category, scientistId]);
  return (
    <Container>
      <div className="title-projects">
        <img src={projects} alt="" />
        <p> VEJA OUTROS PROJETOS </p>
      </div>
      <div id="projects">
        {otherProjects.map((preview) => (
          <Project margin key={preview.id} data={preview} />
        ))}
      </div>
    </Container>
  );
}

export default Projects;
