import React, { useEffect, useMemo, useState } from "react";

//Libraries
import { Bounce } from "react-reveal";
// API
import api from "../../services/api";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Aside from "./components/Aside";
import Main from "./components/Main";
import OtherProduct from "./components/OtherProduct";

// Styled Components
import { Wrapper, Content, Header, ProductContent } from "./styles";

// Icons
import iconStore from "../../assets/icons/Common/store-2.svg";
import { Helmet } from "react-helmet";

function Product({ match }) {
  const [product, setProduct] = useState();
  const [otherProducts, setOtherProducts] = useState();

  const id = useMemo(
    () => ({
      value: match.params.id,
    }),
    [match.params.id]
  );
  useEffect(() => {
    async function loadingProduct() {
      try {
        const { data } = await api.get(`product/${id.value}`);
        setProduct({
          ...data,
        });
      } catch (e) {
        console.log("");
      }
    }
    loadingProduct();
  }, [id]);

  useEffect(() => {
    async function loadingChallenge() {
      try {
        const { data } = await api.get(
          `other-products/${id.value}/${product && product.category}`
        );
        setOtherProducts(data);
      } catch (e) {
        console.log("");
      }
    }
    loadingChallenge();
  }, [id, product]);

  return (
    <>
      <Helmet>
        <title>{product?product.title:'Mercado Whuffie'}</title>
      </Helmet>
      <Bounce left duration={1600} distance="20px">
      <Wrapper>
        <Content>
          <Header>
            <SubHeader />
            <section className="second-content-header">
              <div className="title">
                <img src={iconStore} alt="" />
                <p id="text-1">MERCADO WHUFFIE</p>
              </div>
            </section>
          </Header>

          <ProductContent>
            
         
            <Main product={product}/>
            <Aside product={product} />
          </ProductContent>

          <OtherProduct products={otherProducts} />

          <Footer />
        </Content>
      </Wrapper>
    </Bounce>
    </>
  );
}

export default Product;
