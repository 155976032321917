import React, { useState, useEffect } from "react";

import { Fade } from "react-reveal";
// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
// Styled Components

import { Scrollbars } from "react-custom-scrollbars";

import {
  Wrapper,
  Content,
  Header,
  NotificationContent,
  Notification,
} from "./styles";

import { parseISO, formatDistance } from "date-fns";
import pt from "date-fns/locale/pt";

import api from "../../services/api";

import { store } from "../../store";

import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function Notifications() {
  const [notifications, setNotifications] = useState([]);

  const history = useHistory();

  const { perfil, signed } = store.getState().auth;
  const { profile } = store.getState().user;

  useEffect(() => {
    async function loadNotifications() {
      if (signed) {
        const type =
          perfil === "Apoiador/Visitante"
            ? 1
            : perfil === "Apoiador Empresarial/Desafiante"
            ? 2
            : 3;

        const response = await api.get(
          `my-notifications/${profile && profile.id}/${type}`
        );

        const data = response.data.map((notification) => ({
          ...notification,
          timeDistance: formatDistance(
            parseISO(notification.createdAt),
            new Date(),
            {
              addSuffix: true,
              locale: pt,
            }
          ),
        }));

        setNotifications(data);
      }
    }
    loadNotifications();
  }, [perfil, profile, signed]);

  async function handleMarkAsRead(id) {
    await api.put(`notification/${id}`);

    setTimeout(() => {
      history.go(0);
    }, 500);
  }

  async function deleteNotification(id) {
    try {
      await api.delete(`notification/${id}`);
      toast.success("Notificação Excluida!");
      setTimeout(() => {
        history.go(0);
      }, 500);
    } catch (error) {
      toast.error("Erro ao Excluir Notificação");
    }
  }

  return (
    <>
      <Helmet>
        <title>Notificações</title>
      </Helmet>
      <Fade top duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <p id="title">Notificações</p>
            </Header>
            <NotificationContent>
              <Scrollbars>
                {notifications.map((preview) => (
                  <Notification key={preview.id}>
                    <div className="fs-content">
                      <p id="title">{preview.title}</p>
                      <p id="content">{preview.content}</p>
                      {preview.link !== null && preview.link !== "" && (
                        <Link to={preview.link}>Ver</Link>
                      )}
                    </div>
                    <div className="sec-content">
                      <button
                        type="button"
                        onClick={() => deleteNotification(preview.id)}
                      >
                        Excluir
                      </button>
                      {!preview.read && (
                        <button
                          type="button"
                          onClick={() => handleMarkAsRead(preview.id)}
                        >
                          Marcar como lido
                        </button>
                      )}
                    </div>
                  </Notification>
                ))}
              </Scrollbars>
            </NotificationContent>
            <Footer />
          </Content>
        </Wrapper>
      </Fade>
    </>
  );
}

export default Notifications;
