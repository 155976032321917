import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  cursor: pointer;

  svg {
    margin-right: 30px;
    color: #227af1;

    transition: all 0.3s ease-in-out 0s;
  }

  p {
    font-size: 20px;
    color: #227af1;

    transition: all 0.3s ease-in-out 0s;
  }

  &:hover {
    svg {
      color: ${darken(0.1, "#227af1")};
    }

    p {
      color: ${darken(0.1, "#227af1")};
    }
  }
`;
