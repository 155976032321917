import React from "react";

import { Wrapper, Content, Header } from "./styles";

import logo from "../../assets/images/Common/logo.svg";
import { Flip } from "react-reveal";
import { useHistory } from "react-router-dom";
import { Form, Input } from "@rocketseat/unform";
import { useState } from "react";
import { toast } from "react-toastify";
import Recaptcha from "react-recaptcha";

import { useDispatch } from "react-redux";
import { signUpRequest, signInRequest } from "../../store/modules/auth/actions";
import { Helmet } from "react-helmet";

function LoginAdmin() {
  let history = useHistory();
  const dispatch = useDispatch();

  const [login, setLogin] = useState(true);
  const [reg, setReg] = useState(false);

  const toggle = () => {
    setLogin(!login);
    setReg(false);
  };
  const toggleReg = () => setReg(!reg);

  const [value, setValue] = useState("");

  var senha = process.env.REACT_APP_PASS_ADMIN;

  function checkIsPass() {
    if (!recaptcha) {
      toast.error("Marque o captcha!");
    } else {
      if (value === senha) {
        toast.success("Senha de acesso correta!");
        return true;
      } else {
        toast.error("Senha de acesso incorreta!");
        return false;
      }
    }
  }

  function pass() {
    if (!!loadCaptcha && !recaptcha) {
      toast.error("Marque o captcha!");
    } else {
      checkIsPass() && toggleReg();
    }
  }

  function handleSubmit(data) {
    if (!!loadCaptcha && !recaptcha) {
      toast.error("Marque o captcha!");
    } else {
      data.profile = "admin";

      try {
        dispatch(signUpRequest(data));
        toast.success("Sucesso ao fazer cadastro!");
        setTimeout(() => {
          history.go(0);
        }, 1000);
      } catch (error) {
        toast.error("Deu erro!");
      }
    }
  }

  function handleSubmitLogin({ email, password }) {
    var perfil = "admin";
    dispatch(signInRequest(email, password, perfil));

    setTimeout(function () {
      history.push("/admin");
    }, 600);
  }

  const [recaptcha, setRecaptcha] = useState(false);
  const [loadCaptcha, setLoadCaptcha] = useState(false);

  var verifyCallback = function () {
    setRecaptcha(true);
  };

  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Wrapper>
        <Content>
          <Header>
            <img id="logo" src={logo} alt="" />
            <p id="text-header">ADMIN</p>
          </Header>

          {login ? (
            <Flip top duration={2000} distance="20px">
              <Form className="form" onSubmit={handleSubmitLogin}>
                <Input
                  className="inputs"
                  name="email"
                  type="text"
                  placeholder="Email:"
                />
                <Input
                  className="inputs"
                  name="password"
                  type="password"
                  placeholder="Senha:"
                />

                <Recaptcha
                  sitekey={`${process.env.REACT_APP_CAPTCHA}`}
                  render="explicit"
                  verifyCallback={verifyCallback}
                  onloadCallback={() => setLoadCaptcha(!loadCaptcha)}
                  className="captcha"
                  hl="pt-BR"
                />

                <div className="buttons">
                  <button type="submit">Login</button>
                  <button id="other-btn" type="button" onClick={toggle}>
                    Não tenho uma conta
                  </button>
                </div>
              </Form>
            </Flip>
          ) : !reg ? (
            <Form className="form" onSubmit={pass}>
              <Input
                type="text"
                onChange={(event) => setValue(event.target.value)}
                className="inputs"
                name="verify"
                placeholder="Senha de acesso:"
              />
              <Recaptcha
                onloadCallback={() => setLoadCaptcha(!loadCaptcha)}
                sitekey={`${process.env.REACT_APP_CAPTCHA}`}
                render="explicit"
                verifyCallback={verifyCallback}
                className="captcha"
                hl="pt-BR"
              />
              <button type="submit">Verificar</button>
            </Form>
          ) : (
            <Flip top duration={2000} distance="20px">
              <Form className="form" onSubmit={handleSubmit}>
                <Input
                  className="inputs"
                  name="name"
                  type="text"
                  placeholder="Nome:"
                />

                <Input
                  className="inputs"
                  name="email"
                  type="text"
                  placeholder="Email:"
                />
                <Input
                  className="inputs"
                  name="password"
                  type="password"
                  placeholder="Senha:"
                />
                <Input
                  className="inputs"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirmar Senha:"
                />
                <Recaptcha
                  sitekey={`${process.env.REACT_APP_CAPTCHA}`}
                  render="explicit"
                  verifyCallback={verifyCallback}
                  className="captcha"
                  hl="pt-BR"
                />
                <div className="buttons">
                  <button type="submit">Cadastrar</button>
                  <button
                    id="other-btn"
                    type="button"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    Já tenho uma conta
                  </button>
                </div>
              </Form>
            </Flip>
          )}
        </Content>
      </Wrapper>
    </>
  );
}

export default LoginAdmin;
