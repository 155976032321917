import styled, { css } from "styled-components";
import { Shake,TextFocusIn } from "../../styles/animations";
import { darken } from "polished";

export const Wrapper = styled.div`
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .box-for-scientists {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;

    margin-bottom: 25px;

    > p {
      font-family: GothamProRegular;
      margin-right: 20px;
    }

    button {
      width: 181px;
      height: 40px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .box-for-scientists {
      width: 90vw;
      p,
      button {
        font-size: 0.8rem;
      }

      button {
        width: 40%;
      }
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 432px;
  background: #227af1;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  #second-content-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    margin-bottom: 30px;

    img {
      margin-top: 25px;
    }

    #text-1 {
      margin-top: 10px;
      font-size: 52px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
    #text-2 {
      width: 407px;

      font-family: GothamProRegular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }

    .buttons {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 29px;

      z-index: 1;

      p {
        margin-right: 84px;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }

      input {
        width: 259px;
        height: 60px;
        border-radius: 30px;
        border: solid 1px #ffffff;
        background: transparent;

        margin-right: 16px;

        font-size: 16.5px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: start;
        padding-left: 31.6px;
        color: #ffffff;

        &::placeholder {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: 1px;
          text-align: start;
          color: #ffffff;
        }

        &::-moz-placeholder {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          opacity: 1;
          letter-spacing: 1px;
          text-align: start;
        }

        &::-webkit-input-placeholder {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;

          letter-spacing: 1px;
          text-align: start;
        }
      }

      .button-header {
        width: 259px;
        height: 60px;
        border-radius: 30px;
        border: solid 1px #ffffff;
        background: transparent;

        margin-right: 16px;
        padding-left: 31.6px;

        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: start;

        color: #ffffff;

        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          margin-bottom: 22.4px;
          margin-right: 27px;
        }
      }

      .button-submit {
        width: 64px;
        height: 64px;
        border-radius: 50%;

        margin-left: 5px;

        background-color: #ffffff;

        display: flex;
        align-items: center;
        justify-content: center;

        padding-bottom: 20.8px;
        padding-left: 6.95px;

        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-color: ${darken(0.05, "#ffffff")};
        }
      }
    }

    #img-header {
      display: block;
      position: absolute;

      width: 196px;
      height: 363px;
      align-self: flex-end;

      top: 50px;

      z-index: 0.5;
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top:10px;

  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 332px;

    #second-content-header {
      .buttons {
        display: none;
      }

      img {
        width: 46.4px;
        height: 42.8px;
      }

      #text-1 {
        font-size: 1.5rem;
      }

      #text-2 {
        width: 80%;
        word-wrap: break-word;
      }
      #img-header {
        display: none;
        margin-left: 0;
      }
    }
  }
`;
export const StoreContent = styled.section`
  margin-top: 32px;
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  .filter {
    display: flex;
    align-items: center;
    align-self: flex-start;

    align-self: center;

    margin-left: 145px;
    #first-filter-icon {
      margin-right: 14.2px;
    }

    #first-filter-text {
      margin-right: 19px;
      margin-top: 5px;

      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
  }

  #filter-section {
    margin-top: 29px;
    margin-bottom: 67px;
    margin-left: 8px;

    width: 1083px;
    height: 2.5px;
    background: #eee;
  }

  .products {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .pagination {
    margin-top: 42px;
    margin-right: 139px;

    width: 249px;
    height: 45px;
    display: flex;
    align-self: flex-end;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .filter {
      width: 70%;
      margin-left: 0;
      margin-right: 10%;

      #first-filter-text {
        font-size: 0.9rem;
        width: 70px;
        margin-right: 15px;
      }

      #first-filter-icon {
        height: 25px;
      }

      #third {
        display: none;
      }
    }

    #filter-section {
      width: 100%;
    }

    .pagination {
      justify-self: auto;
      margin-left: 10%;
    }
  }
`;
export const BtnFilter = styled.button`
  background: transparent;
  display: flex;

  .second-filter-icon {
    margin-right: 7px;

    width: 25px;
    height: 25px;

    border-radius: 50%;

    transition: all 0.3s ease-in-out 0s;

    ${(props) =>
      !props.active &&
      css`
        background: transparent;
        &:hover {
          background-color: ${darken(0.1, "#fff")};
        }
      `}

    &:hover {
      background-color: ${darken(0.1, "#fff")};
    }

    ${(props) =>
      props.active &&
      css`
        background-color: ${darken(0.1, "#fff")};
        &:hover {
          background-color: ${darken(0.15, "#fff")};
        }
      `}
  }

  .second-filter-text {
    font-size: 16px;
    font-weight: 500;
    font-family: GothamPro;
    line-height: 1.5;

    text-align: left;
    color: #227af1;

    margin-right: 37px;
    margin-top: 5px;
  }

  @media only screen and (max-width: 768px) {
    .second-filter-text {
      width: 16px;
      height: 16px;

      font-size: 0.8rem;

      display: flex;
      align-items: center;
    }
  }
`;

export const DropDownContainer = styled.div`
  margin-top: 12px;
  z-index: 1;
`;

export const DropDownHeader = styled.div`
  margin-bottom: 0.8em;

  width: 260px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 30px;
  padding-right: 20px;

  font-family: GothamProRegular;
  font-size: 16.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  margin-right: 16px;

  z-index: 1;

  cursor: pointer;

  .icon-drop {
    margin-bottom: 25px;
  }

  #up-icon {
    transform: rotate(180deg);
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 15.5em;

  padding: 0;

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -8px;
    margin-left: 92px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
  }

  animation: ${TextFocusIn} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
`;

export const DropDownList = styled.ul`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  color: #227af1;
  font-weight: 500;

  height: 75px;
  z-index: 1;

  text-align: center;

  border-radius: 5px;

  &:first-child {
    padding-top: 10px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 5%;
  margin-top: 5%;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 85%;
  height: 90%;

  z-index: 1;

  margin-left: 15px;

  border-bottom: 1.2px solid #eee;

  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    color: ${darken(0.15, "#227af1")};
    animation: ${Shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

  }
`;

export const Page = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 5px;

  background-color: #d6d6e3;

  transition: all 0.3s ease-in-out 0s;

  &:hover {
    background-color: ${darken(0.15, "#d6d6e3")};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #227af1;
      &:hover {
        background-color: ${darken(0.15, "#227af1")};
      }
    `}

  display:flex;
  align-items: center;
  justify-content: center;

  margin-right: 6px;

  cursor: pointer;

  p {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 139px;

  @media screen and (max-width: 768px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
`;
