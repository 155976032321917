import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.section`
  #title-messages {
    font-size: 45px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .messages {
    .message {
      width: 628px;
      height: 226px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      padding: 30px;
      margin-bottom: 14px;

      display: flex;

      .icon {
        width: 70px;
        height: 70px;
        border: solid 2px rgba(127, 126, 190, 0.31);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 10px;

        #background-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;

          background-color: rgba(127, 127, 185, 0.31);

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .content {
        display: flex;
        flex-direction: column;

        margin-top: 19px;

        p {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #227af1;
          margin-bottom: 10px;
        }

        #text {
          width: 484px;

          font-family: GothamProRegular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
    }
  }
  .floatPage {
    float: right;

    margin-top: 30px;
  }
  .write {
    margin-top: 100px;
    #title-write {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.89;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
    .message {
      width: 628px;
      height: 179px;

      border-radius: 15px;

      outline: none;
      resize: none;
      overflow: hidden;

      padding: 19px 33.6px 0px 33.6px;

      margin-bottom: 19px;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);

      &::placeholder {
        clear: left;
        flex-wrap: wrap;

        color: #d6d6e3;
      }

      &::-moz-placeholder {
        opacity: 1;
      }
    }

    #btn-send {
      width: 168px;
      height: 45px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      transition: all 0.3s ease-in-out 0s;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    #title-messages {
      font-size: 1.4rem;
    }

    .messages {
      width: 100%;
      .message {
        width: 100% !important;
      }
    }
    .write {
      width: 100%;
      #title-write {
        font-size: 1rem;
      }

      .message {
        width: 100% !important;
      }
    }
  }
`;
