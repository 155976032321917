import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 0 0 0;
  background: #227af1;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 54px;
  position: relative;
  z-index: 0;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;

  flex-direction: column;

  position: relative;
  z-index: 1;

  #icon {
    width: 56.4px;
    height: 62.8px;

    margin-bottom: 8px;
  }

  h1 {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;

    color: #ffffff;

    text-transform: uppercase;

    margin-top: 15.6px;
  }

  #text {
    font-family: GothamProRegular;
    font-size: 14px;
    line-height: 2;
    text-align: left;

    color: #ffffff;
  }

  ${(props) =>
    props.larger &&
    css`
      max-width: 56.6%;
      flex-basis: 56.6%;

      padding-left: 15px;
    `}

  ${(props) =>
    !props.larger &&
    css`
      max-width: 43.3%;
      flex-basis: 43.3%;

      margin-bottom: 50px;
      
      align-items: flex-start;
    `} 

  @media screen and (max-width: 805px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;

    #icon {
      width: 46.4px;
      height: 42.8px;
    }

    #text-subcontent {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 472px) {
    h1 {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 314px) {
    h1 {
      font-size: 1rem;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  transform: translateY(40px);

  @media screen and (max-width:806px ){
    align-items: center;
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;

  &.translate{
    transform: translateY(18px);
  }
`;
