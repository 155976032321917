import React from "react";

import { Container, FirstContent, SecondContent, ThirdContent } from "./styles";

function Second({
  title,
  company,
  conclusion,
  category,
  description,
  premium,
  specialPremium,
}) {
  return (
    <Container>
      <FirstContent>
        <p id="title-challenge">{title}</p>
        <p className="normal-text">Empresa: {company}</p>
        <p className="normal-text">Data limite: {conclusion}</p>
        <p className="normal-text">Categoria: {category}</p>
      </FirstContent>
      <SecondContent>
        <p>{description}</p>
      </SecondContent>
      <ThirdContent>
        <div className="value">
          <p className="normal-text">Prêmio:</p>
          <p id="price-value">{premium}</p>
          <p className="normal-text">,00</p>
          <p className="normal-text">W$</p>
        </div>
        {specialPremium && (
          <>
            <p className="normal-text">+</p>
            <p className="normal-text">Prêmio Especial</p>
          </>
        )}
      </ThirdContent>
    </Container>
  );
}

export default Second;
