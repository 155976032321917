import React, {  useState } from "react";
import { useHistory } from "react-router";

import { Container } from "../../../../styles/global";

import Product from "../../../../components/Product";
import { Wrapper, Row, Column, ChangeProduct } from "./styles";

import iconStore from "../../../../assets/icons/Home/store.svg";
import prev from "../../../../assets/icons/Common/product-left.svg";
import next from "../../../../assets/icons/Common/product-right.svg";

import storeWuffie from "../../../../assets/images/Home/store-wuffie.svg";

import useProducts from "../../../../hooks/useProducts";

function First() {
  const history = useHistory();
  const { products } = useProducts();
  const [page, setPage] = useState(0);

  const paginateNext = () => {
    if (products[page + 2] !== undefined) {
      setPage(page + 1);
    }
  };

  const paginatePrev = () => {
    if (page >= 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Row>
            <Column>
              <img className="img-1" src={iconStore} alt="" />
              <img className="img-2" src={storeWuffie} alt="" />
              <h1>
                {" "}
                Mercado <br />
                Whuffie{" "}
              </h1>
              <p id="science-2">
                Seu apoio à Ciência rende pontos de incentivo, ou melhor,
                “moedas Whuffie”, que você poderá trocar por produtos, serviços
                e experiências no mundo real!
              </p>
              <button id="btn-store" onClick={() => history.push("/store")}>
                Conheça o Mercado Whuffie
              </button>
            </Column>
            <Column products>
              <div className="content-products">
                <div id="products">
                  {products !== null && products.length > 0 && (
                    <ChangeProduct>
                      <button
                        className="paginate-img"
                        disabled={page === 0 ? true : false}
                      >
                        <img src={prev} onClick={paginatePrev} alt="" />
                      </button>
                      <button
                        className="paginate-img"
                        disabled={
                          products[page + 2] === undefined ? true : false
                        }
                      >
                        <img src={next} onClick={paginateNext} alt="" />
                      </button>
                    </ChangeProduct>
                  )}
                </div>
                <div id="second">
                  {products !== null && products.length > 0 && (
                    <>
                      <Product special={true} data={products[page]} />

                      {products.length > 1 && (
                        <Product special={true} data={products[page + 1]} />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
}

export default First;
