import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 10px 0 350px 0;

  @media screen and (max-width: 1255px) {
    padding: 10px 0 390px 0;
  }

  @media screen and (min-width: 947px) and (max-width: 1203px) {
    padding: 10px 0 360px 0;
  }

  @media screen and (max-width: 946px) {
    padding: 10px 0 240px 0;
    background-color: #227af1;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 760px) {
    padding: 10px 0 80px 0;
  }
`;

export const Background = styled.img`
  width: 100%;

  top: 0;
  left: 0;

  position: absolute;
  z-index: -1;

  @media screen and (min-width: 1100px) {
    max-height: 700px;
  }

  @media screen and (max-width: 946px) {
    display: none;
  }
`;

export const HeaderContainer = styled.header`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  #science {
    position: relative;
    #scientists {
      display: block;
      overflow: visible;
      position: absolute;

      width: 411.6px;
      height: 455.8px;

      margin-top: 102px;
      margin-left: 6px;

      object-fit: contain;
    }

    #floor-scientists {
      display: block;
      overflow: visible;
      position: absolute;
      margin-top: 543px;
    }
  }

  #second-content-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    div {
      #circles {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        #first-circle {
          margin-right: 40px;
          margin-top: 270px;
          overflow: visible;
          position: absolute;

          width: 642px;
          height: 642px;

          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.05);

          z-index: -1;

          @media (max-width: 1300px) {
            top: -63px;
            margin-left: 50px;
            width: 622px;
            height: 622px;
          }
          @media (min-width: 1300px) {
            margin-left: 50px;
            top: -68px;
          }
          @media screen and (max-width: 1255px) {
            transform: translateY(-10%);
            width: 502px;
            height: 502px;
          }

          @media screen and (max-width: 1100px) {
            width: 402px;
            height: 402px;
          }
          @media screen and (max-width: 1035px) {
            width: 382px;
            height: 382px;
            transform: translateY(-15%);
          }
        }

        #second-circle {
          margin-right: 40px;
          margin-top: 270px;
          position: absolute;
          overflow: visible;

          width: 410px;
          height: 410px;

          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.05);

          z-index: -1;

          @media (max-width: 1300px) {
            top: 40px;
            margin-left: 50px;
            width: 390px;
            height: 390px;
          }

          @media (min-width: 1300px) {
            margin-left: 50px;
            top: 45px;
          }
          @media screen and (max-width: 1255px) {
            transform: translateY(-15%);
            width: 260px;
            height: 260px;
          }
          @media screen and (max-width: 1100px) {
            width: 240px;
            height: 240px;
            transform: translateY(-25%);
          }
          @media screen and (max-width: 1035px) {
            transform: translateY(-38%);
          }
        }

        #third-circle {
          margin-right: 40px;
          margin-top: 270px;
          position: absolute;
          overflow: visible;

          width: 208px;
          height: 208px;

          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.05);

          z-index: -1;

          @media (max-width: 1300px) {
            top: 133px;
            margin-left: 50px;
            width: 188px;
            height: 188px;
          }

          @media (min-width: 1300px) {
            top: 138px;
            margin-left: 50px;
          }

          @media screen and (max-width: 1255px) {
            transform: translateY(-50%);
          }
          @media screen and (max-width: 1100px) {
            transform: translateY(-65%);
          }
          @media screen and (max-width: 1035px) {
            transform: translateY(-85%);
          }
        }
      }

      #fourth-circle {
        margin-right: 40px;
        margin-top: 270px;

        position: absolute;
        overflow: visible;

        width: 175px;
        height: 175px;
        border-radius: 50%;

        -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
        box-shadow: 0 0 7.5px 0 #45c6e5;
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(32%, rgba(106, 243, 255, 0.67)),
          color-stop(141%, rgba(138, 238, 197, 1))
        );
        background: -o-linear-gradient(
          left,
          rgba(106, 243, 255, 0.67) 32%,
          rgba(138, 238, 197, 0.67) 141%
        );
        background: linear-gradient(
          90deg,
          rgba(106, 243, 255, 0.67) 32%,
          rgba(138, 238, 197, 0.67) 141%
        );

        z-index: 1;

        @media (max-width: 1300px) {
          top: 150px;
          margin-left: 50px;
          width: 160px;
          height: 160px;
        }

        @media (min-width: 1300px) {
          top: 155px;
          margin-left: 50px;
        }

        @media screen and (max-width: 1255px) {
          transform: translateY(-60%);
        }
        @media screen and (max-width: 1100px) {
          transform: translateY(-80%);
        }
        @media screen and (max-width: 1035px) {
          transform: translateY(-100%);
        }

        button {
          /* margin-right:40px;
                        margin-top:60px; */
          cursor: pointer;

          display: block;
          position: absolute;
          z-index: 5;

          margin-top: 13px;
          margin-left: 11px;

          width: 151px;
          height: 151px;
          box-shadow: 0 0 15px 0 #45c6e5;
          background: linear-gradient(
            90deg,
            rgba(106, 243, 255, 1) 98%,
            rgba(138, 238, 197, 1) 100%
          );
          -webkit-box-shadow: 0 0 15px 0 #45c6e5;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(32%, rgba(106, 243, 255, 1)),
            color-stop(141%, rgba(138, 238, 197, 1))
          );
          background: -o-linear-gradient(
            left,
            rgba(106, 243, 255, 1) 32%,
            rgba(138, 238, 197, 1) 141%
          );

          border-radius: 50%;

          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #fff;

          @media (max-width: 1300px) {
            margin-left: 12px;
            width: 135px;
            height: 135px;
            /* top:10px; */
          }

          -webkit-transition: all 1s ease 6s;
          -o-transition: all 0.3s ease 0.3s;
          transition: all 0.3s ease 0.3s;

          @media screen and (max-width: 759px) {
            display: none;
            margin: 0;
          }

          &:hover {
            background: -webkit-gradient(
              linear,
              left top,
              right top,
              color-stop(32%, rgba(106, 243, 255, 1)),
              color-stop(141%, rgba(138, 238, 197, 1))
            );
            background: -o-linear-gradient(
              left,
              rgba(106, 243, 255, 1) 32%,
              rgba(138, 238, 197, 1) 141%
            );
            background: linear-gradient(
              90deg,
              ${darken(0.1, "rgba(106,243,255,1)")} 32%,
              ${darken(0.1, "rgba(138,238,197,1)")} 141%
            );
          }
        }
      }
    }
  }

  @media screen and (max-width: 1255px) {
    #science {
      #scientists {
        max-width: 351.6px;
        max-height: 295.8px;
        margin-left: 0;
      }

      #floor-scientists {
        transform: translateY(-150px);
        max-width: 301.6px;
      }
    }
  }

  @media screen and (max-width: 946px) {
    #science {
      #scientists {
        max-width: 321.6px;
        max-height: 265.8px;
        margin-left: 0;
      }

      #floor-scientists {
        transform: translateY(-180px);
        max-width: 251.6px;
      }
    }
  }

  #btnProjects {
    margin-top: 30px;

    padding: 20px;

    border-radius: 30px;

    box-shadow: 0 0 15px 0 #45c6e5;
    background: #5cf3ff;
    -webkit-box-shadow: 0 0 15px 0 #45c6e5;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    &:hover {
      background: ${darken(0.1, "#5cf3ff")};
    }
  }

  @media (min-width: 760px) {
    #btnProjects {
      display: none;
      background: red !important;
    }
  }
  @media screen and (max-width: 760px) {
    margin: 0;
    margin-top: 30px;

    #science {
      display: none;
    }
    #circles {
      display: none;
      margin: 0;
      #first-circle,
      #second-circle,
      #third-circle,
      #fourth-circle {
        margin: 0;
        display: none;
      }
    }
  }
`;

export const BackGroundHeader = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }

  @media only screen and (min-width: 768px) and (max-width: 1366px) {
    width: 100vw !important;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
  #shape1 {
    position: absolute;
    top: -6px;
    z-index: -1;
    width: 100%;
    max-height: 500px;
  }
  #shape2 {
    position: absolute;
    top: -3px;
    z-index: -1;
    margin-top: -4px;
  }
  #shape3 {
    position: absolute;
    top: -10px;
    z-index: -1;
    margin-top: -4px;
    margin-left: -9px;

    @media (max-width: 1300px) {
      width: 1343px;
    }
  }
`;

export const Title = styled.p`
  width: 754px;

  margin-top: 37.5px;

  font-size: 50px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  @media screen and (max-width: 1300px) {
    margin-left: 80px;
    font-size: 40px;
  }

  @media screen and (max-width: 1255px) {
    margin-left: 0px;
    width: 75%;
    font-size: 35px;
    margin-top: 10px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 33px;
  }

  @media screen and (max-width: 946px) {
    width: 100%;

    margin: 0;
  }

  @media screen and (max-width: 473px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  margin-top: 17px;

  width: 455px;

  font-family: GothamProRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  @media (max-width: 1300px) {
    margin-left: 100px;
  }

  @media screen and (max-width: 1255px) {
    margin-left: 0px;
    font-size: 14.5px;
  }

  @media screen and (max-width: 959px) {
    width: 60%;
  }

  @media screen and (max-width: 759px) {
    width: 100%;
    margin: 0;
  }
`;
