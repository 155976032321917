import React from "react";

import { Wrapper, Row, Column, Projects } from "./styles";

import { Container } from "../../../../styles/global";

import iconProjects from "../../../../assets/icons/Common/projects-2.svg";

import useProjectsHome from "../../../../hooks/useProjectsHome";

import Project from "../../../../components/NewProject";

function Second() {
  const { projects } = useProjectsHome();
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <img src={iconProjects} alt="" />
            <h1>VEJA OUTROS PROJETOS</h1>
            <Projects lessTwo={projects.length > 2 ? false : true}>
              {projects &&
                projects.map((preview) => (
                  <Project key={preview.id} data={preview.project} />
                ))}
            </Projects>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Second;
