import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;

  font-family: GothamPro;

  /*Webkits*/
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  /*Webkits*/

  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 1366px;
  height: 100%;

  display: flex;
  flex-direction: column;

  /* Webkits */
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  /* Webkits */
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 211px;

  display: flex;
  flex-direction: column;

  background: #227af1;

  /* Webkits */
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  /* Webkits */

  .second-content-header {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      /* Webkits */

      #text-1 {
        margin-left: 23px;

        font-size: 50px;
        font-weight: 500;
        line-height: 1.4;
        text-align: left;

        color: #ffffff;
      }
    }
  }
  @media only screen and (max-width: 849px) {
    padding-top:10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;

    .second-content-header {
      .title {
        margin-top: 2.5vh;
        img {
          width: 46.4px;
          height: 42.8px;
        }

        #text-1 {
          font-size: 1.6rem;
        }
      }
    }
  }
`;
export const ChallengeContent = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-left: 139px;

  /* Webkits */
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;

  /* Webkits */

  .first-content-about {
   
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    margin-left: 0px;

    display: flex;
    flex-direction: column !important;
    align-items: center;
  }
`;

export const Doubts = styled.div`
  width: 628px;
  height: 226px;

  display: flex;

  margin-bottom: 21px;
  margin-top: 7px;
  margin-left: 3px;

  padding: 30px;

  border-radius: 10px;

  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  ${(props) =>
    props.active &&
    css`
      background-color: #fafaff;
    `}

  /* Webkits */
    -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  /* Webkits */
  .icon {
    width: 70px;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;

    border: solid 2px rgba(127, 126, 190, 0.31);
    border-radius: 50%;

    /* Webkits */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    /* Webkits */

    #background-img {
      width: 50px;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;

      background-color: rgba(127, 127, 185, 0.31);

      /* Webkits */
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      /* Webkits */
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    margin-top: 19px;

    /* Webkits */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Webkits */

    p {
      margin-bottom: 10px;

      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #227af1;
    }

    #text {
      width: 484px;

      font-family: GothamProRegular;
      font-size: 14px;
      line-height: 2;
      text-align: left;

      color: #000000;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;

    .content {
      width: 100%;
      #text {
        width: 80%;
      }
    }
  }
`;