import { useEffect, useState } from "react";

import api from "../services/api";

export default function useProducts() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const loadProducts = async () => {
      const { data } = await api.get("/products");

      if (data !== null && data.length !== 0) {
        setProducts(data);
      }
    };
    loadProducts();
  }, []);

  return { products };
}
