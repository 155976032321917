import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Container } from "../../../../styles/global";
import { Wrapper, Row, Column, ImgWrapper, Img, ButtonContent } from "./styles";

import engageIcon2 from "../../../../assets/icons/Home/engage-icon-3.svg";
import engage2 from "../../../../assets/images/Home/engage.svg";
import api from "../../../../services/api";

import Partners from "../Partners";

function Sixth() {
  const history = useHistory();
  const [institutionAssistant, setInstitutionAssistant] = useState([]);

  useEffect(() => {
    const assistants = async () => {
      const { data } = await api.get("/assistants");
      const filterOne = data.filter((item) => item.type === "Instituição");

      setInstitutionAssistant(filterOne);
    };
    assistants();
  }, []);

  return (
    <>
      <Wrapper>
        <Container>
          <Row>
            <Column small>
              <img className="img-1" src={engageIcon2} alt="" />
              <h1> Sem apoio, a Ciência não avança </h1>
              <p id="science-2">
                Se você é cientista, pesquisador ou representa uma instituição
                de ensino ou pesquisa, cadastre gratuitamente seus projetos e
                pesquisas científicas em nossa plataforma. Nós vamos te ajudar!
              </p>
            </Column>
            <Column>
              <ImgWrapper>
                <Img src={engage2} alt="Image"></Img>
              </ImgWrapper>
            </Column>
          </Row>
        </Container>
      </Wrapper>

      <Partners data={institutionAssistant} />
      <Wrapper>
        <Container>
          <Row>
            <ButtonContent>
              <button onClick={() => history.push("/scientists")}>
                Sou Cientista/Pesquisador!
              </button>
            </ButtonContent>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
}

export default Sixth;
