import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div `
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: GothamPro;
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div `
  width: 1366px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Header = styled.header `
  width: 100%;
  height: 392px;

  background: rgb(41, 118, 250);
  background: linear-gradient(
    90deg,
    rgba(41, 118, 250, 1) 22%,
    rgba(68, 169, 252, 1) 71%,
    rgba(75, 183, 253, 1) 95%
  );

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .fs-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-left: 155px;

    > img {
      height: 550px;
      margin-top:-70px;
    }

    #text-congrat {
      margin-top: 60px;

      width: 410px;
      font-size: 45px;
      font-weight: 500;

      line-height: 1.11;

      text-align: center;
      color: #ffffff;
    }
  }

  .circles {
    align-self: flex-end;
    #first-circle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: absolute;
      overflow: visible;
      width: 684px;
      height: 684px;
      opacity: 0.05;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -380px;
      margin-left: -400px;

      z-index: -0.8;
    }

    #second-circle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: visible;
      position: absolute;

      width: 408px;
      height: 408px;
      opacity: 0.06;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -243px;
      margin-left: -295px;

      z-index: -0.8;
    }

    #third-circle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      position: absolute;
      overflow: visible;
      width: 192px;
      height: 192px;
      opacity: 0.06;

      border-radius: 50%;
      background-color: #ffffff;

      margin-top: -100px;
      margin-left: -175px;

      z-index: -0.8;
    }
  }

  @media only screen and (max-width: 849px) {
    padding-top:10px;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 292px;
    margin: 0;

    .circles {
      display: none;
    }

    .fs-content {
      width: 100%;
      margin: 0;

        >img {
            display:none;
            margin-top:0;
        }

      #text-congrat {
        width: 90%;
        font-size: 2rem;
      }
    }
  }
`;

export const MainContent = styled.section `
  width: 100%;
  height: 600px;

  display: flex;
  flex-direction: column;
  align-items:center;

  .content {
    width: 100%;

    margin-top: 120px;
    margin-right: 37px;

    display: flex;
    flex-direction: column;
    align-items:center;

    .buttons{
        width:50%;

        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content:space-between;
    }

    p {
      width: 682px;
      font-size: 18px;
      font-family: GothamProRegular;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }

    button {
      margin-top: 25px;
      width: 200px;
      height: 70px;
      border-radius: 15px;
      background-color: #227af1;

      transition: all 0.3s ease-in-out 0s;
      z-index: 1;
      font-family: GothamPro;
      font-size: 15.5px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        background-color: ${darken(0.1, "#227af1")};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 700px;

    .content {
      margin: 0;
      padding: 0;
      margin-top: 10%;
      p {
        width: 90%;
        word-wrap: break-word;
        font-size: 0.85rem;

        text-align: justify;

        margin: 0;
      }

      button{
          width:80%;
      }

      .buttons{
        width:100%;
        flex-direction:column;

        button{
            margin-bottom:20px;
        }
        }
        .content-delete{
            width:100%;
            margin-left:10%;
        }
    }
  }
`;