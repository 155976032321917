import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 40px 0 20px 0 ;
  background: #fff;
  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: ${({ small }) => (small ? "33.3%" : "66.6%")};
  flex-basis: 50%;

  .img-1 {
    margin-bottom: 24px;
  }

  > h1 {
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    text-transform: uppercase;
  }

  #science-2 {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    margin-bottom: 10px;
    margin-top: 11px;
  }

  button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    border-radius: 30px;
    background-color: #227af1;

    font-family: GothamPro;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 352px;
    height: 60px;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1250px) {
    max-width: 50%;
    > h1 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;

    > h1 {
      font-size: 1.5rem;
    }

    #science-2 {
      text-align: justify;
    }

   
  }
`;

export const ButtonContent = styled.div`
  margin-bottom: 15px;
  margin-top:50px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  display: flex;
  align-items: center;
  flex-direction: column;

  button {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    border-radius: 30px;
    background-color: #227af1;

    font-family: GothamPro;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 60px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 352px;
    height: 60px;

    &:hover {
      background-color: ${darken(0.1, "#227af1")};
    }
  }

  @media screen and (max-width: 768px) {
    button {
      padding: 20px 33px;
      width: 100%;
      text-align: center;
      font-size: 0.9rem;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 755px;
  display: flex;
  justify-content: flex-start;

  margin-left: 46px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 30px;
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 650px;
`;
