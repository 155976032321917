import React from "react";

import { Wrapper, Row, Column, Img } from "./styles";

import { Container } from "../../../../styles/global";
import { Link, useHistory } from "react-router-dom";

import firstImg from "../../../../assets/images/Scientists/first-1.svg";
import secondImg from "../../../../assets/images/Scientists/second-1.svg";


import { store } from "../../../../store";

function Header() {
  const { token } = store.getState().auth;

  const history = useHistory();

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column> 
            <Img className="second" src={firstImg} alt="" />
            <Img className="second" src={secondImg} alt="" />
          </Column>
          <Column larger>
            <h1 id="title">
              SOU CIENTISTA, COMO POSSO CADASTRAR MEUS PROJETOS E RECEBER APOIO?
            </h1>
            <p id="text">
              É muito simples! Primeiro você clica em{" "}
              <strong>Login/Cadastrar</strong>, escolhe a opção{" "}
              <strong>Cientista/Pesquisador</strong> e então preenche os campos
              do cadastro, criando assim o seu <strong>Perfil W4S</strong>. Por
              meio dele, você terá acesso a diversas ferramentas e recursos para
              interagir com outros cientistas, apoiadores, instituições
              científicas e empresas. E tudo de graça, é claro! <br />
              <br />
              Não vamos estragar a surpresa e lhe contar todas as
              funcionalidades da nossa plataforma aqui, mas um dos recursos mais
              importantes disponíveis em seu Perfil é o{" "}
              <strong>Cadastro de Projeto</strong>. De maneira fácil e
              intuitiva, você irá cadastrar, de forma resumida e em linguagem
              acessível, determinadas informações sobre seu projeto, podendo
              inclusive inserir fotos, vídeos e links externos para complementar
              informações que julgar necessárias. A finalidade principal ali é
              demonstrar a importância do seu projeto e atrair o maior número de
              apoiadores possível. <br />
              <br />É você quem escolhe quais informações irá inserir no
              cadastro do seu projeto, bem como que tipo de apoio você busca
              para ele. Nem todos os projetos científicos buscam recursos
              financeiros, por exemplo. Mas caso o seu necessite, basta definir
              a meta de arrecadação e lançar sua campanha de doações! Finalizada
              a campanha, você poderá solicitar a transferência do dinheiro
              arrecadado, descontado a pequena taxa de administração da
              plataforma e seguindo os procedimentos definidos nos{" "}
              <Link to="/terms-user" style={{ color: "#227af1" }}>
                Termos de Uso
              </Link>{" "}
              e nos{" "}
              <Link to="/terms-money" style={{ color: "#227af1" }}>
                Termos e Condições de Aporte, Doações e Saques
              </Link>{" "}
              da plataforma. <br />
              <br />
              <strong>Importante</strong>: nós seguimos fielmente a{" "}
              <strong>legislação brasileira</strong> em todas as atividades e
              transações sob nossa responsabilidade, além de utilizarmos
              avançadas tecnologias e protocolos de segurança de dados. Consulte
              nossa{" "}
              <Link to="/terms-politic" style={{ color: "#227af1" }}>
                Política de Privacidade
              </Link>
              !
              <br />
              <br />
              Além de tudo isso, ao cadastrar um projeto científico, ele{" "}
              <strong style={{ marginRight: 5 }}>não</strong>é publicado
              imediatamente. Nós fazemos contato com o cientista autor de cada
              projeto, checamos dados e informações de procedência e
              confiabilidade, e só então liberamos (ou não) aquele projeto em
              nossa plataforma. Tudo isso para garantir que toda a comunidade de
              apoiadores da We4Science possa se sentir segura para apoiar
              qualquer projeto cadastrado aqui.
              <strong>Confiança é tudo para nós!</strong> <br />
              <br />
              Agora que você já sabe como funciona, chegou a hora de cadastrar
              aquele seu projeto científico incrível em nossa plataforma e
              entrar de vez para a{" "}
              <strong style={{ fontStyle: "italic" }}>
                Iniciativa We4Science – Todos pela Ciência!
              </strong>{" "}
              <br />
              <br />A propósito, sempre que surgir alguma dúvida, fique à
              vontade para entrar em contato com nossa{" "}
              <strong>Equipe de Suporte</strong> pelo
              <Link to="/contact?&type=normal" style={{ color: "#227af1" }}>
                {" "}
                Fale Conosco
              </Link>{" "}
              ou pelos demais aplicativos de contato presentes na plataforma.{" "}
              <br />
              <br />
              Se você representa uma{" "}
              <strong>
                Universidade, Instituto de Ciência e Tecnologia
              </strong>{" "}
              ou outra{" "}
              <strong>
                organização pública ou privada de pesquisa e ciência
              </strong>
              , converse conosco agora mesmo e saiba como se tornar um
              importante <strong>parceiro</strong> dessa Iniciativa! <br />
              <br />
            </p>
            <div className="buttons">
              <button
                onClick={() => {
                  history.push("/contact?&type=especial");
                }}
              >
                {" "}
                Fale Conosco
              </button>
            </div>
            <div className="register">
              <p>
                Amigo(a) Cientista, clique em Login/Cadastrar e seja
                bem-vindo(a) à Iniciativa We4Science – Todos pela Ciência!
              </p>
              <button
                onClick={() => {
                  token ? history.push("profile") : history.push("register");
                }}
              >
                Login / Cadastrar
              </button>
            </div>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Header;
