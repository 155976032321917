import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 59px;
  border-radius: 0 0 10px 10px;

  background: rgb(41, 118, 250);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(54%, rgba(41, 118, 250, 1)),
    to(rgba(68, 169, 252, 1))
  );
  background: -o-linear-gradient(
    left,
    rgba(41, 118, 250, 1) 54%,
    rgba(68, 169, 252, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(41, 118, 250, 1) 54%,
    rgba(68, 169, 252, 1) 100%
  );

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 23px;

  img {
    height: 29px;
    margin-right: 9.4px;
  }

  a {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;

    color: #fff;

    &:hover {
      color: ${darken(0.1, "#FFF")};
    }
  }
`;
