import styled, { css } from 'styled-components';
import { darken } from 'polished'


export const Wrapper = styled.div `
    height: 100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family: GothamPro;
    @media only screen and (max-width:768px){
        overflow-x: hidden;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const Content = styled.div `
    width:1366px;
    height: 100%;

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    @media only screen and (max-width:768px){
        width:100vw;
    }
`

export const Header = styled.header `
    width:100%;
    height: 392px;

    background: rgb(41,118,250);
    background: linear-gradient(90deg, rgba(41,118,250,1) 22%, rgba(68,169,252,1) 71%, rgba(75,183,253,1) 95%);

    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;

    .items{ 
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        #text-header{
            margin-top:70px;
            margin-left:250px;

            width: 410px;
            font-size: 30px;
            font-weight: 500;
            
            line-height: 1.6;

            text-align: center;
            color: #ffffff;
            
        }

        #img-header{
            height: 465px;

            margin-top:-30px;

            z-index: 1;
        }
    }

    @media only screen and (max-width: 849px) {
    padding-top:10px;
  }
    @media only screen and (max-width:768px){
        width:100vw;

        .items{
            height: 100%;
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            #text-header{
                margin:0;
                text-align:center;

                font-size:1.5rem;
            }
            #img-header{
                display:none;
                margin-left:0;
            }
        }
    }
`
export const MainContent = styled.section `
    width: 100%;
    height: 800px;

    margin-top:100px;
    margin-left:139px;

    #title{
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
    }

    .form{

        margin-top:50px;
        width:800px;

        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }

    .input-imgs{
        width: 352px;
        height: 60px;
        padding: 1px 0 1px 27.6px;
        border-radius: 30px;
        border: solid 1px #7f7ebe;

        margin-right:16px;

        display:flex;
        align-items:center;
        justify-content:space-between;

        #placeholder{
            font-family:GothamProRegular;
            font-size: 14px;
            line-height: 1.71;
            text-align: left;
            color: #7f7fb9;
        }
    }

    .previews{
        display:flex;
        margin-top:20px;
        .preview{
            height:97px;
            width:76px;

            margin-right:16px;

            display:flex;
            flex-direction:column;
            align-items:center;

            #teste{
                width: 76px;
                height: 65px;
                border-radius:10px;
            }

            #btn-preview{
                width:40px;

                background:transparent;

                font-size: 14px;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #227af1;

                transition: all .3s ease-in-out 0s;
                &:hover{
                    color:${darken(0.1, '#227af1')};
                }
            }
        }
    }

    .box-info{
        display:flex;
        flex-direction:column;
        align-items:center;
        width:352px;
        >p{
            margin-top:15px;
            margin-bottom:15px;
            max-width:352px;
            word-wrap:break-word;
            color:#7f7ebe;
        }
    }

    input{
        width: 352px;
        height: 60px;
        border-radius: 30px;
        border: solid 1.4px #7f7ebe;
        margin-right:16px;
        margin-bottom:20px;
        padding-left:33.6px;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
        
        &::-moz-placeholder{
            opacity: 1;
        }
        &::placeholder{
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7f7fb9;
        }
    }

    textarea{
        width:720px;
        height: 200px;

        border-radius: 15px;

        border: solid 1.4px #7f7ebe;

        outline: none;
        resize: none;
        overflow:hidden;

        padding:19px 33.6px 0px 33.6px;

        margin-bottom:19px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;


        &::placeholder{
            clear:left;
            flex-wrap:wrap;
            
            color: #7f7fb9;
        }

        &::-moz-placeholder{
            opacity: 1;
        }
    }

    hr{
        background:#eee;
        height: 2px;
        width:720px;

        margin-top:20px;
    }

    #btn-submit{
        margin-top:40px;

        width: 193px;
        height: 60px;

        border-radius:30px;

        background-color: #227af1;

        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        transition: all .3s ease-in-out 0s;
            
        &:hover{
            background-color:${darken(0.1, '#227af1')};
        }
    }
    @media only screen and (max-width:768px){
        width:100vw;
        height: 135vh;
        margin-left:0px;

        #title{
            margin-left:10%;
        }

        .box-info{
            width:100% !important;
        }

        .form{
            margin-left:10%;
            width:80%;

            input{
                width:100%;
            }         

            .input-imgs{
                width:100%;
                margin-bottom:20px;
            }   
        }
    }
`

export const BoxLabel = styled.div `
    width: 58px;
    height: 58px;
    border-radius:50%;
    background-color: #227af1;

    ${props => props.active && css`
        background-color: ${darken(0.2, '#227af1')};
    `}

    display:flex;
    align-items:center;
    justify-content:center;
   .label-box {
        cursor: pointer;

        >div{
            display:flex;
            align-items:center;
            justify-content:center;
            width: 58px;
            height: 58px;
            border-radius:50%;

            input{
                display:none;
            }

            transition: all .3s ease-in-out 0s;

            &:hover{
                opacity: 0.7;
                background-color: ${darken(0.1, '#227af1')};
            }
        }
    }    
`