import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 120px 0;
  background: #227af1;
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  @media screen and (max-width: 1183px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  flex-basis: 50%;

  ${(props) =>
    props.larger &&
    css`
      max-width: 66.6%;
    `}

  ${(props) =>
    !props.larger &&
    css`
      max-width: 33.3%;
    `}

  .img-1 {
    margin-bottom: 24px;
  }

  > h1 {
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
  }

  #donation-text-2 {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #fff;

    margin-bottom: 40px;
    margin-top: 11px;
  }

  button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: relative;
    z-index: 1;

    width: 352px;
    height: 60px;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
    box-shadow: 0 0 7.5px 0 #45c6e5;
    background: rgb(106, 243, 255);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(32%, rgba(106, 243, 255, 1)),
      to(rgba(138, 238, 197, 1))
    );
    background: linear-gradient(
      90deg,
      rgba(106, 243, 255, 1) 32%,
      rgba(138, 238, 197, 1) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(106, 243, 255, 1) 32%,
      rgba(138, 238, 197, 1) 100%
    );

    font-family: GothamPro;
    font-size: 15.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 60px;
    margin-top: 40px;

    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background: linear-gradient(
        90deg,
        ${darken(0.1, "rgba(106,243,255,1)")} 32%,
        ${darken(0.1, "rgba(138,238,197,1)")} 141%
      );
    }
  }

  #first-circle-donation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: absolute;
    overflow: visible;
    width: 654px;
    height: 654px;

    border-radius: 50%;

    background-color: rgba(255, 255, 255, 0.05);

    transform: translate(-320px, -420px);
    z-index: -0.8;
  }

  #second-circle-donation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: visible;
    position: absolute;

    width: 418px;
    height: 418px;
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 50%;

    z-index: -0.8;
  }

  #third-circle-donation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: absolute;
    overflow: visible;
    width: 212px;
    height: 212px;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.06);

    z-index: -0.8;
  }

  .donation-content-2 {
    width: 100%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-around;

    margin-left: 50px;

    #title-donation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      transform: translateY(-70px);

      #text-title-donation {
        margin-top: 12px;
        margin-left: 10px;

        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.5;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
    }
  }
  @media screen and (max-width: 1183px) {

    max-width: 100%;
    .donation-content-2 {
      margin: 0;
    }

    #margin{
      margin-right: 20px;
    }

    #first-circle-donation {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    > h1 {
      font-size: 1.5rem;
    }

    #margin{
      margin-right: 0;
    }

    #donation-text-2 {
      text-align: justify;
    }

    button {
      padding: 20px 33px;
      width: 100%;
    }
  }

  @media screen and (max-width: 610px) {
    .donation-content-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 246px;

  transform: translate(-49px, 210px);

  @media screen and (max-width: 1183px) {
    display: none;
    visibility: hidden;
  }
`;

export const Img = styled.img`
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 374px;
`;

export const Donation = styled.div`
  width: 255px;
  height: 390px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  border: solid 2px rgba(255, 255, 255, 0.41);
  z-index: 1;
  position: relative;

  ul {
    margin-top: 12.5px;
    transform: translateY(-70px);
    #list-people-donation {
      margin-left: 20px;
      margin-bottom: 25px;

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;

      #position-people {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        margin-right: 20px;

        width: 46.5px;
        height: 48.5px;
        border-radius: 5px;
        border: solid 2px rgba(106, 243, 255, 1);

        p {
          -webkit-text-stroke: 0.5px #6af3ff;
          font-size: 23px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.86;
          letter-spacing: normal;
          color: #5cf3ff;

          margin-left: 8px;
          margin-top: 10px;
        }
      }

      #data-people {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        > p {
          margin-top: 2px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          word-break: normal;
        }

        div {
          display: flex;
          display: -webkit-box;
          display: -ms-flexbox;
          #cash {
            margin-top: 5px;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
          }

          #dot {
            margin-top: 8px;
            margin-left: 11px;

            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
          }
        }
      }
    }
  }

  @media screen and (max-width: 610px) {
    margin: 0;
    margin-bottom: 70px;
  }
`;
