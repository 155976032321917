import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import api from '../../../services/api'

import { signFailure, signInSuccess } from './actions'

export function* signIn({ payload }) {

    try {

        const { email, password, perfil } = payload

        if (perfil === 'Apoiador/Visitante') {
            const response = yield call(api.post, 'sessions/supporters', {
                email,
                password
            })

            const { token, user } = response.data

            api.defaults.headers.Authorization = `Bearer ${token}`

            yield put(signInSuccess(token, user))

            toast.success('Bem-vindo(a) à Iniciativa We4Science – Todos pela Ciência!')


        } else if (perfil === 'Apoiador Empresarial/Desafiante') {
            const response = yield call(api.post, 'sessions/companies', {
                email,
                password
            })

            const { token, user } = response.data

            api.defaults.headers.Authorization = `Bearer ${token}`

            yield put(signInSuccess(token, user))

            toast.success('Bem-vindo(a) à Iniciativa We4Science – Todos pela Ciência!')


        } else if (perfil === 'Cientista/Pesquisador') {
            const response = yield call(api.post, 'sessions/scientists', {
                email,
                password
            })

            const { token, user } = response.data

            api.defaults.headers.Authorization = `Bearer ${token}`

            yield put(signInSuccess(token, user))

            toast.success('Bem-vindo(a) à Iniciativa We4Science – Todos pela Ciência!')
        } else if (perfil === 'admin') {
            const response = yield call(api.post, 'sessions/admin', {
                email,
                password
            })

            const { token, user } = response.data

            api.defaults.headers.Authorization = `Bearer ${token}`

            yield put(signInSuccess(token, user))

            toast.success('Bem vindo(a) ao Admin!')
        }

    } catch (err) {
        toast.error('Falha na autenticação, verifique seus dados')
        yield put(signFailure())
    }
}


export function* signUp({ payload }) {
    try {
        const { name, email, avatar_id, phone, preferences, password, confirmPassword, profile } = payload.data


        const user = Object.assign({ name, email, avatar_id, phone, preferences, password, confirmPassword, profile, whuffie: 1 })

        const admin = Object.assign({ name, email, password, confirmPassword })

        if (profile === 'Apoiador/Visitante') {
            yield call(api.post, 'supporters', user)
        } else if (profile === 'Apoiador Empresarial/Desafiante') {
            yield call(api.post, 'companies', user)
        } else if (profile === 'Cientista/Pesquisador') {
            yield call(api.post, 'scientists', user)
        } else if (profile === "admin") {
            yield call(api.post, 'admin', admin)
        }

        toast.success('Perfil cadastrado com sucesso!')

    } catch (err) {
        toast.error('Falha no cadastro, verifique seus dados!')

        yield put(signFailure())
    }
}

export function setToken({ payload }) {
    if (!payload) return

    const { token } = payload.auth

    if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`
    }
}

export function signOut() {
    toast.success('Redirecionado para página de Login! Até breve :)')
}

export default all([
    takeLatest('persist/REHYDRATE', setToken),
    takeLatest('@auth/SIGN_IN_REQUEST', signIn),
    takeLatest('@auth/SIGN_UP_REQUEST', signUp),
    takeLatest('@auth/SIGN_OUT', signOut),
])