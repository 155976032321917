import styled, { css } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  padding: 80px 0;
  background: #227af1;
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  z-index: 1;

  > h1 {
    font-size: 52px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;
  }

  #science-2 {
    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    z-index: 1;

    width: 352px;
    height: 60px;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 7.5px 0 #45c6e5;
    box-shadow: 0 0 7.5px 0 #45c6e5;
    background: rgb(106, 243, 255);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(32%, rgba(106, 243, 255, 1)),
      to(rgba(138, 238, 197, 1))
    );
    background: linear-gradient(
      90deg,
      rgba(106, 243, 255, 1) 32%,
      rgba(138, 238, 197, 1) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(106, 243, 255, 1) 32%,
      rgba(138, 238, 197, 1) 100%
    );

    font-family: GothamPro;
    font-size: 15.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    margin-bottom: 60px;
    margin-top: 40px;

    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;

    &:hover {
      background: linear-gradient(
        90deg,
        ${darken(0.1, "rgba(106,243,255,1)")} 32%,
        ${darken(0.1, "rgba(138,238,197,1)")} 141%
      );
    }
  }

  @media screen and (max-width: 756px) {
    > h1 {
      font-size: 1.5rem;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    > button {
      width: 100%;
    }
    #science-2 {
      text-align: justify;
    }
  }
`;

export const Projects = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${(props) =>
    props.lessThree &&
    css`
      justify-content: space-evenly;
    `}

  @media screen and (min-width: 804px) and (max-width: 1154px) {
    justify-content: space-evenly;
  }

  @media screen and (max-width: 803px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Circles = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  #first-circle-projects {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: absolute;
    overflow: visible;
    width: 1002px;
    height: 1002px;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.05);

    margin-bottom: 160px;

    z-index: -0.8;
  }

  #second-circle-projects {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: absolute;
    overflow: visible;

    width: 67.83%;
    height: 67.83%;

    border-radius: 50%;

    background-color: rgba(255, 255, 255, 0.03);

    z-index: -0.8;
  }

  #third-circle-projects {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    position: absolute;
    overflow: visible;

    width: 60%;
    height: 60%;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.04);

    z-index: -0.8;
  }

  @media screen and (min-width: 975px) and (max-width: 1149px) {
    #first-circle-projects {
      width: 802px;
      height: 802px;
    }
  }

  @media screen and (min-width: 700px) and (max-width: 974px) {
    #first-circle-projects {
      width: 602px;
      height: 602px;
    }
  }

  @media screen and (max-width: 699px) {
    display: none;
    visibility: hidden;
    #first-circle-projects {
      display: none;
    }
    #second-circle-projects {
      display: none;
    }
    #third-circle-projects {
      display: none;
    }
  }
`;
