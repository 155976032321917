import { darken } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 628px;

  margin-right: 150px;
  margin-top: 50.6px;

  display: flex;
  flex-direction: column;

  /* Webkits */
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  /* Webkits */

  #title-challenge {
    width: 628px;

    margin-bottom: 24.6px;

    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;

    color: #227af1;
  }

  .img-challenge {
    width: 628px;
    height: 333px;

    align-self: center;

    border-radius: 10px;

    -ms-flex-item-align: center;
    -ms-grid-row-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;

    #image-about-challenge {
      width: 100%;
      height: 100%;
    }
  }

  .about-challenge {
    margin-top: 104px;

    #title-about {
      font-size: 26px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }

    #section-about {
      width: 100%;
      height: 2px;

      margin-top: 10px;
      margin-bottom: 30px;

      background: #eee;
    }

    #text-about {
      margin-bottom: 13px;

      font-family: GothamProRegular;
      font-size: 14px;
      line-height: 2;
      text-align: left;

      color: #000000;

      width: 100%;
      word-wrap: break-word;
    }
  }

  .galery {
    width: 628px;
    height: 422px;

    margin-top: 33px;
    margin-bottom: 20px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;

      p {
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #227af1;
      }

      .icons {
        width: 111px;

        display: flex;
        justify-content: space-around;

        display: -webkit-box;
        display: -ms-flexbox;
        -ms-flex-pack: distribute;

        .icons-gallery {
          width: 48px;
          height: 48px;
          background-color: #fff;
          border-radius: 50%;

          cursor: pointer;

          -webkit-transition: all 0.3s ease-in-out 0s;
          -o-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s;

          margin-left: 15px;

          &:hover {
            background-color: ${darken(0.1, "#FFF")};
          }
        }
      }
    }

    .images {
      width: 628px;
      height: 357px;

      margin-top: 17px;

      border: 1px solid #eee;
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      #img {
        width: 100%;
        height: 100%;

        border-radius: 10px;
      }
    }
  }

  .links {
    width: 628px;
    height: 263px;
    margin-top: 48px;

    #title {
      font-size: 26px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }

    #section-links {
      width: 100%;
      height: 2px;

      margin-top: 10px;
      margin-bottom: 30px;

      background: #eee;
    }

    .link {
      display: flex;
      align-items: center;

      margin-bottom: 27px;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      /* Webkits */

      img {
        width: 22px;
        height: 22px;
      }

      a {
        margin-left: 10px;

        font-size: 16px;
        font-weight: 500;
        text-align: left;
        text-decoration: underline;

        color: #227af1;
      }
    }
  }

  .about-seller {
    margin-bottom: 50px;

    #title-about-seller {
      font-size: 26px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }

    #section-about-seller {
      width: 100%;
      height: 2px;

      margin-top: 10px;
      margin-bottom: 30px;

      background: #eee;
    }

    #text-about-seller {
      font-family: GothamProRegular;
      font-size: 14px;
      line-height: 2;
      text-align: justify;

      color: #000000;
      width: 100%;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }

  .about-company {
    width: 628px;

    margin-bottom: 74px;

    #title-about-company {
      font-size: 26px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }

    #section-about-company {
      width: 100%;
      height: 2px;

      margin-top: 10px;
      margin-bottom: 36px;

      background: #eee;
    }

    .about-company-content {
      width: 628px;
      height: 380px;

      display: flex;

      padding-left: 41px;
      padding-top: 39px;

      border-radius: 10px;

      box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      background-color: #ffffff;

      /* Webkits */
      -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
      display: -webkit-box;
      display: -ms-flexbox;
      /* Webkits */

      .logo {
        width: 107px;
        height: 107px;

        margin-right: 30px;

        border-radius: 10px;

        img {
          max-width: 107px;
          max-height: 107px;
        }
      }

      .content-info-company {
        margin-top: -10px;

        p,
        a {
          font-size: 14px;
          font-weight: 500;
          line-height: 2.5;
          text-align: left;

          color: #227af1;
          word-break: break-all;
          word-wrap: break-word;
        }

        #text-content-info-company {
          width: 423px;

          font-family: GothamProRegular;
          font-size: 14px;
          line-height: 2;
          text-align: justify;

          color: #000000;
        }

        .social-networks {
          display: flex;
          align-items: center;

          margin-top: 12px;

          /* Webkits */
          display: -webkit-box;
          display: -ms-flexbox;
          -webkit-box-align: center;
          -ms-flex-align: center;
          /* Webkits */

          p {
            font-size: 14px;
            letter-spacing: 2;
            line-height: 2;

            color: #227af1;
          }

          .icons-social {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            /* Webkits */
            display: -webkit-box;
            display: -ms-flexbox;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            -ms-flex-pack: distribute;
            -webkit-box-align: center;
            -ms-flex-align: center;
            /* Webkits */

            svg {
              margin-left: 20px;
              margin-right: 10px;

              color: #227af1;

              cursor: pointer;

              transition: all 0.3s ease-in-out 0s;

              /* Webkits */
              -webkit-transition: all 0.3s ease-in-out 0s;
              -o-transition: all 0.3s ease-in-out 0s;
              /* Webkits */

              &:hover {
                color: ${darken(0.15, "#227af1")};
              }
            }

            #icon-email {
              width: 22px;
              height: 17.8px;

              margin-left: 20px;
              margin-bottom: 2px;
              cursor: pointer;
            }
          }
        }

        #profile {
          width: 206px;
          height: 45px;

          margin-top: 16px;
          padding-left: 56px;

          border-radius: 30px;
          background-color: #227af1;

          font-size: 14px;
          font-weight: bold;
          line-height: 1.07;
          text-align: left;
          color: #ffffff;

          transition: all 0.3s ease-in-out 0s;

          /* Webkits */
          -webkit-transition: all 0.3s ease-in-out 0s;
          -o-transition: all 0.3s ease-in-out 0s;
          /* Webkits */

          &:hover {
            background: ${darken(0.1, "#227af1")};
          }
        }
      }
    }
  }

  .denounce-content {
    width: 628px;

    margin-bottom: 50px;

    #title-denounce {
      font-size: 26px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }

    #section-denounce {
      width: 100%;
      height: 2px;

      margin-top: 10px;
      margin-bottom: 30px;

      background: #eee;
    }

    .content-about-denounce {
      display: flex;
      align-items: center;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      /* Webkits */

      #text-about-denounce {
        width: 423px;

        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 2;
        text-align: left;

        color: #000000;
      }

      button {
        width: 168px;
        height: 60px;

        margin-left: 37px;

        border-radius: 30px;
        border: solid 2px #2976fa;

        background: transparent;

        font-size: 16px;
        font-weight: bold;

        line-height: 1.5;

        color: #227af1;

        transition: all 0.3s ease-in-out 0s;

        /* Webkits */
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        /* Webkits */

        &:hover {
          background: #227af1;
          color: #fff;
        }
      }
    }
  }

  .notification-content {
    width: 628px;

    margin-bottom: 50px;

    #title-notification {
      font-size: 26px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }

    #section-notification {
      width: 100%;
      height: 2px;

      margin-top: 10px;
      margin-bottom: 30px;

      background: #eee;
    }

    .content-about-notification {
      display: flex;
      align-items: center;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      /* Webkits */

      #text-about-notification {
        width: 423px;

        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 2;
        text-align: justify;

        color: #000000;
      }

      button {
        width: 168px;
        height: 60px;

        margin-left: 37px;

        border-radius: 30px;
        border: solid 2px #2976fa;

        background: transparent;

        font-size: 16px;
        font-weight: bold;

        line-height: 1.5;

        color: #227af1;

        transition: all 0.3s ease-in-out 0s;

        /* Webkits */
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        /* Webkits */

        &:hover {
          background: #227af1;
          color: #fff;
        }
      }
    }
  }

  .questions-content {
    width: 628px;

    margin-bottom: 91px;

    #title-questions {
      font-size: 26px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }

    #section-questions {
      width: 100%;
      height: 2px;

      margin-top: 10px;
      margin-bottom: 30px;

      background: #eee;
    }

    .questions {
    }
    .write {
      margin-top: 70px;

      #title-write {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.89;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
      .message {
        width: 628px;
        height: 179px;

        border-radius: 15px;

        outline: none;
        resize: none;
        overflow: hidden;

        padding: 19px 33.6px 0px 33.6px;

        margin-bottom: 19px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;

        -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
        box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);

        &::placeholder {
          clear: left;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;

          color: #d6d6e3;
        }

        &::-moz-placeholder {
          opacity: 1;
        }
      }

      #btn-send {
        width: 168px;
        height: 45px;
        border-radius: 30px;
        background-color: #227af1;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-right: 0;

    #title-challenge {
      width: 100%;
      font-size: 1.4rem;
      margin-left: 5%;
    }

    .img-challenge {
      margin-left: 5%;
      width: 90%;
      height: 40vh;

      margin-top: 5vh;
    }

    .about-challenge {
      width: 95%;
      padding-left: 5%;

      #title-about {
        font-size: 1.6rem;
      }
    }

    .galery {
      width: 90%;
      height: 70vh;

      margin-bottom: 14vh;

      .title {
        font-size: 1.4rem;
        margin-left: 5%;
      }

      .images {
        width: 100%;
        height: 80%;
        margin-left: 5%;
      }
    }

    .links {
      width: 90%;
      margin-left: 5%;

      #title {
        font-size: 1.2rem;
      }

      .link {
        width: 80% !important;

        a {
          margin-left: 2px;
          word-wrap: break-word;
          word-break: keep-all;
          width: 75% !important;
          font-size: 0.9rem !important;
        }
      }
    }

    .about-seller {
      width: 90%;
      margin-left: 5%;

      #title-about-seller {
        font-size: 1.2rem;
      }
    }

    .about-company {
      width: 90%;
      margin-left: 5%;

      #title-about-company {
        font-size: 1.2rem;
      }
      .about-company-content {
        width: 90%;
        height: 80vh;
        margin-left: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 5%;
        padding-top: 10px;

        .logo {
          width: 30%;
          height: 20%;

          margin-right: 0;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .content-info-company {
          margin-top: 0;
          width: 100%;

          p {
            font-size: 0.8rem;
          }

          #text-content-info-company {
            width: 80%;
            font-size: 0.8rem;
          }

          .social-networks {
            width: 100%;

            display: flex;
            flex-direction: column !important;
            align-items: flex-start;
          }

          #profile {
            margin-top: 5vh;
            width: 55%;
            padding-left: 0;
            text-align: center;
          }
        }
      }
    }

    .denounce-content {
      width: 90vw !important;
      margin-left: 5vw;

      .content-about-denounce {
        width: 100%;

        display: flex;
        flex-direction: column;

        #text-about-denounce {
          width: 90%;
          font-size: 0.8rem !important;
          text-align: justify;
        }

        button {
          width: 28%;
          margin-left: 2%;
          font-size: 0.8rem;
        }
      }
    }

    .questions-content {
      width: 90vw;
      margin-left: 5vw;

      .write {
        .message {
          width: 90%;
        }
      }
    }
  }
`;

export const Doubts = styled.div`
  width: 628px;
  height: 226px;

  display: flex;

  margin-bottom: 21px;
  margin-top: 7px;
  margin-left: 3px;

  padding: 30px;

  border-radius: 10px;

  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  ${(props) =>
    props.active &&
    css`
      background-color: #fafaff;
    `}

  /* Webkits */
    -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  /* Webkits */
  .icon {
    width: 70px;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;

    border: solid 2px rgba(127, 126, 190, 0.31);
    border-radius: 50%;

    /* Webkits */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    /* Webkits */

    #background-img {
      width: 50px;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;

      background-color: rgba(127, 127, 185, 0.31);

      /* Webkits */
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      /* Webkits */
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    margin-top: 19px;

    /* Webkits */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    /* Webkits */

    p {
      margin-bottom: 10px;

      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #227af1;
    }

    #text {
      width: 484px;

      font-family: GothamProRegular;
      font-size: 14px;
      line-height: 2;
      text-align: left;

      color: #000000;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;

    .content {
      width: 100%;
      #text {
        width: 80%;
      }
    }
  }
`;
