import React from "react";

import { Wrapper, Row, Column, Projects } from "./styles";

import { Container } from "../../../../styles/global";

import Project from "../../../../components/NewProject";
import iconThird from "../../../../assets/icons/Donors/third.svg";
import { useHistory } from "react-router";
import useProjectsHome from "../../../../hooks/useProjectsHome";

function Third() {
  const { projects } = useProjectsHome();

  const history = useHistory();

  return (
    <Wrapper>
      <Container margin>
        <Row>
          <Column>
            <img id="icon-projects" src={iconThird} alt="" />
            <h1>Escolha um projeto</h1>
          </Column>
        </Row>
        <Row>
          <Column>
            <Projects  lassTwo={projects.length > 2 ? false : true}>
              {projects &&
                projects.map((preview) => (
                  <Project key={preview.id} data={preview.project} />
                ))}
            </Projects>
          </Column>
        </Row>
        <Row center>
          <Column>
            <button onClick={() => history.push("projects")}>
              Ver mais projetos
            </button>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Third;
