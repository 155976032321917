import { useEffect, useState } from "react";

import api from "../services/api";

export default function useDifferentsProjects(id, category, scientist_id) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    async function getOtherProjects() {
      const res = await api.get(
        `other-projects/${id}/${category}/${scientist_id}`
      );

      const aux = [];

      for (let index = 0; index < 3; index++) {
        if (res.data[index] !== undefined) {
          aux.push(res.data[index]);
        }
      }
      setProjects(aux);
    }
    getOtherProjects();
  }, [id, category, scientist_id]);

  return { projects };
}
