import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import { store } from "../../../../store";
import api from "../../../../services/api";

//components
import { Container } from "./styles";
import DevelopmentTeam from "../DevelopmentTeam";
import YouTubeVideo from "../../../../lib/youtubevideo";

//assets
import iconNext from "../../../../assets/icons/Common/product-right.svg";
import iconPrev from "../../../../assets/icons/Common/product-left.svg";
import iconCheckBlue from "../../../../assets/icons/Challenges/check.svg";

function AboutContent({ data, member }) {
  const { token, perfil, signed } = store.getState().auth;
  const { profile } = store.getState().user;

  var galery = [];
  const [positionImg, setPositionImg] = useState(0);
  function nextImg() {
    if (positionImg < galery.length - 1) {
      setPositionImg(positionImg + 1);
    }
  }

  function prevImg() {
    if (positionImg > 0) {
      setPositionImg(positionImg - 1);
    }
  }

  const [check, setCheck] = useState(null);

  var link = data && data.youtube_link;

  const usersData = [
    {
      id: 1,
      username: `${link}`,
    },
  ];

  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/); // eslint-disable-next-line
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
  const usersIds = usersData.map((user) => YouTubeGetID(user.username));
  const a = Object.values(usersIds);

  const videoIDs = a;

  useEffect(() => {
    async function chechTrue() {
      var img = new Image();
      img.src = "http://img.youtube.com/vi/" + videoIDs + "/mqdefault.jpg";
      img.onload = function () {
        if (this.width === 120) {
          setCheck(false);
        } else {
          setCheck(true);
        }
      };
    }
    chechTrue();
  }, [videoIDs]);

  if (check) {
    galery.push(0);
    data && data.first != null && galery.push(data && data.first.url);
    data && data.second != null && galery.push(data && data.second.url);
    data && data.third != null && galery.push(data && data.third.url);
  } else {
    data && data.first != null && galery.push(data && data.first.url);
    data && data.second != null && galery.push(data && data.second.url);
    data && data.third != null && galery.push(data && data.third.url);
  }

  function completeLink(link) {
    var finalLink;
    if (link !== null) {
      if (
        link.substring(0, 7) === "http://" ||
        link.substring(0, 8) === "https://"
      ) {
        finalLink = link;
      } else {
        finalLink = "https://www." + link;
      }
      return finalLink;
    } else {
      return link;
    }
  }

  async function handleComplaint() {
    if (!!token) {
      var datas = Object.assign({
        user: profile.id,
        perfil: perfil,
        project_id: parseInt(data.id),
      });
      try {
        await api.post("complaint-projects", datas);
        toast.success("Projeto denunciado, nossa equipe irá averiguar!");
      } catch (error) {
        console.log(error);
        toast.error("Erro ao denunciar projeto!");
      }
    } else {
      toast.error("É necessário estar logado!");
    }
  }

  const [subscriveNotifications, setSubscribeNotifications] = useState(false);

  useEffect(() => {
    async function checkNotifications() {
      if (signed) {
        const type =
          perfil === "Apoiador/Visitante"
            ? 1
            : perfil === "Apoiador Empresarial/Desafiante"
            ? 2
            : 3;

        try {
          const res = await api.get(
            `/check-notifications/${profile && profile.id}/${type}/${data.id}`
          );
          setSubscribeNotifications(res.data.register);
        } catch (e) {
          console.log("Erro notifications");
        }
      }
    }
    checkNotifications();
  }, [data, perfil, profile, signed]);

  const history = useHistory();

  async function getNotification() {
    if (!token) {
      toast.error("É necessário estar logado!");
    } else {
      const datas = Object.assign({
        user: profile.id,
        profile: perfil,
        project_id: data.id,
      });

      try {
        await api.post("projects-news", datas);
        toast.success("Você receberá notificações deste Projeto!");
        setTimeout(() => {
          history.go(0);
        }, 2500);
      } catch (error) {
        toast.error("Erro ao cadastrar!");
      }
    }
  }

  async function deleteNotification() {
    const type =
      perfil === "Apoiador/Visitante"
        ? 1
        : perfil === "Apoiador Empresarial/Desafiante"
        ? 2
        : 3;

    try {
      await api.delete(
        `/projects-news/${profile && profile.id}/${type}/${data.id}`
      );
      toast.success("Você não receberá  mais notificações deste Projeto!");
    } catch (error) {
      toast.error("Erro ao excluir!");
    }
  }

  return (
    <Container>
      <p id="title-about"> Sobre </p>
      <div className="content-what-is">
        <p id="title-content-what-is"> O que é </p>
        <section id="section-content-what-is"> </section>
      </div>
      <p style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>
        {" "}
        {data && data.about}{" "}
      </p>
      <div className="galery">
        <div className="title">
          <p> Galeria </p>
          <div className="icons">
            <div
              className="icons-gallery"
              onClick={() => {
                prevImg();
              }}
            >
              <img className="icon-prev" src={iconPrev} alt="" />
            </div>
            <div
              className="icons-gallery"
              onClick={() => {
                nextImg();
              }}
            >
              <img src={iconNext} alt="" />
            </div>
          </div>
        </div>
        <div className="images">
          {check && positionImg === 0 ? (
            <YouTubeVideo users={usersData} videoIDs={videoIDs} />
          ) : (
            <img src={galery[positionImg]} alt="" />
          )}
        </div>
      </div>
      <div className="content-what-is">
        <p id="title-content-what-is"> Quem se beneficia </p>
        <section id="section-content-what-is"> </section>
        <p id="text"> {data && data.who_benefits} </p>
      </div>
      <div className="content-what-is">
        <p id="title-content-what-is">Principais objetivos e metas</p>
        <section id="section-content-what-is"> </section>
        <p id="text"> {data && data.goals_objectives} </p>
      </div>
      <div className="content-what-is">
        <p id="title-content-what-is">Estágio atual de desenvolvimento</p>
        <section id="section-content-what-is"> </section>
        <p id="text"> {data && data.stage} </p>
      </div>
      <div className="content-what-is">
        <p id="title-content-what-is"> Parceiros </p>
        <section id="section-content-what-is"> </section>
        <p id="text"> {data && data.partners} </p>
      </div>
      <div className="content-what-is">
        <p id="title-content-what-is"> Recursos necessários </p>
        <section id="section-content-what-is"> </section>
        <p id="text">{data && data.necessary_resources}</p>
      </div>
      <div className="content-what-is">
        <p id="title-content-what-is">Importância da colaboração</p>
        <section id="section-content-what-is"> </section>
        <p id="text">{data && data.importance_collaboration}</p>
      </div>
      {data && data.useful_links !== null && (
        <div className="links">
          <p id="title"> Links úteis </p>
          <section id="section-links"> </section>
          {data && (
            <Scrollbars style={{ width: 628, height: 183 }}>
              {data.useful_links
                .split(";")
                .filter((link) => link !== "")
                .map((option) => (
                  <div className="link" key={Math.random()}>
                    <img src={iconCheckBlue} alt="" />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${completeLink(option)}`}
                    >
                      {option}
                    </a>
                  </div>
                ))}
            </Scrollbars>
          )}
        </div>
      )}
      <div className="development-team">
        <p id="title"> Equipe desenvolvedora </p>
        <section id="section-development-team"> </section>
        <ul className="content-development-team">
          <Scrollbars style={{ width: 655, height: 378 }}>
            {member.map((option) => (
              <DevelopmentTeam key={option.id} data={option} />
            ))}
          </Scrollbars>
        </ul>
      </div>
      <div className="content-denounce">
        <p id="title-content-what-is"> Denunciar projeto </p>
        <section id="section-content-what-is"> </section>
        <div id="denounce">
          <p>
            Encontrou aqui alguma informação que viola nossos Termos de Uso?
            Vamos analisar sua denúncia com muita atenção e responsabilidade, e
            assim que possível dar um retorno para você e para o responsável
            pelo conteúdo.
          </p>
          <button onClick={() => handleComplaint()}>Denunciar</button>
        </div>
      </div>
      {subscriveNotifications ? (
        <div className="content-denounce">
          <p id="title-content-what-is">
            {" "}
            Deixar de Receber Notificações do Projeto{" "}
          </p>
          <section id="section-content-what-is"> </section>
          <div id="denounce">
            <p>
              Deseja ser não ser mais notificado sobre este projeto? clique no
              botão ao lado.
            </p>
            <button onClick={deleteNotification}>Desinscrever</button>
          </div>
        </div>
      ) : (
        <div className="content-denounce">
          <p id="title-content-what-is"> Receber Notificações do Projeto </p>
          <section id="section-content-what-is"> </section>
          <div id="denounce">
            <p>
              Deseja ser notificado sempre que houver uma novidade sobre este
              projeto? clique no botão ao lado.
            </p>
            <button onClick={getNotification}>Receber Notificação</button>
          </div>
        </div>
      )}
    </Container>
  );
}

export default AboutContent;
