import { darken } from "polished";
import styled from "styled-components";

import { check, icon, textAnimation } from "../../../../styles/animations";

export const Wrapper = styled.div`
  padding: 34px 0 0 0;
  background: #fff;
  position: relative;
  z-index: 0;

  
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  max-width: 100%;
  flex-basis: 100%;

  @media screen and (max-width: 735px) {
    align-items: center;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 100%;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;

export const PaypalContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 23px;

  border-bottom: 2px solid #eee;

  p {
    max-width: 60%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #7f7fb9;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: none;
    align-items: center;

    p {
      max-width: 100%;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 472px) {
    p {
      text-align: justify;
    }
  }
`;

export const FormContent = styled.div`
  width: 100%;
  margin-top: 19.2px;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-bottom: 2px solid #eee;

  #text-form {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: normal;

    color: #7f7fb9;
  }

  .inputs {
    max-width: 740px;
    display: flex;
    flex-wrap: wrap;
  }

  #margin {
    margin-right: 16px;
  }

  .content-input {
    display: flex;
    flex-direction: column;

    #obs {
      color: #7f7fb9;
      margin-bottom: 15px;
      text-align: center;
    }

    span {
      color: #227af1;
      font-style: italic;
      font-family: GothamProRegular;

      margin-bottom: 15px;

      animation: ${textAnimation} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        both;
    }
  }

  form {
    .value-input {
      width: 720px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      padding-left: 25px;
      padding-right: 10px;

      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;

      margin-bottom: 17px;

      &::-moz-placeholder {
        opacity: 1;
      }

      &::placeholder {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
    }

    .normal-input {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      border: solid 1.4px #7f7ebe;

      margin-bottom: 20px;
      padding-left: 33.6px;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      &::-moz-placeholder {
        opacity: 1;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .label__checkbox {
      display: none;
    }

    .label__check {
      display: inline-block;
      border-radius: 3px;
      border: solid 1px #7f7fb9;
      background: white;
      vertical-align: middle;
      margin-right: 20px;
      width: 26px;
      height: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out 0s;

      margin-left: 5px;

      i.icon {
        opacity: 0.2;
        font-size: calc(1rem + 1vw);
        color: transparent;
        transition: all 0.3s ease-in-out 0s;
        -webkit-text-stroke: 3px #fff;
      }

      &:hover {
        border: solid 2.5px #7f7fb9;
      }
    }

    .label__text {
      display: flex;
      align-items: center;

      #text-checkbox {
        font-size: 14px;
        text-align: left;
        color: #7f7fb9;
      }
    }

    .label__checkbox:checked + .label__text .label__check {
      animation: ${check} 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;

      .icon {
        opacity: 1;
        transform: scale(0);
        color: white;
        -webkit-text-stroke: 0;
        animation: ${icon} 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.4s 1
          forwards;
      }
    }

    .textarea-form {
      width: 715px;
      height: 179px;
      border-radius: 15px;
      border: solid 1px #7f7ebe;
      outline: none;
      resize: none;
      overflow: hidden;
      padding-top: 19px;
      padding-left: 33.6px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #7f7fb9;

      margin-top: 29px;

      &::placeholder {
        clear: left;
        flex-wrap: wrap;
        color: #7f7fb9;
      }
      &::-moz-placeholder {
        opacity: 1;
      }
    }

    #text-content {
      max-width: 730px;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;

      margin-top: 14.5px;

      a {
        font-size: 14px;

        text-decoration: underline;
        color: #227af1;
        font-weight: bold;

        font-family: GothamProRegular;

        margin-left: 5px;
      }
    }

    .button {
      width: 260px;
      height: 60px;

      border-radius: 30px;

      display: flex;

      margin-bottom: 78px;
      margin-top: 27px;

      .paypal-content {
        width: 67px;
        height: 60px;

        border-radius: 30px 0 0 30px;

        background-color: #1e6fdd;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      #button-paypal {
        width: 193px;
        height: 60px;

        border-radius: 0 30px 30px 0;

        background-color: #227af1;

        padding-left: 26.4px;

        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;

        &:hover {
          background-color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  @media screen and (max-width: 735px) {
    flex-direction: column;
    align-items: none;
    #margin {
      margin-right: 0;
    }
    .inputs {
      max-width: 100%;
      flex-direction: column;
    }

    .content-input {
      max-width: 100%;
    }

    form {
      max-width: 100%;
      .value-input,
      .normal-input,
      .textarea-form {
       width: 100%;
      }
    }
  }
`;
