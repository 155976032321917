import React, { useRef, useState } from "react";

// Libraries
import { Bounce, Fade } from "react-reveal";
import { Form, Input, Textarea } from "@rocketseat/unform";
// Componets
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  RegisterContent,
  Button,
  Question,
} from "./styles";

import { Scrollbars } from "react-custom-scrollbars";

// Icons
import iconContact from "../../assets/icons/Contact/first.svg";
import iconQuestions from "../../assets/icons/Contact/third.svg";
import iconPlus from "../../assets/icons/Contact/plus.svg";
import iconLess from "../../assets/icons/Contact/less.svg";
import iconTitle from "../../assets/icons/Contact/second.svg";

import queryString from "querystring";

// Images
import imgHeader from "../../assets/images/Contact/first.svg";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Contact() {
  const { type } = queryString.parse(window.location.search);

  const [buttonPeople, setButtonPeople] = useState(() => true);
  const [buttonCompany, setButtonCompany] = useState(() => false);
  const [buttonProject, setButtonProject] = useState(() => false);
  // Buttons questions
  const [buttonQ1, setButtonQ1] = useState(() => true);
  const [buttonQ2, setButtonQ2] = useState(() => false);
  const [buttonQ3, setButtonQ3] = useState(() => false);
  const [buttonQ4, setButtonQ4] = useState(() => false);
  const [buttonQ5, setButtonQ5] = useState(() => false);
  const [buttonQ6, setButtonQ6] = useState(() => false);
  const [buttonQ7, setButtonQ7] = useState(() => false);
  const [buttonQ8, setButtonQ8] = useState(() => false);
  const [buttonQ9, setButtonQ9] = useState(() => false);
  const [buttonQ10, setButtonQ10] = useState(() => false);
  const [buttonQ11, setButtonQ11] = useState(() => false);
  const [buttonQ12, setButtonQ12] = useState(() => false);
  const [buttonQ13, setButtonQ13] = useState(() => false);
  const [buttonQ14, setButtonQ14] = useState(() => false);
  const [buttonQ15, setButtonQ15] = useState(() => false);
  const [buttonQ16, setButtonQ16] = useState(() => false);
  const [buttonQ17, setButtonQ17] = useState(() => false);
  const [buttonQ18, setButtonQ18] = useState(() => false);
  const [buttonQ19, setButtonQ19] = useState(() => false);
  const [buttonQ20, setButtonQ20] = useState(() => false);
  const [buttonQ21, setButtonQ21] = useState(() => false);
  const [buttonQ22, setButtonQ22] = useState(() => false);
  const [buttonQ23, setButtonQ23] = useState(() => false);

  const handleQ1Click = () => setButtonQ1(!buttonQ1);
  const handleQ2Click = () => setButtonQ2(!buttonQ2);
  const handleQ3Click = () => setButtonQ3(!buttonQ3);
  const handleQ4Click = () => setButtonQ4(!buttonQ4);
  const handleQ5Click = () => setButtonQ5(!buttonQ5);
  const handleQ6Click = () => setButtonQ6(!buttonQ6);
  const handleQ7Click = () => setButtonQ7(!buttonQ7);
  const handleQ8Click = () => setButtonQ8(!buttonQ8);
  const handleQ9Click = () => setButtonQ9(!buttonQ9);
  const handleQ10Click = () => setButtonQ10(!buttonQ10);
  const handleQ11Click = () => setButtonQ11(!buttonQ11);
  const handleQ12Click = () => setButtonQ12(!buttonQ12);
  const handleQ13Click = () => setButtonQ13(!buttonQ13);
  const handleQ14Click = () => setButtonQ14(!buttonQ14);
  const handleQ15Click = () => setButtonQ15(!buttonQ15);
  const handleQ16Click = () => setButtonQ16(!buttonQ16);
  const handleQ17Click = () => setButtonQ17(!buttonQ17);
  const handleQ18Click = () => setButtonQ18(!buttonQ18);
  const handleQ19Click = () => setButtonQ19(!buttonQ19);
  const handleQ20Click = () => setButtonQ20(!buttonQ20);
  const handleQ21Click = () => setButtonQ21(!buttonQ21);
  const handleQ22Click = () => setButtonQ22(!buttonQ22);
  const handleQ23Click = () => setButtonQ23(!buttonQ23);

  function handlePeopleClick() {
    if (buttonPeople === false) {
      setButtonPeople(!buttonPeople);

      if (buttonCompany === true) {
        setButtonCompany(!buttonCompany);
      }
      if (buttonProject === true) {
        setButtonProject(!buttonProject);
      }
    }
  }

  function handleCompanyClick() {
    if (buttonCompany === false) {
      setButtonCompany(!buttonCompany);

      if (buttonPeople === true) {
        setButtonPeople(!buttonPeople);
      }
      if (buttonProject === true) {
        setButtonProject(!buttonProject);
      }
    }
  }

  function handleProjectClick() {
    if (buttonProject === false) {
      setButtonProject(!buttonProject);

      if (buttonPeople === true) {
        setButtonPeople(!buttonPeople);
      }
      if (buttonCompany === true) {
        setButtonCompany(!buttonCompany);
      }
    }
  }

  const formRef = useRef(null);

  function functionThatResetsForm() {
    formRef.current.reset();
  }

  async function handleSubmit(data, { resetForm = functionThatResetsForm }) {
    if (buttonPeople) {
      data.profile = "Apoiador/Visitante";
    } else if (buttonCompany) {
      data.profile = "Pesquisador-Cientista/Instituição de Ensino e Pesquisa";
    } else if (buttonProject) {
      data.profile = "Empresa Privada";
    }

    data.type = type;

    try {
      await api.post("contacts", data);
      toast.success("Sucesso ao enviar mensagem!");
    } catch (error) {
      toast.error("Erro ao enviar mensagem!");
    }

    resetForm();
  }

  function handleType() {
    if (type === "normal") {
      return "FALE CONOSCO";
    } else if (type === "especial") {
      return `SOLICITAR PERMISSÃO ESPECIAL`;
    } else if (type === "cooperation") {
      return `SOLICITAR TERMO DE COOPERAÇÃO`;
    } else if (type === "support") {
      return `SUPORTE W4S`;
    } else if (type === "recover") {
      return `RECUPERAR SENHA`;
    }
  }

  const title = handleType();

  return (
    <>
      <Helmet>
        <title>Fale Conosco</title>
      </Helmet>
      <Wrapper>
        <Bounce top duration={2000} distance="20px">
          <Content>
            <Header>
              <SubHeader />
              <section id="second-content-header">
                <img id="icon-header" src={iconContact} alt="" />
                <div className="titles">
                  <p id="title">{title}</p>
                  <p id="sub-title">
                    Informe abaixo qual é o seu perfil de usuário para entrar em
                    contato ou tirar dúvidas com a equipe We4Science.
                  </p>
                </div>

                <div className="buttons">
                  <Button active={buttonPeople} onClick={handlePeopleClick}>
                    Apoiador / Visitante
                  </Button>
                  <Button active={buttonCompany} onClick={handleCompanyClick}>
                    Cientista / Pesquisador
                  </Button>
                  <Button active={buttonProject} onClick={handleProjectClick}>
                    Empresa / Desafiante
                  </Button>
                </div>

                <div className="buttons-two">
                  <Button active={buttonPeople} onClick={handlePeopleClick}>
                    Apoiador / Visitante
                  </Button>
                  <Button active={buttonCompany} onClick={handleCompanyClick}>
                    Cientista / Pesquisador
                  </Button>
                  <Button active={buttonProject} onClick={handleProjectClick}>
                    Empresa / Desafiante
                  </Button>
                </div>

                <img id="img-header" src={imgHeader} alt="" />
              </section>
            </Header>
            <RegisterContent>
              <div className="form">
                <div className="title-form">
                  <img src={iconTitle} alt="" />
                  <p>Envie-nos uma mensagem</p>
                </div>
                <Form className="inputs" onSubmit={handleSubmit} ref={formRef}>
                  <Input name="name" type="text" placeholder="Nome:" />
                  <Input name="email" type="text" placeholder="E-mail:" />
                  <Input name="phone" type="text" placeholder="Telefone:" />

                  <div className="buttons-inputs">
                    <Input name="subject" type="text" placeholder="Assunto:" />
                    <button type="submit" id="submit-button">
                      Enviar
                    </button>
                  </div>
                  <Textarea
                    name="message"
                    id="message"
                    type="text"
                    placeholder="Mensagem:"
                  />
                  <button type="submit" id="other-button">
                    Enviar
                  </button>
                </Form>
              </div>

              <div className="terms">
                <hr />
                <p id="title-terms">Termos W4S</p>

                <div className="content-terms">
                  <Link to="/terms-money">
                    Termos e Condições de Uso - Doações, Aportes e Saques
                  </Link>
                  <Link to="/terms-user">Termos de Uso</Link>
                  <Link to="/terms-politic">
                    Política de Privacidade do Website e da Plataforma
                    We4Science
                  </Link>

                  <p style={{ marginTop: 20 }}>
                    Legislação pertinente para parcerias entre a We4Science e
                    Instituições Públicas brasileiras:
                    <br />
                    <br />
                    <a
                      href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13243.htm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lei 13.243/2016
                    </a>
                    <br />
                    <br />
                    <a
                      href="http://www.planalto.gov.br/ccivil_03/_ato2019-2022/2020/decreto/D10534.htm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Decreto 10.534/2020
                    </a>
                  </p>
                </div>

                <hr />
              </div>

              <div className="terms">
                <hr />
                <p id="title-terms">TUTORIAL EM VÍDEO WE4SCIENCE</p>
                <div className="content-terms">
                  <a
                    href="https://www.youtube.com/watch?v=d8lLwTcnqcU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Como fazer meu cadastro de Usuário?
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=GzbUkJTbmRE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Como doar para um projeto ou pesquisa científica?
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=TELiiOjLDtU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Como cadastrar um Projeto ou Pesquisa Científica?
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=kTjQ0p4UDT0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Como cadastrar Novidades em um projeto científico?
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=XoOHyX2_BEg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Como cadastrar Produtos, Serviços ou Experiências?
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=hW2aMnubIXA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Como cadastrar um Desafio?
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=0zCX7xIKYm8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Como enviar uma proposta de Solução para um Desafio?
                  </a>
                </div>
                <hr />
              </div>

              <div className="questions">
                <div className="title">
                  <img src={iconQuestions} alt="" />
                  <div>
                    <p>Perguntas frequentes</p>
                  </div>
                </div>
              </div>
              <ul className="content">
                <Scrollbars style={{ width: 1110, height: 629 }}>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">Por que apoiar a Ciência?</p>
                      {buttonQ1 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ1Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ1Click}
                        />
                      )}
                    </div>
                    {buttonQ1 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Porque a Ciência é o principal motor para o progresso
                          da humanidade e a solução para alguns dos grandes
                          problemas e desafios que ameaçam a existência humana e
                          de todos os seres vivos em nosso planeta. Por meio da
                          Ciência, podemos produzir mais e melhores alimentos,
                          desenvolver medicamentos e tratamentos que salvarão
                          vidas, produtos e máquinas que melhorarão nossa
                          qualidade de vida, procedimentos e tecnologias que
                          ajudarão a preservar o meio ambiente e levar a
                          humanidade aos confins do universo. Promover o
                          progresso da Ciência é promover o nosso próprio
                          progresso!
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Colaborar com a Ciência não é papel do Governo/Estado?
                      </p>
                      {buttonQ2 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ2Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ2Click}
                        />
                      )}
                    </div>
                    {buttonQ2 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Com certeza! Mas também é nosso papel exigir dos
                          Governos para que invistam cada vez mais na Ciência e
                          Educação. Também devemos nos preocupar em eleger
                          candidatos que defendam essas bandeiras, independente
                          da sua inclinação ideológica No entanto, também
                          acreditamos que só isso não basta e sabemos que a
                          Ciência é cada vez menos valorizada pela classe
                          política de modo geral, então chegou a hora (e a
                          tecnologia) para cada um de nós colaborarmos
                          diretamente com o progresso da Ciência!
                        </p>
                      </Fade>
                    )}
                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Quem pode apresentar projetos e pesquisas na plataforma?
                      </p>
                      {buttonQ3 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ3Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ3Click}
                        />
                      )}
                    </div>
                    {buttonQ3 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Apenas cientistas, pesquisadores, professores,
                          profissionais ou alunos autorizados por universidades
                          e instituições públicas ou privadas de Educação,
                          Pesquisa e Ciência.
                          <br />
                          <br />
                          <strong>Exceções:</strong> Empresas privadas não
                          ligadas diretamente à Educação, Pesquisa e Ciência ou
                          pessoas físicas não ligadas a instituições científicas
                          também podem apresentar projetos e pesquisas
                          científicas em nossa plataforma. No entanto, é
                          necessário solicitar uma “Permissão Especial” aos
                          administradores da plataforma. Para liberação dessa
                          permissão, o candidato deverá comprovar que possui as
                          capacidades necessárias para tocar o projeto/pesquisa
                          adiante, além de outras garantias.
                        </p>
                      </Fade>
                    )}
                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Por que apenas universidades, centros de pesquisa,
                        empresas e pessoas físicas com Permissão Especial podem
                        criar projetos e pesquisas na plataforma?
                      </p>
                      {buttonQ4 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ4Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ4Click}
                        />
                      )}
                    </div>
                    {buttonQ4 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          O nosso objetivo é tentar garantir para a nossa
                          comunidade de apoiadores científicos que os projetos e
                          pesquisas encontrados aqui possuem procedência
                          confiável e são desenvolvidos por instituições e
                          profissionais sérios e comprometidos. Todos os
                          criadores têm os dados checados pela administração da
                          plataforma, e cada projeto e pesquisa passa por uma
                          cuidadosa análise da administração antes de ser
                          apresentado aos usuários.
                        </p>
                      </Fade>
                    )}
                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Que tipo de projeto e pesquisa NÃO pode ser apresentado
                        na plataforma?
                      </p>
                      {buttonQ5 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ5Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ5Click}
                        />
                      )}
                    </div>
                    {buttonQ5 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Projetos de cunho bélico/militar, que apoiam ou
                          incentivem a violência, que façam apologia à
                          pedofilia, exploração sexual, cometimento de crimes
                          e/ou que fujam a proposta da plataforma. Cabe
                          ressaltar que todo projeto e pesquisa passa pelo
                          filtro da administração que poderá sugerir pequenas
                          mudanças na apresentação (não no projeto) para se
                          enquadrar em nossa política, por exemplo, na escolha
                          das fotos e vídeos, no texto de descrição do projeto,
                          etc..
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Alguém pode “roubar” a ideia do meu projeto ou pesquisa
                        se eu apresentá-la aqui?
                      </p>
                      {buttonQ6 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ6Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ6Click}
                        />
                      )}
                    </div>
                    {buttonQ6 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Em nossa plataforma você só irá apresentar uma
                          descrição superficial do seu projeto ou pesquisa,
                          procurando encontrar parceiros, colaboradores e/ou
                          doadores. Caso tenha receio do seu projeto ou pesquisa
                          ser plagiado, você deve se resguardar juridicamente
                          junto ao escritório de direitos autorais, registro de
                          patentes e propriedade intelectual. Nossa plataforma
                          não se responsabiliza por eventuais – ainda que
                          improváveis – ações de plágio.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Projetos e pesquisas científicas na área das ciências
                        humanas também são bem-vindos na plataforma?
                      </p>
                      {buttonQ7 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ7Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ7Click}
                        />
                      )}
                    </div>
                    {buttonQ7 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">Claro que sim!</p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Quem pode colaborar e fazer doações?{" "}
                      </p>
                      {buttonQ8 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ8Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ8Click}
                        />
                      )}
                    </div>
                    {buttonQ8 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          De um modo geral, qualquer pessoa física maior de 18
                          anos ou empresa com CNPJ ativo no Brasil, que estejam
                          cadastrados em nossa plataforma. Você pode consultar
                          mais detalhes em nosso{" "}
                          <Link to="/terms-money">
                            Termo de Uso – Doações, Aportes e Saques
                          </Link>
                          .
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Preciso ser um cientista para colaborar com algum
                        projeto ou pesquisa?{" "}
                      </p>
                      {buttonQ9 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ9Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ9Click}
                        />
                      )}
                    </div>
                    {buttonQ9 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Não. Você só precisa ser alguém interessado em
                          colaborar com a Ciência, independente do seu nível
                          escolar, profissão, renda, inclinação política, crença
                          ou descrença religiosa, time de futebol favorito,
                          etc..
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        De que forma se pode colaborar com um projeto ou
                        pesquisa científica?{" "}
                      </p>
                      {buttonQ10 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ10Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ10Click}
                        />
                      )}
                    </div>
                    {buttonQ10 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Com doações em dinheiro a partir de R$ 5,00 (cinco
                          reais), oferecendo conhecimento útil aos pesquisadores
                          responsáveis, prestando serviços técnicos para o seu
                          desenvolvimento, estabelecendo parcerias
                          institucionais ou apenas divulgando aquele projeto em
                          suas redes sociais para que outras pessoas possam
                          conhecê-lo e colaborar com ele.
                          <br />
                          <br />
                          Se você representa uma empresa privada, então além das
                          possibilidades acima, também pode cadastrar produtos,
                          serviços e experiências em nosso Mercado Whuffie, o
                          que nos ajuda a criar uma grande cadeia de incentivo.
                          Além de poder lançar Desafios para os membros de nossa
                          comunidade. Saiba mais clicando na aba{" "}
                          <Link to="/company">Empresas</Link>!
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Paga-se para usar a Plataforma W4S?
                      </p>
                      {buttonQ11 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ11Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ11Click}
                        />
                      )}
                    </div>
                    {buttonQ11 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">Não, ela é gratuito</p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        De que forma a We4Science se sustenta financeiramente?
                      </p>
                      {buttonQ12 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ12Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ12Click}
                        />
                      )}
                    </div>
                    {buttonQ12 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          É descontada uma pequena{" "}
                          <strong>taxa de administração</strong> de 15% (quinze
                          por cento) sobre o valor total arrecadado nas
                          campanhas de doações de cada projeto. Os usuários
                          também podem doar para a própria plataforma, caso
                          queiram ajudar essa iniciativa a alcançar mais
                          cientistas, projetos, instituições e colaboradores da
                          Ciência.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Ao me cadastrar na We4Science, sou obrigado a doar ou
                        colaborar com algum projeto ou pesquisa?
                      </p>
                      {buttonQ13 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ13Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ13Click}
                        />
                      )}
                    </div>
                    {buttonQ13 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Claro que não. Fique a vontade para apenas ver e
                          conhecer os projetos e pesquisas científicas em nossa
                          plataforma. Você pode acompanhar os projetos e
                          novidades de acordo com suas próprias preferências
                          científicas. Quem sabe um dia você encontra algum que
                          mereça o seu apoio?
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">Como funciona a Moeda Whuffie? </p>
                      {buttonQ14 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ14Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ14Click}
                        />
                      )}
                    </div>
                    {buttonQ14 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Cada doação e colaboração realizada gera determinada
                          quantidade de “moedas Whuffie” para o apoiador. Com
                          essa moeda, ele pode comprar produtos, serviços e
                          experiências oferecidas pelas empresas cadastradas na
                          plataforma. Esses produtos, serviços e experiências
                          ficam disponíveis no{" "}
                          <Link to="/store">Mercado Whuffie.</Link>
                          <br />
                          <br />
                          As instituições de Ensino, Pesquisa e Ciência também
                          geram moedas Whuffie para si mesmas quando colaboram
                          nos projetos de outras instituições, finalizam uma
                          campanha de arrecadação para um projeto/pesquisa com
                          sucesso ou vencem Desafios lançados por empresas na
                          plataforma.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Um apoiador pode comprar produtos, serviços e
                        experiências de qualquer empresa cadastrada?
                      </p>
                      {buttonQ15 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ15Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ15Click}
                        />
                      )}
                    </div>
                    {buttonQ15 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Sim. Ele pode usar suas moedas para adquirir os
                          produtos, serviços e experiências de qualquer empresa
                          cadastrada, desde que eles estejam disponíveis no
                          Mercado Whuffie e o usuário tenha saldo de moedas
                          suficiente. Trata-se de um incentivo para que os
                          colaboradores possam ajudar cada vez mais projetos e
                          pesquisas científicas de qualquer instituição
                          cadastrada.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        As empresas são obrigadas a oferecer produtos ou
                        serviços no Mercado Whuffie?
                      </p>
                      {buttonQ16 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ16Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ16Click}
                        />
                      )}
                    </div>
                    {buttonQ16 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Não. Oferece quem quer, como e quando quiser.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Como é definida a quantidade de moedas que cada
                        colaborador recebe? E o valor dos produtos, serviços e
                        experiências?{" "}
                      </p>
                      {buttonQ17 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ17Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ17Click}
                        />
                      )}
                    </div>
                    {buttonQ17 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Existe uma tabela fixa que define quanto cada tipo de
                          colaboração rende em moedas aos seus colaboradores. Já
                          os produtos, serviços e experiências oferecidos pelas
                          empresas são precificados livremente por elas mesmas.
                          Veja a seguir as formas de adquirir moedas Whuffie.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Formas de adquirir Moedas Whuffies
                      </p>
                      {buttonQ18 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ18Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ18Click}
                        />
                      )}
                    </div>
                    {buttonQ18 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          ▪ Na confirmação de cada cadastro de usuário – W$ 01
                          moeda. <br />
                          ▪ Doação de valores – R$1,00 corresponde a W$ 01
                          moeda; (não existe centavos,
                          <br />
                          sempre arredondamos para cima. Exemplo: Doou R$ 10,15
                          ganha 11 Whuffies) <br />
                          ▪ Colaboração Técnica – W$ 100 moedas; <br />
                          ▪ Serviços – W$ 300 moedas; <br />
                          ▪ Parcerias Institucionais – W$ 500 moedas; <br />▪
                          Compartilhamento nas Redes Sociais – W$ 05 moedas;{" "}
                          <br />
                          ▪ As instituições de educação, ciência e pesquisa
                          ganham moedas whuffie de acordo com suas colaborações
                          em projetos de outras instituições e também com os
                          valores arrecadados em seus próprios projetos.
                          Exemplo: arrecadou R$ 10.000,00 = W$ 10.000. <br />
                          <br />
                          <br />
                          Importante: instituições não podem usar suas moedas
                          para aquisição de produtos, serviços e experiências no
                          Mercado Whuffie.
                          <br />
                          <br />
                          Seu saldo de moedas serve exclusivamente para lhe dar
                          mais destaque na plataforma e aumentar a visibilidade
                          dos seus projetos.
                          <br />
                          <br />
                          ▪ Empresas ganham moedas na medida em que fazem
                          doações, colaborações e têm seus produtos, serviços ou
                          experiências adquiridos por outros usuários no Mercado
                          Whuffie.
                          <br />
                          ▪ Usuários do tipo Apoiador-Visitante ganham moedas
                          Whuffie na medida em que fazem doações e colaborações
                          nos projetos e vencem os desafios empresariais.
                          <br />
                          Colaborações técnicas, prestação de serviços e
                          parcerias institucionais precisam ser validadas pelo
                          cientista titular do projeto para ser contabilizada no
                          perfil do Apoiador.
                          <br />
                          <br />
                          Doações em dinheiro já contabilizam moedas
                          automaticamente no perfil do Apoiador-Doador, com
                          exceção das doações realizadas pelo sistema PIX ou
                          Depósito Identificado, cujas moedas Whuffie são
                          contabilizadas e lançadas no perfil do Apoiador-Doador
                          após a confirmação do pagamento pela administração da
                          plataforma W4S.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Posso doar para o meu próprio projeto ou pesquisa
                        científica?
                      </p>
                      {buttonQ22 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ22Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ22Click}
                        />
                      )}
                    </div>
                    {buttonQ22 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">Pode sim</p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">
                        Posso colaborar para o meu próprio projeto ou pesquisa
                        científica para gerar moedas Whuffie?
                      </p>
                      {buttonQ19 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ19Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ19Click}
                        />
                      )}
                    </div>
                    {buttonQ19 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Não. Mas você pode incentivar seus amigos e familiares
                          a colaborarem em seu projeto.{" "}
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">Como funcionam os Desafios?</p>
                      {buttonQ20 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ20Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ20Click}
                        />
                      )}
                    </div>
                    {buttonQ20 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Apenas perfis de Empresas podem lançar Desafios com o
                          objetivo de solucionarem problemas ou encontrarem
                          oportunidades e inovações organizacionais a partir da
                          contribuição da nossa comunidade de usuários e das
                          Instituições de Educação e Ciência que habitam nossa
                          plataforma, além da possível participação de outras
                          empresas.
                          <br />
                          <br />
                          Contudo, para lançar um Desafio, as empresas precisam
                          oferecer prêmios em moedas Whuffie aos vencedores,
                          incentivando que essas empresas também doem para
                          projetos e pesquisas científicas encontrados aqui.
                          Assim, elas aumentam seu saldo Whuffie e podem lançar
                          desafios com premiações atraentes, que irão incentivar
                          mais pensadores a solucionarem seus problemas ou
                          encontrarem novas oportunidades e inovações para o seu
                          negócio.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">O que é o Fundo Cosmos?</p>
                      {buttonQ21 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ21Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ21Click}
                        />
                      )}
                    </div>
                    {buttonQ21 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Fundo Cosmos é a conta geral da plataforma We4Science,
                          aqui os apoiadores da Ciência podem fazer doações sem
                          precisar escolher um projeto científico específico.
                          Quando houver doações suficientes, nós lançaremos
                          editais e desafios periodicamente para destinar os
                          recursos desse fundo, que terão como destinação
                          projetos ligados à Sustentabilidade e Meio Ambiente,
                          Saúde, Educação e também Exploração Espacial. É isso
                          mesmo que você leu: Exploração Espacial - “ao infinito
                          e além!”
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                  <Question>
                    <div className="title-question-box">
                      <p id="title-question">O que significa Whuffie? </p>
                      {buttonQ23 ? (
                        <img
                          src={iconLess}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ23Click}
                        />
                      ) : (
                        <img
                          src={iconPlus}
                          className="btn-questions"
                          alt=""
                          onClick={handleQ23Click}
                        />
                      )}
                    </div>
                    {buttonQ23 && (
                      <Fade top duration={1000} distance="20px">
                        <p id="text-question">
                          Criado originalmente pelo escritor canadense Cory
                          Doctorow, o termo Whuffie designa uma "moeda" fictícia
                          baseada na reputação das pessoas no ambiente virtual.
                          Cada pessoa pode adquirir mais ou menos moedas Whuffie
                          de acordo com suas boas práticas e ações na internet.
                          Quanto maior o saldo de moedas Whuffie de uma pessoa
                          no mundo online, mais credibilidade e benefícios
                          estarão disponíveis para ela no mundo real.
                        </p>
                      </Fade>
                    )}

                    <section></section>
                  </Question>
                </Scrollbars>
              </ul>
            </RegisterContent>

            <Footer />
          </Content>
        </Bounce>
      </Wrapper>
    </>
  );
}

export default Contact;
