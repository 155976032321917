import React from "react";
import Scrollbars from "react-custom-scrollbars";

import { Container, New } from "./styles";
import YouTube from "react-youtube";

import iconCalendarPurple from "../../../../assets/icons/Project/calendar-purple.svg";

function NewContent({ data }) {
  const opts = {
    height: "335",
    width: "542",
    playerVars: {
      autoplay: 0,
    },
  };

  function YouTubeGetIDNews(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/); // eslint-disable-next-line
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
  return (
    <Container>
      <p id="title-new"> Novidades </p>
      <div className="data">
        <ul>
          {data && data.length > 0 && (
            <Scrollbars style={{ width: 655, height: 890 }}>
              {data.map((data) => (
                <New
                  img={data.image || data.yt_link ? true : false}
                  key={data.id}
                >
                  <div className="calendar-content">
                    <img src={iconCalendarPurple} alt="" />
                    <p> {data.formattedDate} </p>
                  </div>
                  <p id="title-new-content"> {data.title} </p>
                  <p> {data.content} </p>
                  {data.image && (
                    <img id="img-new" src={data.image.url} alt="" />
                  )}
                  {data.yt_link && (
                    <YouTube
                      id="img-new"
                      videoId={`${YouTubeGetIDNews(data.yt_link)}`}
                      opts={opts}
                    />
                  )}
                </New>
              ))}
            </Scrollbars>
          )}
        </ul>
      </div>
    </Container>
  );
}

export default NewContent;
