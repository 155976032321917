import React from "react";

import atomIcon from "../../../../assets/icons/About/atom.svg";
import headerImg from "../../../../assets/images/About/header-1.svg";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import NavBar from "../../../../components/SubHeader";

function Header() {
  return (
    <Wrapper>
      <NavBar />
      <Container>
        <Row>
          <Column>
            <img id="icon" src={atomIcon} alt="" />
            <h1>QUEM SOMOS</h1>
            <p id="text">
              Somos uma iniciativa que une instituições de ensino e pesquisa,
              cientistas, pesquisadores, empresas privadas e pessoas comuns que
              não entendem como a Ciência funciona, mas entendem sua importância
              para um mundo melhor. Somos uma grande comunidade de apoiadores e
              entusiastas da Ciência!
            </p>
          </Column>
          <Column larger>
            <ImgWrapper>
              <Img src={headerImg} alt="" />
            </ImgWrapper>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Header;
