import styled, { createGlobalStyle } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
    *{
        margin:0;
        padding:0;
        outline:0;
        border:0;
        box-sizing:border-box;
        -webkit-box-sizing:border-box;
    }
    *:focus{
        outline:0;    
    }

    html,body,#root {
        height: 100%;
        font-family:GothamPro;
    }

    body {
        -webkit-font-smoothing: antialiased;
        display:block;
        overflow:visible;
    }

    a{
        text-decoration: none;
    }

    button {
        cursor: pointer;
        font-size:16px;
    }

    ul{
        list-style:none;
    }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1386px;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${({ padding }) => (padding ? "0" : "50px")};
  padding-left: ${({ padding }) => (padding ? "0" : "50px")};

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media screen and (max-width: 472px) {
    margin-right: ${({ margin }) => (margin ? "0" : "auto")};
    margin-left: ${({ margin }) => (margin ? "0" : "auto")};
    padding-right: ${({ margin }) => (margin ? "0" : "15px")};
    padding-left: ${({ margin }) => (margin ? "0" : "15px")};
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "#4B59F7" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background: ${({ primary }) => (primary ? "#0467FB" : "#4B59F7")};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
