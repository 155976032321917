import React, { useState } from "react";

import { Container } from "../../../../styles/global";
import { Wrapper, Row, Column, ChangeProduct } from "./styles";

import engageIcon1 from "../../../../assets/icons/Home/engage-icon-1.svg";
import prev from "../../../../assets/icons/Common/product-left.svg";
import next from "../../../../assets/icons/Common/product-right.svg";

import Challenge from "../../../../components/Challenge";

import useChallenges from "../../../../hooks/useChallenges";

function Fifth() {
  const [page, setPage] = useState(0);
  const { challenges } = useChallenges();

  const paginateNext = () => {
    if (challenges[page + 2] !== undefined) {
      setPage(page + 1);
    }
  };

  const paginatePrev = () => {
    if (page >= 1) {
      setPage(page - 1);
    }
  };
  return (
   
    <Wrapper>
       {challenges !== null && challenges.length > 0 && (
      <Container>
        <Row>
          <Column larger>
            <div className="content-products">
              <div id="products">
                
                  <ChangeProduct>
                    <button
                      className="paginate-img"
                      id="margin"
                      disabled={page === 0 ? true : false}
                    >
                      <img src={prev} onClick={paginatePrev} alt="" />
                    </button>
                    <button
                      className="paginate-img"
                      disabled={
                        challenges[page + 2] === undefined ||
                        challenges[page + 1] === undefined
                          ? true
                          : false
                      }
                    >
                      <img src={next} onClick={paginateNext} alt="" />
                    </button>
                  </ChangeProduct>
                
              </div>
              <div id="second">
                {challenges !== null && challenges.length > 0 && (
                  <>
                    <Challenge special={true} data={challenges[page]} />

                    {challenges.length > 1 && (
                      <Challenge special={true} data={challenges[page + 1]} />
                    )}
                  </>
                )}
              </div>
            </div>
          </Column>
          <Column>
            <img className="img-1" src={engageIcon1} alt="" />
            <h1>Benefícios para sua empresa! </h1>
            <p id="science-2">
              Ao se cadastrar como apoiador empresarial, você poderá lançar
              desafios para solucionar problemas, encontrar oportunidades e
              gerar inovações competitivas para o seu negócio, além de
              fortalecer o marketing social ligado a sua marca!
            </p>
          </Column>
        </Row>
      </Container>)}
    </Wrapper>
  );
}

export default Fifth;
