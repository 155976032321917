import React from "react";

import Header from "./container/Header";
import First from "./container/First";
import Second from "./container/Second";
import Third from "./container/Third";

import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

function Cosmos() {
  return (
    <>
      <Helmet>
        <title>Fundo Cosmos</title>
      </Helmet>
      <Header />
      <First />
      <Second />
      <Third />
      <Footer />
    </>
  );
}

export default Cosmos;
