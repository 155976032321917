import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
    const persistedReducer = persistReducer({
            key: 'we4science',
            storage,
            whitelist: ['auth', 'user','cookies'],
        },
        reducers
    );

    return persistedReducer;
};