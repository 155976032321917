import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 55px;
  width: 352px;

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const ProductInfo = styled.div`
  width: 352px;
  height: 334px;
  border-radius: 10px;

  -webkit-box-shadow: 0 0 15px 0 rgba(49, 22, 159, 0.09);
  box-shadow: 0 0 13px 0 rgba(49, 22, 159, 0.09);

  .product-content-2 {
    width: 100%;
    height: 275px;

    background-color: #227af1;
    border-radius: 10px 10px 0px 0px;

    .title-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      padding-left: 27.4px;

      border-bottom: 1px solid ${darken(0.05, "#227af1")};

      #info-text {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.5;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }

      #company,
      #date {
        margin-top: 10px;

        width: 209px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
    }

    .description {
      margin-top: 24.6px;
      padding-bottom: 11px;
      padding-left: 27.4px;

      border-bottom: 1px solid ${darken(0.05, "#227af1")};

      #infos-description {
        margin-bottom: 14px;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
    }

    #price-text-1 {
      margin-top: 24.6px;
      margin-left: 27.4px;

      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .product-price {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;

      margin-left: 27.4px;
      margin-top: 10px;

      #price-value {
        font-size: 35px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #5cf3ff;
      }
      #price-value-2 {
        margin-right: 10px;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #5cf3ff;
      }
      #price-text {
        margin-right: 10px;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #5cf3ff;
      }
    }
  }
  #product-content-3 {
    width: 100%;
    height: 59px;
    border-radius: 0 0 10px 10px;
    background: rgb(44, 86, 227);
    background: linear-gradient(
      90deg,
      rgba(44, 86, 227, 1) 32%,
      rgba(68, 169, 252, 1) 77%,
      rgba(106, 192, 255, 1) 96%
    );



    padding: 0 0 0 27.4px;

    .content {
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 18px;

      img {
        margin-right: 9.4px;
      }
      #product-link {
        background: transparent;

        font-family: GothamProRegular;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;

        transition: all 0.3s ease-in-out 0s;

        &:hover {
          color: ${darken(0.2, "#FFF")};
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-top: 5vh;

    p {
      font-size: 0.9rem !important;
    }
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 352px;

  border-radius: 10px;

  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  margin-top: 20px;

  .title-company {
    max-width: 100%;

    padding: 29px 0 17.6px 23px;

    border-bottom: 1px solid #eee;

    #company-name {
      font-size: 20px;
      font-weight: 500;
      text-align: left;

      color: #227af1;
    }
  }

  .logo-company {
    max-width: 100%;
    border-bottom: 1px solid #eee;
    .content-img {
      display: flex;
      justify-content: center;
      align-items: center;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      /* Webkits */

      img {
        max-height: 115px;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  .social-networks {
    padding: 15.3px 0 10.8px 23px;
    border-bottom: 1px solid #eee;

    #title-social-networks {
      margin-bottom: 10px;

      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      text-align: left;
      color: #7f7fb9;
    }

    .icons {
      display: flex;
      align-items: center;

      /* Webkits */
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      /* Webkits */

      svg {
        margin-right: 27.2px;

        cursor: pointer;

        transition: all 0.3s ease-in-out 0s;
        /* Webkits */
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        /* Webkits */
        &:hover {
          color: ${darken(0.1, "#7f7fb9")};
        }
      }
    }
  }

  .adress {
    width: 100%;

    padding: 20.6px 10px 25px 23px;

    #title-adress-networks {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      text-align: left;

      color: #7f7fb9;

      margin-bottom: 10px;
    }

    #text-adress {
      max-width: 100%;
      word-wrap: break-word;
      cursor: pointer;
      color: #227af1;
      transition: all 0.3s ease-in-out 0s;
      text-decoration: underline;
      &:hover {
        color: ${darken(0.1, "#227af1")};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90% !important;

    .logo-company {
      width: 100% !important;
    }
    .phone-company {
      width: 100% !important;
      #title-phone-company,
      .number {
        font-size: 0.85rem !important;
      }
    }
    .email-company {
      width: 100% !important;
      #title-email-company,
      .content-email-company {
        font-size: 0.8rem !important;
      }
    }
    .social-networks {
      width: 100% !important;
      #title-social-networks {
        font-size: 0.8rem !important;
      }
    }
    .adress {
      width: 100% !important;
      #title-adress-networks {
        font-size: 0.8rem !important;
      }

      #text-adress {
        width: 80% !important;
        word-wrap: break-word;
        font-size: 0.8rem !important;
      }
    }
  }
`;
