import { useEffect, useState } from "react";
import api from "../services/api";

export default function useSupporters() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const get = async () => {
      const { data } = await api.get("assistants");
      setImages(data);
    };
    get();
  }, []);

  return {images};
}
