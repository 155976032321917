import React from "react";

import { Container } from "./styles";

import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";

import GoBackTerms from "../../components/GoBackTerms";

function politicTerms() {
  return (
    <>
      <Helmet>
        <title>Política de Privacidade do Website e da Plataforma</title>
      </Helmet>
      <Fade left duration={1600} distance="20px">
        <Container>
          <GoBackTerms />
          <div className="term">
            <p align="center">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <span style={{ fontSize: "large" }}>
                    <strong>
                      Política de Privacidade do Website e da Plataforma{" "}
                    </strong>
                  </span>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <span style={{ fontSize: "large" }}>
                    <em>
                      <strong>We4Science</strong>
                    </em>
                  </span>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <span style={{ fontSize: "small" }}>
                    <em>Última atualização: 22/01/2021</em>
                  </span>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>SOBRE A PLATAFORMA</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  A We4Science é uma organização de apoio ao desenvolvimento
                  científico nacional e internacional a partir da promoção,
                  colaboração e financiamento coletivo a projetos e pesquisas
                  científicas. Nesse sentido, construímos e disponibilizamos uma
                  plataforma online segura e intuitiva, que facilita e incentiva
                  a divulgação do conhecimento científico, bem como a interação
                  direta entre cientistas, pesquisadores, instituições de ensino
                  e pesquisa, empresas e apoiadores científicos.{" "}
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Assim, a presente Política de Privacidade do Website e da
                  Plataforma{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science</strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  trata, por sua vez, da coleta e do processamento de dados
                  pessoais dos visitantes do site e usuários cadastrados na
                  plataforma, ou seja, seus “titulares”. Os dados de contato que
                  aqui serão detalhados são fornecidos pelos próprios titulares
                  voluntariamente, através dos meios de contato disponíveis em
                  nossa página.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Além dos dados de contato detalhados nesta Política, este site
                  também armazena cookies em seu navegador para personalizar sua
                  experiência, corrigir erros e melhorar a usabilidade do site.
                  Você pode configurar suas preferências a respeito do
                  armazenamento de cookies. Para saber mais, leia nossa&nbsp;
                </span>
              </span>

              <a
                href="https://www.inloco.com.br/politicas/cookies?hsLang=pt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#0d0d0d" }}>
                  <span style={{ fontFamily: "Arial, serif" }}>
                    <u>Política de Cookies</u>
                  </span>
                </span>
              </a>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  ao final deste documento.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>DO TRATAMENTO DE DADOS PESSOAIS</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>Fonte dos dados pessoais</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Nosso website coleta dados pessoais por meio do preenchimento
                  de nossos formulários disponíveis em nossas páginas, bem como,
                  no rodapé de{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>
                    nosso site para resolver dúvidas de nossos usuários, fazer
                    contato com eles e/ou customizar sua experiência de
                    navegação e acesso a conteúdos em nossa plataforma
                  </strong>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>.</span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Os dados coletados nos formulários são utilizados para{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    registro e comunicação com potenciais clientes, fornecer os
                    serviços prestados por nossa Plataforma e, caso autorizado
                    pelo titular de dados, segmentação e campanhas de marketing.
                  </em>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    Todos os dados são utilizados estritamente para os fins
                    especificados nesta Política de Privacidade e são informados
                    abaixo.
                    <br /> <br />{" "}
                  </em>
                </span>
              </span>
              <span style={{ color: "#222222" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <span style={{ fontSize: "medium" }}>
                    Por fim, o plugin WhatsApp (janela de bate-papo) que está
                    disponível em nosso site para que o titular tire dúvidas
                    sobre temas gerais sobre a Plataforma We4Science ou para
                    direcionar o titular a um membro da equipe We4Science para
                    que possa respondê-lo. Esse canal coleta informações de
                    contato (como nome, número de telefone) para que a
                    comunicação continue em caso de desconexão.
                  </span>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>Dados Pessoais e Finalidades do Tratamento</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  O presente documento visa prestar informações sobre a coleta,
                  utilização, processamento e o armazenamento de dados pessoais
                  (art. 5º, X, LGPD) fornecidos por seus Usuários, estando em
                  conformidade com a Legislação Brasileira, especialmente quanto
                  a Lei nº 12.965/2014 (Marco Civil da Internet) quanto a Lei nº
                  13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>A </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science</strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  segue os princípios estabelecidos na legislação nacional de
                  proteção de dados (LGPD), especialmente aos que estabelecem
                  que o tratamento de dados pessoais deve ser relevante,
                  adequado e transparente, se limitando ao tratamento mínimo de
                  dados necessário ao funcionamento da plataforma e os objetivos
                  dos serviços a serem prestados, nos termos do{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>artigo </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  6º da Lei nº 13.709/2018.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Assim, ao aceitar o presente termo, você declara e reconhece
                  que a coleta e o tratamento de seus dados pessoais é
                  necessária para a execução dos serviços prestados pela
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong> We4Science</strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  , conforme os requisitos de tratamento de dados pessoais
                  previstos na Lei Geral de Proteção de Dados Pessoais (art. 7º,
                  Lei 13.709/2018):
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <table width="100%" cellSpacing={0} cellPadding={8}>
              <thead>
                <tr>
                  <td
                    style={{ background: "#bfbfbf" }}
                    bgcolor="#bfbfbf"
                    width="18%"
                  >
                    <p align="center">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            <strong>Tipo de Dado Pessoal</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#bfbfbf" }}
                    bgcolor="#bfbfbf"
                    width="30%"
                  >
                    <p align="center">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            <strong>Base Legal</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#bfbfbf" }}
                    bgcolor="#bfbfbf"
                    width="52%"
                  >
                    <p align="center">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            <strong>Finalidade do Tratamento</strong>
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>Nome</span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para a execução de contrato ou de
                            procedimentos preliminares relacionados a contrato
                            do qual seja parte o titular, a pedido do titular
                            dos dados (art. 7º, V, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Identificação do Usuário. Trata-se de dado pessoal
                            essencial para que seja possível entrar em contato
                            com o Usuário, para atender às suas solicitações e
                            fornecer respostas direcionadas.{" "}
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="18%">
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>E-mail</span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="top" width="30%">
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para a execução de contrato ou de
                            procedimentos preliminares relacionados a contrato
                            do qual seja parte o titular, a pedido do titular
                            dos dados (art. 7º, V, Lei nº 13.709/2018); e b)
                            para atender aos interesses legítimos do controlador
                            ou de terceiros (art. 7º, IX, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="top" width="52%">
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            a) É usado como identificador da conta do usuário na
                            plataforma. Além disso é meio de comunicação com o
                            Usuário, para envio de mensagens informativas. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site; b)
                            Direcionamento de conteúdo de marketing, bem como de
                            campanhas publicitárias, melhorando a experiência do
                            Usuário. Além disso, é relevante para saber se a
                            pessoa que está se comunicando é maior de idade,
                            respeitando as normas de uso da plataforma.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Telefone Fixo e/ou Celular
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para a execução de contrato ou de
                            procedimentos preliminares relacionados a contrato
                            do qual seja parte o titular, a pedido do titular
                            dos dados (art. 7º, V, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Meio de comunicação com o Usuário. Além de forma
                            para contato entre Usuários e Plataforma. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="18%">
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>Endereço</span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="top" width="30%">
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessários para a execução de contrato ou de
                            procedimentos preliminares relacionados a contrato
                            do qual seja parte o titular, a pedido do titular
                            dos dados (art. 7º, V, Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="top" width="52%">
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            É usado para a localização do Usuário da plataforma.
                            Além de forma para contato entre Usuários e
                            Plataforma. Essa informação só é obtida quando, de
                            forma livre, inequívoca e informada, o Usuário
                            preenche espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Data de nascimento
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para a execução de contrato ou de
                            procedimentos preliminares relacionados a contrato
                            do qual seja parte o titular, a pedido do titular
                            dos dados (art. 7º, V, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para calcular a idade da pessoa exibida
                            na plataforma, e averiguar se o Usuário respeita os
                            limites de idade da plataforma. Além disso, é usada
                            para refinar os resultados de buscas de moradia, de
                            forma que possamos entregar resultados mais
                            relevantes e compatíveis com as pessoas que estão
                            buscando dados na plataforma.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>Sexo</span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 11º, I, Lei nº 13.709/2018) c/c
                            Cumprimento de obrigação legal ou regulatória pelo
                            controlador (art. 7º, IX, II, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para cumprimento de legislações fiscais
                            e/ou tributárias; comprovação de maioridade legal
                            (art. 4º e 5º, Lei 10.406/2002);
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="18%">
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>CPF</span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="top" width="30%">
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Cumprimento de obrigação legal ou regulatória pelo
                            controlador (art. 7º, IX, II, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td valign="top" width="52%">
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para cumprimento de legislações fiscais
                            e/ou tributárias, emissão de notas fiscais;
                            comprovantes de pagamento e/ou transferência
                            eletrônica de valores; exigência dos termos de uso
                            da plataforma e para a correta prestação de serviços
                            pela plataforma para o Usuário. Ainda, por exigência
                            dos sistemas de intermediação de pagamento, bem
                            como, para identificação do Usuário na plataforma.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            IP (Internet Protocol), Localização, Fonte de
                            referência, Tipo de navegador, Duração da visita,
                            Páginas visitadas
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Cumprimento de obrigação legal ou regulatória pelo
                            controlador (art. 7º, II, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Obediência ao artigo 15 da Lei n. 12.965/2014, que
                            impõe o dever da Plataforma em manter os respectivos
                            registros de acesso a aplicações de internet, sob
                            sigilo, em ambiente controlado e sob segurança,{" "}
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            <strong>pelo prazo mínimo de 6 (seis) meses</strong>
                          </span>
                        </span>
                      </span>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>.</span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#ffffff" }}
                    bgcolor="#ffffff"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Seus Interesses Científicos
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 11º, I, Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Para customizar a experiência do usuário quanto ao
                            seu acesso ao conteúdo da plataforma. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Nº WhatsApp
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 11º, I, II, Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Meio de comunicação com o Usuário. Além de forma
                            para contato entre Usuários e Plataforma. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#ffffff" }}
                    bgcolor="#ffffff"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Seu Facebook
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 11º, I, II, Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Meio de comunicação com o Usuário. Além de forma
                            para contato entre Usuários e Plataforma. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Seu LinkedIn
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 11º, I, II, Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Meio de comunicação com o Usuário. Além de forma
                            para contato entre Usuários e Plataforma. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#ffffff" }}
                    bgcolor="#ffffff"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Seu Twitter
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 11º, I, II, Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Meio de comunicação com o Usuário. Além de forma
                            para contato entre Usuários e Plataforma. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Seu Instagram
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 11º, I, II, Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Meio de comunicação com o Usuário. Além de forma
                            para contato entre Usuários e Plataforma. Essa
                            informação só é obtida quando, de forma livre,
                            inequívoca e informada, o Usuário preenche
                            espontaneamente formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#ffffff" }}
                    bgcolor="#ffffff"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>Profissão</span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        {" "}
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para a execução de contrato ou de
                            procedimentos preliminares relacionados a contrato
                            do qual seja parte o titular, a pedido do titular
                            dos dados (art. 7º, V, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Forma de identificação do usuário pela plataforma e
                            por outros usuários. Também permite que o usuário
                            possa mostrar suas qualificações profissionais e
                            assim otimizar suas interações sociais e
                            profissionais dentro da plataforma. Essa informação
                            só é obtida quando, de forma livre, inequívoca e
                            informada, o Usuário preenche espontaneamente
                            formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Empresa/ Instituição
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Necessário para a execução de contrato ou de
                            procedimentos preliminares relacionados a contrato
                            do qual seja parte o titular, a pedido do titular
                            dos dados (art. 7º, V, Lei nº 13.709/2018).
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Forma de identificação do usuário pela plataforma e
                            por outros usuários. Também permite que o usuário
                            possa mostrar suas qualificações profissionais e
                            assim otimizar suas interações sociais e
                            profissionais dentro da plataforma. Essa informação
                            só é obtida quando, de forma livre, inequívoca e
                            informada, o Usuário preenche espontaneamente
                            formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#ffffff" }}
                    bgcolor="#ffffff"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Link Plataforma Lattes
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 7º, I Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Forma de identificação do usuário pela plataforma e
                            por outros usuários. Também permite que o usuário
                            possa mostrar suas qualificações profissionais e
                            assim otimizar suas interações sociais e
                            profissionais dentro da plataforma. Essa informação
                            só é obtida quando, de forma livre, inequívoca e
                            informada, o Usuário preenche espontaneamente
                            formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#f9f9f9" }}
                    bgcolor="#f9f9f9"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mini Currículo
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 7º, I Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#f9f9f9" }}
                    valign="top"
                    bgcolor="#f9f9f9"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Forma de identificação do usuário pela plataforma e
                            por outros usuários. Também permite que o usuário
                            possa mostrar suas qualificações profissionais e
                            assim otimizar suas interações sociais e
                            profissionais dentro da plataforma. Essa informação
                            só é obtida quando, de forma livre, inequívoca e
                            informada, o Usuário preenche espontaneamente
                            formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ background: "#ffffff" }}
                    bgcolor="#ffffff"
                    width="18%"
                  >
                    <p>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Nível de Escolaridade
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="30%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Mediante o fornecimento de consentimento pelo
                            titular (art. 7º, I Lei nº 13.709/2018)
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{ background: "#ffffff" }}
                    valign="top"
                    bgcolor="#ffffff"
                    width="52%"
                  >
                    <p align="justify">
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span style={{ fontSize: "0.89rem" }}>
                            Forma de identificação do usuário pela plataforma e
                            por outros usuários. Também permite que o usuário
                            possa mostrar suas qualificações profissionais e
                            assim otimizar suas interações sociais e
                            profissionais dentro da plataforma. Essa informação
                            só é obtida quando, de forma livre, inequívoca e
                            informada, o Usuário preenche espontaneamente
                            formulários do site.
                          </span>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>A </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science</strong>
                  </em>
                </span>
              </span>{" "}
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  poderá requerer documentos adicionais ao(s) Usuário(s) no
                  exercício de suas atividades, além de demais dados cadastrais,
                  com base no art. 7º, I, Lei nº 13.709/2018.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>FORMA E DURAÇÃO DO TRATAMENTO</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>Decisão automatizada</strong>
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Não ocorre decisão exclusivamente automatizada para nenhuma
                  das finalidades do tratamento de dados pessoais detalhado
                  nesta Política de Privacidade. Todo o tratamento realizado com
                  os dados coletados pelos meios de contato do website tem
                  interferência humana de membros de nossa equipe.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>Medidas de segurança</strong>
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Os dados a que se refere esta Política são coletados
                  diretamente do titular que, voluntariamente os fornece por
                  meio dos formulários e, são utilizados apenas para as
                  finalidades pretendidas e autorizadas pelo titular.{" "}
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Os dados pessoais tratados como imagens, senhas e informações
                  dos usuários são armazenados pela{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>
                      We4Science no banco de dados do servidor contratado
                      Kinghost
                    </strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  , que aplica medidas físicas, técnicas e organizacionais de
                  segurança, como criptografia no transporte e armazenamento de
                  dados. Além disso, tais informações são acessadas por um
                  número restrito de colaboradores e ficam armazenadas apenas
                  pelo tempo necessário para as finalidades descritas nesta
                  Política.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>
                    Compartilhamento
                    <br />{" "}
                  </strong>
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ fontFamily: "Arial, serif" }}>
                Apenas compartilhamos dados com operadores, isto é, agentes que
                realizam tratamento de dados em nome de um controlador (nesse
                caso, a We4Science) para facilitar a operação. Estes agentes não
                podem utilizar as informações compartilhadas pela We4Science
                para nenhuma finalidade que não aquelas pretendidas pela própria
                We4Science. São eles: Kinghost para hospedagem e armazenamento
                de dados; Paypal para transações financeiras; Whatsapp e
                Telegram para contato, Youtube, Twitter, Facebook, Instagram e
                Linkedin para relacionamento e promoção da plataforma.
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Portanto, ao aceitar essas políticas você{" "}
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>
                    declara e reconhece estar ciente e de acordo com a
                    transferência internacional de dados, conforme artigo 7º, I,
                    c/c art. 33, VII, da Lei nº 13709/2018
                  </strong>
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}> (LGPD).</span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Ademais, dados pessoais de Usuários poderão ser compartilhados
                  com outros Usuários da plataforma em função dos serviços
                  prestados, bem como, para a execução dos serviços contratados
                  por meio desta plataforma, nos termos do art. 7º, II{" "}
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>e</em>
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  V, Lei nº 13.709/2018.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>
                    RESPONSABILIDADE DOS AGENTES DE TRATAMENTO DE DADOS PESSOAIS
                  </strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>A </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science</strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  realiza o tratamento de dados pessoais como controlador,
                  agente ao qual competem as decisões referentes ao tratamento,
                  nos termos do{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>art. </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  5º, VI da Lei Geral de Proteção de Dados.{" "}
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>A </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science</strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  indica como seu encarregado pelo tratamento de dados pessoais
                  o CEO desta empresa, Sr. Emerson César Lemos Araújo, email:{" "}
                </span>
              </span>
              <span style={{ color: "#0000ff" }}>
                <u>
                  <a href="mailto:contato@we4science.com">
                    <span style={{ fontFamily: "Arial, serif" }}>
                      contato@we4science.com
                    </span>
                  </a>
                </u>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  , nos termos do art. 41 da Lei Geral de Proteção de Dados, de
                  modo a aceitar reclamações e/ou comunicações dos titulares e
                  da Autoridade Nacional de Proteção de Dados, para prestar
                  esclarecimentos e adotar providências em razão de sua
                  obrigação legal.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>Direitos do titular</strong>
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>A </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science</strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  tem como missão respeitar a privacidade e assegurar a proteção
                  de dados pessoais de seus usuários.{" "}
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Assim, garantimos ao titular de dados pessoais o exercício de
                  seus direitos nos exatos termos da legislação aplicável,
                  podendo o titular obter da plataforma, em relação aos dados
                  por ela tratados, a qualquer momento e mediante requisição:
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Confirmação da existência de tratamento de dados;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Acesso aos dados;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Correção de dados incompletos, inexatos ou desatualizados;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Anonimização, bloqueio ou eliminação de dados desnecessários,
                  excessivos ou tratados em desconformidade com o disposto na
                  lei;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Portabilidade dos dados a outro fornecedor de serviço ou
                  produto, mediante requisição expressa e observados os segredos
                  comercial e industrial;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Eliminação dos dados tratados com o consentimento do titular,
                  exceto nas hipóteses previstas na Lei;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Informação das entidades públicas e privadas com as quais a{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science </strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  realizou uso compartilhado de dados;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Informação sobre a possibilidade de não fornecer consentimento
                  e sobre as consequências da negativa;
                </span>
              </span>
            </p>
            <p align="justify">
              <span style={{ color: "#000000" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Revogação do consentimento.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>POLÍTICA DE COOKIES</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Após sua autorização, este site envia pequenos arquivos de
                  texto (“cookies”) para o seu navegador quando você nos visita
                  pela primeira vez. Em uma visita posterior, seu navegador nos
                  envia esses cookies de volta, para que possamos nos lembrar de
                  você e de suas preferências ao navegar em nosso site. À medida
                  que você interage com nosso site, ele permanece trocando
                  cookies com seu navegador enquanto tivermos a sua permissão.
                  Essa troca de informações{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>entre os dois</em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  ocorre quando é estritamente necessário para o bom
                  funcionamento do website e/ou para fins de performance,
                  funcionalidade e/ou segmentação, de acordo com a categorização
                  prevista no{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>ICC UK Cookie Guide</strong>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  [https://www.huntingact.org/wp-content/uploads/icc-uk-cookie-guide.pdf].
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Os cookies caracterizados como estritamente necessários
                  possuem seu armazenamento ativo por padrão, afinal, sem eles
                  nosso website não funcionaria de forma apropriada. Esses
                  cookies não guardam informações que possam identificar você.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Os cookies armazenados para avaliação de performance têm como
                  objetivo a implementação de melhorias em nosso website.
                  Análises de comportamento dos nossos usuários nos permitem
                  saber quais partes do website são mais visitadas ou quando
                  ocorrem erros, por exemplo. Informações estatísticas são
                  armazenadas de forma agregada, o que impossibilita a
                  identificação inequívoca dos usuários, portanto, o
                  armazenamento de cookies de performance também fica sempre
                  ativo, sem comprometer sua privacidade.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Os cookies para fins de agregar funcionalidades são os
                  responsáveis por controlar suas preferências pessoais em nosso
                  site como idioma e tamanho do texto e fonte, além de permitir
                  que você entre em contato conosco diretamente e/ou que tire
                  dúvidas por meio do nosso “fale conosco”.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Os cookies de segmentação permitem que você seja impactado por
                  outras mídias apenas com aquilo que é mais relevante para
                  você.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Assim, os cookies necessários e os de performance são
                  fundamentais para o correto funcionamento desse website e não
                  permitem a identificação de usuários. Entretanto, o
                  armazenamento de cookies para fins de funcionalidade e
                  segmentação de nossos serviços em seu navegador é opcional e
                  pode ser recusado.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>SEGURANÇA</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>A </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science </strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  tem o compromisso de preservar a estabilidade, a segurança e
                  as funcionalidades da plataforma, por meio de medidas técnicas
                  compatíveis com os padrões internacionais e pelo estímulo ao
                  uso de boas práticas. Todavia nenhum serviço disponível na
                  internet possui total garantia de segurança, disponibilidade,
                  muito menos contra invasões e/ou acessos ilegais.{" "}
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Nos casos em que terceiros não autorizados tenham acesso aos
                  nossos sistemas de forma ilícita, a{" "}
                </span>
              </span>{" "}
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science </strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  diligenciará da melhor forma para identificar o(s)
                  responsável(eis) pela atuação ilícita, entretanto, a
                  plataforma não se responsabiliza pelos danos causados pelos
                  infratores.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>CONTATO</strong>
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>A </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>
                    <strong>We4Science </strong>
                  </em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  disponibiliza um formulário de contato, bem como,
                  disponibiliza um endereço eletrônico de e-mail como canal de
                  contato e comunicação com seus Usuários.
                </span>
              </span>
            </p>
            <p align="justify">&nbsp;</p>
            <p align="justify">
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  Assim, em caso de dúvida, caso queira fazer um comentário ou
                  mesmo enviar uma sugestão, por favor, entre em contato por
                  meio do Formulário{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>(link para Fale Conosco)</strong>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  {" "}
                  ou com o nosso Encarregado pelo Tratamento de Dados Pessoais
                  (“
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <em>Data Protection Officer</em>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  ”) enviando um e-mail para{" "}
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>
                  <strong>contato@we4science.com</strong>
                </span>
              </span>
              <span style={{ color: "#0d0d0d" }}>
                <span style={{ fontFamily: "Arial, serif" }}>.</span>
              </span>
              <br />
              <br />
              Li e aceito os Termos de Uso e as Políticas de Privacidade da
              plataforma We4Science.
              <br />
              <br />
            </p>
          </div>
        </Container>
      </Fade>
    </>
  );
}

export default politicTerms;
