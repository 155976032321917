import { darken } from "polished";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 60px 0 0 0;
  background: #fff;
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 54px;
  position: relative;
  z-index: 0;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  #icon {
    width: 56.4px;
    height: 62.8px;

    margin-bottom: 8px;
  }

  #title {
    font-size: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;
    text-transform: uppercase;
  }

  #text {
    margin-top: 10px;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    strong {
      color: #227af1;
      font-weight: bold;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    button {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      border: solid 2px #2976fa;
      background: #fff;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #227af1;

      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background: #227af1;
        color: #fff;
      }
    }
  }

  .register {
    margin-top: 58px;

    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.94;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;

      margin-bottom: 8px;
    }

    button {
      width: 352px;
      height: 60px;
      border-radius: 30px;
      background-color: #227af1;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #fff;


      transition: all 0.3s ease-in-out 0s;

      &:hover {
        background: ${darken(0.1, "#227af1")};
      }
    }
  }

  ${(props) =>
    props.larger &&
    css`
      max-width: 56.6%;
      flex-basis: 56.6%;

      padding-left: 15px;
      padding-bottom: 78px;
    `}

  ${(props) =>
    !props.larger &&
    css`
      max-width: 43.3%;
      flex-basis: 43.3%;
      justify-content: space-between;
      align-items: flex-end;
    `} 

  @media screen and (max-width: 805px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;

    #icon {
      width: 46.4px;
      height: 42.8px;
    }

    #text-subcontent {
      max-width: 100%;
    }

    .second {
      display: none;
    }
  }

  @media screen and (max-width: 472px) {
    #title {
      font-size: 1.5rem;
      text-align: justify;
    }

    #text {
      text-align: justify;
      line-height: 1.4;
    }

    .buttons {
      button {
        width: 100%;
      }
    }

    .register {
      p {
        font-size: 16px;
        text-align: justify;
        line-height: 1.4;
      }

      button {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 314px) {
    #title {
      font-size: 1rem;
    }
  }
`;

export const Img = styled.img`
  max-width: 100%;
  padding-right: 0;
  border: 0;
  vertical-align: middle;
`;
