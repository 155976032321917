import React from "react";
import { Container } from "../../../../styles/global";
import { Wrapper, Row, Column } from "./styles";

import iconSupporter from "../../../../assets/icons/Profile/profiles/support.svg";
import iconCheckWhite from "../../../../assets/icons/Common/check-white.svg";

function Seventh() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <img className="img-1" src={iconSupporter} alt="" />
            <h1> Como podemos apoiar a ciência ? </h1>
            <p id="subtitle">
              Existem muitas formas de apoiar a Ciência. A seguir listamos
              algumas das mais importantes:
            </p>
            <ul>
              <li>
                <div className="box-list">
                  <img id="icon-list" src={iconCheckWhite} alt="" />
                  <p id="title-list">Faça uma doação!</p>
                </div>
                <p id="text-list">
                  Infelizmente os cientistas e pesquisadores não podem avançar
                  em seus projetos e pesquisas se não houver dinheiro e recursos
                  para isso. Em nossa plataforma, você pode encontrar projetos e
                  pesquisas sérias, de instituições confiáveis e fazer doações
                  de maneira rápida e segura.
                </p>
              </li>
              <li>
                <div className="box-list">
                  <img id="icon-list" src={iconCheckWhite} alt="" />
                  <p id="title-list">Ofereça um apoio técnico!</p>
                </div>
                <p id="text-list">
                  Alguns projetos e pesquisas científicas podem ser
                  impulsionados se contarem com o conhecimento e apoio de outros
                  cientistas e pesquisadores. Ajude a criar uma grande rede de
                  cientistas e pesquisadores aqui na W4S!
                </p>
              </li>
              <li>
                <div className="box-list">
                  <img id="icon-list" src={iconCheckWhite} alt="" />
                  <p id="title-list">Compartilhe essa ideia!</p>
                </div>
                <p id="text-list">
                  Nem sempre temos dinheiro ou conhecimento técnico para apoiar
                  um projeto ou pesquisa científica, mas sempre podemos ajudar a
                  encontrar quem tenha! Compartilhe seus projetos favoritos nas
                  suas redes sociais, assim você também estará apoiando a
                  Ciência!
                </p>
              </li>
              <li>
                <div className="box-list">
                  <img id="icon-list" src={iconCheckWhite} alt="" />
                  <p id="title-list">
                    Valorize as empresas que apoiam a Ciência!
                  </p>
                </div>
                <p id="text-list">
                  Dê preferência por consumir produtos e serviços de empresas
                  que apoiam e ajudam a financiar projetos e instituições de
                  ensino e pesquisa científica.
                  <br />
                  <br />
                  Aqui na W4S nós te ajudamos a encontrá-las!
                </p>
              </li>
              <li>
                <div className="box-list">
                  <img id="icon-list" src={iconCheckWhite} alt="" />
                  <p id="title-list">Eu já faço Ciência. Como posso ajudar?</p>
                </div>
                <p id="text-list">
                  Cadastre gratuitamente seus melhores projetos e pesquisas
                  científicas em nossa plataforma. Depois, convide sua
                  universidade, centro de pesquisa ou empresa a apoiar a
                  Iniciativa We4Science – Todos pela Ciência. Convide também
                  seus amigos e colegas cientistas a fazerem o mesmo, pois
                  juntos podemos criar, descobrir e conquistar muito mais!
                </p>
              </li>
              <li>
                <div className="box-list">
                  <img id="icon-list" src={iconCheckWhite} alt="" />
                  <p id="title-list">
                    Represento uma empresa privada. Como posso ajudar?
                  </p>
                </div>
                <p id="text-list">
                  Cadastre-se gratuitamente em nossa plataforma. Depois,
                  cadastre alguns itens do seu portfólio de produtos, serviços
                  ou experiências no “Mercado Whuffie”. Assim você ajuda a
                  fortalecer nosso importante sistema de incentivos e
                  recompensas para que cada vez mais apoiadores se juntem à
                  Iniciativa We4Science – Todos pela Ciência. Claro, você também
                  apoia a Ciência quando lança Desafios em nossa plataforma que
                  valorizam nossos cientistas, quando decide doar ou investir
                  nos projetos cadastrados aqui ou quando cria parcerias com
                  universidades e instituições científicas.
                </p>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Seventh;
