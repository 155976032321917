import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.aside`
  width: 352px;

  margin-top: 125px;
  .company-info {
    width: 352px;
    height: 390.8px;

    border-radius: 10px;

    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    /* Webkits */
    -webkit-box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    /* Webkits */

    .title-company {
      height: 72.6px;
      padding-top: 29px;

      #company-name {
        margin-bottom: 17.6px;
        margin-left: 35px;

        font-size: 20px;
        font-weight: 500;
        text-align: left;

        color: #227af1;
      }

      section {
        width: 100%;
        height: 1px;

        background: #eee;
      }
    }

    .logo-company {
      height: 115px;
      width: 352px;
      .content-img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;

        /* Webkits */
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        /* Webkits */

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      section {
        width: 100%;
        height: 1px;

        background: #eee;
      }
    }

    .social-networks {
      height: 90.2px;
      padding-top: 15.3px;
      margin-top: 10px;

      #title-social-networks {
        margin-left: 33px;
        margin-bottom: 10px;

        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        text-align: left;
        color: #7f7fb9;
      }

      .icons {
        display: flex;
        align-items: center;

        margin-left: 33px;
        margin-bottom: 10.8px;

        /* Webkits */
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-align: center;
        -ms-flex-align: center;
        /* Webkits */

        svg {
          margin-right: 27.2px;

          cursor: pointer;

          transition: all 0.3s ease-in-out 0s;
          /* Webkits */
          -webkit-transition: all 0.3s ease-in-out 0s;
          -o-transition: all 0.3s ease-in-out 0s;
          /* Webkits */
          &:hover {
            color: ${darken(0.1, "#7f7fb9")};
          }
        }
      }

      section {
        width: 100%;
        height: 1px;
        background: #eee;
      }
    }

    .adress {
      height: 136.6px;
      padding-top: 20.6px;

      #title-adress-networks {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        text-align: left;

        color: #7f7fb9;

        margin-left: 33px;
        margin-bottom: 10px;
      }

      #text-adress {
        margin-left: 33px;
        cursor: pointer;
        color: #227af1;
        transition: all 0.3s ease-in-out 0s;
        text-decoration: underline;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  .special-premiun {
    height: 160px;
    padding: 15px;

    border-radius: 10px;

    box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 40px;

    .box-special-premiun {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;

      > p {
        font-weight: bold;
        color: #227af1;
        margin-left: 15px;
      }
    }

    p {
      color: #7f7fb9;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.57;
      text-align: left;
    }
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    width: 90%;
    margin-left: 5%;

    .challenge-info {
      width: 100%;
      margin-top: 5vh;

      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 0.9rem !important;
      }

      .content-3-challenge {
        position: relative;
        z-index: -2;
      }

      .content-4-challenge {
        margin-left: 0;
        width: 136px;
        height: 136px;

        z-index: 5;

        div {
          width: 110px;
          height: 110px;

          button {
            width: 90px;
            height: 90px;
          }
        }
      }
    }

    .company-info {
      width: 90% !important;
      margin-bottom: 10px;

      .logo-company {
        width: 100% !important;
      }
      .phone-company {
        width: 100% !important;
        #title-phone-company,
        .number {
          font-size: 0.85rem !important;
        }
      }
      .email-company {
        width: 100% !important;
        #title-email-company,
        .content-email-company {
          font-size: 0.8rem !important;
        }
      }
      .social-networks {
        width: 100% !important;
        #title-social-networks {
          font-size: 0.8rem !important;
        }
      }
      .adress {
        width: 100% !important;
        #title-adress-networks {
          font-size: 0.8rem !important;
        }

        #text-adress {
          width: 80% !important;
          word-wrap: break-word;
          font-size: 0.8rem !important;
        }
      }
    }

    .special-premiun {
      width: 90% !important;

      margin-bottom: 30px;

      p {
        font-size: 0.85rem;
      }
    }
  }
`;
