import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { Bounce } from "react-reveal";

import { Wrapper, Content, Header, MainContent, BoxLabel } from "./styles";

//API
import api from "../../services/api";

import imgArticle from "../../assets/images/article.svg";
import iconClips from "../../assets/icons/Profile/clips.svg";
// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";

import { Form, Input, Textarea } from "@rocketseat/unform";
import { toast } from "react-toastify";

import { store } from "../../store";
import { useHistory } from "react-router-dom";

function Article() {
  const [imageNew, setImageNew] = useState([]);

  const ref = useRef();

  async function handleChangeEdit(e) {
    const data = new FormData();

    data.append("file", e.target.files[0]);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    var newfile = Object.assign({ id, url });

    setImageNew([...imageNew, newfile]);
  }

  function deleteImg(id) {
    api.delete(`files/${id}`);
  }

  const { perfil } = store.getState().auth;
  const { profile } = store.getState().user;

  const history = useHistory();

  async function handleSubmit(data) {
    data.image_id = imageNew[0].id;

    data.user_id = profile.id;
    data.perfil = perfil;

    try {
      await api.post("articles", data);
      toast.success("Artigo enviado!");
      setTimeout(() => {
        history.push("/");
      }, 1500);
    } catch (error) {
      toast.error("Erro ao enviar artigo!");
    }
  }
  return (
    <>
      <Helmet>
        <title>Cadastrar Artigo</title>
      </Helmet>
      <Bounce bottom duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <div className="items">
                <p id="text-header">
                  Compartilhe conhecimento científico na W4S!
                </p>

                <img id="img-header" src={imgArticle} alt="" />
              </div>
            </Header>
            <MainContent>
              <p id="title"> Cadastrar Artigo </p>
              <Form
                onSubmit={handleSubmit}
                className="form"
                initialData={profile}
              >
                <Input name="name" type="text" placeholder="Nome: " />
                <Input name="email" type="text" placeholder="Email: " />
                <div className="box-info">
                  <div className="input-imgs">
                    <p id="placeholder"> Imagem </p>
                    <BoxLabel>
                      <label className="label-box" htmlFor="avatar">
                        <div>
                          <img src={iconClips} id="clips" alt="" />
                          <input
                            type="file"
                            id="avatar"
                            accept="image/*"
                            disabled={imageNew.length === 1}
                            onChange={handleChangeEdit}
                            ref={ref}
                          />
                        </div>
                      </label>
                    </BoxLabel>
                  </div>

                  <p>
                    Cadastre necessariamente 2 imagens (Dimensões
                    recomendadas:720x412)
                  </p>
                </div>

                <Input
                  name="article_link"
                  type="text"
                  placeholder="Link do Artigo: "
                />
                <div className="previews">
                  {imageNew.map((preview) => (
                    <div className="preview" key={preview.id}>
                      <img id="teste" src={preview.url} alt="avatar" />
                      <button
                        type="button"
                        id="btn-preview"
                        onClick={() => {
                          deleteImg(preview.id);
                          setImageNew(
                            imageNew.filter((item) => item.id !== preview.id)
                          );
                        }}
                      >
                        Exluir
                      </button>
                    </div>
                  ))}
                </div>
                <Textarea
                  name="curriculum"
                  type="text"
                  placeholder="Mini currículo: "
                  maxLength={140}
                />
                <Input
                  name="yt_link"
                  type="text"
                  placeholder="Link para vídeo no Youtube (opcional): "
                />
                <Input
                  name="aditional_link"
                  type="text"
                  placeholder="Link para conteúdo complementar (opcional): "
                />
                <hr />
                <button id="btn-submit" type="submit">
                  Cadastrar
                </button>
              </Form>
            </MainContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Article;
