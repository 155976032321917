import { takeLatest, put, all } from "redux-saga/effects";

import { changeToTrueSuccess} from "./actions";

export function* cookiesToTrue(payload) {
    const { accept } = payload;
    yield put(changeToTrueSuccess(accept));
}

export default all([takeLatest("@cookies/CHANGE_TO_TRUE", cookiesToTrue)]);
