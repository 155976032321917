import React from "react";

import { Container, TitleBox,Content } from "./styles";

import newsPaperAside from "../../../../assets/icons/Blog/jornal-sidebar.svg";
import { useHistory } from "react-router-dom";
import { store } from "../../../../store";
import { toast } from "react-toastify";

function ArticleCard() {
  const history = useHistory();
  const { token } = store.getState().auth;

  function checkIsAuth() {
    if (!!token) {
      setTimeout(() => {
        history.push("article");
      }, 500);
    } else {
      toast.error("É necessário estar logado!");
    }
  }

  return (
    <Container>
      <TitleBox>
        <img src={newsPaperAside} alt="" />
        <p id="title">Publique Aqui!</p>
      </TitleBox>
      <Content>
        <p className="normal-text">
          Seja um cientista ou colunista parceiro! Envie seus artigos para serem
          publicados aqui e em nossas redes sociais!
        </p>
        <button type="button" onClick={checkIsAuth} className="button">
          Enviar
        </button>
      </Content>
    </Container>
  );
}

export default ArticleCard;
