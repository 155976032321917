import React, { useEffect, useState } from "react";

// Libraries
// import { Link } from "react-router-dom";
import { Bounce } from "react-reveal";
import { Scrollbars } from "react-custom-scrollbars";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import Challenge from "../../components/Challenge";

// Styled Components
import {
  Wrapper,
  Content,
  Header,
  ChallengesContent,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  BtnFilter,
  ChallengeContent,
} from "./styles";

// Icons
import iconHeader from "../../assets/icons/Challenges/first.svg";
import iconDrop from "../../assets/icons/Common/drop.svg";
import iconFilter from "../../assets/icons/Common/filter.svg";
import iconFilterDown from "../../assets/icons/Common/filter-down.svg";
import iconFilterUp from "../../assets/icons/Common/filter-up.svg";
// Icons from the other Foulder
import iconSubmit from "../../assets/icons/Store/icon-submit.svg";

// Images
import imgHeader from "../../assets/images/Challenges/first.svg";

import api from "../../services/api";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";

import { differenceInDays } from "date-fns";
import { store } from "../../store";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

function Challenges() {
  var optionsCategory = [
    "Agronegócio",
    "Meio ambiente e Sustentabilidade",
    "Indústria",
    "Comércio e prestação de serviços",
    "Desenvolvimento Urbano",
    "Artes e Cultura",
    "Ciência e Tecnologia",
    "Economia",
    "Educação",
    "Saúde",
    "Filosofia e Sociologia",
    "Entretenimento",
    "Infantil",
    "Internet",
    "Negócios e Oportunidades",
    "Política",
    "Produtos e Serviços",
    "Responsabilidade Social",
    "Mídia e Comunicação",
    "Outros",
  ];

  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [filters, setFilters] = useState([]);
  const [optionsCompany, setOptionsCompany] = useState([]);

  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(16);
  const [newCategories, setNewCategories] = useState([]);

  const { perfil } = store.getState().auth;

  useEffect(() => {
    const loadCategories = async () => {
      const res = await api.get("new-categories");
      setNewCategories(res.data);
    };
    loadCategories();
  }, []); // [] avoid useEffect() to run and make an never ended loop at each updates

  newCategories.map((item) => optionsCategory.push(item.category));

  useEffect(() => {
    const FetchCountries = async () => {
      const res = await api.get("challenges");
      setCountries(res.data);
      setFilters(res.data);
    };
    FetchCountries();
  }, []); // [] avoid useEffect() to run and make an never ended loop at each updates

  useEffect(() => {
    const loadCompanies = async () => {
      const res = await api.get("companies");
      var arrAux = [];

      for (let index = 0; index < res.data.length; index++) {
        arrAux.push(res.data[index].name);
      }
      setOptionsCompany(arrAux);
    };
    loadCompanies();
  }, []);

  const toggling = () => setIsOpen(!isOpen);
  const togglingCategory = () => setIsOpenCategory(!isOpenCategory);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const onOptionClickedCategory = (value) => () => {
    setSelectedOptionCategory(value);
    setIsOpenCategory(false);
  };

  // PAGINATION

  const paginate = (number) => setCurrentPage(number);

  const indexLastCountries = currentPage * countriesPerPage;
  const indexFirstCountries = indexLastCountries - countriesPerPage;
  const currentCountries = countries.slice(
    indexFirstCountries,
    indexLastCountries
  );

  function handleFilter() {
    setCountries(filters);
    //Nomes
    if (
      searchName != null &&
      selectedOption === null &&
      selectedOptionCategory === null
    ) {
      if (
        countries.filter((countrie) => countrie.title.includes(searchName))
          .length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
      } else {
        toast.success("Busca Concluída!");

        setCountries(
          countries.filter((countrie) => countrie.title.includes(searchName))
        );
        setSearchName("");
      }
    }
    //Empresa
    else if (
      searchName === "" &&
      selectedOption &&
      selectedOptionCategory === null
    ) {
      if (
        selectedOption &&
        countries.filter((countrie) => countrie.company.name === selectedOption)
          .length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) => countrie.company.name === selectedOption
          )
        );
        setSelectedOption(null);
      }
    }

    //Categoria
    else if (
      searchName === "" &&
      selectedOption === null &&
      selectedOptionCategory
    ) {
      if (
        selectedOptionCategory &&
        countries.filter(
          (countrie) => countrie.category === `${selectedOptionCategory}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSelectedOptionCategory(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) => countrie.category === `${selectedOptionCategory}`
          )
        );
        setSelectedOptionCategory(null);
      }
    }
    //Nome e Empreas
    else if (
      searchName !== "" &&
      selectedOption !== null &&
      selectedOptionCategory === null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.title.includes(searchName) &&
            countrie.company.name === selectedOption
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
        setSelectedOption(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.title.includes(searchName) &&
              countrie.company.name === selectedOption
          )
        );
        setSearchName("");
        setSelectedOption(null);
      }
    }
    //Nome e Categorias
    else if (
      searchName !== "" &&
      selectedOption === null &&
      selectedOptionCategory !== null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.title.includes(searchName) &&
            countrie.category === `${selectedOptionCategory}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
        setSelectedOptionCategory(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.title.includes(searchName) &&
              countrie.category === `${selectedOptionCategory}`
          )
        );
        setSearchName("");
        setSelectedOptionCategory(null);
      }
    }

    //Empresa e Categorias
    else if (
      searchName === "" &&
      selectedOption !== null &&
      selectedOptionCategory !== null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.company.name === selectedOption &&
            countrie.category === `${selectedOptionCategory}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSelectedOption(null);
        setSelectedOptionCategory(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.company.name === selectedOption &&
              countrie.category === `${selectedOptionCategory}`
          )
        );
        setSelectedOption(null);
        setSelectedOptionCategory(null);
      }
    }

    //Todos
    else if (
      searchName !== "" &&
      selectedOption !== null &&
      selectedOptionCategory !== null
    ) {
      if (
        countries.filter(
          (countrie) =>
            countrie.title.includes(searchName) &&
            countrie.company.name === selectedOption &&
            countrie.category === `${selectedOptionCategory}`
        ).length === 0
      ) {
        toast.error("Nenhum resultado encontrado");
        setSearchName("");
        setSelectedOption(null);
        setSelectedOptionCategory(null);
      } else {
        toast.success("Busca Concluída!");
        setCountries(
          countries.filter(
            (countrie) =>
              countrie.title.includes(searchName) &&
              countrie.company.name === selectedOption &&
              countrie.category === `${selectedOptionCategory}`
          )
        );
        setSearchName("");
        setSelectedOption(null);
        setSelectedOptionCategory(null);
      }
    }
  }

  function handleTeste() {
    if (filterOne || filterTwo || filterThree || filterFour || filterFive) {
      toast.error("Outro filtro já esta sendo utilizado");
    } else {
      if (
        selectedOption !== null ||
        searchName !== "" ||
        selectedOptionCategory !== null
      ) {
        handleFilter();
      } else {
        toast.error("Nenhuma opção selecionada!");
        setCountries(filters);
      }
    }
  }

  //Filters

  const [filterOne, setFilterOne] = useState(false);
  const [filterTwo, setFilterTwo] = useState(false);
  const [filterThree, setFilterThree] = useState(false);
  const [filterFour, setFilterFour] = useState(false);
  const [filterFive, setFilterFive] = useState(false);

  function isToday() {
    const currentDate = new Date();

    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const today = new Date(currentYear, currentMonth, currentDayOfMonth, 0, 0);
    return today;
  }

  function dayInUnix(data) {
    const aux = data.split("/");

    const day = new Date(aux[2], aux[1] - 1, aux[0], 0, 0);
    return day;
  }

  //Mais Recentes
  const toogleFilterOne = () => {
    if (searchName !== "" || selectedOption || selectedOptionCategory) {
      toast.error("Outros filtros estão sendo utilizados!");
    } else {
      if (
        filterOne === false &&
        !filterTwo &&
        !filterThree &&
        !filterFour &&
        !filterFive
      ) {
        //verifica se possui items com diferença de dia em até 15 dias
        if (
          countries.filter(
            (item) =>
              differenceInDays(isToday(), new Date(item.createdAt)) <= 15
          ).length === 0
        ) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterOne(true);
          setCountries(
            countries.filter(
              (item) =>
                differenceInDays(isToday(), new Date(item.createdAt)) <= 15
            )
          );
        }
      } else if (filterOne) {
        setFilterOne(false);
        setCountries(filters);
      }
    }
  };

  //Maior data limite
  const toogleFilterTwo = () => {
    if (searchName !== "" || selectedOption || selectedOptionCategory) {
      toast.error("Outros filtros estão sendo utilizados!");
    } else {
      if (
        filterTwo === false &&
        !filterOne &&
        !filterThree &&
        !filterFour &&
        !filterFive
      ) {
        //verifica se possui items com diferença de conclusão mais de 30 dias
        if (
          countries.filter(
            (item) =>
              differenceInDays(isToday(), dayInUnix(item.conclusion)) >= 30
          ).length === 0
        ) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterTwo(true);
          setCountries(
            countries.filter(
              (item) =>
                differenceInDays(isToday(), dayInUnix(item.conclusion)) >= 30
            )
          );
        }
      } else if (filterTwo) {
        setFilterTwo(false);
        setCountries(filters);
      }
    }
  };

  //Menor data limite
  const toogleFilterThree = () => {
    if (searchName !== "" || selectedOption || selectedOptionCategory) {
      toast.error("Outros filtros estão sendo utilizados!");
    } else {
      if (
        filterThree === false &&
        !filterOne &&
        !filterTwo &&
        !filterFour &&
        !filterFive
      ) {
        //verifica se possui items com diferença de conclusão em até 30 dias
        if (
          countries.filter(
            (item) =>
              differenceInDays(isToday(), dayInUnix(item.conclusion)) <= 30
          ).length === 0
        ) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterThree(true);
          setCountries(
            countries.filter(
              (item) =>
                differenceInDays(isToday(), dayInUnix(item.conclusion)) <= 30
            )
          );
        }
      } else if (filterThree) {
        setFilterThree(false);
        setCountries(filters);
      }
    }
  };

  //Menor prêmio
  const toogleFilterFour = () => {
    if (searchName !== "" || selectedOption || selectedOptionCategory) {
      toast.error("Outros filtros estão sendo utilizados!");
    } else {
      if (
        filterFour === false &&
        !filterOne &&
        !filterTwo &&
        !filterThree &&
        !filterFive
      ) {
        var total = 0;
        //adiciona o total de valores dos produtos
        countries.map((item) => (total += item.premium));
        //calcula a média
        const average = parseFloat(total / countries.length);
        //verifica se os items tem valores inferiores a media
        if (countries.filter((item) => item.premium <= average).length === 0) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterFour(true);
          setCountries(countries.filter((item) => item.premium <= average));
        }
      } else if (filterFour) {
        setFilterFour(false);
        setCountries(filters);
      }
    }
  };

  //Maior prêmio
  const toogleFilterFive = () => {
    if (searchName !== "" || selectedOption || selectedOptionCategory) {
      toast.error("Outros filtros estão sendo utilizados!");
    } else {
      if (
        filterFive === false &&
        !filterOne &&
        !filterTwo &&
        !filterThree &&
        !filterFour
      ) {
        var total = 0;
        //adiciona o total de valores dos produtos
        countries.map((item) => (total += item.premium));
        //calcula a média
        const average = parseFloat(total / countries.length);
        //verifica se os items tem valores superiores a media
        if (countries.filter((item) => item.premium >= average).length === 0) {
          toast.error("Nenhum item encontrado!");
        } else {
          setFilterFive(true);
          setCountries(countries.filter((item) => item.premium >= average));
        }
      } else if (filterFive) {
        setFilterFive(false);
        setCountries(filters);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Desafios</title>
      </Helmet>
      <Bounce top duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <section id="second-content-header">
                <img src={iconHeader} alt="" />
                <p id="text-1">DESAFIOS</p>
                <p id="text-2">
                  Mostre todo o seu talento, encontre oportunidades
                  profissionais e ainda ganhe prêmios ajudando a resolver nossos
                  Desafios!
                </p>
                <div className="buttons">
                  <p id="text-title-buttons">Buscar Desafios:</p>
                  <input
                    placeholder="Nome do Desafio"
                    onChange={(event) => {
                      setSearchName(event.target.value);
                    }}
                  ></input>
                  <DropDownContainer>
                    <DropDownHeader id="small" onClick={toggling}>
                      <p>{selectedOption || "Empresa Criadora"}</p>
                      {isOpen ? (
                        <img
                          className="icon-drop"
                          id="up-icon"
                          src={iconDrop}
                          alt=""
                        />
                      ) : (
                        <img className="icon-drop" src={iconDrop} alt="" />
                      )}
                    </DropDownHeader>
                    {isOpen && (
                      <DropDownListContainer>
                        <DropDownList>
                          <Scrollbars style={{ width: 240, height: 45 }}>
                            {optionsCompany.map((option) => (
                              <ListItem
                                onClick={onOptionClicked(option)}
                                key={Math.random()}
                              >
                                {option}
                              </ListItem>
                            ))}
                          </Scrollbars>
                        </DropDownList>
                      </DropDownListContainer>
                    )}
                  </DropDownContainer>
                  <DropDownContainer>
                    <DropDownHeader onClick={togglingCategory}>
                      <p>{selectedOptionCategory || "Categoria"}</p>
                      {isOpenCategory ? (
                        <img
                          className="icon-drop"
                          id="up-icon"
                          src={iconDrop}
                          alt=""
                        />
                      ) : (
                        <img className="icon-drop" src={iconDrop} alt="" />
                      )}
                    </DropDownHeader>
                    {isOpenCategory && (
                      <DropDownListContainer>
                        <DropDownList>
                          <Scrollbars style={{ width: 240, height: 45 }}>
                            {optionsCategory.map((option) => (
                              <ListItem
                                onClick={onOptionClickedCategory(option)}
                                key={Math.random()}
                              >
                                {option}
                              </ListItem>
                            ))}
                          </Scrollbars>
                        </DropDownList>
                      </DropDownListContainer>
                    )}
                  </DropDownContainer>
                  <button className="button-submit" onClick={handleTeste}>
                    <img src={iconSubmit} alt="" />
                  </button>
                </div>

                <img id="img-header" src={imgHeader} alt="" />
              </section>
            </Header>
            <ChallengesContent>
              <div className="filter">
                <img id="first-filter-icon" src={iconFilter} alt="" />
                <p id="first-filter-text">Filtro por:</p>

                <BtnFilter active={filterOne} onClick={toogleFilterOne}>
                  <img
                    className="second-filter-icon"
                    src={iconFilterUp}
                    alt=""
                  />
                  <p className="second-filter-text">Mais recentes</p>
                </BtnFilter>

                <BtnFilter active={filterTwo} onClick={toogleFilterTwo}>
                  <img
                    className="second-filter-icon"
                    src={iconFilterDown}
                    alt=""
                  />
                  <p className="second-filter-text">Maior data limite</p>
                </BtnFilter>

                <BtnFilter active={filterThree} onClick={toogleFilterThree}>
                  <img
                    className="second-filter-icon"
                    src={iconFilterUp}
                    alt=""
                  />
                  <p className="second-filter-text">Menor data limite</p>
                </BtnFilter>

                <BtnFilter active={filterFour} onClick={toogleFilterFour}>
                  <img
                    className="second-filter-icon"
                    src={iconFilterDown}
                    alt=""
                  />
                  <p className="second-filter-text">Menor prêmio</p>
                </BtnFilter>

                <BtnFilter active={filterFive} onClick={toogleFilterFive}>
                  <img
                    className="second-filter-icon"
                    src={iconFilterUp}
                    alt=""
                  />
                  <p className="second-filter-text">Maior prêmio</p>
                </BtnFilter>
              </div>

              <section id="filter-section"></section>

              {perfil && perfil === "Apoiador Empresarial/Desafiante" && (
                <>
                  <div className="box-for-scientists">
                    <div>
                      <p>Cadastre um Novo Desafio Agora!</p>
                      <button
                        type="button"
                        onClick={() =>
                          history.push("profile?register=challenge")
                        }
                      >
                        Clique Aqui!
                      </button>
                    </div>
                    <p>
                      <strong>IMPORTANTE:</strong> Preencha os campos
                      obrigatórios em seu Perfil para poder utilizar esse
                      recurso.{" "}
                    </p>
                  </div>
                </>
              )}

              <ChallengeContent>
                {currentCountries.map((countries, number) => (
                  <ul className="country" key={number}>
                    <Challenge data={countries} />
                  </ul>
                ))}
              </ChallengeContent>

              {countries.length !== 0 && (
                <div className="floatPage">
                  <Pagination
                    currentPage={currentPage}
                    countriesPerPage={countriesPerPage}
                    totalCountries={countries.length}
                    paginate={paginate}
                  />
                </div>
              )}
            </ChallengesContent>
            <Footer />
          </Content>
        </Wrapper>
      </Bounce>
    </>
  );
}

export default Challenges;
