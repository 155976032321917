import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.li`
  width: 628px;
  height: 378px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  padding-top: 32px;
  margin-bottom: 15px;
  margin-left: 8px;
  margin-top: 5px;

  display: flex;

  .profile {
    margin-left: 23px;

    #border {
      width: 120px;
      height: 120px;
      border: solid 2px rgba(127, 126, 190, 0.31);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      #img-profile {
        width: 101px;
        height: 101px;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 85%;
          height: 85%;
          border-radius: 50%;
        }
      }
    }
  }

  .content-profile {
    margin-left: 35px;

    p {
      font-family: GothamPro;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #227af1;
    }

    #description {
      font-family: GothamProRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }

    #section-content-profile {
      width: 90%;
      height: 2px;
      background: #eee;

      margin-bottom: 10px;
      margin-top: 10px;
    }

    a {
      margin-top: 100px;

      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.5px;
      text-align: left;
      color: #227af1;
    }

    .social-networks {
      display: flex;
      align-items: center;

      p {
        margin-right: 10px;
      }

      .icons-social {
        margin-top: 15px;
        svg {
          color: #227af1;
          margin-left: 13.8px;
          margin-right: 13.8px;

          transition: all 0.3s ease-in-out 0s;

          &:hover {
            color: ${darken(0.1, "#227af1")};
          }
        }

        #icon-email {
          width: 24px;
          height: 19.8px;

          cursor: pointer;

          margin-left: 13.8px;
          margin-bottom: 2px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 65vh;

    margin-left: 4px;

    flex-direction: column;
    align-items: center;

    .profile {
      margin-left: 0;

      #border {
        width: 80px;
        height: 80px;
        #img-profile {
          width: 61px !important;
          height: 61px !important;

          img {
            width: 85%;
            height: 85%;
          }
        }
      }
    }

    .content-profile {
      margin-left: 4vw;

      p {
        font-size: 0.8rem !important;
      }

      .social-networks {
        width: 95%;
        margin-left: 0;

        display: flex;
        flex-direction: column;

        p {
          margin-right: 0;
        }

        .icons-social {
          margin-top: 15px;
          svg {
            height: 18px !important;

            margin-right: 6px;
          }

          #icon-email {
            width: 18px !important;

            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;
