import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 80px 0;
  background: #227af1;
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  display: flex;
  align-items: center;
  flex-direction: column;

  > h1 {
    font-size: 52px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;
  
    margin-top: 40px;
  }
  #subtitle {
    margin-top: 12px;
    margin-bottom: 53.2px;

    font-family: GothamProRegular;
    font-size: 15px;
    line-height: 1.88;
    text-align: center;

    color: #fff;
  }

  ul {
    width: 100%;
    display: flex;
   
    justify-content: space-between;
    flex-wrap: wrap;

    /* Webkits */
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    /* Webkits */

    li {
      width: 352px;
      margin-bottom: 44.2px;

      .box-list {
        display: flex;
        #icon-list {
          width: 40.8px;
          height: 40.8px;

          margin-bottom: 15px;
          margin-right: 15px;
        }

        #title-list {
          font-size: 20px;
          font-weight: 500;
          line-height: 1.5;
          text-align: left;

          color: #ffffff;

          margin-bottom: 10px;
        }
      }

      #text-list {
        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 1.88;
        text-align: left;

        color: #fff;
      }
    }
  }
  
  @media screen and (min-width:805px) and (max-width: 1155px) {
    
    ul{
      li{
        width: 45%;
      }
    }
  }

  @media screen and (max-width: 804px) {
    >h1{
      font-size: 1.5rem;
    }
    
    ul{
      li{
        width: 100%;
      }
    }
  }
`;
