import React from "react";
import styled from "styled-components";

export const YT = styled.div`
  width: 628px;
  height: 357px;
  border-radius: 10px;

  @media only screen and (max-width:550px){
    width: 90vw;
    height: 56vh;
  }
`;
//version 1

class YouTubeVideo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.previousIndex = 0;
  }

  componentDidMount = () => {
    // On mount, check to see if the API script is already loaded

    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // If script is already there, load the video directly
      this.loadVideo();
    }
  };

  loadVideo = () => {
    const { videoIDs } = this.props;

    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player`, {
      playerVars: {
        playlist: videoIDs.join(","),
      },
      events: {
        onStateChange: this.onPlayerStateChange,
      },
    });
  };
  onPlayerStateChange = (event) => {
    const { videoIDs } = this.props;
    /*
                the video has changed
                seize the opportunity to update the playlist without interruption
                */
    // eslint-disable-next-line
    if (event.data == -1) {
      // get current video index
      var index = this.player.getPlaylistIndex();

      // update when playlists do not match
      // eslint-disable-next-line
      if (this.player.getPlaylist().length != videoIDs.length) {
        // update playlist and start playing at the proper index
        this.player.loadPlaylist(videoIDs, this.previousIndex + 1);
      }

      /*
                keep track of the last index we got
                if videos are added while the last playlist item is playing,
                the next index will be zero and skip the new videos
                to make sure we play the proper video, we use "last index + 1"
                */
      this.previousIndex = index;
    }
  };

  render = () => {
    return (
      <div>
        <YT id={`youtube-player`} />
      </div>
    );
  };
}

export default YouTubeVideo;
