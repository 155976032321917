import React from "react";

import { Container } from "./styles";
import ChallengeInfo from "../ChallengeInfo";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

import iconAttention from "../../../../assets/icons/Common/attention.svg";

function Aside({ challenge }) {
  function completeLink(link) {
    var finalLink;
    if (link !== null) {
      if (
        link.substring(0, 7) === "http://" ||
        link.substring(0, 8) === "https://"
      ) {
        finalLink = link;
      } else {
        finalLink = "https://www." + link;
      }
      return finalLink;
    } else {
      return link;
    }
  }
  return (
    <Container>
      <ChallengeInfo challenge={challenge} />

      <div className="company-info">
        <div className="title-company">
          <p id="company-name">Empresa Desafiante</p>
          <section></section>
        </div>

        <div className="logo-company">
          <div className="content-img">
            <img
              src={
                challenge &&
                challenge.company.avatar !== null &&
                challenge.company.avatar.url
              }
              alt=""
            />
          </div>
          <section></section>
        </div>

        <div className="social-networks">
          <p id="title-social-networks">Redes sociais:</p>
          <div className="icons">
            <a
              href={challenge && completeLink(challenge.company.instagram)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram
                title={challenge && `${challenge.company.instagram}`}
                size={21}
                color="#7f7fb9"
              />
            </a>
            <a
              href={challenge && completeLink(challenge.company.twitter)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter
                title={challenge && `${challenge.company.twitter}`}
                size={21}
                color="#7f7fb9"
              />
            </a>
            <a
              href={challenge && completeLink(challenge.company.facebook)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF
                title={challenge && `${challenge.company.facebook}`}
                size={19}
                color="#7f7fb9"
              />
            </a>
            <a
              href={challenge && completeLink(challenge.company.linkedin)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn
                title={challenge && `${challenge.company.linkedin}`}
                size={21}
                color="#7f7fb9"
              />
            </a>
            <a
              href={challenge && completeLink(challenge.company.youtube)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube
                title={challenge && `${challenge.company.youtube}`}
                size={21}
                color="#7f7fb9"
              />
            </a>
          </div>
          <section></section>
        </div>
        <div className="adress">
          <p id="title-adress-networks">Website:</p>
          <a
            id="text-adress"
            target="_blank"
            rel="noopener noreferrer"
            href={
              challenge &&
              challenge.company &&
              `${completeLink(challenge.company.website)}`
            }
          >
            {challenge && challenge.company && challenge.company.website}
          </a>
        </div>
      </div>

      <div className="special-premiun">
        <div className="box-special-premiun">
          <img src={iconAttention} alt="" />
          <p>ATENÇÃO</p>
        </div>
        <p>Você que é um Desafiante fique atento as suas notificações!</p>
        {/*Este Desafio pode ou não conter premiação especial.*/}
      </div>
    </Container>
  );
}

export default Aside;
