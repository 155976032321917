import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 54px;
  padding: 10px 0 40px 0;
  background: #227af1;
  @media screen and (max-width: 1010px) {
    padding: 30px 0 40px 0;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  z-index: 1;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 50px;
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase;
    }
    #text-subcontent {
      max-width: 536px;

      font-family: GothamProRegular;
      font-size: 14px;
      line-height: 2;
      text-align: center;
      color: #ffffff;
    }
  }

  ul {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;

      width: 352px;

      .box-list {
        display: flex;

        > img {
          height: 40.8px;
          margin-top: 17px;
        }

        #list-title {
          width: 100%;

          font-size: 20px;
          font-weight: 500;
          line-height: 1.5;
          text-align: left;
          color: #ffffff;

          margin-top: 15px;
        }
      }

      #list-text {
        margin-top: 20px;

        width: 100%;

        font-family: GothamProRegular;
        font-size: 14px;
        line-height: 2;
        text-align: left;
        color: #ffffff;
      }
    }
  }

  @media screen and (max-width: 1010px) {
    .content {
      justify-content: center;

      width: 100%;
      #text-subcontent {
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 834px) {
    .content {
      h1 {
        margin-top: 20px;
        font-size: 2rem;
      }
    }

    ul {
      flex-direction: column;
      justify-content: none;
      li {
        width: 100%;

        .box-list {
          #list-title {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 474px) {
    h1 {
      font-size: 1.5rem;
    }
    ul {
      li {
        .box-list {
          #list-title {
            font-size: 1rem;
          }
        }
      }
    }
  }
`;

export const ImgWrapper = styled.div`
  max-width: 585.8px;
  display: flex;
  align-items: center;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
`;
