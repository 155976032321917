import React from "react";

import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import imgProject from "../../../../assets/images/Project/second.svg";
import { useHistory } from "react-router-dom";

function Third() {
  const history = useHistory();
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <h1 id="donation-text-1">
              INSCREVA
              <br /> SEU PROJETO
            </h1>
            <p id="donation-text-2">
              Ao inscrever um breve e simples resumo do seu projeto ou pesquisa
              científica gratuitamente em nossa plataforma, você abre um vasto
              horizonte para a captação de recursos financeiros, intercâmbio de
              conhecimentos, busca de parceiros institucionais nacionais e
              internacionais, além de impulsionar sua carreira profissional!
            </p>
            <button onClick={() => history.push("profile")}>
              Cadastre-se agora!
            </button>
            <div id="first-circle-donation"> </div>
            <div id="second-circle-donation"></div>
            <div id="third-circle-donation"></div>
          </Column>
          <Column>
            <ImgWrapper>
              <Img src={imgProject} alt="" />
            </ImgWrapper>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Third;
