import React from "react";

import { Fade } from "react-reveal";

import { Wrapper, Content, Header, MainContent } from "./styles";

import { Link, useHistory } from "react-router-dom";

//Icons
import iconHeader from "../../assets/icons/Success/check.svg";

// Images -> Common Foulder
import imgMain from "../../assets/images/champ.svg";

import {
  FaWhatsapp,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";

// Components
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import { store } from "../../store";
import { Helmet } from "react-helmet";

export default function SendChallenge() {
  const history = useHistory();

  const { token } = store.getState().auth;

  function goToProfile() {
    if (!!token) {
      setTimeout(() => {
        history.push("profile");
      }, 350);
    } else {
      setTimeout(() => {
        history.push("register");
      }, 350);
    }
  }

  return (
    <>
      <Helmet>
        <title>Desafio enviado para análise!</title>
      </Helmet>
      <Fade top duration={2000} distance="20px">
        <Wrapper>
          <Content>
            <Header>
              <SubHeader />
              <div className="congratulations">
                <div className="box">
                  <div className="box-img">
                    <img src={iconHeader} alt="" />
                  </div>
                </div>
                <p id="text-congrat">Desafio enviado para análise!</p>
              </div>
              <div className="circles">
                <div id="first-circle"> </div> <div id="second-circle"> </div>
                <div id="third-circle"> </div>
              </div>
            </Header>
            <MainContent>
              <div className="content">
                <p>
                  <strong>Parabéns, Desafiante! </strong>
                  <br />
                  <br />
                  O cadastro do seu Desafio foi realizado com sucesso e já está
                  em análise por nossa equipe técnica. 
                  <br />
                  <br />
                  Se for seu primeiro Desafio, é possível que nossa equipe entre
                  em contato com você para sanar eventuais dúvidas ou solicitar
                  possíveis correções e ajustes, por isso verifique se seus
                  dados de contato (telefone e email) estão cadastrados
                  corretamente em seu{" "}
                  <span
                    onClick={() => goToProfile()}
                    style={{ cursor: "pointer", color: "#227af1" }}
                  >
                    Perfil Empresarial
                  </span>
                  .
                  <br />
                  <br />
                  Você também pode sanar eventuais dúvidas pelo 
                  <Link to="contact?&type=normal" style={{ color: "#227af1" }}>
                    Fale Conosco
                  </Link>
                  . 
                  <br />
                  <br />
                  Nossa equipe agradece sua participação na 
                  <strong>Iniciativa We4Science - Todos pela Ciência!</strong> e
                  deseja boa sorte em seu Desafio! 
                  <br />
                  <br />
                  Enquanto seu Desafio não é publicado, aproveite esse momento
                  para acompanhar as novidades sobre a Ciência e a W4S em nossas
                  redes sociais!
                </p>
                <button
                  type="button"
                  onClick={() => {
                    setTimeout(() => {
                      history.push("/teste");
                    }, 150);
                  }}
                >
                  Home
                </button>
              </div>
              <div className="second-content">
                <img src={imgMain} alt="" />
                <div className="social-networks">
                  <p id="text-social-networks"> Siga-nos nas redes sociais: </p>
                  <hr />
                  <div className="icons">
                    <a
                      href="https://api.whatsapp.com/send?phone=+55038988016970"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp size={23} />
                    </a>
                    <a
                      href="https://www.instagram.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram size={23} />
                    </a>
                    <a
                      href="https://twitter.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter size={23} />
                    </a>
                    <a
                      href="https://pt-br.facebook.com/We4Science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebookF size={23} />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC3SaYAjljSoSu-Aj0XsSq-w"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube size={23} />
                    </a>
                  </div>
                </div>
              </div>
            </MainContent>

            <Footer />
          </Content>
        </Wrapper>
      </Fade>
    </>
  );
}
