import React from "react";


import { Wrapper, Row, Column, ImgWrapper, Img } from "./styles";

import { Container } from "../../../../styles/global";

import NavBar from "../../../../components/SubHeader";

import iconHeader from "../../../../assets/icons/Scientists/icon-header.svg";

import imgHeader from "../../../../assets/images/Scientists/img-header.svg";
import floor from "../../../../assets/images/Scientists/floor.svg";

function Header() {
  return (
    <Wrapper>
      <NavBar />
      <Container>
        <Row>
          <Column>
            <img id="icon" src={iconHeader} alt="" />
            <h1>CIENTISTAS</h1>
            <p id="text">
              Se você é cientista, pesquisador(a) ou representa uma Instituição
              de Ensino e Pesquisa, chegou a hora de alavancar seus projetos
              científicos, sua instituição e sua carreira na Ciência!
            </p>
          </Column>
          <Column larger>
            <ImgWrapper>
              <Img className="translate" src={imgHeader} alt="" />
              <Img src={floor} alt="" />
            </ImgWrapper>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Header;
