import { darken } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 1212px;
  #title-new {
    font-size: 45px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #227af1;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .news {
    margin-top: 20px;
    ul {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    margin-left: 5%;

    #title-new {
      font-size: 1.4rem;
    }

    .news {
      width: 100%;

      ul {
        width: 100%;
      }
    }
  }
`;

export const New = styled.li`
  width: 628px;
  height: 212px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(49, 22, 159, 0.12);
  background-color: #ffffff;

  z-index: 1;
  position: relative;

  padding: 20.8px 0 0 44px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 5px;

  ${(props) =>
    props.img &&
    css`
      height: 559px;

      #img-new {
        margin-top: 7.8px;
        max-width: 542px;
        max-height: 335px;
      }
    `}

  .calendar-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    margin-bottom: 5px;

    p {
      margin-left: 10px;

      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7f7fb9;
    }
    .trash {
      display: flex;
      align-items: center;
      margin-left: 442.6px;
      cursor: pointer;

      #text-trash {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #227af1;

        transition: all 0.3s ease-in-out 0s;
        &:hover {
          color: ${darken(0.1, "#227af1")};
        }
      }
    }
  }

  #title-new-content {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #2976fa;
  }

  p {
    width: 633px;

    font-family: GothamProRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  @media only screen and (max-width: 768px) {
    width: 80vw !important;

    padding: 2vh 0 0 5vw;

    p {
      width: 100%;
      word-wrap: break-word;
      word-break: break-all;
    }

    ${(props) =>
      props.img &&
      css`
        #img-new {
          max-width: 80%;
          max-height: 80%;
        }
      `}
  }
`;
