import React from "react";

import {
  Wrapper,
  Row,
  Column,
  FooterLinksItems,
  FooterLink,
  WrapperTwo,
} from "./styles";

import { Container } from "../../styles/global";

import logo from "../../assets/icons/footer/logo.svg";

// External Icons
import {
  FaWhatsapp,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";
import { useHistory } from "react-router";

function Footer() {
  //const { perfil } = store.getState().auth;

  const history = useHistory();


  return (
    <>
      <Wrapper>
        <Container>
          <Row>
            <Column>
              <div className="fs-container">
                <img className="logo" src={logo} alt="" />
                <div className="social-networks" >
                  <p>Redes Sociais</p>
                  <div>
                    <a
                      href="https://api.whatsapp.com/send?phone=+55038988016970"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp />
                    </a>

                    <a
                      href="https://www.instagram.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href="https://www.twitter.com/we4science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter />
                    </a>
                    <a
                      href="https://www.facebook.com/We4Science"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebookF id="small" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC3SaYAjljSoSu-Aj0XsSq-w"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube id="big" />
                    </a>
                  </div>
                </div>
              </div>
            </Column>
            <Column>
              <div className="container-links-footer">
                <FooterLinksItems>
                  <FooterLink to="/about"> Quem somos </FooterLink>
                  <FooterLink to="/projects"> Projetos e Pesquisas </FooterLink>
                  <FooterLink to="/store"> Mercado Whuffie </FooterLink>
                  <FooterLink to="/supporters"> Apoiadores </FooterLink>
                  <FooterLink to="/scientists"> Cientistas </FooterLink>
                </FooterLinksItems>
                <FooterLinksItems>
                  <FooterLink to="/company"> Empresas </FooterLink>
                  <FooterLink to="/blog"> Radar </FooterLink>
                  <FooterLink to="/challenges"> Desafios </FooterLink>
                  <FooterLink to="/contact?&type=normal">
                    {" "}
                    Fale Conosco{" "}
                  </FooterLink>
                </FooterLinksItems>
                <button onClick={()=>history.push("/register")}>Seja um parceiro W4S!</button>
              </div>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <WrapperTwo>
        <Container>
          <Row spaceBetween>
            <p className="text-footer-2">
              We4Science Projects and Innovation Ltda &reg;{" "}
              {new Date().getFullYear()} - Todos os direitos reservados
            </p>
            <p className="text-footer-2">
              Produzido por{" "}
              <a
                id="link-fac"
                href="https://www.facconsulting.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facconsulting
              </a>
            </p>
          </Row>
        </Container>
      </WrapperTwo>
    </>
  );
}

export default Footer;
