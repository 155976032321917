import React from "react";

import {
  HeaderContainer,
  Background,
  Title,
  Subtitle,
  Wrapper,
} from "./styles";

import backgroundImg from "../../../../assets/images/background.svg";

import science from "../../../../assets/images/Home/science.svg";
import floorScientists from "../../../../assets/images/Home/floor-scients-1.svg";

import Navbar from "../../../../components/SubHeader";
import { useHistory } from "react-router";

import { Container } from "../../../../styles/global";

function Header() {
  const history = useHistory();

  return (
    <Wrapper>
      <Navbar />
      <Container>
        <Background src={backgroundImg} alt="" />
        <HeaderContainer>
          <div id="science">
            <img src={floorScientists} id="floor-scientists" alt="" />
            <img src={science} id="scientists" alt="" />
          </div>

          <section id="second-content-header">
            <Title>
              Todos nós podemos contribuir com a Ciência e o progresso da
              humanidade!
            </Title>
            <Subtitle>
              Sejam pessoas comuns, empresas privadas, universidades ou centros
              de pesquisa e tecnologia, aqui todos são recompensados por apoiar
              a Ciência!
            </Subtitle>

            <button id="btnProjects" onClick={() => history.push("projects")}>
              {" "}
              Conheça os Projetos e Pesquisas!
            </button>

            <div>
              <div id="circles">
                <div id="first-circle"> </div> <div id="second-circle"> </div>
                <div id="third-circle"> </div>
                <div id="fourth-circle">
                  <div className="button-circles">
                    <button onClick={() => history.push("projects")}>
                      Conheça os Projetos e Pesquisas!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </HeaderContainer>
      </Container>
    </Wrapper>
  );
}

export default Header;
